function OsSystem(options) {
  THREE.Object3D.call(this)
  this.type = 'OsSystem'

  if (!options) {
    options = {}
  }

  const fromJSON = !!options.fromJSON

  // @TODO: This was added to load autoDesign system results from JSON but beware this may
  // break initial scene loading...
  if (fromJSON && options.userData && !editor.sceneIsLoading) {
    options = options.userData
  }

  // Apply uuid immediately to ensure that any later updates in the constructor use
  // this uuid instead of the randomly generated uuid which would trigger
  // phantom calcs for system uuids that do not exist.
  if (options.uuid) {
    this.uuid = options.uuid
  }

  // Temporary hack for PDF generation
  if (!options.moduleId && options.userData && options.userData.moduleId) {
    options.moduleId = options.userData.moduleId
  }

  // Temporary hack for PDF generation
  if (options.userData && options.userData.module && options.userData.moduleId) {
    if (!AccountHelper.getModuleData(options.userData.moduleId).id) {
      console.log('Inject options.userData.module into AccountHelper.loadedData.componentModuleSpecs')
      var newModuleSpec = new window.ModuleType(options.userData.module)
      if (window.SWAP_DOMAIN_FUNC) {
        var keys = [
          // Normally moduleTexture would be injected dynamically from Exhibit content when loading component specs
          // But if we are fetching directly from osSystem.module then the API domain may be incorrect. We will manually
          // modify it here before injecting into component specs
          'module_texture',
          'logo',
        ]

        keys.forEach((key) => {
          var val = newModuleSpec[key]
          if (val) {
            newModuleSpec[key] = window.SWAP_DOMAIN_FUNC(val)
          }
        })

        if (newModuleSpec.component_content) {
          var keys2 = ['component_content', 'logo_image_url', 'module_texture_url']
          keys2.forEach((key) => {
            var val = newModuleSpec.component_content[key]
            if (val) {
              newModuleSpec.component_content[key] = window.SWAP_DOMAIN_FUNC(val)
            }
          })
        }
      }

      AccountHelper.loadedData.componentModuleSpecs.push(newModuleSpec)
    }
  }

  if (options.userData?.module) {
    /*
    secret=true argument has been removed due to complicated data corruption that has not been fully diagnosed.
    but we are confident that disabling secret=true will fix the immediate issue. We can do a more thorough
    investigation and fix later. See https://github.com/open-solar/opensolar-todo/issues/9230
    */
    this.module_type = new window.ModuleType(options.userData.module)
  }

  //Set module code and return the value which may be different due to applying defaults etc.
  this.setModuleTypeByModuleId(options.moduleId ? options.moduleId : this.detectModuleId())

  this.dcOptimizer(options.dcOptimizerId ? options.dcOptimizerId : null)

  //format: key: default
  var optionKeyAndDefaults = {
    shadingOverride: [],
    unstrungModulesInverterEfficiency: null,
    basicPriceOverride: null,
    basicPriceOverridePerWatt: null,
    essential_backup_metrics: {},
    commission_override_manually: null,
    commission_id: null,
    inverterRange: null,
    pricing_scheme_id: options.basicMode ? null : AccountHelper.getPricingSchemeDefaultId(),
    version: null,
    costing_override: null,
    output: null,
    pricing: null,
    snapshot: null,
    show_customer: true,
    name: '',
    is_current: false,
    transformable: false,
    order: 0,
    system_lifetime: 0,
    discount: 0,
    incentives: null,
    payment_options: [],
    payment_options_override: null,
    payment_options_settings_overrides: {},
    contract_template_overrides: {},
    line_items: [],
    incentive_to_installer: [],
    incentive_to_customer: [],
    non_solar_price_included: 0,
    non_solar_project_type: undefined,
    milestone_payment_overrides: {},
    force_enable_cashflow: false,
    lender_battery_price_override: undefined,
    adders_per_system: 0,
    adders_per_panel: 0,
    adders_per_watt: 0,
    override_price_locking: false,
    export_limit: null,
    battery_control_scheme: null,
    load_offsettable_by_battery_fraction: null,
    load_offsettable_by_battery_cap: null,
    generation_override: null,
    self_consumption_override: null,
    allow_shade_mitigation: true,
    autoString: SetbacksHelper ? Boolean(SetbacksHelper.inverterModellingAutomation) : true,
    calculator: WorkspaceHelper.getDefaultPerformanceCalculator(),
    utility_tariff_id_override: null,
    // integration_json possibly already set above when auto-applying moduleId or optimizer
    integration_json: this.integration_json ? this.integration_json : null,
    systemPanelPlacement: null,
    system_efficiency_override: undefined,
    ibi_reduction_incentive: undefined,
    sweden_green_deduction_incentive: {
      solar_pv_price: 0,
      battery_price: 0,
      ev_charger_price: 0,
      num_of_household_members: 1,
    },
    custom_data: {},
    slots: [],
    mcs_self_consumption_calculator_override: undefined,
    weather_dataset_override: undefined,
  }

  for (var key in optionKeyAndDefaults) {
    this[key] = Utils.getFromOptionsOrDefault(options, key, optionKeyAndDefaults[key])
  }

  if (options.moduleGrids) {
    options.moduleGrids.forEach(function (mgOptions) {
      editor.addObject(
        new OsModuleGrid(
          Object.assign(
            {
              size: this.moduleType().size,
              azimuth: 180,
              slope: 20,
            },
            mgOptions
          )
        ),
        this
      )
    }, this)
  }

  if (!fromJSON) {
    // These are excluded to ensure that when loading auto-designed systems we do not get
    // duplicate inverters/etc. We may be able to remove this completely if we verify it is
    // no longer needed for any cases.
    if (options.inverters) {
      options.inverters.forEach(function (inverterOptions) {
        editor.addObject(new OsInverter(inverterOptions), this)
      }, this)
    }

    if (options.batteries) {
      options.batteries.forEach(function (batteryOptions) {
        editor.addObject(new window.OsBattery(batteryOptions), this)
      }, this)
    }

    if (options.others) {
      options.others.forEach(function (otherOptions) {
        console.log('opt', otherOptions)
        editor.addObject(new window.OsOther(otherOptions), this)
      }, this)
    }
  }

  // Adjust defaults (if possible) to basic mode can be used
  if (options.basicMode) {
    if (this.autoString) {
      // if trying to use basicMode then disable autoString, regardless of preferences
      // otherwise basic mode will always be blocked if autoString default is true
      this.autoString = false
    }
  }
  this._basicMode = false
  if (options.basicMode && this.basicModeCompatible()) {
    this.setBasicMode(options.basicMode)
  }

  // Warning/Error messages which can be used by SmartComponents.
  this.messages = {}

  // This data is not stored, it is managed by AutoApplyHelper
  // Never auto-sync paymentOptions
  this.autoSync = {
    pricingScheme: Boolean(options?.autoSync?.pricingScheme),
    costing: Boolean(options?.autoSync?.costing),
    adders: Boolean(options?.autoSync?.adders),
  }

  this.assessSlotsDebounced = window.Utils.debounce(this.assessSlots, 100)
  this.assessSlotsDebounced()
}

OsSystem.moduleIdDefault = null

OsSystem.getComponentTypeFromSlotKey = function (slotKey) {
  var slotTypeToComponentType = {
    ac_isolator: 'isolator',
    dc_isolator: 'isolator',
    trunk_cable: 'cable',
    ac_cable: 'cable',
    dc_cable: 'cable',
  }

  var { slotType } = OsSystem.slotKeyToDict(slotKey)
  return slotTypeToComponentType[slotType] || slotType
}

OsSystem.slotKeyToDict = function (slotKey) {
  var slotTypes = [
    'dc_optimizer',
    'isolator',
    'meter',
    'mounting', //deprecated
    'roof_hook', //deprecated
    'general',
    'ac_isolator',
    'dc_isolator',
    'trunk_cable',
    'ac_cable',
    'dc_cable',
    'mounting_rail',
    'mounting_roof_anchor',
    'mounting_clamp',
    'mounting_flashing',
    'mounting_roof_fixing',
    'mounting_other',
  ]
  if (slotKey) {
    for (var i = 0; i < slotTypes.length; i++) {
      let slotType = slotTypes[i]
      if (slotKey.startsWith(slotType)) {
        var objectUuid = slotKey.replace(slotType + '_')
        return { slotType: slotType, objectUuid }
      }
    }
  }
  return { slotType: null, objectUuid: null }
}

OsSystem.getComponentsForSlot = function (components, slotKey) {
  return components.filter((component) => component.slotKey === slotKey)
}

OsSystem.prototype = Object.assign(Object.create(THREE.Object3D.prototype), {
  constructor: OsSystem,
  // Slots should be used for components that are tied to particular areas of the system.
  // For example, isolators (aka switches, breakers) are added at particular points in the system and must be specced accordingly
  getComponentsForSlot: function (slotKey) {
    return OsSystem.getComponentsForSlot(this.others(), slotKey)
  },

  objectsUpdated() {
    // Gets called whenever descendant objects are added/removed from the system
    //
    // Check before calling because this.assessSlotsDebounced may not have been injected yet if this happens
    // inside the OsSystem constructor.
    if (this.assessSlotsDebounced) {
      this.assessSlotsDebounced()
    }
  },

  assessSlots() {
    const slots = []
    for (const inverter of this.inverters()) {
      inverter.collectSlots(slots)
    }
    // Retain info in slots and detect changes
    // @TODO: Consider if we could ignore changes due to slots changing from undefined to empty array?
    // This would allow us to define systems more concisely and avoid needing to populate empty slots array
    // For now we must populate slots to avoid this triggering changes.
    let hasChanges = !this.slots || this.slots.length !== slots.length
    if (this.slots) {
      for (var i = 0; i < slots.length; i++) {
        const slot = slots[i]
        const existingSlot = this.slots.find((s) => s.type === slot.type && s.attachToUuid === slot.attachToUuid)
        // This might need to change at some point if the slots generated by collectSlots have more info that type and attachToUuid
        if (existingSlot) slots[i] = existingSlot
        else hasChanges = true
      }
    }

    //Only trigger update if changes detected
    if (hasChanges) {
      // This must be associated with the same command that triggered the change originally
      // This is difficult because assessSlots() is debounced so we don't necessarily know which command
      // actually triggered it. Therefore, we will simply check for the most recent command that could have triggered it.
      // and use that. This is a little dangerous if we don't consider all the commands that could trigger it,
      // but the downside is not too bad, it just means that redo-ing after an undo will clear the test of the
      // redo commands which is not great, but will not often affect the user.
      const undos = window.editor.history.undos
      const lastCommandUUID = undos.length > 0 ? undos[undos.length - 1].commandUUID : undefined
      window.editor.execute(new window.SetValueCommand(this, 'slots', slots, lastCommandUUID))
    }
  },

  isEmpty: function () {
    /*
    Used to determine when we can ignore recalcs. It is not enough to just check that the system is "empty" because
    we should allow recalcs on a system which has existing output/calcs so it can be cleared.
    We only check this.output but this is just a proxy for any calcs results like pricing, etc.
    */
    return this.children.length === 0 && !this.output
  },

  toolsActive: function () {
    return {
      translateXY: false,
      translateZ: false,
      rotate: false,
      scaleXY: false,
      scaleZ: false,
      scale: false, //legacy
    }
  },

  priceHasChangedSinceOverrideApplied: function () {
    // Compare saved diffs (which was saved at the time the override was added)
    // versus current diffs

    var current = this.calculateDiffFromPriceOverrideVersusCalculatedPrice()

    for (var payment_option_id in this.temp_payment_options_delta_versus_price_override) {
      if (current[payment_option_id] !== this.temp_payment_options_delta_versus_price_override[payment_option_id]) {
        return true
      }
    }
    return false
  },

  saveDiffFromPriceOverrideVersusCalculatedPrice: function () {
    this.temp_payment_options_delta_versus_price_override = this.calculateDiffFromPriceOverrideVersusCalculatedPrice()
    return this.temp_payment_options_delta_versus_price_override
  },

  calculateDiffFromPriceOverrideVersusCalculatedPrice: function () {
    var autoPopulateFirstRun = false

    if (!this.payment_options) {
      return {}
    }
    if (!this.payment_options_settings_overrides) {
      // No price overrides are set, no need to check
      return {}
    }
    if (!this.temp_payment_options_delta_versus_price_override) {
      // No calculated-price-versus-override deltas are set
      // We should calculate them now so we can detect any changes from this point onwards.
      // e.g. When we have loaded an existing system this will allow us to detect any changes going forward.
      // Obviously, they will never detect a change on this first call immediately after being set.
      autoPopulateFirstRun = true
    }
    var result = {}
    this.payment_options
      .filter(
        (po) =>
          Boolean(this.payment_options_settings_overrides[po.id]) &&
          this.payment_options_settings_overrides[po.id].price &&
          this.pricing.system_price_including_tax
      )
      .forEach((po) => {
        result[po.id] = this.payment_options_settings_overrides[po.id].price - this.pricing.system_price_including_tax
      }, this)

    if (autoPopulateFirstRun) {
      this.temp_payment_options_delta_versus_price_override = result
    }

    return result
  },

  detectInheritedShadingOverride: function () {
    if (this.hasShadingOverride()) {
      return this.shadingOverride
    } else {
      return []
    }
  },

  systemContainsHybridInverter: function () {
    return this.inverters().some(function (inverter) {
      return inverter.isHybridInverter() || inverter?.hybrid === 'Y'
    })
  },

  shadingOverrideRawIsComplete: function () {
    // Ignore any modules where overrides apply
    // Test all shadingOverrideRaw raytraces each have 288 values
    return this.getModules().every(
      (m) =>
        m.hasShadingOverride() ||
        (m.shadingOverrideRaw &&
          m.shadingOverrideRaw.length > 0 &&
          m.shadingOverrideRaw.every((sor) => sor.length === 288))
    )
  },

  reloadModuleSpecs: function () {
    //force clear imperfect module data, reload module specs
    this.initializingModuleSpecs = true
    const forceUpdate = true
    this.setModuleTypeByModuleId(this.moduleId, forceUpdate)
    this.initializingModuleSpecs = false
  },

  add: function (child) {
    THREE.Object3D.prototype.add.call(this, child)
  },

  inverters: function () {
    return this.children.filter(function (child) {
      return child.type === 'OsInverter'
    })
  },

  batteries: function () {
    return this.children.filter(function (child) {
      return child.type === 'OsBattery'
    })
  },

  others: function () {
    return this.children.filter(function (child) {
      return child.type === 'OsOther'
    })
  },

  compatibleBatteryCodes: function () {
    /*
    Return codes for components in this system matching either a) hybrid inverters or b) battery chargers.
    1) Should we send these codes to the back-end and do filtering there?
      - Pros: Avoid needing to store compatibility data in the front-end
      - Cons: We lack this data on the front-end.
    Or
    2) should we load in the compatibilities into Studio and do filtering here first, then send the resolved components
    to the back-end to filter battery results.
      - Pros: We can use compatibility data in the front-end for warnings, suggestions etc.
      - Cons: More data required for loading components and storing inside design. What if a battery is compatible
              with 100 other batteries, that is an extra 100 codes we need to load into the front-end and save in
              the design data.
    */

    // @TODO: Remove the need to check otherType.compatibleBatteryCodes exists by adding temporary components properly
    // as instances of OtherType, see ManageOtherComponentsDialog.commitChanges(...)

    var result = []
    this.others().forEach((osOther) => {
      let otherType = osOther.getComponentData()
      if (otherType && otherType.compatibleBatteryCodes) {
        let tmpCompatibleBatteryCodes = otherType.compatibleBatteryCodes()
        if (tmpCompatibleBatteryCodes?.length > 0) {
          result = result.concat(tmpCompatibleBatteryCodes)
        }
      }
    })
    this.inverters().forEach((osInverter) => {
      let inverterType = osInverter.getComponentData()
      if (inverterType && inverterType.compatibleBatteryCodes) {
        let tmpCompatibleBatteryCodes = inverterType.compatibleBatteryCodes()
        if (tmpCompatibleBatteryCodes?.length > 0) {
          result = result.concat(tmpCompatibleBatteryCodes)
        }
      }
    })
    return result
  },

  compatibleChargerCodes: function () {
    var result = []
    this.batteries().forEach((osBattery) => {
      let batteryType = osBattery.getComponentData()
      if (batteryType) {
        let tmpCompatibleChargerCodes = batteryType.compatibleChargerCodes()
        if (tmpCompatibleChargerCodes?.length > 0) {
          result = result.concat(tmpCompatibleChargerCodes)
        }
      }
    })
    return result
  },

  componentCodes: function () {
    return [this.moduleType().code].concat(
      this.inverters().map((i) => i.code),
      this.batteries().map((b) => b.code),
      this.others().map((o) => o.code)
    )
  },

  componentsUnique: function () {
    var componentCodesAdded = []
    var components = []

    components.push(this.moduleType())

    var componentsOtherThanModule = [].concat(this.inverters(), this.batteries(), this.others())

    componentsOtherThanModule.forEach((c) => {
      if (componentCodesAdded.indexOf(c.code) === -1) {
        components.push(c.getComponentData())
        componentCodesAdded.push(c.code)
      }
    })
    // strip empty components which could happen if design includes a component that is no longer available (or wrong id)
    return components.filter(Boolean)
  },

  annotations: function () {
    return this.children.filter(function (child) {
      return child.type === 'OsAnnotation'
    })
  },

  confirmBeforeDelete: function () {
    return 'Are you sure you want to delete this system? '
  },

  handleDelete: function (editor, commandUUID) {
    if (!commandUUID) {
      commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
    }
    if (editor.getSystems().length === 1) {
      window.Designer.showNotification(
        window.translate('Cannot delete. Design must have at least one system!'),
        'warning'
      )
      return
    }
    editor.execute(new window.RemoveObjectCommand(this, true, undefined, commandUUID))
    const systems = editor.getSystems()
    if (systems.length === 1) {
      editor.execute(new window.SetValueCommand(systems[0], 'show_customer', true, commandUUID))
    }
  },

  refreshAutomatedComponents: function () {
    this.refreshDcOptimizerEfficiencyAndQuantity(editor)
  },

  onChange: function () {
    this.refreshDcOptimizerEfficiencyAndQuantity(editor)

    this.refreshStrings(editor)

    // If inverters are supplied override unstrungModulesInverterEfficiency based on assigned inverters
    this.unstrungModulesInverterEfficiency = this.calculateAverageInverterEfficiency()
  },

  getModuleTotalOutput: function () {
    return this.moduleType().kw_stc * this.getModules().length
  },

  calculateAverageInverterEfficiency: function () {
    if (this.inverters().length > 0) {
      return (
        this.inverters()
          .map(function (i) {
            return i.efficiency
          })
          .reduce(function (a, b) {
            return a + b
          }, 0) / this.inverters().length
      )
    } else if (this.unstrungModulesInverterEfficiency) {
      //use existing deafult, if set
      return this.unstrungModulesInverterEfficiency
    } else {
      //fallback to hard-coded default value
      if (AccountHelper && AccountHelper.getDefaultInverterEfficiency) {
        return AccountHelper.getDefaultInverterEfficiency()
      } else {
        return null
      }
    }
  },

  refreshUserData: function () {
    //placeholder!!
    this.userData = {
      uuid: this.uuid,
      version: this.version,
      order: this.order,
      system_lifetime: this.system_lifetime,
      inverters: this.inverters().map(function (o) {
        return o.refreshUserData()
      }),
      batteries: this.batteries().map(function (o) {
        return o.refreshUserData()
      }),
      others: this.others().map(function (o) {
        return o.refreshUserData()
      }),
      annotations: this.annotations().map(function (o) {
        return o.refreshUserData()
      }),
      unstrungModulesByModuleGrid: this.unstrungModulesByModuleGrid(),
      unstrungModulesInverterEfficiency: this.unstrungModulesInverterEfficiency,
      module: this.moduleType(),
      moduleId: this.moduleId,
      inverterRange: this.inverterRange,
      output: this.output,
      environmentals: this.environmentals,
      pricing: this.pricing,
      consumption: this.consumption,
      payment_options: this.payment_options,
      bills: this.bills,
      pricing_scheme_id: this.pricing_scheme_id,
      costing_override: this.costing_override,
      shadingOverride: this.shadingOverride ? this.shadingOverride : [],
      basicMode: this.isBasicMode(),
      basicPriceOverride: this.basicPriceOverride ? this.basicPriceOverride : null,
      commission_override_manually:
        this.commission_override_manually !== null ? this.commission_override_manually : null,

      // commission id can be:
      // null -> for auto-apply
      // int -> for custom commission
      // string -> 'manual' or 'no-commission', but we don't save these values
      commission_id: this.commission_id ? (_.isString(this.commission_id) ? null : this.commission_id) : null,

      basicPriceOverridePerWatt: this.basicPriceOverridePerWatt ? this.basicPriceOverridePerWatt : null,
      tax_override_hardware:
        this.tax_override_hardware || this.tax_override_hardware === 0 ? this.tax_override_hardware : null,
      tax_override_non_hardware:
        this.tax_override_non_hardware || this.tax_override_non_hardware === 0 ? this.tax_override_non_hardware : null,
      snapshot: this.snapshot,
      show_customer: this.show_customer, ///
      is_current: this.is_current, ///
      name: this.name,
      kwStc: this.kwStc(),
      kw_stc: this.kwStc(), ///
      moduleQuantity: this.moduleQuantity(),
      module_quantity: this.moduleQuantity(), ///
      dcOptimizerEfficiency: this.dcOptimizerEfficiency(),
      autoString: this.autoString,
      discount: this.discount,
      incentives: this.incentives,
      payment_options_override: this.payment_options_override,
      payment_options_settings_overrides: this.payment_options_settings_overrides,
      contract_template_overrides: this.contract_template_overrides,
      line_items: this.line_items,
      incentive_to_installer: this.incentive_to_installer.map((incentive) => _.omit(incentive, ['new'])),
      incentive_to_customer: this.incentive_to_customer.map((incentive) => _.omit(incentive, ['new'])),
      non_solar_price_included: this.non_solar_price_included,
      non_solar_project_type: this.non_solar_project_type,
      milestone_payment_overrides: this.milestone_payment_overrides,
      force_enable_cashflow: this.force_enable_cashflow,
      lender_battery_price_override: this.lender_battery_price_override,
      adders_per_system: this.adders_per_system,
      adders_per_panel: this.adders_per_panel,
      adders_per_watt: this.adders_per_watt,
      site: {
        is_commercial: WorkspaceHelper?.project?.is_residential === false,
        longitude: editor.scene.sceneOrigin4326[0],
        latitude: editor.scene.sceneOrigin4326[1],
        timezoneOffset: editor.scene.timezoneOffset,
        zip:
          WorkspaceHelper && WorkspaceHelper.project && WorkspaceHelper.project.zip
            ? WorkspaceHelper.project.zip
            : null,
        country:
          WorkspaceHelper && WorkspaceHelper.project && WorkspaceHelper.project.country_iso2
            ? WorkspaceHelper.project.country_iso2
            : null,
      },
      battery_total_kwh: this.batteryTotalKwh(),
      override_price_locking: this.override_price_locking,
      export_limit: this.export_limit,
      battery_control_scheme: this.battery_control_scheme,
      is_battery_dc_connected: this.is_battery_dc_connected,
      is_battery_dc_connected_auto: this.is_battery_dc_connected_auto ?? true,
      load_offsettable_by_battery_fraction: this.load_offsettable_by_battery_fraction,
      load_offsettable_by_battery_cap: this.load_offsettable_by_battery_cap,
      generation_override: this.generation_override,
      self_consumption_override: this.self_consumption_override,
      mcs_self_consumption_calculator_override: this.mcs_self_consumption_calculator_override,
      allow_shade_mitigation: this.allow_shade_mitigation,
      calculator: this.calculator,
      utility_tariff_id_override: this.utility_tariff_id_override,
      integration_json: this.integration_json,
      systemPanelPlacement: this.systemPanelPlacement ? this.systemPanelPlacement : 'Roof',
      shadingByPanelGroup: this.moduleGrids().map((mg) => ({
        uuid: mg.uuid,
        slope: mg.getSlope(),
        azimuth: mg.getAzimuth(),
        beam_access: mg.beamAccess,
        beam_access_back: mg.beamAccessBack || null,
        module_quantity: mg.moduleQuantity(),
      })),
      electrical: this.electricalCalcs(),
      system_efficiency_override: this.system_efficiency_override,
      mounting: this.mounting,
      mounting_type: this.mounting_type,
      slots: this.slots,
      ibi_reduction_incentive: this.ibi_reduction_incentive,
      sweden_green_deduction_incentive: this.sweden_green_deduction_incentive,
      custom_data: this.custom_data,
      partner_accessories: this.partner_accessories,
      essential_backup_metrics: this.essential_backup_metrics,
      weather_dataset_override: this.weather_dataset_override,
    }
    return this.userData
  },

  applyUserData: function () {
    var fields = [
      'version',
      'order',
      'system_lifetime',
      'moduleId',
      'inverterRange',
      'output',
      'environmentals',
      'pricing',
      'additional_costs',
      'consumption',
      'payment_options',
      'payment_options_override',
      'payment_options_settings_overrides',
      'contract_template_overrides',
      'line_items',
      'bills',
      'shadingOverride',
      'unstrungModulesInverterEfficiency',
      'basicPriceOverride',
      'commission_override_manually',
      'commission_id',
      'basicPriceOverridePerWatt',
      'tax_override_hardware',
      'tax_override_non_hardware',
      'snapshot',
      'show_customer',
      'is_current',
      'name',
      'autoString',
      'discount',
      'incentives',
      'incentive_to_installer',
      'incentive_to_customer',
      'non_solar_price_included',
      'non_solar_project_type',
      'milestone_payment_overrides',
      'force_enable_cashflow',
      'lender_battery_price_override',
      'adders_per_system',
      'adders_per_panel',
      'adders_per_watt',
      'pricing_scheme_id',
      'costing_override',
      'override_price_locking',
      'export_limit',
      'battery_control_scheme',
      'is_battery_dc_connected',
      'is_battery_dc_connected_auto',
      'load_offsettable_by_battery_fraction',
      'load_offsettable_by_battery_cap',
      'generation_override',
      'self_consumption_override',
      'mcs_self_consumption_calculator_override',
      'allow_shade_mitigation',
      'calculator',
      'utility_tariff_id_override',
      'integration_json',
      'systemPanelPlacement',
      'system_efficiency_override',
      'mounting',
      'mounting_type',
      'slots',
      'ibi_reduction_incentive',
      'sweden_green_deduction_incentive',
      'custom_data',
      'partner_accessories',
      'essential_backup_metrics',
      'weather_dataset_override',
    ]

    ObjectBehaviors.applyUserData.call(this, fields, this.userData)

    //shadingOverride requires special formatting
    if (!this.shadingOverride) {
      this.shadingOverride = []
    }

    if (this.userData.basicMode && this.basicModeCompatible()) {
      this.setBasicMode(true)
    }

    this.populateMessagesFromData()
  },

  getAlterDataFromObject: function (obj) {
    // We do not save/load id from API because it clashes with built-in read-only ThreeJS id property
    var keys = [
      'version',
      'output',
      'environmentals',
      'pricing',
      'additional_costs',
      'payment_options',
      'bills',
      'consumption',
      'override_price_locking',
    ]
    var alterData = {}
    keys.forEach(function (key) {
      if (obj.hasOwnProperty(key)) {
        alterData[key] = this[key]
      }
    }, this)
    return alterData
  },

  clearHashedArgs: function () {
    if (this.output) {
      this.output.hashed_args = null
    }
    if (this.environmentals) {
      this.environmentals.hashed_args = null
    }
    if (this.bills) {
      this.bills.hashed_args = null
    }
    if (this.pricing) {
      this.pricing.hashed_args = null
    }
    if (this.payment_options) {
      this.payment_options.forEach(function (payment_option) {
        payment_option.hashed_args = null
      })
    }
  },

  getHashedArgs: function () {
    return {
      pricing: this.pricing ? this.pricing.hashed_args : null,
      output: this.output ? this.output.hashed_args : null,
      environmentals: this.environmentals ? this.environmentals.hashed_args : null,
      bills: this.bills ? this.bills.hashed_args : null,
      payment_options:
        this.payment_options && this.payment_options.length > 0
          ? this.payment_options.map(function (payment_option) {
              return payment_option.hashed_args
            })
          : null,
    }
  },

  populateWithDataFromObject: function (obj, invertersUserData) {
    // We do not save/load id from API because it clashes with built-in read-only ThreeJS id property
    var keys = [
      'version',
      'output',
      'environmentals',
      'pricing',
      'additional_costs',
      'payment_options',
      'bills',
      'consumption',
      'override_price_locking',
    ]
    keys.forEach(function (key) {
      if (obj.hasOwnProperty(key)) {
        this[key] = obj[key]
      }
    }, this)

    // allow patching of integration_json with updates from calcs
    const keysToPatch = ['integration_json']
    keysToPatch.forEach((key) => {
      // only do the patch if an object is returned with at least one key
      if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) {
        const currentVal = this[key] || {}
        this[key] = { ...currentVal, ...obj[key] }
      }
    }, this)

    this.populateMessagesFromData()
  },

  simulateFirstYearOnly: function () {
    // Assume that the size of proposed bill calcs == 1 is sufficient to determine this
    // Which assumes that system lifetime is always longer than 1 year.
    // @TODO: Find a more robust method which will not fail when system lifetime == 1 year
    // One alternative could be to use Object.values(this.bills.proposed)[0].bills_yearly.length but that may not help
    // solve the problem with system lifetime == 1 year
    if (
      !this.bills ||
      !this.bills.proposed ||
      !Object.values(this.bills.proposed)[0] ||
      Object.values(this.bills.proposed)[0].years_to_simulate === 1
    ) {
      return true
    } else {
      return false
    }
  },

  populateMessagesFromData: function () {
    // Extract any messages from nested data and surface into system messages
    // This is the same location that we store Plugin messages
    var _system = this

    // Payment options store messages as a list where each item can store multiple messages with text/type
    if (this.payment_options) {
      // check this.payment_options beacuse this may be called before they are initiated

      var messageType = 'warning'
      var paymentOptionsMessageParts = []
      var paymentOptionPromptMessages = []

      this.payment_options.forEach((po) => {
        if (po.messages) {
          po.messages.forEach((message) => {
            if (['error', 'error_prompt'].includes(message.type)) {
              messageType = message.type
            }
            paymentOptionsMessageParts.push(message.text ? message.text : message.message)
            if (message.error_prompt_id) {
              message.payment_option_id = po.id
              paymentOptionPromptMessages.push(message)
            }
          })
        }
      })

      if (paymentOptionsMessageParts.length) {
        WorkspaceHelper?.addProjectErrorToReduxStore({
          message: paymentOptionsMessageParts.join('\n\n'),
          key: 'SYSTEM_PAYMENT_OPTION_MESSAGE',
          severity: messageType,
          systemId: _system.uuid,
          source: 'output',
          category: 'payment_option',
        })
      } else {
        window.WorkspaceHelper?.removeProjectErrorFromReduxStore(
          'SYSTEM_PAYMENT_OPTION_MESSAGE',
          _system.uuid,
          'output'
        )
      }

      if (paymentOptionPromptMessages.length) {
        // add payment option prompts to store
        paymentOptionPromptMessages.forEach((errorPrompt) => {
          window.WorkspaceHelper?.addProjectErrorPromptToReduxStore(
            errorPrompt.message,
            errorPrompt.error_prompt_id,
            errorPrompt.integration,
            _system.uuid,
            errorPrompt?.payment_option_id
          )
        })
      } else {
        window.WorkspaceHelper?.clearProjectErrorPrompts()
      }
    }

    // Output stores messages as a list of strings and we will assume they are all "warning" level

    if (this.output?.details?.system?.messages && this.output.details.system.messages.length > 0) {
      this.output.details.system.messages.forEach((message, messageIndex) => {
        WorkspaceHelper?.addProjectErrorToReduxStore({
          message,
          key: 'SYSTEM_OUTPUT_ERROR',
          severity: 'warning',
          systemId: _system.uuid,
          source: 'output',
          category: 'system',
        })
      })
    } else {
      window.WorkspaceHelper?.removeProjectErrorFromReduxStore('SYSTEM_OUTPUT_ERROR', _system.uuid, 'output')
    }
  },

  isSpecsReloadRequired() {
    if (!window.AccountHelper) {
      return true
    }
    if (window.AccountHelper.isLoaded() === false) {
      return true
    }
    const activatedModule = !!window.AccountHelper.loadedData.componentModuleSpecs.find(
      (item) => item.id === this.moduleId
    )
    if (!activatedModule) {
      return true
    }

    const inverterRequireReload = this.inverters().some((inverter) => {
      return !inverter.getComponentData()
    })

    if (inverterRequireReload) {
      return true
    }
    const batteryRequireReload = this.batteries().some((battery) => {
      return !battery.getComponentData()
    })

    if (batteryRequireReload) {
      return true
    }
    const otherRequireReload = this.others().some((other) => {
      return !other.getComponentData()
    })

    if (otherRequireReload) {
      return true
    }

    return false
  },

  refreshDesignComponentSpecs() {
    this.reloadModuleSpecs()
    //reloadSpecs by applyUserData
    this.inverters().forEach((i) => i.applyUserData())
    this.batteries().forEach((b) => b.applyUserData())
    this.others().forEach((o) => o.applyUserData())
  },

  getPerformanceCalculator: function () {
    if (this.calculator) {
      return this.calculator
    } else {
      return WorkspaceHelper && WorkspaceHelper.getDefaultPerformanceCalculator()
    }
  },

  raytracedShadingAvailable: function () {
    // SAM and MCS has raytracing enabled.
    //
    // 3rd Party currently assumes raytracing IS enabled - even though we may use the pre-calculated output values
    // we currently assume we still want to run shading simulations to drive external output calcs etc.
    // In future we may need two types or some other way to indicate that shade simulations shoulds till run
    // even if we don't need them for calculating output.
    const calculatorWithRaytrace = [2, 3, 5, 6]
    return calculatorWithRaytrace.includes(this.getPerformanceCalculator()) && !this.isRaytracedShadingDisabled()
  },

  hasRaytracedShading: function () {
    return this.moduleGrids().some((mg) => mg.hasRaytracedShading())
  },

  allModuleGridsAreOnTiltRacks: function () {
    // is the tilt > 0 a necessary check?
    return this.moduleGrids().every((mg) => mg.isUsingTiltRacks() && Math.abs(mg.getRacksTilt()) > 0)
  },

  isBifacialAndAllPanelsOnTiltRacks: function () {
    return this.isBifacial() && this.allModuleGridsAreOnTiltRacks()
  },

  isBifacial: function () {
    return this.moduleType()?.bifaciality > 0
  },

  isTracking: function () {
    /*
    Return true if any module grids have any kind of tracking enabled
    */
    return this.moduleGrids().some((mg) => mg.trackingMode() > 0)
  },

  isRaytracedShadingDisabled: function () {
    return this.isBifacialAndAllPanelsOnTiltRacks() || this.isTracking()
  },

  clearElectricals: function (editor) {
    //clone so we don't delete the array we're deleting from
    var children = this.children.slice(0)
    var removedChildren = []
    children.forEach(function (child) {
      if (child.type === 'OsInverter') {
        //this.remove(child);
        editor.removeObject(child)
        removedChildren.push(child)
      }
    }, this)
    return removedChildren
  },

  clearModules: function (editor) {
    //clone so we don't delete the array we're deleting from
    var children = this.children.slice(0)
    children.forEach(function (child) {
      if (child.type === 'OsModule' || child.type === 'OsModuleGrid') {
        //this.remove(child);
        editor.removeObject(child)
      }
    }, this)
    this.modules = []
  },

  moduleQuantity: function (fromStringsOrAll) {
    if (fromStringsOrAll === 'strings') {
      return this.inverters()
        .map(function (i) {
          return i.moduleQuantity()
        })
        .reduce(function (a, b) {
          return a + b
        }, 0)
    } else {
      //default
      return this.moduleGrids()
        .map(function (mg) {
          return mg.moduleQuantity()
        })
        .reduce(function (a, b) {
          return a + b
        }, 0)
    }
  },

  unstrungModulesByModuleGrid: function () {
    var _unstrungModulesByModuleGrid = []
    var strungModuleUuids = this.getStrungModules().map(function (m) {
      return m.uuid
    })
    var moduleSize = this.moduleType().size
    this.moduleGrids().forEach(function (mg) {
      var unstrungModulesInThisGrid = []
      mg.getModules().forEach(function (m) {
        if (strungModuleUuids.indexOf(m.uuid) === -1) {
          unstrungModulesInThisGrid.push({
            uuid: m.uuid,
            cell: m.cell,
            slope: mg.getPanelTilt(),
            racks: mg.getRacksTilt(),
            azimuth: m.getAzimuth(),
            shadingOverride: m.detectInheritedShadingOverride(),
            size: moduleSize,

            // Lookup tilt racks from moduleGrid instead of m.userData.use_tilt_rack
            // because we don't trust userData which might be out stale at this point
            // whereas mg.panelConfiguration is reliable
            use_tilt_rack: mg.isUsingTiltRacks(),

            dualTiltSubset: m.getAzimuthalSubset(),
          })
        }
      })
      if (unstrungModulesInThisGrid.length > 0) {
        //This moduleGrid has some unstrung modules, add it

        mg.refreshUserData()

        var modulesFront = mg.isDualTilt()
          ? unstrungModulesInThisGrid.filter((m) => m.dualTiltSubset === OsModuleGrid.AzimuthalSubsets.Front)
          : unstrungModulesInThisGrid
        var modulesBack = mg.isDualTilt()
          ? unstrungModulesInThisGrid.filter((m) => m.dualTiltSubset === OsModuleGrid.AzimuthalSubsets.Back)
          : []

        // separate the collection of panels facing in each direction
        _unstrungModulesByModuleGrid.push({
          uuid: mg.uuid,
          trackingMode: mg.trackingMode(),
          slope: mg.getPanelTilt(), //we may want to adjust this to account for underlying slope??
          azimuth: mg.getAzimuth(),
          modules: modulesFront,
          modulesPerRow: mg.modulesPerRow(),
          gcr: mg.calculateGroundCoverageRatio(),
          bifacialTransmissionFactor: mg.calculateBifacialTransmissionFactor(),
          groundClearance: mg.groundClearance(),
          diffuseShading: mg.diffuseShading,
        })

        if (modulesBack.length > 0) {
          // panels facing opposite the indicated module grid direction

          _unstrungModulesByModuleGrid.push({
            uuid: mg.uuid + '-back',
            trackingMode: mg.trackingMode(),
            slope: mg.getPanelTilt(), //we may want to adjust this to account for underlying slope??
            azimuth: (mg.getAzimuth() + 180) % 360,
            modules: modulesBack,
            modulesPerRow: mg.modulesPerRow(),
            gcr: mg.calculateGroundCoverageRatio(),
            bifacialTransmissionFactor: mg.calculateBifacialTransmissionFactor(),
            groundClearance: mg.groundClearance(),
            diffuseShading: mg.diffuseShadingBack,
          })
        }
      }
    }, this)
    return _unstrungModulesByModuleGrid
  },

  getUnstrungModules: function () {
    return this.moduleGrids()
      .map((a) => a.getModules())
      .flat()
      .filter((m) => !m.assignedOsString)
  },

  refreshUnstrungModulesMarker: function () {
    const visible = Designer._unstrungModuleDotVisibility
    if (visible && this.hasIncompleteStringing()) {
      this.getModules().forEach(function (osModule) {
        osModule.refreshDotMarker(editor)
      })
    } else {
      editor.filter('type', 'OsDotMarker').forEach(function (dotMarker) {
        editor.removeObject(dotMarker, false)
      })
    }
  },

  hasIncompleteStringing: function () {
    //If there is one or more inverters in the system AND there is ANY stringing done AND there are unstrung panels
    if (this.inverters()?.length > 0) {
      const hasStrungModules = this.moduleQuantity() - this.getUnstrungModules().length > 0
      return hasStrungModules && this.getUnstrungModules().length > 0
    } else {
      return false
    }
  },

  stringingIsComplete: function () {
    return this.moduleQuantity() === 0 || this.moduleQuantity('strings') === this.moduleQuantity(false)
  },

  validateSamSpecs: function () {
    var results = { modules: [], inverters: [] }

    if (this.getPerformanceCalculator() === 2) {
      // Validate module specs
      results.modules = window.ModuleType.validateSamSpecs(this.moduleType())
      if (results.modules.length > 0) {
        window.WorkspaceHelper.addProjectErrorToReduxStore({
          message:
            'The selected module component in your design has not been configured for System Advisor Model (SAM). ' +
            'Please email 3D@opensolar.com with the hardware in question, and it will be promptly configured for System Advisor Model (SAM).',
          key: 'SAM_MODULE_VALIDATION_ERROR',
          severity: 'error',
          systemId: this.uuid,
          source: 'sam_validation',
          category: 'system',
          field: 'design',
        })
      } else {
        window.WorkspaceHelper.removeProjectErrorFromReduxStore(
          'SAM_MODULE_VALIDATION_ERROR',
          this.uuid,
          'sam_validation'
        )
      }

      this.inverters().forEach((inverterType) => {
        if (results.inverters.length === 0) {
          results.inverters = window.InverterType.validateSamSpecs(inverterType)
        }
      })
      if (results.inverters.length > 0) {
        window.WorkspaceHelper.addProjectErrorToReduxStore({
          message:
            'An inverter in your design has not been configured for System Advisor Model (SAM). ' +
            'If you are using a custom component, please ensure all fields have been specified in Control > Design & Hardware. ' +
            'If you are using standard components from the OpenSolar database, please email 3D@opensolar.com with the hardware in question, ' +
            'and it will be promptly configured for System Advisor Model (SAM).',
          key: 'SAM_INVERTER_VALIDATION_ERROR',
          severity: 'error',
          systemId: this.uuid,
          source: 'sam_validation',
          category: 'system',
          field: 'design',
        })
      } else {
        window.WorkspaceHelper.removeProjectErrorFromReduxStore(
          'SAM_INVERTER_VALIDATION_ERROR',
          this.uuid,
          'sam_validation'
        )
      }
    } else {
      // Clean up any existing validation messages if we're not using SAM calculator
      window.WorkspaceHelper.removeProjectErrorFromReduxStore(
        'SAM_MODULE_VALIDATION_ERROR',
        this.uuid,
        'sam_validation'
      )
      window.WorkspaceHelper.removeProjectErrorFromReduxStore(
        'SAM_INVERTER_VALIDATION_ERROR',
        this.uuid,
        'sam_validation'
      )
    }

    return results
  },

  validate: function () {
    var result = {
      errors: [],
      warnings: [],
    }
    if (!this.stringingIsComplete()) {
      result.warnings.push('Strings incomplete')
    }
    return result
  },

  kwStc: function () {
    return this.moduleQuantity('all') * this.moduleType()['kw_stc']
  },

  batteryTotalKwh: function () {
    return this.batteries()
      .map(function (battery) {
        const batteryQuantity = battery.quantity ?? 1
        return parseFloat(battery.kwh_optimal * batteryQuantity)
      })
      .reduce(function (a, b) {
        return a + b
      }, 0)
  },

  getDistributedGenerationRules: function () {
    const proposedBills = this.bills?.proposed
    if (proposedBills && Object.values(proposedBills).length > 0) {
      return Object.values(proposedBills)[0].distributed_generation_rules
    }
    return null
  },

  batteryTotalUsableKwh: function () {
    return this.batteries()
      .map(function (battery) {
        const batteryQuantity = battery.quantity ?? 1
        return (
          parseFloat(battery.kwh_optimal) * parseFloat(battery.depth_of_discharge_factor) * parseFloat(batteryQuantity)
        )
      })
      .reduce(function (a, b) {
        return a + b
      }, 0)
  },

  getSystem: function () {
    return this
  },

  moduleGrids: function () {
    //return editor.filter('type','OsModuleGrid', false, this);
    return this.children
      .map(function (c) {
        return c.type === 'OsModuleGrid' ? c : null
      })
      .filter(Boolean)
  },
  /*
   return undefined or cogsOverride object
   this also responsible for converting legacy cogsOverride to new format
  */
  getCogsOverride() {
    const cogsOverride = this.integration_json?.generic?.component_overrides

    if (!cogsOverride) return undefined

    if (Object.keys(cogsOverride).length > 0) {
      for (const [key, value] of Object.entries(cogsOverride)) {
        if (value === null) {
          // Remove any `null` values from the object
          delete cogsOverride[key]
        } else if (typeof value !== 'object') {
          // If we have a legacy integration_json that is not an object, we should convert it to the new format
          cogsOverride[key] = { price: value, source: 'unknown', code: 'unknown' }
        }
      }
    }

    return cogsOverride
  },
  setComponentCostOverride: function (
    componentActivation,
    price,
    overrideSource = 'unknown', // This is the source of the override, e.g. 'segen', 'outlet', 'manual', 'unknown'
    isLastChange = false // We apply the override in batches. We only want to trigger objectChanged once.
  ) {
    /*
    TODO check this is still a valid comment. 
    Note that the data type of priceOverride is very important
    - priceOverride === null indicates an existing override should be cleared and we should revert to the distributor price, if available.
    - priceOverride === undefined indicates no override is being supplied so do not modify it if already populated.
    */

    // add empty object if it does not exist
    if (!this.integration_json?.generic?.component_overrides) {
      const integration_json = this.integration_json || {}
      integration_json['generic'] = integration_json.generic || {}
      integration_json['generic']['component_overrides'] = {}
      this.integration_json = integration_json
    }

    // Ensure that if this is called with an invalid/empty componentActivation it does not crash
    if (componentActivation?.id) {
      const cogsOverride = this.getCogsOverride()
      const existingOverride = cogsOverride?.[String(componentActivation.id)]
      var newOverride = null

      if (overrideSource === 'manual' && existingOverride?.price !== price) {
        newOverride = {
          price,
          source: overrideSource,
          code: componentActivation.code,
        }
      } else if (
        cogsOverride[String(componentActivation.id)]?.price !== price ||
        cogsOverride[String(componentActivation.id)]?.source !== overrideSource
      ) {
        newOverride = {
          price,
          source: overrideSource,
          code: componentActivation.code,
        }
      }

      // Apply new override if source OR price has changed.
      if (newOverride) {
        cogsOverride[String(componentActivation.id)] = newOverride

        this.applyComponentOverridesToAllMatchingCodes(
          newOverride.price,
          newOverride.source,
          newOverride.code,
          parseInt(componentActivation.id)
        )
        // }
      }
      if (this.parent && isLastChange) {
        // Do not trigger objectChanged in the constructor
        window.editor.signals.objectChanged.dispatch(this)
      }
    }
  },
  applyComponentOverridesToAllMatchingCodes: function (price, source, code, componentActivationId) {
    // Unfortunately we can have duplicate component activations with the same code
    // Therefore, we should copy these overrides into all matching component activation code
    // Otherwise we may only update one of the duplicates which will result in the overrides
    // not being applied to the activation that we are actually using.

    try {
      // Unfortunately this is very inefficient, but duplicate activations can affect any type of component
      let includeArchived = true
      let allComponentActivations = []
        .concat(AccountHelper.getComponentModuleSpecsAvailable(includeArchived))
        .concat(AccountHelper.getComponentInverterSpecsAvailable(includeArchived))
        .concat(AccountHelper.getComponentBatterySpecsAvailable(includeArchived))
        .concat(AccountHelper.getComponentOtherSpecsAvailable(includeArchived))

      let componentActivation = allComponentActivations.find(
        (_componentActivation) => _componentActivation.id === componentActivationId
      )
      const cogsOverride = this.getCogsOverride()

      // get code based on activation id so we can use to copy across into other activations with the same code
      // @TODO: We are not checking component type here because we dont actually know it
      // Since this is a temporary fix we will hope that we never have two activations with the same ID with different types.
      let code = componentActivation?.code
      if (code && cogsOverride) {
        allComponentActivations.forEach((possibleDuplicateComponentActivation) => {
          // Aggressively override any existing overrides with the same code
          if (possibleDuplicateComponentActivation.code === code) {
            cogsOverride[String(possibleDuplicateComponentActivation.id)] = {
              price,
              source,
              code,
            }
          }
        })
      }
    } catch (e) {
      console.error(e)
    }
  },
  removeComponentCostOverride: function (componentActivation) {
    if (componentActivation?.id) {
      const cogsOverride = this.getCogsOverride()
      if (cogsOverride && cogsOverride[componentActivation.id]) {
        delete cogsOverride[componentActivation.id]
      }
    }
  },
  refreshComponentCostOverrides: function (bomLineItems) {
    /*
    Only refresh prices for existing overrides, do not add additional overrides.
    */
    const existingCogsOverride = this.getCogsOverride()

    if (existingCogsOverride) {
      bomLineItems.forEach((lineItem) => {
        const componentId = lineItem.data?.id

        if (existingCogsOverride[componentId]) {
          const afterDiscountPrice = lineItem.getBestDiscountOffer()?.afterDiscount
          if (afterDiscountPrice) {
            const pricePerItem = afterDiscountPrice / lineItem.quantity
            this.integration_json.generic.component_overrides[componentId] = {
              price: pricePerItem,
              source: lineItem.selectedDistributorOrderingData?.distributor || 'unknown',
            }
          }
        }
      })
      editor.signals.objectChanged.dispatch(this)
    }
  },

  clearComponentCostOverrides: function () {
    if (this.integration_json?.generic?.component_overrides) {
      this.integration_json.generic.component_overrides = {}
      editor.signals.objectChanged.dispatch(this)
    }
  },

  detectModuleId: function () {
    if (OsSystem.moduleIdDefault) {
      //if default is explicitly set then use it directly without other dependencies
      return OsSystem.moduleIdDefault
    } else if (AccountHelper) {
      return AccountHelper.getModuleTypeDefault().id
    } else {
      //This will cause an error but we're really struggling if we don't even have AccountHelper
      return null
    }
  },

  moduleType: function (id = null) {
    let moduleType = this.module_type
    if (AccountHelper.loadedDataReady.componentModuleSpecs) {
      moduleType = AccountHelper.getModuleData(id ? id : this.moduleId)
    }

    if (!moduleType) {
      if (this.module_type) {
        // If the new lookup failed and it was previously populated, leave it unchanged
        return this.module_type
      } else {
        // If this.module_type is not available as a fallback then just return a dummy 'Unknown' moduleType
        // which will at least be in a valid format
        return AccountHelper.getModuleData(-1)
      }
    }
    return moduleType.code === 'Unknown' && this.module_type ? this.module_type : moduleType
  },

  setModuleTypeByModuleId: function (moduleId, forceUpdate) {
    // Avoid clearing raytraced shading unless we are sure that moduleId is changing on this update
    // otherwise we may clear shading incorrectly and also cause other errors, like failed output calcs.
    var doClearRaytracedShading = moduleId && this.moduleId && moduleId !== this.moduleId

    if (moduleId === this.moduleId && !forceUpdate) {
      console.log('Ignoring OsSytem.setModuleTypeByModuleId(). moduleId is unchanged')
      return
    }

    var moduleType = this.moduleType(moduleId)

    if (moduleType.id) {
      this.moduleId = moduleType.id
    } else {
      // If we cannot find the matching module data just use the supplied moduleId so the assignment will not be lost
      // when the components load later. This is particularly important for solarZero initData for UX2-only
      console.warn('Warning: Attempting to apply moduleId to the system but not matching module data was found (yet)')
      this.moduleId = moduleId
    }

    if (moduleId) {
      this.moduleGrids().forEach(function (mg) {
        mg.setSize(moduleType.size)
        mg.moduleTexture(moduleType.module_texture)

        // @TODO: We could speed this up by checking to see whether the cell positions have actually
        // changed and only clear if changed, but benefit will be minimal.
        if (doClearRaytracedShading) {
          mg.clearRaytracedShadingForModules()
        }

        mg.refreshUserData()
      })
    } else {
      console.log('Abort setModuleTypeByModuleId: moduleId not set')
    }
  },

  clearRaytracedShading: function () {
    this.moduleGrids().forEach(function (mg) {
      mg.clearRaytracedShadingForModules()
      mg.refreshUserData() //@TODO: Is calling refreshUserData crazy slow to do every frame of a drag?
      mg.saveHash()
    })
    window.Designer.requestSystemCalculations(this)
  },

  maxPanelsPerOptimizer: function () {
    var dcOptimizer = this.dcOptimizer()
    if (dcOptimizer) {
      // Beware: PC Optimizer is in Watts but module max power is in kW

      // Maximum number of panels per string of optimisers
      // = Maximum power per string (optimiser)/panel power
      // Only if optimizer and modules have sufficient specs
      var maxPanelsForPowerConstraint =
        dcOptimizer.dc_optimizer_max_input_power && this.moduleType().kw_stc > 0
          ? Math.floor(dcOptimizer.dc_optimizer_max_input_power / (this.moduleType().kw_stc * 1000))
          : 1

      // Number of panels in series per optimiser
      // max input voltage <= Max Voc (lowest temp) of panels
      // Only if optimizer and modules have sufficient specs
      var minimumTemperature = 0
      var maxPanelsInSeries =
        dcOptimizer.dc_optimizer_max_input_voltage && this.moduleType().voltage(minimumTemperature) > 0
          ? Math.floor(dcOptimizer.dc_optimizer_max_input_voltage / this.moduleType().voltage(minimumTemperature))
          : null

      // Number of panels in parallel per optimiser
      // maximum input short circuit current <= Max Input current of panels
      var maxPanelsInParallel =
        dcOptimizer.dc_optimizer_max_input_current && this.moduleType().isc
          ? Math.floor(dcOptimizer.dc_optimizer_max_input_current / this.moduleType().isc)
          : null

      var maxPanelsPerOptimizer =
        maxPanelsInSeries && maxPanelsInParallel
          ? Math.min(maxPanelsForPowerConstraint, maxPanelsInSeries * maxPanelsInParallel)
          : maxPanelsForPowerConstraint

      return maxPanelsPerOptimizer
    } else {
      return 0
    }
  },

  refreshDcOptimizerEfficiencyAndQuantity: function () {
    // Find first dcOptimizer from other components
    var dcOptimizer = this.dcOptimizer()
    if (dcOptimizer) {
      // @TODO: Beware if there is no optimizer this currently returns null
      // We need to decide whether to just set quantity to 0
      // We should probably not remove the optimizer from the system because that could cause unexpected problems.
      var maxPanelsPerOptimizer = this.maxPanelsPerOptimizer()
      // We are not absolutely confident in our calculations. While a value of 0 indicates
      // that the optimizer is incorrectly sized, we will assume it is 1-optimizer-per-panel
      // to avoid blocking this scenario.
      if (!maxPanelsPerOptimizer) {
        console.log('Warning: maxPanelsPerOptimizer = 0. Adjusting to 1-optimizer-per-panel')
        maxPanelsPerOptimizer = 1
      }
      dcOptimizer.quantity = Math.ceil(this.moduleQuantity() / maxPanelsPerOptimizer)
    }
  },

  pricingScheme: function () {
    return AccountHelper.getPricingSchemeById(this.pricing_scheme_id)
  },

  getName: function () {
    if (this.name && this.name.length > 0) {
      return this.name
    } else {
      var hasPanels = this.kwStc() > 0
      var hasBattery = this.batteryTotalKwh() > 0

      if (hasPanels && hasBattery) {
        return (
          window.roundToDecimalPlaces(this.kwStc(), 3) +
          'kW System with ' +
          window.roundToDecimalPlaces(this.batteryTotalKwh(), 3) +
          ' kWh Battery'
        )
      } else if (hasPanels) {
        return window.roundToDecimalPlaces(this.kwStc(), 3) + ' kW System'
      } else if (hasBattery) {
        return window.roundToDecimalPlaces(this.batteryTotalKwh(), 3) + ' kWh Battery'
      } else {
        return 'Empty System'
      }
    }
  },

  getUnstrungInverters: function () {
    return this.inverters().filter(function (inverter) {
      return inverter.moduleQuantity() === 0
    })
  },

  getSummary: function () {
    var showManufacturer = false
    //
    // DEPRECATED: No longer saved into system_data, we calculate it dynamically from system_data
    //
    //For String Inverters
    //moduleId x ModuleQuantity
    //InverterCode (Mppt1, Mppt2)
    //
    //e.g.
    //MOD1231 x 10
    //INVERTER_ABC (8x2, 6,3)
    //
    //For Microinverters
    //Only show first inverter for the system
    //No difference if systems is fully/partially/not strung.
    //Inverter manufacturer_name plus code plus x{ModuleQuantity}
    //e.g. Manufac INV109213 x12

    var summaryParts = []

    summaryParts.push(this.getName())

    var moduleType = this.moduleType()

    //Hide all stringing details if stringing is incomplete (otherwise some inverters may show strings and others not)
    var skipStringSummary = !this.stringingIsComplete()

    if (moduleType && moduleType.id > 0) {
      summaryParts.push(
        (showManufacturer ? moduleType.manufacturer_name + ' ' : '') +
          moduleType.code +
          ' x ' +
          this.moduleQuantity('all')
      )
    }

    // if (this.inverters().length > 0 && this.inverters()[0].microinverter) {
    //   //micro inverters
    //   summaryParts.push(
    //     this.inverters()[0].manufacturer_name +
    //       ' ' +
    //       this.inverters()[0].code +
    //       ' x ' +
    //       this.moduleQuantity('all') +
    //       ','
    //   )
    // } else {
    //   //string inverters
    //   summaryParts = summaryParts.concat(
    //     this.inverters().map(function (i) {
    //       return i.getSummary(skipStringSummary)
    //     })
    //   )
    // }

    skipStringSummary = true
    var inverterSummariesGrouped = {}
    this.inverters().forEach(function (inverter) {
      var inverterSummary = inverter.getSummary(showManufacturer, skipStringSummary)
      var quantity

      if (inverter.microinverter) {
        quantity = inverter.moduleQuantity()
          ? Math.ceil(inverter.moduleQuantity() / inverter.mppt_quantity)
          : Math.ceil((this.moduleQuantity() - this.getStrungModules().length) / inverter.mppt_quantity)
      } else {
        quantity = 1
      }

      if (!inverterSummariesGrouped[inverterSummary]) {
        inverterSummariesGrouped[inverterSummary] = quantity
      } else {
        inverterSummariesGrouped[inverterSummary] += quantity
      }
    }, this)

    var inverterParts = []
    for (let key in inverterSummariesGrouped) {
      inverterParts.push(key + ' x ' + inverterSummariesGrouped[key])
    }

    summaryParts = summaryParts.concat(inverterParts)

    var batterySummariesGrouped = {}
    this.batteries().forEach(function (battery) {
      var batterySummary = battery.getSummary(showManufacturer)
      if (!batterySummariesGrouped[batterySummary]) {
        batterySummariesGrouped[batterySummary] = 1
      } else {
        batterySummariesGrouped[batterySummary] += 1
      }
    }, this)

    var batteryParts = []
    for (let key in batterySummariesGrouped) {
      batteryParts.push(key + ' x ' + batterySummariesGrouped[key])
    }

    summaryParts = summaryParts.concat(batteryParts)

    //Add notes for tilt racks to summary
    var tiltRackPanelQuantity = this.moduleGrids()
      .map(function (mg) {
        return mg.isUsingTiltRacks() ? mg.moduleQuantity() : 0
      })
      .reduce(function (a, b) {
        return a + b
      }, 0)

    if (tiltRackPanelQuantity > 0) {
      if (tiltRackPanelQuantity === this.moduleQuantity('all')) {
        summaryParts.push(' Tilt Racks.')
      } else {
        summaryParts.push(' Tilt Racks (' + tiltRackPanelQuantity + ' panels only).')
      }
    }

    // var annualOutput = this.output && this.output.annual > 0 ? Math.round(this.output.annual) + ' kWh' : 'TBC'
    // summaryParts.push('Annual Output: ' + annualOutput)
    // var systemPrice =
    //   this.pricing && this.pricing.system_price_including_tax
    //     ? '$' + Math.round(this.pricing.system_price_including_tax)
    //     : 'TBC'
    // var systemTax = this.pricing && this.pricing.tax ? '$' + Math.round(this.pricing.tax) : 'TBC'
    // summaryParts.push('Price: ' + systemPrice + ' (Tax: ' + systemTax + ')')
    // }

    return summaryParts.join('\n')
  },

  getChildren: function () {
    return this.inverters()
  },

  getModules: function () {
    var modules = []

    this.moduleGrids().forEach(function (mg) {
      mg.getModules().forEach(function (m) {
        modules.push(m)
      }, this)
    }, this)

    return modules
  },

  getStrings: function (inverterFilterFunc) {
    if (!inverterFilterFunc) {
      inverterFilterFunc = () => true
    }

    var osStrings = []

    this.inverters()
      .filter(inverterFilterFunc)
      .forEach(function (inverter) {
        inverter.mppts().forEach(function (mppt) {
          mppt.strings().forEach(function (osString) {
            osStrings.push(osString)
          })
        })
      })
    return osStrings
  },

  getStrungModules: function () {
    var results = []
    this.getStrings().forEach(function (s) {
      s.modules.forEach(function (m) {
        results.push(m)
      })
    })
    return results
  },

  cancelHighlightModuleGrid: () => {}, // set initially to no-op

  highlightModuleGridByUuid: function (uuid, opts = {}) {
    this.cancelHighlightModuleGrid() // ensures that we cleanup any active highlight sessions

    const moduleGridsInSystem = this.moduleGrids()

    const { targetModuleGrid, otherModuleGrids } = moduleGridsInSystem.reduce(
      (result, current) => {
        if (current.uuid === uuid) {
          result.targetModuleGrid = current
        } else {
          result.otherModuleGrids.push(current)
        }
        return result
      },
      { targetModuleGrid: null, otherModuleGrids: [] }
    )

    // abort if the target module grid is not found
    // OR if there are no other module grids, in this case
    // we don't have anything to highlight the target module grid against
    if (!targetModuleGrid || otherModuleGrids.length === 0) return

    const setMaterialProps = (material, transparent, opacity) => {
      material.transparent = transparent
      material.opacity = opacity
      material.needsUpdate = true
    }

    const reversionProcedure = []

    // STEP 1: reduce the opacity of all module grids in the system
    moduleGridsInSystem.forEach((mg) => {
      const modulesInModuleGrid = mg.getModules()
      const sampleModule = modulesInModuleGrid[0]
      if (!sampleModule || !sampleModule?.material) return

      if (Array.isArray(sampleModule.material)) {
        // multiple materials
        sampleModule.material.forEach((material) => {
          setMaterialProps(material, true, 0.3)
          reversionProcedure.push(() => {
            setMaterialProps(material, false, 1.0)
          })
        })
      } else {
        // single material (is this even a valid state?)
        setMaterialProps(sampleModule.material, true, 0.3)
        reversionProcedure.push(() => {
          setMaterialProps(sampleModule.material, false, 1.0)
        })
      }
    })

    // STEP 2: clone the module material used in the target module grid
    // and bring the opacity for that material back up
    // to highlight the target module grid against the others
    const modulesInTarget = targetModuleGrid.getModules()
    const sampleModule = modulesInTarget[0]

    if (sampleModule?.material) {
      const originalModuleMaterial = sampleModule.material

      if (Array.isArray(sampleModule.material)) {
        const materialsCopy = sampleModule.material.map((mat) => mat.clone())
        materialsCopy.forEach((material) => {
          setMaterialProps(material, false, 1.0)
        })
        modulesInTarget.forEach((module) => (module.material = materialsCopy))
      } else {
        const materialCopy = sampleModule.material.clone()
        setMaterialProps(materialCopy, false, 1.0)
        modulesInTarget.forEach((module) => (module.material = materialCopy))
      }

      reversionProcedure.push(() => {
        modulesInTarget.forEach((module) => {
          module.material = originalModuleMaterial
        })
      })
    }

    if (opts.render === true) {
      window.editor.render()
    }

    this.cancelHighlightModuleGrid = (opts = {}) => {
      reversionProcedure.forEach((func) => func())
      if (opts.render === true) {
        window.editor.render()
      }
      reversionProcedure.length = 0
    }

    return this.cancelHighlightModuleGrid
  },

  getModulesSortedForStinging: function (moduleUuids) {
    var modules = this.getModules().filter((o) =>
      moduleUuids && moduleUuids.length > 0 ? moduleUuids.includes(o.uuid) : true
    )

    // Super-simple fix for bad string layouts > sort modules:
    // for each cluster:
    // draw back-and-forth snake path over the bounding box starting from top left and moving along the row first
    // before moving down to the next column, alternative the direction when moving to the next row.
    //
    // start----|
    //          |
    // |--------|
    // |
    // |----- end
    var score = (_module) => {
      var [x, y] = _module.getCellCoordinates()
      var xScore = y % 2 === 0 ? x : -1 * x
      var yScore = y
      // avoid negative nubmers, add zero padding to ensure sorting as string works
      var xScorePadded = String(xScore + 1000).padStart(6, '0')
      var yScorePadded = String(yScore + 1000).padStart(6, '0')

      return `${_module.parent.uuid}_${yScorePadded}_${xScorePadded}`
    }
    modules.sort((a, b) => {
      return score(a).localeCompare(score(b))
    })
    return modules
  },

  refreshStrings: function (editor) {
    editor.uiPause('render', 'OsSystem.refreshStrings')
    this.getStrings().forEach(function (osString) {
      osString.refreshLine(editor)
    })
    editor.uiResume('render', 'OsSystem.refreshStrings')
  },

  hasShadingOverride: function () {
    return this.shadingOverride && this.shadingOverride.length > 0
  },

  getShading: function () {
    if (this.shadingOverride.join() !== '') {
      return this.shadingOverride
    } else {
      return []
    }
  },

  isBasicMode: function () {
    return this._basicMode
  },
  clearComponents: function (commandUUID) {
    while (this.moduleGrids().length > 0) {
      window.editor.execute(new RemoveObjectCommand(this.moduleGrids()[0], true, false, commandUUID))
    }
    while (this.inverters().length > 0) {
      window.editor.execute(new RemoveObjectCommand(this.inverters()[0], true, false, commandUUID))
    }
    while (this.batteries().length > 0) {
      window.editor.execute(new RemoveObjectCommand(this.batteries()[0], true, false, commandUUID))
    }
    while (this.others().length > 0) {
      window.editor.execute(new RemoveObjectCommand(this.others()[0], true, false, commandUUID))
    }
  },
  setBasicMode: function (value) {
    if (value !== this._basicMode) {
      if (value === true) {
        if (this.basicModeCompatible()) {
          window.editor.execute(new SetValueCommand(this, '_basicMode', value))
          if (editor) editor.signals.objectChanged.dispatch(this, 'skipRecalc')
        } else {
          if (this.pricing && this.pricing.system_price_including_tax) {
            this.basicPriceOverride !== this.pricing.system_price_including_tax &&
              window.editor.execute(
                new SetValueCommand(this, 'basicPriceOverride', this.pricing.system_price_including_tax)
              )
          }
          this.discount !== 0 && window.editor.execute(new SetValueCommand(this, 'discount', 0))
          this.non_solar_price_included !== 0 &&
            window.editor.execute(new SetValueCommand(this, 'non_solar_price_included', 0))
          this.adders_per_system !== 0 && window.editor.execute(new SetValueCommand(this, 'adders_per_system', 0))
          this.adders_per_panel !== 0 && window.editor.execute(new SetValueCommand(this, 'adders_per_panel', 0))
          this.adders_per_watt !== 0 && window.editor.execute(new SetValueCommand(this, 'adders_per_watt', 0))

          // Strip all data incompatible with basic mode
          while (this.inverters().length > 0) {
            window.editor.execute(new RemoveObjectCommand(this.inverters()[0], true))
          }
          while (this.batteries().length > 0) {
            window.editor.execute(new RemoveObjectCommand(this.batteries()[0], true))
          }
          while (this.others().length > 0) {
            window.editor.execute(new RemoveObjectCommand(this.others()[0], true))
          }
          this.payment_options_override !== null &&
            window.editor.execute(new SetValueCommand(this, 'payment_options_override', null))

          this.payment_options_settings_overrides &&
            Object.keys(this.payment_options_settings_overrides).length > 0 &&
            window.editor.execute(new SetValueCommand(this, 'payment_options_settings_overrides', {}))

          this.contract_template_overrides &&
            Object.keys(this.contract_template_overrides).length > 0 &&
            window.editor.execute(new SetValueCommand(this, 'contract_template_overrides', {}))

          this.line_items &&
            this.line_items.length > 0 &&
            window.editor.execute(new SetValueCommand(this, 'line_items', []))
          this.incentives !== null && window.editor.execute(new SetValueCommand(this, 'incentives', null))
          this.autoString !== false && window.editor.execute(new SetValueCommand(this, 'autoString', false))
          this.pricing_scheme_id !== null && window.editor.execute(new SetValueCommand(this, 'pricing_scheme_id', null))
          window.editor.execute(new SetValueCommand(this, '_basicMode', value))
          if (editor) editor.signals.objectChanged.dispatch(this)
        }
      } else {
        // If no basicPriceOverride is set then switch to using the default pricing scheme instead of keeping manual
        // price entry mode and copying over the empty/old override.
        if (this.basicPriceOverride) {
          // keep manual pricing
        } else {
          window.editor.execute(
            new SetValueCommand(this, 'pricing_scheme_id', AccountHelper.getPricingSchemeDefaultId())
          )
        }
        window.editor.execute(new SetValueCommand(this, '_basicMode', value))
        if (editor) editor.signals.objectChanged.dispatch(this, 'skipRecalc')
      }
    }
  },

  basicModeCompatible: function () {
    if (
      this.inverters().length > 0 ||
      this.batteries().length > 0 ||
      this.others().length > 0 ||
      (this.line_items && this.line_items.length > 0) ||
      this.payment_options_override ||
      (this.payment_options_settings_overrides && Object.keys(this.payment_options_settings_overrides).length > 0) ||
      (this.contract_template_overrides && Object.keys(this.contract_template_overrides).length > 0) ||
      this.incentives ||
      this.autoString === true ||
      this.pricing_scheme_id ||
      this.discount ||
      this.non_solar_price_included ||
      this.adders_per_system ||
      this.adders_per_panel ||
      this.adders_per_watt
    ) {
      return false
    } else {
      return true
    }
  },

  dcOptimizer: function (otherId) {
    if (typeof otherId !== 'undefined') {
      // Automatically adds another component if required, or replaces an existing optimizer if already present
      var dcOptimizer = this.dcOptimizer()

      if (otherId) {
        var otherType = window.AccountHelper.getOtherById(otherId)

        if (!dcOptimizer) {
          // none set, create new
          dcOptimizer = new window.OsOther({ other_id: otherId })
          window.editor.execute(new window.AddObjectCommand(dcOptimizer, this, false))
        } else if (dcOptimizer.other_id !== otherId) {
          //already set but need to change
          window.editor.execute(new window.UpdateElectricalsCommand(dcOptimizer, 'componentType', otherType))
        } else {
          //already set, no change required
        }
      } else {
        if (!dcOptimizer) {
          // already empty, no change required
        } else {
          // remove
          this.removeObject(dcOptimizer)
        }
      }
      this.refreshDcOptimizerEfficiencyAndQuantity()
    }

    return this.others().filter((other) => other.other_component_type === 'dc_optimizer')[0]
  },

  dcOptimizerEfficiency: function () {
    var dcOptimizer = this.dcOptimizer()
    if (dcOptimizer) {
      return dcOptimizer.dc_optimizer_efficiency
    } else {
      return null
    }
  },

  hasPanelLevelOptimization: function () {
    if (this.dcOptimizer()) {
      return true
    }

    var inverters = this.inverters()
    for (var i = 0; i < this.inverters().length; i++) {
      if (inverters[i].microinverter === true) {
        return true
      }
    }

    return false
  },

  getSystemPanelPlacement: function () {
    let gridsPanelPlacements = this.moduleGrids().map((obj) => obj.panelPlacement)
    let systemPanelPlacement = ''
    if (gridsPanelPlacements.includes('roof') && gridsPanelPlacements.includes('ground')) {
      systemPanelPlacement = 'Roof & Ground'
    } else if (gridsPanelPlacements.includes('roof')) {
      systemPanelPlacement = 'Roof'
    } else if (gridsPanelPlacements.includes('ground')) {
      systemPanelPlacement = 'Ground'
    }
    return systemPanelPlacement
  },

  electricalCalcs: function () {
    try {
      return {
        temperature_min_max: Utils.getMinMaxTemperature(window.WorkspaceHelper?.project),
      }
    } catch (e) {
      console.warn(e)
      return {}
    }
  },

  removeModuleGrid: function (moduleGrid) {
    // verify if the target module grid belongs to this system
    var moduleGridUuids = this.moduleGrids().map((mgrid) => mgrid.uuid)
    if (moduleGridUuids.indexOf(moduleGrid.uuid) !== -1) {
      if (window.editor.selected === moduleGrid) {
        window.editor.deselect()
      }
      window.editor.deleteObjectByUuid(moduleGrid.uuid)
    }
  },

  hasBuildablePanels: function () {
    let moduleGrids = this.moduleGrids()
    if (moduleGrids.length === 0) return false // the system doesn't have any active panels yet
    for (let i = 0; i < moduleGrids.length; i++) {
      if (moduleGrids[i].hasBuildablePanels()) {
        // one of the module grids has buildable panels, that's all we need to know
        return true
      }
    }
    return false
  },

  allActivePanelsAreBuildable: function () {
    if (!this.hasBuildablePanels()) {
      // no sign of buildable panels whatsoever
      // no need to check further
      return false
    }
    let moduleGrids = this.moduleGrids()
    for (let i = 0; i < moduleGrids.length; i++) {
      if (!moduleGrids[i].allActivePanelsAreBuildable()) {
        // if one of the module grids has incomplete coverage, the entire system has incomplete coverage
        return false
      }
    }

    return true
  },

  setAllPanelsAsBuildable: function (dispatchSignal = true) {
    this.moduleGrids().forEach((grid) => {
      grid.setAllPanelsAsBuildable(false) // do not dispatch per-module-grid change signal
    })
    dispatchSignal && editor.signals.objectChanged.dispatch(this, 'buildableCells')
  },

  clearBuildablePanels: function (dispatchSignal = true) {
    this.moduleGrids().forEach((grid) => {
      grid.clearBuildablePanels(false) // do not dispatch per-module-grid change signal
    })
    dispatchSignal && editor.signals.objectChanged.dispatch(this, 'buildableCells')
  },

  getCentroid: function () {
    // Based on centroid of moduleGrid centroids
    let moduleGrids = this.moduleGrids()
    let boundingBox = new THREE.Box3()
    for (let i = 0; i < moduleGrids.length; i++) {
      boundingBox.expandByPoint(moduleGrids[i].position)
    }
    return boundingBox.getCenter(new THREE.Vector3())
  },
})

OsSystem.shadingOverrideTo288 = function (shadingOverride) {
  if (!shadingOverride || shadingOverride.length === 0) {
    return _.range(288).map((i) => 0)
  } else if (shadingOverride.length === 1) {
    return _.range(288).map((i) => shadingOverride[0])
  } else if (shadingOverride.length === 12) {
    return [].concat(
      ...shadingOverride.map((shadingOverrideForMonth) => _.range(24).map((i) => shadingOverrideForMonth))
    )
  } else if (shadingOverride.length === 4) {
    return [].concat(
      ...shadingOverride.map((shadingOverrideForSeason) => _.range(72).map((i) => shadingOverrideForSeason))
    )
  } else {
    throw new Error('Unexpected shading format')
  }
}
