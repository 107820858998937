/**
 * @author adampryor
 */

MINIMUM_LINE_LENGTH = 0.5

var SequenceController = function (editor, viewport) {
  this.name = 'Sequence'

  const inputs = {
    activation: (activationState) => {
      if (activationState === this.active) return
      if (activationState === true) {
        this.activate()
      } else {
        this.deactivate()
      }
    },
  }
  this.inputs = inputs

  const outputs = {
    activation: new window.Signal(),
  }
  this.outputs = outputs

  const hotkeys = new Hotkeys(this)
  hotkeys.on(Hotkeys.ESCAPE).do(() => {
    if (this.active) {
      this.deactivate()
    }
  })
  hotkeys.on(Hotkeys.Q).do(() => {
    if (this.active && this.points.length === 0) {
      let mode = this.getMode()
      if (mode === 'aframe') {
        mode = 'hip'
      } else if (mode === 'hip') {
        mode = 'shed'
      } else if (mode === 'shed') {
        mode = 'aframe'
      } else {
        // other modes including dormer modes can still switch back to aframe with hotkey
        mode = 'aframe'
      }
      this.abort()
      this.activate(mode)
    }
  })
  hotkeys.on(Hotkeys.D).do(() => {
    if (this.active && this.points.length === 0) {
      let mode = this.getMode()
      if (mode === 'aframe_dormer') {
        mode = 'hip_dormer'
      } else if (mode === 'hip_dormer') {
        mode = 'shed_dormer'
      } else if (mode === 'shed_dormer') {
        mode = 'aframe_dormer'
      } else {
        // other modes including quick-roof (non-dormer) modes can still switch back to dormer with hotkey
        mode = 'aframe_dormer'
      }
      this.abort()
      this.activate(mode)
    }
  })
  hotkeys.on(Hotkeys.R).do(() => {
    if (this.active && this.points.length === 0) {
      if (window.ViewHelper.enableDrawingTools()) {
        this.abort()
        Designer.startPlacementMode('Roof (R)')
      }
    }
  })

  var container = viewport.container
  var domElement = viewport.container.dom

  this.active = false

  var nodes = []
  this.nodes = nodes

  var edges = []
  this.edges = edges

  var points = []
  this.points = points

  var _this = this
  const pointer = new THREE.Vector2()
  const pointerRaycaster = new THREE.Raycaster()

  var constraintPlane = null

  // Constrains the first ridge line if placed onto a facet
  var constraintRidgeLine3 = null
  var constraintLine3 = []

  var mode = null // aframe, hip, shed, aframe_dormer, hip_dormer, shed_dormer

  var ghostNode = null
  var ghostEdge = null
  var ghostNodeReflected = null
  var ghostEdgeReflected = null

  ////////////////////////////////////
  // Activate/Deactivate
  ////////////////////////////////////

  this.abort = function () {
    this.deactivate()
  }

  this.activate = function (_mode) {
    mode = _mode

    if (!this.active) {
      editor.saveControllerState()
      editor.manageController('General', false)
      editor.manageController('ModulePlacement', false)

      // Cancel roof drawing if active
      editor.manageController('AddObject', false)

      domElement.addEventListener('mousedown', onPointerDown, false)
      domElement.addEventListener('touchstart', onPointerDown, false)
      domElement.addEventListener('mousemove', onPointerMove, false)
      domElement.addEventListener('touchmove', onPointerMove, false)
      domElement.addEventListener('mouseup', onPointerUp, false)
      domElement.addEventListener('mouseout', onPointerUp, false)
      domElement.addEventListener('touchend', onPointerUp, false)
      domElement.addEventListener('touchcancel', onPointerUp, false)
      domElement.addEventListener('touchleave', onPointerUp, false)
      this.active = true
    }

    // Clear any selected object before starting to ensure wizard panels
    // is visible
    if (editor.selected) {
      editor.select(null)
      //force dispatch objectSelected signal to refreshPanel
      editor.signals.objectSelected.dispatch()
    }

    editor.signals.sceneLoaded.add(clear)
    editor.signals.controlModeChanged.add(clear)
    editor.signals.displayModeChanged.add(clear)
    editor.signals.sceneGraphChanged.add(clear)
    editor.signals.transformModeChanged.add(clear)
    editor.signals.editorCleared.add(clear)
    clear()
    editor.signals.sequenceUpdated.dispatch()
    outputs.activation.dispatch(this.active)
    hotkeys.attach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)

    return this.abort.bind(this)
  }

  this.deactivate = function () {
    mode = null
    if (this.active) {
      domElement.removeEventListener('mousedown', onPointerDown, false)
      domElement.removeEventListener('touchstart', onPointerDown, false)
      domElement.removeEventListener('mousemove', onPointerMove, false)
      domElement.removeEventListener('touchmove', onPointerMove, false)
      domElement.removeEventListener('mouseup', onPointerUp, false)
      domElement.removeEventListener('mouseout', onPointerUp, false)
      domElement.removeEventListener('touchend', onPointerUp, false)
      domElement.removeEventListener('touchcancel', onPointerUp, false)
      domElement.removeEventListener('touchleave', onPointerUp, false)
      this.active = false
    }

    editor.signals.sceneLoaded.remove(clear)
    editor.signals.controlModeChanged.remove(clear)
    editor.signals.displayModeChanged.remove(clear)
    editor.signals.sceneGraphChanged.remove(clear)
    editor.signals.transformModeChanged.remove(clear)
    editor.signals.editorCleared.remove(clear)
    clear()
    editor.signals.sequenceUpdated.dispatch()
    outputs.activation.dispatch(this.active)
    hotkeys.detach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)

    editor.revertControllerState()
  }

  function clear() {
    while (edges.length) {
      editor.removeObject(edges.pop())
    }

    while (nodes.length) {
      editor.removeObject(nodes.pop())
    }
    points.length = 0

    if (ghostNode) {
      editor.removeObject(ghostNode)
      ghostNode = null
    }

    if (ghostEdge) {
      editor.removeObject(ghostEdge)
      ghostEdge = null
    }

    if (ghostNodeReflected) {
      editor.removeObject(ghostNodeReflected)
      ghostNodeReflected = null
    }

    if (ghostEdgeReflected) {
      editor.removeObject(ghostEdgeReflected)
      ghostEdgeReflected = null
    }

    updateConstraints()
  }

  ////////////////////////////////////
  // Mouse/Pointer Handlers
  ////////////////////////////////////

  function getMode() {
    return mode
  }
  this.getMode = getMode

  function isDormer() {
    return mode && mode.indexOf('dormer') !== -1
  }
  this.isDormer = isDormer

  function getState() {
    return {
      mode: mode,
      points: points,
    }
  }
  this.getState = getState

  function positionAndAzimuthForPoints(mode, points) {
    var bearing = Utils.bearing(points[0], points[1])

    var position

    if (mode === 'aframe_dormer') {
      var scale = new THREE.Vector3(
        points[2].clone().sub(points[1]).length() * 2,
        points[1].clone().sub(points[0]).length(),
        1
      )

      // origin at midpoint of edge (apex) near first point placement
      var deltaFromPoints2And3 = points[2].clone().sub(points[1])
      position = points[0]
    } else if (mode === 'hip_dormer') {
      // find the theoretical point that would be the other edge of an a-frame
      // if we extended the ridge line out over the hip
      var ridgeLine = new THREE.Line3(points[0], points[1])
      var virtualApexPoint = ridgeLine.closestPointToPoint(points[2], false, new THREE.Vector3())

      // we then calculate everything just like an aframe_dormer but using the virtual apex point

      var scale = new THREE.Vector3(
        points[2].clone().sub(virtualApexPoint).length() * 2,
        virtualApexPoint.clone().sub(points[0]).length(),
        1
      )

      // position is equal to first point placement
      var deltaFromPoints2And3 = points[2].clone().sub(virtualApexPoint)
      position = points[0]
    } else if (mode === 'shed_dormer') {
      var scale = new THREE.Vector3(
        points[2].clone().sub(points[1]).length(),
        points[1].clone().sub(points[0]).length(),
        1
      )

      // origin at midpoint of edge (apex) near first point placement
      var deltaFromPoints2And3 = points[2].clone().sub(points[1])
      position = points[0].add(deltaFromPoints2And3.multiplyScalar(0.5))
    } else if (mode === 'aframe' || mode === 'hip' || mode === 'shed') {
      var scale = new THREE.Vector3(
        points[2].clone().sub(points[1]).length(),
        points[1].clone().sub(points[0]).length(),
        1
      )

      // origin at midpoint of edge near first point placement
      var deltaFromPoints1And2 = points[1].clone().sub(points[0])
      var deltaFromPoints2And3 = points[2].clone().sub(points[1])
      position = points[0]
        .clone()
        .add(deltaFromPoints1And2.multiplyScalar(0.5))
        .add(deltaFromPoints2And3.multiplyScalar(0.5))
    }

    return {
      position: position,
      scale: scale,
      azimuth: bearing,
    }
  }
  this.positionAndAzimuthForPoints = positionAndAzimuthForPoints

  function addPoint(point) {
    // Enforce minimum length to avoid absurdly small structures,
    // especially due to accidental clicks
    if (points.length > 0) {
      var distanceFromPreviousPoint = point
        .clone()
        .sub(points[points.length - 1])
        .length()
      if (distanceFromPreviousPoint < MINIMUM_LINE_LENGTH) {
        return
      }
    }

    points.push(point)

    if (points.length === 3) {
      if (mode === 'hip') {
        var slopes = [20, 20, 20, 20]

        // complete! Build the final structure and deactivate
        var { position, scale, azimuth } = positionAndAzimuthForPoints(mode, points)

        var structure = new OsStructure({
          position: position.toArray(),
          azimuth: azimuth,
          scale: scale.toArray(),
          slopes: slopes,
          mode: mode,
        })
        structure.snapToTerrainNow({ silent: true })

        editor.execute(new AddObjectCommand(structure, undefined, true))
      } else if (mode === 'hip_dormer') {
        var slopes = [20, 20, 20, 0]

        // complete! Build the final structure and deactivate
        var { position, scale, azimuth } = positionAndAzimuthForPoints(mode, points)

        var structure = new OsStructure({
          position: position.toArray(),
          azimuth: azimuth,
          scale: scale.toArray(),
          slopes: slopes,
          mode: mode,
        })
        structure.snapToTerrainNow({ silent: true })
        editor.execute(new AddObjectCommand(structure, undefined, true))
      } else if (mode === 'aframe' || mode === 'aframe_dormer') {
        var slopes = [20, 0, 20, 0]

        // complete! Build the final structure and deactivate
        var { position, scale, azimuth } = positionAndAzimuthForPoints(mode, points)

        var structure = new OsStructure({
          position: position.toArray(),
          azimuth: azimuth,
          scale: scale.toArray(),
          slopes: slopes,
          mode: mode,
        })
        structure.snapToTerrainNow({ silent: true })
        editor.execute(new AddObjectCommand(structure, undefined, true))
      } else if (mode === 'shed' || mode === 'shed_dormer') {

        var slopes

        // shed and shed_dormer have different slope configurations
        // it is critical to get these correct otherwise the wrong facet
        // will have the slope applied.
        if (mode === 'shed') {
          slopes = [0, 0, 20, 0]
        } else if (mode === 'shed_dormer') {
          slopes = [0, 20, 0, 0]
        }

        // complete! Build the final structure and deactivate
        var { position, scale, azimuth } = positionAndAzimuthForPoints(mode, points)

        var structure = new OsStructure({
          position: position.toArray(),
          azimuth: azimuth,
          scale: scale.toArray(),
          slopes: slopes,
          mode: mode,
        })
        structure.snapToTerrainNow({ silent: true })
        editor.execute(new AddObjectCommand(structure, undefined, true))
      }

      clear()
      mode = null

      // Can't figure out the bloody references to this/private scope
      editor.controllers.Sequence.deactivate()
    } else {
      var previousNode = nodes.length > 0 ? nodes[nodes.length - 1] : null

      var node = new OsNode({ position: point })
      node.refreshForCamera()

      nodes.push(node)
      editor.addObject(node)

      if (previousNode) {
        var edge = new OsEdge({ nodes: [previousNode, node] })
        edge.RemoveWithoutCommand = true
        editor.addObject(edge)
      }
      updateConstraints()
    }
  }

  function updateConstraints() {
    if (points.length !== 1) {
      constraintRidgeLine3 = null
    }

    if (points.length >= 1) {
      constraintPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), points[0])
    } else {
      constraintPlane = null
    }

    if (points.length == 2) {
      if (mode === 'aframe' || mode === 'hip' || mode === 'shed' || mode === 'shed_dormer') {
        // line perpendicular to the first line, co-planar with ground.
        var perpendicularDirection = new THREE.Line3(points[0], points[1])
          .delta()
          .normalize()
          .applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2)
          .multiplyScalar(50)
        constraintLine3 = [
          new THREE.Line3(points[1], points[1].clone().add(perpendicularDirection)),
          new THREE.Line3(points[1], points[1].clone().sub(perpendicularDirection)),
        ]
      } else if (mode === 'aframe_dormer') {
        // line perpendicular to the first line, co-planar with ground.
        var perpendicularDirection = new THREE.Line3(points[0], points[1])
          .delta()
          .normalize()
          .applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2)
          .multiplyScalar(50)
        constraintLine3 = [
          new THREE.Line3(points[1], points[1].clone().add(perpendicularDirection)),
          new THREE.Line3(points[1], points[1].clone().sub(perpendicularDirection)),
        ]
      } else if (mode === 'hip_dormer') {
        // line at 45 degrees from ridge, but it must flip to ensure it points in the correct direction from ridge
        constraintLine3 = []

        var hipDirectionA = new THREE.Line3(points[0], points[1])
          .delta()
          .normalize()
          .applyAxisAngle(new THREE.Vector3(0, 0, 1), (0.5 * Math.PI) / 2)
          .multiplyScalar(100)
        constraintLine3.push(new THREE.Line3(points[1], points[1].clone().add(hipDirectionA)))

        var hipDirectionB = new THREE.Line3(points[0], points[1])
          .delta()
          .normalize()
          .applyAxisAngle(new THREE.Vector3(0, 0, 1), (-0.5 * Math.PI) / 2)
          .multiplyScalar(100)
        constraintLine3.push(new THREE.Line3(points[1], points[1].clone().add(hipDirectionB)))
      }
    } else {
      constraintLine3 = []
    }
  }

  function pointerToPointWithConstraints(event) {
    var eventForPointer = event.changedTouches ? event.changedTouches[0] : event
    // var eventForPointer = Utils.normalizeClientPositionForEvent(event)
    var mousePosition = new THREE.Vector2().fromArray(
      viewport.getMousePosition(eventForPointer.clientX, eventForPointer.clientY)
    )

    if (constraintLine3.length && constraintPlane) {
      // constrain to closest point on line which is flat (z=0) and is co-planar with the original point
      var pointOnPlane = viewport.getIntersectionWithPlane(mousePosition, constraintPlane)

      //now find closest point on constraintLine
      var pointOnConstraintLine
      if (constraintLine3.length) {
        var pointOnConstraintLines = constraintLine3.map((line) =>
          line.closestPointToPoint(pointOnPlane, true, new THREE.Vector3())
        )

        // get closest match
        var closestPointOnConstraintLineDistance = 100000
        pointOnConstraintLines.forEach((p, pointIndex) => {
          var distance = p.clone().sub(pointOnPlane).length()
          if (distance < closestPointOnConstraintLineDistance) {
            closestPointOnConstraintLineDistance = distance
            pointOnConstraintLine = p
          }
        })
      }

      if (pointOnConstraintLine) {
        return [pointOnConstraintLine, null]
      }
    } else if (constraintRidgeLine3) {
      // More specific than just constraintPlane, when adding dormer to facet
      var pointOnPlane = viewport.getIntersectionWithPlane(mousePosition, constraintPlane)
      var positionOnRidgeLine3 = constraintRidgeLine3.closestPointToPoint(pointOnPlane, true, new THREE.Vector3())
      if (positionOnRidgeLine3) {
        return [positionOnRidgeLine3, null]
      }
    } else if (constraintPlane) {
      // contrain to intersection with plane (at the same level as the original point)

      var position = viewport.getIntersectionWithPlane(mousePosition, constraintPlane)
      if (position) {
        return [position, null]
      }
    } else {
      // no constraint, paint anywhere
      // search for a facet intersection first
      //otherwise fallback to intersection with terrain or ground
      var position

      var facetMeshIntersections = viewport.getIntersects(mousePosition, editor.filter('type', 'OsFacetMesh'))
      if (facetMeshIntersections.length > 0) {
        position = facetMeshIntersections[0].point
        var azimuthVector = facetMeshIntersections[0].object.facet.getAzimuthVector()
        return [position, azimuthVector]
      }

      position = viewport.getIntersectionWithTerrainOrGround(mousePosition)
      return [position, null]
    }
  }

  function onPointerDown(event) {
    var [position, azimuthVector] = pointerToPointWithConstraints(event)
    if (position) {
      // When placing down first point only we may also set a line constraint
      // based on facet direction
      if (isDormer() && points.length === 0) {
        if (azimuthVector) {
          var maxRidgeLength = 50 // any number will do, it just needs to be
          // something so we can allow clamping to the line to ignore
          // drawing backwards
          var azimuthVectorScaled = azimuthVector.multiplyScalar(maxRidgeLength)

          constraintRidgeLine3 = new THREE.Line3(position, position.clone().add(azimuthVectorScaled))
        } else {
          // Dormer must be placed onto a facet, ignore any click which does
          // not intersect with a facet
          Designer.showNotification('Dormers must be placed onto an existing roof', 'warning')
          return
        }
      }

      addPoint(position)
      editor.signals.sequenceUpdated.dispatch()
    }
  }

  function onPointerUp(event) { }

  function onPointerMove(event) {
    if (points.length == 0 && (ghostNode || ghostEdge)) {
      //clear ghostNode and ghostEdge if set
      if (ghostNode) {
        editor.removeObject(ghostNode)
        ghostNode = null
      }

      if (ghostEdge) {
        editor.removeObject(ghostEdge)
        ghostEdge = null
      }

      if (ghostNodeReflected) {
        editor.removeObject(ghostNodeReflected)
        ghostNodeReflected = null
      }

      if (ghostEdgeReflected) {
        editor.removeObject(ghostEdgeReflected)
        ghostEdgeReflected = null
      }

      editor.render()
    } else if (points.length > 0) {
      var showReflected = (mode === 'aframe_dormer' || mode === 'hip_dormer') && points.length === 2

      var previousNode = nodes[nodes.length - 1]

      var [position, azimuthVector] = pointerToPointWithConstraints(event)

      var positionReflected
      if (showReflected) {
        if (mode === 'aframe_dormer') {
          var delta
          delta = position.clone().sub(previousNode.position)
          positionReflected = position.clone().sub(delta).sub(delta)
        } else if (mode === 'hip_dormer') {
          // find point on original line and reflect over that
          var originalLine = new THREE.Line3(nodes[0].position, nodes[1].position)
          // do not clamp, allow it to run off the end of the ridge line
          var reflectionPoint = originalLine.closestPointToPoint(position, false, new THREE.Vector3())
          var delta = position.clone().sub(reflectionPoint)
          positionReflected = position.clone().sub(delta).sub(delta)
        }
      }

      if (!ghostNode) {
        ghostNode = new OsNode({ position: position })
        ghostNode.refreshForCamera()
        editor.addObject(ghostNode)
      } else {
        ghostNode.position.copy(position)
      }

      if (showReflected) {
        if (!ghostNodeReflected) {
          // skip reflected for shed structures
          ghostNodeReflected = new OsNode({ position: positionReflected })
          ghostNodeReflected.refreshForCamera()
          editor.addObject(ghostNodeReflected)
        } else {
          ghostNodeReflected.position.copy(positionReflected)
        }
      }

      if (!ghostEdge) {
        var edge = new OsEdge({ nodes: [previousNode, ghostNode] })
        edge.RemoveWithoutCommand = true
        editor.addObject(edge)
        ghostEdge = edge
      } else {
        // swap nodes if necessary
        var node0 = previousNode
        var node1 = ghostNode
        if (ghostEdge.nodes[0] !== node0 || ghostEdge.nodes[1] !== node1) {
          ghostEdge.setNodes([node0, node1])
        }
        ghostEdge.refreshLine()
      }

      if (showReflected) {
        if (!ghostEdgeReflected) {
          var edgeReflected = new OsEdge({ nodes: [previousNode, ghostNodeReflected] })
          edgeReflected.RemoveWithoutCommand = true
          editor.addObject(edgeReflected)
          ghostEdgeReflected = edgeReflected
        } else {
          var node0Reflected = previousNode
          var node1Reflected = ghostNodeReflected

          if (ghostEdgeReflected.nodes[0] !== node0 || ghostEdgeReflected.nodes[1] !== node1) {
            ghostEdgeReflected.setNodes([node0Reflected, node1Reflected])
          }
          ghostEdgeReflected.refreshLine()
        }
      }

      editor.render()
    }

    // if (points.length === 1) {
    //   // update last edge to point from last note to constrained current cursor position
    //
    //
    // }
  }

  function overrides(eventKeyNormalized) {
    if (this.active && this.points.length === 0 && [Hotkeys.Q, Hotkeys.D, Hotkeys.R].includes(eventKeyNormalized)) {
      return true
    } else {
      return false
    }
  }
  this.overrides = overrides
}
