// Remove log details if implemented on Map Adapters
const logAllowList = {
  Google2DMapAdapter: {
    getBounds: ['GET'],
    get: ['GET'],
    gm_accessors_: ['GET'],
    viewUuid: ['SET'],
    maxZoom: ['GET', 'SET', 'DELETE'],
    maxZoomOverridenForObliqueDetection: ['SET', 'GET', 'DELETE'],
    setCenter: ['GET'],
    set: ['GET'],
    center: ['SET'],
    center_changed: ['GET'],
    changed: ['GET'],
    gm_bindings_: ['GET'],
    __e3_: ['GET'],
    moveCamera: ['GET'],
    __gm: ['GET'],
    zoomFractional: ['SET', 'GET'],
    __scale: ['GET', 'SET'],
    __display: ['GET', 'SET'],
    setOptions: ['GET'],
    setDraggable: ['GET'],
    draggable: ['SET'],
    draggable_changed: ['GET'],
    setZoomControl: ['GET'],
    zoomControl: ['SET'],
    zoomControl_changed: ['GET'],
    setScrollwheel: ['GET'],
    scrollwheel: ['SET'],
    scrollwheel_changed: ['GET'],
    hidden: ['GET', 'SET', 'DELETE'],
    getProjection: ['GET'],
    maxDigitalZoom: ['GET'],
    rebuildLayers: ['SET'],
    getHeading: ['GET'],
    heading: ['GET', 'SET'],
    __heading: ['GET'],
    setHeading: ['GET'],
    heading_changed: ['GET'],
    getTilt: ['GET'],
    tilt: ['GET'],
    removeEventListener: ['GET'],
    setRotateControl: ['GET'],
    rotateControl: ['SET'],
    rotateControl_changed: ['GET'],
    maxZoomTriggered: ['DELETE'],
  },
  OpenLayers2DMapAdapter: {
    getCoordinateFromPixel: ['GET'],
    frameState_: ['GET'],
    viewUuid: ['SET'],
    getView: ['GET'],
    get: ['GET'],
    values_: ['GET'],
    __display: ['SET'],
    updateSize: ['GET'],
    getTargetElement: ['GET'],
    getTarget: ['GET'],
    setSize: ['GET'],
    set: ['GET'],
    notify: ['GET'],
    dispatchEvent: ['GET'],
    listeners_: ['GET'],
    dispatching_: ['GET'],
    pendingRemovals_: ['GET'],
    hidden: ['GET'],
    rebuildLayers: ['SET'],
    maxZoom: ['GET'],
  },
}
const isInAllowList = (instanceName, propName, action) => {
  return logAllowList[instanceName]?.[propName]?.includes(action)
}
const addToAllowList = (instanceName, propName, action) => {
  ;((logAllowList[instanceName] ??= {})[propName] ??= []).push(action)
}

// Add log details to logAllowList when console.debug has been hit
var proxyHandler = {
  get: (target, prop, receiver) => {
    const value = target[prop]
    if (!(prop in target)) {
      if (!isInAllowList(target.constructor.name, prop, 'GET')) {
        addToAllowList(target.constructor.name, prop, 'GET')
        console.debug(
          `GET: ${target.constructor.name} Map adapter prop '${prop}' has not been defined/implemented yet!`
        )
      }
      return target.map[prop]
    }
    return value
  },
  deleteProperty(target, prop) {
    if (!(prop in target)) {
      if (!isInAllowList(target.constructor.name, prop, 'DELETE')) {
        addToAllowList(target.constructor.name, prop, 'DELETE')
        console.debug(
          `DELETE: ${target.constructor.name} Map adapter prop '${prop}' has not been defined/implemented yet!`
        )
      }
    } else {
      delete target[prop]
    }
    if (prop in target.map) {
      delete target.map[prop]
    }
  },
  set(target, prop, value) {
    if (!(prop in target)) {
      if (!isInAllowList(target.constructor.name, prop, 'SET')) {
        addToAllowList(target.constructor.name, prop, 'SET')
        console.debug(
          `SET: ${target.constructor.name} Map adapter prop '${prop}' has not been defined/implemented yet!`
        )
      }
    } else {
      Reflect.set(target, prop, value)
    }
    return Reflect.set(target.map, prop, value)
  },
}

var makeMapProxy = (target) => {
  return new Proxy(target, proxyHandler)
}
