/**
 * @author adampryor

 Record position of start and end touches

 Get raw bearing for start and end touches

 If facing the wrong direction for current hemisphere flip the order of points

Southern hemisphere: 90 <= azimuth <= 270
Northern hemisphere: azimuth < 90 or azimuth > 270

 */

window.terrainMode = 'instant' //instant, fingerpaint, none
window.USE_TILT_RACK_THRESHOLD_SLOPE = 10

var FingerPaintController = function (editor, viewport) {
  this.name = 'FingerPaint'

  const inputs = {
    activation: (activationState) => {
      if (activationState === this.active) return
      if (activationState === true) {
        this.start() // this calls activate
      } else {
        this.finish() // this calls deactivate
      }
    },
  }
  this.inputs = inputs

  const outputs = {
    activation: new window.Signal(),
  }
  this.outputs = outputs

  const hotkeys = new Hotkeys(this)
  hotkeys.on(Hotkeys.ESCAPE).do(() => {
    this.finish()
  })

  const Hemisphere = {
    NORTH: 'north',
    SOUTH: 'south',
  }

  const Orientation = {
    LANDSCAPE: 'landscape',
    PORTRAIT: 'portrait',
  }

  const Configuration = {
    STANDARD: OsModuleGrid.PanelConfigTypes.Flush,
    TILT_RACK: OsModuleGrid.PanelConfigTypes.SingleTilt,
    DUAL_TILT_RACK: OsModuleGrid.PanelConfigTypes.DualTilt,
  }

  const Placement = {
    ROOF: 'roof',
    GROUND: 'ground',
  }

  this.enabled = true
  this.active = false
  this.panelOrientation = Orientation.PORTRAIT
  this.activeChanged = new signals.Signal()

  // Used for unit testing. Set to false to call updateObjectPosition() synchronously
  // instead of asynchronously.
  this.throtteEnabled = true

  var getDomElement = function () {
    return viewport.container.dom
  }

  var setPoints = function () {
    var doFlip = false
    //check if first direction aligns with hemisphere
    //if not, flip them
    var bearing = (Utils.bearing(startPosition, endPosition) + 90 + 360) % 360
    var hemisphere = SceneHelper.getHemisphere()
    if (hemisphere === Hemisphere.NORTH && (bearing <= 90 || bearing >= 270)) {
      doFlip = true
    } else if (hemisphere === Hemisphere.SOUTH && bearing > 90 && bearing < 270) {
      doFlip = true
    }

    if (doFlip === true) {
      points[1] = startPosition
      points[0] = endPosition
    } else {
      points[0] = startPosition
      points[1] = endPosition
    }
  }

  // API
  var _this = this
  var STATE = { NONE: -1, TOUCH_PAINT: 1, MOUSE_PAINT: 2 }
  var _state = STATE.NONE

  var startPosition = new THREE.Vector3()
  var endPosition = new THREE.Vector3()
  var points = [startPosition, endPosition]

  var onMoveCallback
  var newObject
  var usingViewTypeNone = false
  var objectUnderMouseDown = null

  // events
  var changeEvent = { type: 'change' }
  // var finishEvent = { type: 'finished' }

  var grid = null
  var screen = { width: 0, height: 0, offsetLeft: 0, offsetTop: 0 }

  var gridPresets = {}

  // methods

  this.isPainting = function () {
    return _state !== -1
  }

  /**
   * Stores presets that will be used for the next fingerpainting session
   * this can accept presets with partial definition
   * @param {{
   * slope: number,
   * azimuth: number,
   * orientation: string,         // landscape, portrait
   * panelConfiguration: string,  // TILT_RACK, STANDARD,
   * panelTiltOverride: number | null,
   * moduleSpacing: [number, number],
   * }} presets
   */
  this.storeGridPresets = function (presets) {
    gridPresets = presets
  }

  this.getGridPresets = function () {
    return gridPresets
  }

  this.clearGridPresets = function () {
    gridPresets = {}
  }

  function start() {
    var moduleType = editor.selectedSystem?.moduleType()
    if (!moduleType) {
      console.warn("Can't start fingerpaint, no module available, aborting")
      return
    }

    editor.saveControllerState()

    usingViewTypeNone = ViewHelper.selectedViewMapType() === 'None'

    editor.controllers.General.deactivate()
    editor.controllers.Camera.deactivate()
    if (editor.controllers.Annotation) {
      editor.controllers.Annotation.deactivate()
    }

    //must call before 'activate' so the signal will allow overlay to refresh
    window.setOverlay('SolarTouch')

    //hack!!! signal fires immediately but the setOverlay call goes via component state
    setTimeout(function () {
      editor.signals.windowResize.dispatch()
    }, 100)

    _this.activate()

    newObject = new OsModuleGrid({
      size: moduleType.size,
      moduleTexture: moduleType.module_texture,
      azimuth: SceneHelper.defaultTiltRackAzimuth(),
      slope: 0,
      panelTiltOverride: 20,
      cellsActive: ['0,0'],
      orientation: gridPresets.orientation || _this.panelOrientation,
      moduleSpacing: gridPresets.moduleSpacing || [0, 0],
    })
    newObject.ghostMode(true)

    // Avoid floating ghost object on iOS
    // Handling this by simply making ghost panel groups invisible on iOS
    // rather than preventing creation in the first place because I'm afraid to
    // break it during ghost object cleanup/disposal.
    //
    // Set to invisible for all platforms until a drag has occurred to avoid the object appearing in some
    // random place on the screen
    newObject.visible = false

    editor.addObject(newObject)

    onMoveCallback = function (event) {
      if (_this.throtteEnabled) {
        updateObjectPositionThrottled(event, false)
      } else {
        updateObjectPosition(event)
      }
    }

    document.addEventListener('mousemove', mousemove, false)

    //return the function for cancelling the mode
    return function () {
      _this.finish()
    }
  }
  this.start = start

  function finish() {
    if (newObject) {
      if (newObject.parent) {
        editor.removeObject(newObject)
      } else {
        // remove object from viewport (as signal didn't get dispatched on mousedown event)
        editor.signals.objectRemoved.dispatch(newObject)
      }
    }

    editor.revertControllerState()
    _this.deactivate()

    onMoveCallback = null

    window.setOverlay(null)
    // fire an onchanged even after removing overlay because
    // ui is out-of-date since updates are disabled during panel painting
    if (grid) {
      grid.refreshUserData()
      editor.signals.objectChanged.dispatch(grid, 'cellsActive')
      if (editor.selected !== grid) {
        editor.select(grid)
      }
    }

    grid = null

    document.removeEventListener('mousemove', mousemove)
    document.removeEventListener('mouseup', mouseup)

    //Reset. @TODO: Change to make this an explicit part of state, not some random far on window.
    window.terrainMode = 'instant'
  }
  this.finish = finish

  function handleResize() {
    if (viewport) {
      var viewportRect = viewport.rect()

      screen.width = viewportRect.width
      screen.height = viewportRect.height
      screen.offsetLeft = viewportRect.left
      screen.offsetTop = viewportRect.top
    }
  }

  function setPanelOrientation(value) {
    _this.panelOrientation = value
    if (newObject) {
      newObject.moduleLayout(value)
    }
  }

  function updateObjectPosition(event, useOverlay) {
    /////////////////
    // POSITION
    //
    // Place into the world.
    // Intersection with a facet places it on the facet
    // Otherwise intersect with terrain
    // Otherwise intersect with ground
    /////////////////

    editor.uiPause('render', 'FingerPaintController.updateObjectPosition')

    var _event = Utils.normalizeClientPositionForEvent(event)

    var mousePosition = new THREE.Vector3().fromArray(viewport.getMousePosition(_event.clientX, _event.clientY))

    var facetMeshIntersections = viewport.getIntersects(mousePosition, editor.filter('type', 'OsFacetMesh'))

    var position

    if (facetMeshIntersections.length > 0) {
      //Beware: do not modify the position directly because this will modify the cache!
      position = facetMeshIntersections[0].point.clone()
      position.z += 0.2
    }

    if (!position && window.terrainMode === 'instant' && editor.getTerrain()) {
      var positionFromTerrain = viewport.getIntersectionWithTerrain(mousePosition)
      if (positionFromTerrain) {
        //Beware: do not modify the position directly because this will modify the cache!
        positionFromTerrain = positionFromTerrain.clone()
        positionFromTerrain.z += 0.2
      }
      position = positionFromTerrain
    }

    if (!position && useOverlay) {
      //use overlay so it show on top of everything
      position = viewport.getIntersectionWithOverlay(mousePosition)
    }

    if (!position) {
      //place on ground
      position = viewport.getIntersectionWithGround(mousePosition)
    }

    if (!position) {
      console.log('Warning: position null in updateObjectPosition')
      position = new THREE.Vector3()
    }

    newObject.position.copy(position)

    /////////////////
    // ORIENTATION
    //
    // Orient to terrain if available
    // Otherwise orient to facet
    /////////////////
    var orientationIsReady = false

    // If floating on facet, remove the overrides
    // Apply overrides if no facet underneath
    if (!orientationIsReady) {
      var facetUnderneath = newObject.findFacetUnderneath()
      if (facetUnderneath) {
        if (facetUnderneath.slope >= window.USE_TILT_RACK_THRESHOLD_SLOPE) {
          facetUnderneath.floatObject(newObject)
          newObject.azimuthAuto = true
          newObject.slopeAuto = true
          newObject.panelTiltOverride = null
          newObject.panelConfiguration = Configuration.STANDARD
          Utils.applyOrientation(newObject, newObject.getAzimuth(), newObject.getSlope())
          orientationIsReady = true
          newObject.setSize()
        } else {
          newObject.azimuthAuto = false
          newObject.slopeAuto = false
          newObject.elevationAuto = true
          newObject.panelConfiguration = Configuration.TILT_RACK
          if (gridPresets.panelConfiguration === Configuration.TILT_RACK) {
            // copy tilt from the grid presets but force slope=0 since it should come from the facet
            newObject.panelTiltOverride = !isNaN(gridPresets.panelTiltOverride) ? gridPresets.panelTiltOverride : 0
          } else {
            // use fault tilt and slope
            newObject.panelTiltOverride = 20
          }
          newObject.slope = 0
          newObject.azimuth = !isNaN(gridPresets.azimuth) ? gridPresets.azimuth : facetUnderneath.azimuth
          Utils.applyOrientation(newObject, newObject.azimuth, newObject.slope)
          orientationIsReady = true
          newObject.setSize()
        }
      } else if (!facetUnderneath && !editor.getTerrain()) {
        //no facet underneath and no terrain available, use default azimuth, slope=20 and when we actually click
        // to create we will set slope=0 and tiltRacks=20
        const fallbackSlope = window.WorkspaceHelper.getIsProjectLite() ? 22.5 : 20
        const fallbackAzimuth = SceneHelper.getHemisphere() === 'north' ? 180 : 0
        newObject.azimuthAuto = false
        newObject.slopeAuto = false
        newObject.slope = !isNaN(gridPresets.slope) ? gridPresets.slope : fallbackSlope
        newObject.azimuth = !isNaN(gridPresets.azimuth) ? gridPresets.azimuth : fallbackAzimuth
        newObject.panelTiltOverride = !isNaN(gridPresets.panelTiltOverride) ? gridPresets.panelTiltOverride : null
        newObject.panelConfiguration = gridPresets.panelConfiguration || Configuration.STANDARD
        Utils.applyOrientation(newObject, newObject.azimuth, newObject.slope)
        orientationIsReady = true
        newObject.setSize()
        window.SceneHelper.snapModuleGridToGroundLevel(newObject, false)
      }
    }

    if (!orientationIsReady && window.terrainMode === 'instant' && editor.getTerrain()) {
      try {
        var orientation = SceneHelper.orientationAtPosition(newObject.position)
        var newPanelConfiguration =
          orientation.slope < window.USE_TILT_RACK_THRESHOLD_SLOPE ? Configuration.TILT_RACK : Configuration.STANDARD

        if (newPanelConfiguration === Configuration.TILT_RACK) {
          Utils.applyOrientation(
            newObject,
            !isNaN(gridPresets.azimuth) ? gridPresets.azimuth : SceneHelper.defaultTiltRackAzimuth(),
            // since the dummy module grid is for visual guidance only,
            // we can use the tilt as the slope so it "looks like" the dummy module is oriented properly based on
            // the grid preset, no visual difference since the dummy is only a single panel
            !isNaN(gridPresets.panelTiltOverride) ? gridPresets.panelTiltOverride : 20
          )
        } else {
          Utils.applyOrientation(newObject, orientation.azimuth, orientation.slope)
        }

        if (newObject.panelConfiguration !== newPanelConfiguration) {
          newObject.panelConfiguration = newPanelConfiguration
          newObject.applyTiltRacks()
          newObject.refreshGeometryForChildren()
        }
      } catch (error) {
        // if terrain not intersected then don't try to set orientation with terrain
      }
    }

    // Make visible, this is only necessary on the first time the object position is updated because it gets created
    // as invisible until moved by mouse. Conveniently for touch devices this means it will never become visible which
    // is expected behavior, since there is no mouse to follow.
    newObject.visible = true
    // No intersection found with facet or terrain, just use default orientation
    // refreshGridThrottled()
    // newObject.tiltRackAzimuth = 180
    // newObject.tiltRackSlope = 20
    // Utils.applyOrientation(newObject, newObject.tiltRackAzimuth, newObject.tiltRackSlope)

    // Call exactly one render

    editor.uiResume('render', 'FingerPaintController.updateObjectPosition', false)
    editor.render()
  }

  var updateObjectPositionThrottled = window.Utils.throttle(function (event) {
    updateObjectPosition.bind(_this)(event)
  })

  function getWorldPositionFromMouse(clientX, clientY) {
    var mousePosition = new THREE.Vector3().fromArray(viewport.getMousePosition(clientX, clientY))

    if (window.terrainMode === 'fingerpaint' && editor.getTerrain()) {
      // Only return terrain intersection if it's valid, otherwise fallback to ground intersection
      var positionFromTerrain = viewport.getIntersectionWithTerrain(mousePosition)
      if (positionFromTerrain) {
        return positionFromTerrain
      }
    }

    // Ensure we keep the elevation if the grid was placed above ground (i.e on a flat roof)
    var elevation = newObject && newObject.position && newObject.position.z ? newObject.position.z : 0
    return viewport.getIntersectionWithFlatPlaneAtElevation(mousePosition, elevation)
  }

  this.reset = function (_target0, _position0, _up0) {
    _state = STATE.NONE

    _this.dispatchEvent(changeEvent)
  }

  function rotationForStartToEnd() {
    var azimuth = Utils.azimuthBetweenPoints(points)
    window.Utils.applyOrientation(grid, azimuth, !isNaN(grid.panelTiltOverride) ? grid.panelTiltOverride : 0)
  }

  function refreshModules(points) {
    if (points) {
      // Find start and end cols based on points
      // Assumes moduleGrid orientation is already up-to-date

      // Update grid.updateWorldMatrix before detecting the mouse position relative to the grid orientation
      grid.updateWorldMatrix()

      var columnRange = points.map(function (p) {
        // Why is this required???
        var fudge = -1
        return fudge * grid.colForWorldPoint(p)
      })
      if (columnRange[1] === columnRange[0]) {
        //
      } else if (columnRange[1] < columnRange[0]) {
        // reverse the order so we start with smallest first
        columnRange.unshift(columnRange.pop())
      }

      // increase length by 1 so we are rounding up to next panel
      // this also ensures at least one cell is active
      columnRange[1] += 1

      var numberOfModules = columnRange[1] - columnRange[0]
      var startColumn = columnRange[0]
      var cellsActive = _.range(numberOfModules).map(function (i) {
        return i + startColumn + ',0'
      })

      //If cells have changed ensure they are all cleared
      var cellsChanged = grid.cellsActive.join('') !== cellsActive.join('')

      grid.updateBuildableCells(cellsActive)
      grid.populate(cellsActive)

      if (cellsChanged) {
        editor.signals.objectChanged.dispatch(grid, 'cellsActive')
      }

      return
    }
  }

  function refreshGrid() {
    // Draw grid first time
    // this function is only called when placing unto flat or flat-ish surfaces
    // whether on a terrain (3D mode)
    // or on a flat/flat-ish facet
    // or on the ground
    // in these cases, except for 2D only projects, the panels will be on tilt racks, regardless of the grid presets
    // for panels on sloped surfaces, they're created on the spot then studio switches control
    // to the ModulePlacementController
    // see onMouseDown()

    if (!_this.active) {
      console.warn('Ignorning delayed call to refreshGrid after debounce delay')
      return
    }

    const moduleCountStart = grid ? grid.cellsActive.length : 0
    const moduleType = editor.selectedSystem?.moduleType()

    if (!editor.selectedSystem || !moduleType) {
      console.warn('Unable to determine selected system and or module type, aborting finger paint controller')
      return
    }

    editor.uiPause('render', 'refreshGrid')
    editor.uiPause('ui', 'refreshGrid')

    if (!grid) {
      // create the module grid for the first time

      const is2DProject = !window.ViewHelper.hasManualOr3DView()
      const isLiteProject = window.WorkspaceHelper.getIsProjectLite()
      const shouldUseTiltRacks = !is2DProject

      const gridOptions = {
        size: moduleType.size,
        moduleTexture: moduleType.module_texture,

        // auto-slope only applies for sloped surfaces
        slopeAuto: false,

        // auto-azimuth only applies for sloped surfaces
        azimuthAuto: false,

        // auto-elevation only applies for sloped surfaces
        elevationAuto: false,

        // for 2D only projects (such as OS Lite): placing panels will default to roof-mounting
        // for projects with 3D views: placing on flat surfaces will default to ground-mounting
        panelPlacement: is2DProject ? Placement.ROOF : Placement.GROUND,

        // the orientation of the dummy module is already correct based on user input or grid preset
        orientation:
          newObject && newObject.moduleLayout() === Orientation.LANDSCAPE
            ? Orientation.LANDSCAPE
            : Orientation.PORTRAIT,

        // for 3D, we set default slope = 0 and tilt = 20
        // slope value is later changed IF there's grid preset
        slope: shouldUseTiltRacks ? 0 : isLiteProject ? 22.5 : 20,

        // azimuth is later changed IF there's grid preset
        azimuth: newObject.getAzimuth(),

        // if 2D project, use standard config
        // otherwise, use tilt racks since we're painting on a flat surface in 3D
        panelConfiguration: shouldUseTiltRacks ? Configuration.TILT_RACK : Configuration.STANDARD,

        // only apply tilt when tilt rack is applied to the panels
        panelTiltOverride: shouldUseTiltRacks ? 20 : null,
      }

      if (
        gridPresets.panelConfiguration === Configuration.TILT_RACK &&
        gridOptions.panelConfiguration === Configuration.TILT_RACK
      ) {
        // if the grid preset has tilt racks and the grid we're working on is also
        // going to use tilt racks, then appply the tilt override and slope from the grid presets
        gridOptions.panelTiltOverride = !isNaN(gridPresets.panelTiltOverride) ? gridPresets.panelTiltOverride : 20
        gridOptions.slope = !isNaN(gridPresets.slope) ? gridPresets.slope : 0
      }

      if (
        gridPresets.panelConfiguration === Configuration.STANDARD &&
        gridOptions.panelConfiguration === Configuration.STANDARD
      ) {
        gridOptions.slope = !isNaN(gridPresets.slope) ? gridPresets.slope : isLiteProject ? 22.5 : 20
      }

      if (!isNaN(gridPresets.azimuth)) {
        gridOptions.azimuth = gridPresets.azimuth
      }

      if (!!gridPresets.moduleSpacing) {
        gridOptions.moduleSpacing = gridPresets.moduleSpacing
      }

      // NOTE: the constructor of OsModuleGrid will implicitly apply parameters like slope, azimuth, etc.
      // so the module grid will already have the correct orientation (relative to the 3D scene) immediately after creation
      grid = new OsModuleGrid(gridOptions)
      grid.position.copy(endPosition)
      // add module grid to the scene tree under the currently selected system
      editor.addObject(grid, editor.selectedSystem)

      if (grid.handleGhostModeBehavior) {
        grid.handleGhostModeBehavior(true)
      }
      grid.ghostMode(true)
    }

    if (window.terrainMode === 'fingerpaint') {
      // the user is currently dragging the pointer if we're here
      if (grid.cellsActive.length > 1) {
        // if the grid has grown to more than a single panel
        // the azimuth is adjusted based on the anchor points (start and end drag positions)
        // if the grid has just one panel, we use the initial orientation of the panel when it was created (set above)
        SceneHelper.orientWithPositions(grid, [points[0], points[1]])
      }
    } else {
      rotationForStartToEnd()
    }

    grid.azimuthIndicators.sync()
    window.SceneHelper.snapModuleGridToGroundLevel(grid)
    refreshModules(points)
    editor.uiResume('render', 'refreshGrid', false)
    editor.render()
    editor.uiResume('ui', 'refreshGrid', moduleCountStart !== grid.cellsActive.length)
  }

  // listeners

  function mousedown(event) {
    if (_this.enabled === false) return

    event.preventDefault()
    //event.stopPropagation();

    var terrain = editor.getTerrain()

    if (_state === STATE.NONE) {
      // Beware: Do not use the throttled version of this function because it can break-alignment
      // when using gridPresets since it will fires AFTER the grid has actually been placed into
      // the scene.
      updateObjectPosition(event)

      // remove ghost object mouse follower
      editor.removeObject(newObject, false) //do not dispatch signal

      // @TODO: Optimize to avoid double-intersection calcs
      // if instant painting onto a flat surface then use fingerpaint instead
      if (window.terrainMode === 'instant' && !terrain) {
        //No terrain found anywhere, switch to fingerpaint.
        //If we are drawing on a facet it will automatically perform "instant" creation anyway
        window.terrainMode = 'fingerpaint'
      } else if (window.terrainMode === 'instant' && terrain) {
        let _event = Utils.normalizeClientPositionForEvent(event)
        let mousePosition = new THREE.Vector3().fromArray(viewport.getMousePosition(_event.clientX, _event.clientY))

        let objMeshIntersections = viewport.getIntersects(mousePosition, terrain.getMeshes())

        if (objMeshIntersections.length) {
          var orientation = SceneHelper.orientationAtPosition(objMeshIntersections[0].point)
          if (orientation.slope < window.USE_TILT_RACK_THRESHOLD_SLOPE) {
            window.terrainMode = 'fingerpaint'
          }
          objectUnderMouseDown = editor.getTerrain()
        } else {
          //Terrain is loaded but we did not intersect it
          window.terrainMode = 'fingerpaint'
          objectUnderMouseDown = null
        }
      }

      // Check if we are painting on a facet
      // If we are painting onto a facet then we abort "solar touch" and instead
      // place a grid down onto the house, select it, and allow painting modules on it.

      if (window.terrainMode === 'instant' && terrain) {
        let _event = Utils.normalizeClientPositionForEvent(event)
        let mousePosition = new THREE.Vector3().fromArray(viewport.getMousePosition(_event.clientX, _event.clientY))

        let objMeshIntersections = viewport.getIntersects(mousePosition, terrain.getMeshes())

        if (objMeshIntersections.length) {
          editor.uiPauseUntilCompleteNested(
            function () {
              finish()

              var moduleType = editor.selectedSystem?.moduleType()
              var newGrid = new OsModuleGrid({
                // If growing, start with a single module. If painting, start with no modules
                cellsActive: event.metaKey ? ['0,0'] : undefined,
                size: moduleType.size,
                moduleTexture: moduleType.module_texture,
                _mouseOver: true,
                _selected: true,
                orientation: newObject.moduleLayout(),
                moduleSpacing: newObject.moduleSpacing,
              })

              newGrid.position.copy(objMeshIntersections[0].point)
              newGrid.position.z += 0.2

              // Increase footprint in X direction for stability when placing a single panel down
              // But do not inflate in the Y direction in case we hit a wall
              var inflationFactor = [1.2, 1.0]
              // update the global transform matrix of the new module grid
              // critical for the computation inside SceneHelper.autoOrientModuleGrid()
              newGrid.updateMatrixWorld()
              SceneHelper.autoOrientModuleGrid(newGrid, inflationFactor)

              newGrid.updateMatrix()

              // Only create command after auto-orientation
              editor.createObject(newGrid.type, newGrid, editor.selectedSystem)

              if (event.metaKey) {
                newGrid.growOnTerrain(true)
              } else {
                newGrid.selected(true)
                editor.controllers.ModulePlacement.activate()
                editor.controllers.ModulePlacement.onMouseDown(event)
              }
            },
            _this,
            [
              {
                type: 'render',
                lockName: 'uiMouseDown1',
                dispatchSignalOnResume: false, //do not trigger signal, this will happen anyway when grid cells change
              },
              {
                type: 'ui',
                lockName: 'uiMouseDown1',
                dispatchSignalOnResume: false, //do not trigger signal, this will happen anyway when grid cells change
              },
            ]
          )

          return
        }
      }

      var facetMeshUnderMouse = editor.viewport.objectUnderClick(event, editor.filter('type', 'OsFacetMesh'), false)

      objectUnderMouseDown = facetMeshUnderMouse

      if (facetMeshUnderMouse) {
        var facet = facetMeshUnderMouse.facet

        if (facet.slope < window.USE_TILT_RACK_THRESHOLD_SLOPE) {
          // Intersected a flat plane, continue solar touch to allow setting azimuth during drag
          window.terrainMode = 'fingerpaint'

          // Set height of module grid to the intersection point, then continue fingerpainting
          let _event = Utils.normalizeClientPositionForEvent(event)
          let mousePosition = new THREE.Vector3().fromArray(viewport.getMousePosition(_event.clientX, _event.clientY))
          let objMeshIntersections = viewport.getIntersects(mousePosition, [facetMeshUnderMouse])
          newObject.position.z = objMeshIntersections[0].point.z
        } else {
          editor.uiPauseUntilCompleteNested(
            function () {
              finish()

              var _event = Utils.normalizeClientPositionForEvent(event)

              var mousePosition = new THREE.Vector3().fromArray(
                viewport.getMousePosition(_event.clientX, _event.clientY)
              )

              var facetMeshIntersections = viewport.getIntersects(mousePosition, [facetMeshUnderMouse])

              var moduleType = editor.selectedSystem?.moduleType()
              var newGrid = new OsModuleGrid({
                size: moduleType.size,
                moduleTexture: moduleType.module_texture,
                _mouseOver: true,
                _selected: true,
                slope: facet.slope,
                azimuth: facet.azimuth,
                orientation: newObject.moduleLayout(),
                moduleSpacing: newObject.moduleSpacing,
              })

              newGrid.position.copy(facetMeshIntersections[0].point)
              editor.createObject(newGrid.type, newGrid, editor.selectedSystem)

              if (event.metaKey) {
                newGrid.growOnTerrain(true)
              } else {
                newGrid.selected(true)
                editor.controllers.ModulePlacement.activate()
                editor.controllers.ModulePlacement.onMouseDown(event)
              }
            },
            _this,
            [
              {
                type: 'ui',
                lockName: 'uiMouseDown2',
                dispatchSignalOnResume: false, //do not trigger signal, this will happen anyway when grid cells change
              },
              {
                type: 'render',
                lockName: 'uiMouseDown2',
                dispatchSignalOnResume: false, //do not trigger signal, this will happen anyway when grid cells change
              },
            ]
          )
          return
        }
      }

      _state = STATE.MOUSE_PAINT
    }

    startPosition.copy(getWorldPositionFromMouse(event.clientX, event.clientY))
    endPosition.copy(startPosition)
    setPoints()
    refreshGrid()

    // editor.uiPause('ui', 'FingerPaintingInProgress')

    if (grid && grid.ghostMode()) {
      editor.select(grid)
    }
    document.addEventListener('mouseup', mouseup, false)
  }

  function mousemove(event) {
    if (_this.enabled === false) return

    event.preventDefault()
    //event.stopPropagation();

    if (_state === STATE.NONE) {
      onMoveCallback(event)
    } else if (_state === STATE.MOUSE_PAINT) {
      endPosition.copy(getWorldPositionFromMouse(event.clientX, event.clientY))
      setPoints()
      refreshGrid()
    }
  }

  function mouseup(event) {
    if (_this.enabled === false) return

    if (event) event.preventDefault()
    //event.stopPropagation()

    if (_state !== STATE.NONE) {
      _state = STATE.NONE
      setPoints()
      refreshGrid()

      const currentGrid = grid

      if (currentGrid) {
        // Clear ghost mode now because updates are finished, otherwise it will remain a ghost
        if (currentGrid.ghostMode()) {
          currentGrid.ghostMode(false)
          // refresh the selection since the last time the module grid
          // was selected, it was still in "ghost" mode
          editor.selected = null
          editor.select(currentGrid)

          // reposition now because we prevented repositioning earlier
          currentGrid.resetObjectPositionToCentroidOfCells(true)
        }

        // Obsolte note, now longer true:
        // If fingerpainting on terrain, set elevationAuto on finish
        // we do not use elevationAuto while drawing... but why??
        if (window.terrainMode === 'fingerpaint' && editor.getTerrain()) {
          currentGrid.elevationAuto = true
          SceneHelper.autoOrientModuleGrid(currentGrid)
        }

        editor.execute(new AddObjectCommand(currentGrid, editor.selectedSystem, false))
        currentGrid.showSetbacks()
      }
    }

    // editor.uiResume('ui', 'FingerPaintingInProgress')

    document.removeEventListener('mousemove', mousemove)
    document.removeEventListener('mouseup', mouseup)

    finish()
  }

  function touchstart(event) {
    return mousedown({
      clientX: event.touches[0].clientX,
      clientY: event.touches[0].clientY,
      button: 0,
      preventDefault: function () {},
    })
  }

  function touchmove(event) {
    return mousemove({
      clientX: event.touches[0].clientX,
      clientY: event.touches[0].clientY,
      button: 0,
      preventDefault: function () {},
    })
  }

  function touchend(event) {
    return mouseup({ button: 0, preventDefault: function () {} })
  }

  this.isActive = function () {
    return this.active
  }

  this.activate = function () {
    this.active = true
    this.activeChanged.dispatch()

    getDomElement().addEventListener('mousedown', mousedown, false)
    getDomElement().addEventListener('touchstart', touchstart, false)
    getDomElement().addEventListener('touchend', touchend, false)
    getDomElement().addEventListener('touchmove', touchmove, false)

    editor.signals.setPanelOrientation.add(setPanelOrientation)

    editor.signals.windowResize.add(handleResize)
    this.refreshMouseCursor()

    outputs.activation.dispatch(this.active)
    hotkeys.attach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.deactivate = function () {
    this.active = false
    this.activeChanged.dispatch()

    getDomElement().removeEventListener('mousedown', mousedown, false)
    getDomElement().removeEventListener('touchstart', touchstart, false)
    getDomElement().removeEventListener('touchend', touchend, false)
    getDomElement().removeEventListener('touchmove', touchmove, false)

    this.reset()

    // window.Designer.callUi('ToolbarDrawingTools', 'close', [true])

    editor.signals.setPanelOrientation.remove(setPanelOrientation)

    editor.signals.windowResize.remove(handleResize)
    this.refreshMouseCursor()

    outputs.activation.dispatch(this.active)
    hotkeys.detach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.refreshMouseCursor = function () {
    $('#DesignerContainer').css('cursor', this.active === true ? 'copy' : 'auto')
  }

  handleResize()
}

FingerPaintController.prototype = Object.create(THREE.EventDispatcher.prototype)
FingerPaintController.prototype.constructor = FingerPaintController
