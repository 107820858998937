/* eslint-disable */
/*
Loading Sequence

1. Get Hash Params and check identifier is supplied.
	If no identifier show error message (and prompt to enter identifier manually).
2. Load workspace by identifier.
	Is workspace found in database?
		Yes: Load into designer
		No: Try to create blank workspace.... Is lat/lon supplied?
			Yes: Load Designer at supplied lat/lon. First save will create workspace in database.
			No: Is address supplied?
				Yes: Redirect to geolocation tool
*/
let NEW_PROJECT_ID = 'new'

function WorkspaceHelperClass() {
  this.showSaveNotifications = true
  this.project = null
  // this.org_id = null
  this.system_prices_loaded_from_database = {}
  this.polling_full_calcs = {}
}

WorkspaceHelperClass.prototype = Object.assign({
  SAVE_STATUS: {
    FAILED: 0,
    SUCCESS: 1,
    CANCELLED: 2,
  },
  messages: {
    abort_identifier_missing: 'Identifier not supplied. Identifier must be supplied to load or create a design.',
    abort_id_missing: 'ID not supplied. Identifier must be supplied to load or create a design.',
    workspace_not_found_create_blank: 'Workspace not found. Creating blank workspace.',
    abort_location_missing_address_supplied:
      'Location not supplied. Cannot currently create a project using supplied Address. Create a new Project in CRM then launch Designer.',
    abort_missing_location_and_address:
      'Location not supplied. Please select the address from the Address Search or enter the Latitude and Longitude in Project page to use Studio.',
  },

  params: {},

  getParam: function (key) {
    if (key in this.params) {
      return this.params[key]
    } else {
      return null
    }
  },

  //Redirect using a function so we can replace with a mock object in client-side unit tests
  redirect: function (url) {
    console.warn('Attempting to redirect... this is bad')
    return
  },
  //Alert using function which can be over-ridden in unit tests
  alert: function (message, type) {
    var zone = Designer.getZone ? Designer.getZone() : null
    if (zone && zone !== 'studio') {
      // console.log(
      //   'Alert message suppressed, not in studio zone (' +
      //     zone +
      //     '). type:' +
      //     type +
      //     ', message:' +
      //     message
      // )
    } else {
      Designer.showNotification(message, type)
    }
  },

  clear: function () {
    this.params = {}
    this.clearProjectPermissions()
    SetbacksHelper.clear()
    editor.clear()
    ViewHelper.clearViewsCommand()
    if (MapHelper) MapHelper.clear()

    //@TODO: Avoid needing to do this extra call. Currently this results in the last selected system
    // remaining selected even though it is deleted from the scene and visible in panels
    if (editor.selectedSystem) {
      editor.signals.objectRemoved.dispatch(editor.selectedSystem)
    }
  },

  cancelLoading: function () {
    // isFeatureEnabled is undefined when studio UTs run
    if (window.isFeatureEnabled?.('hide_project_save_loader', 'on')) {
      return
    }
    console.warn('WorkspaceHelper.cancelLoading()')
    LoaderHelper.complete()
  },

  getFractionLoaded: function () {
    var loaded = 0
    var total = 0

    var tmp = AccountHelper.partsLoaded()
    loaded += tmp[0]
    total += tmp[1]

    if (WorkspaceHelper.loadDesignRequested == true) {
      loaded++
    }
    total++

    return loaded / total
  },

  updateFractionLoaded: function () {
    // isFeatureEnabled is undefined when studio UTs run
    if (window.isFeatureEnabled?.('hide_project_save_loader', 'on')) {
      return
    }
    if (WorkspaceHelper.loadDesignComplete) {
      LoaderHelper.complete()
      return
    }

    LoaderHelper.fraction(WorkspaceHelper.getFractionLoaded(), true)
  },

  loadWithLocation: function (editor, location, isSceneReady, mapType) {
    var startLocation4326 = [location['lon'], location['lat']]
    editor.designNewlyCreated = true
    WorkspaceHelper.clear()
    if (MapHelper) MapHelper.clearMaxDepths()
    AccountHelper.reset()
    if (!window.isFeatureEnabled?.('hide_project_save_loader', 'on')) {
      WorkspaceHelper.loadDesignRequested = false
      WorkspaceHelper.loadDesignComplete = true
    }
    WorkspaceHelper.project = null
    if (isSceneReady) {
      window.editor.uiResume('render', 'Designer.init')
    }

    SceneHelper.blankSceneWithCountry(
      editor,
      startLocation4326,
      null,
      { iso2: location.country_iso2 },
      true, //bare,
      null,
      undefined,
      undefined,
      undefined,
      true, //autoCreateViews
      true, //skipExtraViews,
      mapType, // overrideDefaultMapType,
      undefined, //keepDesignOnlyChangeViews,
      true //skipAutoCreateSystem
    ).then(function () {
      // Prevent digital zoom
      // Ensure this is cleared when we leave Explore mode in SceneHelper.startDesignMode()
      MapHelper.activeMapInstance.dom.maxDigitalZoom = 1

      // Call immediately AND on animation finished because we don't know how else to do this reliably
      Designer.controlMode = 'map'
      editor.signals.controlModeChanged.dispatch(Designer.controlMode)
    })
  },

  loadStudioProfile: function (params) {
    this.params = params
    AccountHelper.params = params
    AccountHelper.userOrgId = params.org_id

    //To Do: refactor and simplify the following promises into a generic promise generator
    return new Promise((resolve, reject) => {
      const loadOrgPromise = new Promise((resolve) => {
        AccountHelper.loadOrg(resolve)
      })
      const loadComponentSpecsPromise = new Promise((resolve) => {
        AccountHelper.loadComponentSpecs(resolve)
      })
      const loadPricingSchemesPromise = new Promise((resolve) => {
        AccountHelper.loadPricingSchemes(resolve)
      })
      const loadBatterySchemesPromise = new Promise((resolve) => {
        AccountHelper.loadBatterySchemes(resolve)
      })
      const loadIncentivesPromise = new Promise((resolve) => {
        AccountHelper.loadIncentives(resolve)
      })
      const loadPaymentOptionsPromise = new Promise((resolve) => {
        AccountHelper.loadPaymentOptions(resolve)
      })
      const loadCostingsOptionsPromise = new Promise((resolve) => {
        AccountHelper.loadCostings(resolve)
      })
      const loadAddersOptionsPromise = new Promise((resolve) => {
        AccountHelper.loadAdders(resolve)
      })
      const loadCommissionsPromise = new Promise((resolve) => {
        AccountHelper.loadCommissions(resolve)
      })

      Promise.all([
        loadOrgPromise,
        loadComponentSpecsPromise,
        loadPricingSchemesPromise,
        loadBatterySchemesPromise,
        loadIncentivesPromise,
        loadPaymentOptionsPromise,
        loadCostingsOptionsPromise,
        loadAddersOptionsPromise,
        loadCommissionsPromise,
      ])
        .then((values) => {
          // When profile data is loaded after scene
          if (!editor.sceneIsLoading) {
            editor.signals.sceneLoadedAndProfileLoaded.dispatch()
          }

          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },

  loadWithData: function (editor, data, params, project) {
    // disable rendering until after scene is loaded (in onLoaded)
    editor.uiPause('render', 'loadWithData')

    WorkspaceHelper.clear()

    // this.developerMode(Boolean(this.params.developer))

    if (!data) {
      var initData = null
      WorkspaceHelper.onLoaded(
        editor,
        false,
        {
          lat: -28.8144271,
          lon: 153.3319759,
        },
        initData
      )

      editor.uiResume('render', 'loadWithData')
    } else {
      return editor.loadScene(data, params).then(function () {
        if (!project) {
          // Workaround scenario where we have manually injected WorkspaceHelper.project.id and we must not ovewrite it here
          // Specificaly required for GetMapping PDF generation with top-down imagery but may be required for others too.
          var preserveProjectId = WorkspaceHelper.project?.id

          project = {}

          if (preserveProjectId) {
            project.id = preserveProjectId
          }
        }
        editor.uiResume('render', 'loadWithData')
        WorkspaceHelper.onLoaded(editor, true, project)
      })
    }
  },

  start: function () {
    //Build/start the designer
  },

  onLoaded: function (editor, isSceneReady, data, initData) {
    //@todo: Handle case where no design exists yet... populate this with dummy values???
    this.project = data

    // Various possibilities:
    //     Design found, data loaded and valid
    //     Design found, data invalid or empty, overwrite with empty workspace
    //     Design not found, data sufficient to create empty workspace
    //     Design not found, insufficient data but address present to we can use search utility
    //     Design not found, insufficient data to create workspace or use search utility, abort

    //Trick: In tests this gets called with this==window
    //@todo: Can't figure it out, so using WorkspaceHelper instead of this...
    WorkspaceHelper.loadDesignRequested = false
    WorkspaceHelper.loadDesignComplete = true

    WorkspaceHelper.updateFractionLoaded()

    var address = null

    //This either saves the URL for the loaded design or null, both are valid.
    if (data) {
      try {
        WorkspaceHelper.params.url = data['url']
      } catch (err) {
        WorkspaceHelper.params.url = null
      }

      try {
        WorkspaceHelper.params.address = data['address']
        address = data['address']
      } catch (err) {
        WorkspaceHelper.params.address = null
      }
    } else if (WorkspaceHelper.params.address) {
      //keep address if already set elsewhere
      address = WorkspaceHelper.params.address
    }

    var designAddressHTML = address ? address : 'ADDRESS UNKNOWN'

    try {
      var lon = null,
        lat = null

      if (data && data['lat'] && data['lon']) {
        lat = data['lat']
        lon = data['lon']

        //update location from loaded params
        this.params.location = lon + ',' + lat
      } else if (this.paramIsValid('location')) {
        lat = WorkspaceHelper.params.location.split(',')[1]
        lon = WorkspaceHelper.params.location.split(',')[0]
      }

      if (lat && lon) {
        var googleMapsUrl = 'https://www.google.com.au/maps/@' + lat + ',' + lon + ',19z?hl=en'
        designAddressHTML += ' <a href="' + googleMapsUrl + '" target="_blank">Map</a>'
      }

      if (data['identifier']) {
        WorkspaceHelper.params.identifier = data['identifier']
      }
    } catch (err) {
      //
    }

    if (!Utils.iOS()) {
      $('#design-address').html(designAddressHTML)
    }

    if (isSceneReady) {
      //ok, nothing further to do
      editor.signals.projectDataLoaded.dispatch()
      editor.signals.projectInvalidChanged.dispatch(false)

      // window.Designer?.setUiState('InvalidProjectDialog', {
      //   isOpen: false,
      // })
      //editor.signals.animationStart.dispatch()
      // window.Designer.uiRefs['InvalidProjectDialog'] &&
      //   window.Designer.uiRefs['InvalidProjectDialog'].setState({
      //     isOpen: false,
      //   })
      // Enable rendering now scene is ready
      if (isSceneReady) {
        window.editor.uiResume('render', 'Designer.init')
      }
    } else if (this.paramIsValid('location')) {
      // Value of 0 indicates system default, which will be automatically converted to system default calculator is used (i.e. PVWatts)
      var performanceCalculator =
        data && data.configuration && data.configuration.performance_calculator > 0
          ? data.configuration.performance_calculator
          : WorkspaceHelper.getDefaultPerformanceCalculator()

      var blankSceneParamsExcludingPerformanceCalculator = [
        editor,
        WorkspaceHelper.params.location.split(',').map(parseFloat),
        data ? data.country_iso2 : null,
        null,
        null,
        initData,
        data ? data.roof_type_id : null,
        data ? data.timezone_offset : null,
      ]

      //scene not loaded, location found, we can create a new workspace
      WorkspaceHelper.alert(WorkspaceHelper.messages.workspace_not_found_create_blank)

      // This needs serious re-work because we should create a simplified background-only scene using the default map
      // when the design does not yet exist, rather than a full scene.
      SceneHelper.blankScene(...blankSceneParamsExcludingPerformanceCalculator, performanceCalculator, false, true, {
        basicMode: data.is_lite,
        overrideDefaultMapType: data.is_lite ? window.getDefaultMapType(false) : null,
        skipAutoCreateSystem: true,
      })

      editor.signals.projectInvalidChanged.dispatch(false)
      // window.Designer?.setUiState('InvalidProjectDialog', {
      //   isOpen: false,
      //   message: '',
      // })
      // window.Designer.uiRefs['InvalidProjectDialog'] &&
      //   window.Designer.uiRefs['InvalidProjectDialog'].setState({
      //     isOpen: false,
      //     message: '',
      //   })

      // Enable rendering now scene is ready
      window.editor.uiResume('render', 'Designer.init')

      //editor.signals.animationStart.dispatch()
    } else if (this.paramIsValid('address')) {
      //location not found but address was found

      //WorkspaceHelper.alert('Location not supplied. Redirecting to Address geolocator');
      //WorkspaceHelper.redirect('/api/search_address/?identifier='+WorkspaceHelper.params.identifier+'&address='+WorkspaceHelper.params.address);

      //@todo: Redirect to CRM to create Project or allow creation inside Designer? (Avoid duplication...)
      //WorkspaceHelper.alert(WorkspaceHelper.messages.abort_location_missing_address_supplied)
      WorkspaceHelper.project = null
      editor.signals.projectInvalidChanged.dispatch(
        true,
        WorkspaceHelper.messages.abort_location_missing_address_supplied
      )
      // window.Designer?.setUiState('InvalidProjectDialog', {
      //   isOpen: true,
      //   message: WorkspaceHelper.messages.abort_location_missing_address_supplied,
      // })
      // window.Designer.uiRefs['InvalidProjectDialog'] &&
      //   window.Designer.uiRefs['InvalidProjectDialog'].setState({
      //     isOpen: true,
      //     message: WorkspaceHelper.messages.abort_location_missing_address_supplied,
      //   })
    } else {
      //WorkspaceHelper.alert(WorkspaceHelper.messages.abort_missing_location_and_address)
      WorkspaceHelper.project = null
      editor.signals.projectInvalidChanged.dispatch(true, WorkspaceHelper.messages.abort_missing_location_and_address)
      // window.Designer?.setUiState('InvalidProjectDialog', {
      //   isOpen: true,
      //   message: WorkspaceHelper.messages.abort_missing_location_and_address,
      // })
      // window.Designer.uiRefs['InvalidProjectDialog'] &&
      //   window.Designer.uiRefs['InvalidProjectDialog'].setState({
      //     isOpen: true,
      //     message: WorkspaceHelper.messages.abort_missing_location_and_address,
      //   })
    }

    this.applyProjectConfigurationFromProjectOrOrgDefault()
  },

  populateSystems: function () {
    /*
    This should be idempotent. i.e. Calling this on a system that has already been partially/fully created
    should not cause any duplicates or errors.
    */

    editor.getSystems().forEach((system) => {
      // lookup the relevant auto-design geojson using the matching uuids
      var autoDesignGeoJson = editor.scene.autoDesignGeoJson.find(
        (designDataJson) => designDataJson.uuid === system.uuid
      )

      if (autoDesignGeoJson) {
        // Just use the cells that were activated in the design
        SceneHelper.drawModules(editor, system, autoDesignGeoJson, false, ['orientation', 'elevation'])

        // Now add other components
        // Already performed by auto.py but we keep it here because there may be cases where we want to
        // build new systems from scratch inside Studio based on autoDesignGeoJson

        autoDesignGeoJson.features
          .filter((f) => f.properties?.type === 'component')
          .map((f) => f.properties)
          .forEach((caq) => {
            // if object already added to the scene then do not add it again
            if (editor.objectByUuid(caq.uuid)) {
              return
            }

            let component
            switch (caq.component_type) {
              case 'battery':
                component = new OsBattery({ battery_id: caq.id, uuid: caq.uuid })
                break
              case 'inverter':
                component = new OsInverter({ inverter_id: caq.id, uuid: caq.uuid })
                break
              case 'other':
                component = new OsOther({ other_id: caq.id, uuid: caq.uuid })
                break
            }

            if (component) {
              window.editor.execute(new window.AddObjectCommand(component, system, false))
            } else {
              console.warn('Unable to add component', caq)
            }
          })
      } else {
        console.log('Unable to find matching design geojson')
      }
    })
  },
  projectConfiguration: null,
  applyProjectConfigurationFromProjectOrOrgDefault: function () {
    if (SetbacksHelper) {
      // If we have saved project configuration it will be available in this.project.configuration from back-end
      // Otherwise we can load the default from the loaded Org data

      var project_configuration = null
      if (this.project && this.project.configuration) {
        project_configuration = this.project.configuration
      } else if (
        AccountHelper &&
        AccountHelper.loadedData &&
        AccountHelper.loadedData.org &&
        AccountHelper.loadedData.org.default_project_configuration
      ) {
        project_configuration = AccountHelper.loadedData.org.default_project_configuration
      }

      this.projectConfiguration = project_configuration

      if (
        false &&
        this.project.configuration_override &&
        this.project.configuration_override !== project_configuration.url
      ) {
        // @TODO: Lookup from API async using url from this.project.configuration_override because we do not
        // have the data
        // In future we should add the ability to lookup directly from the back-end because we may have a
        // project configuration override specified in unsaved data which is different to this.project.configuration
        // We should check to see if they are inconsistent and if different, load fresh from data.
        // Since setbacks are only visual, hopefully this can happen totally async and just re-render the scene when
        // it finishes.
      } else {
        SetbacksHelper.loadFromProjectConfiguration(project_configuration)
      }
    }
  },

  promptToChangeScaleIfNecessary: function () {
    var scaleIsInteger = Math.abs(MapHelper.activeMapInstance.toMapData().scale % 1) < 0.01

    switch (MapHelper.activeMapInstance.mapData.mapType) {
      case 'Google':
      case 'GoogleRoadMap':
      case 'GoogleTop':
        if (scaleIsInteger == true) {
          return true
        } else if (
          confirm('Google snapshots can only be captured at specific zoom levels.\n\nZoom out and continue?')
        ) {
          MapHelper.zoomToWholeDepth()
          return true
        } else {
          return false
        }

      case 'Bing':
        Designer.showNotification(window.translate('Snapshots cannot be taken on Bing maps'))
        return false
      default:
        return true
    }
  },
  saveInProgress: false,
  save: function (newIdentifier, callback, skipResponseFromApi) {
    //If params.url is null a new record is created
    //If params.url is set then overwrite existing record
    if (!window.ViewHelper.selectedView()?.viewBoxParams) {
      SnapshotHelper.showAndFadeOut()
    }

    if (this.showSaveNotifications) {
      LoaderHelper.active = true
      LoaderHelper.fraction(0.0, false)
      LoaderHelper.show()
      LoaderHelper.animate(20, 100, 0.1, 0.9)
    }

    var newUrl = this.params.url

    if (!newIdentifier) {
      newIdentifier = this.params.identifier
      newUrl = this.params.url
    }

    if (!this.params.identifier) {
      console.warn('identifier not found in WorkspaceHelper')
    }

    // Save with existing identifier
    var _this = this
    _this.saveInProgress = true

    var saveCallback = function (status, data) {
      if (callback) callback(status)

      LoaderHelper.complete()

      // Beware `data` may be empty if when no data was included  in the response, probably an HTTP error
      if (data?.simulate_first_year_only) {
        //start polling...
        const id = data.id
        _this.checkLastCalcStatus(id)
      }

      _this.saveInProgress = false
    }

    var saveDesignNow = function () {
      _this.saveDesign(editor, editor.sceneAsJSON(), newIdentifier, newUrl, saveCallback, skipResponseFromApi)
    }

    if (window.isFeatureEnabled('async_full_calcs_only', 'on')) {
      if (window.ShadeHelper.hasShadingCalcsAwaitingTrigger()) {
        ShadeHelper.calculateShadingBlockedAllSystemsAwaitingTrigger()
      }
      saveDesignNow()
    } else {
      // If calcs are still processing then wait for them to complete, otherwise trigger immediately
      if (Designer.systemsQueued().length > 0) {
        // Automatically trigger shading calcs, just like if the user pressed the button
        // We avoid nasty timing issues because SceneHelper.calculatedShadingQueue()
        // is never blocked when this.saveInProgress is true
        ShadeHelper.calculateShadingBlockedAllSystemsAwaitingTrigger()

        Designer.functionsToCallWhenQueueEmpty.push(saveDesignNow)
      } else {
        saveDesignNow()
      }
    }
  },

  captureSnapshot: function (uuid) {
    if (this.promptToChangeScaleIfNecessary() == false) {
      return false
    }

    var snapshotCallback = function (croppedImageDataUrl) {
      if (typeof uuid !== 'undefined') {
        //optionally check against supplied uuid
        if (editor.selectedSystem?.uuid != uuid) {
          Designer.showNotification(window.translate('Snapshot failed'), 'danger')
          return false
        }
      }

      //var croppedImageDataUrlBase64Raw = croppedImageDataUrl.replace('data:image/png;base64,', '')
      editor.selectedSystem.snapshot = croppedImageDataUrl

      Designer.showNotification(window.translate('Snapshot captured'), 'success')

      editor.signals.objectChanged.dispatch(editor.selectedSystem)
    }

    WorkspaceHelper.snapshot(snapshotCallback, true)
  },

  snapshotAndPublishToSalesForce: function () {
    if (!editor.selectedSystem) {
      this.alert('Select a system to publish', 'danger')
      return
    }

    try {
      var price = editor.selectedSystem.pricing.system_price_including_tax
    } catch (err) {
      this.alert('Calculate system price before publishing', 'danger')
      return
    }

    try {
      var summary = editor.selectedSystem.getSummary()
    } catch (err) {
      this.alert('System summary could not be prepared', 'danger')
      return
    }

    if (this.promptToChangeScaleIfNecessary() == false) {
      return false
    }

    var snapshotCallback = function (croppedImageDataUrl) {
      var snapshotDataBase64Raw = croppedImageDataUrl.replace('data:image/png;base64,', '')

      $.ajax({
        type: 'POST',
        url: API_BASE_URL + 'salesforce/publish/',
        data: JSON.stringify({
          opportunity_id: WorkspaceHelper.params.identifier,
          price: price,
          summary: summary,
          snapshot_base64: snapshotDataBase64Raw,
          snapshot_content_type: 'image/png',
        }),
        contentType: 'application/json',
        headers: Utils.tokenAuthHeaders({
          'X-CSRFToken': getCookie('csrftoken'),
        }), //cors for django
        success: function (response) {
          Designer.showNotification(window.translate('Published to SalesForce'), 'success')
        },
        error: function (response) {
          Designer.showNotification(window.translate('Publish to SalesForce unsuccessful'), 'danger')
          Designer.loginPromptIfRequired(response)
        },
      })
    }

    var saveCallback = function () {
      WorkspaceHelper.snapshot(snapshotCallback)
    }

    this.save(this.params.identifier, saveCallback)
  },

  snapshotAndDownload: function () {
    var _this = this

    if (this.promptToChangeScaleIfNecessary() == false) {
      return false
    }

    var snapshotCallback = function (snapshotDataBase64) {
      var url = snapshotDataBase64.replace(/^data:image\/w+/, 'data:application/octet-stream')
      WorkspaceHelper.download(url, 'download.jpg')
    }
    WorkspaceHelper.snapshot(snapshotCallback)
  },

  download: function (url, fileName) {
    // Old method: This works but filesize is limited to around 2MB
    // var a = document.createElement('a')
    // document.body.appendChild(a)
    // a.style = 'display: none'
    // a.href = url
    // a.download = fileName
    // a.click()

    // New method: Should allow larger filesize
    fetch(url)
      .then(function (res) {
        return res.blob()
      })
      .then(function (blob) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        var blobUrl = window.URL.createObjectURL(blob)
        a.href = blobUrl
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        setTimeout(function () {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(blobUrl)
        }, 1000)
      })
  },

  snapshot: function (snapshotCallback, saveToSystem) {
    if (this.promptToChangeScaleIfNecessary() == false) {
      return false
    }

    var _cb = function (croppedImageDataUrl) {
      if (saveToSystem) {
        editor.selectedSystem.snapshot = croppedImageDataUrl
      }
      SnapshotHelper.finish(editor)

      if (snapshotCallback) snapshotCallback(croppedImageDataUrl)

      WorkspaceHelper.alert('Snapshot ready for download', 'success')
    }

    SnapshotHelper.prepare(editor)

    SnapshotHelper.snapshot(_cb)
  },

  getHashParams: function (hashOverride) {
    var hashRaw = hashOverride ? hashOverride : window.Designer.getHash()

    //If starting with a hash, strip it
    if (hashRaw[0] == '#') {
      hashRaw = hashRaw.substring(1)
    }

    var hashParams = {}
    var e,
      a = /\+/g, // Regex for replacing addition symbol with a space
      r = /([^&;=]+)=?([^&;]*)/g,
      d = function (s) {
        return decodeURIComponent(s.replace(a, ' '))
      },
      q = hashRaw

    while ((e = r.exec(q))) hashParams[d(e[1])] = d(e[2])

    var required_keys = ['identifier', 'address', 'location', 'developer']
    required_keys.forEach(function (key) {
      if (!(key in hashParams)) {
        hashParams[key] = null
      }
    })

    return hashParams
  },

  paramIsValid: function (param) {
    var parts
    switch (param) {
      case 'location':
        try {
          parts = WorkspaceHelper.params.location.split(',')
          return parseFloat(parts[0]) && parseFloat(parts[1])
        } catch (error) {}
        return false
      case 'identifier':
      case 'url':
      case 'address':
        try {
          return WorkspaceHelper.params[param].length > 0
        } catch (error) {}
        return false
      default:
        return false
    }
  },

  setProjectData: function (data) {
    if (WorkspaceHelper?.project?.id !== data.id) {
      WorkspaceHelper.project = { id: data.id }
    }
  },

  setParamsFromProjectData: function (data) {
    if (data.country_iso2) {
      WorkspaceHelper.params.country_iso2 = data.country_iso2
    }

    if (data.roof_type_id) {
      WorkspaceHelper.params.roofTypeId = data.roof_type_id
    }

    if (data.timezone_offset) {
      WorkspaceHelper.params.timezoneOffset = data.timezone_offset
    }
  },

  getIsProjectLite: function () {
    return !!(window.projectForm ? window.projectForm.getState().values.is_lite : WorkspaceHelper.project?.is_lite)
  },

  saveDesign: function (editor, sceneData, identifier, url, callback, skipResponseFromApi) {
    var _this = this

    var success = function (data) {
      window.studioDebug && console.log('success, ingest calculations for output, pricing, etc', data)
      if (_this.showSaveNotifications) Designer.showNotification('Project saved', 'success')
      if (callback) callback(true, data)
    }

    var error = function (data) {
      console.log('data', data)
      var detail = window.Designer.getErrorDetail(data)
      console.log('detail', detail)
      Designer.showNotification(
        window.translate('Workspace could not be saved') + (detail ? ': ' + detail : ''),
        'danger'
      )

      Designer.loginPromptIfRequired(data)

      if (callback) callback(false)
    }

    var data = JSON.stringify({
      // Do not save identifier anymore here, it should only be saved by the project form
      // @TODO: Remove all references to identifier & WorkspaceHelper.params.identifier in studio.
      // identifier: identifier,

      lon: editor.scene.sceneOrigin4326[0],
      lat: editor.scene.sceneOrigin4326[1],
      // Without compression
      // design: JSON.stringify(sceneData),
      // With compression
      design: window.CompressionHelper.compress(JSON.stringify(sceneData)),
    })

    var maxSizeMb = 2.0
    var dataSizeMb = Utils.sizeInMb(data)
    window.studioDebug && console.log('Data Size in MB', dataSizeMb)
    if (dataSizeMb > maxSizeMb) {
      alert(
        'Design filesize (' +
          dataSizeMb.toFixed(2) +
          'mb) exceeds maximum allowed filesize (' +
          maxSizeMb.toFixed(2) +
          'mb)'
      )
      if (callback) callback(false)
      return
    }

    var skipResponseSuffix = skipResponseFromApi === true ? '?skip_response=true' : ''

    $.ajax({
      type: url ? 'PATCH' : 'POST',
      url: url
        ? url + skipResponseSuffix
        : API_BASE_URL + 'orgs/' + window.getStorage().getItem('org_id') + '/projects/' + skipResponseSuffix,
      data: data,
      contentType: 'application/json',
      headers: Utils.tokenAuthHeaders({
        'X-CSRFToken': getCookie('csrftoken'),
      }), //cors for django
      success: success,
      error: error,
    })
  },

  abortSaveProject: function (dueToError = true) {
    if (!this.saveInProgress) {
      // Saving NOT in progress no need to abort
      return
    }

    if (!this._saveCallback) {
      window.captureException(
        new Error('Error when aborting save project. Saving in progress but saveCallback not found')
      )
    }

    this._saveCallback?.(dueToError ? this.SAVE_STATUS.FAILED : this.SAVE_STATUS.CANCELLED) // Fails callback
    Designer.functionsToCallWhenQueueEmpty = [] // Abort project saving in queue
    Designer.AjaxSession.resetAjaxSessionForNameSpace('saveProject') // Abort pending request
  },

  saveProject: function (generateSubmitValueLazy, callback) {
    //If params.url is null a new record is created
    //If params.url is set then overwrite existing record
    if (!window.ViewHelper.selectedView()?.viewBoxParams) {
      SnapshotHelper.showAndFadeOut()
    }

    const _this = this
    const projectId = _this.getProjectId()
    const showSaveLoader = !window.isFeatureEnabled('hide_project_save_loader', 'on')
    _this.saveInProgress = true

    if (showSaveLoader && this.showSaveNotifications) {
      LoaderHelper.active = true
      LoaderHelper.fraction(0.0, false)
      LoaderHelper.show()
      LoaderHelper.animate(20, 100, 0.1, 0.9)
    }

    Designer.AjaxSession.resetAjaxSessionForNameSpace('saveProject')

    const ajaxSaveProjectToken = Designer.AjaxSession.createAjaxSessionToken({
      skipNameSpaceCheck: false,
      nameSpace: 'saveProject',
    })

    const saveCallback = function (saveStatus, data) {
      if (callback) callback(saveStatus, data, projectId.toString())

      LoaderHelper.complete()

      // Beware `data` may be empty if when no data was included  in the response, probably an HTTP error
      if (data?.simulate_first_year_only) {
        //start polling...
        const id = data.id
        _this.checkLastCalcStatus(id)
      }
      _this._saveCallback = undefined
      _this.saveInProgress = false
    }

    _this._saveCallback = saveCallback

    const saveProjectNow = function () {
      // Check if the projectId from when it was queued is equal to current projectId
      // https://github.com/open-solar/opensolar-todo/issues/12559
      if (projectId !== NEW_PROJECT_ID && projectId !== _this.getProjectId()) {
        saveCallback(_this.SAVE_STATUS.FAILED, new Error('Form ID does not match queued project ID'))
        return
      }
      let dataToSave
      try {
        dataToSave = generateSubmitValueLazy()
      } catch (e) {
        console.error('Error generating submit value', e)
        saveCallback(_this.SAVE_STATUS.FAILED, e)
        return
      }
      _this.saveProjectData(ajaxSaveProjectToken, editor, dataToSave, projectId, saveCallback)
    }

    if (window.isFeatureEnabled('async_full_calcs_only', 'on')) {
      if (window.ShadeHelper.hasShadingCalcsAwaitingTrigger()) {
        ShadeHelper.calculateShadingBlockedAllSystemsAwaitingTrigger()
      }
      saveProjectNow()
    } else {
      // If calcs are still processing then wait for them to complete, otherwise trigger immediately
      //
      // We also force shading simulations if ShadeHelper.hasShadingCalcsAwaitingTrigger() which will happens when there
      // more than 50 panels and we are waiting for the user to click the "Recalculate" button. Clicking "Save" when the
      // recalculate button is visible is the same a clicking the recalculate button first and then saving

      if (Designer.systemsQueued().length > 0 || window.ShadeHelper.hasShadingCalcsAwaitingTrigger()) {
        // Automatically trigger shading calcs, just like if the user pressed the button
        // We avoid nasty timing issues because SceneHelper.calculatedShadingQueue()
        // is never blocked when this.saveInProgress is true
        ShadeHelper.calculateShadingBlockedAllSystemsAwaitingTrigger()

        Designer.functionsToCallWhenQueueEmpty.push(saveProjectNow)
      } else {
        saveProjectNow()
      }
    }
  },

  saveProjectData: function (ajaxSessionToken, editor, dataToSave, projectId, callback) {
    let _this = this

    var success = function (data) {
      if (!Designer.AjaxSession.validateAjaxSessionToken(ajaxSessionToken)) {
        // outdated request
        return
      }
      if (_this.showSaveNotifications) Designer.showNotification('Project saved', 'success')
      if (callback) callback(_this.SAVE_STATUS.SUCCESS, data)
    }

    var error = function (data) {
      if (!Designer.AjaxSession.validateAjaxSessionToken(ajaxSessionToken)) {
        // outdated request
        return
      }
      var detail = window.Designer.getErrorDetail(data)
      Designer.showNotification(
        window.translate('Project could not be saved') + (detail ? ': ' + detail : ''),
        'danger'
      )

      Designer.loginPromptIfRequired(data)

      if (callback) callback(_this.SAVE_STATUS.FAILED)
    }

    const orgId = window.getStorage().getItem('org_id')
    if (!projectId || !orgId) {
      Designer.showNotification('Invalid id project: ' + projectId + ', org: ' + orgId, 'danger')
      if (callback) callback(false)
      return
    }

    const data = JSON.stringify(dataToSave)

    // Add async_full_calcs_only param if feature flag is enabled
    const asyncParam = window.isFeatureEnabled('async_full_calcs_only', 'on') ? '?async_full_calcs_only=true' : ''

    var maxSizeMb = 2.0
    var dataSizeMb = Utils.sizeInMb(data)
    window.studioDebug && console.log('Data Size in MB', dataSizeMb)
    if (dataSizeMb > maxSizeMb) {
      alert(
        'Design filesize (' +
          dataSizeMb.toFixed(2) +
          'mb) exceeds maximum allowed filesize (' +
          maxSizeMb.toFixed(2) +
          'mb)'
      )
      if (callback) callback(false)
      return
    }

    const createProjectUrl = API_BASE_URL + `orgs/${orgId}/projects/${asyncParam}`
    const editProjectUrl = API_BASE_URL + `orgs/${orgId}/projects/${projectId}/${asyncParam}`

    $.ajax({
      type: projectId === NEW_PROJECT_ID ? 'POST' : 'PATCH',
      url: projectId === NEW_PROJECT_ID ? createProjectUrl : editProjectUrl,
      data,
      contentType: 'application/json',
      headers: Utils.tokenAuthHeaders({
        'X-CSRFToken': getCookie('csrftoken'),
      }),
      success: success,
      error: error,
    })
  },

  refreshPaymentOptionWarnings: function (system) {
    const filter = (error) => error.source === 'payments'
    this.clearProjectError(filter)

    let mostRecentMessages = []
    // add up to one message of type 'error' for each payment option on the ststem
    system?.payment_options?.forEach((pmt) => {
      if (pmt?.messages?.length) {
        let errorTypes = pmt.messages.filter((message) => message?.type === 'error')
        if (errorTypes?.length) {
          mostRecentMessages.push(errorTypes[0])
        }
      }
    })
    if (mostRecentMessages?.length) {
      mostRecentMessages?.forEach((message) => {
        const formattedMessage = {
          message: message.message || message.text, // fallback to message.text for backwards compatability
          key: message.message || message.text,
          severity: 'error',
          systemId: system.uuid,
          source: 'payments',
          category: 'payments',
          field: 'payment_options',
          ...message,
        }
        if (message?.error_prompt_id) {
          formattedMessage.options = {
            actions: [
              {
                label: window.translate('Update Project Details'),
                callback: () => {
                  window.WorkspaceHelper?.addProjectErrorPromptToReduxStore(
                    message.message,
                    message.error_prompt_id,
                    message.integration,
                    system.uuid,
                    message.payment_option_id
                  )
                },
              },
            ],
          }
        }
        this.addProjectErrorToReduxStore(formattedMessage)
      })
    }
    // else {
    //   const filter = (error) => error.source === 'payments'
    //   this.clearProjectError(filter)
    // }
  },

  refreshPriceLockError: function (system) {
    const pricingIsLocked = window.WorkspaceHelper.project?.is_pricing_locked && system.override_price_locking !== true
    const messageKey = 'PRICE_LOCK_PREVENT_PRICE_CHANGE'
    const message =
      'You have made changes to your design that may affect pricing.  BUT PRICING WAS NOT CHANGED BECAUSE PRICELOCK IS ACTIVE'
    const source = 'output'
    if (pricingIsLocked) {
      this.addProjectErrorToReduxStore({
        message,
        key: messageKey,
        severity: 'warning',
        systemId: system.uuid,
        source,
        category: 'system',
      })
    } else {
      this.removeProjectErrorFromReduxStore(messageKey, system.uuid, source)
    }
  },

  handleCalculationErrors: function (errorMessages, source = 'calculations') {
    // If no error messages, exit early
    if (!errorMessages) return

    // Convert to array if single object
    const errors = Array.isArray(errorMessages) ? errorMessages : [errorMessages]

    errors.forEach((errorMessage) => {
      //Currently we do not stack multiple errors as they are added to the project error store
      //Designer.showNotification('Calculation error: ' + errorMessage.message, errorMessage.severity || 'warning')
      this.addProjectErrorToReduxStore({
        ...errorMessage,
        messageType: 'fieldError',
        systemId: errorMessage.systemId || undefined,
        field: errorMessage.field || undefined,
        source,
      })
    })
  },

  updateEphemeralCalculationError: function (projectCalculationErrorMessages) {
    const filter = (error) => error.source === 'lastCalc' || error.source === 'calculations'
    this.clearProjectError(filter)

    this.handleCalculationErrors(projectCalculationErrorMessages)
  },

  updateLastCalcError: function (projectId, projectCalculationErrorMessages) {
    const currentProjectId = window.WorkspaceHelper?.project?.id
    //skip if project changed
    if (currentProjectId !== projectId) return

    //remove previous errors
    const filter = (error) => error.source === 'lastCalc' || error.source === 'calculations'
    this.clearProjectError(filter)

    // If no error messages, exit early
    if (
      !projectCalculationErrorMessages ||
      (Array.isArray(projectCalculationErrorMessages) && projectCalculationErrorMessages.length === 0)
    )
      return

    // Check if errors are in new format
    const isNewFormat = projectCalculationErrorMessages.some((error) => error.source === 'calculations')

    if (isNewFormat) {
      this.handleCalculationErrors(projectCalculationErrorMessages)
    } else {
      // Handle legacy format
      /**
       *
       * @deprecated The legacy block using lastCalc is only remaining to support the old error handling until the 1st of March 2025
       *             Errors should be handled as the new format
       */
      const detail = Designer.getErrorDetail({ responseJSON: projectCalculationErrorMessages }, 'Unspecified Error.')
      Designer.showNotification('Calculation error: ' + detail, 'danger')

      this.addProjectErrorToReduxStore({
        message: projectCalculationErrorMessages,
        messageType: 'fieldError',
        key: 'LAST_CALC_ERROR',
        severity: 'error',
        systemId: undefined,
        source: 'lastCalc',
        category: 'standalone',
      })
    }
  },

  getUnsharedEntitiesError: function (unsharedEntities) {
    this.addProjectErrorToReduxStore({
      message: unsharedEntities,
      messageType: 'sharingError',
      key: '3PO_ERROR',
      severity: 'warning',
      systemId: undefined,
      source: 'sharing',
      category: 'standalone',
    })
  },

  addProjectErrorToReduxStore: function ({ message, messageType = 'text', key, systemId, source, ...rest }) {
    if (!message || !key) return
    const projectErrors = window.reduxStore?.getState()?.project?.errors || []
    const isErrorExist = !!projectErrors.find(
      (error) =>
        error.message === message && error.key === key && error.systemId === systemId && error.source === source
    )
    if (!isErrorExist) {
      window.reduxStore?.dispatch({
        type: 'PROJECT_ERRORS_ADD',
        payload: {
          message,
          messageType,
          systemId,
          source,
          key,
          ...rest,
        },
      })
    }
  },

  removeProjectErrorFromReduxStore: function (key, systemId, source) {
    if (!key) return
    const projectErrors = window.reduxStore?.getState()?.project?.errors || []
    const isErrorKeyExist = !!projectErrors.find(
      (error) => error.key === key && error.systemId === systemId && error.source === source
    )
    if (isErrorKeyExist) {
      window.reduxStore?.dispatch({
        type: 'PROJECT_ERRORS_DELETE',
        payload: {
          key,
          systemId,
          source,
        },
      })
    }
  },

  clearProjectError: function (filter = () => true) {
    const projectErrors = window.reduxStore?.getState()?.project?.errors || []
    const isErrorExist = !!projectErrors.find(filter)

    if (isErrorExist) {
      window.reduxStore?.dispatch({
        type: 'PROJECT_ERRORS_CLEAR',
        payload: {
          filter,
        },
      })
    }
  },

  // error prompts are similar to project errors but they are meant to show the user an interactive dialog of some kind and therefore are handled differently in redux
  addProjectErrorPromptToReduxStore: function (message, promptId, integration, systemId, paymentOptionId = undefined) {
    if (!message || !promptId) return
    const projectErrorPrompts = window.reduxStore?.getState()?.project?.errorPrompts || []
    const errorExists = !!projectErrorPrompts.find(
      (error) => error.message === message && error.promptId === promptId && error.systemId === systemId
    )
    if (!errorExists) {
      window.reduxStore?.dispatch({
        type: 'PROJECT_ERROR_PROMPT_ADD',
        payload: {
          message,
          promptId,
          integration,
          systemId,
          paymentOptionId,
        },
      })
    }
  },

  removeProjectErrorPrompt: function (promptId, systemId, source) {
    if (!key) return
    const projectErrorPrompts = window.reduxStore?.getState()?.project?.errorPrompts || []
    const isErrorKeyExist = !!projectErrorPrompts.find(
      (error) => error.promptId === promptId && error.systemId === systemId
    )
    if (isErrorKeyExist) {
      window.reduxStore?.dispatch({
        type: 'PROJECT_ERROR_PROMPT_REMOVE',
        payload: {
          promptId,
          systemId,
          source,
        },
      })
    }
  },

  clearProjectErrorPrompts: function () {
    window.reduxStore?.dispatch({
      type: 'PROJECT_ERROR_PROMPT_CLEAR',
    })
  },
  clearProjectPermissions: function () {
    window.reduxStore?.dispatch({
      type: 'PROJECT_PERMISSIONS_CLEAR',
    })
  },
  inheritProjectPermissions: function () {
    window.reduxStore?.dispatch({
      type: 'INHERIT_PROJECT_PERMISSIONS',
    })
  },

  checkLastCalcStatus: function (projectId, nextFullCalcsAttempts = 0) {
    if (this.polling_full_calcs[projectId]) {
      console.debug(`Already polling full calcs for project ${projectId}, aborting`)
      return false
    }
    const MAX_FULL_CALCS_ATTEMPTS = 30

    if (nextFullCalcsAttempts > MAX_FULL_CALCS_ATTEMPTS) {
      console.warn('Error checking calc validation, cancelling polling. too many attempts')
      return false
    }

    if (editor.designMode === 'myenergy') {
      console.log('Cancelling polling in workspaceHelper. MyEnergy handles calc checking')
      return false
    }

    const orgId = window.getStorage().getItem('org_id')
    if (!projectId || !orgId) {
      Designer.showNotification('Invalid id project: ' + projectId + ', org: ' + orgId, 'danger')
      return false
    }

    this.polling_full_calcs[projectId] = true

    const error = (error) => {
      delete this.polling_full_calcs[projectId]
      console.warn('Error checking calc validation, cancelling polling.', error)
    }

    const success = (response) => {
      delete this.polling_full_calcs[projectId]
      if (response.simulate_first_year_only === false) {
        WorkspaceHelper.updateLastCalcError(projectId, response.calculation_error_messages)

        if (window.projectForm?.mutators?.updateFieldSilently) {
          // Only attempt to update if these references still exist, they may have been cleared if we have already
          // left the project
          window.projectForm.mutators.updateFieldSilently('simulate_first_year_only', false)

          if (response.systems) {
            window.projectForm.mutators.updateField('systems', response.systems)
          }
        }
      } else {
        //continue polling...
        setTimeout(() => {
          this.checkLastCalcStatus.bind(this)(projectId, nextFullCalcsAttempts + 1)
        }, 3000)
      }
    }

    $.ajax({
      type: 'GET',
      url: API_BASE_URL + 'orgs/' + orgId + '/projects/' + projectId + '/?fieldset=calculation_status',
      contentType: 'application/json',
      headers: Utils.tokenAuthHeaders({
        'X-CSRFToken': getCookie('csrftoken'),
      }), //cors for django
      success: success,
      error: error,
    })

    return true
  },

  developerModeOverride: null,

  developerMode: function (value) {
    if (typeof value === 'undefined') {
      if (this.developerModeOverride !== null) {
        return this.developerModeOverride
      } else {
        return WorkspaceHelper.getHashParams().developer == 1
      }
    }

    this.developerModeOverride = value

    if (this.developerModeOverride == true) {
      $('body').removeClass('developer-only-hidden').addClass('developer-only-visible')
    } else {
      $('body').removeClass('developer-only-visible').addClass('developer-only-hidden')
    }

    return this.developerModeOverride
  },

  storeSystemPricesFromDatabase: function (systemUuidsToPrices) {
    this.system_prices_loaded_from_database = systemUuidsToPrices
  },

  getYearsToSimulate: function () {
    if (this.project && this.project.years_to_simulate > 0) {
      return this.project.years_to_simulate
    } else {
      return 0
    }
  },
  hasAnySystemsWithPerformanceCalculator(performanceCalculator) {
    return editor.getSystems().some((system) => system.calculator === performanceCalculator)
  },
  defaultPerformanceCalculatorFallback: function () {
    // Hack for timing issue which should be untangled
    // Currently this can be called before we have applied projectConfiguration from either project or org default
    // Apply it now if not already applied
    if (!this.projectConfiguration) {
      console.log('Detecting and applying projectConfiguration from project or org default.')
      this.applyProjectConfigurationFromProjectOrOrgDefault()
    }

    // UX2 >> SAM if not specified anywhere
    //If default project configuration is loaded and not a default (empty) value, then use it
    if (
      this.projectConfiguration &&
      this.projectConfiguration.hasOwnProperty('performance_calculator') &&
      Boolean(this.projectConfiguration.performance_calculator)
    ) {
      return this.projectConfiguration.performance_calculator
    } else {
      return 2
    }
  },
  getDefaultPerformanceCalculatorForNewProjects: function () {
    if (this.hasAnySystemsWithPerformanceCalculator(3)) {
      return 3
    } else if (this.hasAnySystemsWithPerformanceCalculator(2)) {
      // if any systems use new calculator then default to new calculator
      return 2 // force SAM sync one SAM system was found
    } else {
      // else use default for the selected project configuration
      return this.getDefaultPerformanceCalculator()
    }
  },
  getDefaultPerformanceCalculator: function () {
    var selectedCalculatorId =
      this.project && this.project.configuration ? this.project.configuration.performance_calculator : null

    // if no specific calculator selected in the project configuration (value==0)
    // then fallback to system-wide default
    if (!selectedCalculatorId) {
      selectedCalculatorId = this.defaultPerformanceCalculatorFallback()
    }

    return selectedCalculatorId
  },
  getDefaultMCSSelfConsumptionCalculator: function () {
    // TODO: We might want to get this from org config in the furture, rather than hardcoding it null
    return null
  },
  getProjectId: function () {
    /*
    If we were only using UX2 we could simply use projectForm for this but we will also support legacy UX1 too
    */
    if (window.projectForm?.getState()?.values?.id) {
      return window.projectForm.getState().values.id
    }

    if (this.params?.id && this.params?.id !== NEW_PROJECT_ID) {
      return parseInt(WorkspaceHelper.params.id, 10)
    }

    return NEW_PROJECT_ID
  },
})

var WorkspaceHelper = new WorkspaceHelperClass()
