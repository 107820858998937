var toMapDataGeneric = function () {
  /*
  Generic method, this will be overwritten by an instance-specific implementation if one exists
  */

  var center4326
  try {
    center4326 = this.getCenterFromMapUnderCameraRay4326()
  } catch (e) {
    // This may fail for various reasons if other pieces of data are not initialized
    // But this should only happen in MyE when it is safe to just use existing mapData.center
    center4326 = this.mapData.center
  }

  switch (this.mapData.mapType) {
    case 'None':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: null,
        sceneOrigin: editor.scene.sceneOrigin4326.slice(),
        scale: null,
        heading: null,
        oblique: null,
        pano: null,
      })
    case 'Turntable':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: null,
        sceneOrigin: editor.scene.sceneOrigin4326.slice(),
        scale: null,
        heading: null,
        oblique: null,
        pano: null,
      })
    case 'Nearmap3D':
    case 'Google3D':
    case 'GetMapping3D':
    case 'GetMappingPremium3D':
    case 'Vexcel3D':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: null,
        sceneOrigin: editor.scene.sceneOrigin4326.slice(),
        scale: null,
        heading: null,
        oblique: this.mapData.oblique || null,
        pano: null,
      })
    case 'Bing':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData.zoomTarget,
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
      })
    case 'StreetView':
      var streetViewLocation = this.dom.getLocation()

      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        sceneOrigin: this.mapData._sceneOrigin(),
        zoomTarget: this.dom.getZoom(),
        pitch: this.dom.getPov().pitch,
        heading: this.dom.getPov().heading,
        oblique: null,
        pano: streetViewLocation.pano,
      })
    case 'OpenStreetMap':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: null,
        pano: null,
      })
    case 'SixMaps':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        maxZoom: 20,
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: null,
        pano: null,
      })

    case 'LocationSA':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        maxZoom: 20,
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: null,
        pano: null,
      })

    case 'ArcGisStreetMap':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: this.mapData.oblique,
        pano: null,
      })
    case 'Nearmap':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: this.mapData.oblique, //can optionally store surveyResourceId in object
        pano: null,
      })

    case 'GetMapping':
    case 'GetMappingPremium':
    case 'Vexcel':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: this.mapData.oblique,
        pano: null,
      })
    case 'VexcelOblique':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: this.mapData.oblique,
        pano: null,
      })
    case 'NearmapOblique':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: this.mapData.oblique, //can optionally store direction in object
        pano: null,
      })

    case 'NearmapSource':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        maxZoom: this.mapData.oblique?.tiles[0].maxZoom,
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: this.mapData.oblique,
        pano: null,
      })

    case 'Cyclomedia':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: null,
        pano: null,
      })

    case 'CyclomediaOblique':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: this.mapData.heading,
        oblique: this.mapData.oblique,
        pano: null,
      })

    case 'Image':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        zoomDelta: this.mapData.zoomDelta,
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: this.mapData.heading,
        oblique: this.mapData.oblique,
        pano: null,
      })
    case 'QldGlobe':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: null,
        pano: null,
      })
    case 'MetroMap':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: this.mapData.oblique,
        pano: null,
      })
    case 'Kadastraal':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        maxZoom: 20,
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: null,
        pano: null,
      })
    case 'IgnPotential':
    case 'IgnMedian':
    case 'IgnCatastral':
    case 'IgnOrtofotos':
      return new MapData({
        mapType: this.mapData.mapType,
        center: center4326,
        zoomTarget: this.mapData._zoomTarget(),
        sceneOrigin: this.mapData._sceneOrigin(),
        scale: this.mapData.scale,
        heading: null,
        oblique: null,
        pano: null,
      })
    case 'Google':
    case 'GoogleTop':
    case 'GoogleRoadMap':
      var msg = 'toMapData should use overridden method for mapType: ' + this.mapData.mapType
      console.error(msg)
      throw new Error(msg)
  }
}

var getCenterFromMapUnderCameraRay4326Generic = function () {
  /*
  We can only refresh scene center lon/lat when the map is currently active for that instane.
  Otherwise, we must simply return the existing value from mapData
  */
  if (this !== MapHelper.activeMapInstance) {
    return this.mapData.center
  } else {
    var leftMarginPixels = editor && editor.leftMarginPixels ? editor.leftMarginPixels : 0

    var viewportRect = viewport.rect()

    // Do we need to fix for digital zoom / css scaling?
    var screenPositionUnderViewFinder = new THREE.Vector2(
      viewportRect.width / 2 + leftMarginPixels / 2,
      viewportRect.height / 2
    )

    var center4326 = MapHelper.screenPositionToLatLon(
      this,
      screenPositionUnderViewFinder.x,
      screenPositionUnderViewFinder.y,
      this.mapData.scale ? this.mapData.scale : 1,
      this.mapData.scale ? this.mapData.scale : 1
    )

    return center4326
  }
}

function MapInstance(mapData, dom) {
  //In addition to persistent properties, we also manage map.animating which is not saved anywhere

  this.applyMapTypeInstanceMethods = function (mapType) {
    //  methods: drawMap, interactive, setPrimary, updateSize, setView, scaleDomElement, matchScene, toMapData
    if (window.MAP_TYPES[mapType].className) {
      var mapTypeClass = window[window.MAP_TYPES[mapType].className]
      Object.keys(mapTypeClass).forEach((key) => {
        var classValue = mapTypeClass[key]

        if (typeof classValue === 'function') {
          // instance method
          this[key] = classValue.bind(this)
        } else {
          // instance property
          this[key] = classValue
        }
      })
    }

    if (!this.toMapData) {
      this.toMapData = toMapDataGeneric.bind(this)
    }
    if (!this.getCenterFromMapUnderCameraRay4326) {
      this.getCenterFromMapUnderCameraRay4326 = getCenterFromMapUnderCameraRay4326Generic.bind(this)
    }
  }
  this.applyMapTypeInstanceMethods(mapData.mapType)

  this.dom = dom
  this.mapData = mapData

  var _this = this
  MapData.prototype.properties.forEach(function (property) {
    _this[property] = mapData[property]
  })

  this._interactive = null

  // A custom this.interactive() method may have been added above, but if not we will fallback to the default
  if (!this.interactive) {
    this.interactive = function (value) {
      // Interactive means the map can be interacted with directly
      // Normally it is not interactive, so everything is controlled to match the 3D screen.
      if (typeof value === 'undefined') {
        return this._interactive
      }

      if (this._interactive === value) {
        return
      }

      switch (this.mapData.mapType) {
        case 'GoogleRoadMap':
          this.dom.setOptions({
            draggable: value,
            zoomControl: value,
            scrollwheel: false,
          })
          break
        case 'StreetView':
          this.dom.setOptions({
            addressControl: false,
            clickToGo: value,
            disableDefaultUI: !value,
            fullscreenControl: false,
            imageDateControl: value,
            linksControl: false,
            motionTracking: value,
            motionTrackingControl: false,
            scrollwheel: value,
          })
          break
        case 'None':
        case 'Turntable':
        case 'Nearmap3D':
        case 'Google3D':
        case 'GetMapping3D':
        case 'Bing':
        case 'Nearmap':
        case 'GetMapping':
        case 'NearmapOblique':
        case 'NearmapSource':
        case 'SixMaps':
        case 'LocationSA':
        case 'Cyclomedia':
        case 'ArcGisStreetMap':
        case 'CyclomediaOblique':
        case 'Kadastraal':
        case 'IgnPotential':
        case 'IgnMedian':
        case 'IgnCatastral':
        case 'IgnOrtofotos':
          break
        case 'OpenStreetMap':
        case 'Image':
          if (value) {
            this.dom.addControl(new ol.control.Zoom())
          } else {
            this.dom.getControls().clear()
          }
          break
        case 'QldGlobe':
          break
        default:
        //nothing
      }

      this._interactive = value
    }
  }

  this.zoomTargetToZoomTargetCappedAndScale = function (zoomTarget) {
    // Google Maps API now supports fractional zoom levels!!
    // We now only need to perform CSS scaling when the maximum zoom is exceeded :-)

    var maxZoom
    if (this.mapData && this.mapData.maxZoom) {
      // Typically for Google Oblique
      maxZoom = this.mapData.maxZoom
    } else if (this.dom && this.dom.maxZoom) {
      // Typically for Google Top Down
      maxZoom = this.dom.maxZoom
    } else {
      maxZoom = 30
    }
    var zoomTargetCapped = Math.min(zoomTarget, maxZoom)
    var targetMapScale = Math.pow(2, zoomTarget - zoomTargetCapped)
    return { zoomTargetCapped, targetMapScale }
  }
}
