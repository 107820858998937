/**
 * @author adampryor
 */

var RAYTRACE_METHOD = 'THREEJS' // 'THREEJS' or 'JSTS'

//Geometry must include the size in the cache key otherwise systems with different module sizes
//will use the same gemoetry
//
// Cache under key: moduleTexture
var OsModuleCache = {}

var gf = new jsts.geom.GeometryFactory()

function pointsOnLine(x0, y0, x1, y1) {
  var coordinates = []
  var dx = Math.abs(x1 - x0)
  var dy = Math.abs(y1 - y0)
  var sx = x0 < x1 ? 1 : -1
  var sy = y0 < y1 ? 1 : -1
  var err = dx - dy

  while (true) {
    coordinates.push([x0, y0]) // Do what you need to for this

    if (x0 === x1 && y0 === y1) {
      return coordinates
    }
    var e2 = 2 * err
    if (e2 > -dy) {
      err -= dy
      x0 += sx
    }
    if (e2 < dx) {
      err += dx
      y0 += sy
    }
  }
}

function getStudioDetail() {
  if (window.studioDetail) {
    return window.studioDetail
  } else {
    return 'high'
  }
}

function buildMaterialPanel(moduleTextureUrlToLoad) {
  // window.texturesLoading only exists when studio is used for PDF images
  // this is a way of tracking the module textures as they start and finish loading
  var textureRequest = {
    url: moduleTextureUrlToLoad,
  }

  if (window.texturesLoading && window.editor.id) {
    textureRequest.editorId = window.editor.id
    window.texturesLoading.push(textureRequest)
  }

  var textureLoadCallbackSuccess = (_texture) => {
    if (window.texturesLoading) {
      window.texturesLoading.splice(window.texturesLoading.indexOf(textureRequest), 1)
    }
    onModuleTextureImageLoad()
  }

  var textureLoadCallbackError = (error) => {
    if (window.texturesLoading) {
      window.texturesLoading.splice(window.texturesLoading.indexOf(textureRequest), 1)
    }
    console.error(
      'Failed to load texture: ',
      textureRequest.url,
      textureRequest.editorId ? textureRequest.editorId : 'no editor id',
      error
    )
  }

  // MeshStandardMaterial does not seem to work for SoftwareRenderer
  // TextureLoader().load() supports two callbacks - onLoad and onError
  // the middle one, undefined, is for inProgress, which is currently not supported by ThreeJS
  if (Designer.rendererName && Designer.rendererName === 'SoftwareRenderer') {
    return new THREE.MeshLambertMaterial({
      color: 0x333333,
      map: new THREE.TextureLoader().load(
        moduleTextureUrlToLoad,
        textureLoadCallbackSuccess,
        undefined,
        textureLoadCallbackError
      ),
    })
  } else {
    return new THREE.MeshStandardMaterial({
      color: 0x333333,
      map: new THREE.TextureLoader().load(
        moduleTextureUrlToLoad,
        textureLoadCallbackSuccess,
        undefined,
        textureLoadCallbackError
      ),
      roughness: 0.9,
      metalness: 0.1,
    })
  }
}

function buildMaterialMetalActive() {
  if (Designer.rendererName && Designer.rendererName === 'SoftwareRenderer') {
    return new THREE.MeshLambertMaterial({
      color: 0x000000,
      emissive: 0x333333,
    })
  } else {
    return new THREE.MeshStandardMaterial({
      color: 0x333333,
      emissive: 0x333333,
      roughness: 0.3,
      metalness: 0.3,
    })
  }
}

var onModuleTextureImageLoad = function () {
  if (window.editor?.signals?.materialChanged) {
    window.editor.signals.materialChanged.dispatch()
  }
}

function OsModule(options) {
  THREE.Mesh.call(this)

  // @TODO: Fix this rough hack which allows loading shadingOverride from userData.shadingOverride
  // when loading from JSON.
  if (options?.fromJSON === true && options?.userData) {
    options = { ...options, ...options.userData }
  }

  this.moduleTexture(options && options.moduleTexture ? options.moduleTexture : 'default')

  this.size = options && options.size ? options.size : [0.6, 1.2]

  this.setGeometryForSize()

  this.active = !options || options.active !== false ? true : false

  this.cell = options && options.cell ? options.cell : null //an array with 2 elements in the form [x,y]

  this.refreshMaterial()

  this.drawMode = 0

  this.updateMorphTargets()

  this.type = 'OsModule'
  this.name = 'OsModule'

  this.castShadow = this.active && getStudioDetail() === 'high'
  this.receiveShadow = this.active && getStudioDetail() === 'high'

  this.selectable = true //Manually set to false for paintable modules
  this.shadingOverride = options && options.shadingOverride ? options.shadingOverride : []
  this.shadingOverrideRaw = null

  this.assignedOsString = null

  this.selectionOutlineEdges = options && options.selectionOutlineEdges ? options.selectionOutlineEdges : []
}

OsModule.userDataValidators = {
  active: (value) => typeof value === 'boolean',
  cell: (value) => typeof value === 'string' && value.split(',').every((el) => parseInt(el) !== NaN),
  size: (value) => Array.isArray(value) && value.length === 2 && value.every((el) => typeof el === 'number'),
  use_tilt_rack: (value) => typeof value === 'boolean',
  slope: (value) => typeof value === 'number',
  racks: (value) => typeof value === 'number',
  azimuth: (value) => typeof value === 'number',
  trackingMode: (value) => typeof value === 'number',
  gcr: (value) => typeof value === 'number',
  shadingOverride: (value) => Array.isArray(value),
}

OsModule.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  removeStringsAndCleanupEmptyElectricals: function () {
    // Remove this module from any assigned string and if that results in any empty strings/mppts/inverters then
    // remove those too.
    if (this.assignedOsString) {
      // check for any mppts/inverters where this is the only assigned module and store references first before we
      //remove it or the references will be lost
      var mppt = this.assignedOsString.parent
      var inverter = mppt.parent
      this.assignedOsString.removeModule(this)

      if (mppt.moduleQuantity() === 0) {
        editor.deleteObject(mppt)
      }

      if (inverter.moduleQuantity() === 0) {
        editor.deleteObject(inverter)
      }
    }
  },

  toolsActive: function () {
    return {
      translateXY: false,
      translateZ: false,
      rotate: false,
      scaleXY: false,
      scaleZ: false,
      scale: false, //legacy
    }
  },

  generateAndCacheMaterials: function () {
    // Setup textures first which are not dependent on moduleTexture
    if (!OsModuleCache.materialModuleOutline) {
      OsModuleCache.materialModuleOutline = new THREE.LineBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.5,
      })
    }

    if (!OsModuleCache.materialSelectionOutline) {
      // Small screen (e.g. Mobile) should use a thinner line
      // because the user cannot zoom in as close
      var selectionOutlineLineWidth = 6

      try {
        var viewportSize = MapHelper.viewportSize()
        if (viewportSize[0] < 500 || viewportSize[1] < 500) {
          selectionOutlineLineWidth = 5
        }
      } catch (e) {
        // perhaps viewport not ready yet? Default to thick
      }

      OsModuleCache.materialSelectionOutline = new MeshLineMaterial({
        color: new THREE.Color(0xffda00),
        sizeAttenuation: 0,
        lineWidth: selectionOutlineLineWidth,
        resolution: new THREE.Vector3(600, 600),
        depthTest: false,
      })
    }

    if (!OsModuleCache.geometryDotMarker) {
      OsModuleCache.geometryDotMarker = new THREE.SphereBufferGeometry(0.3, 4, 4)
    }

    if (!OsModuleCache.materialDotMarker) {
      OsModuleCache.materialDotMarker = new THREE.MeshBasicMaterial({
        color: new THREE.Color(0xdc0000),
      })
    }

    // Setup textures dependent on moduleTexture

    var moduleTexture = this.moduleTexture()

    var moduleTexturePath

    if (moduleTexture && moduleTexture !== 'default') {
      moduleTexturePath = moduleTexture
    } else {
      moduleTexturePath = Designer.FILE_PATHS.MODULE_IMAGE_SRC
    }

    if (OsModuleCache[moduleTexture]) {
      //material already generated and cached
      return
    } else {
      OsModuleCache[moduleTexture] = {}
    }

    var OsModuleCacheForModuleCode = OsModuleCache[moduleTexture]

    if (!OsModuleCacheForModuleCode.materialMetalActive) {
      OsModuleCacheForModuleCode.materialMetalActive = buildMaterialMetalActive()
    }

    if (!OsModuleCacheForModuleCode.materialActive) {
      // Replace URL with DataURI for any embedded files
      // which bypasses CORS issues for textures
      // On web URLs would work but on tablet CORS fails for files loaded from local filesystem
      var moduleTextureUrlToLoad = window.Designer.prepareFilePathForLoad(moduleTexturePath)

      if (window.StudioPanelTextures === 'disabled') {
        // No texture, flat colour only
        // Only used in PDF for massive systems
        if (!OsModuleCacheForModuleCode.materialPanelFlatColor) {
          // Use MeshLambertMaterial because we only ever need this non-textured mode for PDF generation
          // which cannot render MeshStandardMaterial, so we must use MeshLambertMaterial instead
          OsModuleCacheForModuleCode.materialPanelFlatColor = new THREE.MeshLambertMaterial({
            color: 0x000000,
            emissive: 0x010122,
          })
        }

        OsModuleCacheForModuleCode.materialActive = OsModuleCacheForModuleCode.materialPanelFlatColor
      } else if (getStudioDetail() === 'high') {
        OsModuleCacheForModuleCode.materialActive = [
          OsModuleCacheForModuleCode.materialMetalActive, //edge
          OsModuleCacheForModuleCode.materialMetalActive, //edge
          OsModuleCacheForModuleCode.materialMetalActive, //edge
          OsModuleCacheForModuleCode.materialMetalActive, //edge
          buildMaterialPanel(moduleTextureUrlToLoad),
          OsModuleCacheForModuleCode.materialMetalActive, //backface
        ]
      } else {
        OsModuleCacheForModuleCode.materialActive = buildMaterialPanel(moduleTextureUrlToLoad)
      }
    }

    if (!OsModuleCacheForModuleCode.materialMetalInactive) {
      //Inactive materials will never show in PDF so no need to include a MeshLambertMaterial variation
      OsModuleCacheForModuleCode.materialMetalInactive = new THREE.MeshStandardMaterial({
        visible: false,
        transparent: true,
        opacity: 0.1,
        color: 0xcccccc,
        emissive: 0x666666,
        roughness: 0.3,
        metalness: 0.3,
      })
    }

    //Inactive materials will never show in PDF so no need to include a MeshLambertMaterial variation
    if (!OsModuleCacheForModuleCode.materialInactive) {
      if (getStudioDetail() === 'high') {
        OsModuleCacheForModuleCode.materialInactive = [
          OsModuleCacheForModuleCode.materialMetalInactive,
          OsModuleCacheForModuleCode.materialMetalInactive,
          OsModuleCacheForModuleCode.materialMetalInactive,
          OsModuleCacheForModuleCode.materialMetalInactive,
          new THREE.MeshStandardMaterial({
            visible: false,
            opacity: 0.1,
            transparent: true,
            color: 0xffffff,
            emissive: 0xffffff,
            roughness: 0.3,
            metalness: 0.3,
          }),
          OsModuleCacheForModuleCode.materialMetalInactive,
        ]
      } else {
        OsModuleCacheForModuleCode.materialInactive = new THREE.MeshStandardMaterial({
          visible: false,
          opacity: 0.1,
          transparent: true,
          color: 0xffffff,
          emissive: 0xffffff,
          roughness: 0.3,
          metalness: 0.3,
        })
      }
    }

    if (!OsModuleCacheForModuleCode.materialInactiveBuildable) {
      if (Array.isArray(OsModuleCacheForModuleCode?.materialActive)) {
        OsModuleCacheForModuleCode.materialInactiveBuildable = OsModuleCacheForModuleCode.materialActive.map((mat) =>
          mat.clone()
        )
        OsModuleCacheForModuleCode.materialInactiveBuildable[4].transparent = true
        OsModuleCacheForModuleCode.materialInactiveBuildable[4].opacity = 0.3
      } else {
        OsModuleCacheForModuleCode.materialInactiveBuildable = OsModuleCacheForModuleCode.materialActive.clone()
        OsModuleCacheForModuleCode.materialInactiveBuildable.transparent = true
        OsModuleCacheForModuleCode.materialInactiveBuildable.opacity = 0.3
      }
    }
  },

  userDataValidators: OsModule.userDataValidators,

  refreshUserData: function () {
    var fields = ['active', 'cell', 'size', 'shadingOverride']
    ObjectBehaviors.refreshUserData.call(this, fields)

    //shadingOverride requires special formatting
    if (!this.userData.shadingOverride) {
      this.userData.shadingOverride = []
    }

    this.userData.shadingOverride = this.detectInheritedShadingOverride()

    // We inject use_tilt_rack into userData for module because we need to be able to detect
    // which individual modules use tilt racks even when they are disassociated from
    // moduleGrids. This happens when we are counting tilt racks used for strung modules
    // which are grouped by mppt not by moduleGrid
    this.userData.use_tilt_rack = this.getGrid().isUsingTiltRacks()
    this.userData.slope = this.getGrid().getPanelTilt()
    this.userData.racks = this.getGrid().getRacksTilt()
    this.userData.azimuth = this.getAzimuth()
    this.userData.trackingMode = this.getGrid().trackingMode()
    this.userData.gcr = this.getGrid().calculateGroundCoverageRatio()

    return this.userData
  },

  applyUserData: function () {
    var fields = ['active', 'cell', 'size', 'shadingOverride']
    ObjectBehaviors.applyUserData.call(this, fields, this.userData)

    //shadingOverride requires special formatting
    if (!this.shadingOverride) {
      this.shadingOverride = []
    }

    this.setGeometryForSize()
    this.clearAnnotationCache()
  },

  setGeometryForSizeCache: null,
  setGeometryForSize: function () {
    var size = this.size

    if (
      this.setGeometryForSizeCache &&
      this.setGeometryForSizeCache[0] === size[0] &&
      this.setGeometryForSizeCache[1] === size[1]
    ) {
      // Already ran setGeometryForSize for this size. Skip for speed.
      // but refresh module outline in case the outline needs to be shown/hidden
      this.refreshModuleOutline()
      return
    }

    var geometryCacheKey = 'geometry_' + size.join(',')

    if (!OsModuleCache[geometryCacheKey]) {
      if (getStudioDetail() === 'high') {
        if (size[0] <= size[1]) {
          OsModuleCache[geometryCacheKey] = new THREE.BoxGeometry(size[0], size[1], 0.05, 1, 1, 1)
        } else {
          OsModuleCache[geometryCacheKey] = new THREE.BoxGeometry(size[1], size[0], 0.05, 1, 1, 1)
          OsModuleCache[geometryCacheKey].rotateZ(Math.PI / 2)
        }
      } else {
        if (size[0] <= size[1]) {
          OsModuleCache[geometryCacheKey] = new THREE.PlaneBufferGeometry(size[0], size[1], 1, 1)
        } else {
          OsModuleCache[geometryCacheKey] = new THREE.PlaneBufferGeometry(size[1], size[0], 1, 1)
          OsModuleCache[geometryCacheKey].rotateZ(Math.PI / 2)
        }
      }
      OsModuleCache[geometryCacheKey].excludeFromExport = true
    }
    this.geometry = OsModuleCache[geometryCacheKey]

    // If this size geometry already cached then use cache, otherwise generate and cache

    var geometryCacheKeyOutline = 'geometry_outline_' + size.join(',')

    if (!OsModuleCache[geometryCacheKeyOutline]) {
      var geometry = new THREE.Geometry()
      geometry.vertices.push(
        new THREE.Vector3(-size[0] / 2, -size[1] / 2, 0),
        new THREE.Vector3(-size[0] / 2, size[1] / 2, 0),
        new THREE.Vector3(size[0] / 2, size[1] / 2, 0),
        new THREE.Vector3(size[0] / 2, -size[1] / 2, 0)
      )
      geometry.excludeFromExport = true

      OsModuleCache[geometryCacheKeyOutline] = geometry
    }

    this.moduleOutlineGeometry = OsModuleCache[geometryCacheKeyOutline]
    this.moduleOutlineMaterial = OsModuleCache.materialModuleOutline

    this.refreshModuleOutline()

    this.setGeometryForSizeCache = [size[0], size[1]]
  },

  distanceFromScreenCenterInPixels: function (worldToScreenOffsetFromCenter) {
    // @param worldToScreenOffsetFromCenter: Pass in a function bound to the viewport
    // which can give the pixel offset from center of viewport

    var p = this.position.clone()
    this.parent.localToWorld(p)
    var coordinate = worldToScreenOffsetFromCenter(p)
    var distanceFromScreenCenterInPixels = Math.sqrt(Math.pow(coordinate.x, 2) + Math.pow(coordinate.y, 2))
    return distanceFromScreenCenterInPixels
  },

  constructor: OsModule,

  arrange: function (position, slope, azimuth) {
    this.position.copy(position)

    var slopeQuat = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), slope * THREE.Math.DEG2RAD)
    var azimuthQuat = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), -azimuth * THREE.Math.DEG2RAD)
    this.quaternion.copy(new THREE.Quaternion())
    this.quaternion.multiply(slopeQuat)
    this.quaternion.multiply(azimuthQuat)
  },

  getGrid: function () {
    if (this.parent && this.parent.type === 'OsModuleGrid') {
      return this.parent
    } else {
      return null
    }
  },

  hasTiltRack: function () {
    return this.getGrid().isUsingTiltRacks()
  },

  onChange: function (editor) {
    if (this.getGrid()) {
      //
    } else {
      ObjectBehaviors.floatingOnFacetOnChange.call(this, editor)
    }

    // Allow regeneration of annotation on next update
    this.clearAnnotationCache()
  },

  getFacet: function () {
    if (this.getGrid()) {
      return this.getGrid().facet
    } else {
      console.log('Error: getPlane but no grid found... can we detect plane as object we are floating on, if any?')
    }
  },

  getSystem: function () {
    if (this.parent && this.parent.getSystem) {
      return this.parent.getSystem()
    } else {
      return null
    }
  },

  setActivation: function (activation, propagate, gridOverrideForSpeed, inactiveModuleVisibilityOverrideForSpeed) {
    var grid = gridOverrideForSpeed || this.getGrid()

    var visible
    if (activation) {
      visible = true
    } else if (grid) {
      if (inactiveModuleVisibilityOverrideForSpeed === true || inactiveModuleVisibilityOverrideForSpeed === false) {
        visible = inactiveModuleVisibilityOverrideForSpeed
      } else {
        visible = grid.determineInactiveModuleVisibility()
      }
    } else {
      visible = true
    }

    var changed = this.active !== activation || this.visible !== visible

    this.active = activation
    this.visible = visible
    this.selectable = visible
    this.userData.excludeFromExport = !this.active
    this.userData.active = this.active
    this.castShadow = this.active && getStudioDetail() === 'high'
    this.receiveShadow = this.active && getStudioDetail() === 'high'

    if (changed) {
      this.refreshMaterial()

      if (activation === false && this.assignedOsString) {
        this.assignedOsString.removeModule(this)
      }

      //Update parent ModuleGrid
      if (propagate !== false) {
        if (grid) {
          grid.setActivationForModule(this, this.active)
        }
      }
    }
    this.clearAnnotationCache()

    return changed
  },

  clearDotMarker: function (editor) {
    if (this.dotMarker) {
      if (editor) editor.removeObject(this.dotMarker, false)
      this.dotMarker = null
    }
  },

  refreshDotMarker: function (editor) {
    this.clearDotMarker(editor)

    if (!this.parent || this.assignedOsString || !Designer._unstrungModuleDotVisibility) {
      // no parent || has string || visibility is off
      return
    }

    var marker = new THREE.Mesh(OsModuleCache.geometryDotMarker, OsModuleCache.materialDotMarker)
    this.parent.updateMatrixWorld()
    marker.name = 'Unstrung Panel Dot Marker'
    marker.type = 'OsDotMarker'
    marker.position = this.position
    marker.userData.excludeFromExport = true
    // marker.selectionDelegate = this
    if (editor) editor.addObject(marker, this, false)
    this.dotMarker = marker
  },

  refreshMaterial: function () {
    this.generateAndCacheMaterials()

    var OsModuleCacheForModuleCode = OsModuleCache[this.moduleTexture()]

    if (!OsModuleCacheForModuleCode) {
      console.warn('skip refreshMaterial(): OsModuleCacheForModuleCode not yet created?')
    } else {
      this.material = this.active
        ? OsModuleCacheForModuleCode.materialActive
        : OsModuleCacheForModuleCode.materialInactive
    }

    this.refreshModuleOutline()
  },

  getCellCoordinates: function () {
    return this.cell.split(',').map((v) => parseInt(v))
  },

  // Disable until buffer functionality is finalized
  // getBufferToAdjacentCell: function (_selectionEdgeIndex) {
  //   var cellCoordinates = this.getCellCoordinates()
  //   var dx = [-1, 0, 1, 0][_selectionEdgeIndex]
  //   var dy = [0, 1, 0, -1][_selectionEdgeIndex]
  //
  //   // if next panel also has same selection edge selected then offset by
  //   // this.selectionOutlineEdges
  //   var adjacentCell = cellCoordinates[0] + dx + ',' + (cellCoordinates[1] + dy)
  //
  //   var concaveChecks = [
  //     { relativeCellToCheckForConcave: [-1, -1], edgeType: 3 },
  //     { relativeCellToCheckForConcave: [-1, 1], edgeType: 0 },
  //     { relativeCellToCheckForConcave: [1, 1], edgeType: 1 },
  //     { relativeCellToCheckForConcave: [1, -1], edgeType: 2 },
  //   ]
  //
  //   if (
  //     this.parent.moduleObjects[adjacentCell] &&
  //     this.parent.moduleObjects[adjacentCell].active &&
  //     this.parent.moduleObjects[adjacentCell].selectionOutlineEdges &&
  //     this.parent.moduleObjects[adjacentCell].selectionOutlineEdges.indexOf(_selectionEdgeIndex) !== -1
  //   ) {
  //     // Draw buffer because the edge continues on the next cell
  //     return [dx ? this.parent.gridSpacing[0] : 0, dy ? this.parent.gridSpacing[1] : 0]
  //   }
  //
  //   var concaveCheckCell =
  //     cellCoordinates[0] +
  //     concaveChecks[_selectionEdgeIndex].relativeCellToCheckForConcave[0] +
  //     ',' +
  //     (cellCoordinates[1] + concaveChecks[_selectionEdgeIndex].relativeCellToCheckForConcave[1])
  //
  //   // Draw buffer because the adjacent edge continues on the next cell to make a continuous concave edge
  //   // I don't know how to do this efficiently... switching to do all edge drawing at the OsModuleGrid level... :-(
  //   // EdgeType 2 is concave if the
  //
  //   if (
  //     concaveChecks[_selectionEdgeIndex] &&
  //     this.parent.moduleObjects[concaveCheckCell] &&
  //     this.parent.moduleObjects[concaveCheckCell].active &&
  //     this.parent.moduleObjects[concaveCheckCell].selectionOutlineEdges &&
  //     this.parent.moduleObjects[concaveCheckCell].selectionOutlineEdges.indexOf(
  //       concaveChecks[_selectionEdgeIndex].edgeType
  //     ) !== -1
  //   ) {
  //     return [dx ? this.parent.gridSpacing[0] : 0, dy ? this.parent.gridSpacing[1] : 0]
  //   }
  //
  //   // otherwise always return 0,0
  //   return [0, 0]
  // },

  updateOutlineOnSelected: function () {
    // New optimized function is now available
    // return this.updateOutlineOnSelected1()
    return this.updateOutlineOnSelected2()
  },

  updateOutlineOnSelected2: function () {
    if (this.selectionOutline && this.selectionOutline.parent) {
      // Already exists, no need to redraw
      this.selectionOutline.visible = true
    } else {
      var meshLine = new MeshLine()
      var geometry = new THREE.Geometry()
      geometry.vertices.push(
        new THREE.Vector3(-this.size[0] / 2, -this.size[1] / 2, 0),
        new THREE.Vector3(-this.size[0] / 2, this.size[1] / 2, 0),
        new THREE.Vector3(this.size[0] / 2, this.size[1] / 2, 0),
        new THREE.Vector3(this.size[0] / 2, -this.size[1] / 2, 0),
        new THREE.Vector3(-this.size[0] / 2, -this.size[1] / 2, 0)
      )
      geometry.excludeFromExport = true
      meshLine.setGeometry(geometry)
      var material = OsModuleCache.materialSelectionOutline
      this.selectionOutline = new THREE.Mesh(meshLine, material)
      this.selectionOutline.selectable = false
      this.selectionOutline.userData.excludeFromExport = true
      this.selectionOutline.visible = true
      editor.addObject(this.selectionOutline, this, false) // Do not dispatch signals, heavy & not required
    }
    return
  },

  updateOutlineOnSelected1: function () {
    if (this.selectionOutline && this.selectionOutline.parent) {
      // Already exists, no need to redraw
      this.selectionOutline.visible = true
    } else {
      var meshLine = new MeshLine()
      var geometry = new THREE.Geometry()
      geometry.vertices.push(
        new THREE.Vector3(-this.size[0] / 2, -this.size[1] / 2, 0),
        new THREE.Vector3(-this.size[0] / 2, this.size[1] / 2, 0),
        new THREE.Vector3(this.size[0] / 2, this.size[1] / 2, 0),
        new THREE.Vector3(this.size[0] / 2, -this.size[1] / 2, 0),
        new THREE.Vector3(-this.size[0] / 2, -this.size[1] / 2, 0)
      )
      geometry.excludeFromExport = true
      meshLine.setGeometry(geometry)
      var material = OsModuleCache.materialSelectionOutline
      this.selectionOutline = new THREE.Mesh(meshLine, material)
      this.selectionOutline.selectable = false
      this.selectionOutline.userData.excludeFromExport = true
      this.selectionOutline.visible = true
      editor.addObject(this.selectionOutline, this, false) // Do not dispatch signals, heavy & not required
    }
    return

    // Disable until buffer functionality is finalized
    // if (this.selectionOutlineEdges && this.selectionOutlineEdges.length > 0) {
    //   if (!this.selectionOutline) {
    //     this.selectionOutline = new THREE.Object3D()
    //     this.selectionOutline.selectionEdgesActive = [null, null, null, null]
    //   }
    //   this.selectionOutline.selectable = false
    //   this.selectionOutline.userData.excludeFromExport = true
    //   this.selectionOutline.visible = true
    //
    //   // Check each edge and either add/remove/ignore
    //   for (var selectionEdgeIndex = 0; selectionEdgeIndex < 4; selectionEdgeIndex++) {
    //     if (this.selectionOutlineEdges.indexOf(selectionEdgeIndex) !== -1) {
    //       // edge should show
    //       if (this.selectionOutline.selectionEdgesActive[selectionEdgeIndex]) {
    //         // already exists, do nothing
    //       } else {
    //         //missing, draw now
    //         var meshLine = new MeshLine()
    //         var geometry = new THREE.Geometry()
    //
    //         // Buffer will always draw out in the clockwise direction
    //         // 0 >> buffer to right
    //         // 1 >> buffer down
    //         // 2 >> buffer left
    //         // 3 >> buffer up
    //         //
    //         // We draw the points in a clockwise direction below, so we can simply modify the second point in each line
    //         var buffer = !this.parent.panelTiltOverride ? this.getBufferToAdjacentCell(selectionEdgeIndex) : [0, 0]
    //
    //         if (selectionEdgeIndex === 0) {
    //           //buffer = [this.parent.gridSpacing[0], 0]
    //
    //           geometry.vertices.push(
    //             new THREE.Vector3(-this.size[0] / 2, this.size[1] / 2, 0),
    //             new THREE.Vector3(this.size[0] / 2 + buffer[0], this.size[1] / 2, 0)
    //           )
    //         } else if (selectionEdgeIndex === 1) {
    //           //buffer = [0, this.parent.gridSpacing[1]]
    //           geometry.vertices.push(
    //             new THREE.Vector3(this.size[0] / 2, this.size[1] / 2, 0),
    //             new THREE.Vector3(this.size[0] / 2, -this.size[1] / 2 - buffer[1], 0)
    //           )
    //         } else if (selectionEdgeIndex === 2) {
    //           //buffer = [-this.parent.gridSpacing[0], 0]
    //           geometry.vertices.push(
    //             new THREE.Vector3(this.size[0] / 2, -this.size[1] / 2, 0),
    //             new THREE.Vector3(-this.size[0] / 2 - buffer[0], -this.size[1] / 2, 0)
    //           )
    //         } else if (selectionEdgeIndex === 3) {
    //           //buffer = [0, -this.parent.gridSpacing[1]]
    //           geometry.vertices.push(
    //             new THREE.Vector3(-this.size[0] / 2, -this.size[1] / 2, 0),
    //             new THREE.Vector3(-this.size[0] / 2, this.size[1] / 2 + buffer[1], 0)
    //           )
    //         }
    //
    //         geometry.excludeFromExport = true
    //         meshLine.setGeometry(geometry)
    //         var material = OsModuleCache.materialSelectionOutline
    //         var selectionOutlineMeshline = new THREE.Mesh(meshLine, material)
    //         selectionOutlineMeshline.selectable = false
    //         selectionOutlineMeshline.userData.excludeFromExport = true
    //         selectionOutlineMeshline.visible = true
    //         editor.addObject(selectionOutlineMeshline, this.selectionOutline)
    //
    //         this.selectionOutline.selectionEdgesActive[selectionEdgeIndex] = selectionOutlineMeshline
    //       }
    //     } else {
    //       //selection edge should now show
    //       if (this.selectionOutline.selectionEdgesActive[selectionEdgeIndex]) {
    //         //currently showing, we should remove it
    //         editor.removeObject(this.selectionOutline.selectionEdgesActive[selectionEdgeIndex])
    //         delete this.selectionOutline.selectionEdgesActive[selectionEdgeIndex]
    //       }
    //     }
    //   }
    //
    //   if (!this.selectionOutline.parent) {
    //     editor.addObject(this.selectionOutline, this)
    //   }
    // } else if (this.selectionOutline) {
    //   editor.removeObject(this.selectionOutline)
    //   this.selectionOutline = null
    // }
  },

  moduleTexture: function (value) {
    if (typeof value === 'undefined') {
      return this._moduleTexture ? this._moduleTexture : 'default'
    }

    if (window.SWAP_DOMAIN_FUNC) {
      // Replace domain if necessary for PDF when API domain might differ
      value = window.SWAP_DOMAIN_FUNC(value)
    }

    if (this._moduleTexture !== value) {
      this._moduleTexture = value
      this.refreshMaterial()
    }
  },

  getPermissionCheck: function () {
    return Designer?.permissions.canEdit('systems.panels')
  },

  getContextMenuItems: function (position) {
    if (!this.active) {
      return []
    }

    let _this = this
    let items = []

    if (OsString.visible()) {
      items = editor.filter('type', 'OsString').map(function (es) {
        var selected = _this.assignedOsString === es
        var starIfSelected = selected ? ' *' : ''

        return {
          label:
            window.translate('Assign to string (%{stringSummary})', { stringSummary: es.getSummary() }) +
            starIfSelected,
          useHTML: true,
          selected: selected,
          onClick: function () {
            //es.addModule(_this)
            editor.execute(new StringModuleArrayAssignmentCommand(es, [_this]))
            editor.signals.objectChanged.dispatch(es)
          },
        }
      })
    }

    // items.push({
    //   label: window.translate('Inspect Shading'),
    //   useHTML: false,
    //   selected: false,
    //   onClick: function () {
    //     console.log(_this.debugShadingText())
    //   },
    // })

    // items.push({
    //   label: window.translate('Select Panel'),
    //   useHTML: false,
    //   selected: false,
    //   onClick: function () {
    //     var ignoreSelectionDelegate = true
    //     editor.select(_this, ignoreSelectionDelegate)
    //   },
    // })

    if (_this.parent?.type === 'OsModuleGrid') {
      items.push(..._this.parent?.getContextMenuItems(position))
    }

    return items
  },

  getSlope: function () {
    // Use override if set on the panel group, otherwise use panel group slope
    return this.getGrid().getPanelTilt()
  },

  getAzimuth: function () {
    const moduleGrid = this.getGrid()
    // in a dual-tilt module grid, modules that are "back-facing" are those facing OPPOSITE
    // the indicate module grid azimuth
    return this.isFrontFacing() ? moduleGrid.getAzimuth() : (moduleGrid.getAzimuth() + 180) % 360
  },

  isBackFacing: function () {
    // in a dual-tilt module grid, a module that has a local z-rotation of 180 degrees is facing
    // OPPOSITE the indicated module grid azimuth
    return this.rotation.z * THREE.Math.RAD2DEG === 180
  },

  isFrontFacing: function () {
    // in a dual-tilt module grid, a module that has a local z-rotation of 0 degrees is facing
    // the indicated module grid azimuth
    return this.rotation.z * THREE.Math.RAD2DEG === 0
  },

  getAzimuthalSubset: function () {
    return this.isFrontFacing() ? OsModuleGrid.AzimuthalSubsets.Front : OsModuleGrid.AzimuthalSubsets.Back
  },

  getPlane2: function () {
    // Fixed old version which did not account for tilt racks
    var slope = this.getSlope()
    var azimuth = this.getAzimuth()
    var plane = OsFacet.planeFromAzimuthSlopeCentriod(azimuth, slope, this.getWorldPosition(this.position.clone()))
    return plane
  },

  getPlane: function () {
    // Beware: Does not account for tilt racks. Use getPlane2 instead.

    var grid = this.getGrid()
    if (grid) {
      return this.getGrid().getPlane()
    } else {
      return null
    }
  },

  getCell: function () {
    //return as array instead of csv string
    //we should really always store it as array instead of string
    return this.cell.split(',').map(parseFloat)
  },

  belongsToSelectedGroup: function () {
    return (
      editor.selected.type === 'OsGroup' && editor.selected.objects.some((object) => object.uuid === this.parent.uuid)
    )
  },

  refreshModuleOutline: function () {
    // If active then do not even bother drawing outline.
    // Clear if already exists, then exit.
    //
    // Show outline on active panels if StudioPanelTextures are disabled
    // which avoids panels looking like a big black conjoined blob.
    if (this.parent && this.parent.getBoxHelperVisibility()) {
      this.hideSelectionOutline()
      return
    }
    var isSelected =
      editor.selected && this.parent && (editor.selected.uuid === this.parent.uuid || this.belongsToSelectedGroup())
    if (this.active && isSelected) {
      this.updateOutlineOnSelected()
      return
    } else {
      this.hideSelectionOutline()
    }

    if (this.active && window.StudioPanelTextures !== 'disabled') {
      this.clearModuleOutline()
      return
    }

    var geometry = this.moduleOutlineGeometry
    var material = this.moduleOutlineMaterial

    if (!geometry || !material) {
      return
    }

    if (this.moduleOutline) {
      this.moduleOutline.geometry = geometry
      if (!this.moduleOutline.parent) {
        editor.addObject(this.moduleOutline, this)
      }
    } else {
      this.moduleOutline = new THREE.LineLoop(geometry, material)
      this.moduleOutline.selectable = false
      this.moduleOutline.name = 'ModuleOutline'
      this.moduleOutline.userData.excludeFromExport = true
      //this.moduleOutline.selectionDelegate = this
      //this.moduleOutline.getSystem = this.getSystem
      this.moduleOutline.visible = true

      editor.addObject(this.moduleOutline, this)
    }
  },

  clearModuleOutline: function () {
    if (this.moduleOutline) {
      editor.removeObject(this.moduleOutline, false)
    }
  },

  hideSelectionOutline: function () {
    if (this.selectionOutline) {
      editor.removeObject(this.selectionOutline, false)
      this.selectionOutline = null
      // this.selectionOutline.visible = false
    }
  },

  onRemove: function () {
    this.clearModuleOutline()
    if (this.assignedOsString) {
      this.assignedOsString.removeModule(this)
    }
  },

  calculateShadingFromMesh: function (mesh, save) {
    var osModuleWorldPosition = this.getWorldPosition(new THREE.Vector3())
    var distanceFromSunToModule = 100.0
    var raycaster = new THREE.Raycaster()

    var day = 0
    var results = []
    Utils.daysInMonth.forEach(function (daysInMonth) {
      for (var hourUTC = 0; hourUTC < 24; hourUTC++) {
        // @TODO: Quick fill non sun-hours some simple way
        // if (hourUTC < 6 || hourUTC > 20) {
        //   results.push(null) // sun not up, shading irrelevant
        //   continue // next hourUTC
        // }
        // console.log('day', day, hourUTC)
        // Do not set target yet, we want to calculate direction first
        var sunPositionRelative = Utils.sunPositionAtDateTimeUTC(day, hourUTC, 120, -25, null, distanceFromSunToModule)
        var sunPositionWorld = new THREE.Vector3().addVectors(osModuleWorldPosition, sunPositionRelative)
        var direction = sunPositionRelative.clone().negate().normalize()
        // console.log(sunPositionRelative, sunPositionWorld, direction)

        raycaster.set(sunPositionWorld, direction)

        // Check which object the ray intersects first: module (sun) or the mesh (shade)
        // We know the ray will always intersect the module, so rather than checking for intersection with the module,
        // simply measure the distance and compare with the distance to the mesh (if module is closer then sunny)
        // Also, we define the distance from sun to module as a constant, so we only need to check the mesh intersction
        // distance compared to our constant.
        var meshIntersection = raycaster.intersectObject(mesh)

        // @TODO: What do we do if there is no intersection with the mesh
        // Let's assume that no intersection with the mesh means the ray reaches the panel (i.e. sun)
        if (!meshIntersection || meshIntersection.length === 0) {
          console.log('warning: no mesh intersction at ', day, hourUTC)
          results.push(1) // sun
        } else if (meshIntersection[0].distance < distanceFromSunToModule) {
          results.push(0) // shade, ray hit mesh before it hits the module
        } else {
          results.push(1) // sun
        }
      }
      day += daysInMonth
    }, this)

    if (save) {
      this.shadingOverride = results
    }

    return results
  },

  getOrientation: function () {
    return this.getGrid().moduleLayout()
  },

  getPositionWorld: function () {
    return this.parent.localToWorld(this.position.clone())
  },

  getPointsGridOnModule: function (cols, rows) {
    // Note: this.size is already adjusted based on orientation

    if (cols > 1 || rows > 1) {
      var positions = []
      var xSpacing
      var ySpacing

      if (this.getOrientation() === 'portrait') {
        xSpacing = this.size[0] / cols
        ySpacing = this.size[1] / rows

        for (let row = 0; row < rows + 1; row++) {
          for (let col = 0; col < cols + 1; col++) {
            positions.push(
              this.localToWorld(new THREE.Vector3(col * xSpacing - this.size[0] / 2, row * ySpacing - this.size[1] / 2))
            )
          }
        }
      } else {
        //landscape
        xSpacing = this.size[0] / rows
        ySpacing = this.size[1] / cols

        for (let col = 0; col < rows + 1; col++) {
          for (let row = 0; row < cols + 1; row++) {
            positions.push(
              this.localToWorld(new THREE.Vector3(col * xSpacing - this.size[0] / 2, row * ySpacing - this.size[1] / 2))
            )
          }
        }
      }

      return positions
    }

    // 1,1 or not set
    return [this.localToWorld(new THREE.Vector3())]
  },

  getRaytracePositions: function (cols = null, rows = null) {
    // Note: this.size is already adjusted based on orientation

    //@TODO: Try to optimize by passing in cols and rows to avoid detecting each time
    if (!cols || !rows) {
      var moduleType = this.getSystem().moduleType()
      var { cols, rows } = moduleType.getSubstringLayout()
    }

    if (cols > 1 || rows > 1) {
      var positions = []
      var xSpacing
      var ySpacing

      if (this.getOrientation() === 'portrait') {
        xSpacing = this.size[0] / (cols + 1)
        ySpacing = this.size[1] / (rows + 1)

        for (var row = 0; row < rows; row++) {
          for (var col = 0; col < cols; col++) {
            positions.push(
              this.localToWorld(
                new THREE.Vector3((col + 1) * xSpacing - this.size[0] / 2, (row + 1) * ySpacing - this.size[1] / 2)
              )
            )
          }
        }
      } else {
        //landscape
        xSpacing = this.size[0] / (rows + 1)
        ySpacing = this.size[1] / (cols + 1)

        for (var col = 0; col < rows; col++) {
          for (var row = 0; row < cols; row++) {
            positions.push(
              this.localToWorld(
                new THREE.Vector3((col + 1) * xSpacing - this.size[0] / 2, (row + 1) * ySpacing - this.size[1] / 2)
              )
            )
          }
        }
      }

      return positions
    }

    // 1,1 or not set
    return [this.localToWorld(new THREE.Vector3())]
  },

  hasShadingOverride: function () {
    return this.getGrid().hasShadingOverride() || this.getSystem().hasShadingOverride()
  },

  detectInheritedShadingOverride: function () {
    var system = this.getSystem()
    var grid = this.getGrid()

    if (system && system.hasShadingOverride()) {
      return system.shadingOverride
    } else if (grid && grid.hasShadingOverride()) {
      return grid.shadingOverride
    } else if (this.shadingOverride) {
      return this.shadingOverride
    } else {
      return []
    }
  },

  calculateShadingFromElevationGrid: function (
    elevationGrid,
    elevationGridMax,
    gridParams,
    save,
    useOldResultsIfAvailable,
    cacheSunPositionAtDateTimeUTC,
    preGeneratedRaytraceResults
  ) {
    // Hourly shading is in UTC, not local time

    if (useOldResultsIfAvailable && this.shadingOverride && this.shadingOverride.length === 288) {
      return this.shadingOverride
    }

    //@TODO Remove hack
    var lon = window.SceneHelper.getLongitude()
    var lat = window.SceneHelper.getLatitude()
    var horizon = editor.scene.horizon

    var substringLayout = this.getSystem().moduleType().getSubstringLayout()

    var raytracePositions = this.getRaytracePositions()

    var results = OsModule.calculateShadingFromElevationGridWorker(
      this.getGrid().getPanelTilt(),
      this.getAzimuth(),
      substringLayout,
      raytracePositions,
      elevationGrid,
      elevationGridMax,
      lon,
      lat,
      horizon,
      gridParams,
      this.uuid,
      cacheSunPositionAtDateTimeUTC,
      preGeneratedRaytraceResults
    )

    if (save) {
      this.shadingOverride = results.shadingOverride
      this.shadingOverrideRaw = results.raytraceResults
    }

    return {
      uuid: this.uuid,
      shadingOverride: results.shadingOverride,
      shadingOverrideRaw: results.raytraceResults,
    }
  },

  shadingPoints: function () {
    var t = SceneHelper.getTime()

    // console.log('mixed', t.hour288, 'month:' + t.month, 'hour:' + t.hourLocal, 'hour288:' + t.hour288)

    if (!OsModuleCache.ModuleShadingPointShade) {
      OsModuleCache.ModuleShadingPointShade = new THREE.MeshLambertMaterial({
        color: 0xff0000,
        emissive: 0xff0000,
      })
    }

    if (!OsModuleCache.ModuleShadingPointSun) {
      OsModuleCache.ModuleShadingPointSun = new THREE.MeshLambertMaterial({
        color: 0x00ff00,
        emissive: 0x00ff00,
      })
    }

    if (!OsModuleCache.ModuleShadingPointMismatch) {
      OsModuleCache.ModuleShadingPointMismatch = new THREE.MeshLambertMaterial({
        color: 0xffcc00,
        emissive: 0xffcc00,
      })
    }

    if (!OsModuleCache.ModuleShadingPointNone) {
      OsModuleCache.ModuleShadingPointNone = new THREE.MeshLambertMaterial({
        color: 0xcccccc,
        emissive: 0xcccccc,
      })
    }

    if (!OsModuleCache.ModuleShadingPointGeometry) {
      OsModuleCache.ModuleShadingPointGeometry = new THREE.SphereBufferGeometry(0.05)
      OsModuleCache.ModuleShadingPointGeometry.excludeFromExport = true
    }

    var moduleType = this.getSystem().moduleType()

    var { cols, rows } = moduleType.getSubstringLayout()

    var raytracePositions = this.getRaytracePositions(cols, rows)
    var substringIsShaded

    for (var cellIndex = 0; cellIndex < cols * rows; cellIndex++) {
      if (!this.shadingOverrideRaw || !this.shadingOverrideRaw[cellIndex]) {
        console.warn('Shading not found for cell')
        break
      }

      // Snap to nearest hour integer
      var raytraceResult = this.shadingOverrideRaw ? this.shadingOverrideRaw[cellIndex][Math.round(t.hourUTC288)] : null
      var material
      if (raytraceResult === true) {
        // We only need to tests panels for mismatch if they are in the sun
        try {
          substringIsShaded = this.shadingOverrideRaw
            ? moduleType
                .getAllCellsInSubstring(cellIndex)
                .map((siblingCellIndex) => this.shadingOverrideRaw[siblingCellIndex][Math.round(t.hourUTC288)])
                .every(Boolean) !== true
            : null
        } catch (err) {
          console.warn(err)
          substringIsShaded = true
        }
        if (substringIsShaded) {
          // This cell has sun but the whole substring is shaded, show that it's blocked
          material = OsModuleCache.ModuleShadingPointMismatch
        } else {
          material = OsModuleCache.ModuleShadingPointSun
        }
      } else if (raytraceResult === false) {
        material = OsModuleCache.ModuleShadingPointShade
      } else {
        material = OsModuleCache.ModuleShadingPointNone
      }

      var mesh = new THREE.Mesh(OsModuleCache.ModuleShadingPointGeometry, material)
      mesh.type = 'ModuleShadingPoint'
      mesh.excludeFromExport = true
      mesh.position.copy(raytracePositions[cellIndex])
      editor.addObject(mesh, editor.sceneHelpers, false) // skip signals which can be super slow
    }

    // Draw substrings
    // SceneHelper.getSubstringLayout().sample_to_substring.forEach(sampleIndices => {
    //   if(sampleIndices.length > 0){
    //
    //     // @TODO: Draw line and use color to show whether the substring is shaded or not,
    //     // drawLine(sampleIndices.map(cellIndex => raytracePositions[cellIndex]) )
    //
    //   }
    // })
  },

  shadingForMonth: function (month) {
    return this.shadingOverride.filter(function (value, index) {
      return index >= month * 24 && index < (month + 1) * 24
    })
  },

  debugShadingText: function () {
    if (this.shadingOverride) {
      var printValue = function (value) {
        if (value === true) {
          return '1'
        } else if (value === false) {
          return '0'
        } else {
          return '-'
        }
      }

      var index = 0
      var day = 0
      var results = []
      var hoursInSun = 0
      var hoursInShade = 0
      Utils.daysInMonth.forEach(function (daysInMonth) {
        var hoursInSunForDay = 0
        var hoursInShadeForDay = 0

        var shadingForDay = ''
        for (var hour = 0; hour < 24; hour++) {
          if (this.shadingOverride[index] === true) {
            hoursInSunForDay++
            hoursInSun++
          } else if (this.shadingOverride[index] === false) {
            hoursInShadeForDay++
            hoursInShade++
          } else {
            //
          }
          shadingForDay += printValue(this.shadingOverride[index])
          index++
        }
        var simpleSunAccessPercentageForDay = Math.round(
          (100 * hoursInSunForDay) / (hoursInSunForDay + hoursInShadeForDay)
        )

        results.push(shadingForDay + ' ' + simpleSunAccessPercentageForDay + '% ' + day)
        day += daysInMonth
      }, this)
      var percent = Math.round((100 * hoursInSun) / (hoursInSun + hoursInShade))
      return window.translate('Annual Sun Access: %{percent}%', { percent }) + '\n\n' + results.join('\n')
    } else {
      console.log('shadingOverride not set')
    }
  },

  debugShading: function () {
    if (this.shadingOverride) {
      var printValue = function (value) {
        if (value === true) {
          return '1'
        } else if (value === false) {
          return '0'
        } else if (value === null) {
          return 'N/A'
        } else {
          return 'Unknown'
        }
      }

      var index = 0
      var day = 0
      var results = []
      Utils.daysInMonth.forEach(function (daysInMonth) {
        for (var hour = 0; hour < 24; hour++) {
          results.push(day + ' ' + hour + ':00' + ': ' + printValue(this.shadingOverride[index]))
          index++
        }
        day += daysInMonth
      }, this)
      console.log(results)
    } else {
      console.log('shadingOverride not set')
    }
  },

  getAnnotationCache: null,

  clearAnnotationCache: function () {
    this.getAnnotationCache = null
  },

  getAnnotation: function () {
    if (this.getAnnotationCache === null) {
      var content = null

      if (!this.shadingOverride || this.shadingOverride.length !== 288) {
        //If using terrain we know this will be recalculated later so show that shading calcs are pending
        if (editor.getTerrain()) {
          content = '...'
        } else {
          content = null
        }
      } else {
        var grid = this.getGrid()
        var weightings = window.ShadeHelper.getOrBuildCacheSunAlignmentDateTimeUTC(
          window.SceneHelper.getLongitude(),
          window.SceneHelper.getLatitude(),
          grid.getPanelTilt(),
          grid.getAzimuth()
        )
        var beamSunAccessPercentage = ShadeHelper.percentageSun(this.shadingOverride, weightings)
        var diffuseSunAccessPercentage = 100 * (1 - grid.diffuseShading)
        var diffuseWeighting = 0.15
        var weightedSunAccessPercentage = Math.round(
          beamSunAccessPercentage * (1 - diffuseWeighting) + diffuseSunAccessPercentage * diffuseWeighting
        )

        content = weightedSunAccessPercentage + '%'
      }

      if (!content) {
        this.getAnnotationCache = false // false ensures it will not be calculated again until reset to null
      } else {
        this.getAnnotationCache = {
          content: content,
          position: this.getWorldPosition(new THREE.Vector3()),
        }
      }
    }

    return this.getAnnotationCache
  },

  onSelect: function () {},

  onDeselect: function () {},

  calculateShading: function (elevationGrid, elevationGridMax, lon, lat, gridParams, preGeneratedRaytraceResults) {
    // Runs async, when all requested shading calcs are completed
    // the global promise `window.shadingOnReady` will be resolved
    ShadeHelper.calculateShadingForModule({
      slope: this.getSlope(),
      azimuth: this.getAzimuth(),
      substringLayout: this.getSystem().moduleType().getSubstringLayout(),
      raytracePositions: this.getRaytracePositions(),
      elevationGrid: elevationGrid,
      elevationGridMax: elevationGridMax,
      lon: lon,
      lat: lat,
      horizon: editor.scene.horizon,
      gridParams: gridParams,
      uuid: this.uuid,
      cacheSunPositionAtDateTimeUTC: ShadeHelper.getOrBuildCacheSunPositionAtDateTimeUTC(lon, lat),
      preGeneratedRaytraceResults,
    })
  },

  shadingOverrideRawMean: function () {
    /*
    Average all raytraced samples to a single value per hour
    */
    var values = Array(288).fill(null)

    if (!this.shadingOverrideRaw?.length) {
      throw new Error('shading simulation results not available')
    } else {
      for (var h = 0; h < 288; h++) {
        // If sun is behind first panel then exit and just return null
        // There is no need to process any other panels
        // And since we initialized the array with null values, we can just exis without making any update
        if (this.shadingOverrideRaw[0][h] === null) {
          // skip to next hour, leave values[h] as null
          continue
        } else {
          var sumForHour = 0
          for (var i = 0; i < this.shadingOverrideRaw.length; i++) {
            sumForHour += this.shadingOverrideRaw[i][h]
          }
          values[h] = sumForHour / this.shadingOverrideRaw.length
        }
      }
    }
    return values
  },
})

OsModule.getClosestShadeSample = function (preGeneratedRaytraceResults, x, y) {
  /*
  Units for params `x` and `y` are: world units (meters) from scene origin
  
  This technically gives the closest manhattan distance, not actual distance, but manhattan
  distance is good enough for this purpose and is much faster.
  */
  return preGeneratedRaytraceResults.reduce((a, b) => {
    // @TODO: Pre-calculate the distances for each point so we only ever need one distance calc per point
    var da = Math.abs(a.x - x) + Math.abs(a.y - y)
    var db = Math.abs(b.x - x) + Math.abs(b.y - y)
    return db < da ? b : a
  })
}

const getModuleShading = (raytraceResults, substringLayout) => {
  const sampleToSubstring = substringLayout.sample_to_substring

  const averageSubstringShading = (index) => {
    const substringsShading = sampleToSubstring.map((substring, substringIndex) => {
      const unshadedPoints = substring
        .map((rayTraceIndex) => raytraceResults[rayTraceIndex][index])
        .filter((raytraceResult) => raytraceResult === false && raytraceResult !== null).length

      const numPoints = sampleToSubstring[substringIndex].length
      return unshadedPoints / numPoints
    })
    return substringsShading.reduce((a, b) => a + b) / substringsShading.length
  }

  const standardSubstringShading = (index) => {
    const substringsShading = sampleToSubstring
      .map((sampleIndicesForSubstring) =>
        sampleIndicesForSubstring
          .map((sampleIndex) => raytraceResults[sampleIndex][index])
          // every sample in the substring must be either unshaded (or no direct sun)
          .some((raytraceResult) => raytraceResult === false && raytraceResult !== null)
      )
      .filter(Boolean).length

    return substringsShading / sampleToSubstring.length
  }

  const shadingOverride = []
  for (var i = 0; i < 288; i++) {
    //quick check first value if no direct sun set to null
    if (raytraceResults[0][i] === null) {
      shadingOverride.push(null)
    } else {
      shadingOverride.push(
        substringLayout.substring_averaging ? averageSubstringShading(i) : standardSubstringShading(i)
      )
    }
  }
  return shadingOverride
}

OsModule.getClosestOutputSample = function (osModule, outputSamples) {
  /*
  Units for params `x` and `y` are: mercator meters in projecion 3857

  outputSamples is a list of features in the format:

    {"type":"Feature","geometry":{"type":"Point","coordinates":[-13612225.035206,4562032.763713]},"properties":{"kwh_per_kw":1817}}
  
  This technically gives the closest manhattan distance, not actual distance, but manhattan
  distance is good enough for this purpose and is much faster.
  */

  // @TODO: Processing all points in parallel would avoid a number of unnecessary transformations per panel
  const moduleWorldPosition = osModule.getWorldPosition(new THREE.Vector3())
  const modulePosition3857 = SceneHelper.scenePositionsTo3857UsingSceneOrigin(
    [moduleWorldPosition],
    editor.scene.sceneOrigin4326
  )[0]

  return outputSamples.reduce((a, b) => {
    // @TODO: Pre-calculate the distances for each point so we only ever need one distance calc per point
    var da =
      Math.abs(a.geometry.coordinates.x - modulePosition3857[0]) +
      Math.abs(a.geometry.coordinates.y - modulePosition3857[1])
    var db =
      Math.abs(b.geometry.coordinates.x - modulePosition3857[0]) +
      Math.abs(b.geometry.coordinates.y - modulePosition3857[1])
    return db < da ? b : a
  })
}

OsModule.calculateShadingFromElevationGridWorker = function (
  slope,
  azimuth,
  substringLayout,
  raytracePositions,
  elevationGrid,
  elevationGridMax,
  lon,
  lat,
  horizon,
  gridParams,
  uuid,
  cacheSunPositionAtDateTimeUTC,
  preGeneratedRaytraceResults
) {
  //@TODO: Optimize to share between all modules instead of recreating each time
  var gridBounds = gf.createLineString(
    [
      [0, 0],
      [0, gridParams.resolution - 1],
      [gridParams.resolution - 1, gridParams.resolution - 1],
      [gridParams.resolution - 1, 0],
      [0, 0],
    ].map(function (c) {
      return new jsts.geom.Coordinate(c[0], c[1])
    })
  )

  // Sample multiple locations per panel
  // Currently if any rays are blocked we assume the panel is shaded.
  // @TODO: Determine the number of substrings (separated by bypass diodes) which are shaded
  // Requires change the data format for shadingOverride to either
  //  a) percentage of unshaded substrings or
  //  b) one true/false value per substring

  var { cols, rows } = substringLayout
  var raytraceResults = []

  raytracePositions.forEach((raytracePosition) => {
    // @TODO: Allow the option of combining both pre-generated and raytraced results to allow shading from either
    if (preGeneratedRaytraceResults) {
      var closestShadeSample = OsModule.getClosestShadeSample(
        preGeneratedRaytraceResults,
        raytracePosition.x,
        raytracePosition.y
      )
      /*
      We could compare the x/y position of requested raytracePosition and the x/y position of the best sample found
      */
      raytraceResults.push(closestShadeSample.shading288)
    } else {
      raytraceResults.push(
        this.shadingAtWorldPosition(
          slope,
          azimuth,
          raytracePosition,
          elevationGrid,
          elevationGridMax,
          gridParams,
          gridBounds,
          lon,
          lat,
          horizon,
          cacheSunPositionAtDateTimeUTC
        )
      )
    }
  })

  // Each substring has the indexes of raytraced points
  // Shading on any of the raytraced points will treat the whole substring as shaded

  // We combine substring results into a percentage shading for the whole panel
  // Based on how many substrings are shaded
  const shadingOverride = getModuleShading(raytraceResults, substringLayout)

  // Old method where panel has only a single shade value
  // var raytraceResultsCombined = []
  // var panelValue
  // for (var i = 0; i < 288; i++) {
  //   panelValue = null
  //   raytraceResults.forEach(raytraceResult => {
  //     if (raytraceResult[i] === false) {
  //       panelValue = false
  //     } else if (panelValue !== false && raytraceResult[i] === true) {
  //       // ignore sun values if we have already been shaded
  //       panelValue = true
  //     }
  //   })
  //   raytraceResultsCombined.push(panelValue)
  //
  //   // if (!raytraceResults.every(raytraceResult => raytraceResults[0][i] === raytraceResult[i])) {
  //   //   var output = ''
  //   //
  //   //   for (var cellIndex = 0; cellIndex < cols * rows; cellIndex++) {
  //   //     var raytraceResult = raytraceResults[cellIndex][i]
  //   //
  //   //     if (raytraceResult === true) {
  //   //       output += '0'
  //   //     } else if (raytraceResult === false) {
  //   //       output += '1'
  //   //     } else {
  //   //       output += '-'
  //   //     }
  //   //
  //   //     if (cellIndex % cols === cols - 1) {
  //   //       output += '\n'
  //   //     }
  //   //   }
  //   //   console.log('mixed', i, 'day:' + Math.floor(i / 24), 'hour:' + (i % 24))
  //   //   console.log(output)
  //   // }
  // }

  return {
    uuid: uuid,
    raytraceResults: raytraceResults,
    shadingOverride: shadingOverride,
  }
}

OsModule.shadingAtGridCellPosition = function (
  slope,
  azimuth,
  raytracePosition,
  gridParams,
  gridCellPosition,
  elevationGrid,
  elevationGridMax,
  gridBounds,
  lon,
  lat,
  horizon,
  cacheSunPositionAtDateTimeUTC,
  months,
  hoursIncrement,
  ignoreBackfaceShading,
  skipFirstRaypoints
) {
  var day = 0
  var results = []
  var dayHourIndex = 0

  //skip the first N points which are imprecise
  if (typeof skipFirstRaypoints === 'undefined') {
    skipFirstRaypoints = 2
  }

  if (typeof ignoreBackfaceShading === 'undefined') {
    ignoreBackfaceShading = true
  }

  if (!hoursIncrement) {
    hoursIncrement = 1
  }

  var panelPlaneNormal = Utils.normalFromSlopeAzimuth(slope, azimuth)

  Utils.daysInMonth.forEach(function (daysInMonth, monthIndex) {
    if (months && months.indexOf(monthIndex) === -1) {
      // skip this month
      dayHourIndex += 24
    } else {
      for (var hourUTC = 0; hourUTC < 24; hourUTC += hoursIncrement) {
        // @TODO: Quick fill non sun-hours some simple way

        // @TODO: Refactor so we only calculate inclination & azimuth once and re-use for all panels
        var sp = cacheSunPositionAtDateTimeUTC
          ? cacheSunPositionAtDateTimeUTC[dayHourIndex]
          : Utils.sunPositionAtDateTimeUTC(day, hourUTC, lon, lat)

        // Calculate direction of ray to determine whether it is behind the plane of the panel
        // This is not considered "shaded" because there is no way for the beam to directly hit the panel anyway
        var inclination = Math.PI / 2 - sp.altitude

        var sunRayDirection = new THREE.Vector3(0, 0, 1)
        sunRayDirection.applyAxisAngle(new THREE.Vector3(1, 0, 0), inclination)
        sunRayDirection.applyAxisAngle(new THREE.Vector3(0, 0, 1), -(sp.azimuth + Math.PI))
        sunRayDirection.negate()

        // Optinally debug a single raytrace for inspection in QGIS
        var debugRayTrace =
          typeof window !== 'undefined' && window.debugRaytraceDay === day && window.debugRaytraceHour === hourUTC

        var isSunny = OsModule.isSunny(
          raytracePosition,
          gridParams,
          gridCellPosition,
          elevationGrid,
          elevationGridMax,
          gridBounds,
          horizon,
          ignoreBackfaceShading,
          skipFirstRaypoints,
          sunRayDirection,
          panelPlaneNormal,
          sp.azimuth,
          sp.altitude,
          debugRayTrace
        )

        results.push(isSunny)

        // increment dayHourIndex immediately after using it because we may exit the loop at different places
        dayHourIndex += hoursIncrement
      }
    }
    day += daysInMonth
  }, this)
  return results
}

OsModule.worldPositionToElevationGridCell = function (position, gridParams) {
  // use x and y only, ignore position.z

  return new THREE.Vector2(
    Math.round((gridParams.resolution * (position.x - gridParams.min.x)) / gridParams.size.x),
    gridParams.resolution -
      1 -
      Math.round((gridParams.resolution * (position.y - gridParams.min.y)) / gridParams.size.y)
  )
}

OsModule.shadingAtWorldPosition = function (
  slope,
  azimuth,
  raytracePosition,
  elevationGrid,
  elevationGridMax,
  gridParams,
  gridBounds,
  lon,
  lat,
  horizon,
  cacheSunPositionAtDateTimeUTC
) {
  var gridCellPosition = OsModule.worldPositionToElevationGridCell(raytracePosition, gridParams)

  return OsModule.shadingAtGridCellPosition(
    slope,
    azimuth,
    raytracePosition,
    gridParams,
    gridCellPosition,
    elevationGrid,
    elevationGridMax,
    gridBounds,
    lon,
    lat,
    horizon,
    cacheSunPositionAtDateTimeUTC
  )
}

OsModule.isSunny = function (
  raytracePosition,
  gridParams,
  gridCellPosition,
  elevationGrid,
  elevationGridMax,
  gridBounds,
  horizon,
  ignoreBackfaceShading,
  skipFirstRaypoints,
  sunRayDirection,
  panelPlaneNormal,
  sunAzimuth,
  sunAltitude,
  debugRayTrace
) {
  // When sun is near co-planar with plane of array, it's subject to precision issues.
  // Close angles will add an extra elevation error tolerance to reduce these issues.
  var dot = sunRayDirection.dot(panelPlaneNormal)

  // Precision workaround:
  // Must be approximately ~15 degrees above parallel to prevent almost perpendicular rays showing as shaded
  // @TODO: Can we reduce the need for such a large threshold by fixing a bug somewhere else?
  // Sample code for calculating dot treshold:
  // var thresholdSizeDegrees = 5
  // var v1 = new THREE.Vector3(1, 0, 0)
  // var v2 = new THREE.Vector3(1, 0, 0).applyAxisAngle(new THREE.Vector3(0, 0, 1), (90-thresholdSizeDegrees) * THREE.Math.DEG2RAD)
  // console.log('dot threshold', v1.dot(v2)) // 0.08715574274765825
  //
  // For a 3D dot product, an angle of 10 degrees ~= dot product = 0.17364817766693041
  // For a 3D dot product, an angle of 5 degrees ~= dot product = 0.08715574274765825
  // For a 3D dot product, an angle of 2 degrees ~= dot product = 0.03489949670250109
  // For a 3D dot product, an angle of 1 degrees ~= dot product = 0.017452406437283463
  // Disabled: dot product = 0.0
  var dotThresholdToTreatAsBackface = -0.0348 //~2 degrees
  var dotThresholdToTreatAsNearPlaneOrArray = -0 // ~0 degrees (not implemented)

  // Due to inaccuracy in depth map, sometimes the ray can fall below the depth
  // Therefore, we raise the raytrace position up to the depth at that location.
  var epsilon

  if (sunRayDirection.z > 0) {
    // Sun is below horizon
    return null
  } else if (ignoreBackfaceShading !== false && dot > dotThresholdToTreatAsBackface) {
    // Sun is either shining from behind the panel, shading irrelevant
    return null
  } else if (dot > dotThresholdToTreatAsNearPlaneOrArray) {
    epsilon = 0.2
  } else {
    epsilon = 0.1
  }

  var raytraceStartElevation =
    Math.max(raytracePosition.z, elevationGrid[gridCellPosition.y][gridCellPosition.x]) + epsilon

  if (horizon) {
    if (sunAltitude * THREE.Math.RAD2DEG < horizon[Math.round(sunAzimuth * THREE.Math.RAD2DEG)]) {
      // sun is up but below the far horizon
      return false
    }
  }

  // find intersection with grid edge then find all grid cells lying on that ray (like an aliased line)
  // No need to scale x and y coordinates because they are equal. i.e. Grid cells are square
  // Scale to viewport.gridParams.resolution * 2 units so it's guaranteed do exceed the grid boundary
  // Note: ThreeJS rotations are counter-clockwise
  var rayDelta = new THREE.Vector2(0, gridParams.resolution * 2).rotateAround(
    new THREE.Vector2(0, 0),
    sunAzimuth + Math.PI
  )

  var gridIntersectionCoordinates

  if (RAYTRACE_METHOD === 'JSTS') {
    // @TODO: Calculate intersection rays for one panel then simply copy-and-translate ray coordinates for other panels
    // instead of calculating them all individually using sun azimuth/altitude
    var ray = gf.createLineString([
      new jsts.geom.Coordinate(gridCellPosition.x, gridCellPosition.y),
      new jsts.geom.Coordinate(gridCellPosition.x + rayDelta.x, gridCellPosition.y + rayDelta.y),
    ])
    var gridIntersection = ray.intersection(gridBounds)
    if (!gridIntersection || !gridIntersection.coordinates) {
      // console.log('no intersection found between ray and grid, skip to next hour')
      return null
    }

    gridIntersectionCoordinates = gridIntersection.coordinates.coordinates[0]
  } else {
    // Alternative using ThreeJS instead of JSTS which seems super slow
    // @TODO: Convert to 2D coordinates which will be much faster than 3D

    // Create a Box3 around the terrain boundary extending far above and below
    var box3 = new THREE.Box3(
      new THREE.Vector3(0, 0, -100),
      new THREE.Vector3(gridParams.resolution - 1, gridParams.resolution - 1, 100)
    )

    var ray3 = new THREE.Ray(
      new THREE.Vector3(gridCellPosition.x, gridCellPosition.y, 0),
      new THREE.Vector3(rayDelta.x, rayDelta.y, 0)
    )
    gridIntersectionCoordinates = ray3.intersectBox(box3, new THREE.Vector3())
  }

  var rayPoints = pointsOnLine(
    gridCellPosition.x,
    gridCellPosition.y,
    Math.round(gridIntersectionCoordinates.x),
    Math.round(gridIntersectionCoordinates.y)
  )

  if (debugRayTrace) {
    Utils.exportElevationGridWithRay(
      debugRayTrace,
      elevationGrid,
      rayPoints,
      gridCellPosition,
      raytraceStartElevation, //do not use raytracePosition.z which may sometimes fall below the depth map
      sunAltitude,
      gridParams
    )
  }

  for (var i = skipFirstRaypoints, l = rayPoints.length; i < l; i++) {
    var rayPoint = rayPoints[i]

    // Ignore the cell on the module itself
    // Removed because we now use skipFirstRaypoints instead
    // if (rayPoint[0] == gridCellPosition.x && rayPoint[1] == gridCellPosition.y) {
    //   continue
    // }

    // @TODO: Optimize this by calcluating the delta for each cell then incrementing each time?
    // but beware aliasing -> we need to calculate and apply dx and dy separately

    // scale x and y coordinates separately (@TODO: Can we assume equal scaling for x and y coordinates to simplify?)

    var rayElevationAtPoint = Utils.calculateRayElevationAtPoint(
      elevationGrid,
      rayPoint,
      gridCellPosition,
      raytraceStartElevation, //do not use raytracePosition.z which may sometimes fall below the depth map
      sunAltitude,
      gridParams
    )

    var sceneElevationAtPoint = elevationGrid[rayPoint[1]][rayPoint[0]]

    // Assess whether early termination is possible

    if (sceneElevationAtPoint > rayElevationAtPoint) {
      //no need to process more points on this ray
      return false
    } else if (rayElevationAtPoint > elevationGridMax) {
      // ray is already higher than the highest elevation in the scene, no need to process more points
      // we have escaped into the sky
      return true
    }
  }

  // we reached the end of the row without being blocked: sunny :-)
  return true
}

OsModule.loadExportTexture = function () {
  OsModule.exportTextureLoading = true
  new THREE.TextureLoader().load(
    Designer.prepareFilePathForLoad(Designer.FILE_PATHS.MODULE_IMAGE_EXPORT_SRC),
    (texture) => {
      OsModule.exportTexture = texture
    }
  )
}
