// for promotion banners
window.enableFinancialPromotion = false
/*
locationLog debugging utility for tracking and reporting on locations
*/
window.locationLog = []
window.locationLogAdd = function (item) {
  if (item[0] && item[1] && item[2]) {
    var lastItem = window.locationLog[window.locationLog.length - 1]
    if (lastItem) {
      var threshold = 0.002
      var dx = Math.abs(lastItem[1] - item[1])
      var dy = Math.abs(lastItem[2] - item[2])
      if (dx > threshold || dy > threshold) {
        console.log('threshold exceeded from previous log', dx, dy, item)
      }
    }

    window.locationLog.push(item)
  } else {
    console.log('locationLog invalid, skipping', item)
  }
}

window.locationLogCsv = function () {
  var output = 'label,x,y\n'
  window.locationLog.forEach((line, index) => {
    if (line[0] && line[1] && line[2]) {
      output += index + 1 + ' ' + line.join(',') + '\n'
    } else {
      console.log('Line skipped:', line)
    }
  })
  console.log(output)
}

if (!window.getStorage) {
  // window.getStorage not set in Designer.js injecting dummy now
  // Used when window.getStorage not initialized in public/index.html.
  // e.g. When generating html for PDF generation
  window.getStorage = function () {
    return window.localStorage
  }
}

/*
Developer productivity flags. Due to the complexity of getStorage and the difference between
appStorage/localStorage/sessionStorage just use localStorage for all developer flags.
*/
const storage = window.localStorage
if (storage.getItem('saveReplays')) {
  window.saveReplays = true
}

if (storage.getItem('disablePreload3D')) {
  window.disablePreload3D = true
}

if (storage.getItem('disableAutoSave')) {
  window.disableAutoSave = true
}

if (storage.getItem('studioDebug')) {
  window.studioDebug = true
}

if (storage.getItem('translationDebug')) {
  window.translationDebug = true
}

if (storage.getItem('studioDetail')) {
  window.studioDetail = storage.getItem('studioDetail')
} else {
  window.studioDetail = 'high'
}

if (storage.getItem('defaultMapType')) {
  window._defaultMapType = storage.getItem('defaultMapType')
} else {
  window._defaultMapType = null
}

var RENDER_ORDER = {
  FacetMesh: 2,
  FacetMeshSetbacks: 3,
  ArraySetbacks: 3,
  OsAngle: 4,
}

window.setDefaultMapType = function (defaultMapType) {
  window.getStorage().setItem('defaultMapType', defaultMapType)
  window._defaultMapType = defaultMapType
}

window.getDefaultMapType = function (allowSaved = true) {
  if (allowSaved) {
    // If a default has been set for UX2 use it without worrying about other settings
    try {
      // beware it could be "null" which is valid json
      var imageryTypeDefaultString = window.getStorage().getItem('imageryTypeDefault')
      if (imageryTypeDefaultString) {
        var imageryTypeDefaultObject = JSON.parse(imageryTypeDefaultString) || {} // handle null
        if (imageryTypeDefaultObject.map_type === 'Google3D') {
          return 'Google3D'
        } else if (imageryTypeDefaultObject.map_type === 'Nearmap3D') {
          return 'Nearmap3D'
        } else if (imageryTypeDefaultObject.map_type === 'GetMapping3D') {
          return 'GetMapping3D'
        } else if (imageryTypeDefaultObject.variation_name === 'GetMapping2D') {
          return 'GetMapping2D'
        } else if (imageryTypeDefaultObject.map_type === 'GetMappingPremium3D') {
          return 'GetMappingPremium3D'
        } else if (imageryTypeDefaultObject.variation_name === 'GetMappingPremium2D') {
          return 'GetMappingPremium2D'
        } else if (imageryTypeDefaultObject.map_type === 'Vexcel3D') {
          return 'Vexcel3D'
        }
      }
    } catch (err) {
      console.warn(err)
    }
  }

  if (AccountHelper.hasNearmapOnOpenSolar() === true) {
    return 'Nearmap'
  } else {
    return 'GoogleTop'
  }
}

function getStudioDetail() {
  if (window.studioDetail) {
    return window.studioDetail
  } else {
    return 'high'
  }
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

function isIE() {
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true
  } else {
    return false
  }
}

window.CompressionHelper = {
  decompress: function (rawData, parseJson) {
    // simply returns the string if already decompressed
    var data

    if (!rawData) {
      throw new Error('Design data not found')
    } else if (typeof rawData === 'object') {
      // Already an object, no decompression could possibly be required, just return it
      // This can happen when an object is sometimes returned as an actual object
      // and sometimes stringified and compressed
      return rawData
    } else if (rawData && rawData[0] !== '{') {
      var decodedData = isIE() ? Base64.atob(rawData) : atob(rawData)
      data = pako.ungzip(decodedData, { to: 'string' })
    } else {
      data = rawData
    }

    if (parseJson === true) {
      try {
        return JSON.parse(data)
      } catch (error) {
        Designer.showNotification('Error loading design. Details: ' + error, 'error')
        return null
      }
    } else {
      return data
    }
  },
  compress: function (rawData) {
    return btoa(window.pako.gzip(rawData, { to: 'string', level: 4 }))
  },
}

var DISPATCH_SIGNALS_OVERRIDE = false

if (!window.disableChromeAutoComplete) {
  window.disableChromeAutoComplete = function () {
    if (window.$) {
      $("input[autocomplete='off']").each(function (i, element) {
        element.setAttribute('autoComplete', 'new-password')
      })
    }
  }
}

var Designer = {}

Designer.permissions = new PermissionsTree('Studio Permissions')

Designer.ALLOW_SELECT_OTHER_COMPONENT_IN_VIEWPORT = false

Designer.panelExpanded = null

Designer.showFacetsOnActiveMapType = function () {
  if (window.STUDIO_FEATURE_FLAGS?.DESIGN_ON_OBLIQUES) {
    return true
  } else if (['Google', 'NearmapSource'].includes(MapHelper.activeMapInstance?.mapType)) {
    return false
  } else if (MapHelper.activeMapInstance?.mapType === 'Image' && Designer.hasMultipleImageryViews()) {
    return false
  } else {
    return true
  }
}

Designer.updateSubmitErrors = function (errorData) {
  if (errorData?.responseJSON && errorData.responseJSON instanceof Object && !Array.isArray(errorData.responseJSON)) {
    window.projectForm?.mutators.setSubmitErrors(errorData.responseJSON)
  }
}

Designer.getErrorDetail = function (errorData, defaultMessage) {
  if (!defaultMessage) {
    defaultMessage = null
  }

  Designer.updateSubmitErrors(errorData)

  //temporary fix for now
  if (errorData?.responseJSON) {
    if (typeof errorData.responseJSON === 'string') {
      return errorData.responseJSON
    } else if (errorData.responseJSON.message) {
      return errorData.responseJSON.message
    } else if (errorData.responseJSON instanceof Object && !Array.isArray(errorData.responseJSON)) {
      const flattenedErrorMessages = window.Utils.flatErrorMessages(errorData.responseJSON)
      return Object.values(flattenedErrorMessages).join('; ')
    }
  }

  return defaultMessage
}

Designer.AjaxSession = new AjaxSession()

Designer.Ajax = new Ajax({ ajaxSession: Designer.AjaxSession })

Designer.style = 'default' // 'default' or 'planset'

Designer.hasSystemWithModules = function () {
  if (editor) {
    var systems = editor.getSystems()

    if (
      systems &&
      systems.length > 0 &&
      systems.filter(function (system) {
        return system.moduleQuantity() > 0
      }).length > 0
    ) {
      return true
    }
  }
}

Designer.hasValidSystemForCustomer = function () {
  if (editor) {
    var systems = editor.getSystems()

    if (
      systems &&
      systems.length > 0 &&
      systems.filter(function (system) {
        return system.moduleQuantity() > 0 && system.show_customer === true
      }).length > 0
    ) {
      return true
    }
  }
  return false
}

Designer.hasViewForCustomer = function () {
  if (ViewHelper) {
    if (
      ViewHelper.views &&
      ViewHelper.views.length > 0 &&
      ViewHelper.views.filter(function (view) {
        return view.show_customer === true
      }).length > 0
    ) {
      return true
    }
  }
}

Designer.getModuleGridsForSelectedSystem = function (selectedSystemUuid) {
  if (typeof selectedSystemUuid === 'undefined') {
    selectedSystemUuid = editor.selectedSystem ? editor.selectedSystem.uuid : null
  }

  var selectedSystem = editor.getSystems().filter(function (system) {
    return system.uuid === selectedSystemUuid
  })[0]
  if (!selectedSystem) {
    return []
  } else {
    return selectedSystem.moduleGrids()
  }
}

Designer.initComplete = false

//Store the timestamp after which the calculation will proceed.
//Other values are 'ready' or 'waiting'
Designer.systemCalculationsQueued = {}
//waiting for result come back from backend
Designer.calculationResponseWaitingQueued = []
Designer.functionsToCallWhenQueueEmpty = []

Designer.uiUpdatesActive = { ui: true, render: true }

Designer.loginPromptHandleDismiss = function (event) {
  event.preventDefault()
  window.editor.signals.escapePressed.dispatch()
}

Designer.loginPromptIfRequired = function (response) {
  if (response.status === 403 && response.responseJSON?.permission_error) {
    console.log('Permission error ', response.responseJSON?.permission_error)
    // stay logged-in if response contain permission error
  } else if (response.status === 401 || response.status === 403) {
    window.WorkspaceHelper.cancelLoading()
    window.location.hash = '#/login'
  }
}

Designer.systemsQueued = function () {
  var reduxSystemCalculation = window?.reduxStore?.getState()?.designer?.systemCalculation
  var debounceQueue = reduxSystemCalculation?.debounceQueue || []
  return debounceQueue
}

Designer.systemsProcessing = function () {
  var reduxSystemCalculation = window?.reduxStore?.getState()?.designer?.systemCalculation
  var processQueue = Object.keys(reduxSystemCalculation?.processQueue || {})
  return processQueue
}

Designer.systemsQueuedOrProcessing = function () {
  return [...Designer.systemsQueued(), ...Designer.systemsProcessing()]
}

Designer.updateCalculationResponseWaitingQueued = function (type, uuid) {
  if (type === 'add') {
    if (Designer.calculationResponseWaitingQueued.indexOf(uuid) === -1) {
      Designer.calculationResponseWaitingQueued.push(uuid)
    }
    // update redux so the payment options page can be aware of the list of currently calculating systems
    window.reduxStore.dispatch({
      type: 'MARK_SYSTEM_AS_CALCULATING',
      payload: {
        uuid: uuid,
      },
    })
  } else {
    const index = Designer.calculationResponseWaitingQueued.indexOf(uuid)
    if (index !== -1) {
      Designer.calculationResponseWaitingQueued.splice(index, 1)
    } else {
      console.warn('system: ' + uuid + ' not found in calculationResponseWaitingQueued')
    }

    let system = window.editor.getSystems()?.find((sys) => sys.uuid === uuid)
    window.WorkspaceHelper.refreshPaymentOptionWarnings(system)
  }
}

var beginCalcSystem = function (systemUuid) {
  if (!editor.objectByUuid(systemUuid)) {
    console.warn('dispatchCalculationActionDebounced aborted, system no longer exists, ignore')
    const debounceQueue = window.reduxStore?.getState()?.designer?.systemCalculation?.debounceQueue
    if (window.reduxStore && debounceQueue && debounceQueue.includes(systemUuid)) {
      window.reduxStore.dispatch({
        type: 'CALCULATION_DEBOUNCE_QUEUE_REMOVE',
        payload: {
          uuid: systemUuid,
        },
      })
    }
    // System gets deleted while waiting for re-calc. Abort project saving
    if (window.WorkspaceHelper.saveInProgress) {
      window.Designer.showNotification(
        'Significant design changes detected. Project save aborted. Please click "Save" again to proceed.',
        'warning'
      )
      console.warn('System gets deleted while waiting for re-calc. Abort project saving.')
      window.WorkspaceHelper.abortSaveProject()
    }
    return
  }

  console.log('send request for' + systemUuid)

  var callback = function () {
    if (Designer.systemsQueued().length === 0) {
      if (Designer.functionsToCallWhenQueueEmpty.length > 0) {
        for (var i = 0; i < Designer.functionsToCallWhenQueueEmpty.length; i++) {
          if (Designer.functionsToCallWhenQueueEmpty[i]) {
            Designer.functionsToCallWhenQueueEmpty[i]()
            //Clear before continuing to next, to prevent any possibility of multiple calls
            Designer.functionsToCallWhenQueueEmpty[i] = null
          } else {
            console.warn('Attempting to call Designer.functionsToCallWhenQueueEmpty[i] which is empty')
          }
        }
        Designer.functionsToCallWhenQueueEmpty = []
      }
    }
  }
  if (window.reduxStore && !ReplayHelper?.replayInProgress) {
    window.reduxStore.dispatch({
      type: 'SYSTEM_CALCULATION_REQUEST',
      payload: {
        uuid: systemUuid,
        callback,
        showSuccessNotification: window.WorkspaceHelper.showSaveNotifications,
      },
    })
  }
}

const wait = window.localStorage.getItem('debugCalcDebounceWaitTime')
  ? parseInt(window.localStorage.getItem('debugCalcDebounceWaitTime'))
  : 3000

let pendingSystems = {}
var debounceCalcAllSystems = Debounce.make(() => {
  let toCalc = pendingSystems
  pendingSystems = {}
  for (var i in toCalc) {
    beginCalcSystem(i)
  }
}, wait)

Designer.dispatchCalculationActionDebounced = function (systemUuid) {
  pendingSystems[systemUuid] = true
  debounceCalcAllSystems()
}

Designer.startAllSystemCalculation = function () {
  editor.filter('type', 'OsSystem').forEach((system) => {
    Designer.requestSystemCalculations(system)
  })
}

Designer.startSystemCalculation = function (system) {
  if (system && system.type === 'OsSystem' && system.uuid) {
    const debounceQueue = window.reduxStore?.getState()?.designer?.systemCalculation?.debounceQueue
    if (window.reduxStore && (!debounceQueue || !debounceQueue.includes(system.uuid))) {
      window.reduxStore.dispatch({
        type: 'CALCULATION_DEBOUNCE_QUEUE_ADD',
        payload: {
          uuid: system.uuid,
        },
      })
    }

    Designer.dispatchCalculationActionDebounced(system.uuid)
  }
}

Designer.requestSystemCalculations = function (system) {
  if (!editor.displayMode === 'presentation') {
    // console.log('ignore Designer.requestSystemCalculations(), scene not editable')
    return
  }

  if (editor.uiPauseLocks.calcs.length > 0) {
    return
  }

  if (system && system.isEmpty()) {
    console.log('Skip Designer.requestSystemCalculations because system has not yet been modified by the user')
    return
  }

  // Performance note: We cannot just abort early when system.awaitingCalcs === true because we need to push back
  // continually delay the execution for each action that affects calcs

  // Do not trigger recalcs due to adding/changing/deleting objects if scene is loading
  // Tricky: If we check for editor.sceneIsLoading inside the debounced function it runs asynchronously
  // and means the check often happens after scene has already finished loading - and the recalc fires!
  if (window.editor.sceneIsLoading !== true && !editor.changingHistory && system && !system.initializingModuleSpecs) {
    system.awaitingCalcs = true

    Designer.startSystemCalculation(system)
  }
}

Designer.requestSystemCalculationsImmediate = function (system) {
  if (system.type !== 'OsSystem') {
    throw new Error('Attempting to recalculate for object which is not a system. Ignore.')
  }
  system.systemCalcCount ? system.systemCalcCount++ : (system.systemCalcCount = 1)
  var initialQueueStatus = Designer.systemCalculationsQueued[system.uuid]
  if (system && (!initialQueueStatus || initialQueueStatus !== 'waiting' + system.systemCalcCount)) {
    var futureTimestamp = Date.now() + 3000.0
    window.studioDebug && console.log('Add to processQueue: ' + system.uuid + ' at ' + futureTimestamp)
    Designer.systemCalculationsQueued[system.uuid] = futureTimestamp

    // Signal will trigger react-updates so only trigger if it has meaningfully changed
    // Do not displatch editor.signals.systemCalculationsAddedToQueue because system is already queued for calc
    if (!initialQueueStatus || initialQueueStatus === 'ready') {
      editor.signals.systemCalculationsAddedToQueue.dispatch(system)
    }
  }
}

Designer.requestSystemCalculationsDebounced = window.Utils.debounce(Designer.requestSystemCalculationsImmediate, 100)

// Used to avoid sending a new signal if the previous state is unchanged
Designer.processQueueLastState = {}
Designer.processQueueLastStateHash = null

Designer.processQueue = function () {
  var timestamp = Date.now()

  if (Object.keys(Designer.systemCalculationsQueued).length > 0) {
    Object.keys(Designer.systemCalculationsQueued).forEach(function (uuid) {
      if (!isNaN(Designer.systemCalculationsQueued[uuid]) && Designer.systemCalculationsQueued[uuid] < timestamp) {
        window.studioDebug && 'Utils.designInvertersAndStrings(' + uuid + ')'

        var system = window.editor.objectByUuid(uuid)

        if (!system) {
          window.studioDebug &&
            console.log(
              'System not found for uuid... system was probably deleted or scene cleared since calc was queued.'
            )
          delete Designer.systemCalculationsQueued[uuid]
          return
        }

        var callbackUuid = uuid
        var callback = function () {
          console.log('Calcs returned for ' + callbackUuid)
          Designer.systemCalculationsQueued[callbackUuid] = 'ready'
          editor.signals.systemCalculationsRemovedFromQueue.dispatch(system)

          if (Designer.systemsQueued().length === 0) {
            if (Designer.functionsToCallWhenQueueEmpty.length > 0) {
              for (var i = 0; i < Designer.functionsToCallWhenQueueEmpty.length; i++) {
                if (Designer.functionsToCallWhenQueueEmpty[i]) {
                  Designer.functionsToCallWhenQueueEmpty[i]()
                  //Clear before continuing to next, to prevent any possibility of multiple calls
                  Designer.functionsToCallWhenQueueEmpty[i] = null
                } else {
                  console.warn('Attempting to call Designer.functionsToCallWhenQueueEmpty[i] which is empty')
                }
              }
              Designer.functionsToCallWhenQueueEmpty = []
            }
          }
        }
        var recordedCalcCount = system.systemCalcCount
        //Determine how to process this system based on system.autoString
        var submitted =
          system.autoString == true && (!system.stringingIsComplete() || system.requireClusters)
            ? Utils.designInvertersAndStrings(uuid, callback, undefined, recordedCalcCount)
            : SceneHelper.calculateSystem(window.editor, uuid, callback)

        if (submitted === true) {
          Designer.systemCalculationsQueued[uuid] = 'waiting' + recordedCalcCount
        } else if (submitted) {
          // Currently if not === true but not empty we assume this must be an unresolved promise
          Designer.systemCalculationsQueued[uuid] = 'waiting' + recordedCalcCount
        } else {
          //calculation result status is not really ready, we should improve this
          Designer.systemCalculationsQueued[callbackUuid] = 'ready'
          console.warn('Unable to submit for calculations, removing from queue: uuid:' + uuid)
        }
      }
    })
  }

  let systemCalculatingUuids = Designer.calculationResponseWaitingQueued
  if (systemCalculatingUuids.length) systemCalculatingUuids = [...systemCalculatingUuids]

  let systemsQueuedOrProcessingUuids = Designer.systemsQueuedOrProcessing()

  const updateInfo = {
    // legacy values which are critical to existing behavior
    //
    // There are systems which have calculations in queued/processing but which will not appear
    // in either of systemsLoadingUuids or systemCalculatingUuids. This happens if they are in
    // reduxSystemCalculation.processQueue. We will not change existing functinality, but note that
    // these systems WILL be included in the new systemCalcsInProgress value which includes both
    // debounceQueue and processQueue.
    systemsLoadingUuids: Designer.systemsQueued(),
    systemCalculatingUuids,

    // new values which are more ergonomic
    systemCalcsInProgressUuids: systemsQueuedOrProcessingUuids,
    sceneLoadingInProgress: !!window.editor?.sceneIsLoading,
    viewsLoadingInProgress: !!window.editor?.viewTabsLoading(),
    shadeCalcsInProgress: ShadeHelper.systemsAwaitingTrigger().length > 0,
    systemCalcsInProgress: systemsQueuedOrProcessingUuids.length > 0,
    autoDesignEphemeralInProgress: SceneHelper.autoDesignEphemeralInProgress > 0,
    fullCalcsInProgress: window.projectForm?.getState()?.values?.simulate_first_year_only,

    //idle: derived below for performance reasons,
  }

  // Note: fullCalcsInProgress is ignored for determining "idle" state. The rationale is that this is not
  // handled by studio in the front-end, it is a separate background/back-end process that is relevant to
  // track but not actually something being "done" in the studio.
  updateInfo.idle =
    !updateInfo.sceneLoadingInProgress &&
    !updateInfo.viewsLoadingInProgress &&
    !updateInfo.shadeCalcsInProgress &&
    !updateInfo.systemCalcsInProgress &&
    !updateInfo.autoDesignEphemeralInProgress

  // Stringifying the updateInfo will suffice as a hash
  // This will always fire a signal the first time processQueue runs but most calls after that
  // will no longer fire a signal unless something has changed
  const processQueueStateHash = JSON.stringify(updateInfo)

  if (processQueueStateHash !== Designer.processQueueLastStateHash) {
    if (window.studioDebug) {
      const hasChanged = (oldValue, newValue) => {
        var oldValueForComparison = Array.isArray(oldValue) ? oldValue.join('|') : oldValue
        var newValueForComparison = Array.isArray(newValue) ? newValue.join('|') : newValue
        return newValueForComparison !== oldValueForComparison
      }

      const updates = []

      for (const key in updateInfo) {
        updates.push({
          key,
          old: Designer.processQueueLastState[key],
          new: updateInfo[key],
          changed: hasChanged(Designer.processQueueLastState[key], updateInfo[key]) ? '***' : '',
        })
      }

      console.log(`---updateInfo changed---`)
      console.table(updates)
    }

    Designer.processQueueLastState = updateInfo
    Designer.processQueueLastStateHash = processQueueStateHash

    window.editor?.signals?.queueProcessed?.dispatch(updateInfo)
  }
}

if (window.TESTING !== true) {
  Designer.systemCalculationsQueuedInterval = setInterval(Designer.processQueue, 1000)
}
Designer.refreshStringsDelay = 500
Designer.refreshStringsIntervalId = null

Designer.isRefreshStringsScheduled = function () {
  return Designer.refreshStringsIntervalId !== null
}
Designer.scheduleRefreshStrings = function () {
  if (!Designer.isRefreshStringsScheduled()) {
    setTimeout(function () {
      try {
        editor.uiPause('render', 'Designer.scheduleRefreshStrings')
        editor.uiPause('ui', 'Designer.scheduleRefreshStrings')

        editor.filter('type', 'OsSystem').forEach(function (_system) {
          _system.refreshStrings(editor)
        })

        editor.uiResume('ui', 'Designer.scheduleRefreshStrings')
        editor.uiResume('render', 'Designer.scheduleRefreshStrings')
      } catch (err) {
        console.warn('Error in refreshStrings', err)
      }
      Designer.refreshStringsIntervalId = null
    }, Designer.refreshStringsDelay)
  } else {
    window.studioDebug && console.log('RefreshStrings already queued... skipping')
  }
}

/*Designer.uiRefs = {
  ToolbarMenu: null,
  Panel: null,
  PanelSystem: null,
  PanelModuleGrid: null,
  PanelFacet: null,
  PanelProperties: null,
  DesignerComponent: null,
  PanelFixSkew: null,
  DirectionAndMagnitudeField: null,
}*/

Designer.uiRefBottomMenu = {}

Designer.typingInField = false

/*Designer.handleCloseOtherMenus = function (menuToKeepOpen) {
  for (var name in Designer.uiRefs) {
    if (Designer.uiRefs[name] && Designer.uiRefs[name].close) {
      if (menuToKeepOpen != Designer.uiRefs[name]) {
        Designer.uiRefs[name].close()
      }
    }
  }
}*/

Designer.changeControl = function (mode, discardChanges, forceInteractiveValue) {
  /*
    If resetting back to "both" we need to keep the screen position of both layers just how they are
    We do this by recalculating the sceneOrigin4326 which would allow them to remain in current positions.
    */
  if (!editor.controllers.Camera) {
    console.warn('Warning: Skip Designer.changeControl, editor.controllers.Camera not found')
    return
  }

  if (mode == 'best') {
    mode = 'both'
  }

  if (mode === 'map') {
    editor.controllers?.Tooltip?.tooltipsMarkAsDirty()
  }

  //Enable/disable interactions for scene or map transparent based on mode
  if (forceInteractiveValue === true || forceInteractiveValue === false) {
    editor.interactive(forceInteractiveValue, mode)
    MapHelper.interactive(forceInteractiveValue)
  } else {
    editor.interactive(mode == 'scene' || mode == 'both', mode)
    MapHelper.interactive(mode == 'map' || mode == 'both')
  }

  MapHelper.setPrimary(mode == 'map')

  if (mode == 'both') {
    //resync back together

    if (discardChanges === true) {
      window.studioDebug &&
        console.log(
          'Designer.changeControl() with discardChanges===true, do not call MapHelper.updateLatLonForSceneOrigin(...)'
        )
    } else if (editor.sceneIsLoading === true) {
      window.studioDebug &&
        console.log('editor.sceneIsLoading===true, do not call MapHelper.updateLatLonForSceneOrigin(...)')
    } else {
      //////////////////////////////////////////////////////////////////
      // ZoomDelta Updates
      // Bake in zoom changes that occurred during the align-map mode
      // Only applies to Image but could also apply to other map types
      // like CyclomediaOblique?
      //////////////////////////////////////////////////////////////////
      var mapData = MapHelper.activeMapInstance.mapData

      if (mapData.mapType === 'Image' || mapData.mapType === 'CyclomediaOblique') {
        // mapData.zoomTarget should be set to the difference between map.getZoom() and (zoomTarget+zoomDelta)
        var zoomTargetDifference =
          MapHelper.activeMapInstance.dom.getView().getZoom() - mapData.zoomTarget - mapData.zoomDelta

        mapData._zoomTarget(mapData.zoomTarget + zoomTargetDifference)
      }

      //////////////////////////////////////////////////////////////////
      // End ZoomDelta Updates
      //////////////////////////////////////////////////////////////////

      //find map.lonlat at current sceneOrigin based on screen position
      var screenPositionOfSceneOrigin = editor.viewport.worldToScreen(new THREE.Vector3(), editor.camera)

      //var mapLonLatForScreenPosition = MapHelper.screenPositionToLatLon(MapHelper.activeMapInstance, screenPositionOfOrigin[0], screenPositionOfOrigin[1])

      //save the new sceneOrigin lonlat
      try {
        MapHelper.updateLatLonForSceneOrigin(screenPositionOfSceneOrigin)
      } catch (err) {
        //This can fail when maps haven't yet fully loaded, it's not a problem.
        console.log(err)
      }
    }
  }

  Designer.controlMode = mode

  console.debug('workaround temporary react setState bug')
  window.setTimeout(function () {
    editor.signals.controlModeChanged.dispatch(mode)
  }, 100)
}

Designer.startPlacementMode = function (label, options) {
  console.log('Designer.handleClick() with label:', label)

  if (!window.editor?.getDesignGuidesVisibility()) {
    // restore hidden editor guides when placing new objects in scene
    window.editor?.setDesignGuidesVisibility({ visible: true, renderWhenDone: true })
  }

  let objectType = null
  let cancelFunction = null

  switch (label) {
    case 'Roof (R)':
      objectType = 'OsEdge'
      cancelFunction = editor.controllers['AddObject'].start(objectType, true)
      break
    case 'Obstruction (O)':
      objectType = 'OsObstruction'
      cancelFunction = editor.controllers['AddObject'].start(objectType, undefined, options)
      break
    case 'Structure A-frame':
      objectType = 'OsStructure'
      cancelFunction = editor.controllers['Sequence'].activate('aframe')
      break
    case 'Structure Hip':
      objectType = 'OsStructure'
      cancelFunction = editor.controllers['Sequence'].activate('hip')
      break
    case 'Structure Shed':
      objectType = 'OsStructure'
      cancelFunction = editor.controllers['Sequence'].activate('shed')
      break
    case 'Dormer A-frame':
      objectType = 'OsStructure'
      cancelFunction = editor.controllers['Sequence'].activate('aframe_dormer')
      break
    case 'Dormer Hip':
      objectType = 'OsStructure'
      cancelFunction = editor.controllers['Sequence'].activate('hip_dormer')
      break
    case 'Dormer Shed':
      objectType = 'OsStructure'
      cancelFunction = editor.controllers['Sequence'].activate('shed_dormer')
      break
    case 'Tree Trimmer (I)':
      objectType = 'OsClipper'
      cancelFunction = editor.controllers['AddObject'].start(objectType)
      break
    case 'Tree (T)':
      objectType = 'OsTree'
      cancelFunction = editor.controllers['AddObject'].start(objectType)
      break
    case 'Annotation (N)':
      objectType = 'OsAnnotation'
      cancelFunction = editor.controllers['AddObject'].start(objectType)
      break
    case 'System':
      objectType = 'OsSystem'
      //no special state or cancel function required
      // Use SAM for new systems if any systems use SAM already, otherwise use PVWatts
      editor.createObject(objectType, null, null, {
        calculator: WorkspaceHelper.getDefaultPerformanceCalculatorForNewProjects(),
      })
      label = null
      break
    case 'Paint Modules (P)':
      window.editor.deselect()
      if (window.editor.controllers.FingerPaint.isActive()) {
        // fingerpaint is currently active, abort
        // this behaves as a toggle
        window.editor.controllers.FingerPaint.finish()
      } else {
        cancelFunction = window.editor.controllers.FingerPaint.start()
        if (!cancelFunction) return
      }
      break
    case 'Wire (W)':
      cancelFunction = editor.controllers['AddObject'].start('OsEdge', true, {
        addWireForSystem: editor.selectedSystem,
      })
      break
  }

  window.editor.signals.placementModeChanged.dispatch({
    label,
    objectType,
    cancelFunction,
  })
}

Designer.resize = function () {
  setTimeout(function () {
    editor.signals.windowResize.dispatch()
  }, 100)
}

Designer.onWindowResize = function (event, forceRenderOnFinished) {
  window.studioDebug && console.log('Designer.onWindowResize')

  //Resize DOM elements first before updating MapHelper
  Designer.resize()
  SnapshotHelper.resize()

  if (MapHelper.activeMapInstance) {
    MapHelper.updateSize(MapHelper.activeMapInstance)

    var selectedView = ViewHelper.selectedView()

    if (selectedView) {
      if (selectedView.mapData.mapType === MapHelper.activeMapInstance.mapData.mapType) {
        MapHelper.setView(MapHelper.activeMapInstance, selectedView.mapData).then(function () {
          if (forceRenderOnFinished) {
            if (window.editor) {
              window.studioDebug && console.log('Designer.onWindowResize > forceRenderOnFinished')
              editor.render(true, true)
            }
          }

          // This should not actually be required, but UX2 does not always save mapData correctly
          // due to sidebar positioning so this will correct it until mapData is fixed.
          // MapHelper.matchScene()
        })
      } else {
        /*
        One known reason why this might happen is due to delayed callbacks as a result of detecting google obliques
        while viewing another map type
        */
        console.log(
          'Designer.onWindowResize: Skip MapHelper.setView() because mapType for selectedView does not match activeMapInstance'
        )
      }
    }
  }
}

Designer.onWindowResizeAfterDelay = function (e, forceRenderOnFinished) {
  /*
    Sadly the amount of delay is not precise. 250ms seems to be reliable but we should
    really identify the real issue/trigger and handle it properly.
    */
  setTimeout(function () {
    Designer.onWindowResize(e, forceRenderOnFinished)
  }, 100)

  setTimeout(function () {
    Designer.onWindowResize(e, forceRenderOnFinished)
  }, 1000)
}

Designer.preventFocusOnDesignerButtons = function () {
  /*
    Inspired by issue with clicking a button then using space to toggle control mode
    was triggering a button click!

    Ensure this is only applied once.
    */
  if (Designer.buttonFocusPreventionApplied !== true) {
    $('body').on('focus.spt', '#DesignerContainer button', function (e) {
      $this = $(this)
      $this.blur()
    })
  }
  Designer.buttonFocusPreventionApplied = true
}

Designer.initCameraAnimationEvents = function (editor) {
  editor.signals.cameraAnimationFinished.add(function (viewIndex) {
    if (!Designer.viewUuidForCurrentAnimation) {
      console.warn(
        'Warning: cameraAnimationFinished but not Designer.viewUuidForCurrentAnimation set. Ignore calling ViewHelper.saveView()'
      )
    } else if (ViewHelper.views.length > 0) {
      if (this.interactive()) {
        const viewIndex = ViewHelper.getIndexForView(ViewHelper.getViewByUuid(Designer.viewUuidForCurrentAnimation))
        if (ViewHelper.hasChangedCamera(viewIndex)) {
          editor.execute(new window.SaveViewCommand(ViewHelper, viewIndex))
        }
      }
    } else {
      console.log('Warning: Calling ViewHelper.saveView() but no views present... we should not fire this event')
    }

    // Dispatch animationStop AFTER calling SaveViewCommand otherwise it will think an extra render is required
    // because it will think animation was not running.
    window.editor.signals.animationStop.dispatch('camera', 'cameraAnimation')
  }, editor)

  editor.signals.cameraAnimationStarted.add(function () {
    Designer.viewUuidForCurrentAnimation = ViewHelper.selectedViewUuid()
    editor.signals.animationStart.dispatch('camera', 'cameraAnimation')
  }, editor)
}

Designer.loadProjectById = function (projectId) {}

Designer.allowUiUpdates = function () {
  if (
    WorkspaceHelper.designIsLoading ||
    editor.sceneIsLoading ||
    SceneHelper.drawModulesInProgress ||
    Designer.uiUpdatesActive.ui === false
  ) {
    return false
  } else {
    return true
  }
}

Designer.getHash = function () {
  var querystring = window.location.href.indexOf('?') != -1 ? window.location.href.split('?')[1] : ''
  var hash = window.location.hash.length > 0 ? window.location.hash : querystring
  hash = hash.split('&amp;').join('&')
  hash = hash.split('#').join('')
  return hash
}

Designer.listeningForEvents = function (eventType) {
  // Essential requirements:
  //    Editor must be present and
  //    Either editor or mapHelper must be interactive
  if (!editor || editor.designMode === 'explore' || (!editor.interactive() && !MapHelper.interactive())) {
    return false
  }
  if (editor.pauseDesignEventListener) return

  //Extra checks for specific eventTypes
  if (eventType === 'key') {
    if (Designer.typingInField) {
      // console.log('Ignoring key presses in Designer.js: typingInField')
      return false
    }
  }

  return true
}

Designer.clearShadingFromObjectAddedChangedRemoved = function (object) {
  if (editor.sceneIsLoading) {
    return
  }

  if (editor.displayMode === 'presentation') {
    // console.log('ignore clearShadingFromObjectAddedChangedRemoved, scene not editable')
    return
  }

  // If object is OsTerrain and we are loading from saved state then panels already have shading
  // How do we know this is initial load?
  // How do we know that shading calcs weren't broken before this?
  if (object.type === 'OsTerrain') {
    window.studioDebug && console.info('Terrain loaded. Existing shading results are not cleared.')
    return
  }

  // Objects which affect shading should clear raytraced shading
  // strict=true so we ignore updates to Scene and OsSystem which don't actually impact shading
  if (SceneHelper.objectAffectsDepth(object, true) && editor.scene.raytracedShadingAvailable()) {
    // If object belongs to a system only recalc that system, otherwise recalc all systems
    var restrictToSystem = object.getSystem ? object.getSystem() : null

    var systemsToUpdate = restrictToSystem ? [restrictToSystem] : editor.getSystems()

    systemsToUpdate.forEach((system) => {
      system.moduleGrids().forEach((mg) => {
        mg.clearRaytracedShadingForModules()
      })

      // if we have generation_override specified, clear it now.
      // @TODO: This will need to change when we make this a first-class supported feature to ensure
      // manually specified generation is not lost. Currently we only clear if we detect that it can be
      // auto-regenerated using output samples (for Auto-Design on GSA and possibly other design modes in future too)
      if (system.generation_override && editor.scene.autoFacetsGeoJson) {
        editor.execute(
          new SetValueCommand(system, 'generation_override', null, Utils.generateCommandUUIDOrUseGlobal(), true)
        )
      }

      Designer.requestSystemCalculations(system)
    })
  }
}

// Debouncing this may be imperfect because we may ignore one object due to a trigger by another object
// but it should be ok for now and will greatly improve performance when dragging objects
Designer.clearShadingFromObjectAddedChangedRemovedDebounced = window.Utils.debounce(
  Designer.clearShadingFromObjectAddedChangedRemoved,
  100
)

Designer.clearShadingFromObjectAddedChangedRemovedDebouncedSkipRedundant = function (object) {
  if (editor.sceneIsLoading) {
    return
  }

  if (!SceneHelper.objectAffectsDepth(object, true)) {
    // Do not clearShading if the object does not actually shading (by affecting scene depth)
    // For example, various helper objects like LineLoop etc may be added but they do not affect shading
    // and should be ignored.
    return
  }

  // If we are reloading a save design and this is the terrain being loading, then shading would have alreday been run
  // before it was saved, so we should not clear shading now. But if this is new terrain that is being added or changed
  // then we should re-run shading.
  // How to we know this is terrain being loaded from a saved state??
  if (object.type === 'OsTerrain' && editor.waiting.terrainDsmFromSavedState === true) {
    console.log('Terrain loaded from saved state. Existing shading results are not cleared.')
    return
  }

  if (editor.displayMode === 'presentation') {
    // console.log('ignore Designer.clearShadingFromObjectAddedChangedRemovedDebouncedSkipRedundant(), scene not editable')
    return
  }

  if (editor.uiPauseLocks.clearShading.length > 0) {
    if (window.studioDebug) {
      console.log('uiPause blocking clearShading until resume for object:', object)
    }
    return
  }

  if (editor.changingHistory) {
    if (window.studioDebug) {
      console.log('skip clearShading during undo redo')
    }
    return
  }

  if (SceneHelper.rebuildFacetTexturesInProgress <= 0) {
    Designer.clearShadingFromObjectAddedChangedRemovedDebounced(object)
  } else {
    // console.log('Skipping clearShadingFromObjectAddedChangedRemovedDebounced, not required for this change')
  }
}

Designer.init = function (
  DesignerRootDomElement,
  apiBaseUrl,
  params,
  injectEditor,
  injectViewport,
  setupControllers = true
) {
  Designer.setDefaultPermissions()

  /*
    Designer.init() initializes Designer with components, event handlers
    but does not actually launch/load anything. That happens separately.

    Warning: window.viewport.renderActive() default to false!
    Rendering is skipped until window.viewport.renderActive() is set to true
    e.g. By WorkspaceHelper.onLoaded(true,...)

    */
  if (Designer.initComplete) {
    window.console.log('Designer.init ignored. Designer.initComplete already true')
    return
  }

  Designer.preventFocusOnDesignerButtons()

  window.loadMapScripts = window.Designer.getHash().indexOf('nomaps') == -1 ? true : false

  if (typeof apiBaseUrl === 'undefined') {
    apiBaseUrl = '/api/'
  }
  window.API_BASE_URL = apiBaseUrl

  window.URL = window.URL || window.webkitURL
  window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder

  Number.prototype.format = function () {
    return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  //
  //Ensure origin is sent when loading images so origin header is sent in the request
  //which ensures that any CORS headers are included in the response
  THREE.ImageUtils.crossOrigin = 'anonymous'

  var editor = injectEditor ? injectEditor : new Editor()

  editor.DesignerRootDomElement = DesignerRootDomElement

  // Designer.controlMode = null //@TODO: Beware this could cause problems with new maps? 'both'
  // Designer.changeControl('both')
  Designer.controlMode = 'both' //@TODO: Beware this could cause problems with new maps? 'both'

  Designer.undoHistory = null

  window.viewport = injectViewport ? injectViewport : new Viewport(editor)

  // Always start with render inactive
  // rendering will be enabled after building the scene
  window.editor.uiPause('render', 'Designer.init')

  if (DesignerRootDomElement) {
    editor.DesignerRootDomElement.appendChild(viewport.container.dom)
  } else {
    window.studioDebug && console.log('Warning: DesignerRootDomElement not set')
  }

  //Ensure editor.signals and MapHelper.signals are the same
  if (MapHelper) {
    MapHelper.bindEditorSignals()
  }

  Designer.rendererName = Designer.getRendererType()

  // Create Renderer
  if (DesignerRootDomElement) {
    Designer.createRenderer(
      Designer.rendererName, //e.g. 'WebGLRenderer', 'SoftwareRenderer'
      true,
      true,
      false,
      false,
      window.hasOwnProperty('PRESERVE_DRAWING_BUFFER') ? window.PRESERVE_DRAWING_BUFFER : undefined
    )
  }

  // Setup Controllers after creating renderer because renderer is required by SelectionBoxController
  if (setupControllers) {
    editor.initControllers()
  }

  // Add Modal Dialog container

  window.modal = new UI.Modal()
  modal.dom.style.zIndex = 3000000

  if (DesignerRootDomElement) {
    editor.DesignerRootDomElement.appendChild(modal.dom)
  }

  editor.signals.cycleNextMapSceneControl.dispatch('both')

  // showParticles and showLineSegments are used to show snap guides
  //
  // Applied when window.WorkspaceHelper.developerMode()===true
  window.particles = []
  window.showParticles = function (positionsVector3, color, size) {
    var geometry = new THREE.Geometry()
    positionsVector3.forEach(function (p) {
      geometry.vertices.push(p)
    })

    if (!color) {
      color = 0xff0000
    }

    if (!size) {
      size = 2
    }

    var material = new THREE.PointsMaterial({ color: color, size: size })
    var newParticles = new THREE.Points(geometry, material)
    newParticles.userData.excludeFromExport = true
    newParticles.selectable = false
    editor.addObject(newParticles, undefined, DISPATCH_SIGNALS_OVERRIDE)
    particles.push(newParticles)
  }

  window.clearParticles = function () {
    if (particles.length) {
      particles.forEach(function (p) {
        editor.removeObject(p, DISPATCH_SIGNALS_OVERRIDE)
      })
    }
    particles = []
  }

  var linesByGroup = {}
  var lineGroups = {
    parallel: 0x000000,
    perpendicular: 0x000000,
    diagonal: 0x000000,
  }
  // Alternatiev for debugging
  window.debugLineSegments = function () {
    // Replace snap lines colors with debug colors
    lineGroups = {
      parallel: 0xff0000,
      perpendicular: 0x00ff00,
      diagonal: 0x0000ff,
    }
  }
  window.cacheLineSegmentMaterials = {}

  window.showLineSegments = function (lineGroupName, arrayOfLineSegmentsAsPairs) {
    var lines = linesByGroup[lineGroupName]
    if (lines) {
      editor.removeObject(lines, DISPATCH_SIGNALS_OVERRIDE)
      lines = null
    }
    if (!window.cacheLineSegmentMaterials[lineGroupName]) {
      window.cacheLineSegmentMaterials[lineGroupName] = new THREE.LineBasicMaterial({
        color: lineGroups[lineGroupName],
        linewidth: 1,
        // linecap: 'round', //ignored by WebGLRenderer
        // linejoin: 'round', //ignored by WebGLRenderer
      })
    }
    var material = window.cacheLineSegmentMaterials[lineGroupName]

    var geometry = new THREE.Geometry()
    arrayOfLineSegmentsAsPairs.forEach(function (p) {
      geometry.vertices.push(p)
    })
    lines = new THREE.LineSegments(geometry, material)
    lines.userData.excludeFromExport = true
    lines.selectable = false
    editor.addObject(lines, undefined, DISPATCH_SIGNALS_OVERRIDE)
    linesByGroup[lineGroupName] = lines
  }

  window.clearSnapGuides = function () {
    for (var lineGroupName in linesByGroup) {
      let lines = linesByGroup[lineGroupName]
      if (lines) {
        editor.removeObject(lines, DISPATCH_SIGNALS_OVERRIDE)
        lines = null
      }
    }
    window.clearParticles()
  }

  document.addEventListener(
    'dragover',
    function (event) {
      if (!Designer.listeningForEvents('dragdrop')) {
        window.studioDebug && console.log('Ignoring file drop in Designer.js: Editor and MapHelper are not interactive')
        return
      }

      event.preventDefault()
      event.dataTransfer.dropEffect = 'copy'
    },
    false
  )

  document.addEventListener(
    'drop',
    function (event) {
      if (!Designer.listeningForEvents('dragdrop')) {
        window.studioDebug && console.log('Ignoring file drop in Designer.js: Editor and MapHelper are not interactive')
        return
      }

      event.preventDefault()

      if (event.dataTransfer.files.length > 0) {
        editor.loader.loadFile(event.dataTransfer.files[0], Designer.showNotification)
      }
    },
    false
  )

  window.shiftIsDown = false

  // Variation which only triggers after a delay which seems to allow window to resize first
  // Could be due to React setState() not occurring immediately
  if (true) {
    window.addEventListener('resize', Designer.onWindowResizeAfterDelay, false)
  } else {
  }

  //

  var isLoadingFromHash = false

  //Prefer javascript window hash so this can override anything provided in the querystring

  var hash = window.Designer.getHash()

  if (hash.substr(1, 5) === 'file=') {
    var file = hash.substr(6)

    if (confirm('Any unsaved data will be lost. Are you sure?')) {
      var loader = new THREE.FileLoader()
      loader.crossOrigin = ''
      loader.load(file, function (text) {
        editor.clear()
        editor.fromJSON(JSON.parse(text))
      })

      isLoadingFromHash = true
    }
  }

  SnapshotHelper.insert(viewport.container.dom)

  editor.signals.typingInField.add(function (value) {
    Designer.typingInField = value
  })

  editor.signals.sceneLoadedAndProfileLoaded.add(function () {
    AutoApplyHelper.detectAndApplyAutoSync()

    editor.history.storefirstCmdIdAfterInit()

    if (ReplayHelper.saveReplayIsEnabled() && !ReplayHelper.replayInProgress) {
      ReplayHelper.startRecording()
    }
  })

  editor.signals.sceneLoaded.add(function () {
    if (window.loadAvailableImagery) {
      window.loadAvailableImagery()
    }

    // When scene is loaded after profile data
    if (AccountHelper.isLoaded()) {
      editor.signals.sceneLoadedAndProfileLoaded.dispatch()
    }
  })

  editor.signals.cameraChanged.add(function () {
    if (!editor.interactive()) return

    // Hide blurred texture when viewing from a perfectly top-down camera angle to avoid artifacts on steep edges
    if (!editor.sceneIsLoading && !WorkspaceHelper.designIsLoading && editor.terrainSettings.wallBlurringActive) {
      const terrain = editor.getTerrain()
      if (terrain) {
        const cameraIsOverhead = editor.controllers?.Camera?.orientationIsApproximatelyTopDown()
        terrain.setWallBlurringActive(!cameraIsOverhead)
      }
    }

    if (MapHelper.interactive() && !editor.sceneIsLoading && !WorkspaceHelper.designIsLoading) {
      try {
        MapHelper.setMapPositionFromWorldPositionAtViewportCenter()
      } catch (error) {
        console.log(
          'Exception in signals.cameraChanged() from MapHelper.setMapPositionFromWorldPositionAtViewportCenter(). Ignorning...'
        )
      }
    }

    if (
      !ViewHelper.selectedView()?.viewBoxParams &&
      ViewHelper.selectedView()?.show_customer === true &&
      editor.designMode !== 'myenergy' &&
      editor.designMode !== 'explore'
    ) {
      SnapshotHelper.showAndFadeOut()
    }
  })

  editor.signals.shadingUpdated.add(function () {
    SceneHelper.updateSun()
    SceneHelper.shadingPoints()
  })

  //Bind
  editor.signals.viewsChanged.add(function (views, selectedViewUuid) {
    if (typeof views === 'undefined') {
      views = ViewHelper.views
    }

    if (editor.scene) {
      editor.scene.views = ViewHelper.views
      editor.scene.selectedViewUuid = ViewHelper.selectedViewUuid()
    }

    if (typeof selectedViewUuid === 'undefined') {
      selectedViewUuid = ViewHelper.selectedViewUuid()
    }

    //Set roof visibility based on view.showTextures
    var view = ViewHelper.getViewByUuid(selectedViewUuid, views)

    // Auto-create ground if this view has showGround = true
    if (view) {
      if (view.showGround && !this.getGround()) {
        window.SceneHelper.refreshGround()
      }
    }

    if (view) {
      // Special override for showTextures for 3D views. Ideally we should untangle this but that requires verifying
      // exactly how showTextures behaves across all view types.
      var showTextures =
        view.showTextures ||
        (MapData.mapTypeIs3D(view.mapData?.mapType) && view.facetDisplayModeOverride === 'alternative')

      OsFacetMesh.setVisibilityByType(
        'textures',
        showTextures && view.style !== 'planset' && ViewHelper.facetDisplayMode() !== 'edges'
      )

      if (this.getGround()) {
        this.getGround().setReceiveShadows(view.showTextures && getStudioDetail() === 'high')
      }
    }

    //Set ground visibility based on view.showGround
    if (view) {
      if (this.getGround()) {
        this.getGround().setVisible(view.showGround)
      }
    }

    //Set terrain & sky visibility only for Nearmap3D
    var terrainVisibility =
      view &&
      view.mapData &&
      (view.mapData.mapType == 'Nearmap3D' ||
        view.mapData.mapType == 'Google3D' ||
        view.mapData.mapType == 'GetMapping3D' ||
        view.mapData.mapType == 'GetMappingPremium3D' ||
        view.mapData.mapType == 'Vexcel3D')
        ? true
        : false
    if (this.getTerrain()) {
      this.getTerrain().visible = terrainVisibility
    }
    if (window.getSky && window.getSky()) {
      window.getSky().visible = terrainVisibility
    }

    var showFacets = Designer.showFacetsOnActiveMapType()

    //Set OsEdge style and visibility based on view.style
    if (view) {
      const usePlansetStyle = view.style === 'planset' || ViewHelper.facetEdgesDisplayMode() === 'planset'
      Designer.style = usePlansetStyle ? 'planset' : 'default'

      const edgeDisplayMode = ViewHelper.facetEdgesDisplayMode()
      const showEdges = showFacets && (usePlansetStyle || edgeDisplayMode !== 'none')
      const showWalls = showFacets && ViewHelper.facetWallsDisplayMode() !== 'none'
      const showWires = this.getDesignGuidesVisibility() && showEdges

      this.filter('type', 'OsEdge').forEach(function (o) {
        o.refreshLine()

        if (o.isWire()) {
          o.setVisible(showWires)
        } else if (o.isWallCorner) {
          // Special handling of visibility for wallCorners which are non-selectable
          // Currently just hide them in all views other than 3D/None
          // Should we add a clearer way to identify wallCorners instead of using !o.selectable?
          // e.g. Set type = 'OsEdgeWallCorner'?
          o.visible = showWalls
        } else if (usePlansetStyle) {
          o.visible = true
        } else {
          //If not style === 'planset' then show if editor.displayMode === interactive
          // o.visible = this.visibilityByMode[this.displayMode].interactiveElements
          o.visible = showEdges
        }
      }, this)
    }

    // Not sure why we appear to be in the Editor.js scope here?
    this.checkElementVisibility()

    if ((editor.getTerrain() || editor.scene.dsmUrl) && !ViewHelper.has3DView()) {
      // Removed this warning because it is probably more confusing than helpful.
      // Designer.showNotification(window.translate('Unloading 3D data which is no longer in use by any views.'))

      OsTerrain.unloadTexturedDSM(editor)
      ViewHelper.clean3DUrls()
    }

    // If 3D URLs are set and data has not yet been downloaded then start downloading now
    // @TODO: We cannot store this URL because it has a token which will expire after 30 days.
    // Store something that can be reloaded
    // => We should replace the token/session with a placeholder so we can later reload and re-inject a fresh placeholder
    //
    if (editor.scene.dsmUrl && editor.scene.orthoUrl) {
      /*
      Prevent loading if a) preloading is disabled (but for UX1 we always allow preloading beacuse this is used
      for normal 3D loading)
      */
      if (!AccountHelper.isPro() && !ViewHelper.allowPreload3D()) {
        // Only pre-load 3D if
        // a) 3D view is accessible by the current user
        // b) User is Pro (which we guess based on AccountHelper.isLoaded())
        // Specifically this means either a) in studio b) in MyE/PDF where the 3D view is visible to the customer
      } else if (
        editor.scene.dsmUrl !== editor.scene.dsmUrlLoadRequested &&
        editor.scene.orthoUrl !== editor.scene.orthoUrlLoadRequested
      ) {
        // Record loading so we don't load again
        editor.scene.dsmUrlLoadRequested = editor.scene.dsmUrl
        editor.scene.orthoUrlLoadRequested = editor.scene.orthoUrl
        editor.loadTexturedDSM(editor.scene.dsmUrl, editor.scene.orthoUrl)
      }
    }

    // Disable move controls if 3D terrain exists but we are not in a 3D view
    if (
      editor.viewport.transformControls &&
      editor.viewport.transformControls.transformMode &&
      ViewHelper.disable3DTools()
    ) {
      window.editor.signals.transformModeChanged.dispatch(null)
    }

    SceneHelper.applyFacetDisplayMode()

    // Update visiblity for facets AFTER calling SceneHelper.applyFacetDisplayMode()
    // because rebuilding the facets may make the faces visible which needs to be over-ridden
    // We still rebuild the facets because they may need to be re-created based on other changes
    // but we just make them invisible.
    this.filter('type', 'OsFacet').forEach(function (o) {
      if (o.mesh) {
        o.mesh.visible = showFacets
      }
      o.vertices.forEach((n) => {
        // Only use this to hide vertices, not to show them
        // Showing vertices is handled elsewhere and depends on selected object, etc.
        if (showFacets === false) {
          n.visible = showFacets
        }
      })
    })

    //@TODO: Why is a timeout required to avoid two frames being rendered at once?
    //(or previous frame not being cleared fully?)
    // setTimeout(function(){

    // Uploaded image designs & design mode
    // If uploading an image into studio before design mode has been selected (i.e. while design mode panel is in
    // 'open-locked' state) then the addition of a new view should automatically change the design mode selector
    // to closed-interactive because obviously something has happened to bypass design mode selection.
    // if (views.length > 1 && Designer.uiRefs && Designer.uiRefs.ToolbarDesignMode) {
    //   Designer.uiRefs.ToolbarDesignMode.close()
    // }
    // Designer.callUi('ToolbarDesignMode', 'close')

    this.renderIfNotAnimating()

    // },1)
  }, editor)

  //@TODO: reinstate
  // window.Designer.uiRefs['ToolbarViews'].setState({mapTypes: Object.keys(window.MAP_TYPES) })

  // //System selection
  // editor.signals.systemSelected.add(function(object) {
  //   var uuid = object ? object.uuid : null
  //
  //   refreshSystemsUI()
  // })

  editor.signals.objectAdded.add(function (object, stopRequestCalculations) {
    SceneHelper.getSceneBoundingBoxCacheInvalidate()

    // Objects which don't belong to a system will automatically clear shading
    if (!object.getSystem) {
      Designer.clearShadingFromObjectAddedChangedRemovedDebouncedSkipRedundant(object)
    } else if (object.getSystem) {
      var system = object.getSystem()

      if (object === system) {
        // Do not trigger calcs when a new empty system is added. e.g. When starting or restarting a new project.
        // Let recalcs happen automatically after the first change is made.
        return
      }

      if (system && !system.isDuplicating) {
        Designer.clearShadingFromObjectAddedChangedRemovedDebouncedSkipRedundant(object)

        //check system which can be empty (e.g. for removed modules etc)
        if (system && !stopRequestCalculations) {
          Designer.requestSystemCalculations(system)
        }
      }
    }
  })

  editor.signals.objectChanged.add(function (object, attributeName) {
    SceneHelper.getSceneBoundingBoxCacheInvalidate()

    Designer.clearShadingFromObjectAddedChangedRemovedDebouncedSkipRedundant(object)

    if (object.type === 'OsSystem' && attributeName === 'is_current' && object.is_current === true) {
      //system marked as current, remove from any other systems
      editor.filter('type', 'OsSystem').forEach(function (s) {
        if (s.uuid !== object.uuid && s.is_current === true) {
          s.is_current = false
          editor.signals.objectChanged.dispatch(s, 'is_current')
        }
      })
    }

    if (
      attributeName !== 'undefined' &&
      (attributeName == 'order' ||
        attributeName == 'setPosition' ||
        attributeName == 'name' ||
        attributeName == 'visible' ||
        attributeName == 'output' ||
        attributeName == 'show_customer' ||
        attributeName == 'skipRecalc' ||
        attributeName == 'slots')
    ) {
      window.studioDebug && console.log('Skip add to processQueue, attributeName:' + attributeName)
    } else if (object.getSystem) {
      var system = object.getSystem()

      //check system which can be empty (e.g. for removed modules etc)
      if (system) {
        Designer.requestSystemCalculations(system)
      }
    }

    /*
    Situations where we need to update strings:
    module: position changed, toggle visibility
    modulegrid: position changed, paint/unpaint modules

    Only update at most every 100ms to avoid flooding???

    Don't even both checking if already queued

    */
    if (!Designer.isRefreshStringsScheduled() && OsString.visible()) {
      var refreshStringsRequired = false

      if (editor.selectedSystem) {
        if (object.type == 'OsModuleGrid') {
          refreshStringsRequired = true
        } else if (object.type == 'OsEdge' && object.changeAffectsModuleGrids(editor.selectedSystem?.uuid)) {
          refreshStringsRequired = true
        } else if (object.type == 'OsFacet' && object.changeAffectsModuleGrids(editor.selectedSystem?.uuid)) {
          refreshStringsRequired = true
        }
      }

      if (refreshStringsRequired) {
        Designer.scheduleRefreshStrings()
      }
    }
  })

  editor.signals.objectRemoved.add(function (object) {
    SceneHelper.getSceneBoundingBoxCacheInvalidate()

    Designer.clearShadingFromObjectAddedChangedRemovedDebouncedSkipRedundant(object)
    //If we deleted an object that belongs to a system, queue system calcs
    // Unfortunately by this stage the parent has already been removed
    // So object.getSystem() is useless and will always return empty
    // We workaround by storing the parent inside object.parentBeforeRemoval

    if (object.parentBeforeRemoval && object.type !== 'OsAnnotation') {
      //check system which can be empty (e.g. for removed modules etc)
      Designer.requestSystemCalculations(object.parentBeforeRemoval)
    }

    if (this.selectedSystem === object) {
      // If we delete a system select the previous system
      this.selectSystem(null)
      this.select(this.getSystems().length > 0 ? this.getSystems()[0] : null)
    } else if (this.selected === object) {
      // If we deleted the selected object that belongs to a system, select that system
      if (object.parentBeforeRemoval) {
        this.select(object.parentBeforeRemoval)
      } else {
        this.select(null)
      }
    }
  }, editor)

  editor.signals.projectDataLoaded.add(function () {
    // if (window.Designer.uiRefs['ToolbarMenu']) {
    var warnings = []

    if (WorkspaceHelper.project && !WorkspaceHelper.project.utility_tariff) {
      warnings.push('Utility tariff not set, utility bill savings will be zero.')
    }

    // window.Designer.uiRefs['ToolbarMenu'].setState({
    //   address: WorkspaceHelper.params.address,
    //   warnings: warnings,
    // })
    // Designer.setUiState('ToolbarMenu', {
    //   address: WorkspaceHelper.params.address,
    //   warnings: warnings,
    // })
    // }
    var discardChanges = true
    this.setMode(this.displayMode, discardChanges)

    var systemUuidsToPrices = {}
    this.filter('type', 'OsSystem').forEach(function (s) {
      try {
        if (s && s.pricing && s.pricing.system_price_including_tax) {
          systemUuidsToPrices[s.uuid] = s.pricing.system_price_including_tax
        }
      } catch (err) {
        console.warn(err)
      }
    })

    WorkspaceHelper.storeSystemPricesFromDatabase(systemUuidsToPrices)
  }, editor)

  editor.signals.historyChanged.add(function () {
    window.studioDebug && console.log('Warning: Designer.undoHistory currently only supports a single instance')
    Designer.undoHistory = {
      numUndos: editor.history.undos.length,
      numRedos: editor.history.redos.length,
    }
    // if (window.Designer.uiRefs['ToolbarMenu']) {
    //   window.Designer.uiRefs['ToolbarMenu'].refresh()
    // }
    // Designer.callUi('ToolbarMenu', 'refresh')
  })

  // editor.signals.transformModeChanged.add(function (mode) {
  // if (window.Designer.uiRefs['PanelProperties']) {
  //   window.Designer.uiRefs['PanelProperties'].setState({
  //     transformMode: mode,
  //   })
  // }
  // Designer.setUiState('PanelProperties', {
  //   transformMode: mode,
  // })
  // })

  window.editor.signals.expansionPanelChanged.add(function (expanded) {
    // Viridian is only required for color/lighting updates, all other interactivity is always supported
    // if the OsOther object has a displayable child object
    OsOther.refreshVisibility(editor.selectedSystem.others(), expanded)
    Designer.panelExpanded = expanded
  })

  //Disable translation tools initially until manually selected
  editor.signals.transformModeChanged.dispatch(null)

  Designer.requestDelete = function (object, skipConfirm) {
    if (typeof object === 'undefined') {
      object = editor.selected
    }

    if (skipConfirm !== true) {
      var confirmationPrompt = object.confirmBeforeDelete ? object.confirmBeforeDelete() : 'Delete ' + object.name + '?'
      if (confirm(confirmationPrompt) === false) return
    }

    if (object.type === 'OsGroup') {
      object.handleDelete(editor)
    } else {
      editor.deleteObject(object)
    }
  }

  editor.signals.controlModeChanged.add(function (mode) {
    if (MapHelper.activeMapInstance) {
      MapHelper.activeMapInstance.interactive(mode == 'map')
    }

    //Show/hide inset border which indicates that only obliques are being panned
    var insetBorderForMapsElement = document.getElementById('insetBorderForMaps')
    if (insetBorderForMapsElement) {
      insetBorderForMapsElement.style.display = mode == 'map' ? '' : 'none'
    }

    //Show Scene as semi-transparent only if scene is disabled and map is interactive
    if (!editor.interactive() && MapHelper.interactive()) {
      //$('#viewport').css({display:'none'})
      $('#viewport').css({ 'pointer-events': 'none' })
      $('#viewport').css({ opacity: 0.4 })

      //Divs which will block clicks on the map below the 3D scene
      $('.map-blocker').css({ 'pointer-events': 'none' })

      MapHelper.setOpacity('')
    } else if (editor.interactive() && !MapHelper.interactive()) {
      //If map inactive show map as semi-transparent
      MapHelper.setOpacity(0.7)
    } else {
      MapHelper.setOpacity('')

      //$('#viewport').css({display:''})
      $('#viewport').css({ 'pointer-events': 'all' })
      $('#viewport').css({ opacity: 1.0 })

      //Divs which will block clicks on the map below the 3D scene
      $('.map-blocker').css({ 'pointer-events': '' })
    }
  })

  editor.signals.cycleNextMapSceneControl.add(function (nextValue) {
    if (!nextValue) {
      var currentValue = window.Designer.controlMode

      if (currentValue == 'both') {
        // We now only cycle between "both" and "map". "scene" is effectively disabled
        // nextValue = 'scene'
        nextValue = 'map'
      } else if (currentValue == 'scene') {
        nextValue = 'map'
      } else if (currentValue == 'map') {
        nextValue = 'both'
      } else {
        window.studioDebug &&
          console.log('Warning: cycleNextMapSceneControl received but currentValue not set. Set to: both')
        nextValue = 'both'
      }
    }

    //hide tool tips
    if (!window.ViewHelper.selectedView()?.isAligned) {
      window.ViewHelper.selectedView().isAligned = true
      window.ViewHelper.saveView()
    }

    Designer.changeControl(nextValue)
  })

  editor.signals.mapAnimationFinished.add(function () {
    if (ViewHelper.views.length > 0) {
      if (MapHelper.activeMapInstance.dom.hidden === true) {
        window.studioDebug && console.log('mapAnimationFinished: Do not save as view')
      } else {
        if (window.editor.interactive() || window.Designer.controlMode === 'map') {
          // Only save if editor is interactive OR we are in map-only mode
          //do we need this?
          // ViewHelper.saveView()
        }
      }
    } else {
      window.studioDebug &&
        console.log('Warning: Calling ViewHelper.saveView() but no views present... we should not fire this event')
    }
  })

  editor.signals.projectConfigurationChanged.add(function () {
    // Rebuild all facetMeshes to update setbacks
    editor.filter('type', 'OsFacet').forEach(function (facet) {
      facet.refreshMesh(editor)
    })
  })

  editor.signals.objectSelected.add(Designer.listenForChangeStringVisibility)
  editor.signals.objectSelected.add(Designer.listenForDisableShadingVisibility)
  editor.signals.objectSelected.add(Designer.listenForRefreshFacetHelpersVisibility)
  editor.signals.objectSelected.add(Designer.listenForObjectSelectedToEnableHandleController)

  Designer.initCameraAnimationEvents(editor)

  window.editor.uiResume('render', 'Designer.init')
}

Designer.embedDisplayWithData = function (data, DesignerRootDivSelector, disableMapHelper, injectEditor, apiBaseUrl) {
  console.info(
    'Designer.embedDisplayWithData(): with window.PUBLIC_URL=' +
      window.PUBLIC_URL +
      ' and DesignerRootDivSelector: ' +
      DesignerRootDivSelector
  )

  // Disable MapHelper
  if (disableMapHelper === true) {
    window.MapHelper = null
  }

  var params = {
    data: data,
  }

  window.Designer.init($(DesignerRootDivSelector)[0], apiBaseUrl, null, injectEditor)

  injectEditor.disableTextureRefreshHack = true
  injectEditor.uiPauseAllowed.ui = false
  injectEditor.uiPauseAllowed.render = false

  var discardChanges = true
  injectEditor.setMode('presentation', discardChanges)
  // window.editor.setMode('interactive', discardChanges)

  window.WorkspaceHelper.loadWithData(injectEditor, params.data, params).then(function () {
    injectEditor.uiResume('ui', 'Designer.init')
    injectEditor.uiResume('render', 'Designer.init')
  })
}

Designer.CashFlowChartData = function (chartType, cashFlows, paybackYear, paymentType, currencySymbol, YAxisRange) {
  // First year then every 5th year. e.g. 0, 4, 9, 14, 19
  return {
    labels: Array(cashFlows.length)
      .fill()
      .map(function (v, i) {
        return i === 0 || (i + 1) % 5 === 0 ? new Date().getFullYear() + i : ''
      }),
    series: [
      {
        name: chartType,
        data: cashFlows,
        paybackYear: paybackYear,
        paymentType: paymentType,
        currencySymbol: currencySymbol,
      },
    ],
    options: {
      referenceValue: 0,
      low: YAxisRange.minYAxis,
      high: YAxisRange.maxYAxis !== 0 ? YAxisRange.maxYAxis : undefined, // This value cannot be 0
      seriesBarDistance: 10,
      // chartPadding: 20,
      showLabel: true,
      fullWidth: true,
      width: '100%',
      plugins: [
        Designer.ctBarLabelsCashFlows({
          textAnchor: 'middle',
        }),
      ],
      axisX: {
        showGrid: false,
      },
      axisY: {
        scaleMinSpace: 15, //Beware: If there are two few ticks sometimes $0 axis line may not show
        offset: 70,
        labelOffset: {
          x: 0,
          y: 5,
        },
        labelInterpolationFnc: function (value) {
          return window.formatCurrencyWithSymbol(parseInt(value, 10), currencySymbol, window.locale, 0)
        },
      },
    },
  }
}

Designer.ctBarLabelsCashFlows = function (options) {
  var ctBarLabelsCashFlows
  return (ctBarLabelsCashFlows = function (chart) {
    var defaultOptions = {
      labelClass: 'ct-bar-label cash-flows',
      labelInterpolationFnc: Chartist.noop,
      labelOffset: {
        x: 0,
        y: 0,
      },
      position: {
        x: null,
        y: null,
      },
      textAnchor: 'middle',
    }

    options = Chartist.extend({}, defaultOptions, options)

    if (chart instanceof Chartist.Bar) {
      chart.on('draw', function (data) {
        if (data.type === 'bar') {
          var label = Designer.labelForData(data)
          var grey = '#ababab'
          if (data.value.y < 0) {
            data.element.attr({
              style: 'stroke: ' + grey + '!important;',
            })
          }
          data.group
            .elem(
              'text',
              {
                // This gets the middle point of the bars and then adds the
                // optional offset to them
                x: label.x,
                y: label.y,
                style: label.style,
              },
              options.labelClass
            )
            .text(label.label)
        }
      })
    }
  })
}

const addLabelPointerCircle = (d, placement) => (
  d.group.append(
    new Chartist.Svg(
      'circle',
      {
        cx: d.x2,
        cy: placement === 'end' ? d.y2 : d.y1,
        r: 3,
      },
      'ct-marker'
    )
  ),
  d
)

const addLabelPointerLine = (d, placement) => (
  d.group.append(
    new Chartist.Svg('line', {
      x1: d.x2,
      y1: placement === 'end' ? d.y2 : placement === 'start' ? d.y2 - 7 : d.y1,
      x2: d.x2,
      y2: placement === 'end' ? d.y2 + 7 : placement === 'start' ? d.y2 : d.y1,
      style: 'stroke:rgba(0,0,0, 0.3);stroke-width:1',
    })
  ),
  d
)

//   //Difference with PDF version: In MyEnergy, 'Initial Investment' shows for both annual and cumulative
Designer.labelForData = function (data, version, hasPhoenix) {
  if (
    (data.series.name.startsWith('cash_flows') ||
      (data.series.name.startsWith('bank_balance') && version === 'interactive')) &&
    data.index === 0 &&
    data.value.y < 0 &&
    data.series.paybackYear > 0 &&
    data.series.paymentType === 'cash'
  ) {
    data = addLabelPointerCircle(data, 'end')
    data = addLabelPointerLine(data, 'end')

    return {
      label: window.translate('Initial Investment'),
      style: 'text-anchor: start',
      x: (data.x1 + data.x2) / 2 + 0,
      y: Math.max(data.y1, data.y2) + 18,
    }
  } else if (
    data.series.name.startsWith('bank_balance') &&
    data.index === Math.floor(data.series.paybackYear) &&
    data.series.paybackYear < 15 &&
    data.series.paybackYear > 0 &&
    data.series.paymentType === 'cash'
  ) {
    data = addLabelPointerCircle(data, 'start')
    data = addLabelPointerLine(data, 'middle')

    var parts = Utils.toYearsAndMonths(data.series.paybackYear)
    var monthsLabel =
      parts.months > 0
        ? ', ' + parts.months + ' ' + (parts.months > 1 ? window.translate('months') : window.translate('month'))
        : ''
    var paybackYearLabel =
      (parts.years >= 10 ? parts.years + 1 : parts.years) +
      ' ' +
      (parts.years == 1 ? window.translate('year') : window.translate('years')) +
      (parts.years >= 10 ? '' : monthsLabel)
    monthsLabel

    return {
      label: window.translate('Payback') + ' ' + paybackYearLabel,
      style: 'text-anchor: start',
      x: (data.x1 + data.x2) / 2,
      y: data.y1 + 18,
    }
  } else if (
    data.series.name.startsWith('bank_balance') &&
    data.index === data.series.data.length - 1 &&
    data.value.y > 0
  ) {
    data = addLabelPointerCircle(data, 'end')
    data = addLabelPointerLine(data, 'start')
    return {
      label:
        window.translate(hasPhoenix ? 'Estimated Net Savings' : 'Net Savings') +
        ' ' +
        window.formatCurrencyWithSymbol(Math.round(data.value.y), data.series.currencySymbol, window.locale, 0),
      style: 'text-anchor: end',
      x: (data.x1 + data.x2) / 2,
      y: data.y2 - 8,
    }
  } else if (
    data.series.name.startsWith('cumulative_savings') &&
    data.index === data.series.data.length - 1 &&
    data.value.y > 0
  ) {
    data = addLabelPointerCircle(data, 'end')
    data = addLabelPointerLine(data, 'start')
    return {
      label:
        window.translate('Lifetime Bill Savings') +
        ' ' +
        window.formatCurrencyWithSymbol(
          Math.round(data.series.total_lifetime_savings),
          data.series.currencySymbol,
          window.locale,
          0
        ),
      style: 'text-anchor: end',
      x: (data.x1 + data.x2) / 2 - 8,
      y: data.y2 - 8,
    }
  } else {
    return {
      label: '',
      style: 'display: none',
      x: 0,
      y: 0,
    }
  }
}

Designer.BillSavingsChartData = function (
  oldBill,
  newBill,
  paymentTitle,
  paymentType,
  regularPayment,
  currencySymbol,
  billFrequency,
  totalBillBasedIncentive,
  showNetSpend
) {
  var billFrequencyLabels = {
    quarterly: 'Quarterly',
    monthly: 'Monthly',
    every_second_month: 'Every Second Month',
  }

  var oldBillSeries = {
    name: window.translate('Old Bill'),
    className: 'OldBill',
    data: [oldBill, 0],
    currencySymbol: currencySymbol,
  }
  var newBillSeries = {
    name: window.translate('New Bill'),
    className: 'NewBill',
    data: [0, newBill],
    currencySymbol: currencySymbol,
  }

  var totalBillBasedIncentiveSeries = {
    name: window.translate('Incentives'),
    className: 'Incentives',
    data: [0, -totalBillBasedIncentive],
    currencySymbol: currencySymbol,
  }

  var netSpend = newBill + regularPayment - totalBillBasedIncentive
  var netSpendSeries = {
    name: window.translate('Net Spend'),
    className: 'NewBill NetSpend',
    data: [0, netSpend],
    currencySymbol: currencySymbol,
  }

  var regularPaymentSeries = {
    name:
      (paymentType === 'regular_payment' ? paymentTitle + ' (' : '') +
      window.translate(billFrequencyLabels[billFrequency]) +
      (paymentType === 'regular_payment' ? ')' : ' ' + window.translate('Payments')),
    className: 'Payment',
    data: newBill < 0 ? [0, regularPayment - newBill] : [0, regularPayment],
    currencySymbol: currencySymbol,
  }

  var series = []
  series.push(oldBillSeries)
  if (showNetSpend) {
    series.push(netSpendSeries)
  } else if (regularPayment > 0) {
    series.push(newBillSeries)
    series.push(regularPaymentSeries)
  } else if (newBill < 0 && !!totalBillBasedIncentive) {
    series.push(newBillSeries)
    series.push(totalBillBasedIncentiveSeries)
  } else {
    series.push(newBillSeries)
  }

  var low = Math.min(0, oldBill, newBill, netSpend)
  var high = Math.max(oldBill * 1.2, (newBill + regularPayment) * 1.2, low)

  return {
    labels: ['Old', 'New'],
    series,
    options: {
      low,
      high,
      stackBars: true,
      seriesBarDistance: 20,
      fullWidth: true,
      chartPadding: 0,
      regularPayment: regularPayment,
      axisX: {
        showLabel: false,
        showGrid: false,
      },
      axisY: {
        showGrid: true,
        offset: 55,
        labelInterpolationFnc: function (value) {
          return window.formatCurrencyWithSymbol(parseInt(value, 10), currencySymbol, window.locale, 0)
        },
      },
      plugins: [
        Designer.ctBarLabelsBillSavings({
          textAnchor: 'middle',
        }),
      ],
    },
  }
}

Designer.LifeTimeSavingsMaxMagnitudeY = function (currentBillsYearly, proposedBillsYearly, regularPaymentsYearly) {
  const cumulativeSavingsByYear = []
  const cumulativeCurrentBillsByYear = currentBillsYearly
    .map((bill) => bill.annual.total)
    .map(((sum = 0), (n) => (sum += n)))
  const cumulativeProposedBillsByYear = proposedBillsYearly
    .map((bill) => bill.annual.total)
    .map(((sum = 0), (n) => (sum += n)))
  const cumulativeRegularPaymentsByYear = regularPaymentsYearly?.map(((sum = 0), (n) => (sum += n)))

  for (let year = 0; year < currentBillsYearly.length; year++) {
    cumulativeSavingsByYear[year] = cumulativeCurrentBillsByYear[year] - cumulativeProposedBillsByYear[year]
    if (regularPaymentsYearly && regularPaymentsYearly.length > 0) {
      cumulativeSavingsByYear[year] += cumulativeRegularPaymentsByYear[year]
    }
  }

  return {
    maxYAxis: Math.max(Math.max.apply(null, cumulativeSavingsByYear), 0),
    minYAxis: Math.min(Math.min.apply(null, cumulativeSavingsByYear), 0),
  }
}

Designer.LifeTimeSavingsChartData = function (
  billsYearlyCurrent,
  billsYearlyProposed,
  currencySymbol,
  YAxisRange,
  regularPaymentsYearly,
  isIgnoreTax,
  distributedGenerationRules,
  hideExportCreditBreakdown
) {
  // Return cumulative bill savings for every 5 years, but inclusive of first and final year simulated.
  // For example, if we had simulated 15 years, it should return the following years = [0, 4, 9, 14, 15]
  const yearsToDisplay = [] // maybe we need a better descriptive name
  const stepsInYear = 5
  for (let i = 0; i < billsYearlyProposed.length; i++) {
    // initial
    if (i === 0) {
      yearsToDisplay.push(i)
      continue
    }
    // stepsInYear to display
    if ((i + 1) % stepsInYear === 0) {
      yearsToDisplay.push(i)
      continue
    }
    // Add the final year if it is not already included in the years
    if (i === billsYearlyProposed.length - 1) {
      yearsToDisplay.push(i)
      continue
    }
  }

  const dataByYear = []

  const dataForYear = {
    year: 0,
    cumulativeFeedInTariff: 0,
    cumulativeIncentives: 0,
    cumulativeSavings: 0,
    cumulativeNetSavings: 0,
  }
  for (let year = 0; year < billsYearlyProposed.length; year++) {
    const billIncentiveForYearProposed = billsYearlyProposed[year].annual.incentives.reduce(
      (sum, incentive) => sum + incentive.inc_tax,
      0
    )
    const billIncentiveForYearCurrent = billsYearlyCurrent[year].annual.incentives.reduce(
      (sum, incentive) => sum + incentive.inc_tax,
      0
    )
    const billIncentiveForYear = billIncentiveForYearProposed - billIncentiveForYearCurrent

    //Note: regular_payments are negative.
    // We will convert it to a positive value to avoid confusion later on.
    const regularPaymentsForYear =
      regularPaymentsYearly && regularPaymentsYearly[year] ? -regularPaymentsYearly[year] : 0

    const billTaxForYear = isIgnoreTax ? 0 : billsYearlyCurrent[year].annual.tax - billsYearlyProposed[year].annual.tax
    // Note that total_utility_bill_only amount is always exclusive of tax. If we do not want to ignore tax amount (i.e., for residential projects)
    // we need to make sure to add the tax amount back to the total bill savings amount.
    const billSavingsForYear =
      billsYearlyCurrent[year].annual.total_utility_bill_only -
      billsYearlyProposed[year].annual.total_utility_bill_only +
      billTaxForYear

    // Note that feed-in-tariff is the same as "export credit"
    // If the distributed generation rules is 'heco_customer_grid_supply', we set the feed-in-tariff to 0
    // since it is already included in the bill savings amount to prevent double counting in calculated savings.
    const feedInTariffForYear =
      distributedGenerationRules !== 'heco_customer_grid_supply'
        ? billsYearlyProposed[year].annual.feed_in_tariff - billsYearlyCurrent[year].annual.feed_in_tariff
        : 0

    // We need to subtract feed-in tariff from bill savings since the bill savings already include the feed-in tariff amount.
    const savingsExcludingFeedInTariffForYear = billSavingsForYear - regularPaymentsForYear - feedInTariffForYear

    const feedInTariffForYearShown = hideExportCreditBreakdown ? 0 : feedInTariffForYear
    const totalSavingsForYearShown = hideExportCreditBreakdown
      ? savingsExcludingFeedInTariffForYear + feedInTariffForYear
      : savingsExcludingFeedInTariffForYear

    dataForYear['year'] = year + 1
    dataForYear['cumulativeFeedInTariff'] += feedInTariffForYearShown
    dataForYear['cumulativeIncentives'] += billIncentiveForYear
    dataForYear['cumulativeSavings'] += totalSavingsForYearShown
    dataForYear['cumulativeNetSavings'] += totalSavingsForYearShown + billIncentiveForYear + feedInTariffForYearShown

    if (yearsToDisplay.indexOf(year) !== -1) {
      dataByYear.push({ ...dataForYear })
    }
  }

  const totalLifetimeSavings =
    dataForYear['cumulativeFeedInTariff'] + dataForYear['cumulativeIncentives'] + dataForYear['cumulativeSavings']

  const hasNegativeSavings = dataByYear.some((v) => v['cumulativeSavings'] < 0)
  const YAxisAdjustmentFactor = 1.1
  return {
    labels: dataByYear.map((v) => v['year']),
    series: [
      {
        name: 'cumulative_feed_in_tariff',
        data: dataByYear.map((v) => (hasNegativeSavings ? 0 : v['cumulativeFeedInTariff'])),
        className: 'feed-in-tariff',
      },
      {
        name: 'cumulative_performance_based_incentive',
        data: dataByYear.map((v) => (hasNegativeSavings ? 0 : v['cumulativeIncentives'])),
        className: 'performance_based_incentive',
      },
      {
        name: 'cumulative_savings',
        data: dataByYear.map((v) => (hasNegativeSavings ? v['cumulativeNetSavings'] : v['cumulativeSavings'])),
        className: hasNegativeSavings ? 'net_savings' : 'savings',
        total_lifetime_savings: totalLifetimeSavings,
        currencySymbol: currencySymbol,
      },
    ],
    options: {
      // Documents: https://gionkunz.github.io/chartist-js/api-documentation.html
      low: Math.min(YAxisRange.minYAxis * YAxisAdjustmentFactor, 0),
      high: Math.max(YAxisRange.maxYAxis * YAxisAdjustmentFactor, 0.1), // this value cannot be 0 otherwise it throws tons of error
      stackBars: true,
      seriesBarDistance: 20,
      fullWidth: true,
      dataByYear,
      chartPadding: 0,
      axisX: { showLabel: true, showGrid: false },
      axisY: {
        showLabel: true,
        showGrid: true,
        offset: 65,
        labelInterpolationFnc: function (value) {
          return window.formatCurrencyWithSymbol(parseInt(value, 10), currencySymbol, window.locale, 0)
        },
      },
      showLabel: true,
      plugins: [
        Designer.ctBarLabelsLifeTimeSavings({
          textAnchor: 'middle',
        }),
      ],
    },
  }
}

Designer.ctBarLabelsLifeTimeSavings = function (options) {
  var ctBarLabelsLifeTimeSavings
  return (ctBarLabelsLifeTimeSavings = function (chart) {
    var defaultOptions = {
      labelClass: 'ct-bar-label',
      labelInterpolationFnc: Chartist.noop,
      labelOffset: {
        x: 0,
        y: 0,
      },
      position: {
        x: null,
        y: null,
      },
      textAnchor: 'middle',
    }

    options = Chartist.extend({}, defaultOptions, options)

    // if (chart instanceof Chartist.Bar) {
    chart.on('draw', function (data) {
      if (data.type === 'bar') {
        var label = Designer.labelForData(data)
        data.element.attr({
          year: chart?.options?.dataByYear?.[data.index]?.year,
          cumulativeFeedInTariff: chart?.options?.dataByYear?.[data.index]?.cumulativeFeedInTariff,
          cumulativeIncentives: chart?.options?.dataByYear?.[data.index]?.cumulativeIncentives,
          cumulativeSavings: chart?.options?.dataByYear?.[data.index]?.cumulativeSavings,
          id: data.index + '-lifetime-saving-bar',
        })
        data.group
          .elem(
            'text',
            {
              // This gets the middle point of the bars and then adds the
              // optional offset to them
              x: label.x,
              y: label.y,
              style: label.style,
            },
            options.labelClass
          )
          .text(label.label)
      }
    })
    // }
  })
}

Designer.ctBarLabelsBillSavings = function (options) {
  var ctBarLabelsBillSavings
  return (ctBarLabelsBillSavings = function (chart) {
    var defaultOptions = {
      labelClass: 'ct-bar-label',
      labelInterpolationFnc: Chartist.noop,
      labelOffset: {
        x: 0,
        y: 0,
      },
      position: {
        x: null,
        y: null,
      },
      textAnchor: 'middle',
    }
    options = Chartist.extend({}, defaultOptions, options)
    var positionX =
      options.position.x ||
      function (data) {
        return (data.x1 + data.x2) / 2 + options.labelOffset.x
      }

    var positionY =
      options.position.y ||
      function (data) {
        return (data.y1 + data.y2) / 2 + options.labelOffset.y
      }

    chart.on('draw', function (data) {
      if (data.type === 'bar') {
        if (
          data &&
          data.series &&
          data.index === 1 &&
          (data.series.className === 'NewBill' || data.series.className === 'NewBill NetSpend')
        ) {
          //New Bill Label
          var labelOverFlow = Math.abs(data.y1 - data.y2) < 20
          //ensure label is clearly visible from the chart when value is very small
          var style =
            Math.round(data.value.y) === 0 || labelOverFlow ? 'fill:#000000;text-anchor: middle' : 'text-anchor: middle'
          data.group
            .elem(
              'text',
              {
                x: positionX(data),
                y: positionY(data),
                style: style,
              },
              options.labelClass
            )
            .text(
              Math.round(data.value.y) === 0
                ? data.series.name + ': ' + data.series.currencySymbol + '0'
                : data.series.name
            )
        } else if (
          data &&
          data.series &&
          data.series.className === 'Payment' &&
          data.index === 1 &&
          Math.round(data.value.y) !== 0
        ) {
          var showRegularPayment = Boolean(
            chart.options && chart.options.regularPayment && chart.options.regularPayment > 0
          )
          //Payment Label
          showRegularPayment &&
            data.group.append(
              new Chartist.Svg(
                'circle',
                {
                  cx: data.x2,
                  cy: data.y2,
                  r: 3,
                },
                'ct-marker'
              )
            )
          showRegularPayment &&
            data.group.append(
              new Chartist.Svg('line', {
                x1: data.x2,
                y1: data.y2,
                x2: data.x2,
                y2: 18,
                style: 'stroke:rgba(0,0,0, 0.3);stroke-width:1',
              })
            )
          showRegularPayment &&
            data.group
              .elem(
                'text',
                {
                  x: positionX(data),
                  y: 10,
                  style: 'text-anchor: middle',
                },
                options.labelClass
              )
              .text(data.series.name)
        } else {
          data.group
            .elem(
              'text',
              {
                // This gets the middle point of the bars and then adds the
                // optional offset to them
                x: positionX(data),
                y: positionY(data),
                style: 'text-anchor: middle',
              },
              options.labelClass
            )
            // Add bar label with series name if != 0
            .text(Math.round(data.value.y) !== 0 ? data.series.name : '')
        }
      }
    })
  })
}

Designer.ctBarLabelsSystemPerformance = function (options) {
  var customRound = function (value) {
    if (value > 100) {
      return Math.round(value).toLocaleString(window.locale)
    } else {
      return (Math.round(10 * value) / 10).toLocaleString(window.locale)
    }
  }

  return function ctBarLabelsSystemPerformance(chart) {
    var defaultOptions = {
      labelClass: 'ct-bar-label-small',
      labelInterpolationFnc: Chartist.noop,
      labelOffset: {
        x: 0,
        y: 8,
      },
      position: {
        x: null,
        y: null,
      },
      textAnchor: 'middle',
    }

    options = Chartist.extend({}, defaultOptions, options)

    var positionX =
      options.position.x ||
      function (data) {
        return (data.x1 + data.x2) / 2 + options.labelOffset.x
      }

    var positionY =
      options.position.y ||
      function (data) {
        return data.y2 - options.labelOffset.y
      }

    if (chart instanceof Chartist.Bar) {
      chart.on('draw', function (data) {
        if (data.type === 'bar') {
          data.group
            .elem(
              'text',
              {
                // This gets the middle point of the bars and then adds the
                // optional offset to them
                x: positionX(data),
                y: positionY(data),
                style: 'text-anchor: middle',
              },
              options.labelClass
            )
            // Add bar label with series name if != 0
            .text(Math.round(data.value.y) !== 0 ? customRound(data.value.y) : '')
        }
      })
    }
  }
}

Designer.prepareFilePathForLoad = function (file_path) {
  if (window.EMBEDDED_FILES && window.EMBEDDED_FILES[file_path]) {
    return window.EMBEDDED_FILES[file_path]
  } else if (file_path.indexOf('http') === 0) {
    //Do not modify remote URLs
    return file_path
  } else {
    return window.PUBLIC_URL + file_path
  }
}

Designer.FILE_PATHS = {
  COMPASS_TEXTURE: '/images/compass-face-texture.png',
  MODULE_IMAGE_SRC: '/images/solar_module_small.jpg',
  MODULE_IMAGE_EXPORT_SRC: '/images/module_texture_stringing.jpg',
  RIGHT_ANGLE_IMAGE_SRC: '/images/ic_border_inner_black_24dp_1x.png',
  ROOF_TEXTURES: {
    clay: '/images/roof_texture_clay.jpg',
    asphalt: '/images/roof_texture_asphalt.jpg',
    default: '/images/roof_texture_asphalt.jpg',
  },
  WALL_TEXTURES: {
    brick_white: '/images/wall_texture_brick_white.jpg',
    brick_brown: '/images/wall_texture_brick_brown.jpg',
    default: '/images/wall_texture_brick_white.jpg',
  },
}

/*
Showing shadings is easy when Inverter panel is expanded but it's trickier to hide shadings.
Listening for panel to be contracted is easy but it can also disappear when object is deselected.
*/
Designer._shadingVisibility = false

Designer.shadingVisibility = function (value, opts = {}) {
  const visualizeShadingPoints = opts.visualizeShadingPoints === undefined ? true : opts.visualizeShadingPoints
  const visualizeSunrays = opts.visualizeSunrays === undefined ? true : opts.visualizeSunrays

  if (typeof value === 'undefined') {
    return Designer._shadingVisibility
  }

  if (value && !editor.selectedSystem.raytracedShadingAvailable()) {
    value = false
  }

  if (value !== Designer._shadingVisibility) {
    if (value === true) {
      // In some cases we will need to trigger shading calcs here
      // When a) raw shading calcs not complete and b) shading is NOT being calculated or queued
      if (!editor.selectedSystem?.shadingOverrideRawIsComplete()) {
        Designer.showNotification('Preparing shading visualisation...')

        // Use calculateShadingBlocked to force calcs, so they do not get deferred
        // Designer.requestSystemCalculations(editor.selectedSystem)

        // We pass extra parameter to ensure that raw (shadingOverrideRaw) data is populated
        // Normally calcs only require shadingOverride to be populated because the raw data is not
        // required for output calcs. But we want the raw data so we can see it.
        var requireRaytracedShading = editor.selectedSystem.raytracedShadingAvailable()
        var requireRawShadingData = true
        ShadeHelper.calculateShadingBlocked(editor.selectedSystem, requireRaytracedShading, requireRawShadingData)
      }
    }

    // We only hide shadows from the terrain because it is very heavy
    // We keep other shadows active always because they will not severly impact performance.
    var t = editor.getTerrain()
    if (t) {
      t.receiveShadow = value
      t.castShadow = value
    }

    Designer._shadingVisibility = value
    visualizeSunrays && SceneHelper.sunraysToModules(value, { render: false })
    visualizeShadingPoints && SceneHelper.shadingPoints(value)
    window.editor.signals.objectAnnotationChanged.dispatch(editor.selected)
  }
}

Designer.listenForDisableShadingVisibility = function (object) {
  // If we have selected an object which will result in the Shading Expansion Panel disappearing
  // force shading visibility to false
  // This is idempotent so no danger if already false
  // We don't need to enable shading visibility here because it happens when Shading expansion
  // panel is expanded
  if (!object || (object.type !== 'OsModule' && object.type !== 'OsModuleGrid')) {
    Designer.shadingVisibility(false)
  }
}
/*
Showing strings is easy when Inverter panel is expanded but it's trickier to hide strings.
Listening for panel to be contracted is easy but it can also disappear when object is deselected.
*/
Designer._stringVisibility = false
Designer._unstrungModuleDotVisibility = false

Designer.setUnstrungModuleDotVisibility = function (value) {
  Designer._unstrungModuleDotVisibility = value
  editor.selectedSystem?.refreshUnstrungModulesMarker()
}

Designer.setStringVisibility = function (value) {
  if (typeof value === 'undefined') {
    return Designer._stringVisibility
  }

  if (value !== Designer._stringVisibility) {
    if (value === true) {
      Designer.setUnstrungModuleDotVisibility(true)
      OsString.visible(true)
      Designer.scheduleRefreshStrings()
      Designer._stringVisibility = true
    } else {
      Designer.setUnstrungModuleDotVisibility(false)
      OsString.visible(false)
      Designer._stringVisibility = false
    }
  }
}

Designer.setDefaultPermissions = function () {
  const permissionKeyStrings = [
    'systems',
    'systems.panels',
    'systems.buildablePanels',
    'systems.inverters',
    'systems.batteries',
    'views',
    'views.viewBox',
    'views.imagery',
  ]
  const permissionsPopulated = Designer.permissions.getAllPermissionKeys().length > 1 // if 1, only the root exists

  if (permissionsPopulated) {
    permissionKeyStrings.forEach((keyString) => {
      Designer.permissions.updatePermissionsByKeyString(keyString, true, true, true, true)
    })
  } else {
    permissionKeyStrings.forEach((keyString) => {
      Designer.permissions.addPermissionsByKeyString(keyString, true, true, true, true)
    })
  }
}

Designer.listenForChangeStringVisibility = function (object) {
  // alway set string visibility to true
  // if current selected object is OsGroup and OsString included in group
  if (object && object.type === 'OsGroup' && object.objects.some((o) => o.type === 'OsString')) {
    Designer.setStringVisibility(true)
    return
  }
  // If we have selected an object which does not display the system sidebar then
  // the system sidebar will disappear. force string visibility to false
  // This is idempotent so no danger if already false
  // We don't need to enable string visibility here because it happens when Inverters expansion
  // panel is expanded
  var objectTypesWhichShowSystemSidebar = ['OsSystem', 'OsInverter', 'OsMppt', 'OsString', 'OsBattery', 'OsOther']

  if (!object || objectTypesWhichShowSystemSidebar.indexOf(object.type) === -1) {
    Designer.setStringVisibility(false)
  }
}

Designer.listenForRefreshFacetHelpersVisibility = function (object) {
  //@TODO: Optimize

  // We must call the selected facet last (if any) beacuse otherwise the visible children
  // may be set invisible by other non-selected facets
  // This avoids needing to do extra checks for each node for all the facets it belongs to which may be selected
  var facetSelected = null
  editor.filter('type', 'OsFacet').forEach((f) => {
    if (editor.selected !== f) {
      f.refreshVisibility()
    } else {
      facetSelected = f
    }
  })

  if (facetSelected) {
    facetSelected.refreshVisibility()
  }
}

Designer.listenForObjectSelectedToEnableHandleController = function () {
  const hasEditPermission = editor.selected?.getPermissionCheck
    ? editor.selected.getPermissionCheck()
    : Designer.permissions.canEdit()

  if (!hasEditPermission) {
    // previously selected object handler may still be active
    editor.controllers?.Handle?.deactivate()
    return
  }

  if (editor.controllers?.Handle) {
    editor.controllers.Handle.handleObjectSelected(editor.selected)
  }
}

Designer.getRendererType = function (defaultType) {
  if (!defaultType) {
    if (Designer.rendererName) {
      // If already set, then use existing
      defaultType = Designer.rendererName
    } else {
      defaultType = 'WebGLRenderer'
    }
  }

  if (defaultType === 'WebGLRenderer') {
    if (System.support.webgl === true) {
      return defaultType
    } else {
      return 'SoftwareRenderer'
    }
  } else {
    return defaultType
  }
}

Designer.createRenderer = function (type, antialias, shadows, gammaIn, gammaOut, preserveDrawingBuffer) {
  var rendererTypes = {
    WebGLRenderer: THREE.WebGLRenderer,
    CanvasRenderer: THREE.CanvasRenderer,
    SVGRenderer: THREE.SVGRenderer,
    SoftwareRenderer: THREE.SoftwareRenderer,
    RaytracingRenderer: THREE.RaytracingRenderer,
  }

  if (type === 'WebGLRenderer' && System.support.webgl === false) {
    console.error('Error: WebGLRenderer requested but not supported.')
  }

  if (antialias && Designer.OS_SAFARI_BUG_237906) {
    console.warn('Safari bug affecting iOS 15.4.1 workaround is to force antialias=false')
    /*
     * https://bugs.webkit.org/show_bug.cgi?id=237906
     * https://github.com/openlayers/openlayers/pull/13492
     */
    antialias = false
  }

  var renderer = new rendererTypes[type]({
    antialias: antialias,
    alpha: true,
    preserveDrawingBuffer: preserveDrawingBuffer,
  })
  renderer.gammaInput = gammaIn
  renderer.gammaOutput = gammaOut
  if (shadows && renderer.shadowMap) {
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
  }

  window.editor.signals.rendererChanged.dispatch(renderer)
}

Designer.showNotification = function (text, type, options) {
  window.studioDebug && console.error('Designer.showNotification not injected: ' + text)
}

Designer.hideNotification = function (text, type, options) {
  window.studioDebug && console.error('Designer.hideNotification not injected: ' + text)
}

Designer.browseNearmapPhotos = function (location4326, onSuccess, onError) {
  window.$.ajax({
    type: 'GET',
    url:
      window.API_BASE_URL +
      'orgs/' +
      window.getStorage().getItem('org_id') +
      '/nearmap/source/photos/' +
      location4326[0] +
      ',' +
      location4326[1],
    data: {
      nearmap_token: window.getStorage().getItem('nearmap_token'),
      api_key_nearmap: window.AccountHelper.getApiKey('nearmap'),
      nearmap_session_id: window.nearmapSessionId,
    },
    dataType: 'json',
    contentType: 'application/json',
    headers: window.Utils.tokenAuthHeaders({
      'X-CSRFToken': window.getCookie('csrftoken'),
    }), //cors for django
    success: function (data) {
      if (onSuccess) {
        onSuccess(data)
      }
    },
    error: function (data) {
      var message
      if (data.status === 401) {
        window.editor.signals.requestNearmapLogin.dispatch()
        // Designer.setUiState('LoginWithNearmapDialog', {
        //   isOpen: true,
        // })
      } else {
        if (data && data.responseJSON && data.responseJSON.detail) {
          message = data.responseJSON.detail
        } else {
          message = 'Imagery not found or not available.'
        }
      }
      if (onError) {
        onError(message)
      }
    },
  })
}

Designer.classifyDirection = function (inclination, bearing, forceCardinal) {
  if (inclination < 15) {
    return 'V'
  }

  var sectorSize = 22.5
  var sectorIndex = Math.round(((bearing - 11.25) % 360) / sectorSize)

  //North = sectorIndex 15 and 0
  //NorthEasth = sectorIndex 1 and 2
  var direction = ['N', 'NE', 'NE', 'E', 'E', 'SE', 'SE', 'S', 'S', 'SW', 'SW', 'W', 'W', 'NW', 'NW', 'N'][sectorIndex]

  if (forceCardinal) {
    if (direction.length === 1) {
      return direction
    } else {
      var mapping = {
        NE: 'N',
        SE: 'E',
        SW: 'S',
        NW: 'W',
      }
      return mapping[direction]
    }
  } else {
    return direction
  }
}

Designer.refreshNearmapSessionId = function (onSuccess, onError) {
  if (window.getStorage().getItem('nearmap_token')) {
    window.nearmapSessionId = 'fake_nearmap_session_id'
    window.nearmapSessionIdLoading = false
    window.editor.signals.mapChanged.dispatch()
    return
  }

  var success = function (data) {
    window.nearmapSessionId = data.nearmap_session_id
    window.nearmapSessionIdLoading = false
    window.editor.signals.mapChanged.dispatch()

    if (onSuccess) {
      onSuccess()
    }
  }

  var error = function (data) {
    var detail =
      data.responseJSON && data.responseJSON.detail && data.responseJSON.detail.length > 0
        ? data.responseJSON.detail
        : 'Unknown error'

    if (!AccountHelper.getApiKey('nearmap')) {
      // Only show notification for NMOS. API Key users should not be affected by a failed session.
      window.Designer.showNotification('Could not create Nearmap session: ' + detail, 'danger')
    }

    window.nearmapSessionId = null
    window.nearmapSessionIdLoading = false
    console.log('error', data)

    if (onError) {
      onError()
    }
  }

  window.nearmapSessionIdLoading = true

  if (window.getStorage().getItem('nearmap_token')) {
    //Not yet working... perhaps tokens don't need sessions?
    window.$.ajax({
      type: 'POST',
      url:
        window.API_BASE_URL +
        'orgs/' +
        window.getStorage().getItem('org_id') +
        '/nearmap/session/?nearmap_session_id=' +
        window.nearmapSessionId,
      dataType: 'json',
      contentType: 'application/json',
      headers: window.Utils.tokenAuthHeaders({
        Authentication: 'Bearer ' + window.getStorage().getItem('nearmap_token'),
        'X-CSRFToken': window.getCookie('csrftoken'),
      }), //cors for django
      success: success,
      error: error,
    })
  } else {
    window.$.ajax({
      type: 'POST',
      url:
        window.API_BASE_URL +
        'orgs/' +
        window.getStorage().getItem('org_id') +
        '/nearmap/session/?api_key_nearmap=' +
        window.AccountHelper.getApiKey('nearmap') +
        '&nearmap_session_id=' +
        window.nearmapSessionId,
      dataType: 'json',
      contentType: 'application/json',
      headers: window.Utils.tokenAuthHeaders({
        'X-CSRFToken': window.getCookie('csrftoken'),
      }), //cors for django
      success: success,
      error: error,
    })
  }
}

Designer.resolveSelectionDelegate = function (object, allowCreateSelectionDelegateGroup) {
  if (typeof object.selectionDelegate === 'function') {
    return object.selectionDelegate(editor, allowCreateSelectionDelegateGroup)
  } else if (object.selectionDelegate) {
    return Designer.resolveSelectionDelegate(object.selectionDelegate, allowCreateSelectionDelegateGroup)
  } else {
    return object
  }
}

//////////////////////
// Copied from PanelModule.js (ES6)
// We can only move this into a single place for both Studio and React/ES6 when we unify these codebases.
//////////////////////

var stripNulls = (values) => values.filter((value) => value !== null)

// Duplicated in misc.ts
var sumArray = (values) => {
  return values.reduce((a, b) => a + b, 0)
}

// Duplicated in misc.ts
var meanArray = (values) => sumArray(values) / values.length

Designer.getAverage = (values) => {
  const valuesValid = stripNulls(values)
  return meanArray(valuesValid)
}

Designer.lerp3 = (a, b, u) => {
  return [(1 - u) * a[0] + u * b[0], (1 - u) * a[1] + u * b[1], (1 - u) * a[2] + u * b[2]]
}

var white = [255, 255, 255]
var red = [208, 2, 27]
var orange = [245, 166, 35]
var green = [126, 211, 33]

Designer.colorForPanelsRGB = (value) => {
  var mid = 0.8

  if (value === null || isNaN(value)) {
    return white.map((v) => v / 255)
  } else if (value > mid) {
    //above mid, tween between orange and green
    return Designer.lerp3(orange, green, (value - mid) / (1 - mid)).map((v) => v / 255)
  } else {
    //below mid, tween between red and orange
    return Designer.lerp3(red, orange, value / mid).map((v) => v / 255)
  }
}

Designer.colorForHeatmap = (value) => {
  const violet = [44, 0, 35] // low
  const orange = [205, 68, 50] // mid
  const yellow = [255, 240, 0] // high

  var mid = 0.5

  if (value === null || isNaN(value)) {
    return white.map((v) => v / 255)
  } else if (value > mid) {
    //above mid
    return Designer.lerp3(orange, yellow, (value - mid) / (1 - mid)).map((v) => v / 255)
  } else {
    //below mid
    return Designer.lerp3(violet, orange, value / mid).map((v) => v / 255)
  }
}

Designer.countUniqueImageryPerspectives = () => {
  // Raw imagery types are either: null, 'top', 'oblique', 'unknown', '3D',
  // We map raw imagery types into perspectives:
  // - 'top-perspective' (which includes 'top' and '3D' or 'none' when ground imagery set). 3D is actually a top-down image mapped to a mesh
  // - 'oblique-perspective' (which includes 'oblique' imagery)
  // Multiple 'top' imageries only count for a single 'top-perspective'
  // Oblique views are all counted individually.

  var imageryTypes = []
  ViewHelper.views.forEach((view) => {
    if (window.MAP_TYPES[view.mapData.mapType]) {
      var imageryType = window.MAP_TYPES[view.mapData.mapType].imageryType(view.mapData)
      imageryTypes.push(imageryType)
    } else {
      console.warn('Designer.hasMultipleImageryViews failed, assume none', view)
      return 'none'
    }
  })

  var perspectives = {
    top: 0,
    oblique: 0,
  }

  if (imageryTypes.includes('top', '3D') || (imageryTypes.includes('none') && editor.scene.hasGroundImagery())) {
    perspectives.top = 1
  }

  // Note some obliques return imagery type "oblique_heading_{heading}" instead of exactly 'oblique'
  perspectives.oblique = imageryTypes.filter((imageryType) => imageryType.includes('oblique')).length

  var numberOfUniquePerspectives = perspectives.top + perspectives.oblique

  return numberOfUniquePerspectives
}

Designer.hasMultipleImageryViews = () => {
  return Designer.countUniqueImageryPerspectives() > 1
}

Designer.allowExportForSelectedSystem = () => {
  if (Designer.selectedSystemHasFacetUpdatedWithMultipleImageryViews()) {
    return false
  }

  // Extra check: Since we were not tracking for older designs, add extra check to prevent exporting older projects
  var earliestPermittedDate = '2023-07-16T21:57:20.950Z'

  try {
    // e.g. Format for created_date is "2022-06-08T04:54:01.575789Z" which is the same as new Date().toISOString()
    var createdDate = projectForm.getState().values.created_date

    if (createdDate && createdDate < earliestPermittedDate) {
      return false
    }
  } catch (e) {
    console.warn(e)
  }

  return true
}

Designer.selectedSystemHasFacetUpdatedWithMultipleImageryViews = () => {
  return editor.filter('type', 'OsFacet').some((osFacet) => osFacet.maxImageryPerspectivesWhenUpdated > 1)
}

Designer.exportToDxfEnabled = () => {
  return editor.extensions.DxfExporter.isAvailable()
}

Designer.isEagleViewReportLoaded = () => {
  return (
    !!window?.editor?.scene?.preGeneratedRaytraceResults || window?.editor?.scene?.preGeneratedRaytraceResults === null
  )
}

Designer.exportToDxf = (editor) => {
  if (!editor) editor = window.editor
  editor.extensions.DxfExporter.generateAndDownload()
}

window.convertLocaleName = (locale) => {
  if (!locale || !locale.includes) {
    return locale
  } else if (!locale.includes('_')) {
    return locale
  } else {
    const parts = locale.split('_')
    return `${parts[0]}-${parts[1]}`
  }
}

window.formatCurrencyNumber = (value, places, locale) => {
  if (!places && places !== 0) {
    places = 2
  }
  locale = convertLocaleName(locale)

  return new Intl.NumberFormat(locale, { minimumFractionDigits: places, maximumFractionDigits: places }).format(
    value ? value : 0
  )
}

window.formatCurrencyWithSymbol = (value, currencySymbol, locale, places) => {
  if (currencySymbol === '€') {
    if (['en', 'en-us'].includes(locale)) {
      // Beware language us lowercase like en-us not en-US
      return '€' + window.formatCurrencyNumber(value, places, locale)
    } else {
      return window.formatCurrencyNumber(value, places, locale) + ' €'
    }
  } else {
    return currencySymbol + window.formatCurrencyNumber(value, places, locale)
  }
}

/**
 * https://bugs.webkit.org/show_bug.cgi?id=237906
 * https://github.com/openlayers/openlayers/pull/13492
 * @type {boolean}
 */
Designer.UA =
  typeof navigator !== 'undefined' && typeof navigator.userAgent !== 'undefined'
    ? navigator.userAgent.toLowerCase()
    : ''

// Designer.OS_SAFARI_BUG_237906 =
//   Utils.iOS() &&
//   Designer.UA.indexOf('safari') !== -1 &&
//   Designer.UA.indexOf('chrom') == -1 &&
//   !!(Designer.UA.indexOf('version/15.4') >= 0 || Designer.UA.match(/cpu (os|iphone os) 15_4 like mac os x/))
Designer.OS_SAFARI_BUG_237906 = Utils.iOS()

Designer.isNestedWindow = () => {
  /*
  Copy of util.isNestedWindow but available on the window object
  */
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
