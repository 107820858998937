/*
eg. data =
{
    "code": "SPR-390E-WHT-D",
    "voc": 85.3,
    "temp_coefficient": 0.0307,
    "kw_stc": 390,
    "manufacturer_name": "SunPower",
    "size": [1.046, 2.067]
}
*/

function ModuleType(data, secret = false) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('ModuleType: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)

  if (secret) {
    // hide any data in the spec sheet that will reveal the model and make of the module
    this.code = 'Unknown'
    this.manufacturer_name = 'Unknown'
    this.logo = ''
  }

  if (data.code.toLowerCase().indexOf('solaria40') !== -1) {
    this.substring_layout = 'solaria_4_quadrants_5_parallel'
  } else if (data.code.toLowerCase().indexOf('solaria') !== -1 || data.code.toLowerCase().indexOf('powerxt') !== -1) {
    this.substring_layout = 'solaria_4_quadrants_2_parallel'
  } else if (data?.manufacturer_name && data.manufacturer_name.toLowerCase().includes('optivolt')) {
    this.substring_layout = 'optivolt_with_averaging'
  } else {
    // this.substring_layout = 'standard_3_portrait_60_samples'
    this.substring_layout = 'standard_3_portrait_9_samples'
  }
}

ModuleType.prototype = Object.assign({
  properties: [
    'id',
    'module_id',
    'code',
    'technology',
    'max_power_voltage',
    'voc',
    'imp',
    'isc',
    'noct',
    'temp_coefficient_isc',
    'temp_coefficient_vpmax',
    'temp_coefficient_voc',
    'cells_in_series',
    'kw_stc',
    'manufacturer',
    'manufacturer_name',
    'size',
    'cost',
    'is_default',
    'quantity',
    'annual_degradation_override',
    'first_year_degradation',
    'bifaciality',
    'transmission',
    'external_link',
    'external_label',
    'module_texture',
    'logo',
    'exhibitor_org_id',
    'is_archived',
    'substring_layout',
    'cost',
    'labor_adjustment',
    'price_adjustment',
    'product_warranty',
    'price_adjustment_per_watt',
    'sandia_specs',
    'ordering',
    'skus',
    'width',
    'height',
    'thickness',
    'weight',
    'org_id',
    'share_with_orgs',
    'external_data',
    'short_description',
  ],
  voltage: function (temperature) {
    return this.voc * (1.0 + (this.temp_coefficient_voc / 100) * (temperature - 25))
  },
  powerMax: function () {
    // In kW
    if (this.max_power_voltage && this.imp) {
      return this.max_power_voltage * this.imp * 0.001
    } else {
      // fallback to STC specs if other specs not available
      return this.kw_stc
    }
  },
  calculateArea: function () {
    return this.size[0] * this.size[1]
  },
  getShadingCells: function () {
    return [
      this.substringsBySubstringLayout[this.substring_layout].cols,
      this.substringsBySubstringLayout[this.substring_layout].rows,
    ]
  },
  getSubstringLayout: function () {
    return this.substringsBySubstringLayout[this.substring_layout]
  },
  getAllCellsInSubstring: function (cellIndex) {
    var substringIndices = this.getSubstringLayout().sample_to_substring
    for (var i = 0; i < substringIndices.length; i++) {
      if (substringIndices[i].indexOf(cellIndex) !== -1) {
        return substringIndices[i]
      }
    }
    return []
  },
  substringsBySubstringLayout: {
    standard_3_portrait_60_samples: {
      cols: 6,
      rows: 10,
      substring_averaging: false,
      sample_to_substring: [
        [].concat
          .apply(
            [],
            _.range(10).map((i) => [i * 6 + 0])
          )
          .concat(
            [].concat.apply(
              [],
              _.range(9, -1, -1).map((i) => [i * 6 + 1])
            )
          ),
        [].concat
          .apply(
            [],
            _.range(10).map((i) => [i * 6 + 2])
          )
          .concat(
            [].concat.apply(
              [],
              _.range(9, -1, -1).map((i) => [i * 6 + 3])
            )
          ),
        [].concat
          .apply(
            [],
            _.range(10).map((i) => [i * 6 + 4])
          )
          .concat(
            [].concat.apply(
              [],
              _.range(9, -1, -1).map((i) => [i * 6 + 5])
            )
          ),
      ],
    },
    standard_3_portrait_9_samples: {
      cols: 3,
      rows: 3,
      substring_averaging: false,
      sample_to_substring: [
        [0, 3, 6], //left column
        [1, 4, 7], //middle column
        [2, 5, 8], //right column
      ],
    },
    solaria_4_quadrants_5_parallel: {
      cols: 4,
      rows: 10,
      substring_averaging: false,
      sample_to_substring: [
        [0, 1],
        [2, 3],
        [4, 5],
        [6, 7],
        [8, 9],
        [10, 11],
        [12, 13],
        [14, 15],
        [16, 17],
        [18, 19],
        [20, 21],
        [22, 23],
        [24, 25],
        [26, 27],
        [28, 29],
        [30, 31],
        [32, 33],
        [34, 35],
        [36, 37],
        [38, 39],
      ],
    },
    solaria_4_quadrants_2_parallel: {
      cols: 4,
      rows: 4,
      substring_averaging: false,
      sample_to_substring: [
        [0, 1],
        [2, 3],
        [4, 5],
        [6, 7],
        [8, 9],
        [10, 11],
        [12, 13],
        [14, 15],
      ],
    },
    optivolt_with_averaging: {
      cols: 3,
      rows: 3,
      substring_averaging: true,
      sample_to_substring: [
        [0, 3, 6, 1, 4, 7],
        [1, 4, 7, 2, 5, 8],
      ],
    },
  },
})

ModuleType.fromArray = function (items) {
  return items.map(function (item) {
    return new ModuleType(item)
  })
}

var validate_boolean = function (value) {
  return value === true || value === false
}

var validate_string_not_empty = function (value) {
  return value && value.length > 0
}
var validate_number_greater_than_zero = function (value) {
  return value > 0
}
var validate_number_less_than_zero = function (value) {
  return value < 0
}
var array_length_2_greater_than_zero = function (value) {
  return value && value.length === 2 && value[0] > 0 && value[1] > 0
}

var validateWithRules = function (data, validationRules) {
  var validationErrors = []
  for (var key in validationRules) {
    var func = validationRules[key]
    if (!data.hasOwnProperty(key) || typeof data[key] === 'undefined' || !func(data[key])) {
      validationErrors.push(key)
    }
  }
  return validationErrors
}

ModuleType.validateSamSpecs = function (moduleData) {
  // Quick check to ensure fields are not empty. No type/range checking.
  var validationRules = {
    code: validate_string_not_empty,
    technology: validate_string_not_empty,
    max_power_voltage: validate_number_greater_than_zero,
    voc: validate_number_greater_than_zero,
    imp: validate_number_greater_than_zero,
    isc: validate_number_greater_than_zero,
    noct: validate_number_greater_than_zero,
    temp_coefficient_isc: validate_number_greater_than_zero,
    temp_coefficient_vpmax: validate_number_less_than_zero,
    temp_coefficient_voc: validate_number_less_than_zero,
    cells_in_series: validate_number_greater_than_zero,
    kw_stc: validate_number_greater_than_zero,
    size: array_length_2_greater_than_zero,
    annual_degradation_override: validate_number_greater_than_zero,
    // 'bifaciality',
    // 'transmission',
  }
  return validateWithRules(moduleData, validationRules)
}

function PricingScheme(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('PricingScheme: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

PricingScheme.DefaultDataForTests = {
  id: 1,
  url: '/1/',
  title: 'Test',
  pricing_formula: '',
  auto_apply_enabled: '',
  auto_apply_only_specified_states: '',
  auto_apply_only_specified_zips: '',
  auto_apply_component_codes: '',
  auto_apply_min_system_size: '',
  auto_apply_max_system_size: '',
  auto_apply_sector: '',
  priority: '',

  // Disabled beacuse we should not need to surface pricing details in studio because this data is not available
  // to all users, and is only required by the back-end for calculations.
  // configuration_json: '{}',
}

PricingScheme.prototype = Object.assign({
  properties: [
    'id',
    'title',
    'pricing_formula',
    'is_archived',
    'configuration_json',
    'auto_apply_enabled',
    'auto_apply_only_specified_states',
    'auto_apply_only_specified_zips',
    'auto_apply_component_codes',
    'auto_apply_min_system_size',
    'auto_apply_max_system_size',
    'auto_apply_sector',
    'priority',
    'url',
    'org_id',
    'share_with_orgs',
  ],
  pricingSettings: function () {
    var configuration = JSON.parse(this.configuration_json)
    return configuration
  },
  autoApply: function () {
    return this.auto_apply_enabled
  },
  matchesState: function (state) {
    var specified_states = this.auto_apply_only_specified_states

    //empty value counts as a match
    if (!specified_states || specified_states.length === 0) {
      return true
    } else if (
      specified_states === state ||
      specified_states.indexOf(state + ',') !== -1 ||
      specified_states.endsWith(',' + state)
    ) {
      return true
    } else {
      return false
    }
  },
  matchesZip: function (zip) {
    var specified_zips = this.auto_apply_only_specified_zips

    //empty value counts as a match
    if (!specified_zips || specified_zips.length === 0) {
      return true
    } else if (
      specified_zips === zip ||
      specified_zips.indexOf(zip + ',') !== -1 ||
      specified_zips.endsWith(',' + zip)
    ) {
      return true
    } else {
      return false
    }
  },
})

PricingScheme.fromArray = function (items) {
  return items
    .map(function (item) {
      return new PricingScheme(item)
    })
    .sort(function (a, b) {
      return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
    })
}

PricingScheme.autoDetect = function (items, state, zip) {
  var bestMatch = null

  items.forEach(function (item) {
    if (item.autoApply()) {
      if (item.matchesState(state)) {
        if (item.matchesZip(zip)) {
          //this matches, but is it the highest priority?
          if (!bestMatch || item.priority > bestMatch.priority) {
            bestMatch = item
          }
        }
      }
    }
  })

  return bestMatch
}

function Costing(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('Costing: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

Costing.DefaultDataForTests = {
  id: 1,
  url: '/1/',
  title: 'Test',
  is_archived: false,

  // Note: different field name to other models
  priority: '', // non-standard fieldname for controlling whether auto-apply should be enabled
  auto_apply_priority: '',

  auto_apply_only_specified_states: '',
  auto_apply_only_specified_zips: '',
  auto_apply_component_codes: '',
  auto_apply_min_system_size: '',
  auto_apply_max_system_size: '',
  auto_apply_sector: '',

  // Disabled beacuse we should not need to surface pricing details in studio because this data is not available
  // to all users, and is only required by the back-end for calculations.
  // configuration_json: '{}',
}

Costing.prototype = Object.assign({
  properties: [
    'id',
    'title',
    'is_archived',
    'priority',
    'auto_apply_priority',
    'auto_apply_only_specified_states',
    'auto_apply_only_specified_zips',
    'auto_apply_component_codes',
    'auto_apply_min_system_size',
    'auto_apply_max_system_size',
    'auto_apply_sector',
    'url',
    'org',
    'org_id',
    'share_with_orgs',
  ],
})

Costing.fromArray = function (items) {
  return items
    .map(function (item) {
      return new Costing(item)
    })
    .sort(function (a, b) {
      return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
    })
}

function Adder(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('Adder: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

Adder.addToSystem = function (system, adder) {
  const lineItems = system.line_items ? [...system.line_items] : []
  lineItems.push({
    adder_id: adder.id,
    type: adder.type,
    label: adder.label || '',
    value: parseFloat(adder.value),
    cost_value: parseFloat(adder.cost_value),
    formula: Adder.formulaFromDatabase[adder.formula],
    tax_included: parseFloat(adder.tax_included),
    show_customer: Boolean(adder.show_customer),
    value_includes_tax: adder.value_includes_tax,
    quantity: adder?.quantity || 1,
    unit_label: adder.unit_label || '',
    dealer_fee_interaction_type: adder.dealer_fee_interaction_type,
    price_treatment: adder.price_treatment || 0,
    show_customer_cost: Boolean(adder.show_customer_cost),
    display_as_line_item: Boolean(adder.display_as_line_item),
    only_admin_can_remove: adder.only_admin_can_remove,
  })
  window.editor.execute(new window.SetValueCommand(system, 'line_items', lineItems, undefined, true))
}

Adder.formulaFromDatabase = {
  0: 'fixed',
  1: 'perWatt',
  2: 'perPanel',
  3: 'priceInclTax',
  4: 'priceExclTax',
  5: 'pricePayableInclTax',
  6: 'pricePayableExclTax',
}

Adder.DefaultDataForTests = {
  id: 1,
  url: '/1/',
  label: 'Test',
  is_archived: false,

  auto_apply_enabled: '',
  auto_apply_only_specified_states: '',
  auto_apply_only_specified_zips: '',
  auto_apply_component_codes: '',
  auto_apply_min_system_size: '',
  auto_apply_max_system_size: '',
  auto_apply_sector: '',

  type: '',
  value: 0,
  formula: 'fixed',
  tax_included: 10.0,
  show_customer: true,
  cost_value: 0,
  value_includes_tax: true,
  unit_label: '',
  dealer_fee_interaction_type: 2,
  price_treatment: 0,
  show_customer_cost: false,
  display_as_line_item: false,
  only_admin_can_remove: false,
}

Adder.prototype = Object.assign({
  properties: [
    'id',
    'url',
    'label',
    'is_archived',

    'auto_apply_enabled',
    'auto_apply_only_specified_states',
    'auto_apply_only_specified_zips',
    'auto_apply_only_specified_utilities',
    'auto_apply_component_codes',
    'auto_apply_min_system_size',
    'auto_apply_max_system_size',
    'auto_apply_sector',
    'auto_apply_roof_type',
    'auto_apply_min_slope',
    'auto_apply_max_slope',

    'label',
    'type',
    'value',
    'formula',
    'tax_included',
    'show_customer',
    'cost_value',
    'value_includes_tax',
    'quantity',
    'unit_label',
    'dealer_fee_interaction_type',
    'price_treatment',
    'show_customer_cost',
    'org',
    'org_id',
    'share_with_orgs',
    'display_as_line_item',
    'only_admin_can_remove',
  ],
  matchesLineItem: function (lineItem) {
    var fieldsToMatch = [
      'label',
      'type',
      'value',
      'formula', //special
      'tax_included',
      'show_customer',
      'cost_value',
      'value_includes_tax',
      'unit_label',
      'dealer_fee_interaction_type',
      'show_customer_cost',
      'display_as_line_item',
    ]

    var mismatches = fieldsToMatch.filter((field) => {
      if (field === 'formula') {
        // requires transformation
        return Adder.formulaFromDatabase[this[field]] !== lineItem[field]
      } else if (!this[field] && !lineItem[field]) {
        // if both values are null-ish treat them as matches (e.g. '' and null should match)
        return false
      } else {
        // all other fields
        // not using === to avoid complications with data types when using react state instead of line_item
        return this[field] !== lineItem[field]
      }
    }, this)

    return mismatches.length === 0
  },
})

Adder.fromArray = function (items) {
  return items
    .map(function (item) {
      // Map some differences from database to front-end
      item.label = item.title

      return new Adder(item)
    })
    .sort(function (a, b) {
      return a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1
    })
}

function Incentive(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('Incentive: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

Incentive.DefaultDataForTests = {
  id: 1,
  url: '/1/',
  title: 'Test',
  // auto_apply_enabled: '',
  // auto_apply_only_specified_states: '',
  // auto_apply_only_specified_zips: '',
}

Incentive.prototype = Object.assign({
  properties: [
    'title',
    'is_archived',
    // 'auto_apply_enabled',
    // 'auto_apply_only_specified_states',
    // 'auto_apply_only_specified_zips',
    'url',
    'id',
    'org',
    'org_id',
    'share_with_orgs',
  ],
})

Incentive.fromArray = function (items) {
  return items.map(function (item) {
    return new Incentive(item)
  })
}

function Commission(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('Commission: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

Commission.DefaultDataForTests = {
  id: 1,
  title: 'Test',
  roles: [],
}

Commission.prototype = Object.assign({
  properties: ['title', 'roles', 'id', 'is_archived', 'org_id'],
})

Commission.fromArray = function (items) {
  return items.map(function (item) {
    return new Commission(item)
  })
}

function PaymentOption(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('PaymentOption: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

PaymentOption.DefaultDataForTests = {
  id: 1,
  url: '/1/',
  title: 'Test',
  auto_apply_enabled: false,
  auto_apply_only_specified_states: '',
  auto_apply_only_specified_zips: '',
  auto_apply_component_codes: '',
  auto_apply_min_system_size: '',
  auto_apply_max_system_size: '',
  auto_apply_sector: '',
}

PaymentOption.prototype = Object.assign({
  properties: [
    'id',
    'title',
    'is_archived',
    'auto_apply_enabled',
    'auto_apply_only_specified_states',
    'auto_apply_only_specified_zips',
    'auto_apply_component_codes',
    'auto_apply_min_system_size',
    'auto_apply_max_system_size',
    'auto_apply_sector',
    'url',
    'priority',
    'org',
    'org_id',
    // This is a very hacky approach we can use to detect the ProductType for Sunlight products
    'integration_external_reference',
    'configuration_json',
    'share_with_orgs',
  ],
})

PaymentOption.fromArray = function (items) {
  return items.map(function (item) {
    return new PaymentOption(item)
  })
}

function AutoApplyHelper(system, projectData) {
  this.system = system
  this.projectData = projectData
}

// enable auto-sync of values match automated values
// Only enable auto-apply if we find any auto_apply_enabled options for each section
// We never sync payment options, we will only ever show a warning message + manual apply button if different
AutoApplyHelper.detectAndApplyAutoSync = function () {
  editor.getSystems().forEach((system) => {
    // Special treatment of legacy costing option "Auto Apply" (value=null)
    // Ensure this is done BEFORE delta is calculated
    if (system.costing_override === null) {
      var costing_override_id =
        (WorkspaceHelper.project?.costing_override
          ? parseInt(Utils.urlToId(WorkspaceHelper.project?.costing_override))
          : null) ||
        WorkspaceHelper.project?.costing?.id ||
        null
      if (costing_override_id !== null) {
        window.editor.execute(
          new window.SetValueCommand(system, 'costing_override', costing_override_id, undefined, false)
        )
      }
    }

    var autoApplyHelper = new AutoApplyHelper(system, WorkspaceHelper.project)
    var delta = autoApplyHelper.delta()

    if (
      AccountHelper.getAddersAvailable().filter((adder) => adder.auto_apply_enabled).length > 0 &&
      delta?.adders?.add?.length === 0 &&
      delta?.adders?.remove?.length === 0
    ) {
      // adders are matching, enable auto-sync until it is changed
      system.autoSync.adders = true
    }

    if (
      AccountHelper.getPricingSchemesAvailable().filter((pricingScheme) => pricingScheme.auto_apply_enabled).length >
        0 &&
      !delta.pricingScheme
    ) {
      // pricing scheme is matching, enable auto-sync until it is changed
      system.autoSync.pricingScheme = true
    }

    if (AccountHelper.getCostingsAvailable().filter((costing) => !!costing.priority).length > 0 && !delta.costing) {
      // costing is matching, enable auto-sync until it is changed
      system.autoSync.costing = true
    }
  })
}

AutoApplyHelper.prototype = Object.assign({
  getHighestPriorityOrg: function (items) {
    let highestPriorityOrgId = this.getHighestPriority(items)?.org_id
    if (!highestPriorityOrgId) {
      return items
    }
    return items.filter((item) => highestPriorityOrgId === item.org_id)
  },
  getExternalOrgFirst: function (items) {
    let returnItems = null
    const orgId = AccountHelper.userOrgId
    returnItems = items.filter((item) => orgId !== item.org_id).concat(items.filter((item) => orgId === item.org_id))
    return returnItems
  },
  getHighestPriority: function (items) {
    var bestMatch = null
    const orgId = AccountHelper.userOrgId
    // Items from external org have high priority
    items.forEach(function (item) {
      if (
        !bestMatch ||
        // Workaround for Costing which uses field name "auto_apply_priority" beacuse priority fieldname is already taken
        (item.auto_apply_priority || item.priority) > (bestMatch.auto_apply_priority || bestMatch.priority) ||
        (item.org_id !== orgId &&
          (item.auto_apply_priority || item.priority) === (bestMatch.auto_apply_priority || bestMatch.priority))
      ) {
        bestMatch = item
      }
    })
    return bestMatch
  },
  getMatchedAdders: function (state, zip, utility_id, sector, kwStc, componentCodes, roofTypeId) {
    const matchedAdders = AccountHelper.getAddersAvailable().filter((item) =>
      this.matchesAll(item, state, zip, utility_id, sector, kwStc, componentCodes, roofTypeId)
    )
    if (!matchedAdders.length) return []

    const adderMap = {}
    const slopes = this.system.userData.shadingByPanelGroup.map((module) => Number(module.slope.toFixed(1)))
    slopes.forEach((slope) => {
      matchedAdders.forEach((adder) => {
        const minOk = !adder.auto_apply_min_slope || slope >= adder.auto_apply_min_slope
        const maxOk = !adder.auto_apply_max_slope || slope <= adder.auto_apply_max_slope
        if (minOk && maxOk) {
          const key = adder.id
          if (!adderMap[key]) {
            adderMap[key] = { ...adder, quantity: 0 }
          }
          // A system has multiple panels, each with its own slope.
          // - Adders WITHOUT min/max slope are added with quantity = 0 and does not increment it.
          // - Adders WITH slope constraints increment quantity for each matching panel slope.
          if (adder.auto_apply_min_slope || adder.auto_apply_max_slope) {
            adderMap[key].quantity += 1
          }
        }
      })
    })
    return Object.values(adderMap).map((adder) => {
      if (adder.quantity === 0) adder.quantity = 1
      return adder
    })
  },
  generate: function (sectionToUpdate) {
    // beware null errors if projectData is null due to timing issue or other reason
    var state = this.projectData?.state
    var zip = this.projectData?.zip
    var assignedRole = this.projectData?.assigned_role
    var sector = this.projectData?.is_residential || !this.projectData ? 1 : 2
    var kwStc = this.system.kwStc()
    var componentCodes = this.system.componentCodes()

    const utilityTariff =
      this.projectData?.utility_tariff_proposed_data ||
      this.projectData?.utility_tariff_current_data ||
      this.projectData?.utility_tariff_proposed_or_guess
    const utility_id = utilityTariff?.data?.utility_id || null

    const roofTypeUrl = this.projectData?.roof_type
    const roofTypeId = roofTypeUrl ? parseInt(roofTypeUrl.split('/').filter(Boolean).pop(), 10) : null

    return {
      paymentOptions:
        (!sectionToUpdate || sectionToUpdate === 'paymentOptions') &&
        this.getHighestPriorityOrg(
          AccountHelper.getAutoAppliedPaymentOptions().filter((item) =>
            this.matchesAll(item, state, zip, utility_id, sector, kwStc, componentCodes, roofTypeId)
          )
        ),

      // If commission_id is set or commission override is set (including zero values too) then keep the applied value
      // and do not auto-detect commission_id based on assigned role.
      commissions:
        (!sectionToUpdate || sectionToUpdate === 'commissions') &&
        (this.system.commission_id ||
        this.system.commission_override_manually > 0 ||
        this.system.commission_override_manually === 0
          ? null
          : AccountHelper.getAutoAppliedCommissionByRole(assignedRole)),
      pricingScheme:
        (!sectionToUpdate || sectionToUpdate === 'pricingScheme') &&
        this.getHighestPriority(
          AccountHelper.getPricingSchemesAvailable().filter((item) =>
            this.matchesAll(item, state, zip, utility_id, sector, kwStc, componentCodes, roofTypeId)
          )
        ),
      costing:
        (!sectionToUpdate || sectionToUpdate === 'costing') &&
        this.getHighestPriority(
          AccountHelper.getCostingsAvailable()
            .filter((item) => !!item.priority)
            .filter((item) => this.matchesAll(item, state, zip, utility_id, sector, kwStc, componentCodes, roofTypeId))
        ),
      adders:
        (!sectionToUpdate || sectionToUpdate === 'adders') &&
        this.getExternalOrgFirst(
          this.getMatchedAdders(state, zip, utility_id, sector, kwStc, componentCodes, roofTypeId)
        ),
    }
  },
  delta: function (sectionToUpdate) {
    var targetState = this.generate(sectionToUpdate)

    var matchesAutoApply = this.matchesAutoApply

    var changesToApply = {
      // Ony show show deltas/messages when payment options are overridden, they have not meaning when we are using
      // auto-detected payment options on the back-end

      paymentOptions: null,
      // Disable all payment option auto-apply changes until we have reviewed this feature in more detail
      //
      // paymentOptions:
      //   (!sectionToUpdate || sectionToUpdate === 'paymentOptions') && this.system.payment_options_override
      //     ? {
      //         // If overrides are specified compared against them, otherwise compare against the calculated payment options
      //         add: this.system.payment_options_override
      //           ? targetState.paymentOptions.filter((addPo) => !this.system.payment_options_override.includes(addPo.id))
      //           : targetState.paymentOptions.filter(
      //               (addPo) => !this.system.payment_options.map((enabledPo) => enabledPo.id).includes(addPo.id)
      //             ),
      //         remove: this.system.payment_options_override
      //           ? this.system.payment_options_override.filter(
      //               (enabledPoId) => !targetState.paymentOptions.map((po) => po.id).includes(enabledPoId)
      //             )
      //           : this.system.payment_options
      //               .filter((enabledPo) => !targetState.paymentOptions.map((po) => po.id).includes(enabledPo.id))
      //               .map((enabledPo) => enabledPo.id),
      //         values: targetState.paymentOptions.map((po) => po.id),
      //       }
      //     : null,

      // No need to update if pricing scheme was already auto-applied correctly but we need to add applied pricing id
      // into system.pricing.pricing_scheme_id
      commissions:
        (!sectionToUpdate || sectionToUpdate === 'commissions') &&
        (this.projectData?.assigned_role_id && targetState.commissions !== this.system.commission_id
          ? targetState.commissions
          : null),
      pricingScheme:
        (!sectionToUpdate || sectionToUpdate === 'pricingScheme') &&
        (targetState.pricingScheme && targetState.pricingScheme.id !== this.system.pricing_scheme_id
          ? targetState.pricingScheme
          : null),

      costing:
        (!sectionToUpdate || sectionToUpdate === 'costing') &&
        (targetState.costing && targetState.costing.id !== this.system.costing_override ? targetState.costing : null),

      adders: (!sectionToUpdate || sectionToUpdate === 'adders') && {
        add: targetState.adders.filter(
          (addAdder) =>
            !this.system.line_items.some((li) => li.adder_id === addAdder.id && li.quantity === addAdder.quantity)
        ),
        remove: this.system.line_items
          .filter((li) => Boolean(li.adder_id && matchesAutoApply(AccountHelper.getAdderById(li.adder_id)))) // only remove adders which have auto-apply enabled
          .filter(
            (enabledLineItem) =>
              !targetState.adders.some(
                (adder) => adder.id === enabledLineItem.adder_id && adder.quantity === enabledLineItem.quantity
              )
          ),
        values: targetState.adders.map((adder) => adder.id),
      },
    }
    return changesToApply
  },
  apply: function (dryRun, sectionToUpdate) {
    var messages = []
    var hasChanged = false
    var changesToApply = this.delta(sectionToUpdate)
    var functionsToApply = []

    // Disable all payment option auto-apply changes until we have reviewed this feature in more detail
    //
    // if (!sectionToUpdate || sectionToUpdate === 'paymentOptions') {
    //   if (changesToApply.paymentOptions?.add?.length || changesToApply.paymentOptions?.remove?.length) {
    //     // enable override so changes can be applied
    //     functionsToApply.push(
    //       function () {
    //         // Never auto-sync paymentOptions
    //         // Manually re-applying the value will re-enable auto-sync
    //         this.system.autoSync.paymentOptions = false
    //
    //         window.editor.execute(
    //           new window.SetValueCommand(
    //             this.system,
    //             'payment_options_override',
    //             changesToApply.paymentOptions.values,
    //             undefined,
    //             false
    //           )
    //         )
    //
    //         Designer.showNotification(window.translate('Payment Options Updated.'), 'info')
    //       }.bind(this)
    //     )
    //     hasChanged = true
    //     messages.push('Payment Options can be refreshed')
    //   }
    // }

    if (!sectionToUpdate || sectionToUpdate === 'commissions') {
      if (changesToApply.commissions) {
        functionsToApply.push(
          function () {
            window.editor.execute(
              new window.SetValueCommand(this.system, 'commission_id', changesToApply.commissions, undefined, false)
            )

            Designer.showNotification(window.translate('Commissions Updated'), 'info')
          }.bind(this)
        )
        hasChanged = true
        messages.push('Commissions can be refreshed')
      }
    }

    if (!sectionToUpdate || sectionToUpdate === 'pricingScheme') {
      if (changesToApply.pricingScheme) {
        functionsToApply.push(
          function () {
            // Manually re-applying the value will re-enable auto-sync
            this.system.autoSync.pricingScheme = true

            window.editor.execute(
              new window.SetValueCommand(
                this.system,
                'pricing_scheme_id',
                changesToApply.pricingScheme.id,
                undefined,
                false
              )
            )

            Designer.showNotification(window.translate('Pricing Scheme Updated'), 'info')
          }.bind(this)
        )
        hasChanged = true
        messages.push('Pricing Scheme can be refreshed')
      }
    }

    if (!sectionToUpdate || sectionToUpdate === 'costing') {
      if (changesToApply.costing) {
        functionsToApply.push(
          function () {
            // Manually re-applying the value will re-enable auto-sync
            this.system.autoSync.costing = true

            window.editor.execute(
              new window.SetValueCommand(this.system, 'costing_override', changesToApply.costing.id, undefined, false)
            )

            Designer.showNotification(window.translate('Costing Updated'), 'info')
          }.bind(this)
        )
        hasChanged = true
        messages.push('Costing can be refreshed')
      }
    }

    if (!sectionToUpdate || sectionToUpdate === 'adders') {
      if (changesToApply.adders?.add?.length || changesToApply.adders?.remove?.length) {
        functionsToApply.push(
          function () {
            // Manually re-applying the value will re-enable auto-sync
            this.system.autoSync.adders = true

            changesToApply.adders.add.forEach((adder) => {
              //add adder
              Adder.addToSystem(this.system, adder)
            }, this)

            //remove adders
            if (changesToApply.adders.remove.length > 0) {
              var lineItems = this.system.line_items.filter(
                (li) =>
                  !changesToApply.adders.remove.some((ca) => ca.adder_id === li.adder_id && ca.quantity === li.quantity)
              )
              window.editor.execute(new window.SetValueCommand(this.system, 'line_items', lineItems, undefined, true))
            }

            Designer.showNotification(window.translate('Adders Updated'), 'info')
          }.bind(this)
        )
        hasChanged = true
        messages.push('Adders can be refreshed')
      }
    }

    // Do not make any changes if any redos are found beacuse the change could wipe out redos
    if (dryRun !== true && !ReplayHelper?.replayInProgress && editor.history.redos.length === 0) {
      functionsToApply.forEach((functionToApply) => functionToApply())
      if (hasChanged) {
        editor.signals.objectChanged.dispatch(this.system)
      }
    }
    return messages
  },
  matchesAll: function (item, state, zip, utility_id, sector, kwStc, componentCodes, roofType) {
    return (
      this.matchesAutoApply(item) &&
      this.matchesNotArchived(item) &&
      this.matchesState(item, state) &&
      this.matchesUtilityAndZip(item, utility_id, zip) &&
      this.matchesSystemSize(item, kwStc) &&
      this.matchesComponentCodes(item, componentCodes) &&
      this.matchesSector(item, sector) &&
      this.matchesRoofType(item, roofType)
    )
  },
  hasMatchWithWildcards: function (itemsToMatchWithWildcards, codesInSystem) {
    return itemsToMatchWithWildcards.some((requiredComponentCode) => {
      if (codesInSystem.includes(requiredComponentCode)) {
        return true
      } else if (requiredComponentCode.startsWith('*')) {
        var requiredComponentSuffix = requiredComponentCode.split('*').join('')
        if (codesInSystem.some((code) => code.endsWith(requiredComponentSuffix))) {
          return true
        }
      } else if (requiredComponentCode.endsWith('*')) {
        var requiredComponentPrefix = requiredComponentCode.split('*').join('')
        if (codesInSystem.some((code) => code.startsWith(requiredComponentPrefix))) {
          return true
        }
      }
      return false
    })
  },

  matchesComponentCodes: function (item, componentCodesInSystem) {
    if (!item.auto_apply_component_codes) {
      return true
    }
    const auto_apply_component_codes_parsed = item.auto_apply_component_codes.split(',').map((code) => code.trim())
    return this.hasMatchWithWildcards(auto_apply_component_codes_parsed, componentCodesInSystem)
  },
  matchesSystemSize: function (item, kwStc) {
    if (item.auto_apply_max_system_size && kwStc > item.auto_apply_max_system_size) {
      // too big
      return false
    } else if (item.auto_apply_min_system_size && kwStc < item.auto_apply_min_system_size) {
      // too small
      return false
    } else {
      return true
    }
  },
  matchesNotArchived: function (item) {
    return Boolean(!item.is_archived)
  },
  matchesAutoApply: function (item) {
    // Workaround for Costing which uses field name "auto_apply_priority" beacuse priority fieldname is already taken
    return !!(
      item &&
      (item.auto_apply_priority || (item.auto_apply_priority || 0) > 0 || Boolean(item.auto_apply_enabled))
    )
  },
  matchesSector: function (item, sector) {
    // Note: auto_apply_sector===0 >>> 'All'
    if (item.auto_apply_sector && item.auto_apply_sector !== 0 && item.auto_apply_sector !== sector) {
      return false
    } else {
      return true
    }
  },
  matchesRoofType: function (item, roof_type) {
    // Note: auto_apply_roof_type===0 >>> 'All'
    if (item?.auto_apply_roof_type && item.auto_apply_roof_type !== 0 && item.auto_apply_roof_type !== roof_type) {
      return false
    }
    return true
  },
  matchesState: function (item, state) {
    const specified_states = item.auto_apply_only_specified_states
    if (!specified_states) {
      return true
    }
    const specified_states_parsed = specified_states.split(',').map((state) => state.trim())
    return this.hasMatchWithWildcards(specified_states_parsed, [state])
  },
  matchesZip: function (item, zip) {
    const specified_zips = item.auto_apply_only_specified_zips
    if (!specified_zips) {
      return true
    }
    const specified_zips_parsed = specified_zips.split(',').map((zip) => zip.trim())
    return this.hasMatchWithWildcards(specified_zips_parsed, [zip])
  },
  matchesUtilityAndZip: function (item, utility_id, zip) {
    const specified_utility_ids = item?.auto_apply_only_specified_utilities
    const specified_zips = item.auto_apply_only_specified_zips
    const is_utility_ids_specified = Array.isArray(specified_utility_ids) && specified_utility_ids.length > 0
    if (!is_utility_ids_specified && !specified_zips) return true
    if (!is_utility_ids_specified) return this.matchesZip(item, zip)
    if (specified_utility_ids.includes(utility_id)) return true
    return this.matchesZip(item, zip)
  },
})

/*
eg. data =
{
    "code": "MICRO-0.25-I-OUTD-US-208",
    "inverter_max_voltage": 50,
    "mppt_quantity": 1,
    "is_valid": true,
    "voltage_nominal": 208,
    "max_power_rating": 250,
    "manufacturer_name": "ABB",
    "microinverter": true,
}
*/
function InverterType(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('InverterType: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

InverterType.prototype = Object.assign({
  properties: [
    'id',
    'inverter_id',
    'code',
    'voltage_max',
    'mppt_voltage_max',
    'mppt_voltage_min',
    'current_isc_max',
    'current_dc_max',
    'mppt_quantity',
    'is_valid',
    'voltage_nominal',
    'nominal_input_voltage',
    'voltage_minimum',
    'max_power_rating',
    'max_dc_power',
    'manufacturer',
    'manufacturer_name',
    'microinverter',
    'hybrid',
    'external_link',
    'external_label',
    'efficiency',
    'power_consumption_at_night',
    'is_default',
    'cost',
    'quantity',
    'logo',
    'exhibitor_org_id',
    'is_archived',
    'cost',
    'labor_adjustment',
    'price_adjustment',
    'price_adjustment_per_watt',
    'price_adjustment_per_panel',
    'skus',
    'ordering',
    'extra',
    'current_type',
    'current_rating',
    'voltage_rating',
    'cable_thickness',
    'cable_length',
    'phase_type',
    'current_ac_max',
    'inbuilt_dc_isolator',
    'org_id',
    'share_with_orgs',
    'external_data',
    'short_description',
  ],
  compatibleBatteryCodes: function () {
    return this.extra?.compatible_battery_codes || []
  },
  getRange: function () {
    if (this.microinverter) {
      // Never use a range for a microinverter, we will specify the inverter activation instead
      return null
    } else {
      return {
        code: this.manufacturer_name + '_' + this.voltage_nominal + (this.microinverter ? '_micro' : '_string'),
        label:
          this.manufacturer_name + ' Range (' + this.voltage_nominal + 'V)' + (this.microinverter ? ' [micro]' : ''),
      }
    }
  },
})

InverterType.fromArray = function (items) {
  return items.map(function (item) {
    return new InverterType(item)
  })
}

InverterType.validateSamSpecs = function (inverterData) {
  // Quick check to ensure fields are not empty. No type/range checking.
  var validationRules = {
    code: validate_string_not_empty,
    voltage_max: validate_number_greater_than_zero,
    voltage_nominal: validate_number_greater_than_zero,
    voltage_minimum: validate_number_greater_than_zero,
    max_power_rating: validate_number_greater_than_zero,
    microinverter: validate_boolean,
    efficiency: validate_number_greater_than_zero,
  }
  return validateWithRules(inverterData, validationRules)
}

/*
eg. data =
{
    "code": "MICRO-0.25-I-OUTD-US-208",
    "manufacturer_name": "Tesla",
}
*/
function BatteryType(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('BatteryType: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

BatteryType.prototype = Object.assign({
  properties: [
    'id',
    'battery_id',
    'code',
    'manufacturer',
    'manufacturer_name',
    'kwh_optimal',
    'depth_of_discharge_factor',
    'power_max_continuous',
    'aging_factor',
    'efficiency_factor',
    'warranty_kwh_1_cycle_per_day',
    'voltage',
    'end_of_life_capacity',
    'external_link',
    'external_label',
    'logo',
    'exhibitor_org_id',
    'is_archived',
    'cost',
    'labor_adjustment',
    'price_adjustment',
    'price_adjustment_per_watt',
    'price_adjustment_per_panel',
    'price_treatment',
    'skus',
    'ordering',
    'extra',
    'org_id',
    'share_with_orgs',
    'external_data',
    'short_description',
  ],
  compatibleChargerCodes: function () {
    return this.extra?.compatible_charger_codes || []
  },
})

BatteryType.fromArray = function (items) {
  return items.map(function (item) {
    return new BatteryType(item)
  })
}

/*
eg. data =
{
    "code": "SuperRack",
    "manufacturer_name": "Rack Co.",
    "description": "Great system.",
}
*/
function OtherType(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('OtherType: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

OtherType.prototype = Object.assign({
  properties: [
    'id',
    'other_id',
    'code',
    'other_component_type',
    'title',
    'manufacturer',
    'manufacturer_name',
    'description',
    'show_customer',
    'show_in_your_solution',
    'show_in_quotation_table',
    'is_archived',
    'dc_optimizer_efficiency',
    'dc_optimizer_max_input_power',
    'dc_optimizer_max_input_voltage',
    'dc_optimizer_max_input_current',
    'dc_optimizer_max_output_voltage',
    'dc_optimizer_max_output_current',
    'external_link',
    'external_label',
    'logo',
    'exhibitor_org_id',
    'cost',
    'is_default',
    'labor_adjustment',
    'price_adjustment',
    'price_adjustment_per_watt',
    'price_adjustment_per_panel',
    'price_treatment',
    'skus',
    'ordering',
    'extra',
    'current_type',
    'current_rating',
    'voltage_to_current_rating',
    'voltage_rating',
    'cable_thickness',
    'cable_length',
    'phase_type',
    'model',
    'org_id',
    'share_with_orgs',
    'weight',
    'external_data',
    'short_description',
  ],
  compatibleBatteryCodes: function () {
    return this.extra?.compatible_battery_codes || []
  },
})

OtherType.fromArray = function (items) {
  return items.map(function (item) {
    return new OtherType(item)
  })
}

function RoofType(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('PaymentOption: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

RoofType.DefaultDataForTests = {
  id: 1,
  name: '',
  texture: '',
}

RoofType.prototype = Object.assign({
  properties: ['id', 'name', 'texture'],
})

RoofType.fromArray = function (items) {
  return items.map(function (item) {
    return new RoofType(item)
  })
}

function SystemDesign(data) {
  this.data = data
}

SystemDesign.prototype.moduleQuantity = function () {
  var quantity = 0
  this.data.config.inverters.forEach(function (i) {
    i.mppts.forEach(function (m) {
      m.strings.forEach(function (s) {
        quantity += s.modules.length
      })
    })
  })
  return quantity
}

SystemDesign.prototype.moduleId = function () {
  return this.data.config.module.id
}

SystemDesign.prototype.inverterIds = function () {
  //For consistency and determinism, always sort codes
  var _inverterCodes = []
  this.data.config.inverters.forEach(function (i) {
    _inverterCodes.push(i.id)
  })
  return _inverterCodes.sort()
}

SystemDesign.prototype.inverterCodes = function () {
  //For consistency and determinism, always sort codes
  var _inverterCodes = []
  this.data.config.inverters.forEach(function (i) {
    _inverterCodes.push(i.code)
  })
  return _inverterCodes.sort()
}

function AccountHelperClass() {}

function Tag(data) {
  this.properties.forEach(function (key) {
    if (!data.hasOwnProperty(key)) {
      if (window.studioDebug) {
        console.log('Tag: Missing property:' + key)
      }
    } else {
      this[key] = data[key]
    }
  }, this)
}

Tag.prototype = Object.assign({
  properties: ['title', 'allow_fetch', 'url', 'id'],
})

Tag.fromArray = function (items) {
  return items.map(function (item) {
    return new Tag(item)
  })
}

AccountHelperClass.AccountDefaultData = {
  supplier_shipping_fixed_per_system: 0.1,
  supplier_shipping_per_panel: 0.1,
  racking_fixed_per_system: 0.1,
  racking_per_panel: 0.1,
  bos_fixed_per_system: 0.1,
  bos_per_panel: 0.1,
  labor_fixed_per_system: 0.1,
  labor_per_panel: 0.1,
  project_site_shipping_and_warehousing_fixed_per_system: 0.1,
  project_site_shipping_and_warehousing_per_panel: 0.1,
  allocation_of_lead_gen_fixed_per_system: 0.1,
  allocation_of_lead_gen_per_panel: 0.1,
  allocation_of_salary_fixed_per_system: 0.1,
  allocation_of_salary_per_panel: 0.1,
  presale_software_and_design_fixed_per_system: 0.1,
  presale_software_and_design_per_panel: 0.1,
  commission_fixed_per_system: 0.1,
  commission_per_watt: 0.1,
  commission_percentage_of_cogs_and_labor: 0.1,
  project_management_fixed_per_system: 0.1,
  project_management_per_panel: 0.1,
  design_drawings_fixed_per_system: 0.1,
  design_drawings_per_panel: 0.1,
  permit_costs_fixed_per_system: 0.1,
  permit_costs_per_panel: 0.1,
  other_costs_fixed_per_system: 0.1,
  other_costs_per_panel: 0.1,
}

var loadedDataEmpty = {
  org: null,
  componentModuleSpecs: [],
  componentInverterSpecs: [],
  componentBatterySpecs: [],
  componentOtherSpecs: [],
  costings: [],
  commissions: [],
  adders: [],
  pricingSchemes: [],
  batterySchemes: [],
  incentives: [],
  paymentOptions: [],
  roofTypes: [],
  wallTypes: [
    {
      id: 1,
      name: 'Brick White',
      texture: 'brick_white',
    },
    {
      id: 2,
      name: 'Brick Brown',
      texture: 'brick_brown',
    },
  ],
  autoDesign: {},
}

var loadedDataReadyEmpty = {
  org: false,
  // This be Lazy loaded after initial loads
  componentSpecsOrdering: false,
  componentModuleSpecs: false,
  componentInverterSpecs: false,
  componentBatterySpecs: false,
  componentOtherSpecs: false,
  pricingSchemes: false,
  costings: false,
  commissions: false,
  adders: false,
  batterySchemes: false,
  incentives: false,
  paymentOptions: false,
  roofTypes: false,
  wallTypes: true,
}

var clone = function (original) {
  return JSON.parse(JSON.stringify(original))
}

AccountHelperClass.prototype = Object.assign({
  // Params are typically saved when org is initially loaded params need not be handled directly
  // They can still be specified on individual calls to retain flexibility, testability, etc.
  params: {},

  loadedData: clone(loadedDataEmpty),

  cloneAndRedactedLoadedData: function () {
    var keysToRedact = {
      org: {
        keys: [
          'api_key_google',
          'api_key_bing',
          'api_key_arcgis_osm',
          'api_key_vexcel',
          'api_key_cyclomedia',
          'api_key_nearmap',
          'api_key_utility_api',
          'api_key_pvsell',

          'api_key_chat',
          'api_key_sunlight_email',
          'api_key_sunlight_password',
          'api_key_plenti_partner_id',
          'api_key_energy_ease_token',
        ],
        role: {
          keys: [
            'api_key_chat',
            'greenlancer_token',
            'greenlancer_token_expiration',
            'brighte_access_token',
            'brighte_refresh_token',
          ],
        },
      },
    }

    try {
      var loadedDataCopy = clone(AccountHelper.loadedData)

      if (loadedDataCopy.org) {
        keysToRedact.org.keys.forEach((key) => {
          if (loadedDataCopy.org[key]) {
            loadedDataCopy.org[key] = 'REDACTED'
          }
        })

        if (loadedDataCopy.org.roles) {
          loadedDataCopy.org.roles.forEach((role) => {
            keysToRedact.org.role.keys.forEach((key) => {
              if (role[key]) {
                role[key] = 'REDACTED'
              }
            })
          })
        }
      }

      return loadedDataCopy
    } catch (e) {
      console.error(e)
      return {}
    }
  },

  loadedDataReady: clone(loadedDataReadyEmpty),

  reset: function () {
    this.loadedData = clone(loadedDataEmpty)
    this.loadedDataReady = clone(loadedDataReadyEmpty)
  },

  getModuleTypeDefaultId: function () {
    return this.getModuleTypeDefault().id
  },

  getModuleTypeDefault: function () {
    const orgId = AccountHelper.userOrgId
    let availableModules = this.getComponentModuleSpecsAvailable()
    availableModules = availableModules
      .filter((item) => orgId !== item.org_id)
      .concat(availableModules.filter((item) => orgId === item.org_id))
    var moduleDefaults = availableModules.filter(function (moduleData) {
      return moduleData.is_default
    })

    if (moduleDefaults.length > 0) {
      return this.getModuleData(moduleDefaults[0].id)
    } else if (this.getComponentModuleSpecsAvailable().length > 0) {
      return this.getModuleData(this.getComponentModuleSpecsAvailable()[0].id)
    } else {
      return this.getModuleData(null)
    }
  },

  getInverterRanges: function () {
    var ranges = []
    ranges.push({ code: null, label: '' }) //empty option which will use all available inverters on the org

    // Add ranges (which group string inverters by manufacturer_name and voltage
    this.getComponentInverterSpecsAvailable().forEach(function (i) {
      var range = i.getRange()
      if (
        range &&
        ranges
          .map(function (r) {
            return r.code
          })
          .indexOf(range.code) === -1
      ) {
        ranges.push(range)
      }
    })

    ranges.sort(function (a, b) {
      return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    })

    // Add all individual inverters too
    this.getComponentInverterSpecsAvailable().forEach(function (i) {
      ranges.push({
        code: i.id,
        label: i.code,
      })
    })

    return ranges
  },

  getDefaultInverterId: function () {
    //Returns null if none found, safer than using getDefaultBattery directly without catching errors
    var defaultInverter = this.getDefaultInverter()
    if (defaultInverter && defaultInverter.id) {
      return defaultInverter.id
    } else {
      return null
    }
  },

  getDefaultInverter: function () {
    var defaultInverterMatch = AccountHelper.getComponentInverterSpecsAvailable().filter(function (i) {
      return i.is_default === true
    })
    if (defaultInverterMatch.length > 0) {
      return defaultInverterMatch[0]
    } else {
      return AccountHelper.getComponentInverterSpecsAvailable()[0]
    }
  },

  getDefaultInverterEfficiency: function () {
    var defaultInverter = this.getDefaultInverter()
    if (defaultInverter) {
      return defaultInverter.efficiency
    } else {
      return null
    }
  },

  getDefaultBattery: function () {
    var defaultBattteryMatch = AccountHelper.getComponentBatterySpecsAvailable().filter(function (b) {
      return b.is_default === true
    })
    if (defaultBattteryMatch.length > 0) {
      return defaultBattteryMatch[0]
    } else {
      return AccountHelper.getComponentBatterySpecsAvailable()[0]
    }
  },

  getDefaultBatteryId: function () {
    //Returns null if none found, safer than using getDefaultBattery directly without catching errors
    var defaultBattery = this.getDefaultBattery()
    if (defaultBattery && defaultBattery.id) {
      return defaultBattery.id
    } else {
      return null
    }
  },

  getComponentModuleSpecsAvailable: function (includeArchived) {
    return this.loadedData.componentModuleSpecs.filter((component) => !!includeArchived || !component.is_archived)
  },
  getComponentInverterSpecsAvailable: function (includeArchived) {
    return this.loadedData.componentInverterSpecs.filter((component) => !!includeArchived || !component.is_archived)
  },
  getComponentBatterySpecsAvailable: function (includeArchived) {
    return this.loadedData.componentBatterySpecs.filter((component) => !!includeArchived || !component.is_archived)
  },
  getComponentOtherSpecsAvailable: function (includeArchived) {
    return this.loadedData.componentOtherSpecs.filter((component) => !!includeArchived || !component.is_archived)
  },
  addComponentOtherSpecs: function (componentsToAdd) {
    const dedupedComponents = this.getComponentOtherSpecsAvailable()
    const existingIds = dedupedComponents.map((comp) => comp.id)
    componentsToAdd.forEach((comp) => {
      if (!existingIds.includes(comp.id)) dedupedComponents.push(comp)
    })
    this.loadedData.componentOtherSpecs = dedupedComponents
  },
  getComponentDcOptimizerSpecsAvailable: function () {
    return this.loadedData.componentOtherSpecs
      .filter((component) => !component.is_archived)
      .filter((other) => other && other.other_component_type === 'dc_optimizer')
  },
  getComponentActivationFromCode: function (code, componentType) {
    var componentSpecsCombined = []

    if (!componentType || componentType === 'module') {
      componentSpecsCombined = componentSpecsCombined.concat(this.loadedData.componentModuleSpecs)
    }
    if (!componentType || componentType === 'inverter') {
      componentSpecsCombined = componentSpecsCombined.concat(this.loadedData.componentInverterSpecs)
    }
    if (!componentType || componentType === 'battery') {
      componentSpecsCombined = componentSpecsCombined.concat(this.loadedData.componentBatterySpecs)
    }
    if (!componentType || componentType === 'other') {
      componentSpecsCombined = componentSpecsCombined.concat(this.loadedData.componentOtherSpecs)
    }
    return _.find(componentSpecsCombined, { code: code })
  },
  getComponentActivationSpecs: function (id, componentType) {
    var componentActivation = null

    if (!componentType || componentType === 'module') {
      componentActivation = AccountHelper.getModuleById(id)
      if (componentActivation && componentActivation.id) {
        return componentActivation
      }
    }

    if (!componentType || componentType === 'inverter') {
      componentActivation = AccountHelper.getInverterById(id)
      if (componentActivation && componentActivation.id) {
        return componentActivation
      }
    }

    if (!componentType || componentType === 'battery') {
      componentActivation = AccountHelper.getBatteryById(id)
      if (componentActivation && componentActivation.id) {
        return componentActivation
      }
    }

    if (!componentType || componentType === 'other') {
      componentActivation = AccountHelper.getOtherById(id)
      if (componentActivation && componentActivation.id) {
        return componentActivation
      }
    }

    return null
  },
  getModuleById: function (id) {
    /*
    Alias to match other components
    */
    return AccountHelper.getModuleData(id)
  },
  getInverterById: function (id) {
    return _.find(this.loadedData.componentInverterSpecs, { id: id })
  },
  getBatteryById: function (id) {
    return _.find(this.loadedData.componentBatterySpecs, { id: id })
  },
  getOtherById: function (id) {
    return _.find(this.loadedData.componentOtherSpecs, { id: id })
  },
  getRoofTypeById: function (id) {
    return _.find(this.loadedData.roofTypes, { id: id })
  },
  getRoofTypeDefault: function () {
    if (this.loadedData.roofTypes[0]) {
      return this.loadedData.roofTypes[0]
    } else {
      return {
        id: -1,
        name: 'Unspecified',
        texture: 'asphalt',
      }
    }
  },
  getWallTypeById: function (id) {
    return _.find(this.loadedData.wallTypes, { id: id })
  },
  getWallTypeDefault: function () {
    if (this.loadedData.wallTypes[0]) {
      return this.loadedData.wallTypes[0]
    } else {
      return {
        id: -1,
        name: 'Unspecified',
        texture: 'brick_white',
      }
    }
  },
  getAdderById: function (id) {
    return this.loadedData.adders.find((adder) => adder.id === id)
  },
  addAdderToLibrary: function (adder) {
    return this.loadedData.adders.push(adder)
  },
  isLoaded: function () {
    //@TODO: Don't use length>0 as a check because loaded list may be empty
    return (
      this.loadedDataReady.org &&
      this.loadedDataReady.pricingSchemes &&
      this.loadedDataReady.batterySchemes &&
      this.loadedDataReady.incentives &&
      this.loadedDataReady.paymentOptions &&
      this.loadedDataReady.costings &&
      this.loadedDataReady.commissions &&
      this.loadedDataReady.adders &&
      this.loadedDataReady.componentModuleSpecs &&
      this.loadedDataReady.componentInverterSpecs &&
      this.loadedDataReady.componentBatterySpecs &&
      this.loadedDataReady.componentOtherSpecs &&
      this.loadedDataReady.roofTypes
    )
  },

  waitUntilLoadedDataIsReady: async function () {
    return Utils.getValueWhenReady(() => {
      return { value: true, isReady: this.isLoaded() }
    }, 20000)
  },

  partsLoaded: function () {
    var keys = Object.keys(this.loadedDataReady)
    var loaded = keys.filter(function (key) {
      return this.loadedDataReady[key]
    }, this).length
    return [loaded, keys.length]
  },

  loadOrg: function (callback) {
    Designer.Ajax({
      url: API_BASE_URL + 'orgs/' + window.getStorage().getItem('org_id') + '/?format=json&fieldset=studio',
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      context: this,
      success: function (_org) {
        this.loadOrgSuccess(_org, callback)
      },
      error: function (response) {
        console.log('loadAccount error', response)

        this.loadedData.org = null

        Designer.loginPromptIfRequired(response)
      },
    })
  },
  terrainSize: 100,
  terrainUrlsCache: {
    duration: 1000 * 60 * 10, // 10 minutes, 600 seconds, 600,000 milliseconds
    key: function (identifier, location4326, country_iso2, state, premium_img_available = false) {
      // @TODO: Should we replace premium_img_available with a sorted list of all premium imagery products available?
      // because the lookup will only include those types which are available. This may be low concern because it is
      // unlikely this will be a common problem, and they can just reload the browser to work around it.

      // very roughly the nearest 10 meters
      var dp = 4
      var location4326Rounded = [
        roundToDecimalPlacesPrecise(location4326[0], dp),
        roundToDecimalPlacesPrecise(location4326[1], dp),
      ]
      return (
        identifier +
        '_' +
        location4326Rounded.join('_') +
        '_' +
        country_iso2 +
        '_' +
        state +
        '_' +
        premium_img_available
      )
    },
    save: function (identifier, location4326, country_iso2, state, premium_img_available = false, value) {
      // inject timestamp for when it was collected
      // Note this could be a little dangerous because value is often an array (not an object/dict) but setting a
      // property on an array is possible and seems reasonable in this situation, since we are only storing these
      // cache values temporarily and in memory, not serializing them anywhere.
      value.timestamp = new Date().getTime()

      AccountHelper.terrainUrlsCache.data[
        AccountHelper.terrainUrlsCache.key(identifier, location4326, country_iso2, state, premium_img_available)
      ] = value
    },
    get: function (identifier, location4326, country_iso2, state, premium_img_available = false) {
      var value =
        AccountHelper.terrainUrlsCache.data[
          AccountHelper.terrainUrlsCache.key(identifier, location4326, country_iso2, state, premium_img_available)
        ]

      if (value && value.timestamp) {
        // Cache duration may be different depending on the contents of the cached data
        // If result contains EagleView report in progress, then only cache for X seconds
        var cacheDuration

        const evReportIsReady = (mapTypeData) => {
          return (
            mapTypeData?.variation_data?.ReportStatus?.Status === 'Completed' ||
            (mapTypeData?.variation_data?.ReportStatus?.StatusId === 2 &&
              mapTypeData?.variation_data?.ReportStatus?.SubStatusId === 43)
          )
        }

        var containsEvInProgress = (identifier, cachedMapTypeResults) => {
          return (
            identifier === 'cachedGetMapTypesAtLocationRequest' &&
            cachedMapTypeResults &&
            cachedMapTypeResults[0] &&
            cachedMapTypeResults[0].find(
              (mapTypeData) => mapTypeData?.map_type === 'EagleViewInform' && !evReportIsReady(mapTypeData)
            )
          )
        }

        if (containsEvInProgress(identifier, value)) {
          cacheDuration = 1000 * 60 * 1 // 1 minute, 60 seconds, 60,000 milliseconds
        } else {
          // otherwise use default cache duration
          cacheDuration = AccountHelper.terrainUrlsCache.duration
        }

        if (value.timestamp + cacheDuration > new Date().getTime()) {
          // value found and not expired, return it
          return value
        } else {
          if (window.studioDebug) {
            console.log('terrainUrlsCache found but value has expired, ignore cache item')
          }
        }
      }

      return null
    },
    delete: function (identifier, location4326, country_iso2, state, premium_img_available = false) {
      var key = AccountHelper.terrainUrlsCache.key(identifier, location4326, country_iso2, state, premium_img_available)
      if (AccountHelper.terrainUrlsCache.data.hasOwnProperty(key)) {
        delete AccountHelper.terrainUrlsCache.data.hasOwnProperty(key)
      }
    },
    deleteAll: function () {
      AccountHelper.terrainUrlsCache.data = {}
    },
    data: {},
  },
  projectLocation: function () {
    try {
      return {
        country_iso2: window.WorkspaceHelper.project?.country_iso2 || '',
        //@TODO: Somehow we should load state and use this in the lookup too.
        //Should this come from the project record? Do we have it ready at that time?
        state: window.WorkspaceHelper.project?.state || '',
        address: window.WorkspaceHelper.project?.address || '',
      }
    } catch (error) {
      return { country_iso2: '', state: '', address: '' }
    }
  },
  sceneOrigin4326FromSceneOrProject: function () {
    /*
    Always use WorkspaceHelper.project.lat/lon if available
    Only fallback to editor.scene.sceneOrigin4326 if project not available
    for some strange reason.

    This is essential because we want to use the latest lat/lon from project,
    not re-use what we have already designed.

    @TODO: We may want variations which can indicate the preference between current design lat/lon and project lat/lon
    */
    let sceneOrigin4326 = null

    if (window.WorkspaceHelper.project) {
      sceneOrigin4326 = [window.WorkspaceHelper.project.lon, window.WorkspaceHelper.project.lat]
    }

    if (!sceneOrigin4326 || !sceneOrigin4326[0] || !sceneOrigin4326[1]) {
      sceneOrigin4326 = window.editor.scene.sceneOrigin4326
    }

    if (!sceneOrigin4326 || !sceneOrigin4326[0] || !sceneOrigin4326[1]) {
      //use startLocation4326 from project form
      if (!window.WorkspaceHelper.project) {
        console.warn('Error in window.AccountHelper.sceneOrigin4326FromSceneOrProject')
        return
      }
    }

    return sceneOrigin4326
  },

  getIsPremiumImageryAvailable: function (location4326, country_iso2, projectState) {
    let role = undefined
    const orgId = AccountHelper.userOrgId
    if (orgId) {
      try {
        role = reduxState.auth.roles.filter((role) => role.org_id === orgId)[0]
      } catch (e) {}
    }
    if (!role)
      return {
        isAvailable: false,
        blockedReason: 'permissions',
      }
    // TODO: refactor this to use the new Designer.permissions system
    if (!!role?.permissions?.purchases_for_projects?.create) {
      return {
        isAvailable: true,
        blockedReason: undefined,
      }
    } else {
      return {
        isAvailable: false,
        blockedReason: 'permissions',
      }
    }
  },

  getTerrainUrlsAtLocation: async function (
    provider,
    location4326,
    country_iso2,
    state,
    successHandler,
    errorHandler,
    filterResultsFunc
  ) {
    try {
      // Use first match for same provider as current view
      var mapType
      if (provider === 'Nearmap') {
        mapType = 'Nearmap3D'
      } else if (provider === 'Google') {
        mapType = 'Google3D'
      } else if (provider === 'GetMapping') {
        mapType = 'GetMapping3D'
      } else if (provider === 'GetMappingPremium') {
        mapType = 'GetMappingPremium3D'
      } else if (provider === 'Vexcel') {
        mapType = 'Vexcel3D'
      }

      var [mapTypes] = await this.getMapTypesForProject(location4326)

      // @TODO: This is broken because it only ever chooses the first result
      // We should allow injecting a filter function to select the correct match

      var resultsWithSameMapType = mapTypes.filter((m) => m.map_type === mapType)

      var mapTypeData = resultsWithSameMapType[0]

      if (filterResultsFunc) {
        var firstFilteredResult = resultsWithSameMapType.find(filterResultsFunc)
        if (firstFilteredResult) {
          mapTypeData = firstFilteredResult
        } else {
          console.warn('Filter did not match any results, use first result for matching map type instead.')
        }
      }

      if (mapTypeData && mapTypeData.variation_data) {
        if (successHandler) {
          successHandler(mapTypeData.variation_data)
        }
        return Promise.resolve(mapTypeData.variation_data)
      }
    } catch (err) {
      console.error(err)
      errorHandler(err)
      Designer.loginPromptIfRequired(err)
    }
  },

  applySavedLoadedData: function (data) {
    AccountHelper.reset()
    AccountHelper.params = {}
    AccountHelper.loadOrgSuccess(data.org)
    AccountHelper.loadComponentSpecsSuccess(
      {
        module_types: data.componentModuleSpecs,
        inverter_types: data.componentInverterSpecs,
        battery_types: data.componentBatterySpecs,
        other_types: data.componentOtherSpecs,
        roof_types: data.roofTypes,
      },
      null
    )

    AccountHelper.loadPricingSchemesSuccess(data.pricingSchemes, null)
    AccountHelper.loadBatterySchemesSuccess(data.batterySchemes, null)
    AccountHelper.loadIncentivesSuccess(data.incentives, null)
    AccountHelper.loadPaymentOptionsSuccess(data.paymentOptions, null)
    AccountHelper.loadCostingsSuccess(data.costings, null)
    AccountHelper.loadCommissionsSuccess(data.commissions, null)
    AccountHelper.loadAddersSuccess(data.adders, null)
  },

  loadOrgSuccess: function (_org, callback) {
    this.loadedData.org = _org

    // If org.api_key_google is not set then use default key from localStorage
    // Ideally this would be retrieved from redux props but we are outside React/Redux context

    try {
      var api_key_google_from_auth = JSON.parse(window.getStorage().getItem('auth')).api_key_google

      if (api_key_google_from_auth && api_key_google_from_auth.length > 0 && this.loadedData.org) {
        this.loadedData.org.api_key_google = api_key_google_from_auth
        if (window.studioDebug) {
          console.log('Loaded default api_key_google from window.getStorage().auth')
        }
      }
    } catch (e) {
      console.warn('Error loading api_key_google from window.getStorage().auth', e)
    }

    //If Nearmap API Key is available then automatically start a nearmap session
    if (_org?.api_key_nearmap) {
      Designer.refreshNearmapSessionId()
    }

    this.loadedDataReady.org = true
    if (callback) callback()
  },

  loadComponentSpecs: function (callback) {
    Designer.Ajax({
      url: this.formUrl('component_specs', true),
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      context: this,
      success: function (response) {
        if (!response['module_types'] || response['module_types'].length === 0) {
          Designer.showNotification(
            window.translate(
              'No module specs found for this org. Please setup component in the org management system.'
            ),
            'danger'
          )
        }

        if (!response['inverter_types'] || response['inverter_types'].length === 0) {
          Designer.showNotification(
            window.translate(
              'No inverter specs found for this org. Please setup component in the org management system.'
            ),
            'danger'
          )
        }

        this.loadComponentSpecsSuccess(response, callback)
      },
      error: function (response) {
        console.log('loadComponentSpecs error', response)
        Designer.loginPromptIfRequired(response)
      },
    })
  },

  loadComponentSpecsWithOrderingDataSuccess: function (response) {
    this.loadComponentSpecsSuccess(response)
    // This be Lazy loaded after initial loads
    this.loadedDataReady.componentSpecsOrdering = true
  },

  loadComponentSpecsSuccess: function (response, callback) {
    this.loadedData.componentModuleSpecs = ModuleType.fromArray(response['module_types'])
    this.loadedData.componentInverterSpecs = InverterType.fromArray(response['inverter_types'])
    this.loadedData.componentBatterySpecs = BatteryType.fromArray(response['battery_types'])
    this.loadedData.componentOtherSpecs = OtherType.fromArray(response['other_types'])
    this.loadedData.roofTypes = RoofType.fromArray(response['roof_types'])
    this.loadedData.autoDesign = response['auto_design'] || {}

    this.loadedDataReady.componentModuleSpecs = true
    this.loadedDataReady.componentInverterSpecs = true
    this.loadedDataReady.componentBatterySpecs = true
    this.loadedDataReady.componentOtherSpecs = true
    this.loadedDataReady.roofTypes = true
    // Technically loadComponentSpecsSuccess could happen after loadComponentSpecsWithOrderingDataSuccess
    this.loadedDataReady.componentSpecsOrdering = false

    // Clear any activateComponentsRequests if they have now been satisfied
    this.activateComponentsRequestsClearIfSatisfied()

    if (callback) callback()
  },
  getSharedWithIds: function () {
    const sharedWith = window.WorkspaceHelper.project?.shared_with
    return sharedWith
      ? sharedWith
          .map((item) => {
            return item.org_id
          })
          .toString()
      : ''
  },
  formUrl: function (resource, includeSharedParam = false) {
    const orgId = window.getStorage().getItem('org_id')
    const defaultParams = '?limit=1000&show_archived=1&fieldset=list'
    const initUrl = `${API_BASE_URL}orgs/${orgId}/${resource}/${includeSharedParam ? '' : defaultParams}`

    const sharedIds = this.getSharedWithIds()
    const shareParams = `${includeSharedParam ? '?include_shared=1' : ''}&visible_to=${orgId}&owned_by=${
      window.WorkspaceHelper.project?.org_id
    },${sharedIds}`
    return initUrl + (sharedIds.length > 0 ? shareParams : includeSharedParam ? '?include_shared=2' : '')
  },
  loadPricingSchemes: function (callback) {
    Designer.Ajax({
      url: this.formUrl('pricing_schemes'),
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      context: this,
      success: function (response) {
        if (!response || response.length === 0) {
          Designer.showNotification(
            window.translate(
              'No pricing schemes found for this org. Please setup pricing schemes in the org management system.'
            ),
            'danger'
          )
        }

        this.loadPricingSchemesSuccess(response, callback)
      },
      error: function (response) {
        console.log('loadPricingSchemesSuccess error', response)
        Designer.loginPromptIfRequired(response)
      },
    })
  },

  loadBatterySchemes: function (callback) {
    Designer.Ajax({
      url: this.formUrl('battery_schemes'),
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      context: this,
      success: function (response) {
        this.loadBatterySchemesSuccess(response, callback)
      },
      error: function (response) {
        console.log('loadBatterySchemesSuccess error', response)
        Designer.loginPromptIfRequired(response)
      },
    })
  },

  loadBatterySchemesSuccess: function (response, callback) {
    var BatterySchemesLoaded = response

    // Sort batterySchemes by priority, ascending
    this.loadedData.batterySchemes = Utils.sortArrayOfObjects(BatterySchemesLoaded, 'name')
    this.loadedDataReady.batterySchemes = true
    if (callback) callback()
  },

  loadPricingSchemesSuccess: function (response, callback) {
    var pricingSchemesLoaded = PricingScheme.fromArray(response)

    // Sort pricingSchemes by priority, ascending
    this.loadedData.pricingSchemes = Utils.sortArrayOfObjects(pricingSchemesLoaded, 'title')
    this.loadedDataReady.pricingSchemes = true
    if (callback) callback()
  },

  loadPremiumProducts: function (callback, ajaxOptions) {
    Designer.Ajax(
      {
        url: API_BASE_URL + 'orgs/' + window.getStorage().getItem('org_id') + '/wallet_products/?fieldset=list',
        headers: Utils.tokenAuthHeaders(),
        dataType: 'json',
        context: this,
        success: function (response) {
          if (callback) callback(response)
        },
        error: function (response) {
          console.log('loadPremiumProducts error', response)
          Designer.loginPromptIfRequired(response)
        },
      },
      ajaxOptions
    )
  },

  getPricingSchemeDefault: function () {
    //Try to find matching pricing scheme with auto-assign enabled

    var state = WorkspaceHelper.project ? WorkspaceHelper.project['state'] : null
    var zip = WorkspaceHelper.project ? WorkspaceHelper.project['zip'] : null
    const availablePricingSchemes = this.getPricingSchemesAvailable()
    var autoDetectedPricingScheme = PricingScheme.autoDetect(availablePricingSchemes, state, zip)

    if (autoDetectedPricingScheme) {
      return autoDetectedPricingScheme
    } else if (availablePricingSchemes && availablePricingSchemes.length > 0) {
      return availablePricingSchemes[0]
    } else {
      return null
    }
  },

  getPricingSchemeDefaultId: function () {
    //Convenience method to avoid calling getPricingSchemeDefault().title which would need
    //null-pointer errors to be caught
    var pricingSchemeDefault = this.getPricingSchemeDefault()
    if (pricingSchemeDefault) {
      return pricingSchemeDefault.id
    } else {
      return null
    }
  },

  getPricingSchemeById: function (id) {
    for (var i = 0, l = this.loadedData.pricingSchemes.length; i < l; i++) {
      if (this.loadedData.pricingSchemes[i].id === id) {
        return this.loadedData.pricingSchemes[i]
      }
    }
  },

  getPricingSchemesAvailable: function () {
    return this.loadedData.pricingSchemes.filter((pricingScheme) => !pricingScheme.is_archived)
  },

  getCostingsAvailable: function () {
    return this.loadedData.costings.filter((costing) => !costing.is_archived)
  },

  loadIncentives: function (callback) {
    Designer.Ajax({
      url: this.formUrl('incentives'),
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      context: this,
      success: function (response) {
        if (!response || response.length === 0) {
          //no worries, many orgs will not have any manual incentives created
        }

        this.loadIncentivesSuccess(response, callback)
      },
      error: function (response) {
        console.log('loadIncentivesSuccess error', response)
        Designer.loginPromptIfRequired(response)
      },
    })
  },

  loadIncentivesSuccess: function (response, callback) {
    var incentivesLoaded = Incentive.fromArray(response)

    // Sort pricingSchemes by priority, ascending
    this.loadedData.incentives = Utils.sortArrayOfObjects(incentivesLoaded, 'title')

    this.loadedDataReady.incentives = true
    if (callback) callback()
  },

  getIncentiveById: function (id) {
    return this.loadedData.incentives.find((incentive) => incentive.id === id)
  },

  getIncentiveByTitle: function (title) {
    return this.loadedData.incentives.find((incentive) => incentive.title === title)
  },

  getIncentivesAvailable: function () {
    return this.loadedData.incentives.filter((incentive) => !incentive.is_archived)
  },

  getPaymentOptionsById: function (id) {
    for (var i = 0, l = this.loadedData.paymentOptions.length; i < l; i++) {
      if (this.loadedData.paymentOptions[i].id === id) {
        return this.loadedData.paymentOptions[i]
      }
    }
  },

  getAutoAppliedCommissionByRole: function (roleUrl) {
    const autoAppliedCommission = this.getCommissionAvailable().find((commission) => {
      if (commission?.roles?.includes) {
        return commission.roles.includes(roleUrl)
      } else {
        return false
      }
    })
    return autoAppliedCommission?.id
  },
  getAutoAppliedPaymentOptions: function () {
    // auto apply any pmts with auto apply enabled except for these three scenarios
    // 1.) the product is not active in this project's state
    // 2.) it's a mosaic or sungage payment option but the user has not connected their role to the finco
    // 3.) it's a googleap product with channels and the role does not have access to that channel
    const projectState = window.WorkspaceHelper.project?.state
    let org = undefined
    let role = undefined
    let authState = JSON.parse(window.getStorage().getItem('auth'))
    const org_id = authState?.org_id
    org = JSON.parse(window.getStorage().getItem('org'))
    if (org && authState && authState.roles && authState.roles.length > 0) {
      role = authState.roles.find((r) => r.org_id === org_id)
    }
    const roleMosaicEnabled = role && role.mosaic_enabled ? role.mosaic_enabled : false
    const roleSungageEnabled = role && role.sungage_enabled ? role.sungage_enabled : false
    return (
      this.getPaymentOptionAvailable().filter((payment) => {
        let disabledForStateByIntegration = false
        let disabledDueToInactiveRole = false
        let disabledForChannels = false
        if (payment.configuration_json) {
          try {
            let integrationConfig = JSON.parse(payment.configuration_json)
            let states = integrationConfig?.states
            if (states && states.length > 0 && projectState && !states.includes(projectState)) {
              disabledForStateByIntegration = true
            }
            if (
              integrationConfig.integration === 'loanpal' &&
              integrationConfig.channels &&
              integrationConfig.channels?.length > 0 &&
              !org.ignore_loanpal_channels
            ) {
              if (!role.loanpal_channels) disabledForChannels = true
              else {
                let foundAMatch = false
                integrationConfig.channels?.forEach((channel) => {
                  if (role.loanpal_channels?.includes(channel)) foundAMatch
                })
                if (!foundAMatch) disabledForChannels = true
              }
            } else if (integrationConfig.integration === 'sungage' && !roleSungageEnabled)
              disabledDueToInactiveRole = true
            else if (integrationConfig.integration === 'mosaic' && !roleMosaicEnabled) disabledDueToInactiveRole = true
          } catch (ex) {}
        }
        return (
          payment.auto_apply_enabled &&
          !disabledForStateByIntegration &&
          !disabledDueToInactiveRole &&
          !disabledForChannels
        )
      }) || []
    )
  },

  getPaymentOptionAvailable: function () {
    return this.loadedData.paymentOptions.filter((payment) => !payment.is_archived)
  },

  getCommissionAvailable: function () {
    return this.loadedData.commissions
  },

  loadPaymentOptions: function (callback) {
    Designer.Ajax({
      url: this.formUrl('payment_options'),
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      context: this,
      success: function (response) {
        if (!response || response.length === 0) {
          //no worries, many orgs will not have any manual PaymentOptions created
        }

        this.loadPaymentOptionsSuccess(response, callback)
      },
      error: function (response) {
        console.log('loadPaymentOptionsSuccess error', response)
        Designer.loginPromptIfRequired(response)
      },
    })
  },

  loadPaymentOptionsSuccess: function (response, callback) {
    var paymentOptionsLoaded = PaymentOption.fromArray(response)

    // Sort pricingSchemes by priority, ascending
    this.loadedData.paymentOptions = Utils.sortArrayOfObjects(paymentOptionsLoaded, 'title')

    this.loadedDataReady.paymentOptions = true
    if (callback) callback()
  },

  loadCostings: function (callback) {
    Designer.Ajax({
      url: this.formUrl('costings'),
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      context: this,
      success: function (response) {
        if (!response || response.length === 0) {
          //no worries, many orgs will not have any manual Costing created
        }

        this.loadCostingsSuccess(response, callback)
      },
      error: function (response) {
        console.log('loadCostingsSuccess error', response)
        Designer.loginPromptIfRequired(response)
      },
    })
  },

  loadCostingsSuccess: function (response, callback) {
    var costingsLoaded = Costing.fromArray(response)

    // Sort pricingSchemes by priority, ascending
    this.loadedData.costings = Utils.sortArrayOfObjects(costingsLoaded, 'title')

    this.loadedDataReady.costings = true
    if (callback) callback()
  },

  loadCommissions: function (callback) {
    Designer.Ajax({
      url:
        API_BASE_URL +
        'orgs/' +
        window.getStorage().getItem('org_id') +
        '/commissions/?fieldset=list&limit=1000&show_archived=1',
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      context: this,
      success: function (response) {
        this.loadCommissionsSuccess(response, callback)
      },
      error: function (response) {
        console.log('loadCommissionsSuccess error', response)
        Designer.loginPromptIfRequired(response)
      },
    })
  },

  loadCommissionsSuccess: function (response, callback) {
    var commissionsLoaded = Commission.fromArray(response)

    // Sort pricingSchemes by priority, ascending
    this.loadedData.commissions = Utils.sortArrayOfObjects(commissionsLoaded, 'title')
    this.loadedDataReady.commissions = true
    if (callback) callback()
  },

  getAddersAvailable: function () {
    return this.loadedData.adders.filter((adder) => !adder.is_archived)
  },

  loadAdders: function (callback) {
    Designer.Ajax({
      url: this.formUrl('adders'),
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      context: this,
      success: function (response) {
        if (!response || response.length === 0) {
          //no worries, many orgs will not have any manual Adder created
        }

        this.loadAddersSuccess(response, callback)
      },
      error: function (response) {
        console.log('loadAddersSuccess error', response)
        Designer.loginPromptIfRequired(response)
      },
    })
  },

  loadAddersSuccess: function (response, callback) {
    var addersLoaded = Adder.fromArray(response)

    // Sort pricingSchemes by priority, ascending
    this.loadedData.adders = Utils.sortArrayOfObjects(addersLoaded, 'title')

    this.loadedDataReady.adders = true
    if (callback) callback()
  },

  getModuleData: function (moduleActivationId) {
    for (var i = 0; i < this.loadedData.componentModuleSpecs.length; i++) {
      if (this.loadedData.componentModuleSpecs[i].id === moduleActivationId) {
        return this.loadedData.componentModuleSpecs[i]
      }
    }

    if (window.TESTING !== true) {
      if (window.studioDebug) {
        console.log('Warning: AccountHelper:getModuleData() Warning: Module not found')
      }
    }
    const sharedProject = !!window.WorkspaceHelper.project?.shared_with?.length
    const defaultId = sharedProject ? (moduleActivationId ? moduleActivationId : null) : null

    return new ModuleType({
      id: defaultId,
      code: 'Unknown',
      technology: null,
      product_warranty: null,
      max_power_voltage: 0,
      voc: 0,
      imp: 0,
      isc: 0,
      noct: 0,
      cells_in_series: 0,
      cost: 0,
      kw_stc: 0,
      manufacturer_name: 'Unknown',
      quantity: 0,
      size: [0, 0],
      temp_coefficient_voc: 0,
      temp_coefficient_isc: 0,
      temp_coefficient_vpmax: 0,
      annual_degradation_override: 0,
      module_texture: 'default',
      substring_layout: 'standard_3_portrait_60_samples',
    })
  },

  getApiKey: function (mapLibrary) {
    // Used to inject an API key rather than loading from the Acccount.
    // e.g. Used by Project Page where org data is already loaded by React
    // But studio is only being used as a dumb map display
    //
    // Also used by UX2 to inject API Keys into the window from Redux
    // See ducks/auth.ts
    if (mapLibrary === 'google' && this.overrideApiKeyGoogle) {
      return this.overrideApiKeyGoogle
    } else if (mapLibrary === 'nearmap' && this.overrideApiKeyNearmap) {
      return this.overrideApiKeyNearmap
    } else if (mapLibrary === 'arcgisstreetmap' && this.overrideApiKeyArcGisOSM) {
      return this.overrideApiKeyArcGisOSM
    }

    try {
      return (
        AccountHelper.loadedData.org['api_key_' + mapLibrary] ||
        AccountHelper.loadedData.org['default_api_key_' + mapLibrary]
      )
    } catch (err) {
      if (window.studioDebug) {
        console.warn(
          'AccountHelper.getApiKey() could not get api key for: ' + mapLibrary + '. Proxy will be used if possible'
        )
      }
      return ''
    }
  },

  hasNearmapApiKey: function () {
    return Boolean(this.getApiKey('nearmap'))
  },

  hasNearmap: function () {
    if (this.hasNearmapApiKey()) {
      return true
    }

    //Quick shortcut for detecting whether Org is Nearmap On OpenSolar
    //In future this will not be sufficient if other types of external accounts are supported
    return this.hasNearmapOnOpenSolar()
  },
  // Injected from React to make this available in Studio without/before loading data from back-end
  hasNearmapOnOpenSolar: function () {
    if (window.hasNearmapOnOpenSolarInjectedFromReact) {
      return window.hasNearmapOnOpenSolarInjectedFromReact()
    }

    // New method for detecting NMOS before project/org is loaded, supplied by redux
    return Boolean(this.loadedData && this.loadedData.org && this.loadedData.org?.external_account_id)
  },
  isPro: function () {
    try {
      var roles = JSON.parse(window.getStorage().getItem('auth')).roles
      return roles.length > 0
    } catch (e) {}
    return false
  },
  getOrgCountryIso2: function () {
    return AccountHelper &&
      AccountHelper.loadedData &&
      AccountHelper.loadedData.org &&
      AccountHelper.loadedData.org.country_iso2
      ? AccountHelper.loadedData.org.country_iso2
      : null
  },
  getMeasurementUnits: function () {
    var measurementUnit = ''
    if (
      AccountHelper &&
      AccountHelper.loadedData &&
      AccountHelper.loadedData.org &&
      AccountHelper.loadedData.org.measurement_units
    ) {
      measurementUnit = AccountHelper.loadedData.org.measurement_units
    } else if (this.getOrgCountryIso2() === 'US') {
      measurementUnit = 'imperial'
    } else {
      measurementUnit = 'metric'
    }
    return measurementUnit
  },
  /*
  Format for each request:
  {
    message: 'Enable integrated inverter for Panel XYZ',
    codes: ['aaa','bbb']
  }
  */
  activateComponentsRequests: [],
  activateComponentsRequestsClearIfSatisfied: function () {
    var hasChanged = false
    for (var i = this.activateComponentsRequests.length - 1; i >= 0; i--) {
      let codesRequested = this.activateComponentsRequests[i].codes
      var codesMatched = 0
      codesMatched += this.loadedData.componentModuleSpecs.filter((c) => codesRequested.indexOf(c.code) !== -1).length
      codesMatched += this.loadedData.componentInverterSpecs.filter((c) => codesRequested.indexOf(c.code) !== -1).length
      codesMatched += this.loadedData.componentBatterySpecs.filter((c) => codesRequested.indexOf(c.code) !== -1).length
      codesMatched += this.loadedData.componentOtherSpecs.filter((c) => codesRequested.indexOf(c.code) !== -1).length

      if (codesMatched >= codesRequested.length) {
        this.activateComponentsRequests.splice(i, 1)
        hasChanged = true
      }
    }

    if (hasChanged) {
      // Force refresh of System Summary panel, in case component activation box should be cleared
      window.editor.signals.sceneGraphChanged.dispatch()

      // Force system refresh in case plugins should be re-run after components have been loaded
      window.editor.signals.objectChanged.dispatch(window.this.system)
    }
  },
  activateComponents: function (componentCodes, callback, callbackError) {
    $.ajax({
      type: 'POST',
      url: API_BASE_URL + 'orgs/' + window.getStorage().getItem('org_id') + '/activate_components/',
      headers: Utils.tokenAuthHeaders(),
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({
        codes: componentCodes,
      }),
      context: this,
      success: function (response) {
        // Future improvement
        // Load component details into Studio so we can use immediately without needing to load components again
        // Currently not working beacuse the response will not include exhibit content
        // window.AccountHelper.loadedData.componentModuleSpecs.push(new window.ModuleType(response.data))
        if (response['already_activated'] && response['already_activated'].length > 0) {
          console.log('Components already activated', response['already_activated'])
        }

        Designer.showNotification(componentCodes.length > 1 ? 'Components activated' : 'Component activated')

        // var callback = () => {
        //   // Ensure plugins can trigger after newly-added specs are loaded
        //   editor.signals.objectChanged.dispatch(this.system)
        // }
        window.AccountHelper.loadComponentSpecs(callback)
      },
      error: function (response) {
        console.log('activateComponent error', response)
        var errorMessage =
          response.responseJSON && response.responseJSON.detail
            ? response.responseJSON.detail
            : 'Error activating components'
        Designer.showNotification(window.translate(errorMessage), 'error')
        Designer.loginPromptIfRequired(response)

        if (callbackError) {
          callbackError()
        }
      },
    })
  },

  debouncedActivateComponents: window.Utils.debounce(function (componentCodes, callback) {
    window.reduxStore?.dispatch({
      type: 'COMPONENT_ACTIVATION_REQUEST',
      payload: {
        codes: componentCodes,
        callback,
      },
    })
  }, 400),
})

var AccountHelper = new AccountHelperClass()
