/**
 * @author adampryor
 */

function OsFacet(options) {
  this.gf = new jsts.geom.GeometryFactory()

  THREE.Object3D.call(this)

  this.type = 'OsFacet'
  this.vertices = options && options.nodes ? options.nodes : []
  this.mesh = null
  this.objectsFloating = []
  this.centroid = null
  this.plane = new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), new THREE.Vector3())
  this.azimuth = null
  this.slope = null
  this._roofTypeId = options && options.roofTypeId ? options.roofTypeId : AccountHelper.getRoofTypeDefault().id
  this._wallTypeId = options && options.wallTypeId ? options.wallTypeId : AccountHelper.getWallTypeDefault().id

  // Store actualt texture values which are derived from rootType & wallType so we still know the texture
  // even when we have not loaded the roofTypes
  this.roofTexture = options?.roofTexture || this.roofType()?.texture || null
  this.wallTexture = options?.wallTexture || this.wallType()?.texture || null

  this._snapToTerrain = options && options.hasOwnProperty('snapToTerrain') ? options.snapToTerrain : true

  this.area = null
  this.derivedFromFacet = (options && options.derivedFromFacet) || null

  this.isLocked = options && options.hasOwnProperty('isLocked') ? options.isLocked : false

  if (options && options.fromJSON === true && !options.nodes) {
    // @TODO: Untanble this later, but for now we will just populate options.nodes from JSON
    // Perhaps this should only be applied when re-doing/un-doing and not during initial scene loading?
    var _nodes = options.userData.vertices.map((uuidNode) => editor.objectByUuid(uuidNode))
    if (_nodes.some((n) => !n)) {
      console.warn(
        'Nodes could not be found to attach to facet... perhaps they are not yet loaded in the scene? Do not overwrite userData.nodes or options.nodes. They should be re-linked later'
      )
    } else {
      options.nodes = _nodes
    }
  }

  if (options && options.nodes) {
    options.nodes.forEach(function (node) {
      node.addToFacet(this)
      // editor.execute(new AddNodeToFacetCommand(node, this))
    }, this)
  }

  this.refreshUserData()
  this.refreshName()

  //Beware: Enabling line caching causes unknown problems. e.g. Click handlers get stale etc.
  this.edgesAsLine3CachedEnabled = false
  this.edgesAsLine3Cached = null

  this.azimuthOverride = null
  this.slopeOverride = null
  this.obstruction = typeof options?.obstruction !== 'undefined' ? Boolean(options.obstruction) : false

  //Do not save hash yet... only after calling onChange because that will wire-up floaters
  //this.saveHash()

  this.deletedByUserAction = false

  this.isManaged = options && options.hasOwnProperty('isManaged') ? options.isManaged : false

  // record the highest number of views that were active when any edit was made
  this.maxImageryPerspectivesWhenUpdated =
    options && options.hasOwnProperty('maxImageryPerspectivesWhenUpdated')
      ? options.maxImageryPerspectivesWhenUpdated
      : 0

  this.MAX_HEIGHT = 100
}

OsFacet.userDataValidators = {
  vertices: (value) => value.every((uuid) => typeof uuid === 'string'),
  objectsFloating: (value) => value.every((uuid) => typeof uuid === 'string'),
  slope: (value) => typeof value === 'number',
  azimuth: (value) => typeof value === 'number',
  roofTypeId: (value) => typeof value === 'number',
  wallTypeId: (value) => typeof value === 'number',
  roofTexture: (value) => typeof value === 'string',
  wallTexture: (value) => typeof value === 'string',
}

OsFacet.prototype = Object.assign(Object.create(THREE.Object3D.prototype), {
  constructor: OsFacet,
  toolsActive: function () {
    return {
      translateXY: !this.isManaged,
      translateZ: !this.isManaged,
      translateX: false,
      rotate: false,
      scaleXY: false,
      scaleZ: false,
      scale: false, //legacy
      delete: !this.isManaged,
      duplicate: !this.isManaged,
    }
  },
  _hash: null,
  getHash: ObjectBehaviors.changeDetection.getHash,
  belongsToGroup: ObjectBehaviors.belongsToGroup,
  saveHash: ObjectBehaviors.changeDetection.saveHash,
  clearHash: ObjectBehaviors.changeDetection.clearHash,
  hasChanged: ObjectBehaviors.changeDetection.hasChanged,

  getTransformTargets: function () {
    return this.vertices
  },

  asObject: function () {
    return {
      azimuth: this.azimuth,
      slope: this.slope,
      azimuthOverride: this.azimuthOverride,
      slopeOverride: this.slopeOverride,
      centroid: this.centroid ? this.centroid.toArray() : null,
      position: this.position.toArray(),
      vertices: this.vertices.map(function (v) {
        return [v.uuid, v.position.toArray()]
      }),
      edges: this.getEdges().map(function (e) {
        //edge positions already determined by vertices, only need to include edgeType
        return e.edgeType
      }),
      objectsFloating: this.objectsFloating
        ? this.objectsFloating.map(function (o) {
            return o.uuid
          })
        : null,
      area: this.area,
      roofTypeId: this.roofTypeId(),
      wallTypeId: this.wallTypeId(),
      snapToTerrain: this.snapToTerrain(),
    }
  },
  roofTypeId: function (value) {
    if (typeof value === 'undefined') {
      return this._roofTypeId
    } else if (value !== this._roofTypeId) {
      this._roofTypeId = value
    }
  },
  roofType: function () {
    return AccountHelper.getRoofTypeById(this.roofTypeId())
  },
  wallTypeId: function (value) {
    if (typeof value === 'undefined') {
      return this._wallTypeId
    } else if (value !== this._wallTypeId) {
      this._wallTypeId = value
    }
  },
  wallType: function () {
    return AccountHelper.getWallTypeById(this.wallTypeId())
  },
  snapToTerrain: function (value) {
    if (typeof value === 'undefined') {
      return this._snapToTerrain
    } else if (value !== this._snapToTerrain) {
      this._snapToTerrain = value
    }
  },
  refreshVisibility: function () {
    if (!this.mesh) {
      return
    }

    //If facet selected or group which contains this facet then show helpers, otherwise hide
    var helperVisibility

    if (!Designer.showFacetsOnActiveMapType()) {
      helperVisibility = false
    } else if (
      editor.selected &&
      (editor.selected === this ||
        this.belongsToGroup(editor.selected) ||
        (editor.selected.type === 'OsGroup' &&
          editor.selected.objects.some(
            (object) => object.type === 'OsNode' && this.vertices.some((node) => node.uuid === object.uuid)
          )) ||
        (editor.selected.type === 'OsStructure' && editor.selected.facets.indexOf(this) !== -1) ||
        (editor.selected.type === 'OsEdge' && editor.selected.getFacets().indexOf(this) !== -1) ||
        (editor.selected.type === 'OsNode' && editor.selected.getFacets().indexOf(this) !== -1) ||
        (editor.selected.type === 'OsModuleGrid' && editor.selected.facet === this))
    ) {
      helperVisibility = true
    } else {
      helperVisibility = false
    }

    // Show/hide nodes and edges too
    this.vertices.filter((v) => !v.ghostMode()).forEach((v) => (v.visible = helperVisibility))
    // this.getEdges().forEach(e => (e.visible = helperVisibility))
  },

  isNonSpatial: function () {
    return this.vertices.length === 0
  },

  vertexPositions: function () {
    return this.vertices.map(function (v) {
      return v.position
    })
  },

  vertexPositionsRelativeToCentroid: function () {
    return this.vertices.map(function (v) {
      return new THREE.Vector3().subVectors(v.position, this.centroid)
    }, this)
  },

  getFacets: function () {
    return [this]
  },

  verticesProjectedOntoPlane: function (positions) {
    //@todo: What should this return... null? Or throw exception?
    if (!this.plane) {
      return null
    }
    return positions.map(function (p) {
      //Old incorrect method, this foreshortens the plane and distorts x and y values,
      //when it should only set the Z value
      //return this.plane.projectPoint(p)

      const z = this.zForXY(p.x, p.y)
      return new THREE.Vector3(p.x, p.y, z)
    }, this)
  },

  refreshMesh: function (editor) {
    editor.uiPause('render', 'osFacet.refreshMesh')
    try {
      this.deleteFacetMesh()

      //With only 2 vertices special case, draw line with no setbacks
      if (this.vertices.length < 2) {
        //Insufficient vertices for mesh or even a line
        editor.uiResume('render', 'osFacet.refreshMesh')
        return
      }

      //Make planar by projecting vertices onto regression Plane.

      var verticesOnPlane = this.verticesProjectedOntoPlane(this.vertexPositions())

      this.mesh = new OsFacetMesh(this, verticesOnPlane)
      this.mesh.name = 'OsFacetMesh'

      //editor.execute(new AddObjectCommand(this.mesh, this, false));
      editor.addObject(this.mesh, this)
    } catch (err) {
      console.log('refreshMesh error', err)
      //@todo: Try to recover...e.g. clear our duplicate vertices?

      //@todo: Prevent this from happening in the first place!
      var uniqueVertices = []
      this.vertices.forEach(function (v) {
        if (OsNode.nodeForCoordinates(uniqueVertices, v.position.x, v.position.y, v.position.z)) {
          //delete it
          v.removeFromFacet(this)
          console.log('  - removing duplicate node from facet')
        } else {
          uniqueVertices.push(v)
        }
      }, this)
    }
    editor.uiResume('render', 'osFacet.refreshMesh')
  },
  line2DFromCoordinates: function (coordinates) {
    return this.gf.createLineString(
      coordinates.map(function (c) {
        return new jsts.geom.Coordinate(c[0], c[1])
      })
    )
  },

  onSelect: function (editor) {
    var edges = this.getEdges()
    edges.forEach((edge) => edge.refreshLine())
  },

  onDeselect: function (editor) {
    var edges = this.getEdges()
    edges.forEach((edge) => edge.refreshLine())
  },

  confirmBeforeDelete: function () {
    return 'Are you sure you want to delete this facet?'
  },

  alignViewOnChange: function (newValue) {
    var isPlacingFirstFacet = editor.filter('type', 'OsFacet').length === 1 && newValue === true
    var isUndoingPlaceFirstFacet = editor.filter('type', 'OsFacet').length === 0 && newValue === false
    if (isUndoingPlaceFirstFacet || isPlacingFirstFacet) {
      editor.setViewAligned(newValue)
      return true
    } else {
      return false
    }
  },

  shapesWithSetbacksJSTS: function (useSetbacks) {
    // Create 3D planar polygon
    // Make 2D by zeroing Z coordinate
    // Create setback region by buffering each line with it's appropriate setback distance
    // Combine setbacks into a single shape
    // Subtract setbacks from main region
    // Return both the remaining (multi-)polygon and the setbacks (multi-)polygon

    var verticesOnPlane = this.verticesProjectedOntoPlane(this.vertexPositions())

    //Use JSTS for speed (and similarity with shapely)
    var planarPolygon2D = Utils.polygon2DFromCoordinates(
      verticesOnPlane.map(function (v) {
        return v.toArray()
      })
    ) //ignores Z axis

    if (!useSetbacks) {
      return {
        facetShape: planarPolygon2D,
        facetClippedShape: null,
        setbacksShape: null,
      }
    }

    var edges = this.getEdges()

    var edgesBuffered = _.range(verticesOnPlane.length)
      .map(function (i) {
        var edge = this.getEdgeWithNodes([this.vertices[i], this.vertices[(i + 1) % this.vertices.length]])

        var setbackDistance = edge ? edge.getSetbackDistance() : null

        //Foreshorten setbacks by slope, based on how similar the line is to the azimuth direction

        //If no setbacks then return null and this will be filtered from the array
        if (!setbackDistance) {
          return null
        }

        //wraps last coordinate back to first
        var line = this.line2DFromCoordinates([
          verticesOnPlane[i].toArray(),
          verticesOnPlane[(i + 1) % verticesOnPlane.length].toArray(),
        ])

        var azimuthVector = new THREE.Vector3(0, 1, 0).applyAxisAngle(
          new THREE.Vector3(0, 0, 1),
          this.azimuth * THREE.Math.DEG2RAD
        )
        //console.log('azimuthVector', azimuthVector);

        var lineDirectionVector = new THREE.Line3(verticesOnPlane[i], verticesOnPlane[(i + 1) % verticesOnPlane.length])
          .delta(new THREE.Vector3())
          .normalize()
        //console.log('lineDirectionVector', lineDirectionVector);

        //console.log('dot', Math.abs(azimuthVector.dot(lineDirectionVector)));

        var setbackDistanceForeshortenedFactor = 1 - Math.abs(azimuthVector.dot(lineDirectionVector))
        //console.log('setbackDistanceForeshortenedFactor', setbackDistanceForeshortenedFactor);

        var setbackDistanceForeshortened = Utils.foreshortenFactor(this.slope) * setbackDistance
        //console.log('setbackDistanceForeshortened', setbackDistanceForeshortened);

        var lineSetbackArea = line.buffer(setbackDistanceForeshortened)
        var precisionModel = new jsts.geom.PrecisionModel(1000) // nearest 1 mm
        var geometryPrecisionReducer = new jsts.precision.GeometryPrecisionReducer(precisionModel)
        var lineSetbackAreaReduced = geometryPrecisionReducer.reduce(lineSetbackArea, precisionModel)

        return lineSetbackAreaReduced
      }, this)
      .filter(Boolean)

    // Also subtract a) obstruction facets and b) dormers and add setbacks to them too
    // For now calculate all the obstructions, merge them and add to edgesBuffered and subtract them all together
    // We may want to actually test each obstruction and ignore some if they cause geometry problems.
    if (
      window.WorkspaceHelper.developerMode() === true &&
      !this.obstruction &&
      this.selectionDelegate?.type !== 'OsStructure'
    ) {
      var obs = editor.filterObjects((f) => f.obstruction || f.selectionDelegate?.type === 'OsStructure')

      // No setbacks for dormers/obstructions until we determine if they are often used.
      // var obsShapes = obs
      //   .map((o) => {
      //     var rawFacetShape = o.shapesWithSetbacksJSTS(false).facetShape
      //     // There is no correspnding edgeType yet for dormers/obstructions
      //     // var setbackDistance = o.setbackDistanceForEdgeType(edge.edgeType)
      //     // Do we need to consider foreshortening? In one axis or two?
      //     // var setbackDistance = 0.3
      //     // return rawFacetShape.buffer(setbackDistance).difference(rawFacetShape)
      //   })
      //   .filter((obShape) => {
      //     // @TODO: This check should not be required, but for some reason if the obstructions do not intersect with an existing edge
      //     // then the result of `this.gf.createGeometryCollection(obsShapes).union()` is invalid and returns multiple shapes/geometries instead of
      //     // a single shape which unifies all the shapes and holes. Currently this means that if the obShape does not intersect with any setback edge
      //     // then it will be omitted and that setback will not be added.
      //     // This is also very slow.
      //     return edgesBuffered.some((eb) => eb.intersects(obShape))
      //   })

      // Simpler version with no setback buffers
      var obsShapes = obs.map((o) => {
        var rawFacetShape = o.shapesWithSetbacksJSTS(false).facetShape
      })

      var obsShapesUnified = this.gf.createGeometryCollection(obsShapes).union()
      edgesBuffered.push(obsShapesUnified)
    }

    var setbacksClip = null

    // This can fail for some topologies, just ignore in those cases.
    try {
      setbacksClip = this.gf.createGeometryCollection(edgesBuffered).union()
    } catch (e) {
      console.warn(e)
    }

    //Applying setbacks can cause errors on some polygons. Recover gracefully by skipping shapes with issues.

    var setbacksShape = null
    var facetClippedShape = null

    try {
      setbacksShape = setbacksClip ? setbacksClip.intersection(planarPolygon2D) : null
      facetClippedShape = setbacksShape ? planarPolygon2D.difference(setbacksShape) : null
    } catch (err) {
      console.log(err)
    }

    return {
      facetShape: planarPolygon2D,
      facetClippedShape: facetClippedShape,
      setbacksShape: setbacksShape,
    }
  },

  toGeoJSONFeature: function (sceneOrigin4326, toEpsg, obstructions, useSetbacks) {
    if (this.vertices.length < 3) {
      return null
    }

    if (!toEpsg) toEpsg = '4326'

    //@todo: Since 3857 is kinda dodgy, we should probably use something different
    //for adding scene positions in meters to scene origin
    //e.g. UTM? Annoying with different zones...

    var sceneOrigin3857 = Utils.reprojectCoordinate(sceneOrigin4326, 4326, 3857)

    // Note that shapesWithSetbacksJSTS()['facetClippedShape'] can be a multi-polyon
    // if setbacks clip the polygon facet into two polygons
    // And if it self-intersects it may return null instead of a polygon
    var shapes = this.shapesWithSetbacksJSTS(useSetbacks)
    var shape = shapes.facetClippedShape || shapes.facetShape

    if (!shape) {
      console.warn('OsFacet.toGeoJSONFeature() error: shape is null, is this a valid polygon? Abort.')
      return null
    }

    var transformCoordinatesFilter = {
      interfaces_: function () {
        // Hack to work around interface checks in jsts
        // See open question https://gis.stackexchange.com/questions/292726/how-to-transform-coordinates-for-a-geometry-in-javascript-topology-suite-jsts
        return {
          indexOf: function () {
            return true
          },
        }
      },
      filter: function (coord) {
        var newCoord = Utils.reprojectCoordinate(
          [coord.x + sceneOrigin3857[0], coord.y + sceneOrigin3857[1]],
          3857,
          toEpsg
        )
        coord.x = newCoord[0]
        coord.y = newCoord[1]
      },
    }

    shape.apply(transformCoordinatesFilter)

    var GeoJSONWriter = new jsts.io.GeoJSONWriter()
    var shapeGeoJSON = GeoJSONWriter.write(shape)

    var facetGeoJSON = {
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:EPSG::' + toEpsg,
        },
      },
      type: 'Feature',
      properties: {
        azimuth: this.azimuth,
        slope: this.slope,
      },
      geometry: shapeGeoJSON,
    }

    if (obstructions && obstructions.length) {
      obstructions.forEach(function (o) {
        var obstructionGeoJSON = o.toGeoJSONFeature(sceneOrigin4326, toEpsg, null, false)

        //TurfJS turf.difference() seems to be broken at fine precisions
        //facetGeoJSON = turf.difference(facetGeoJSON, obstructionGeoJSON);

        facetGeoJSON = differenceGeoJSON(facetGeoJSON, obstructionGeoJSON)
      })
    }

    return facetGeoJSON
  },

  addFloatingObject: function (object) {
    if (!object) {
      console.log('Error: trying to facet.addFloatingObject with an empty object!')
      return
    }

    if (this.objectsFloating.indexOf(object) === -1) {
      this.objectsFloating.push(object)
    }
    object.floatingOnFacet = this
    object.facet = this
    this.refreshUserData()
  },

  removeFloatingObject: function (object) {
    if (this.objectsFloating.indexOf(object) != -1) {
      this.objectsFloating.splice(this.objectsFloating.indexOf(object), 1)
    }

    object.facet = null
    object.floatingOnFacet = null
    if (object.refreshUserData) {
      object.refreshUserData()
    }

    this.refreshUserData()
  },

  clearAssociatedObject: function () {
    this.objectsFloating.forEach(function (floatingObject) {
      if (floatingObject.type === 'OsModuleGrid') {
        floatingObject.facet = null
        floatingObject.floatingOnFacet = null
      }
    })
  },

  calculateFloatingPosition: function (x, y, raycaster) {
    //Floating based on plane, not mesh intersection
    return OsFacet.calculateFloatingPositionOnPlane(x, y, raycaster, this.plane)
  },

  floatObject: function (object, floatPosition) {
    if (this.isNonSpatial()) return

    if (!floatPosition) {
      floatPosition = this.calculateFloatingPosition(object.position.x, object.position.y)
    }

    if (floatPosition) {
      var isUpdated = false

      if (object.elevationAuto !== false) {
        if (object.position.z != floatPosition.z) {
          object.position.z = floatPosition.z
          isUpdated = true
        }
      }

      //Set orientation unless object specifically disables it
      if (object.floatAppliesOrientation !== false) {
        //Azimuth for tilt racks are applied here, whereas slope for tilt racks is applied inside module.applyTiltRacks()
        // @TODO: We should ensure that any object with floatAppliesOrientation==true must implement getAzimuth() and getSlope()
        var azimuthToApply = object.azimuthAuto ? this.azimuth : object.getAzimuth()
        var slopeToApply = object.slopeAuto ? this.slope : object.getSlope()

        // Should we auto-apply TILT_RACKS and override=20???
        // if (object.slopeAuto && slopeToApply < 10 && object.panelTiltOverride === null) {
        //   // Do not overwrite panelTiltOverride if it's already set otherwise you can't change it.
        //   object.panelConfiguration = 'TILT_RACK'
        //   object.panelTiltOverride = 20
        // }

        // OsStructure azimuth is calculated the opposite to OsPanelGroup
        if (object.type === 'OsStructure') {
          azimuthToApply += 180
        }

        Utils.applyOrientation(object, azimuthToApply, slopeToApply)

        if (object.type === 'OsModuleGrid') {
          //object.applyTiltRacks();
          object.setSize()

          // Be sure to set skipFloatingOnFacetOnChange to avoid infinite recursion
          object.onChange(editor, true, true)
        }
      }

      if (isUpdated && object.type === 'OsNode' && editor) {
        var snapping = false

        //Disable onChange as a result of reFloating!
        //object.onChange(editor, snapping);

        object.propagateZAlongFlatEdges()

        //Only update Edges (which don't propagate any futerh)
        object.refreshEdges()
      } else if (isUpdated && object.type === 'OsStructure' && editor) {
        object.onChange(editor)
      }
    }
  },

  refloatObjects: function (editor) {
    this.objectsFloating.forEach(function (object) {
      var isFloating = false
      var floatPosition = null

      //Raycaster can be used for both plane and mesh intersections
      var raycaster = new THREE.Raycaster(
        new THREE.Vector3(object.position.x, object.position.y, 1000),
        new THREE.Vector3(0, 0, -1)
      )

      //Floating nodes are always floating even if not directly above the mesh
      if (object.type === 'OsNode') {
        isFloating = true
      } else {
        if (!this.mesh) {
          //console.log('Unable to test object for floating refloatObjects() because this.mesh is null')

          //facet requires mesh to be present. If not preset, create it
          console.log('Force refreshMesh in objectsFloating')
          this.refreshMesh(editor)
        }

        if (!this.mesh) {
          console.log('Unable to test object for floating refloatObjects() because this.mesh is null')
        } else {
          //Vertical ray shooting down onto objects xy position

          this.mesh.updateWorldMatrix()

          var facetMeshIntersection = raycaster.intersectObject(this.mesh)

          if (facetMeshIntersection.length && facetMeshIntersection[0].point) {
            isFloating = true
            //Float based on the mesh (deprecated)
            //floatPosition = facetMeshIntersection[0].point;
          }
        }
      }

      if (isFloating) {
        this.floatObject(object, floatPosition)
      }
    }, this)
  },

  verticesNotFloatingOnThisFacet: function () {
    return this.vertices.filter(function (v) {
      return v.floatingOnFacet != this
    }, this)
  },
  verticesNotFloating: function () {
    return this.vertices.filter(function (v) {
      return v.floatingOnFacet == null
    }, this)
  },
  verticesFloating: function () {
    return this.vertices.filter(function (v) {
      return v.floatingOnFacet != null
    }, this)
  },

  refreshMaxImageryPerspectivesWhenUpdated: function (commandUUID) {
    /*
    Performance improvement: Do not bother incrementing any further if the count >= 2. This avoids the need to keep
    counting the number of views after we already know it has exceeded 1.
   */
    if (this.maxImageryPerspectivesWhenUpdated >= 2) {
      return
    }

    let countUniqueImageryPerspectives = Designer.countUniqueImageryPerspectives()
    if (countUniqueImageryPerspectives > this.maxImageryPerspectivesWhenUpdated) {
      /*
      We should not create a new command for this because when we undo it, it should be
      grouped with the change that actually resulted in the change so we will always require
      commandUUID to be supplied
      */

      return new window.SetValueCommand(
        this,
        'maxImageryPerspectivesWhenUpdated',
        countUniqueImageryPerspectives,
        commandUUID,
        true
      )
    } else {
      return
    }
  },

  refreshPosition: function () {
    if (!this.vertices.length) {
      //Should we perhaps set this to null instead, to indicate it does not have a valid centroid?
      this.centroid = new THREE.Vector3()
      return
    }
    this.centroid = new THREE.Vector3()
    this.vertices.forEach(function (v) {
      this.centroid.add(v.position)
    }, this)
    this.centroid = this.centroid.divideScalar(this.vertices.length)
    this.position.copy(this.centroid)
  },

  refreshPlane: function () {
    var verticesForPlaneCalculation = []

    // Beware: This could create a cycle with infinite recursion.
    var verticesNotFloatingOnThisFacet = this.verticesNotFloatingOnThisFacet()

    if (verticesNotFloatingOnThisFacet.length >= 3) {
      verticesForPlaneCalculation = verticesNotFloatingOnThisFacet
    } else {
      verticesForPlaneCalculation = []
    }

    var points
    if (verticesForPlaneCalculation.length === 0) {
      points = [this.position.toArray()]
    } else {
      points = verticesForPlaneCalculation.map(function (v) {
        return v.position.toArray()
      })
    }
    var allSameZ = points.every(function (p) {
      return Math.abs(p[2] - points[0][2]) < 0.01
    })

    const fitPlane = OsFacet.planeFromPoints(points)
    const fitPlaneSlope = OsFacet.slopeForNormal(fitPlane.normal)

    //Handle flat planes manually to prevent asymptotic error
    if (allSameZ) {
      this.azimuth = this.azimuthOverride || this.estimateAzimuthFromGutters()
      this.slope = this.slopeOverride || 0
      this.plane = OsFacet.planeFromPoints(points, this.azimuth, this.slope)
      return
    }

    if (fitPlaneSlope < window.USE_TILT_RACK_THRESHOLD_SLOPE && !this.isManaged) {
      // calculate the azimuth as if it's a completely flat roof
      // this is only done for roof facets that are NOT "managed" (e.g. not part of a quick roof)
      this.azimuth = this.azimuthOverride || this.estimateAzimuthFromGutters()
      this.slope = fitPlaneSlope
      this.plane = OsFacet.planeFromPoints(points, this.azimuth, this.slope)
    } else {
      // the slope is more than the threshold
      // calculate azimuth and slope based on the regression plane orientation
      this.plane = OsFacet.planeFromPoints(points, this.azimuthOverride, this.slopeOverride)
      this.azimuth = OsFacet.azimuthForNormal(this.plane.normal)
      this.slope = OsFacet.slopeForNormal(this.plane.normal)
    }
  },

  getAzimuthVector: function () {
    return new THREE.Vector3(0, 1, 0).applyAxisAngle(new THREE.Vector3(0, 0, -1), this.azimuth * THREE.Math.DEG2RAD)
  },

  estimateAzimuthFromGutters: function () {
    const edgesFlatBasedOnEdgeType = this.getEdges().filter((e) => SetbacksHelper.edgeTypeIsFlat(e.edgeType))
    const hemisphere = window.SceneHelper.getHemisphere()

    const isFacingEquator = (azimuth) => {
      if (azimuth === 90 || azimuth === 270) {
        // for azimuths facing perfectly east or perfectly west
        // we can't reliably classify them so we just pretend they are "facing" the equator
        return true
      }
      if (hemisphere === 'north') {
        // must be facing south
        return azimuth > 90 && azimuth < 270
      }
      if (hemisphere === 'south') {
        // must be facing north
        return azimuth > 270 || azimuth < 90
      }
    }

    const flipAzimuth = (azimuth) => {
      return (azimuth + 180) % 360
    }

    const getEdgeAzimuthFromFacetCenter = (edge) => {
      // We compute first the azimuth that will lead us from the facet's center
      // to the edge's centroid, so we know the 'general direction' that will point us to the edge
      const facetCenterToEdgeCentroidAzimuth = OsFacet.azimuthForNormal(
        edge.getCentroid().sub(this.centerOfMass()).normalize()
      )
      const facetCenterToEdgeCentroidNormal = new THREE.Vector2(
        Math.cos(facetCenterToEdgeCentroidAzimuth * THREE.Math.DEG2RAD),
        Math.sin(facetCenterToEdgeCentroidAzimuth * THREE.Math.DEG2RAD)
      )
      // We then compute the azimuth that's perpendicular to the edge
      // This computation may return the actual azimuth we want OR the azimuth that points the opposite way
      // The one we get depends on the drawing order of the two points of edge (which is not simple to determine)
      // Since the formula involves subtracting the edge's two points, it is directional in nature
      const edgeAzimuth = (OsFacet.azimuthForNormal(edge.delta().normalize()) + 90) % 360
      const edgeAzimuthNormal = new THREE.Vector2(
        Math.cos(edgeAzimuth * THREE.Math.DEG2RAD),
        Math.sin(edgeAzimuth * THREE.Math.DEG2RAD)
      )
      // This approach is not perfect, but is simple and will work in most real-world flat roof shapes
      // we test the edge azimuth we got and see if it's pointing in the same general direction
      // as the azimuth that will lead us from the facet's center to the edge's centroid
      // if it's not, we flip the edge azimuth
      return edgeAzimuthNormal.dot(facetCenterToEdgeCentroidNormal) < 0 ? flipAzimuth(edgeAzimuth) : edgeAzimuth
    }

    if (edgesFlatBasedOnEdgeType.length) {
      const flatGutters = edgesFlatBasedOnEdgeType.filter((e) => e.edgeType === 'flat_gutter')
      if (flatGutters.length > 0) {
        // the azimuth must point TOWARDS a flat gutter
        // choose the longest one if there are many
        const longestFlatGutter = OsEdge.longestEdge(flatGutters)
        const edgeAzimuth = getEdgeAzimuthFromFacetCenter(longestFlatGutter)
        return isFacingEquator(edgeAzimuth) ? edgeAzimuth : flipAzimuth(edgeAzimuth)
      }

      const gutters = edgesFlatBasedOnEdgeType.filter((e) => e.edgeType === 'gutter')
      if (gutters.length > 0) {
        // the azimuth must point TOWARDS a gutter
        // choose the longest one if there are many
        const longestGutter = OsEdge.longestEdge(gutters)
        const edgeAzimuth = getEdgeAzimuthFromFacetCenter(longestGutter)
        return isFacingEquator(edgeAzimuth) ? edgeAzimuth : flipAzimuth(edgeAzimuth)
      }

      const ridges = edgesFlatBasedOnEdgeType.filter((e) => e.edgeType === 'ridge')
      if (ridges.length > 0) {
        // the azimuth must point AWAY from a ridge
        // choose the longest one if there are many
        const longestRidge = OsEdge.longestEdge(ridges)
        const edgeAzimuth = getEdgeAzimuthFromFacetCenter(longestRidge)
        return (edgeAzimuth + 180) % 360
      }
    } else {
      // no edges are classified as flat gutter or gutter or ridge
      // that we can use as basis to compute an azimuth
      // we choose one edge that's facing the equator the most
      const optimalAzimuth = SceneHelper.getHemisphere() === 'north' ? 180 : 0
      const optimalAzimuthAsNormal = new THREE.Vector2(
        Math.cos(optimalAzimuth * THREE.Math.DEG2RAD),
        Math.sin(optimalAzimuth * THREE.Math.DEG2RAD)
      )

      const edgeAzimuths = this.getEdges().map((edge) => {
        return getEdgeAzimuthFromFacetCenter(edge)
      })

      // for each edge azimuth, compute the dot product relative to the azimuth facing the equator
      // the edge with the highest dot product is the edge that has the least angle
      // relative to the optimal azimuth, and is used as basis for the facet's azimuth
      const selectedAzimuth = edgeAzimuths.reduce(
        (bestSoFar, edgeAzimuth) => {
          const edgeAzimuthAsNormal = new THREE.Vector2(
            Math.cos(edgeAzimuth * THREE.Math.DEG2RAD),
            Math.sin(edgeAzimuth * THREE.Math.DEG2RAD)
          )
          const currentDotProduct = optimalAzimuthAsNormal.dot(edgeAzimuthAsNormal)
          if (currentDotProduct > bestSoFar.dotProduct) {
            // new best
            return { dotProduct: currentDotProduct, azimuth: edgeAzimuth }
          } else {
            return bestSoFar
          }
        },
        { dotProduct: Number.NEGATIVE_INFINITY, azimuth: optimalAzimuth + 180 }
      ).azimuth
      return selectedAzimuth
    }
  },

  getSummary: function () {
    return 'Azimuth:' + Math.round(this.azimuth) + ', Slope:' + Math.round(this.slope) + ''
  },

  getName: function () {
    return (
      'Facet (Azimuth:' +
      Math.round(this.azimuth) +
      ', Slope:' +
      Math.round(this.slope) +
      ', Modules:' +
      this.moduleQuantity() +
      ')'
    )
  },

  refreshName: function () {
    this.name = this.getName()
  },

  userDataValidators: OsFacet.userDataValidators,

  refreshUserData: function (opts) {
    // If roof/wall types are not available then keep existing userData because the roofType
    // will not be available to lookup based on roofTypeId/wallTypeId

    var roofTexture

    if (this.roofType()) {
      roofTexture = this.roofType().texture
    } else if (!this.roofTypeId() && AccountHelper && AccountHelper.getRoofTypeDefault) {
      // roofTypeId is not set so we can safely detect texture from default roof type
      roofTexture = AccountHelper.getRoofTypeDefault().texture
    } else {
      //We have a roof type id set, but we were not able to get the roofType so in this case
      // the best approach is to re-use the value already saved into userData
      // This should only happen if we refreshUserData when rendering the design without
      // having roof-types loaded, in which case we should use the saved roofTexture value
      // and avoid over-writing it.
      roofTexture = AccountHelper.getRoofTypeDefault().texture
    }

    var wallTexture

    if (this.wallType()) {
      wallTexture = this.wallType().texture
    } else if (!this.roofTypeId() && AccountHelper && AccountHelper.getWallTypeDefault) {
      // wallTypeId is not set so we can safely detect texture from default wall type
      wallTexture = AccountHelper.getWallTypeDefault().texture
    } else {
      //We have a wall type id set, but we were not able to get the wallType so in this case
      // the best approach is to re-use the value already saved into userData
      // This should only happen if we refreshUserData when rendering the design without
      // having wall-types loaded, in which case we should use the saved wallTexture value
      // and avoid over-writing it.
      wallTexture = AccountHelper.getWallTypeDefault().texture
    }

    const newUserData = {
      vertices: this.vertices.map(function (o) {
        return o.uuid
      }),
      objectsFloating: this.objectsFloating.map(function (o) {
        return o.uuid
      }),

      slope: this.slope,
      azimuth: this.azimuth,

      slopeOverride: this.slopeOverride,
      azimuthOverride: this.azimuthOverride,
      obstruction: this.obstruction,

      roofTypeId: this.roofTypeId(),
      wallTypeId: this.wallTypeId(),

      roofTexture: roofTexture,
      wallTexture: wallTexture,

      snapToTerrain: this.snapToTerrain(),
      isManaged: this.isManaged,
      maxImageryPerspectivesWhenUpdated: this.maxImageryPerspectivesWhenUpdated,
      isLocked: this.isLocked,
    }

    if (this.userData.excludeFromExport === true) {
      newUserData.excludeFromExport = true
    }

    this.userData = newUserData
    return this.userData
  },

  applyUserData: function () {
    if (OsFacet.applyUserDataEnabled === false) {
      console.log(
        'Note: skipping facet.applyUserData() to prevent errors when some nodes not yet loaded. Be sure to call applyUserData on all facets after loading finished.'
      )
      return
    }

    this.slopeOverride = typeof this.userData.slopeOverride !== 'undefined' ? this.userData.slopeOverride : null
    this.azimuthOverride = typeof this.userData.azimuthOverride !== 'undefined' ? this.userData.azimuthOverride : null
    this.obstruction = typeof this.userData.obstruction !== 'undefined' ? this.userData.obstruction : null
    this.slope = typeof this.userData.slope !== 'undefined' ? this.userData.slope : null
    this.azimuth = typeof this.userData.azimuth !== 'undefined' ? this.userData.azimuth : null

    this.roofTypeId(this.userData.roofTypeId)
    this.wallTypeId(this.userData.wallTypeId)

    this.roofTexture = this.userData.roofTexture
    this.wallTexture = this.userData.wallTexture

    this.snapToTerrain(this.userData.snapToTerrain)

    if (typeof this.userData.isManaged !== 'undefined') {
      // This was added as a bugfix for when we manually call facet.applyUserData() as a workaround in OsStructure()
      // but perhaps we should do this everywhere and avoid applying any undefined values?
      this.isManaged = this.userData.isManaged
    }

    this.maxImageryPerspectivesWhenUpdated = this.userData.maxImageryPerspectivesWhenUpdated || 0
    this.isLocked = !!this.userData.isLocked

    this.userData.vertices.forEach((uuidNode) => {
      if (editor && editor.objectByUuid) {
        var node = editor.objectByUuid(uuidNode)
        if (node) {
          editor.objectByUuid(uuidNode).addToFacet(this, true)
          // editor.execute(new AddNodeToFacetCommand(node, this))
        } else {
          if (!this.userData.isManaged) {
            throw new Error(`OsFacet.applyUserData(): Node with uuid ${uuidNode} not found.`)
          }
        }
      }
    })

    this.userData.objectsFloating.forEach(function (uuidNode) {
      if (editor && editor.objectByUuid) {
        this.addFloatingObject(editor.objectByUuid(uuidNode))
      }
    }, this)
  },

  duplicate: function (options) {
    // Note we do not copy over the value of isManaged so if a managed facet is duplicated it will become unmanaged
    // This is not currently a problem but in future we may want to consider copying this value over to the duplicate.
    var positionOffset = Utils.positionOffsetFromDuplicateOptions(options)
    var newFacet = new OsFacet()
    newFacet.vertices = []
    var commandUuid = Utils.generateCommandUUIDOrUseGlobal()

    this.vertices.forEach((n) => {
      const position = new THREE.Vector3().copy(n.position).add(positionOffset)

      // Only consider "real" persistent nodes, not virtual notes that will not be saved in the design, which
      // includes nodes belonging to OsStructurtes.
      // Refresh this on each iteration to ensure that it includes any nodes that have been added in this loop.
      // Re-using existing nodes it critical instead of duplicating them, otherwise we can have topology errors.
      // Plus, it is nicer beacuse it creates a shared ridge/hips.
      var nodes = editor.filter('type', 'OsNode').filter((n) => !n.userData.excludeFromExport)

      const existingNode = OsNode.nodeForCoordinates(nodes, ...position.toArray())

      const newNode =
        existingNode ||
        new OsNode({
          ...n,
          position: position,
        })
      if (!existingNode) {
        editor.execute(new AddObjectCommand(newNode, null, true, commandUuid))
      }
      newNode.facets.push(newFacet)
      newFacet.vertices.push(newNode)
    })

    // The order of vertices in both new and old facets is the same and we can use this to rebuild the edges.
    this.getEdges().forEach((edge) => {
      // const existingEdge = !edge.userData.excludeFromExport ? edge : null
      var nodes = editor.filter('type', 'OsNode').filter((n) => !n.userData.excludeFromExport)
      var edges = editor.filter('type', 'OsEdge').filter((n) => !n.userData.excludeFromExport)
      var nodesForEdge = edge.nodes.map((n) => nodes.find((node) => node.position.equals(n.position)))
      const existingEdge = OsEdge.getEdgeWithNodes(edges, nodesForEdge)

      const newEdge =
        existingEdge ||
        new OsEdge({
          nodes: [
            newFacet.vertices[this.vertices.indexOf(edge.nodes[0])],
            newFacet.vertices[this.vertices.indexOf(edge.nodes[1])],
          ],
          edgeType: edge.edgeType,

          // linkNodes: true is critical for adding the edge to the nodes which avoids rebuilding them below
          linkNodes: true,
        })
      if (!existingEdge) {
        editor.execute(new AddObjectCommand(newEdge, undefined, false))
      }
    }, this)

    // Do not rely on newFacet.rebuildEdges() because it will clear edgeTypes. Instead, rebuild edges manually
    // which means that newFacet.rebuildEdges() won't have any work to do.
    newFacet.rebuildEdges(editor, { includeAllEdges: true })
    newFacet.userData = {
      vertices: newFacet.vertices.map(function (o) {
        return o.uuid
      }),
      objectsFloating: [],
      slope: this.userData.slope,
      azimuth: this.userData.azimuth,
      slopeOverride: this.userData.slopeOverride,
      azimuthOverride: this.userData.azimuthOverride,
      obstruction: this.userData.obstruction,
      roofTypeId: this.userData.roofTypeId,
      wallTypeId: this.userData.wallTypeId,
      roofTexture: this.userData.roofTexture,
      wallTexture: this.userData.wallTexture,
      snapToTerrain: this.userData.snapToTerrain,
      maxImageryPerspectivesWhenUpdated: this.userData.maxImageryPerspectivesWhenUpdated,
    }
    newFacet.applyUserData()
    newFacet.children = []
    editor.execute(new AddObjectCommand(newFacet, null, true, commandUuid))

    return newFacet
  },

  onRemove: function (editor) {
    // When deleting a facet as an explicit user action delete only those nodes which no long have any other facets
    // Deletion of nodes will automatically delete associated edges

    var removeChildCmds = []

    // After that, some edges may still remain because both nodes belong to a different facet.
    // In that case, if the edge itself does not belong to another facet, delete the edge but leave the nodes.
    var orphanEdgesToRemove = []
    if (this.deletedByUserAction === true) {
      this.getEdges().forEach((edge) => {
        // does not belong to any other facets
        if (edge.getFacets().length === 1) {
          // both nodes belong to other facets
          if (edge.nodes[0].getFacets().length > 1 && edge.nodes[1].getFacets().length > 1) {
            orphanEdgesToRemove.push(edge)
            !editor.changingHistory && removeChildCmds.push(new RemoveObjectCommand(edge, true))
          }
        }
      })
    }

    this.deleteFacetMesh()
    this.clearAssociatedObject()

    //this.deleteEdges()

    // Delete any nodes if they only belong to this facet
    // Reverse loop to avoid iteration & deletion at same time
    var removeVertex = []
    for (var i = this.vertices.length - 1; i >= 0; i--) {
      //save reference because this.vertices is modified here
      var vertex = this.vertices[i]
      var stayInScene = true
      if (this.deletedByUserAction === true) {
        if (vertex.facets.length === 1) {
          if (vertex.parent) {
            stayInScene = false
            removeVertex.push(vertex)
            !editor.changingHistory && removeChildCmds.push(new RemoveObjectCommand(vertex, true))
            //editor.execute(new RemoveObjectCommand(vertex, true))
            //editor.removeObject(vertex)
          } else {
            console.log('Warning: not calling removeObject(node) because node.parent is null. Probably already removed')
          }
        }
      }

      if (stayInScene) {
        // Any vertices which will be kept should refresh visibility because they may now no longer
        // belong to any facets so they should become visible
        vertex.visible = true
      }

      //To do Fix here
      vertex.removeFromFacet(this)
    }

    if (removeChildCmds.length > 0) {
      for (var i = removeVertex.length - 1; i >= 0; i--) {
        var vertex = removeVertex[i]
        vertex.getEdges().forEach(function (_edge) {
          if (_edge.parent) {
            if (_edge.ghostMode()) {
              //always remove an associated ghost edge without using a command
              editor.removeObject(_edge)
            } else {
              !editor.changingHistory && editor.execute(new RemoveObjectCommand(_edge, false))
            }
          } else {
            //Dangerous??? What if the edge has child objects which are still in viewport.objects?
            //console.log("Warning: skipping removeObject(_edge) _edge.parent is null")
          }
        })
      }
      !editor.changingHistory && editor.execute(new MultiCmdsCommand(removeChildCmds))
    }
  },

  deleteFacetMesh: function () {
    //Regardless of how many vertices etc, we will delete the mesh if it exists
    if (this.mesh) {
      if (this.mesh.parent) {
        //Only remove if already added to the scene. Prevent issues with double-calling before added to scene
        //editor.execute(new RemoveObjectCommand(this.mesh, false));
        const meshSetbacksGeometry = this.mesh.meshSetbacks?.geometry || null
        const meshSetbacksOutlineGeometries = []
        if (this.mesh.meshSetbacksOutline) {
          this.mesh.meshSetbacksOutline.children.forEach((outline) => {
            meshSetbacksOutlineGeometries.push(outline.geometry)
          })
        }
        editor.removeObject(this.mesh)
        this.mesh = null
        if (meshSetbacksGeometry) {
          meshSetbacksGeometry.dispose()
        }
        meshSetbacksOutlineGeometries.forEach((outlineGeom) => outlineGeom.dispose())
      } else {
        console.log('Warning: how can the mesh exist but have no parent???')
        //console.log(a.b.c);
        this.mesh = null
      }
    }
  },

  deleteEdges: function () {
    this.getEdges().forEach(function (e) {
      editor.removeObject(e)
    })
  },

  getBoundingBox: function () {
    if (!this.vertices[0]) {
      return new THREE.Box3()
    }
    var boundingBox = new THREE.Box3(this.vertices[0].position.clone(), this.vertices[0].position.clone())
    this.vertices.forEach(function (v) {
      boundingBox.expandByPoint(v.position)
    })
    return boundingBox
  },

  getCentroid: function () {
    return this.getBoundingBox().getCenter(new THREE.Vector3())
  },

  toTurfPolygon: function () {
    var verticesWrapped = this.vertices.concat([this.vertices[0]])

    return window.turf.polygon([
      verticesWrapped.map(function (v) {
        return v.position.toArray()
      }),
    ])
  },

  centerOfMass: function () {
    var polygon = this.toTurfPolygon()
    var center = window.turf.centerOfMass(polygon)
    return new THREE.Vector3(
      center.geometry.coordinates[0],
      center.geometry.coordinates[1],
      this.zForXY(center.geometry.coordinates[0], center.geometry.coordinates[1])
    )
  },

  setbackDistanceForEdgeType: function (edgeType) {
    var distance =
      edgeType in SetbacksHelper.setbackDistances
        ? SetbacksHelper.setbackDistances[edgeType]
        : SetbacksHelper.setbackDistances.default
    if (typeof distance === 'undefined') {
      console.log('Error: Setback not found for edge type: ' + edgeType + '. Fallback to 0 distance.')
      distance = 0
    }
    return distance
  },

  getEdgeWithNodes: function (nodes) {
    return OsEdge.getEdgeWithNodes(this.getEdges(), nodes)
  },

  getEdges: function () {
    //Find all edges where both nodes belong to this facet
    var edgesWithDuplicates = []
    this.vertices.forEach(function (v) {
      v.edges.forEach(function (e) {
        if (e.belongsToFacet(this)) {
          edgesWithDuplicates.push(e)
        }
      }, this)
    }, this)

    return Utils.getUnique(edgesWithDuplicates)
  },
  getEdgesAsCycle: function () {
    var edgesRemaining = this.getEdges()
    var lastEdge = edgesRemaining.pop() //store last edge each time
    var edgesOrdered = [lastEdge]

    while (edgesRemaining.length > 0) {
      lastEdge = edgesRemaining.filter(function (e) {
        return OsEdge.getSharedNode(lastEdge, e)
      })[0]
      if (!lastEdge) {
        throw new Error('Error in getEdgesAsCycle: not a cycle')
      }
      edgesOrdered.push(lastEdge)
      edgesRemaining.splice(edgesRemaining.indexOf(lastEdge), 1)
    }
    return edgesOrdered
  },
  rebuildEdges: function (editor, options) {
    // Run this again on each iteration to ensure it can consider edges which were added in the loop.
    var getEdgesAll = function () {
      return editor.filter('type', 'OsEdge').filter((e) => !e.userData.excludeFromExport)
    }

    if (this.vertices.length === this.getEdges().length) {
      console.log('Skipping rebuildEdges, edges already correct')
    }

    _.range(this.vertices.length).forEach(function (i) {
      var nodesForEdge = [this.vertices[i], this.vertices[(i + 1) % this.vertices.length]]
      var edge =
        options?.includeAllEdges === true
          ? OsEdge.getEdgeWithNodes(getEdgesAll(), nodesForEdge)
          : this.getEdgeWithNodes(nodesForEdge)

      if (!edge) {
        console.log('Edge not found, creating it now')
        var edge = new OsEdge({ nodes: nodesForEdge, edgeType: 'default' })
        // editor.addObject(edge)
        editor.execute(new AddObjectCommand(edge, undefined, false))
      }
    }, this)
  },

  closestEdgeToPosition: function (position) {
    return OsEdge.closestEdgeToPosition(this.getEdges(), position)
  },

  findSnapMatches: function (position) {
    //Find all possible matches to:
    //  - create a parallel lines
  },

  moduleGrids: function (systemUuid) {
    if (typeof systemUuid === 'undefined') {
      //all systems
      return this.objectsFloating
        .map(function (o) {
          return o.type === 'OsModuleGrid' ? o : null
        })
        .filter(Boolean)
    } else {
      return this.objectsFloating
        .map(function (o) {
          return o.type === 'OsModuleGrid' && o.getSystem().uuid === systemUuid ? o : null
        })
        .filter(Boolean)
    }
  },

  moduleQuantity: function () {
    return _.sum(
      this.moduleGrids().map(function (mg) {
        return mg.moduleQuantity()
      })
    )
  },

  selectionDelegate: function (editor, allowCreateSelectionDelegateGroup) {
    //if we have already selected the group that contains this facet then allow selecting the facet itself
    if (editor.selected && this.belongsToGroup(editor.selected)) {
      return this
    }

    if (allowCreateSelectionDelegateGroup !== true) {
      // do not create group when read-only
      return this
    }

    //do not select the facet, instead create and select a group with all contiguous facets
    var facets = this.getContiguousFacets([])

    // add all OsModuleGrid floating on those facets
    // moduleGrids can only float on a single facet at a time so we can simply all all grids from all facets
    var objectsFloating = [].concat.apply(
      [],
      facets.map((f) => f.objectsFloating)
    )

    // Try to find a group which already references all these facets

    // Old variation which would be important if there were already groups created that we could re-select
    // Removed because we assume groups are now always cleaned up after use so there will never be groups to search
    // var group = editor.filter('type', 'OsGroup').filter(
    //   o =>
    //     o.objects &&
    //     o.objects.length === facets.length &&
    //     o.objects
    //       .map(f => f.uuid)
    //       .sort()
    //       .join('') ===
    //       facets
    //         .map(f => f.uuid)
    //         .sort()
    //         .join('')
    // )[0]
    //
    // if (!group) {
    //   group = new OsGroup({ objects: facets })
    //   group.refreshPosition()
    //
    //   //Beware: we must set dispatchSignalsOverride =false on addObject or performance hangs due to signals
    //   editor.addObject(group, editor.scene, false)
    //   // editor.select(group)
    // } else {
    //   group.refreshPosition()
    // }

    if (facets.length === 1 && objectsFloating.length === 0) {
      // Do not use selection group if only a single facet with no floating objects/modulegrids
      return this
    }

    var group = new OsGroup({ objects: facets.concat(objectsFloating) })
    group.refreshPosition()
    //Beware: we must set dispatchSignalsOverride =false on addObject or performance hangs due to signals
    editor.addObject(group, editor.scene, false)

    return group
  },
  onChange: function (editor, force, allowSnapFacets) {
    if (force === true) {
      this.clearHash()
    }

    if (!OsFacet.onChangeEnabled) {
      console.log('Skip onChange: this.onChangeEnabled is false')
      return
    }

    if (
      editor.controllers &&
      editor.controllers.CallbackStack &&
      editor.controllers.CallbackStack.active &&
      editor.controllers.CallbackStack.recordIfNewOrReturnFalse('OsFacet.onChange.' + this.uuid) === false
    ) {
      return
    }

    if (!this.centroid) {
      this.refreshPosition()
    }

    //@todo: only clear these when dirty
    this.edgesAsLine3Cached = null

    /*
        Due to floating nodes, we must first refresh the plane, then refloat nodes, then finally build the mesh
        */

    if (this.hasChanged()) {
      editor &&
        editor.uiPauseUntilComplete &&
        editor.uiPauseUntilComplete(
          function () {
            if (
              this.position.x != this.centroid.x ||
              this.position.y != this.centroid.y ||
              this.position.z != this.centroid.z
            ) {
              //copy into points and reset position
              this.vertices.forEach(function (v) {
                v.position.add(this.position).sub(this.centroid)
              }, this)

              //Change to position/centroid will trigger hasChanged below...
              this.centroid.copy(this.position)

              this.vertices.forEach(function (v) {
                // @TODO: Make this more clear. Currently nodes which belong to an OsStructure
                // cause infinite loops here. We will detect them by ignorning any nodes where
                // parent is null
                if (v.parent) {
                  v.onChange(editor)
                } else {
                  console.log('skipping node.onChange() because parent is none, assume this belongs to OsStructure')
                }
              }, this)
            }

            this.refreshPosition()
            this.refreshPlane()
            this.refreshMesh(editor)
            this.refloatObjects(editor) //Cannot refloat objects until a mesh is created
            this.refreshMesh(editor) //@todo: Only refresh mesh if something has changed
            this.refreshUserData()
            this.refreshName()

            // Check this.isManaged to avoid snapping facets from a structure onto terrain
            if (allowSnapFacets !== false && this.snapToTerrain() === true && !this.isManaged) {
              // New method which only updates affected facets
              SceneHelper.snapFacetsToTerrainAddFacetToQueue(this)

              // Old method which would update all facets and was very slow for complex scenes
              // SceneHelper.snapFacetsToTerrainDebounced()
            }

            this.saveHash()
          },
          this,
          'ui'
        )
    }

    if (editor.viewport && editor.selected === this) {
      editor.viewport.refreshSelectionBox()
    }
  },

  zForXY: function (x, y) {
    // ax + by + cz - d = 0
    // -cz = ax + by - d
    // cz = -(ax + by - d)
    // z = -(ax + by - d)/c
    return -(this.plane.normal.x * x + this.plane.normal.y * y + this.plane.constant) / this.plane.normal.z
  },

  matchesPolygonCoordinates: function (polygonCoordinates) {
    // Allow any rotation and allow reversed
    console.log('matchesPolygonCoordinates...')

    if (polygonCoordinates.length - 1 != this.vertices.length) {
      console.log('polygonCoordinates.length-1 != this.vertices')
      return false
    }

    // Find index for first polygonCoordinate
    // Rearrange nodes starting with first polygonCoordinate
    var startingNodeIndex = null
    for (var i = 0; i < this.vertices.length; i++) {
      if (this.vertices[i].matchesCoordinates(polygonCoordinates[0][0], polygonCoordinates[0][1])) {
        startingNodeIndex = i
      }
    }
    if (startingNodeIndex == null) {
      return false
    }

    var nodesReordered = this.vertices.slice(startingNodeIndex).concat(this.vertices.slice(0, startingNodeIndex))

    for (var i = 0; i < nodesReordered.length; i++) {
      if (!nodesReordered[i].matchesCoordinates(polygonCoordinates[i][0], polygonCoordinates[i][1])) {
        return false
      }
    }
    return true
  },

  matchesNodes: function (nodeSequence) {
    //Match can a) start at any position and wrap around b) forward or reverse

    if (this.vertices.length != nodeSequence.length) {
      return false
    }

    //Modify sequence to start at same node
    var firstVertexPositionInSequence = nodeSequence.indexOf(this.vertices[0])
    if (firstVertexPositionInSequence === -1) {
      return false
    }

    var matchesForward = true
    var nodeSequenceSorted = nodeSequence
      .slice(firstVertexPositionInSequence)
      .concat(nodeSequence.slice(0, firstVertexPositionInSequence))

    for (var i = 0, l = nodeSequenceSorted.length; i < l; i++) {
      if (this.vertices[i] != nodeSequenceSorted[i]) {
        matchesForward = false
        break
      }
    }

    if (matchesForward) {
      return true
    }

    var matchesBackward = true
    var nodeSequenceReversed = [].concat(nodeSequenceSorted).reverse()
    //Rotate the last item back to the start to keep it in position 1
    var nodeSequenceReversedSorted = nodeSequenceReversed.slice(-1).concat(nodeSequenceReversed.slice(0, -1))
    for (var i = 0, l = nodeSequenceReversedSorted.length; i < l; i++) {
      if (this.vertices[i] != nodeSequenceReversedSorted[i]) {
        matchesBackward = false
        break
      }
    }
    if (matchesBackward) {
      return true
    }

    return false
  },

  getContextMenuItems: function (position, _editor) {
    var _this = this
    if (!_editor) {
      _editor = editor
    }

    var items = [
      {
        label: window.translate(`Select Facet (Azimuth: ${Math.round(this.azimuth)})`),
        useHTML: false,
        selected: false,
        targetObjUuid: _this.uuid,
        onClick: function () {
          if (_editor) {
            _editor.select(_this, true)
          }
        },
      },
    ]

    var structure = this.getStructure()
    if (structure) {
      items.push({
        label: window.translate(structure.isDormer() ? `Select Dormer` : `Select Structure`),
        useHTML: false,
        selected: false,
        targetObjUuid: structure.uuid,
        onClick: function () {
          if (_editor) {
            _editor.select(structure, true)
          }
        },
      })
    }

    return items
  },
  changeAffectsModuleGrids: function (systemUuid) {
    if (this.moduleGrids(systemUuid).length > 0) {
      return true
    }
    return false
  },

  isSimpleFacet: function (ignoreEdges) {
    // Facet is "simple" if there is only one flat edge, ignorning an edge which is currently being modified
    // Floating nodes are allowed
    return (
      this.getEdges().filter(function (e) {
        return e.isFlat() && ignoreEdges.indexOf(e) === -1 && e.bothNodesFloating() === false
      }).length === 1
    )
  },
  angleIsConcave: function (edge0, edge1) {
    // Test the point between the acute angle between the edges.
    // If the point falls inside the polygon then angle is convex, otherwise concave

    // Requires edges to share a common point

    var sharedNode = OsEdge.getSharedNode(edge0, edge1)
    if (!sharedNode) {
      throw new Error('No shared node found')
    }

    // Bisector between edges
    var nonSharedNodes = [
      edge0.nodes[0] !== sharedNode ? edge0.nodes[0] : edge0.nodes[1],
      edge1.nodes[0] !== sharedNode ? edge1.nodes[0] : edge1.nodes[1],
    ]

    // bisector position
    var bisectionPoint = new THREE.Vector3(
      nonSharedNodes[0].position.x + nonSharedNodes[1].position.x,
      nonSharedNodes[0].position.y + nonSharedNodes[1].position.y,
      nonSharedNodes[0].position.z + nonSharedNodes[1].position.z
    ).multiplyScalar(0.5)

    // find point with tiny offset from shared point in the direction of the bisector
    var offsetFromSharedNode = new THREE.Vector3().subVectors(bisectionPoint, sharedNode.position).setLength(1)
    var testPosition = new THREE.Vector3().addVectors(sharedNode.position, offsetFromSharedNode)

    var polygon = this.toTurfPolygon()
    var point = window.turf.point([testPosition.x, testPosition.y])
    return window.turf.inside(point, polygon) === false //New version will use turf.pointsWithinPolygon instead
  },
  edgesAdjacentOrLinkedWithoutConcavity: function (edge0, edge1) {
    return this.edgesLinkedWithoutConcavity(edge0, edge1, true)
  },
  edgesLinkedWithoutConcavity: function (edge0, edge1, returnImmediatelyIfAdjacent) {
    // walk each direction around the facet
    // if we can join each edge without hitting a concavity then they are linked
    var edges = this.getEdgesAsCycle()

    var edgeIndex0 = edges.indexOf(edge0)
    var edgeIndex1 = edges.indexOf(edge1)

    if (edgeIndex0 === -1 || edgeIndex1 === -1) {
      return false
    }

    //If edges are adjacent OR edge positions are first and last
    if (returnImmediatelyIfAdjacent) {
      if (
        Math.abs(edgeIndex0 - edgeIndex1) === 1 ||
        (edgeIndex0 === 0 && edgeIndex1 === edges.length - 1) ||
        (edgeIndex1 === 0 && edgeIndex0 === edges.length - 1)
      ) {
        return true
      }
    }

    // rotate so list of edges start with edge0
    var arrayRotate = function (arr, reverse) {
      if (reverse) arr.unshift(arr.pop())
      else arr.push(arr.shift())
      return arr
    }

    var rotations = edges.indexOf(edge0)
    for (var i = 0; i < rotations; i++) {
      arrayRotate(edges)
    }

    // forward
    // start on second edge, because first edge is edge0
    for (var i = 1; i < edges.length; i++) {
      if (this.angleIsConcave(edges[i - 1], edges[i])) {
        // fail, do not return yet because we need to check the reverse direction
        break
      } else if (edges[i] === edge1) {
        // we made it without hitting a concavity
        return true
      }
    }

    // reverse
    edges.reverse()

    //now wrap the last item back to the start, this will not break the loop
    edges.unshift(edges.pop())

    //same loop as above but edges are reversed
    for (var i = 1; i < edges.length; i++) {
      if (this.angleIsConcave(edges[i - 1], edges[i])) {
        // fail, do not return yet because we need to check the reverse direction
        break
      } else if (edges[i] === edge1) {
        // we made it without hitting a concavity
        return true
      }
    }

    return false
  },
  getIntersectionEdges: function (slope) {
    var edges = this.getEdges()
    var edgePlanes = edges.map(function (e) {
      return e.getPlane(slope)
    })

    var intersections = []
    for (var i = 0; i < edges.length; i++) {
      // avoid duplicates, so start second loop from next node
      for (var j = i + 1; j < edges.length; j++) {
        if (i === j) {
          continue
        }

        // TEST REQUIRED: If edges are separated by a concavity then they should not intersect and should not trip each other
        // if (!this.edgesAdjacentOrLinkedWithoutConcavity(edges[i], edges[j])) {
        //   continue
        // }

        var intersectionLine = edgePlanes[i].intersectPlane(edgePlanes[j], new THREE.Vector3())
        // intersectionLine.multiplyScalar(1000)

        if (!intersectionLine) {
          // No intersection found, planes are probably coplanar. Do not try to create an intersection edge
          continue
        }

        // ensure line is large so it's always larger than the footprint and extends in both directions
        var intersectionLineDirection = intersectionLine.delta(new THREE.Vector3()).normalize().multiplyScalar(100)

        //@TODO: Using getCenter is not save because it could literally be anywhere and size of line may be insufficient
        var pInitial = intersectionLine.closestPointToPoint(edges[i].nodes[0].position, false, new THREE.Vector3())
        var p0 = new THREE.Vector3().addVectors(pInitial, intersectionLineDirection)
        var p1 = new THREE.Vector3().subVectors(pInitial, intersectionLineDirection)

        // @TODO: Remove the need for this hack... why is it required???
        p0.x *= -1
        p0.y *= -1
        p0.z *= -1
        p1.x *= -1
        p1.y *= -1
        p1.z *= -1
        //end hack

        // trim the line to ensure a) it falls below all edge planes b) it is only above the facet plane
        // conveniently this also sorts the lines with start=lowest, end=highest
        //
        // @TODO?? Do not trim the line by any plane which is on the other side of a concavity??
        var intersectionLineTrimmed = OsEdge.trimWithPlanes(
          new THREE.Line3(p0, p1),
          edgePlanes.filter(function (p) {
            // Do not trim the line by either of the two planes that created it
            return p !== edgePlanes[i] && p !== edgePlanes[j]
          }),
          [this.plane]
        )

        // var intersectionLineTrimmed = new THREE.Line3(p0, p1)

        // project from 2D intersection to create 3D OsEdge lying on the plane

        // @TODO: Should we re-use the same nodes or create new/disposable nodes for the edges??
        // Missing start or end indicates the line should be skipped
        if (intersectionLineTrimmed.start && intersectionLineTrimmed.end) {
          intersections.push(
            new OsEdge({
              nodes: [
                new OsNode({ position: intersectionLineTrimmed.start, derivedFromFacet: this }),
                new OsNode({ position: intersectionLineTrimmed.end, derivedFromFacet: this }),
              ],
              derivedFromFacet: this,
            })
          )
        }
      }
    }
    return intersections
  },
  solidify: function (slope) {
    // Delete all solidified facets before rebuilding
    editor.filter().forEach(function (o) {
      if (o.derivedFromFacet) {
        editor.removeObject(o)
      }
    })

    var nodes = []
    this.getIntersectionEdges(slope).forEach(function (e) {
      editor.addObject(e)
      editor.addObject(e.nodes[0])
      editor.addObject(e.nodes[1])
      nodes.push(e.nodes[0])
      nodes.push(e.nodes[1])
    })

    //@TODO: Optimize
    nodes.forEach(function (node) {
      if (!node.parent) {
        console.log('node already merged+removed, skip merge')
      } else {
        node.mergeWithNearbyNodes(editor, undefined, false)
      }
    })
  },
  getAdjacentFacets: function () {
    var facets = []
    this.vertices.forEach((e) => {
      e.facets.forEach((f) => {
        if (facets.indexOf(f) === -1) {
          facets.push(f)
        }
      })
    })
    return facets
  },
  getContiguousFacets: function (facetsAlreadyFound) {
    if (!facetsAlreadyFound) {
      facetsAlreadyFound = []
    }

    //including the facet itself
    this.vertices.forEach((e) => {
      //if all facets already added then simply return
      var facetsNotYetAdded = e.facets.filter((f) => {
        return facetsAlreadyFound.indexOf(f) === -1
      })

      if (facetsNotYetAdded.length === 0) {
        return facetsAlreadyFound
      } else {
        //otherwise keep exploring recursively
        facetsNotYetAdded.forEach((f) => {
          if (facetsAlreadyFound.indexOf(f) === -1) {
            facetsAlreadyFound.push(f)
          }
        })
      }
    })
    return facetsAlreadyFound
  },
  getStructure: function () {
    if (!this.isManaged) {
      return null
    } else {
      return editor.filter('type', 'OsStructure').find((s) => s.facets.indexOf(this) !== -1)
    }
  },
})

OsFacet.onChangeEnabled = true

OsFacet.centroidOfPoints = function (points) {
  var sum = [0, 0, 0]
  points.forEach(function (p) {
    sum = numeric.add(sum, p)
  })
  return [sum[0] / points.length, sum[1] / points.length, sum[2] / points.length]
}

OsFacet.subtractFromPoints = function (points, delta) {
  return points.map(function (p) {
    return numeric.sub(p, delta)
  })
}

OsFacet.azimuthForNormal = function (normal) {
  var up = new THREE.Vector3(0, 0, 1)
  var normalProjectedOnGround = normal.clone().projectOnPlane(up)
  return (
    (window.turf.bearing(
      window.turf.point([0, 0]),
      window.turf.point([normalProjectedOnGround.x, normalProjectedOnGround.y])
    ) +
      360) %
    360
  )
}

OsFacet.slopeForNormal = function (normal) {
  var up = new THREE.Vector3(0, 0, 1)
  return Math.acos(Math.abs(normal.dot(up))) * THREE.Math.RAD2DEG
}

OsFacet.planeFromAzimuthSlopeCentriod = function (azimuth, slope, centroid) {
  var dummy = new THREE.Vector3(0, 0, 1) //initial up
  dummy.applyAxisAngle(new THREE.Vector3(1, 0, 0), -slope * THREE.Math.DEG2RAD) //apply slope
  dummy.applyAxisAngle(new THREE.Vector3(0, 0, 1), -azimuth * THREE.Math.DEG2RAD) //apply azimuth
  return new THREE.Plane().setFromNormalAndCoplanarPoint(dummy, centroid)
}

// Make it possible to monkey patch Math.random() and replace with a seeded random number generator
// so we dont encounter surprised in unit tests.
// Usage OsFacet.seed(123) which will overwrite OsFacet.random with the new generator
OsFacet.random = function () {
  return Math.random()
}

OsFacet.seed = 1

OsFacet.useSeed = function (s) {
  if (!s) {
    OsFacet.random = Math.random
  } else {
    OsFacet.random = function () {
      var x = Math.sin(OsFacet.seed++) * 10000
      return x - Math.floor(x)
    }
  }
}

OsFacet.getRandomItems = function (quantity, items) {
  var bucket = []
  for (var i = 0, l = items.length; i < l; i++) {
    bucket.push(i)
  }

  function getRandomFromBucket() {
    var randomIndex = Math.floor(OsFacet.random() * bucket.length)
    return bucket.splice(randomIndex, 1)[0]
  }
  let results = []
  for (var i = 0; i < quantity; i++) {
    results.push(items[getRandomFromBucket()])
  }
  return results
}

OsFacet.planeFromPointsRANSAC = function (points, options = {}) {
  var iterations = options?.iterations || 100
  var numPoints = options?.numPoints || 20
  var distanceThresholdForInliers = options?.distanceThresholdForInliers || 0.1

  // 'inliers', 'meanAbsoluteError'
  var bestPlaneMethod = options?.bestPlaneMethod || 'inliers'

  var calculateMeanAbsoluteError =
    !!options?.bestPlaneMethod === 'meanAbsoluteError' ||
    !!options?.calculateMeanAbsoluteError ||
    SceneHelper.AUTO_ORIENT_DEFAULTS.showMAE

  if (numPoints > points.length) {
    // @TODO: If numPoints is more than all the points available, should we simply use least squares instead of RANSAC
    // or perhaps we should select a subset of all available points (e.g. 50%)
    numPoints = points.length
  }

  if (SceneHelper.AUTO_ORIENT_DEFAULTS.drawNodes) {
    editor.uiPause('render', 'debugForRansacTerrain')
    editor.filterObjects((o) => o.debugForRansacTerrain).forEach((o) => editor.removeObject(o))

    points.forEach(function (p) {
      var node = new OsNode({ position: new THREE.Vector3(p[0], p[1], p[2]), selectable: false })
      node.userData.excludeFromExport = true
      node.debugForRansacTerrain = true

      // hack to allow different material
      node.material = OsNodeCache.materialFloating
      node.floatingOnFacet = true
      node.refreshForCamera()

      editor.addObject(node)
    })
    editor.uiResume('render', 'debugForRansacTerrain')
  }

  var bestPlane = null
  var bestInliers = 0
  var bestMAE = null
  var consoleTableRows = []

  for (var i = 0; i <= iterations; i++) {
    try {
      var randomPoints = OsFacet.getRandomItems(numPoints, points)
      var plane = OsFacet.planeFromPoints(randomPoints)
      var residuals = points.map((point) =>
        Math.abs(plane.distanceToPoint(new THREE.Vector3(point[0], point[1], point[2])))
      )
      var numInliers = residuals.filter((r) => r < distanceThresholdForInliers).length
      var planeMAE = calculateMeanAbsoluteError ? residuals.reduce((a, b) => a + b, 0) / residuals.length : null

      var isBestPlane = false

      if (bestPlaneMethod === 'meanAbsoluteError') {
        // meanAbsoluteError
        if (!bestPlane || planeMAE < bestMAE) {
          isBestPlane = true
        }
      } else {
        // inliers
        if (!bestPlane || numInliers > bestInliers) {
          isBestPlane = true
        }
      }

      if (window.studioDebug) {
        consoleTableRows.push({
          isNewBest: isBestPlane ? 1 : 0,
          points: points.length,
          numInliers: numInliers,
          bestInliers: bestInliers,
          planeMAE: planeMAE,
          bestMAE: bestMAE,
        })
      }

      if (isBestPlane) {
        bestPlane = plane
        bestInliers = numInliers
        bestMAE = planeMAE

        if (numPoints === numInliers) {
          // all points are inliers, there is no point in performing any more iterations
          break
        }
      }
    } catch (error) {
      //ignore no convergence error, continue with iterations
    }
  }

  if (SceneHelper.AUTO_ORIENT_DEFAULTS.showMAE) {
    console.log('bestMAE', bestMAE)
  }

  if (window.studioDebug) {
    console.table(consoleTableRows)
  }

  return {
    plane: bestPlane,
    numInliers: bestInliers,
    meanAbsoluteError: bestMAE,
  }
}

OsFacet.forcePlaneUp = function (plane) {
  var up = new THREE.Vector3(0, 0, 1)
  if (plane.normal.dot(up) < 0) {
    plane.negate()
  }
  return plane
}

OsFacet.planeFromPoints = function (points, azimuthOverride, slopeOverride) {
  var centroidArray = OsFacet.centroidOfPoints(points)
  var pointsCentered = OsFacet.subtractFromPoints(points, centroidArray)

  var normal
  if (points.length === 3) {
    return OsFacet.forcePlaneUp(
      new THREE.Plane().setFromCoplanarPoints(
        new THREE.Vector3().fromArray(points[0]),
        new THREE.Vector3().fromArray(points[1]),
        new THREE.Vector3().fromArray(points[2])
      )
    )
  } else if (points.length > 2) {
    var svd = numeric.svd(pointsCentered)
    var normalArray = [svd.V[0][2], svd.V[1][2], svd.V[2][2]]
    normal = new THREE.Vector3().fromArray(normalArray)
  } else {
    normal = new THREE.Vector3().fromArray([0, 0, 1])
  }

  var plane, azimuth, slope

  var centroid = new THREE.Vector3().fromArray(centroidArray) //As Vector3

  if (!azimuthOverride && !slopeOverride) {
    //No overrides are set, use the normal calculated from points
    plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, centroid)
  } else {
    //Modify normal with overrides for slope and azimuth
    azimuth = azimuthOverride == null ? OsFacet.azimuthForNormal(normal) : azimuthOverride
    slope = slopeOverride == null ? OsFacet.slopeForNormal(normal) : slopeOverride

    //Create plane from any combination of calculated/overriden slope+azimuth
    plane = OsFacet.planeFromAzimuthSlopeCentriod(azimuth, slope, centroid)
  }

  //Plane direction is arbitrary, flip it if facing down
  OsFacet.forcePlaneUp(plane)

  return plane
}

OsFacet.closestToPosition = function (facets, position) {
  var closestIndex = 0
  var closestDistance = 100000

  _.range(facets.length).forEach(function (index) {
    var distanceToEdge = facets[index].centroid.distanceTo(position)

    if (distanceToEdge < closestDistance) {
      closestDistance = distanceToEdge
      closestIndex = index
    }
  })

  return [facets[closestIndex], closestDistance]
}

OsFacet.calculateFloatingPositionOnPlane = function (x, y, raycaster, plane) {
  if (!raycaster) {
    raycaster = new THREE.Raycaster(new THREE.Vector3(x, y, 1000), new THREE.Vector3(0, 0, -1))
  }

  //Floating based on plane, not mesh intersection
  return raycaster.ray.intersectPlane(plane, new THREE.Vector3())
}
