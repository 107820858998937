/**
 * @author mrdoob / http://mrdoob.com/
 */

var Editor = function () {
  // Must match the latest version number in design_json.py
  this.version = '1.5'

  //Populated when viewport is created
  this.viewport_container = null

  //Corresponds to level 21 in top-down maps (old, not adjusted for latitude)
  //this.defaultMetersPerPixel = 0.074645535434742;

  //Corresponds to level 21 in top-down maps
  this.defaultMetersPerPixel = 0.074645535434742 * 0.79

  //Corresponds to level 20 in top-down maps
  // this.defaultMetersPerPixel = 0.074645535434742 * 0.79 * 2;

  //Corresponds to level 19 in top-down maps
  // this.defaultMetersPerPixel = 0.074645535434742 * 0.79 * 4;

  this.cachedGroundReference = null

  // Method used in some commands to ensure related commands are handled together
  // e.g. When removing an object that also removes child objects, this can be helpful.
  this.commandInProgress = false

  var Signal = signals.Signal

  this.signals = {
    // editor queue

    queueProcessed: new Signal(),

    // designer

    placementModeChanged: new Signal(),

    // nearmap

    requestNearmapLogin: new Signal(),

    // script

    editScript: new Signal(),

    // player

    startPlayer: new Signal(),
    stopPlayer: new Signal(),

    // vr

    enterVR: new Signal(),

    enteredVR: new Signal(),
    exitedVR: new Signal(),

    // actions

    overlayChanged: new Signal(),

    // notifications

    editorCleared: new Signal(),

    savingStarted: new Signal(),
    savingFinished: new Signal(),

    themeChanged: new Signal(),

    transformModeChanged: new Signal(),
    spaceChanged: new Signal(),
    rendererChanged: new Signal(),

    sceneBackgroundChanged: new Signal(),
    sceneFogChanged: new Signal(),
    sceneGraphChanged: new Signal(),
    sceneLoaded: new Signal(),

    liteProjectUpgraded: new Signal(),

    cameraChanged: new Signal(),
    cameraAnimationStarted: new Signal(),
    cameraAnimationFinished: new Signal(),

    mapChanged: new Signal(),
    mapAnimationStarted: new Signal(),
    mapAnimationFinished: new Signal(),

    controlModeChanged: new Signal(),
    controllerStatusChanged: new Signal(),
    displayModeChanged: new Signal(),
    viewsChanged: new Signal(),
    viewBoxStatusChanged: new Signal(),

    geometryChanged: new Signal(),

    objectSelected: new Signal(),
    objectDeselected: new Signal(),
    objectFocused: new Signal(),

    objectAdded: new Signal(),
    objectChanged: new Signal(),
    objectRemoved: new Signal(),
    objectAnnotationChanged: new Signal(),

    systemSelected: new Signal(),

    systemCalculationsAddedToQueue: new Signal(),
    systemCalculationsRemovedFromQueue: new Signal(),
    systemCalculationsUpdated: new Signal(),

    helperAdded: new Signal(),
    helperRemoved: new Signal(),

    materialChanged: new Signal(),

    scriptAdded: new Signal(),
    scriptChanged: new Signal(),
    scriptRemoved: new Signal(),

    windowResize: new Signal(),

    showGridChanged: new Signal(),
    cycleNextMapSceneControl: new Signal(),
    viewLockedChanged: new Signal(),
    historyChanged: new Signal(),
    escapePressed: new Signal(),

    projectDataLoaded: new Signal(),
    projectConfigurationChanged: new Signal(),
    typingInField: new Signal(),
    expansionPanelChanged: new Signal(),
    customImageryUploaded: new Signal(),

    animationStart: new Signal(),
    animationStop: new Signal(),

    shadingUpdated: new Signal(),
    sunUpdated: new Signal(),

    sequenceUpdated: new Signal(),

    setPanelOrientation: new Signal(),

    modulePlacementStatusChanged: new Signal(),

    sceneLoadedAndProfileLoaded: new Signal(),

    commandUpdated: new Signal(),

    // setUiState: new Signal(),

    loadHelperShowing: new Signal(),
    projectInvalidChanged: new Signal(),
    designGuidesVisibilityChanged: new Signal(),

    terrainLoaded: new Signal(),
  }

  // this.config = null // new Config('threejs-editor')
  this.history = new History(this)
  this.storage = new Storage()
  this.loader = new Loader(this)

  this._metersPerPixel = null

  this._interactive = null
  this.interactive(false)

  this.camera = this.createCamera()
  this.cameraCenter = new THREE.Vector3()

  this.scene = new OsDesignerScene()
  this.starterSceneData = this.sceneAsJSON()

  this.sceneHelpers = new THREE.Scene()

  this.object = {}
  this.geometries = {}
  this.materials = {}
  this.textures = {}
  this.scripts = {}

  this.selected = null
  this.selectedSystem = null
  this.selectedPreviousFacet = null
  this.helpers = {}
  this.controllers = new ControlPanel()
  this.controllersSavedState = null
  this.viewport = null
  this.callbackStack = []

  this.uiPauseAllowed = { ui: true, render: true, clearShading: true, calcs: true, annotation: true, handle: true }

  this.snappingActive = false
  this.selectionBoxActive = false
  this.selectionLockActive = false

  // Runtime variables not stored/persisted
  this.sceneIsLoading = false
  this.waiting = {
    views: false,
    terrainSearch: false,
    terrainDsm: false,
    terrainTexture: false,
    terrainFirstRender: false,
    terrainDsmFromSavedState: false,
  }

  this.terrainSettings = {
    wallBlurringActive: false,
    wallBlurringRadiusPx: 15,
    wallBlurringBrightnessPercent: 85,
    wallBlurringContrastPercent: 70,
  }

  this.designGuidesVisible = true

  this.extensions = {
    NonSpatialFacets: new NonSpatialFacets(this),
    SharedFacetVfx: new SharedFacetVfx(this),
    ModuleGridOutliner: new ModuleGridOutliner(this),
    SnapshotGenerator: new SnapshotGenerator(this),
    MeshLineUpdater: new MeshLineUpdater(this),
    DxfExporter: new DxfExporter(this),
    BatterySideEffects: BatterySideEffects(this),
    InverterSideEffects: InverterSideEffects(this),
    SystemSideEffects: SystemSideEffects(this),
  }
}

Editor.prototype = {
  setViewport: function (viewport) {
    this.viewport = viewport
    this.viewport_container = viewport.container

    // Activated by default
    this.extensions.MeshLineUpdater.activate()
    this.extensions.DxfExporter.activate()
    this.extensions.BatterySideEffects.activate()
    this.extensions.InverterSideEffects.activate()
    this.extensions.SystemSideEffects.activate()
    window.ViewBoxHelper = new ViewBoxHelperClass()
  },

  clearCallbackStack: function () {
    console.log('Designer.clearCallbackStack')
    this.callbackStack = []
  },

  // If locked, ui/renderer will not resume until specific locks are cleared
  uiPauseLocks: {
    ui: [],
    render: [],
    clearShading: [],
    calcs: [],
    annotation: [],
    handle: [],
  },

  uiResumeOriginalControllerState: {
    ui: {},
    render: {},
    clearShading: [],
    calcs: [],
    annotation: [],
    handle: [],
  },

  uiPause: function (type, lockName) {
    if (!this.uiPauseAllowed[type]) return
    if (lockName) {
      this.uiPauseLockAdd(type, lockName)
    }

    Designer.uiUpdatesActive[type] = false

    if (type === 'ui') {
      this.signals.sceneGraphChanged.active = false
    } else if (type === 'render') {
      this.viewport.renderActive(false)
    } else if (type === 'annotation') {
      // do not deactivate, just clear and stop rendering
      if (this.controllers.Annotation) {
        this.controllers.Annotation.refreshPaused = true
        this.controllers.Annotation.removeAll()
      }
    } else if (type === 'handle') {
      // do not deactivate, just clear and stop refreshing
      if (this.controllers.Handle) {
        this.controllers.Handle.refreshPaused = true
      }
    }
    // console.log('uiPause ' + type, this.uiPauseLocks[type])
  },

  uiResumeTriggered: { ui: false, render: false, clearShading: false, calcs: false, annotation: false, handle: false },

  uiPauseLockAdd: function (type, lockName) {
    if (this.uiPauseLocks[type].indexOf(lockName) === -1) {
      this.uiPauseLocks[type].push(lockName)
    }
    // console.log('Lock Added', lockName, this.uiPauseLocks[type])
  },

  uiPauseLockClear: function (type, lockName) {
    if (this.uiPauseLocks[type].indexOf(lockName) !== -1) {
      this.uiPauseLocks[type].splice(this.uiPauseLocks[type].indexOf(lockName), 1)
      // console.log('Lock Cleared', lockName, this.uiPauseLocks.ui)
    }
  },

  uiResumeSignalTimeoutScheduled: false,

  uiResume: function (type, lockName, dispatchSignalOnResume) {
    if (type && lockName) {
      this.uiPauseLockClear(type, lockName)
    }

    if (this.uiPauseLocks[type].length > 0) {
      // console.log('uiResume blocked: uiPauseLocks.' + type + ' not cleared:', this.uiPauseLocks[type])
      return
    }

    var _editor = this
    Designer.uiUpdatesActive[type] = true

    if (_editor.uiResumeTriggered[type] == false) {
      _editor.uiResumeTriggered[type] = true

      if (type === 'ui') {
        if (dispatchSignalOnResume !== false) {
          setTimeout(function () {
            _editor.signals.sceneGraphChanged.active = true
            _editor.signals.sceneGraphChanged.dispatch(editor.selectedSystem)
            //console.log("uiResume");
            _editor.uiResumeTriggered[type] = false
          }, 200)
        } else {
          // Skip dispatching sceneGraphChanged signal but ensure it is re-activated
          _editor.signals.sceneGraphChanged.active = true
          _editor.uiResumeTriggered[type] = false
        }
      } else if (type === 'render') {
        _editor.uiResumeTriggered[type] = false
        _editor.viewport.renderActive(true)
      } else if (type === 'annotation') {
        if (_editor.controllers.Annotation) {
          _editor.controllers.Annotation.refreshPaused = false

          if (_editor.controllers.Annotation.active && dispatchSignalOnResume !== false) {
            _editor.controllers.Annotation.handleObjectSelected()
          }
        }

        _editor.uiResumeTriggered[type] = false
      } else if (type === 'handle') {
        if (this.controllers.Handle) {
          _editor.controllers.Handle.refreshPaused = false
        }
        _editor.uiResumeTriggered[type] = false
      } else if (type === 'clearShading') {
        // We do not implement dispatchSignalOnResume because we do not reliably know hich object to call it with
        // It must be called manually whenever we resume
      } else if (type === 'calcs') {
        // We do not implement dispatchSignalOnResume because we do not reliably know hich object to call it with
        // It must be called manually whenever we resume
      }
    }
  },

  uiPauseUntilCompleteNested: function (callback, _this, pausesArgs) {
    /*
    Each item in pausesArgs contains arguments for: {type, lockName, dispatchSignalOnResume}
    */
    var lastCallback = callback

    pausesArgs.forEach((pauseArgs) => {
      var previousCallback = lastCallback
      lastCallback = function () {
        editor.uiPauseUntilComplete(
          function () {
            previousCallback()
          },
          _this,
          pauseArgs.type,
          pauseArgs.lockName,
          pauseArgs.dispatchSignalOnResume
        )
      }
    })

    lastCallback()
  },

  uiPauseUntilComplete: function (callback, _this, type, lockName, dispatchSignalOnResume) {
    /*
    Extremely important that we catch any uncaught errors which happen in the callback because otherwise we will may
    leave renderActive() === false which locks up the whole of studio.
    */
    if (!this.uiPauseAllowed[type]) {
      callback.call(_this)
      return
    }
    this.uiPause(type, lockName)

    try {
      callback.call(_this)
    } catch (e) {
      console.error('Unhandled exception in uiPauseUntilComplete, protecting studio interactivity', e)
    }

    this.uiResume(type, lockName, dispatchSignalOnResume)
  },

  uiPauseLocksAllEmpty: function () {
    /*
    Quick check to ensure there are no lingering uiPauseLocks.
    e.g. Check this at the end of all unit tests to ensure previous tests do not break later tests.
    */
    return !Object.values(this.uiPauseLocks).some((locksForType) => locksForType.length > 0)
  },

  uiPauseLocksClearAll: function () {
    Object.keys(this.uiPauseLocks).forEach((key) => (this.uiPauseLocks[key] = []))
  },

  activateSignal(signalName) {
    if (this.signals[signalName]) {
      this.signals[signalName].active = true
    }
  },

  deactivateSignal(signalName) {
    if (this.signals[signalName]) {
      this.signals[signalName].active = false
    }
  },

  initControllers: function (controllersList = undefined) {
    if (!this.viewport) {
      console.log('Error: this.viewport not set, unable to initControllers')
      return
    }

    this.controllers.init(this, controllersList)
  },

  saveControllerState: function () {
    if (this.controllersSavedState !== null) {
      console.log('saveControllerState() cancelled because controllerState already saved. Dangerous to overwrite.')
      console.log(this.controllersSavedState)
      return
    }

    this.controllersSavedState = this.controllers.getState()
  },

  revertControllerState: function () {
    if (!this.controllersSavedState) {
      console.log('Exit this.revertControllerState(): this.controllersSavedState not saved')
      return
    }

    this.controllers.loadState(this.controllersSavedState)
    this.controllersSavedState = null
  },

  manageController: function (controllerName, active, autoActivate) {
    this.controllers.setControllerState(controllerName, active, autoActivate)
  },

  setViewAligned: function (isAligned, view) {
    var selectedView = view || ViewHelper.selectedView()
    selectedView.isAligned = isAligned
    ViewHelper.saveView()
  },

  loadCamera: function (orientation, cameraParams, notTopDown, _cameraControllerTriggerDispatchEvent) {
    if (_cameraControllerTriggerDispatchEvent !== false) {
      _cameraControllerTriggerDispatchEvent = true
    }

    if (!cameraParams) {
      if (orientation) {
        cameraParams = ViewHelper.createCameraParams(orientation)
      } else {
        console.log('Error: loadCamera() called without orientation or cameraParams')
        return
      }
    }

    try {
      this.cameraCenter.fromArray(cameraParams.center)
    } catch (err) {
      console.log(
        'Warning: this.controls do not exist. Only ok in unit tests, otherwise add a viewport to create them.',
        err
      )
    }

    this.camera.position.fromArray(cameraParams.position)

    this.camera.up.fromArray(cameraParams.up)

    // console.log(
    //   'WARNING! Utils.lookAtSafe removed from loadCamera() because it will be called below by refreshCamera(). Delete message and commented lookAt code if everything else checks out'
    // )
    //Utils.lookAtSafe(this.camera, this.cameraCenter);

    this.metersPerPixel(cameraParams.metersPerPixel ? cameraParams.metersPerPixel : this.defaultMetersPerPixel)

    this.refreshCamera()

    if (this.controllers.Camera) {
      //this.controllers.Camera.orbit();
      this.controllers.Camera.reset(
        this.cameraCenter,
        this.camera.position,
        this.camera.up,
        _cameraControllerTriggerDispatchEvent
      )

      this.controllers.Camera.notTopDown = Boolean(notTopDown)
    }

    //This can fire while other things are updating and can break data...
    //this.signals.cameraChanged.dispatch( this.camera );
  },

  refreshCamera: function () {
    this.camera = this.createCamera(undefined, false)
  },

  matchCameraToMap: function () {
    var zoom = MapHelper.activeMapInstance.dom.getZoom()
    var latitude = MapHelper.activeMapInstance.mapData.center[1]
    var metersPerPixelFromMap = MapHelper.getMetersPerPixelForZoomAndLat(zoom, latitude)

    //If mpp already equal then don't perform any updates
    if (metersPerPixelFromMap != this.metersPerPixel()) {
      window.studioDebug && console.log('editor.matchCameraToMap updating')

      this.metersPerPixel(metersPerPixelFromMap)
      this.refreshCamera()
      this.signals.cameraChanged.dispatch()
      // this.render()
    } else {
      window.studioDebug && console.log('editor.matchCameraToMap not updating')
    }
  },

  getWindowDimensions: function () {
    //overridable using monkey patching for tests
    return [window.innerWidth, window.innerHeight]
  },

  tweenLeftMargin: function (endMargin) {
    if (editor.leftMarginPixels === endMargin) {
      // ignore if requested margin is already applied
      return
    }

    // Remove sidebar animations because they cause all kinds of trouble for detecting/setting latlons/locations
    var instant = true

    if (instant) {
      editor.setLeftMarginPixels(endMargin, true, true) // force scene to render
    } else {
      var startMargin = editor.leftMarginPixels
      var duration = 500
      var overrideRender = false
      createjs.Tween.get({})
        .to({}, duration)
        .call(function handleComplete() {
          editor.signals.animationStop.dispatch('tween', 'tweenLeftMargin')
        })
        .on('change', function () {
          var fraction = createjs.Ease.cubicInOut(this.position / this.duration)
          editor.setLeftMarginPixels(startMargin * (1 - fraction) + fraction * endMargin, true, overrideRender)
        })

      editor.signals.animationStart.dispatch('tween', 'tweenLeftMargin')
    }
  },

  leftMarginPixels: 0,

  setCameraViewOffset: function (left) {
    var s = new THREE.Vector2().fromArray(MapHelper.viewportSize())

    // Beware if MapHelper.viewportSize() is [0,0] it will lead to invalid setViewOffset params
    if (s.x > 0 && s.y > 0) {
      var widthWithMargins = s.x + left + left
      var frac = widthWithMargins / s.x
      params = [widthWithMargins, s.y * frac, left / 2, (s.y * frac - s.y) / 2, s.x, s.y]
      editor.camera.setViewOffset(...params)

      if (!left) {
        editor.camera.zoom = 1
      } else {
        editor.camera.zoom = 1 / frac
      }
      editor.camera.updateProjectionMatrix()
    } else {
      console.info('Notice: editor.setCameraViewOffset() ignored because MapHelper.viewportSize() not found or invalid')
    }
  },

  setLeftMarginPixels: function (left, overrideSnapshotHelperUpdate, overrideRender) {
    this.setCameraViewOffset(left)

    if (overrideSnapshotHelperUpdate !== false) {
      // for speed we can avoid this
      SnapshotHelper.leftMarginPixels = left
      SnapshotHelper.resize()
    }

    this.leftMarginPixels = left

    // Applies throttling if required for current mapType
    // This should not actually be required because other updates should handle this automatically
    // But we keep it here just in case it fixes some edges cases which may omit the call to refresh map position
    MapHelper.setMapPositionFromWorldPositionAtViewportCenter()

    if (overrideRender !== false) {
      this.render()
    }
  },

  createCamera: function (_metersPerPixel, dispatchSignals, createNewCamera, windowDimensions) {
    var cameraCenter, cameraToUpdate

    if (createNewCamera) {
      cameraCenter = new THREE.Vector3()
      cameraToUpdate = null
    } else {
      cameraToUpdate = this.camera
      cameraCenter = this.cameraCenter
    }

    if (!cameraToUpdate) {
      if (!windowDimensions) {
        windowDimensions = this.getWindowDimensions()
      }

      cameraToUpdate = new THREE.OrthographicCamera(
        windowDimensions[0] / -2,
        windowDimensions[0] / 2,
        windowDimensions[1] / 2,
        windowDimensions[1] / -2,
        -100,
        1000
      )
      cameraToUpdate.name = 'Camera'
      cameraToUpdate.up.copy(new THREE.Vector3(0, 1, 0))
      cameraToUpdate.zoom = 1
    }

    // If zoom has been updated due to having a margin set, ensure this is cleared before refreshing the camera
    // so it does not interfere.
    if (cameraToUpdate.zoom !== 1) {
      cameraToUpdate.zoom = 1

      // Updating project matrix is quite heavy and we do not seem to actually need this so it is disabled unless
      // we uncover any issues that require it to be called
      // cameraToUpdate.updateProjectionMatrix()
    }

    if (!this.viewport_container || this.viewport_container.dom.getBoundingClientRect().width == 0) {
      if (window.TESTING !== true) {
        window.studioDebug && console.log('viewport_container not found, using camera')
      }
      return cameraToUpdate
    }

    //Always set the width to fixed size (in meters) and let height be determined by aspect ratio
    var w = this.viewport_container.dom.getBoundingClientRect().width
    var h = this.viewport_container.dom.getBoundingClientRect().height

    if (_metersPerPixel) {
      this.metersPerPixel(_metersPerPixel)
    }

    if (!this.metersPerPixel()) {
      this.metersPerPixel(this.defaultMetersPerPixel)
    }

    //keep existing viewSize if already set
    var viewSize = this.getViewSizeFromMetersPerPixel(this.metersPerPixel())

    var aspectRatio = w / h

    var _viewport = {
      viewSize: viewSize,
      aspectRatio: aspectRatio,
      left: -viewSize / 2,
      right: viewSize / 2,
      top: viewSize / aspectRatio / 2,
      bottom: -viewSize / aspectRatio / 2,
      near: -100,
      far: 1000,
    }

    var _camera = new THREE.OrthographicCamera(
      _viewport.left,
      _viewport.right,
      _viewport.top,
      _viewport.bottom,
      _viewport.near,
      _viewport.far
    )

    _camera.zoom = cameraToUpdate.zoom
    _camera.name = cameraToUpdate.name
    _camera.position.copy(cameraToUpdate.position)
    _camera.up.copy(cameraToUpdate.up)
    //_camera.up.copy(new THREE.Vector3(0,1,0));

    //wtf? How can we create a camera without lookAt?
    //Beware this will be a duplicate when we call loadCamera() because it runs lookAtSafe first...
    //@TODO: Why are we referencing this.cameraCenter? Is this so we ensure new/refreshed cameras look at the
    //same location as before the update? Should we be able to detect this from the initial camera orientation?
    Utils.lookAtSafe(_camera, cameraCenter)

    _camera.updateMatrixWorld()
    _camera.updateProjectionMatrix()

    cameraToUpdate.copy(_camera)

    if (dispatchSignals !== false) {
      this.signals.sceneGraphChanged.dispatch()
      this.signals.cameraChanged.dispatch(_camera)
    }

    // Apply cameraViewOffset after rebuilding
    if (editor.leftMarginPixels) {
      this.setCameraViewOffset(editor.leftMarginPixels)
    }

    return cameraToUpdate
    //return _camera
  },

  getViewSizeFromMetersPerPixel: function (metersPerPixel) {
    return metersPerPixel * this.viewport_container.dom.getBoundingClientRect().width * this.camera.zoom
  },

  getViewDataFromScene: function (views) {
    var viewsData = views.slice().sort(function (a, b) {
      if (a.uuid > b.uuid) return -1
      if (a.uuid < b.uuid) return 1
      return 0
    })
    var result = {}
    viewsData.forEach(function (view) {
      result[view.uuid] = Utils.sortObjectKeys(view)
    })
    return result
  },

  metersPerPixel: function (value) {
    if (typeof value === 'undefined') {
      return this._metersPerPixel
    }

    this._metersPerPixel = value
  },

  setTheme: function (value) {
    document.getElementById('theme').href = value

    this.signals.themeChanged.dispatch(value)
  },

  setScene: function (scene) {
    this.scene.uuid = scene.uuid
    this.scene.name = scene.name

    if (scene.background !== null) this.scene.background = scene.background.clone()
    if (scene.fog !== null) this.scene.fog = scene.fog.clone()

    //@TODO: Verify this is no longer required because userData is only used for loading/saving?
    //this.scene.userData = JSON.parse( JSON.stringify( scene.userData ) );

    // avoid render per object

    this.signals.sceneGraphChanged.active = false

    while (scene.children.length > 0) {
      this.addObject(scene.children[0])
    }

    this.signals.sceneGraphChanged.active = true
    this.signals.sceneGraphChanged.dispatch()
  },

  addObject: function (object, parent, dispatchSignalsOverride) {
    if (!parent) {
      parent = this.scene
    }

    var scope = this

    object.traverse(function (child) {
      if (child.geometry !== undefined) scope.addGeometry(child.geometry)
      if (child.material !== undefined) scope.addMaterial(child.material)

      scope.addHelper(child)
    })

    parent.add(object)

    if (object.unshiftNewObject) {
      var newObject = parent.children.pop()
      parent.children.unshift(newObject)
    }

    if (dispatchSignalsOverride !== false) {
      this.signals.objectAdded.dispatch(object)

      if (object.ghostMode && object.ghostMode()) {
        // do not dispatch signals because this only a ghost, not actually part of the scene graph
        this.signals.sceneGraphChanged.dispatch()
      }
    }

    if (!editor.changingHistory) {
      this.checkForSystemUpdate(object)
    } else {
      console.log(
        'Skip this.checkForSystemUpdate(object) while changingHistory because assessSlots should be handled automatically by undo or redo'
      )
    }
  },

  moveObject: function (object, parent, before) {
    if (parent === undefined) {
      parent = this.scene
    }

    parent.add(object)

    // sort children array

    if (before !== undefined) {
      var index = parent.children.indexOf(before)
      parent.children.splice(index, 0, object)
      parent.children.pop()
    }

    this.signals.sceneGraphChanged.dispatch()
  },

  nameObject: function (object, name) {
    object.name = name
    this.signals.sceneGraphChanged.dispatch()
  },

  removeObject: function (object, dispatchSignalsOverride) {
    //if ( object.parent === null ) return; // avoid deleting the camera or scene
    if (object.type == 'Camera' || object.type == 'OsDesignerScene') return // avoid deleting the camera or scene

    if (!object.parent) {
      //console.log("Warning calling removeObject when object.parent is null. Aborting removeObject()", object)
      return
    }

    var scope = this

    object.traverse(function (child) {
      scope.removeHelper(child)
    })

    object.parent.remove(object)

    //This doesn't seem to get fired when deleting in studio... handled in objectRemoved instead
    // if (this.selectedSystem == object) {
    //   this.selectSystem(null)
    // }

    if (dispatchSignalsOverride !== false) {
      this.signals.objectRemoved.dispatch(object)

      if (object.ghostMode && object.ghostMode()) {
        // do not dispatch signals because this only a ghost, not actually part of the scene graph
        this.signals.sceneGraphChanged.dispatch()
      }
    }

    this.checkForSystemUpdate(object)
  },

  checkForSystemUpdate(fromObject) {
    let system
    while (fromObject && fromObject !== this.scene) {
      if (fromObject instanceof OsSystem) {
        system = fromObject
        break
      }
      fromObject = fromObject.parent
    }

    if (system) {
      system.objectsUpdated()
    }
  },

  addGeometry: function (geometry) {
    this.geometries[geometry.uuid] = geometry
  },

  setGeometryName: function (geometry, name) {
    geometry.name = name
    this.signals.sceneGraphChanged.dispatch()
  },

  addMaterial: function (material) {
    this.materials[material.uuid] = material
  },

  setMaterialName: function (material, name) {
    material.name = name
    this.signals.sceneGraphChanged.dispatch()
  },

  addTexture: function (texture) {
    this.textures[texture.uuid] = texture
  },

  addHelper: (function () {
    var geometry = new THREE.SphereBufferGeometry(2, 4, 2)
    var material = new THREE.MeshBasicMaterial({
      color: 0xff0000,
      visible: false,
    })

    return function (object) {
      var helper

      if (object.onAddHelper) {
        object.onAddHelper()
      }

      if (object instanceof THREE.Camera) {
        helper = new THREE.CameraHelper(object, 1)
      } else if (object instanceof THREE.PointLight) {
        helper = new THREE.PointLightHelper(object, 1)
      } else if (object instanceof THREE.DirectionalLight) {
        helper = new THREE.DirectionalLightHelper(object, 1)

        helper.visible = false
      } else if (object instanceof THREE.SpotLight) {
        helper = new THREE.SpotLightHelper(object, 1)
      } else if (object instanceof THREE.HemisphereLight) {
        helper = new THREE.HemisphereLightHelper(object, 1)
      } else if (object instanceof THREE.SkinnedMesh) {
        helper = new THREE.SkeletonHelper(object)
      } else {
        // no helper for this object type
        return
      }

      var picker = new THREE.Mesh(geometry, material)
      picker.name = 'picker'
      picker.userData.object = object
      helper.add(picker)

      this.sceneHelpers.add(helper)
      this.helpers[object.id] = helper

      this.signals.helperAdded.dispatch(helper)
    }
  })(),

  removeHelper: function (object) {
    if (object.onRemoveHelper) {
      object.onRemoveHelper()
    }
    if (this.helpers[object.id] !== undefined) {
      var helper = this.helpers[object.id]
      helper.parent.remove(helper)

      delete this.helpers[object.id]

      this.signals.helperRemoved.dispatch(helper)
    }
  },

  addScript: function (object, script) {
    if (this.scripts[object.uuid] === undefined) {
      this.scripts[object.uuid] = []
    }

    this.scripts[object.uuid].push(script)

    this.signals.scriptAdded.dispatch(script)
  },

  removeScript: function (object, script) {
    if (this.scripts[object.uuid] === undefined) return

    var index = this.scripts[object.uuid].indexOf(script)

    if (index !== -1) {
      this.scripts[object.uuid].splice(index, 1)
    }

    this.signals.scriptRemoved.dispatch(script)
  },

  select: function (object, ignoreSelectionDelegate) {
    if (this.selectionLockIsActive() && object !== this.selected) {
      // prevent the editor from selecting another object
      // when lock selection is active
      return
    }

    if (Array.isArray(object)) {
      let group = new OsGroup({ objects: object })
      this.scene.add(group)
      this.selected = group
      group.refreshPosition(this)
      this.signals.objectSelected.dispatch(group)
      return
    }

    if (
      object &&
      this.selected &&
      object !== this.selected &&
      this.selected.type !== 'OsSystem' &&
      window.shiftIsDown &&
      editor.controllers.AddObject &&
      !editor.controllers.AddObject.active &&
      object.ghostMode?.() !== true
    ) {
      let group

      if (object.selectionDelegate) {
        object = object.selectionDelegate
      }

      if (this.selected.type === 'OsGroup') {
        //already selected a group, add/remove object
        group = this.selected

        if (group.objects.indexOf(object) !== -1) {
          //remove if already included
          let index = group.objects.indexOf(object)
          if (index !== -1) {
            group.objects.splice(index, 1)
            object.onDeselect && object.onDeselect()
          }
          if (group.objects.length === 1) {
            const loneObject = group.objects[0]
            this.deselect()
            this.select(loneObject)
            return
          }
        } else if (object.type !== 'OsSystem') {
          //add to selected objects
          group.objects.push(object)
        }
      } else {
        group = new OsGroup({ objects: [this.selected, object] })
        this.scene.add(group)
        this.selected = group
      }
      //shift is down and another object has been selected, add to the selection instead of changing selection
      group.refreshPosition(this)
      // this.render()
      this.signals.objectSelected.dispatch(group)
      return
    }

    var previouslySelected = this.selected
    var dispatchObjectSelected = false

    //Standardize on null, avoid differences between undefined and null
    if (typeof object === 'undefined') {
      object = null
    }

    ////// Handle Selection Delegate /////
    //Check if click should select the delegate instead of the object itself
    // Recursively dig until we find an object without a selection delegate, or with an executable selectionDelegate

    if (object && object.selectionDelegate && ignoreSelectionDelegate !== true) {
      object = Designer.resolveSelectionDelegate(object, true)
    }

    ////// End Handle Selection Delegate /////

    if (!object && this.selected === this.selectedSystem) {
      // If we have deselected (i.e. not selected anything
      // but current selection is same as this.selectedSystem
      // then do not process the deselection
      return
    } else if (this.selected !== object) {
      //Even if already selected we may still need to selectSystem()
      var uuid = null

      if (object !== null && typeof object !== 'undefined') {
        uuid = object.uuid
      }

      if (this.selected) {
        if (this.selected.getFacets && this.selected.getFacets().length > 0) {
          if (this.selected.getFacets().indexOf(this.selectedPreviousFacet) != -1) {
            //previous facet is associated with this item, retain existing selectedPreviousOsFacet.
          } else {
            //select the first facet for this object.
            this.selectedPreviousFacet = this.selected.getFacets()[0]
          }
        }
      } else if (object.type == 'OsFacet') {
        //If placing a facet and no selectedPreviousFacet then save into there also.
        this.selectedPreviousFacet = object
      }

      this.selected = object

      // Config disabled until implemented properly
      //this.config.setKey('selected', uuid)

      //Call onSelect before objectSelected signal so any updates are available on signal handlers
      if (object && object.onSelect) {
        object.onSelect(editor)
      }

      // do not render yet, only after all updates have occurred
      // otherwise render may not reflect the correct final state
      dispatchObjectSelected = true
    }

    if (object && object.getSystem) {
      var systemForSelectedObject = object.getSystem()
      if (this.selectedSystem !== systemForSelectedObject && systemForSelectedObject) {
        this.selectSystem(systemForSelectedObject)
      }
    }

    //instead of deselecting we should select the current selectedSystem
    //only if this.selectedSystem not already selected! which aborts the selection above
    if (object === null && this.selectedSystem && this.selected !== this.selectedSystem) {
      console.log('Auto-select editor.selectedSystem on deselect')
      this.uiPauseUntilComplete(
        function () {
          this.select(this.selectedSystem)
        },
        this,
        'render',
        'renderPauseLock::autoSelectSystem'
      )
      dispatchObjectSelected = false
    }

    if (previouslySelected) {
      if (previouslySelected.onDeselect) {
        previouslySelected.onDeselect(editor)
      }

      // Ensure this does not crash if FingerPaint is not initialized
      if (previouslySelected.type === 'OsModuleGrid' && this.controllers.FingerPaint) {
        this.controllers.FingerPaint.storeGridPresets({
          slope: previouslySelected.getSlope(),
          azimuth: previouslySelected.getAzimuth(),
          orientation: previouslySelected.moduleLayout(),
          panelConfiguration: previouslySelected.panelConfiguration,
          panelTiltOverride: previouslySelected.panelTiltOverride,
          moduleSpacing: [...previouslySelected.moduleSpacing],
        })
      }
      if (this.selected !== previouslySelected) {
        this.signals.objectDeselected.dispatch(previouslySelected)
      }
    }

    if (dispatchObjectSelected === true) {
      this.signals.objectSelected.dispatch(object)
    } else {
      // If we have not yet rendered (e.g. using objectSelected.dispatch()) then render now
      this.render(true)
    }
  },

  selectById: function (id) {
    if (id === this.camera.id) {
      this.select(this.camera)
      return
    }

    this.select(this.scene.getObjectById(id, true))
  },

  selectByUuid: function (uuid) {
    var scope = this

    // Beware: Do not run scope.select(child) inside the traverse loop
    // because select() may result in a change to the children in the scene
    // which can cause errors if children.length changes inside the traverse loop
    var childToSelect = null

    this.scene.traverse(function (child) {
      if (child.uuid === uuid) {
        childToSelect = child
      }
    })

    if (childToSelect) {
      scope.select(childToSelect)
    }
  },

  selectNextObject: function (objectType, objects) {
    if (objects.length == 0) {
      return
    }

    //Find one position after currently selected object.
    //If selected object not in list (or not set) then this selects the first object
    //If last object is selected this wraps back to first object
    var nextPosition = (objects.indexOf(this.selected) + 1) % objects.length

    this.select(objects[nextPosition])
  },

  selectNextObjectOfType: function (objectType) {
    var objects = this.filter('type', objectType)

    return this.selectNextObject(objectType, objects)
  },

  lockSelection: function () {
    this.selectionLockActive = true
  },

  unlockSelection: function () {
    this.selectionLockActive = false
  },

  selectionLockIsActive: function () {
    return this.selectionLockActive
  },

  deleteObject: function (object, commandUUID) {
    if (object) {
      if (object.onDeleteOverride) {
        object.onDeleteOverride()
      } else {
        //update globalCommandUUID if necessary
        if (!commandUUID) {
          commandUUID = Utils.generateCommandUUIDOrUseGlobal()
        }

        // Remove any strung modules from their assigned string
        if (object.type === 'OsModuleGrid') {
          var strungModules = {}
          object.cellsActive.forEach(function (cell) {
            if (object.moduleObjects[cell].assignedOsString) {
              strungModules[cell] = object.moduleObjects[cell]
            }
          })
          if (Object.values(strungModules).length > 0) {
            // Only execute string-removal comand if any modules are strung
            editor.execute(new RemoveModulesFromStringCommand(strungModules, commandUUID))
          }
        }

        if (object.type === 'OsGroup') {
          object.handleDelete(window.editor, commandUUID)
        } else if (object.type === 'OsNode') {
          window.editor.execute(new RemoveNodeCommand(object, true, commandUUID))
        } else if (object.type === 'OsSystem') {
          object.handleDelete(window.editor, commandUUID)
        } else {
          window.editor.execute(new RemoveObjectCommand(object, true, undefined, commandUUID))
        }
      }
    }
  },

  deleteObjectByUuid: function (uuid) {
    this.deleteObject(this.objectByUuid(uuid))
  },

  deleteSelection: function (askConfirmation = true) {
    const object = this.selected
    if (!object) return
    if (object.type === 'OsSystem' && object._basicMode) return

    // ignore delete command if selected object is not removable
    if (object.toolsActive && object.toolsActive()?.delete === false) return

    const hasPermission = object.getPermissionCheck ? object.getPermissionCheck() : true

    if (!hasPermission) {
      Designer.showNotification('No permission to delete', 'danger')
      return
    }

    const canDeleteResult = object.canDelete ? object.canDelete() : true

    if (canDeleteResult !== true) {
      if (canDeleteResult === false) {
        Designer.showNotification('Unable to delete', 'danger')
      } else {
        Designer.showNotification(canDeleteResult, 'danger')
      }
      return
    }

    const confirmationPrompt = object.confirmBeforeDelete ? object.confirmBeforeDelete() : 'Delete ' + object.name + '?'

    if (confirm(confirmationPrompt) === false) return

    const parent = object.parent
    //Allow deletionn if object.onDeleteOverride is set, even if no parent because deletiong is
    //handled using some custom method
    if (parent !== null || object.onDeleteOverride) this.deleteObject(object)
  },

  deselect: function () {
    this.select(null)
  },

  getSystems: function () {
    return this.filter('type', 'OsSystem').sort(function (a, b) {
      return a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    })
  },

  selectSystemByUuid: function (uuid) {
    this.selectSystem(this.objectByUuid(uuid))
  },

  selectSystem: function (system) {
    this.selectedSystem = system

    this.filter('type', 'OsSystem').forEach(function (s) {
      var _visible = s == system

      if (s.visible != _visible) {
        //this.execute(new SetValueCommand(s, 'visible', _visible))
        //change in visibility required
        // if (editor.history.redos.length > 0) {
        s.visible = _visible
        editor.signals.objectChanged.dispatch(s, 'visible')
        // } else {
        //   s.visible = _visible
        //   //this.execute(new SetValueCommand(s, 'visible', _visible))
        // }
        if (system) {
          // Beware, system could be null when we are de-selecting the system
          OsOther.refreshVisibility(system.others())
        }
      }
    }, this)

    this.signals.systemSelected.dispatch(system)
  },

  focus: function (object) {
    this.signals.objectFocused.dispatch(object)
  },

  focusById: function (id) {
    this.focus(this.scene.getObjectById(id, true))
  },

  clear: function () {
    // Stop recording, save final update for recording, then start recording a new replay after scene is cleared
    var recordingInProgress = ReplayHelper.recordingInProgress
    if (recordingInProgress) {
      // Ensure we do not fire another event other than our final manual recording
      ReplayHelper.stopRecording(true)
    }

    //No need to use onRemove or onChange callbacks during clear, we will remove everything
    //We rely on viewport.editorCleared signal to clear out viewport.objects()
    //because this normally happens from objectRemoved signal
    this.signals.objectRemoved.active = false
    this.signals.sceneGraphChanged.active = false

    this.history.clear()
    this.storage.clear()

    this.scene.reset()

    if (!this.camera) {
      // Do not call loadCamera() unless we need to create a camera from scratch
      // otherwise it fires unnecessary events
      this.loadCamera('top')
    }

    var objects = this.scene.children

    while (objects.length > 0) {
      if (!objects[0].parent) {
        console.log('Removing an object with no parent: ' + objects[0])
      } else {
        this.removeObject(objects[0])
      }
    }

    this.geometries = {}
    this.materials = {}
    this.textures = {}
    this.scripts = {}

    this.unlockSelection()
    this.deselect()

    this.signals.objectRemoved.active = true
    this.signals.sceneGraphChanged.active = true

    this.signals.editorCleared.dispatch()

    if (recordingInProgress) {
      // Resume recording now that scene is cleared
      ReplayHelper.startRecording()
    }
  },

  dumpToConsole: function () {
    var sceneJSON = toJSON()
    console.log('sceneJSON', sceneJSON)
  },

  fromJSON: function (json) {
    // var loader = new THREE.ObjectLoader()

    // // backwards

    // if (json.scene === undefined) {
    //   this.setScene(loader.parse(json))
    //   return
    // }

    // var camera = loader.parse(json.camera)

    // this.camera.copy(camera)
    // //this.camera.aspect = this.camera.aspect;

    // console.log(
    //   'WTF??? Why can we not simply run this.camera.aspect = this.camera.aspect? We are calling this.camera.aspect = this.createCamera().aspect instead???'
    // )
    // this.camera.aspect = this.createCamera().aspect
    // this.camera.updateProjectionMatrix()
    try {
      this.history.fromJSON(json.history)
    } catch (e) {
      console.warn('Unable to rebuild history: ' + e)
    }
    // this.scripts = json.scripts

    // this.setScene(loader.parse(json.scene))
  },

  toJSON: function () {
    //scripts clean up
    this.refreshUserData()
    var scene = this.scene
    var scripts = this.scripts

    for (var key in scripts) {
      var script = scripts[key]

      if (script.length === 0 || scene.getObjectByProperty('uuid', key) === undefined) {
        delete scripts[key]
      }
    }
    return {
      metadata: {},
      project: {
        // gammaInput: this.config.getKey('project/renderer/gammaInput'),
        // gammaOutput: this.config.getKey('project/renderer/gammaOutput'),
        // shadows: this.config.getKey('project/renderer/shadows'),
        // vr: this.config.getKey('project/vr'),
      },
      camera: this.camera.toJSON(),
      scripts: this.scripts,
    }
  },

  objectByUuid: function (uuid) {
    return this.scene.getObjectByProperty('uuid', uuid, true)
  },

  execute: function (cmd, optionalName) {
    this.history.execute(cmd, optionalName)
  },

  undo: function () {
    var undos = this.history.undos
    var commandUUID = undos.slice(-1)[0] && undos.slice(-1)[0].commandUUID

    var undosInBatch = []
    var undoIsSupported = true

    for (var i = undos.length - 1; i > 0; i--) {
      // Only continue processing undos if commandUUID matches
      if (undosInBatch.length === 0 || undos[i].commandUUID === commandUUID) {
        if (undos[i].UNDO_NOT_SUPPORTED === true) {
          undoIsSupported = false
        }

        undosInBatch.push(undos[i])
      } else {
        // no more undos can be added to the batch, exit early
        break
      }
    }

    // Check the next batch of commands to ensure they all support undo
    if (undoIsSupported === false && window.Designer.showNotification) {
      window.Designer.showNotification('Undo not permitted for this action')
      return
    }

    do {
      this.history.undo()
    } while (undos.slice(-1)[0] && typeof commandUUID !== 'undefined' && undos.slice(-1)[0].commandUUID === commandUUID)
  },

  redo: function () {
    var redos = this.history.redos
    var commandUUID = redos.slice(-1)[0] && redos.slice(-1)[0].commandUUID
    do {
      this.history.redo()
    } while (redos.slice(-1)[0] && typeof commandUUID !== 'undefined' && redos.slice(-1)[0].commandUUID === commandUUID)
    if (redos.length === 0) {
      this.filter('type', 'OsSystem').forEach(function (OsSystem) {
        if (OsSystem.awaitingCalcs) {
          window.Designer.requestSystemCalculationsDebounced(OsSystem)
        }
      })
    }
  },

  filterObjects: function (func, firstOnly, parentObject) {
    var matches = []

    if (!parentObject) {
      parentObject = this.scene
    }

    if (!parentObject) {
      // Avoid throwing an exception. An example of how this could happen is if some code is running after
      // the scene has been cleared. Raising an exception could cause all kinds of unexpected failures, so
      // instead we will return an empty result which is safer and intuitively reasonable.
      if (firstOnly) {
        return null
      } else {
        return []
      }
    }

    parentObject.traverse(function (object) {
      if (firstOnly && matches.length == 1) {
        return //exit this iteration, not the whole parent function
      }

      if (func(object)) {
        matches.push(object)
      }
    })

    if (firstOnly) {
      if (matches[0]) {
        return matches[0]
      } else {
        return null
      }
    }

    return matches
  },

  filter: function (property, value, firstOnly, parentObject) {
    return this.filterObjects(
      function (o) {
        return o[property] == value
      },
      firstOnly,
      parentObject
    )
  },

  interactive: function (value, mode, enablePluginsOverride) {
    if (typeof value === 'undefined') {
      return this._interactive
    }

    if (value !== this._interactive) {
      SnapshotHelper.active = value
    }

    var controllerActivations = {
      General: value,
      Camera: value,
      // DeviceOrientation: value,
      // ModulePlacement: value,
      //StringModuleAssignment: false,
      ContextMenu: value,
      //AddObject: false,
      //CallbackStack: false,
      Annotation: true,
      Tooltip: mode === 'map' ? true : value,

      // Do not manage HandleController here, it is activated when object is selected
      // Handle: value,
    }

    for (var key in controllerActivations) {
      if (this.controllers && this.controllers[key]) {
        this.manageController(key, controllerActivations[key])
      } else {
        if (window.TESTING !== true) {
          window.studioDebug && console.log('Skip managing controller, not present', key)
        }
      }
    }

    //Enable/disable signals
    this.signals.historyChanged.active = value

    // Enable/disable plugin syncing
    // Proivde option to override whether plugins are enabled separately to scene interactivity
    // to ensure that we do not unload plugins when component-selection dialogs are used in studio
    // The scene should become non-interactive while dialogs are visible but plugins
    // should not be disabled because this causes issues such as:
    // - system modifications when plugin is disabled
    // - changes to components while plugin is temporarily disabled
    if (setAutoLoadPlugins) {
      setAutoLoadPlugins(value || enablePluginsOverride)
    }

    this._interactive = value
  },

  gridVisibility: function (value) {
    if (typeof value === 'undefined') {
      return this._gridVisibility
    }

    if (this._gridVisibility != value) {
      //Node.setGuidesVisibility( value );
      this.signals.showGridChanged.dispatch(value)
      this.signals.objectChanged.dispatch({})

      this._gridVisibility = value
    }
  },

  displayMode: null,
  designMode: 'hidden',

  visibilityByMode: {
    interactive: {
      //Show everything including inactive modules
      modulesActive: true,
      // modulesInactive: true,
      interactiveElements: true,

      // Note: These will never be visible by default, they only show when mounting tab is open
      otherComponentsWithModels: false,
    },
    presentation: {
      //Hide all interactive elements (nodes/facets/etc), show roof/wall textures and active modules
      modulesActive: true,
      // modulesInactive: false,
      interactiveElements: false,
      otherComponentsWithModels: false,
    },
    hidden: {
      modulesActive: false,
      interactiveElements: false,
      otherComponentsWithModels: false,
    },
  },

  // Must match both of these condtions (visibilityByMode and hideElements)
  showElements: {
    viewWidget: true,
  },

  setShowElements: function (values) {
    if (!values) {
      console.warn('Must pass an object to `setShowElements`')
      return
    }
    this.showElements = values
    this.checkElementVisibility()
  },

  checkElementVisibility: function () {
    var vis = this.visibilityByMode[this.displayMode]
    if (!vis) {
      console.warn('Failed to find visibility mode!')
      return
    }
    if (this.viewWidget) {
      this.viewWidget.visible = vis.interactiveElements && this.showElements.viewWidget
    }
  },

  setDesignMode: function (designMode) {
    if (this.designMode !== designMode) {
      var studioMode = designMode

      if (studioMode === 'hidden') {
        //@TODO: Unload data when hiding studio to reduce overhead, but consider keeping anything which will help to re-display quickly in future
        if (window.editor) {
          // Disabled clear(), we already clear editor when leaving the project section, Is that sufficient?
          // window.editor.clear()
          window.editor.setMode('hidden')
        } else {
          console.warn('window.editor not found, unable to clear/hide')
        }
        this.designMode = designMode
        return
      } else if (studioMode === 'explore') {
        //@TODO: Unload data when hiding studio to reduce overhead, but consider keeping anything which will help to re-display quickly in future
        if (window.editor) {
          // Disabled clear(), we already clear editor when leaving the project section, Is that sufficient?
          // window.editor.clear()
          window.editor.setMode('hidden')
        }
        // the difference between hidden and explore modes is that explore does not exit early
      } else if (studioMode === 'background') {
        if (window.editor) {
          window.editor.setMode('presentation')
        }
      }

      let displayMode = 'presentation'
      if (studioMode === 'studio' || studioMode === 'studioLite' || studioMode === 'explore')
        displayMode = 'interactive'

      //Note: This only sets the mode, it doesn't update the objects because they haven't actually loaded yet
      var discardChanges = true
      window.editor.setMode(displayMode, discardChanges)

      if (studioMode === 'background') {
        // Disabled opacity on DesignerContainer because we now handle this by modifying opacity of white overlay
        // $('#DesignerContainer').css('opacity', 0.4)
        // Why do we need to set viewport pointer events here? Shouldn't this be managed somewhere else?
        // $('#viewport').css('pointer-events', 'none')
      } else {
        // $('#DesignerContainer').css('opacity', '')
        // $('#viewport').css('pointer-events', 'all')
      }
    }
    this.designMode = designMode
  },

  setMode: function (mode, discardChanges) {
    var vis = this.visibilityByMode[mode]

    if (!vis) {
      throw new Error('editor: invalid mode (' + mode + ') for editor.setMode(mode)')
    }

    this.interactive(vis.interactiveElements)

    //Inactive modules: Just always leave these invisible, handled by rollovers etc unrelated to view mode
    // this.filterObjects(function(o){ return o.active==false }).forEach(function(o){
    // 	o.visible = vis.modulesInactive;
    // });

    //Show/Hide grid, helpers, nodes
    this.filter('type', 'OsModuleGrid').forEach(function (o) {
      o.refreshModules()
    })
    this.filter('type', 'OsOther').forEach(function (o) {
      o.visible = vis.interactiveElements && vis.otherComponentsWithModels
    })
    this.filter('type', 'OsNode').forEach(function (o) {
      if (o.isManagedByParent()) {
        o.visible = false
      } else if (!o.ghostMode()) {
        o.visible = vis.interactiveElements
      }
    })
    this.filter('type', 'OsObstruction').forEach(function (o) {
      o.visible = vis.interactiveElements
      if (mode === 'presentation' && typeof o.override_show_customer === 'boolean') {
        o.visible = o.override_show_customer
      }
    })
    this.filter('type', 'OsClipper').forEach(function (o) {
      o.visible = vis.interactiveElements
    })
    this.filter('type', 'OsTree').forEach(function (o) {
      if (mode === 'presentation' && typeof o.override_show_customer === 'boolean') {
        o.visible = o.override_show_customer
      }
    })

    this.filter('type', 'OsEdge').forEach(function (o) {
      // Wires are special edges which WILL be displayed in presentation mode if annotations are enabled
      /*
      @TODO: Fix this NAAASTY hack for detecting:
        a) viewing inside online proposal (Boolean(window.proposalData))
        b) viewing inside a document which is a proposal
      */
      if ((o.isWire() && window.documentType === 'proposal') || Boolean(window.proposalData)) {
        o.visible = false
      } else {
        o.visible = true
      }
    })
    this.filter('type', 'ArrowHelper').forEach(function (o) {
      o.visible = vis.interactiveElements
    })

    const measurementController = this.controllers?.Measurement
    if (!!measurementController) {
      if (vis.interactiveElements && measurementController?.getMeasurements().length > 0) {
        measurementController.activate()
      } else {
        measurementController.deactivate()
      }
    }

    this.displayMode = mode

    this.checkElementVisibility()

    this.sceneHelpers.visible = vis.interactiveElements

    OsFacetMesh.setVisibilityByType('interactiveElements', vis.interactiveElements)

    // We can automatically hide OsStrings but we will never automatically show them
    if (!vis.interactiveElements) {
      OsString.visible(false)
    }

    //only force grid to hide, never show it here
    // if(this.grid) this.grid.visible = false;

    // var display = (vis) ? '' : 'none'
    // $('#menubar').css('display',display)
    // $('#sidebar').css('display',display)
    //

    OsModuleGrid.displayMode = mode

    this.signals.cameraChanged.dispatch()

    //Force update of map modes which may have been disabled in active view by previous state
    if (mode === 'interactive') {
      //Always assume both map layers should be active when interactive
      window.studioDebug && console.log('Forcing change to default state of imagery and map active')

      //Workaround error when loading new quotes, mapData may not yet be loaded
      //OMFG too complicated

      // Note: MapHelper may not yet have activeMapInstance if map library is still loading
      // MapHelper now automatically applyes interactive() to itself in MapHelper.setActiveMap()
      // Is this possibly redundant? Can we consolidate into a single place?
      if (MapHelper && MapHelper.activeMapInstance) {
        window.Designer.changeControl('both', discardChanges)
      }
    } else {
      if (MapHelper) {
        MapHelper.interactive(false)
      }

      // Force show/hide logic on edges if planset style
      // This is not nicely structured and could cause major issues
      if (Designer.style === 'planset') {
        this.signals.viewsChanged.dispatch(ViewHelper.views, ViewHelper.selectedViewUuid())
      }
    }

    this.signals.displayModeChanged.dispatch(mode)
  },

  refreshUserData: function () {
    //Save any properties back into userData
    //Allows us to store properties directly on the objects and only worry about userData when saving/loading
    //Currently optional, it will be applied if the object has a `refreshUserData` method.
    this.scene.traverse(function (o) {
      if (o.refreshUserData) {
        o.refreshUserData()
      }
    })
  },

  applyUserData: function (objects, opts) {
    const logs = {
      warnings: [],
      errors: [],
    }

    const toRemove = []
    const stripObject = (obj) => {
      return {
        uuid: obj.uuid,
        type: obj.type,
        name: obj.name,
        userData: obj.userData,
      }
    }

    const attemptApplyObjectUserData = (o) => {
      if (opts?.skipEphemerals && o.userData?.ephemeral) return
      if (opts?.skipTypes.includes(o.type)) return
      if (!o.applyUserData) return

      const validationResults = window.ObjectBehaviors.validateUserData.call(o)
      validationResults.forEach((v) => {
        logs.warnings.push({
          warning: v,
          object: stripObject(o),
        })
      })

      try {
        if (opts?.onBeforeApply) {
          opts.onBeforeApply(o)
        }
        o.applyUserData()
        if (opts?.onAfterApply) {
          opts.onAfterApply(o)
        }
      } catch (error) {
        // mark object for removal if application of user data fails
        toRemove.push(o)
        logs.errors.push({
          error,
          object: stripObject(o),
          objectRemoved: true,
        })
        // change the UUID of this object so that any attempts
        // by other objects to link to this object will fail
        o.uuid = 'FOR_REMOVAL'
      }
    }

    if (Array.isArray(objects)) {
      objects.forEach(attemptApplyObjectUserData)
    } else {
      this.scene.traverse(attemptApplyObjectUserData)
    }

    toRemove.forEach((o) => {
      this.removeObject(o, false)
      if (o.onRemove) {
        o.onRemove(this)
      }
    })

    //only execute when component specs data ready
    if (AccountHelper.isLoaded()) {
      // this force update module specs for all systems
      // ensure system modules use correct specs
      const systems = this.getSystems()
      systems.forEach((system) => {
        system.reloadModuleSpecs()
      })

      AutoApplyHelper.detectAndApplyAutoSync()
    }

    return logs
  },

  sceneSystemsOnlyAsJSON: function (systemUuids, skipRefreshUserData) {
    // if systemUuids no supplied or empty, include all systems
    if (typeof systemUuids === 'undefined') {
      systemUuids = []
    }

    // Automatically refreshes userData and converts to JSON
    // Optionally disable refresh if we know it has already been refreshed
    if (skipRefreshUserData !== true) {
      this.refreshUserData()
    }

    var sceneJSON = this.sceneAsJSON()

    sceneJSON['object']['children'] =
      sceneJSON['object']['children'] &&
      sceneJSON['object']['children'].filter(function (o) {
        return o.type === 'OsSystem' && (systemUuids.length === 0 || systemUuids.indexOf(o.uuid) !== -1)
      })

    //Strip views which can include massive uploaded images
    //userData is not always set so avoid throwing an exceptions
    if (sceneJSON && sceneJSON['object'] && sceneJSON['object']['userData']) {
      sceneJSON['object']['userData']['views'] = null
    }

    return sceneJSON
  },

  sceneAsJSON: function () {
    //Automatically refreshes userData and converts to JSON
    this.refreshUserData()

    var sceneJSON = this.scene.toJSON()
    sceneJSON['version'] = this.version

    // Record pro-us version in use
    sceneJSON['features'] = { ux: window.uxVersion }

    return sceneJSON
  },

  serializeDesign: function () {
    return window.CompressionHelper.compress(JSON.stringify(this.sceneAsJSON()))
  },

  deserializeDesign: function (designData) {
    return window.CompressionHelper.decompress(designData, true)
  },

  edges: function () {
    var edges = []
    this.filter('type', 'OsFacet').forEach(function (f) {
      edges = edges.concat(f.getEdges())
    })
    return edges
  },

  orderForNextSystem: function () {
    if (this.getSystems().length > 0) {
      return (
        Math.max.apply(
          null,
          this.getSystems().map(function (s) {
            return s.order
          })
        ) + 1
      )
    } else {
      return 0
    }
  },

  createObject: function (type, object, parent, options, overrideSelect, isDuplicating) {
    switch (type) {
      case 'OsFacet':
        if (!object) {
          object = new OsFacet(options)
        }
        this.execute(new AddObjectCommand(object, parent, true))
        this.select(object)
        this.selectedPreviousFacet = object
        return object

      case 'OsSystem':
        if (!object) {
          if (!options) {
            options = {}
          }
          options.order = this.orderForNextSystem()
          if (options.basicMode === undefined) {
            options.basicMode = SceneHelper.useBasicModeForNewSystem()
          }
          options.unstrungModulesInverterEfficiency =
            AccountHelper && AccountHelper.getDefaultInverterEfficiency
              ? AccountHelper.getDefaultInverterEfficiency()
              : null

          if (!isDuplicating) {
            options.autoSync = {
              pricingScheme: true,
              costing: true,
              adders: true,
            }
          }

          object = new OsSystem(options)
          if (isDuplicating) {
            object.isDuplicating = true
          }
        }
        if (options.isDuplicating) {
          object.isDuplicating = true
        }
        this.execute(new AddObjectCommand(object, parent, true))
        return object

      default:
        if (!object) {
          object = new window[type](options)
        }
        this.execute(new AddObjectCommand(object, parent, overrideSelect || true))
        return object
    }
  },

  getGround: function () {
    //Avoid searching whole scene just to find the ground each time
    //If ground is removed the parent will be cleared which will invalidate the reference
    if (this.cachedGroundReference && this.cachedGroundReference.parent) {
      return this.cachedGroundReference
    }

    //If no cached reference then save it now
    this.cachedGroundReference = this.filter('type', 'OsGround')[0]

    return this.cachedGroundReference
  },

  getTerrain: function () {
    return this.scene && this.scene.terrain ? this.scene.terrain : null
  },

  getTerrainWhenReady: async function () {
    const WAIT_FOR_TERRAIN_TIMEOUT = 20000

    if (!ViewHelper.has3DView()) {
      return null
    }

    if (this.waiting?.terrainFirstRender === false) {
      return this.getTerrain()
    }

    var retrieveTerrainFirstRenderIsComplete = () => {
      // wait for terrainFirstRender to become false. This should only be used when terrainFirstRender is initially true
      return { value: false, isReady: this.waiting?.terrainFirstRender === false }
    }
    return Utils.getValueWhenReady(retrieveTerrainFirstRenderIsComplete, WAIT_FOR_TERRAIN_TIMEOUT).then(() => {
      return this.getTerrain()
    })
  },

  getGroundElevation: function () {
    if (this.getGround()) {
      return this.getGround().position.z
    } else {
      return 0
    }
  },

  getBaseElevation: function () {
    /*
    If terrain loaded, use the terrain bounding box Z, otherwise use ground elevation which is almost always z=0
    */
    var t = editor.getTerrain()
    return (t ? t.minZ() : null) || this.getGroundElevation()
  },

  render: function (forceClear, forceRenderEvenWhenNotActive) {
    this.viewport.render(forceClear, forceRenderEvenWhenNotActive)
  },

  renderIfNotAnimating: function () {
    this.viewport.renderIfNotAnimating()
  },

  designNewlyCreated: true,

  loadTexturedDSM: async function (
    dsmUrlRaw,
    orthoUrlRaw,
    terrainPosition,
    onLoad,
    onError,
    refreshUrlUsingProxy,
    overrideElevations,
    useRawUrls
  ) {
    /*
    Possible outcomes:

    Nearmap PAYG:
      - Load from client/javascript cache
      - Nearmap PAYG Image: Route through proxy which appends existing or refreshed transactionToken from database

    Nearmap NMOS:
      - Load from client/javascript cache
      - Nearmap NMOS Image: Route through proxy which appends shared API Key

    Google3D (Legacy Sunroof):
      - Load from client/javascript cache
      - Route through reformat image proxy (ortho only, not dsm. sen original cleaned url to proxy)
      - Refresh expired URL (use original cleaned url)

    Google3D (Google Solar API):
      - Load from client/javascript cache
      - Route through maps proxy (but also append project_id, we may modify this later to improve it)

    GetMapping3D:
      - Load from client/javascript cache
      - Route through proxy which appends existing or refreshed transactionToken from database
        transaction token is generated by OpenSolar API, not a 3rd party. But thankfully the image can be retrieved
        directly in jpg format so no reformatting is required (unlike Google3D). The only reason we use a proxy is to
        protect GetMapping credentials and apply time constraints.
    */
    var _this = this

    var projectId = WorkspaceHelper?.project?.id

    var onProgressDsm = function (event) {
      if (event.lengthComputable && event.total > 0) {
        _this.setProgress('dsm', event.loaded / event.total)
      }
    }

    var onProgressOrtho = function (event) {
      if (event.lengthComputable && event.total > 0) {
        _this.setProgress('ortho', event.loaded / event.total)
      }
    }

    var dsmUrl = dsmUrlRaw
    var orthoUrl = orthoUrlRaw

    var isNewGoogle3D
    var dsmUrlRequestParts
    var orthoUrlRequestParts

    if (useRawUrls) {
      // do not transform URLs. Used during unit tests to point directly to a known available file.
    } else {
      try {
        let dsmUrlRequestPayload
        let orthoUrlRequestPayload

        if (!dsmUrl.includes('getmapping')) {
          // Split the URL and extract the payload if 'getmapping' is not in the URL because the new high res getmapping URL has string "?request="
          dsmUrlRequestPayload = dsmUrl.split('?request=')[1]
          orthoUrlRequestPayload = orthoUrl.split('?request=')[1]
        }

        // Check that request querystring param is supplied before trying to parse it
        if (dsmUrlRequestPayload) {
          dsmUrlRequestParts = JSON.parse(window.atob(dsmUrlRequestPayload))
        }

        if (orthoUrlRequestPayload) {
          orthoUrlRequestParts = JSON.parse(window.atob(orthoUrlRequestPayload))
        }

        // Check the provider and set the isNewGoogle3D flag
        isNewGoogle3D = dsmUrlRequestParts?.provider === 'google_solar_api'
      } catch (e) {
        console.error('Error parsing request param', dsmUrl, orthoUrl, e)
      }

      if (isNewGoogle3D) {
        dsmUrlRequestParts['project_id'] = projectId
        orthoUrlRequestParts['project_id'] = projectId

        if (window?.isFeatureEnabled?.('preload_gsa')) {
          dsmUrlRequestParts['wait_for_preload_cache'] = 'true'
          orthoUrlRequestParts['wait_for_preload_cache'] = 'true'
        }

        dsmUrl = dsmUrl.split('?request=')[0] + '?request=' + window.btoa(JSON.stringify(dsmUrlRequestParts))
        orthoUrl = orthoUrl.split('?request=')[0] + '?request=' + window.btoa(JSON.stringify(orthoUrlRequestParts))
      } else {
        var dsmUrlWithRefreshRedirection =
          !this.designNewlyCreated && !THREE.TerrainLoader.checkCache(SceneHelper.cleanUrlForTerrainEndpoint(dsmUrlRaw))
            ? SceneHelper.refreshUrl(dsmUrlRaw)
            : SceneHelper.cleanUrlForTerrainEndpoint(dsmUrlRaw)
        var orthoUrlWithRefreshRedirection =
          !this.designNewlyCreated &&
          !LoadTextureWithHeadersResponseCache[SceneHelper.cleanUrlForTerrainEndpoint(orthoUrlRaw)]
            ? SceneHelper.refreshUrl(orthoUrlRaw)
            : SceneHelper.cleanUrlForTerrainEndpoint(orthoUrlRaw)

        // If dsmURL or orthoUrl is missing transactionToken we will route through the API proxy which will invisibly
        // inject the transactionToken and retrieve the result.
        // Note that this is incompatible with SceneHelper.routeThroughImageReformatProxy() which is thankfully only used
        // for Google
        var routeThroughProxyIfMissingTransactionToken = function (
          _imageUrlRaw,
          _imageUrlWithRefreshRedirection,
          projectId
        ) {
          if (_imageUrlRaw.includes('google')) {
            // ignore google urls and return url without redirection, it will get redirected below if necessary
            return SceneHelper.refreshUrl(_imageUrlRaw)
          } else if (_imageUrlRaw.includes('getmapping')) {
            return MapHelper.downloadUrlToProxyUrl(_imageUrlRaw, projectId, 'getmapping')
          } else if (_imageUrlRaw.includes('vexcel')) {
            return MapHelper.downloadUrlToProxyUrl(_imageUrlRaw, projectId, 'vexcel')
          } else if (
            !_imageUrlRaw.includes('transactionToken=') ||
            _imageUrlRaw.endsWith('transactionToken=') ||
            _imageUrlRaw.includes('transactionToken=&')
          ) {
            return MapHelper.downloadUrlToProxyUrl(_imageUrlRaw, projectId, 'nearmap')
          } else {
            return _imageUrlWithRefreshRedirection
          }
        }
        dsmUrl = routeThroughProxyIfMissingTransactionToken(dsmUrlRaw, dsmUrlWithRefreshRedirection, projectId)
        orthoUrl = routeThroughProxyIfMissingTransactionToken(orthoUrlRaw, orthoUrlWithRefreshRedirection, projectId)

        // Invisibly replace the google ortho image tiff url to a converter proxy url
        // we still store the original url but we call the proxy url
        // The target URL must be public (not require authentication header)
        // Luckly, we only need to use this proxy method for Google (which does not require authentication)
        // Nearmap requires authentication but does not need a proxy because it provides a JPG format.
        if (orthoUrl.indexOf('getPixels') !== -1) {
          if (LoadTextureWithHeadersResponseCache[orthoUrl]) {
            // do not route through proxy, the image is already available in cache
          } else {
            orthoUrl = SceneHelper.routeThroughImageReformatProxy(orthoUrl)
          }
        }
      }
    }

    if (!window.loadTexturedDSMCount) {
      window.loadTexturedDSMCount = 1
    } else {
      window.loadTexturedDSMCount++
    }

    if (window.loadTexturedDSMCount >= 1000) {
      console.error(
        'editor.loadTexturedDSM() cancelled. Maximuim limit (' +
          window.loadTexturedDSMCount +
          ') reached per session for flood protection'
      )
      return
    }

    this.waiting.terrainDsm = true
    this.waiting.terrainTexture = true
    this.waiting.terrainFirstRender = true

    // This stores whether we are loading terrain from a saved state, versus when we are adding terrain to the scene
    this.waiting.terrainDsmFromSavedState = !!editor.sceneIsLoading

    this.setProgress('all', 0)

    //See http://blog.mastermaps.com/2013/10/terrain-building-with-threejs-part-1.html

    // Sample:
    // dsmUrl = './terrain/trueortho_3857_1.jpg'
    // orthoUrl = './terrain/dsm_3857_1.tif'

    var terrainLoader = new THREE.TerrainLoader()

    var displayTerrainIfReady = function () {
      if (!_this.waiting.terrainDsm && !_this.waiting.terrainTexture) {
        _this.render()

        _this.waiting.terrainFirstRender = false
        _this.setProgress('render', 1)

        _this.signals.viewsChanged.dispatch()
        _this.waiting.terrainDsmFromSavedState = false
      }
    }

    // Start loading texture before DSM even starts so they download in parallel
    var terrainTextureBase = new THREE.Texture()
    terrainTextureBase.minFilter = THREE.LinearFilter

    var texture = window.LoadTextureWithHeaders(
      orthoUrl,
      function (_image) {
        _this.uiPause('ui', 'uiLoadTextureWithHeaders')
        _this.uiPause('render', 'renderLoadTextureWithHeaders')

        // terrainMaterial creation assumes that texture comes from a standard image (e.g. jpg)
        _this.waiting.terrainTexture = false
        _this.setProgress('ortho', 1)
        displayTerrainIfReady()

        OsTerrain.processDSMAndOrthoWhenLoaded(editor)

        if (!_this.terrainSettings.wallBlurringActive) {
          // Wall blurring calls render itself, so we only need to render here if wall blurring disabled
          _this.render()
        }

        _this.uiResume('ui', 'uiLoadTextureWithHeaders', true) // ensure final react render on completion
        _this.uiResume('render', 'renderLoadTextureWithHeaders', true) // calls render() only if we missed a render
      },
      onProgressOrtho,
      (e) => {
        _this.uiResume('ui', 'uiTerrainLoaderOnLoad')
        _this.uiResume('render', 'renderTerrainLoaderOnLoad')
      },

      // We may hit our own API for refreshing the URL, or the target API directly
      // We only inject auth headers if we are targetting our own API
      orthoUrl.indexOf(API_BASE_URL) !== -1 ? Utils.tokenAuthHeaders() : null,
      undefined,
      terrainTextureBase
    )

    terrainLoader.load(
      dsmUrl,
      async function (tiffContents) {
        _this.uiPause('ui', 'uiTerrainLoaderOnLoad')
        _this.uiPause('render', 'renderTerrainLoaderOnLoad')

        var terrainPosition = _this.scene.terrainPosition
        var terrainRotationZ = _this.scene.terrainRotationZ

        var terrain = await OsTerrain.onLoadDsm(tiffContents, terrainPosition, terrainRotationZ, texture, _this)

        if (terrain) {
          // only do this when the creation of the terrain didn't fail
          _this.scene.terrain = terrain
          _this.addObject(terrain)
        }

        _this.waiting.terrainDsm = false
        _this.setProgress('dsm', 1)
        displayTerrainIfReady()

        OsTerrain.processDSMAndOrthoWhenLoaded(_this)

        if (onLoad) {
          onLoad()
        }

        editor.signals.terrainLoaded.dispatch()

        _this.uiResume('ui', 'uiTerrainLoaderOnLoad', true) // ensure final react render on completion
        _this.uiResume('render', 'renderTerrainLoaderOnLoad', true) // calls render() only if we missed a render
      },

      onProgressDsm ? onProgressDsm : null,
      (e) => {
        _this.uiResume('ui', 'uiTerrainLoaderOnLoad')
        _this.uiResume('render', 'renderTerrainLoaderOnLoad')
        if (onError) {
          onError(e)
        }
      },

      // We may hit our own API for refreshing the URL, or the target API directly
      // We only inject auth headers if we are targetting our own API
      //
      // Note we do NOT authenticate for the redirect header due to problems
      // with forwarding our authentication bearer token onto the final destination.
      // Google fails if token is sent. Nearmap succeeds but we should not send tokens to them anyway.
      //
      // Broken:
      // dsmUrl.indexOf(API_BASE_URL) !== -1 ? Utils.tokenAuthHeaders() : null
      //
      // Workaround (now disabled because we need to send headers to proxy urls):
      // null
      //
      // We must send tokens when routing through our proxy
      // We must strip them in the proxy to ensure they are not forwarded to 3rd party
      dsmUrl.indexOf(API_BASE_URL) !== -1 && dsmUrl.indexOf('/proxy/') !== -1 ? Utils.tokenAuthHeaders() : null
    )
  },

  loadStarterScene: function (params) {
    this.loadScene(this.starterSceneData, params)
  },

  loadScene: function (sceneData, params) {
    const _this = this
    params = params || {}

    if (window.designData) {
      // for testing purposes
      sceneData = window.designData
    }

    const onFinishPromise = Promise.withResolvers()
    const logs = {
      warnings: [],
      errors: [],
    }

    onFinishPromise.promise.then(function (logs) {
      _this.signals.objectSelected.active = true

      _this.uiResume('annotation', 'editor.loadScene', false)
      _this.uiResume('render', 'editor.loadScene')
      _this.uiResume('ui')

      _this.sceneIsLoading = false

      _this.signals.sceneLoaded.dispatch()
    })

    const prepareStudio = () => {
      // Workaround bug when customer loads proposal with PAYG imagery, they need to know the project id so they can
      // check the imagery cache
      if (params.id && !window.projectForm) {
        if (!WorkspaceHelper?.project?.id) {
          if (!WorkspaceHelper?.project) {
            WorkspaceHelper.project = {}
          }
          WorkspaceHelper.project.id = params.id
        }
      }

      // Optimisation to prevent renders during loadScene. Any renders which are necessary for building the scene
      // will be fired manually so they will not be broken by this optimization
      this.uiPause('render', 'editor.loadScene')

      // Optimisation to prevent AnnotationController.handleObjectSelected from firing repeatedly while loading the scene
      _this.uiPause('annotation', 'editor.loadScene')

      this.designNewlyCreated = false

      // Apply scaleInflationFactor if set
      if (params.scaleInflationFactor && params.scaleInflationFactor !== 1) {
        sceneData.object.userData.views[0].mapData.zoomTarget = scaleToZoom(
          zoomToScale(sceneData.object.userData.views[0].mapData.zoomTarget) * params.scaleInflationFactor
        )
        sceneData.object.userData.views[0].cameraParams.metersPerPixel *= 1 / params.scaleInflationFactor
      }

      this.sceneIsLoading = true

      if (MapHelper) MapHelper.clearMaxDepths()

      this.uiPause('ui')

      this.signals.objectSelected.active = false

      OsFacet.applyUserDataEnabled = false
      OsFacet.onChangeEnabled = false
    }

    const parseSceneData = () => {
      try {
        this.clear()
        this.loader.handleJSON(sceneData)
        this.history.undos = []
        if (window.saveUndoHistory) {
          try {
            this.history.undos = []
            sceneData.object.userData.historyData && editor.history.fromJSON(sceneData.object.userData.historyData)
          } catch (e) {
            console.warn('Unable to retrieve history: ' + e)
          }
        }
      } catch (err) {
        //Tidy up before bubbling error
        OsFacet.onChangeEnabled = true
        this.signals.objectSelected.active = true
        this.sceneIsLoading = false
        OsFacet.applyUserDataEnabled = true

        this.uiResume('annotation', 'editor.loadScene', false)
        this.uiResume('render', 'editor.loadScene')

        throw err
      }

      // Store the uxVersion that was loaded just in case we need to make any adjustments based on older versions
      this.scene.loadedFromUxVersion = sceneData?.features?.ux

      // Update any fields which may have been updated at the project level
      // Apply to userData because it will be applied to the scene object below
      if (params.hasOwnProperty('timezoneOffset')) {
        // Let the value in the scene take precedence if it is already set
        // This ensures that an existing design which uses a modified value in Summary > Advanced > TimezoneOffset
        // will keep that value when the design is loaded again later
        // Beware that if there is a change to the auto-detected timezone on the project (e.g. due to change in lat/lon)
        // then the timezoneOffset will NOT get updated automatically for an already saved design. It will only be applied
        // if the user restarts the design or uses startDesignMode again.
        if (!this.scene.userData.timezoneOffset) {
          this.scene.userData.timezoneOffset = params.timezoneOffset
        }
      }

      // Do not overwrite a roof type override in the scene from the project data
      // For now we want to allow scene to override the roof type on the project
      // because this is only for visual purposes.
      // Therefore, we do not refresh roofType if roofType is already set on the scene
      // @TODO: We should fix this by:
      //    a) save the roof type change back onto the project model on save
      //    b) re-enabling updates based on project data
      if (params.hasOwnProperty('roofTypeId')) {
        if (this.scene.userData.roofTypeId) {
          // keep existing scene roof type as an override
        } else {
          this.scene.userData.roofTypeId = params.roofTypeId
        }
      }
    }

    const relinkSceneObjects = () => {
      //////////////////////////////////////////////////////
      // STEP 1: Re-link the environmental objects :
      // Scene, Nodes, Edges, Facets, Structures
      //////////////////////////////////////////////////////

      OsFacet.applyUserDataEnabled = true
      //OPTIMISATION!!!
      OsFacet.onChangeEnabled = false

      var nodes = this.filter('type', 'OsNode')
      var edges = this.filter('type', 'OsEdge')
      var facets = this.filter('type', 'OsFacet')
      var obstructions = this.filter('type', 'OsObstruction')
      var structures = this.filter('type', 'OsStructure')

      const logsForEnvObjectsNoStructs = this.applyUserData([...nodes, ...edges, ...facets, ...obstructions], {
        skipEphemerals: true,
        skipTypes: [],
      })
      logs.warnings.push(...logsForEnvObjectsNoStructs.warnings)
      logs.errors.push(...logsForEnvObjectsNoStructs.errors)

      edges.forEach((edge) => {
        edge.onChange(this)
      })

      //Second pass on facets calls facet.onChange now that all objects should be linked
      OsFacet.onChangeEnabled = true
      facets.forEach(function (facet) {
        //facet.refreshMesh(editor);
        facet.onChange(this)
      })

      // Only build structures after facets are already created so they can float correctly
      // Sort structures so that structures with floating objects are processed first
      // Beware: if there are multiple levels of floating objects some objects may get created before the facet
      // they should be floating on.
      var structuresWithFloatingObjects = structures.filter(function (s) {
        return !!s.userData.objectsFloatingOnFacets.find((floatingObjects) => floatingObjects.length > 0)
      })
      var structuresWithNoFloatingObjects = structures.filter(function (s) {
        return !structuresWithFloatingObjects.includes(s)
      })
      var structuresSorted = structuresWithFloatingObjects.concat(structuresWithNoFloatingObjects)

      const logsForEnvObjectsStructsOnly = this.applyUserData(structuresSorted, {
        onAfterApply: (struct) => struct.rebuild(),
        skipEphemerals: false,
        skipTypes: [],
      })
      logs.warnings.push(...logsForEnvObjectsStructsOnly.warnings)
      logs.errors.push(...logsForEnvObjectsStructsOnly.errors)

      //////////////////////////////////////////////////////////////////////////////
      //// STEP 1: Link the non-environmental objects : Modules, ModuleGrids, etc.
      //////////////////////////////////////////////////////////////////////////////

      // we defer the application of userData of OsStructure instances
      // to later, when OsFacet instances are ready to be linked
      const logsForOtherTypes = this.applyUserData(null, {
        skipTypes: ['OsNode', 'OsEdge', 'OsFacet', 'OsObstruction', 'OsStructure'],
        skipEphemerals: true,
      })
      logs.warnings.push(...logsForOtherTypes.warnings)
      logs.errors.push(...logsForOtherTypes.errors)

      SceneHelper.addAnchorAndLights(this)

      if (this.scene?.horizon?.length > 0) {
        SceneHelper.setupHorizon()
      }

      // Force render to ensure intersections can be found between PanelGroups and Facets
      // So they can be re-floated
      // We do not automatically render because it's wasteful during scene creation
      this.render(false, true)

      //Second pass on moduleGrids attempts to link to a facet
      //and repopulate modulePositions and moduleObjects based on child SystemModules
      this.uiPause('render', 'moduleGrid.refreshFromChildren')
      this.filter('type', 'OsModuleGrid').forEach(function (moduleGrid) {
        moduleGrid.refreshFromChildren(this, true)
      }, this)
      this.uiResume('render', 'moduleGrid.refreshFromChildren')
    }

    const reselectSystem = () => {
      // Select first system synchronously now
      // Do not wait for views to be ready because views do not affect the scene whatsoever
      // and we may require editor.selectedSystem to be populated before they are ready
      if (editor.displayMode === 'presentation') {
        //auto-select the first system with show_customer===true

        var systems = editor.filterObjects(function (o) {
          return o.type === 'OsSystem' && o.show_customer === true
        })

        if (params && params.hasOwnProperty('selectedSystemUuid')) {
          this.selectSystemByUuid(params.selectedSystemUuid)
        } else if (systems.length > 0) {
          this.select(systems[0])
        }
      } else {
        if (params && params.hasOwnProperty('selectedSystemUuid')) {
          this.selectSystemByUuid(params.selectedSystemUuid)
        } else {
          var sys = editor.filter('type', 'OsSystem')
          if (sys.length > 0) {
            // OK if objectSelected is still disabled.
            // If this is problematic we can add listener for sceneLoaded

            // Don't just enable the system, actually select it which also enables it
            // editor.selectSystem(systems[0])
            this.select(sys[0])
          }
        }
      }
    }

    const recreateViews = async () => {
      const selectedViewUuid = params.selectedViewUuid || sceneData.object.userData.selectedViewUuid
      ViewHelper.storeViews(this.scene.views, selectedViewUuid)
      var viewUuid

      if (ViewHelper.views.length === 0) {
        onFinishPromise.resolve(logs)
        return
      }

      var availableViewsUuid = ViewHelper.views
        .filter(function (view) {
          return view.show_customer === true
        })
        .map(function (view) {
          return view.uuid
        })

      if (this.displayMode === 'presentation') {
        //When presenting jump to the first view visible to the customer
        if (availableViewsUuid.indexOf(selectedViewUuid) !== -1) {
          viewUuid = selectedViewUuid
        } else {
          viewUuid = availableViewsUuid[0]
        }

        // Handle case when no views are enabled (or block it from showing at all..?)
        // We assume that this is not dangerous beacuse customer presumably will not have any
        // access to MyEnergy if no customer views are enabled
        if (typeof viewUuid === 'undefined') {
          viewUuid = null
        }
      }

      // Auto-detect which view to display initially
      // This will be skipped if a view was selected for displayMode 'presentation'
      // @TODO: Can we unify this with the logic above? Seems to be duplication and confusing between both
      if (!viewUuid) {
        if (selectedViewUuid) {
          viewUuid = selectedViewUuid
        } else if (
          this.scene.selectedViewUuid !== null &&
          availableViewsUuid.indexOf(this.scene.selectedViewUuid) !== -1
        ) {
          // There may be no view which matches selectedViewUuid, only use selectedViewUuid if a view with matching uuid is actually present
          // (e.g. When Studio was saved with a different view than the selected view for the PDF)
          viewUuid = this.scene.selectedViewUuid
        } else if (ViewHelper.views.length > 0) {
          // Fallback to selecting the first existting view
          viewUuid = ViewHelper.views[0].uuid
        } else {
          // unable to detect a default viewUuid, nothing else we can do.
        }
      }

      await ViewHelper.loadViewByUuid(viewUuid, this)
      onFinishPromise.resolve(logs)
    }

    prepareStudio()
    parseSceneData()
    relinkSceneObjects()
    reselectSystem()
    recreateViews()

    return onFinishPromise.promise
  },

  setProgress: function (keys, value) {
    var codeToAction = {
      dsm: 'LOAD_PROGRESS_DSM',
      ortho: 'LOAD_PROGRESS_ORTHO',
      render: 'LOAD_PROGRESS_RENDER',
    }

    if (keys === 'all') {
      keys = ['dsm', 'ortho', 'render']
    } else if (keys.constructor !== Array) {
      keys = [keys]
    }

    keys.forEach((key) => {
      window.reduxStore?.dispatch({
        type: codeToAction[key],
        payload: {
          fraction: value,
        },
      })
    })
  },

  viewTabsLoading: function () {
    return (
      this.waiting.views ||
      this.waiting.terrainSearch ||
      this.waiting.terrainDsm ||
      this.waiting.terrainTexture ||
      this.waiting.terrainFirstRender
    )
  },

  /**
   * Sets the visibility flag of multiple objects in the 3D scene for the next render call
   * By default, this does NOT make a render call after setting the visibility flags
   * @param {{ type: string, visible: boolean, renderWhenDone: boolean }}
   * @returns {undefined}
   */
  setVisibilityOfObjects: function ({ objects = [], visible, renderWhenDone = false }) {
    let selectionIsHidden = false
    objects.forEach((obj) => {
      if (obj.setVisible) {
        obj.setVisible(visible)
      } else {
        obj.visible = visible
      }
      if (obj === this.selected && !visible) {
        // the editor's currently selected object will be invisible
        selectionIsHidden = true
      }
    })
    if (selectionIsHidden) this.deselect()
    if (renderWhenDone) this.render()
  },

  /**
   * Sets the visibility flag of multiple objects, of a particular type, in the 3D scene for the next render call
   * By default, this does NOT make a render call after setting the visibility flags
   * @param {{ type: string, visible: boolean, renderWhenDone: boolean }}
   * @returns {undefined}
   */
  setVisibilityByObjectType: function ({ type, visible, renderWhenDone = false }) {
    this.setVisibilityOfObjects({ objects: this.filter('type', type), visible, renderWhenDone })
  },

  /**
   * Sets the visibility flag of multiple objects, of a particular name, in the 3D scene for the next render call
   * By default, this does NOT make a render call after setting the visibility flags
   * @param {{ name: string, visible: boolean, renderWhenDone: boolean }}
   * @returns {undefined}
   */
  setVisibilityByObjectName: function ({ name, visible, renderWhenDone = false }) {
    this.setVisibilityOfObjects({ objects: this.filter('name', name), visible, renderWhenDone })
  },

  /**
   * Sets the visibility flag of the editor guides in the 3D scene for the next render call
   * By default, this does NOT make a render call after setting the visibility flags
   * @param {{ visible: boolean, renderWhenDone: boolean, showNotifWhenDone: boolean, customNotifMsg: string, dispatchSignal: boolean }}
   * @returns {undefined}
   */
  setDesignGuidesVisibility: function ({
    visible,
    renderWhenDone = false,
    showNotifWhenDone = true,
    customNotifMsg,
    dispatchSignal = true,
    includeAnnotations = true,
    includeMeasurements = true,
  }) {
    this.setVisibilityByObjectType({ type: 'OsClipper', visible })
    this.setVisibilityByObjectType({ type: 'ArrowHelper', visible })
    this.setVisibilityByObjectName({ name: 'FacetMeshSetbacks', visible })
    this.setVisibilityByObjectName({ name: 'FacetMeshSetbacksOutline', visible })
    this.setVisibilityOfObjects({
      objects: this.filter('type', 'OsEdge').filter((edge) => edge.isWire()),
      visible,
    })

    if (includeAnnotations && this.controllers?.Annotation) {
      // this will hide the annotations
      // @TODO, refactor the annotations system to make this more efficient
      if (visible) {
        this.controllers.Annotation.activate()
      } else {
        this.controllers.Annotation.deactivate()
        if (this.selected?.type === 'OsAnnotation') {
          this.deselect()
        }
      }
    }

    if (this.controllers?.Measurement && includeMeasurements) {
      const measurementController = this.controllers.Measurement
      if (visible) {
        measurementController.activate()
      } else {
        measurementController.deactivate()
      }
    }

    if (renderWhenDone) this.render()

    this.designGuidesVisible = visible

    if (dispatchSignal) {
      this.signals.designGuidesVisibilityChanged.dispatch(visible)
    }

    const defaultNotifMessage = `Design guides ${visible ? 'visible' : 'hidden'}.`
    if (showNotifWhenDone) {
      window.Designer.showNotification(customNotifMsg || defaultNotifMessage, visible ? 'info' : 'danger')
    }
  },

  /**
   * Gets the current visibility status of the editor guides
   * @returns {boolean}
   */
  getDesignGuidesVisibility: function () {
    return this.designGuidesVisible
  },

  startMultiSelectMode: function () {
    this.controllers.SelectionBox.activate()
  },

  endMultiSelectMode: function () {
    this.controllers.SelectionBox.deactivate()
  },

  enumerateFacets: function (options = { includeNonSpatialFacets: true, system: null }) {
    const facetsInScene = this.filter('type', 'OsFacet')
    if (options.includeNonSpatialFacets === true) {
      if (!(options.system instanceof OsSystem)) return facetsInScene

      const spatialFacets = facetsInScene.filter((facet) => !facet.isNonSpatial())

      const nonSpatialFacetsUsedBySystem = options.system.moduleGrids().reduce((accumulator, moduleGrid) => {
        if (moduleGrid.facet?.isNonSpatial() && !accumulator.includes(moduleGrid.facet)) {
          accumulator.push(moduleGrid.facet)
        }
        return accumulator
      }, [])

      return [...spatialFacets, ...nonSpatialFacetsUsedBySystem]
    } else {
      return facetsInScene.filter((facet) => !facet.isNonSpatial())
    }
  },
}
