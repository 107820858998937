/**
 * @author adampryor
 */

var ENABLE_MINECRAFTIFY = true
var MAX_DISTANCE_FROM_PERFECT_SKIRT = 0.1

// Previous value as at 20th May 2024
// We cannot reduce this on the target building because facets could get impacted, but we could dampen
// errors more aggressively in a) non-target buildings b) areas in the DSM with no buildings at all
var DELATIN_MAX_ERROR = 0.05
var DELATIN_ERROR_DAMPING_TUNING_FACTOR = 0.01

// Not implemented because this is probably not required now that frame-rate has improved
// and it also adds initial processing time which will be annoying for users.
// To re-enable you would also need to run `yarn add three@0.164.1 --dev` which also greatly
// inflates the size of oslib3d.js and rename SimplifyModifier.js.txt and BufferGeometryUtils.js.txt
var SIMPLIFY_TERRAIN_GEOMETRY = false

var DSM_OPTIMIZATION_METHOD = 'DELATIN' //DELATIN or DENSE_GRID

function OsTerrain(geometry, options) {
  THREE.Mesh.call(this)

  // the extent of the heatmap coverage starting from the center of the terrain
  this.shadingRadius = window.HEATMAP_CONFIGURATION?.shadingRadius || 0.8
  // the number of concurrent web workers created when computing for the heatmap
  this.shadingBatches = window.HEATMAP_CONFIGURATION?.shadingBatches || 5
  // keeps track of the number of shading batches that have returned results
  this.shadingBatchesFinished = 0
  // stores and accumulates the results of all shading batches
  this.shadingResults = []
  this.shadingTextureDownsampleFactor = window.HEATMAP_CONFIGURATION?.shadingTextureDownsampleFactor || 1
  this.shadingMonths = window.HEATMAP_CONFIGURATION?.monthIndices || [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

  this.geometry = geometry
  this.wallBlurringActive = false
  this.wallFacesIndices = options?.wallFacesIndices || []

  // this.textures.terrain stores the original terrain imagery
  // this.textures.heatmap stores the original heatmap imagery
  this.textures = {
    terrain: options && options.texture,
    heatmap: options && options.heatmap,
    terrainAtlas: new THREE.Texture(),
    heatmapAtlas: new THREE.Texture(),
  }

  this.textures.terrainAtlas.minFilter = THREE.LinearFilter

  this.textures.heatmapAtlas.flipY = false
  this.textures.heatmapAtlas.minFilter = THREE.LinearFilter

  this.material = new THREE.MeshStandardMaterial({
    color: 0x666666,
    map: this.textures.terrain,
    flatShading: false,
    roughness: 1.0,
    metalness: 0.0,
    opacity:
      options && options.hasOwnProperty('terrainMaterialStartOpacity') ? options.terrainMaterialStartOpacity : 1.0,
  })

  this.castShadow = options && options.hasOwnProperty('castShadow') ? options.castShadow : false
  this.receiveShadow = options && options.hasOwnProperty('receiveShadow') ? options.receiveShadow : false

  this.name = 'terrain'
  this.type = 'OsTerrain'
  this.rasterResolution = options.rasterResolution

  // rasterData can be updated dynamically
  this.rasterData = options.rasterData
  this.size = !isNaN(options.size) ? [options.size, options.size] : options.size
  this.originalElevationPoints = options.originalElevationPoints || []

  // during initial creation of terrain, store rasterDataOriginal
  // if supplied use the supplied value, otherwise clone from rasterData
  this.rasterDataOriginal = options.rasterDataOriginal ? options.rasterDataOriginal : options.rasterData.slice()

  this.originalElevationPointIndexes = options.originalElevationPointIndexes || []

  this.mode = 'terrain'

  if (options && options.position) {
    this.position.copy(options.position)
    this.updateMatrix()
  }

  // Is this required??
  this.drawMode = 0
  this.updateMorphTargets()

  this.userData.excludeFromExport = true
}

OsTerrain.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsTerrain,
  minZ: function () {
    return this.geometry.boundingBox.min.z + this.position.z
  },

  generateTextureAtlas: function (textureType) {
    const type = textureType || this.getMode()

    if (type === 'terrain') {
      if (!this.textures.terrain.image) {
        console.warn('Failed to generate terrain texture atlas because terrain image is not ready!')
        return
      }
      this.textures.terrainAtlas.image = OsTerrain.createTextureAtlasImage(this.textures.terrain.image, 'terrain')
      this.textures.terrainAtlas.needsUpdate = true
      return
    }

    if (type === 'heatmap') {
      if (!this.textures.heatmap || !this.textures.heatmap.image) {
        console.warn('Failed to generate heatmap texture atlas because heatmap image is not ready!')
        return
      }
      this.textures.heatmapAtlas.image = OsTerrain.createTextureAtlasImage(this.textures.heatmap.image, 'heatmap')
      this.textures.heatmapAtlas.needsUpdate = true
      return
    }
  },

  hasGeneratedTextureAtlas: function (textureType) {
    const type = textureType || this.getMode()
    if (type === 'terrain') return !!this.textures.terrainAtlas.image
    if (type === 'heatmap') return !!this.textures.heatmapAtlas.image
  },

  getMode: function () {
    return this.mode
  },

  setMode: function (mode) {
    this.mode = mode

    if (this.mode === 'terrain') {
      this.material.map = this.getWallBlurringActive() ? this.textures.terrainAtlas : this.textures.terrain
      if (this.getWallBlurringActive() && !this.hasGeneratedTextureAtlas('terrain')) {
        this.generateTextureAtlas('terrain')
      }
      this.material.needsUpdate = true
      window.editor.render()
      return
    }

    if (this.mode === 'heatmap') {
      if (!this.textures.heatmap) {
        // unlike the terrain texture, which is loaded from network
        // the terrain heatmap image is generated on-the-spot when needed
        this.generateHeatmapTexture()
      }
      if (this.getWallBlurringActive() && !this.hasGeneratedTextureAtlas('heatmap')) {
        this.generateTextureAtlas('heatmap')
      }
      this.material.map = this.getWallBlurringActive() ? this.textures.heatmapAtlas : this.textures.heatmap
      this.material.needsUpdate = true
      window.editor.render()
      return
    }
  },

  useHeatmap: function (value) {
    if (value === undefined) return this.getMode() === 'heatmap'
    this.setMode(!!value ? 'heatmap' : 'terrain')
  },

  generateHeatmapTexture: function () {
    // generate the sun positions to simulate
    let sunPositions = []
    let hour = 0
    let sunPosition = null
    this.shadingMonths.forEach((monthIndex) => {
      for (hour = 0; hour < 24; hour++) {
        sunPosition = window._sunPositionAtDateTimeUTC(
          Utils.firstDayIndexForMonth[monthIndex],
          hour,
          SceneHelper.getLongitude(),
          SceneHelper.getLatitude()
        )
        sunPosition.altitude > 0 && sunPositions.push(sunPosition)
      }
    })
    // simulate terrain shading and generate heatmap texture
    let heatmap = ShadeHelper.createHeatmapFromTerrainShadingSnapshots(
      ShadeHelper.simulateTerrainShadingUsingGPU(this, sunPositions)
    )
    // // apply heatmap texture as material to this terrain
    this.textures.heatmap = heatmap
  },

  getMeshes: function () {
    if (this.type === 'Mesh' || this.type === 'OsTerrain') {
      // Type is mesh, use the object itself
      return [this]
    } else {
      //Type is assumed to be OBJ, use the first child
      return [this.children[0]]
    }
  },

  getWallBlurringActive: function () {
    return this.wallBlurringActive
  },

  setWallBlurringActive: function (value, options = {}) {
    if (value === this.wallBlurringActive) return

    const blurWallFaces = value === true

    const orthoImageryIsReady = !!this.textures.terrain.image
    if (!orthoImageryIsReady && this.getMode() === 'terrain') {
      console.warn('skipping wall blurring because ortho imagery is not ready yet!')
      return
    }

    const currentMode = this.getMode()

    if (blurWallFaces) {
      this.geometry.faceVertexUvs[0].forEach((faceVertexUv) => {
        faceVertexUv[0].x /= 2
        faceVertexUv[1].x /= 2
        faceVertexUv[2].x /= 2
      })
      this.wallFacesIndices.forEach((index) => {
        this.geometry.faceVertexUvs[0][index][0].x += 0.5
        this.geometry.faceVertexUvs[0][index][1].x += 0.5
        this.geometry.faceVertexUvs[0][index][2].x += 0.5
      })

      if (!this.hasGeneratedTextureAtlas(currentMode)) {
        this.generateTextureAtlas(currentMode)
      }
      this.material.map = this.textures[this.getMode() + 'Atlas']
    } else {
      this.wallFacesIndices.forEach((index) => {
        this.geometry.faceVertexUvs[0][index][0].x -= 0.5
        this.geometry.faceVertexUvs[0][index][1].x -= 0.5
        this.geometry.faceVertexUvs[0][index][2].x -= 0.5
      })
      this.geometry.faceVertexUvs[0].forEach((faceVertexUv) => {
        faceVertexUv[0].x *= 2
        faceVertexUv[1].x *= 2
        faceVertexUv[2].x *= 2
      })
      this.material.map = this.textures[currentMode]
    }

    this.geometry.uvsNeedUpdate = true
    this.material.needsUpdate = true
    this.wallBlurringActive = value

    if (!!options.render) {
      window.editor.render()
    }
  },

  clearHeatmap: function () {
    this.useHeatmap(false)
    this.textures.heatmap = null
  },

  mergeToShadingResults: function (sresults) {
    if (this.shadingResults.length === 0) {
      this.shadingResults = sresults
      return
    }
    for (var i = 0; i < this.shadingResults.length; i++) {
      if (this.shadingResults[i] == null) {
        this.shadingResults[i] = sresults[i]
      }
    }
  },

  getContextMenuItems: function (position) {
    return [
      {
        label: window.translate('Focus here'),
        useHTML: false,
        selected: false,
        onClick: function () {
          var distance = 100
          var meshOrientation = SceneHelper.orientationAtPosition(position)
          var cameraOffsetFromPosition = meshOrientation.plane.normal.multiplyScalar(distance)
          var cameraPosition = new THREE.Vector3().addVectors(position, cameraOffsetFromPosition)

          if (true) {
            // with animation
            ViewHelper.animateCameraToParams({
              center: position.toArray(),
              position: cameraPosition.toArray(),
              up: new THREE.Vector3(0, 0, 1).toArray(),
              metersPerPixel: ViewHelper.selectedView().cameraParams.metersPerPixel,
            })
          } else {
          }
        },
      },
    ]
  },
})

OsTerrain.positionInScene = function (sceneOrigin4326, terrainCenter4326, z) {
  var terrainPositionDelta = Utils.vectorMetersBetweenLocations4326(sceneOrigin4326, terrainCenter4326)
  return new THREE.Vector3(terrainPositionDelta[0], terrainPositionDelta[1], z)
}

OsTerrain.getCellSizeFromTiff = (tiff) => {
  // Units are based on the projection of the image.

  if (tiff.fileDirectories[0][0].ModelTransformation) {
    // Included by Google endpoint raw values
    return new THREE.Vector2(
      tiff.fileDirectories[0][0].ModelTransformation[0],
      -1 * tiff.fileDirectories[0][0].ModelTransformation[5]
    )
  } else if (tiff.fileDirectories[0][0].ModelPixelScale) {
    // Standard method including:
    //  - Google files which have been compressed using rasterio
    //  - manually created in QGIS transformed/compressed
    return new THREE.Vector2(
      tiff.fileDirectories[0][0].ModelPixelScale[0],
      tiff.fileDirectories[0][0].ModelPixelScale[1]
    )
  }
}

OsTerrain.onLoadDsm = async function (tiffContents, terrainPosition, terrainRotationZ, texture, editor) {
  try {
    var tiff = await GeoTIFF.fromArrayBuffer(tiffContents)
  } catch (error) {
    Designer.showNotification('Failed to load 3D imagery. Please try a different date or imagery.', 'error', {
      buttons: window.Designer.restartDesignMode
        ? [
            {
              label: window.translate('Reset Views'),
              action: () => window.Designer.restartDesignMode(true),
            },
          ]
        : undefined,
    })
    console.error(error)
    console.warn('Possibly missing GOOGLE_EARTH_ENGINE_API_KEY in the zappa file.')
    return
  }

  var image = await tiff.getImage()

  //Assume EPSG:3857
  // var terrainCenter4326 = [4.815094, 52.269297]
  // var terrainCenter3857 = Utils.reprojectCoordinate(terrainCenter4326, 4326, 3857)
  var w = image.getWidth()
  var h = image.getHeight()

  var cellSize = OsTerrain.getCellSizeFromTiff(tiff)

  // Now that the image has been parsed the tiff.fileDirectories will be populated with data

  //Different formats can store this in different ways

  var terrainSize3857

  // Detect Nearmap 3D DSMs because they have incorrect ModelPixelScale
  // This is just one way of finterprinting Nearmap imagery versus other kinds of imagery
  // We cannot use o.fileDirectory.StripOffsets because both Nearmap and Compressed-Google use this
  // For now just use the size. Nearmap = ~500 and Google = ~1000
  // We should certainly make this more robust in future!
  var is_nearmap_dsm = w < 750

  if (window.terrainSize3857Override) {
    // Allow manual override during testing when using synthetic DSM
    terrainSize3857 = window.terrainSize3857Override
  } else if (is_nearmap_dsm) {
    // We cannot use provided metadata at all because it is unreliable and differs by location
    // Therefore we currently assume that scale is exactly the same as we requested in the endpoint
    terrainSize3857 = [w * 0.2, h * 0.2]
  } else {
    terrainSize3857 = [w * cellSize.x, h * cellSize.y]
  }

  // Theoretical method for controlling level of detail for terrain render based on data
  // injected from the mask layer. Not currently implemented and proably not required if
  // frame rate has already improved significantly.

  // plain list, it is not split into rows/cols
  // var gdalMetaData = tiff.fileDirectories[0][0].GDAL_METADATA

  // var buildingFoundInTileArray =
  //   (gdalMetaData && extractValue(gdalMetaData, 'BUILDING_FOUND_IN_TILE')?.split('')) || null

  // sample corresponding to the center region of the DSM, equivalent to the old "fovea" method
  // var buildingFoundInTileArray = [0, 0, 0, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0]

  // Set to null so we truly use the inFovea method which is much faster for initial terrain generation
  // If we see massive frame-rate benefits in future we can re-enable the buildingFoundInTileArray technique
  var buildingFoundInTileArray = null

  // Try to process GeoTiff asynchronously, see https://geotiffjs.github.io/geotiff.js/module-pool-Pool.html
  // @TODO: Work out why this does not work on safari and remove this hack
  // This is low risk if it fails because it will still succeed, just won't use worker pools.
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const pool = !isSafari && window.RUNNING_AS_APP_DEVICE !== 'ios' ? new GeoTIFF.Pool() : null

  var rasters = await image.readRasters({ pool: pool })
  var data = rasters[0]

  var dataOriginal = data.slice()

  var initialTerrainPositionZ

  if (terrainPosition) {
    initialTerrainPositionZ = terrainPosition.z
  } else if (!terrainPosition) {
    if (editor.getTerrain()) {
      terrainPosition = editor.getTerrain().position.clone()
      initialTerrainPositionZ = terrainPosition.z
    } else {
      terrainPosition = new THREE.Vector3(0, 0, 'not-set')

      //Auto-detect after loading DSM
      initialTerrainPositionZ = null
    }
  }

  // Calculate expected terrainPosition based on georeferenced image relative to sceneOrigin
  // We may choose to apply this calculated position, but this was not previously used so this may
  // introduce some positioning errors (which are actually old errors being fixed, but which might
  // mis-align terrain with panels/etc.
  // UPDATED: We do not use the geo-reference to position the terrain IF
  // we detect that there are existing panels in the design AND
  // if we're loading from design data to avoid misaligment with the existing design
  // If there are panels but we're not loading from design data (such as when Reloading 3D or Restarting Design)
  // we use the geo-reference

  const hasPanels = editor.getSystems().some((s) => s.moduleQuantity() > 0)
  const dsmFromSavedState = editor.waiting.terrainDsmFromSavedState
  const ignoreGeorefForTerrainPosition = hasPanels && dsmFromSavedState

  if (!ignoreGeorefForTerrainPosition) {
    console.warn('Using terrain georeference to calculate more accurate terrain position using georeference data.')

    var terrainEpsg = image.geoKeys.ProjectedCSTypeGeoKey // e.g. 32618
    var centerCoordinate

    if (tiff.fileDirectories[0][0].ModelTiepoint) {
      // This format is used if we apply compression using rasterio / compress_geotiff_content()
      // e.g. [0,0,0, 528687.3, 4480930.6, 0] in the format [...pixel_coordinates, ...world_coordinates]
      centerCoordinate = [
        tiff.fileDirectories[0][0].ModelTiepoint[3] + (w / 2) * cellSize.x,
        tiff.fileDirectories[0][0].ModelTiepoint[4] + (h / 2) * cellSize.y * -1,
      ]

      if (window.OSAD) {
        window.OSAD.lastWorldFileLines = OsTerrain.tiffContentsToWorldFile(tiff)
        window.OSAD.lastEpsgCode = terrainEpsg
      }
    } else if (tiff.fileDirectories[0][0].ModelTransformation) {
      // This format is used when we receive raw GeoTiffs from Google endpoints
      // e.g. [0.1,0,0,528690.7,0,-0.1,0,4480930.2,0,0,0,0,0,0,0,1]
      centerCoordinate = [
        tiff.fileDirectories[0][0]?.ModelTransformation[3] +
          (w / 2) * tiff.fileDirectories[0][0]?.ModelTransformation[0],
        tiff.fileDirectories[0][0]?.ModelTransformation[7] +
          (h / 2) * tiff.fileDirectories[0][0]?.ModelTransformation[5],
      ]
    }

    var terrainEpsgLookup = `EPSG:${terrainEpsg}`

    // Check if this projection is already in the list
    if (!ol.proj.get(terrainEpsgLookup)) {
      SceneHelper.addOpenLayersUtmCoordinateTransforms()
    }

    // Now check if the projection is now available. Ignore if not supported.
    // If we closely track which projections are supported we would not need to check again, but for now there may be
    // various ways that projections are added so we will just keep it simple and check again.
    if (ol.proj.get(terrainEpsgLookup)) {
      var terrainCenter4326 = Utils.reprojectCoordinate(centerCoordinate, terrainEpsg, 4326)

      var scenePositionForTerrainCenter = new THREE.Vector2().fromArray(
        SceneHelper.positionForLonLatUsingSceneOrigin(terrainCenter4326, editor.scene.sceneOrigin4326)
      )

      terrainPosition.x = scenePositionForTerrainCenter.x
      terrainPosition.y = scenePositionForTerrainCenter.y
    } else {
      console.warn(`${terrainEpsgLookup} not supported. Leaving terrainPosition xy unchanged.`)
    }
  } else {
    console.warn('Not using terrain georeference to avoid shifting terrain relative to existing panels.')
  }

  var originalElevationPointIndexesToRestore = editor.getTerrain()
    ? editor.getTerrain().originalElevationPointIndexes
    : []

  var {
    geometry,
    originalElevationPointIndexes,
    wallFacesIndices,
    terrainPositionZ,
  } = await OsTerrain.buildTerrainGeometry(
    data,
    dataOriginal,
    terrainPosition,
    terrainSize3857,
    new THREE.Vector2(w, h),
    initialTerrainPositionZ,
    originalElevationPointIndexesToRestore,
    buildingFoundInTileArray
  )

  terrainPosition.z = terrainPositionZ

  if (SIMPLIFY_TERRAIN_GEOMETRY) {
    let modifier = new oslib3d.SimplifyModifier()
    geometry = new THREE.BufferGeometry().fromGeometry(geometry)

    // Beware: we do not currently understand how count of vertices to remove should be handled
    // any value higher than around 20% seems to eliminate all vertices in the mesh which break.
    let fractionToRemove = 0.1
    let numVertices = geometry.attributes.position.count
    let numVerticesToRemove = Math.floor(numVertices * fractionToRemove)
    console.log(`removing fraction of vertices: ${fractionToRemove} = ${numVerticesToRemove}/${numVertices}`)
    geometry = modifier.modify(geometry, numVerticesToRemove)
  }

  var terrain = new OsTerrain(geometry, {
    position: terrainPosition,
    scale: new THREE.Vector3(1, 1, 1),
    rasterResolution: new THREE.Vector2(w, h),
    rasterData: data,
    rasterDataOriginal: dataOriginal,
    originalElevationPointIndexes: originalElevationPointIndexes,
    size: terrainSize3857,
    wallFacesIndices: wallFacesIndices,
    texture: texture,
    // Initially shading will be disabled for performance, until we open expansion panel
    castShadow: Designer.shadingVisibility(),
    receiveShadow: Designer.shadingVisibility(),
  })

  if (terrainRotationZ) {
    terrain.rotation.z = terrainRotationZ
  }

  //@TODO: Create terrain first so the texture starts loading, then process & inject the real geometry
  //So they load in parallel, not waiting for geom to be loaded before starting texture load.

  if (editor.getTerrain()) {
    // terrain should not already exist, but if it does due to some timing issue, remove existing
    // before adding new terrain
    console.warn('Removing existing terrain. Did we load duplicate terrain?')
    editor.removeObject(editor.getTerrain())
  }

  return terrain
}

OsTerrain.processDSMAndOrthoWhenLoaded = function (editor) {
  if (editor.waiting.terrainDsm) return
  if (editor.waiting.terrainTexture) return
  if (!editor.terrainSettings.wallBlurringActive) return
  const cameraIsOverhead = editor.controllers?.Camera?.orientationIsApproximatelyTopDown()
  if (cameraIsOverhead) return // no need to blur terrain walls

  const blurTerrainWalls = () => {
    const terrain = editor.getTerrain()
    if (!terrain) {
      console.warn('editor.getTerrain() not available, defer blurring of terrain walls...')
      return
    }
    terrain.setWallBlurringActive(true)
    editor.render()
  }

  if (editor.scene?.terrain && editor.getTerrain()) {
    blurTerrainWalls()
  } else {
    console.warn(
      'LoadTextureWithHeaders onLoad called but editor.scene.terrain not available. Try again after setTimeout'
    )
    setTimeout(blurTerrainWalls, 1)
  }
}

OsTerrain.buildSkirtGeometryFromVertices = (topVertices, baseBottomZ, worldXyToUv) => {
  ///////////////////////////////////////////////////////
  /// DRAW WALLS
  //
  //Draw skirt as a plane dropping from each edge

  // We either create:
  // - 4 vertices and 2 faces (if flat)
  // - 5 vertices and 3 faces (if sloped)

  var wallGeometries = []

  topVertices.forEach(function (current, index) {
    //Only draw walls if one point is significantly above the ground/baseBottomZ

    var next = topVertices[index + 1] || topVertices[0]

    // Geometry is a) rectangle plus b) triangle on top

    var higher, lower
    var higherIs0 = null
    if (current.z === next.z) {
      //If roof is flat (i.e. if points have same elevation) then do not add the extra triangle
      higherIs0 = null

      //does not matter
      higher = current
      lower = next
    } else if (current.z > next.z) {
      higherIs0 = true
      higher = current
      lower = next
    } else {
      higherIs0 = false
      higher = next
      lower = current
    }

    var wallGeometry = new THREE.Geometry()
    wallGeometry.vertices = [
      new THREE.Vector3(current.x, current.y, baseBottomZ),
      new THREE.Vector3(next.x, next.y, baseBottomZ),
      new THREE.Vector3(next.x, next.y, lower.z),
      new THREE.Vector3(current.x, current.y, lower.z),
    ]

    if (higherIs0 !== null) {
      wallGeometry.vertices.push(higher)
    }

    //@TODO: Set direction to always face out towards camera, instead of using double sided texture
    wallGeometry.faces = [new THREE.Face3(1, 2, 0), new THREE.Face3(3, 0, 2)]

    if (higherIs0 !== null) {
      wallGeometry.faces.push(higherIs0 ? new THREE.Face3(3, 2, 4) : new THREE.Face3(2, 4, 3))
    }

    // calculat UVs only based on the X and Y coordinates, ignore Z
    // ensure these are added in the same order as the faces above.

    wallGeometry.faceVertexUvs[0] = []

    wallGeometry.faceVertexUvs[0].push([
      worldXyToUv(next.x, next.y),
      worldXyToUv(next.x, next.y),
      worldXyToUv(current.x, current.y),
    ])
    wallGeometry.faceVertexUvs[0].push([
      worldXyToUv(current.x, current.y),
      worldXyToUv(current.x, current.y),
      worldXyToUv(next.x, next.y),
    ])

    if (higherIs0 !== null) {
      if (higherIs0) {
        wallGeometry.faceVertexUvs[0].push([
          worldXyToUv(current.x, current.y),
          worldXyToUv(next.x, next.y),
          worldXyToUv(next.x, next.y),
        ])
      } else {
        wallGeometry.faceVertexUvs[0].push([
          worldXyToUv(next.x, next.y),
          worldXyToUv(next.x, next.y),
          worldXyToUv(current.x, current.y),
        ])
      }
    }

    wallGeometries.push(wallGeometry)
  }, this)

  var geom = new THREE.Geometry()
  wallGeometries.forEach((g) => {
    geom.merge(g)
  })

  // Unsure if we need to call this but we expect it doesn't hurt because they are simple flags
  // I expect we probably DO need to call `geom.computeFaceNormals()` but we will call this after merging
  // into the main geometry
  geom.elementsNeedUpdate = true
  geom.uvsNeedUpdate = true

  return geom
}

OsTerrain.getLowestElevationApproximation = (data, w, h) => {
  // geometryMinZ does not need to be exact, just a good, quick estimate
  var geometryMinZ = 1000

  for (var y = 0, yl = h; y < yl; y += 20) {
    for (var x = 0, xl = w; x < xl; x += 20) {
      var index = y * 20 * 20 + x
      if (data[index] < geometryMinZ) {
        geometryMinZ = data[index]
      }
    }
  }
  return geometryMinZ
}

OsTerrain.getTopVerticesForSkirt = (data, w, h, cellSize3857) => {
  // Get skirt vetrices

  var verticesForSkirt = []

  /*
  Confusing dimensions which differ in the y direction.
  # var data: Float32Array 1-D list which you can choose to wrapping from the top left or however else, start from top left corner.
  # var verticex positions in ThreeJS world coordinationes, with origin at bottom left corner.
  */

  // Trace the x and y coordinates from top-left going clockwise
  // The mappings back to position in the data are flipped in y direction

  // Iterations below have x and y as col/row coordinates within the raster data (from top left)
  var x = 0
  var y = h - 1
  var index = 0

  var cellToWorld = (x, y, value) =>
    new THREE.Vector3(
      x * cellSize3857[0] - (w * cellSize3857[0]) / 2,
      y * cellSize3857[1] - (h * cellSize3857[1]) / 2,
      value
    )

  // @TODO: Optimize this by incrementing the index with each iteration instead of calculating it.
  var getIndex = (_x, _y) => {
    // axis mapping is flipped in y direction only
    return (h - _y - 1) * w + _x
  }

  // scan full row
  x = 0
  y = 0
  for (x = 0; x < w; x++) {
    index = getIndex(x, y)
    verticesForSkirt.push(cellToWorld(x, y, data[index]))
  }

  // jump down one row and scan down the right-most column

  x = w - 1
  y = 0
  for (; y < h; y++) {
    index = getIndex(x, y)
    verticesForSkirt.push(cellToWorld(x, y, data[index]))
  }

  // jump left one column and scan the bottom row to the left
  x = w - 2
  y = h - 1
  for (; x > -1; x--) {
    index = getIndex(x, y)
    verticesForSkirt.push(cellToWorld(x, y, data[index]))
  }

  // jumpy up one row and scan the left-most column up
  x = 0
  y = h - 2
  for (; y > 0; y--) {
    index = getIndex(x, y)
    verticesForSkirt.push(cellToWorld(x, y, data[index]))
  }

  /* Optimize
    Remove vertices that are not required because a larger triangle is sufficient
    to match the terrain surface sufficiently closely.

    Optimize- calculate all vertex points. Thin points. Track two point indexes.
    Start at 0,2. Check if points in the middle are close to the vector between
    the two outer points. If so then eliminate the middle point. If yes, then move
    the next point along further so we now have 0,3 (point 1 is gone) now test if
    point 2 is close to vector between 0 and 3. If so then eliminate. Continue until
    no points get eliminated or we reach the point that is the corner at the end of
    that skirt line. When that happens progress the start point to the next remaining
    point and go again until the start point becomes the end.
  */

  verticesForSkirt = douglasPeucker(verticesForSkirt, MAX_DISTANCE_FROM_PERFECT_SKIRT)

  return verticesForSkirt
}

function distanceToLine(point, start, end) {
  return new THREE.Line3(start, end).closestPointToPoint(point, false, new THREE.Vector3()).distanceTo(point)
}

function douglasPeucker(points, epsilon) {
  // Find the point with the maximum distance
  let dmax = 0
  let index = 0
  let end = points.length - 1
  for (let i = 1; i < end; i++) {
    let d = distanceToLine(points[i], points[0], points[end])
    if (d > dmax) {
      index = i
      dmax = d
    }
  }

  // If max distance is greater than epsilon, recursively simplify
  if (dmax > epsilon) {
    let recResults1 = douglasPeucker(points.slice(0, index + 1), epsilon)
    let recResults2 = douglasPeucker(points.slice(index, end + 1), epsilon)

    // Build the result list
    let result = recResults1.slice(0, recResults1.length - 1).concat(recResults2)
    return result
  } else {
    return [points[0], points[end]]
  }
}

OsTerrain.buildTerrainGeometry = async function (
  data,
  dataOriginal,
  rasterPosition,
  terrainSize3857,
  rasterResolution,
  terrainPositionZ,
  originalElevationPointIndexesToRestore,
  buildingFoundInTileArray
) {
  var w = rasterResolution.x
  var h = rasterResolution.y
  var cellSize3857 = [terrainSize3857[0] / w, terrainSize3857[1] / h]

  /*
  To auto-detect terrainPositionZ supply it as null. If a numeric value is supplied it will be retained
  */
  var matchSuppliedTerrainPositionZ =
    (terrainPositionZ || terrainPositionZ === 0) && terrainPositionZ !== 'not-set' ? true : false
  terrainPositionZ = terrainPositionZ && terrainPositionZ !== 'not-set' ? terrainPositionZ : 0

  var mid = [Math.floor(w / 2), Math.floor(h / 2)]
  var elevationAtCentroid = dataOriginal[mid[0] * w + mid[1]]

  var originalElevationPointIndexes = []

  var clipperPoints = SceneHelper.getClipperPoints(
    rasterPosition,
    new THREE.Vector2().fromArray(terrainSize3857),
    rasterResolution
  )

  // ==== Set elevation for clipped points ====
  if (clipperPoints && clipperPoints.length) {
    clipperPoints.forEach((p) => {
      // Optimization to avoid recalculating index multiple times for points
      // var index = SceneHelper.rowColToRasterIndex(p.x, p.y, w)
      var index = p.index

      var clippedElevation = p.z - terrainPositionZ

      // Only apply clippedElevation if lower than the terrain to ensure that TreeClipper never raises the terrain
      // it should only ever lower it.
      if (clippedElevation < dataOriginal[index]) {
        data[index] = clippedElevation
      } else {
        data[index] = dataOriginal[index]
      }

      // Verification required: Are we sure that we never receive duplicate clipperPoints with same index?
      // If not, we would need to add the check here instead of adding them directly
      // if (originalElevationPointIndexes.indexOf(index) !== -1) {
      originalElevationPointIndexes.push(index)
      // }

      var originalElevationPointIndexesToRestoreIndexOf = originalElevationPointIndexesToRestore.indexOf(index)

      if (originalElevationPointIndexesToRestoreIndexOf !== -1) {
        // this point is clipped so we will not be restoring it.
        // remove it here which is very efficient so only the remaining points will be restored below
        originalElevationPointIndexesToRestore.splice(originalElevationPointIndexesToRestoreIndexOf, 1)
      }
    })
  }

  // ==== Restore original points ====
  // Clipped points have already been removed from originalElevationPointIndexesToRestore above
  // We know that all remaining indexes should be restored.

  originalElevationPointIndexesToRestore.forEach((index) => {
    data[index] = dataOriginal[index]
  })

  if (DSM_OPTIMIZATION_METHOD === 'DELATIN') {
    // DELATIN
    const tin = new oslib3d.Delatin(data, w, h, DELATIN_ERROR_DAMPING_TUNING_FACTOR, buildingFoundInTileArray)

    // create reference to help with debugging/dve
    window.tin = tin

    // run mesh refinement until max error is less than supplied value
    if (!OsTerrain.RUN_DELATIN_ASYNC) {
      tin.run(DELATIN_MAX_ERROR)
    } else {
      await tin.runAsync(DELATIN_MAX_ERROR)
    }

    const { coords, triangles } = tin // get vertices and triangles of the mesh

    const geometry = new THREE.Geometry()

    for (let i = 0; i < coords.length; i += 2) {
      geometry.vertices.push(
        new THREE.Vector3(
          ((coords[i] / w) * 1 - 1 / 2) * terrainSize3857[0],
          ((1 - coords[i + 1] / h) * 1 - 1 / 2) * terrainSize3857[1],
          tin.heightAt(coords[i], coords[i + 1])
        )
      )
    }

    for (let f = 0; f < triangles.length; f += 3) {
      var face = new THREE.Face3(triangles[f], triangles[f + 1], triangles[f + 2])
      geometry.faces.push(face)
    }

    // @TODO? Put center point of geometry at z=6m (assuming on roof and 2 stories high)
    if (!matchSuppliedTerrainPositionZ) {
      terrainPositionZ = -1 * (elevationAtCentroid + terrainPositionZ)
    }

    // @TODO: avoid calculating bounding box here which is quite slow for a large geometry and would need to be recalculated anyway
    // after adding the skirt
    geometry.computeBoundingBox()
    var geometryBoundingBox = geometry.boundingBox

    //e.g. Geometry.faceVertexUvs[UV LAYER][face index][uv[face.a], uv[face.b], uv[face.c]]
    var xyToUv = (x, y) =>
      new THREE.Vector2(
        (x - geometryBoundingBox.min.x) / terrainSize3857[0],
        (y - geometryBoundingBox.min.y) / terrainSize3857[1]
      )

    for (let faceIndex = 0; faceIndex < geometry.faces.length; faceIndex++) {
      geometry.faceVertexUvs[0].push([
        xyToUv(geometry.vertices[geometry.faces[faceIndex].a].x, geometry.vertices[geometry.faces[faceIndex].a].y),
        xyToUv(geometry.vertices[geometry.faces[faceIndex].b].x, geometry.vertices[geometry.faces[faceIndex].b].y),
        xyToUv(geometry.vertices[geometry.faces[faceIndex].c].x, geometry.vertices[geometry.faces[faceIndex].c].y),
      ])
    }

    if (ENABLE_MINECRAFTIFY) {
      var worldXyToUv = (x, y) => {
        const uv = new THREE.Vector2(x / terrainSize3857[0] + 0.5, y / terrainSize3857[1] + 0.5)
        if (uv.x === 0) {
          // there is a bit of bleeding between the unblurred ang blurred image at the point where they meet
          // we need to sample a few pixels to the right to avoid the bleeding area
          uv.x += 0.005
        }
        return uv
      }
      var topVerticesForSkirt = OsTerrain.getTopVerticesForSkirt(data, w, h, cellSize3857)
      var skirtBaseZ = OsTerrain.getLowestElevationApproximation(data, w, h) - 2
      var skirtGeometry = OsTerrain.buildSkirtGeometryFromVertices(topVerticesForSkirt, skirtBaseZ, worldXyToUv)
      geometry.merge(skirtGeometry)
    }

    geometry.uvsNeedUpdate = true
    geometry.computeFaceNormals()
    geometry.computeVertexNormals()
    geometry.computeBoundingBox()

    // Different texture for walls
    // Only run after all faces have been added (including for terrain and skirt) and normals calculated.
    // record faces for walls
    const wallFacesIndices = []
    geometry.faces.forEach((face, faceIndex) => {
      // No need for Math.abs() because DSM faces can never point down
      if (!(face.normal.z > 0.3 || faceHeight(face, geometry.vertices) < 0.5)) {
        wallFacesIndices.push(faceIndex)
      }
    })

    return { geometry, originalElevationPointIndexes, wallFacesIndices, terrainPositionZ }
  } else {
    //DENSE TERRAIN GRID, not optimized
    var row, col

    var edgeIndexes = []
    var missingIndexes = []

    const geometry = new THREE.PlaneGeometry(terrainSize3857[0], terrainSize3857[1], w - 1, h - 1)

    ////////////////////////////
    // MINECRAFT-IFY
    //
    // Set all edge vertices to 0 so edges look nice like Minecraft, and we don't see inside meshes
    // @TODO: We can improve speed by starting/ending the loop to exclude first/last rows

    // Quickly and safely get any arbitrary Z value so missing values don't make our boundingBox invalid
    // This is important to avoid complication later with missing values and bounding boxes
    var arbitraryValidZ
    for (let i = 0, l = geometry.vertices.length; i < l; i++) {
      if (data[i] > -9000) {
        arbitraryValidZ = data[i]
        break
      }
    }

    for (let i = 0, l = geometry.vertices.length; i < l; i++) {
      row = Math.floor(i / w)
      col = i % w

      if (row === 0 || row === h - 1 || col === 0 || col >= w - 1) {
        geometry.vertices[i].z = arbitraryValidZ
        edgeIndexes.push(i)
      } else if (data[i] > -9000) {
        geometry.vertices[i].z = data[i]
      } else {
        // set missing values (often set to -9999) to lowest point on mesh
        geometry.vertices[i].z = arbitraryValidZ
        missingIndexes.push(i)
      }
    }

    // place lowest point in the model at z=0
    // calculate minZ before breaking it by modifying edges
    geometry.computeBoundingBox()
    var geometryMinZ = geometry.boundingBox.min.z

    edgeIndexes.forEach(function (i) {
      //2 meters below lowest point
      geometry.vertices[i].z = geometryMinZ - 2
    })

    missingIndexes.forEach(function (i) {
      geometry.vertices[i].z = geometryMinZ
    })

    // END MINECRAFT-IFY
    ////////////////////////////////

    if (!matchSuppliedTerrainPositionZ) {
      terrainPositionZ += -geometryMinZ
    }

    // skirtGeometry not enabled for old non-optimzed method it's unused

    return { geometry, originalElevationPointIndexes, terrainPositionZ }
  }
}

OsTerrain.loadTerrainAtLocation = function (
  _editor,
  provider,
  location4326,
  terrainPositionUnused,
  createView,
  onComplete,
  showNotificationOnError,
  filterResultsFunc,
  commandUUID
) {
  // Flag that terrain search is running and ensure UI loading animation shows immediately
  _editor.waiting.terrainSearch = true
  _editor.setProgress('all', 0)
  _editor.signals.viewsChanged.dispatch()

  var onTerrainFound = function (status, dsmUrl, orthoUrl, terrainCenter4326, rotationDegrees) {
    // 3D content is available, it will already have a new view created.
    // Proceeding with loading standard imagery
    if (status === true) {
      // Unload existing terrain if already loaded
      OsTerrain.unloadTexturedDSM(_editor)

      // Terrain will already be loading but we need to create the Nearmap3D View here

      if (createView === true) {
        // This loads the URLs into the View/MapInstance but they are only loaded when view is first set to active
        let options = {
          // If appStorage use that. This can be updated by ossdk
          orientation: window.getStorage().getItem('studio.defaultCameraOrientationFor3D') || undefined,
        }

        var viewInstance = window.ViewHelper.build3DViewInstance(
          provider === 'nearmap' ? 'Nearmap3D' : 'Google3D',
          options
        )

        // select this view
        // insert into first position
        editor.execute(new AddViewCommand(viewInstance, ViewHelper, true, 0, commandUUID))
      }

      editor.execute(new SetValueCommand(editor.scene, 'terrainProvider', provider, commandUUID))
      editor.execute(new SetValueCommand(editor.scene, 'dsmUrl', dsmUrl, commandUUID))
      editor.execute(new SetValueCommand(editor.scene, 'orthoUrl', orthoUrl, commandUUID))

      var terrainPosition = OsTerrain.positionInScene(
        _editor.scene.sceneOrigin4326,
        terrainCenter4326,
        _editor.scene.terrainPosition ? _editor.scene.terrainPosition.z : 'not-set'
      )
      editor.execute(new SetValueCommand(editor.scene, 'terrainPosition', terrainPosition, commandUUID))

      if (rotationDegrees) {
        new SetValueCommand(editor.scene, 'terrainRotationZ', -1 * rotationDegrees * THREE.Math.DEG2RAD, commandUUID)
      }

      // Since we can only have one type of terrain at at time, upon loading new terrain
      // convert any 3D views to match the terrain provider just loaded
      window.ViewHelper.convertAll3DViewsToProvider(provider)

      // Let viewsUpdated detect that 3D urls exist that haven't yet been loaded then load DSM
      _editor.signals.viewsChanged.dispatch()
    }
  }

  AccountHelper.getTerrainUrlsAtLocation(
    provider,
    location4326,
    window.WorkspaceHelper?.project?.country_iso2,
    window.WorkspaceHelper?.project?.state,
    function (data) {
      onTerrainFound(true, data['dsm'], data['ortho'], [data['lon'], data['lat']], data['rotation_degrees'])
      if (onComplete) {
        onComplete(true)
      }
      _editor.waiting.terrainSearch = false
    },
    function (response) {
      if (showNotificationOnError && response && response.responseJSON && response.responseJSON.detail) {
        var message
        try {
          message = response.responseJSON.detail
        } catch (e) {
          message = '3D Data not found'
        }
        Designer.showNotification(message, 'danger')
      }

      onTerrainFound(false)
      if (onComplete) {
        onComplete(false)
      }
      _editor.waiting.terrainSearch = false
    },
    filterResultsFunc
  )
}

OsTerrain.unloadTexturedDSM = function (_editor) {
  // Should this be moved into OsDesignerScene?
  // Do not use editor.getTerrain() just in case we have more than one terrain loaded
  _editor.filter('type', 'OsTerrain').forEach((osTerrain) => {
    if (osTerrain.parent) {
      _editor.removeObject(osTerrain)
    }
  })
  _editor.scene.terrainProvider = null
  _editor.scene.terrain = null
  _editor.scene.dsmUrlLoadRequested = null
  _editor.scene.orthoUrlLoadRequested = null
  _editor.waiting.terrainDsm = false
  _editor.waiting.terrainTexture = false
  _editor.waiting.terrainFirstRender = false

  _editor.setProgress('all', 1)
}

OsTerrain.createTextureAtlasImage = function (image, type, debug) {
  const canvas = document.createElement('canvas')
  canvas.width = image.width + image.width
  canvas.height = image.height
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = '#dddddd'
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  if (debug) {
    canvas.style.width = canvas.width / 2 + 'px'
    canvas.style.height = canvas.height / 2 + 'px'
    canvas.style.position = 'absolute'
    canvas.style.zIndex = 10000
    canvas.style.margin = 'auto'
    canvas.style.border = '2px solid darkcyan'
    canvas.style.top = 0
    canvas.style.bottom = 0
    canvas.style.left = 0
    canvas.style.right = 0
    document.body.appendChild(canvas)
  }

  const blurRadius = window.editor?.terrainSettings.wallBlurringRadiusPx || 15
  const brightness = window.editor?.terrainSettings.wallBlurringBrightnessPercent || 85
  const contrast = window.editor?.terrainSettings.wallBlurringContrastPercent || 70

  if (type === 'terrain') {
    ctx.drawImage(image, 0, 0)
    ctx.filter = `blur(${blurRadius}px) brightness(${brightness}%) contrast(${contrast}%)`
    ctx.drawImage(image, image.width, 0)
    return ctx.canvas
  }

  if (type === 'heatmap') {
    let imageData = new ImageData(new Uint8ClampedArray(image.data), image.width, image.height)
    ctx.putImageData(imageData, 0, 0)
    ctx.filter = `blur(${blurRadius}px) brightness(${brightness}%) contrast(${contrast}%)`
    ctx.drawImage(canvas, image.width, 0)
    return ctx.canvas
  }
}

OsTerrain.convertModelTransformationToWorldFile = function (modelTransformation) {
  // Assume modelTransformation is a 3x3 matrix [A, B, 0, D, E, 0, C, F, 1] (Affine transformation)

  const A = modelTransformation[0] // Coefficient for pixelX in worldX
  const B = modelTransformation[1] // Coefficient for pixelY in worldX
  const C = modelTransformation[6] // Translation in worldX (X-coordinate of upper-left pixel)

  const D = modelTransformation[3] // Coefficient for pixelX in worldY
  const E = modelTransformation[4] // Coefficient for pixelY in worldY
  const F = modelTransformation[7] // Translation in worldY (Y-coordinate of upper-left pixel)

  // The world file coefficients are [A, D, B, E, C, F]
  return [A, D, B, E, C, F]
}

OsTerrain.tiffContentsToWorldFile = function (tiff) {
  if (tiff.fileDirectories[0][0].ModelTransformation) {
    return OsTerrain.convertModelTransformationToWorldFile(tiff.fileDirectories[0][0].ModelTransformation)
  } else if (tiff.fileDirectories[0][0].ModelTiepoint) {
    // Assuming the first tiepoint (usually only one is present) and first pixel scale
    // const [i, j, k, x, y, z] = tiff.fileDirectories[0][0].ModelTiepoint // i,j are pixel indices, x,y,z are the geographic coordinates
    const x = tiff.fileDirectories[0][0].ModelTiepoint[3]
    const y = tiff.fileDirectories[0][0].ModelTiepoint[4]

    const pixelScaleX = tiff.fileDirectories[0][0].ModelPixelScale[0] // Pixel size in x-direction
    const pixelScaleY = -1 * tiff.fileDirectories[0][0].ModelPixelScale[1] // Pixel size in y-direction (typically negative)

    // The world file coefficients
    return [
      pixelScaleX, // Pixel size in the x-direction
      0.0, // Rotation term (0 if no rotation)
      0.0, // Rotation term (0 if no rotation)
      pixelScaleY, // Pixel size in the y-direction (usually negative)
      x + pixelScaleX / 2, // X coordinate of the center of the upper-left pixel
      y - pixelScaleY / 2, // Y coordinate of the center of the upper-left pixel
    ]
  } else {
    throw new Error('Unexpected tiff format')
  }
}
