/**
 * @author adampryor
 */

function getStudioDetail() {
  if (window.studioDetail) {
    return window.studioDetail
  } else {
    return 'high'
  }
}

var cacheFacetMesh = {
  roofGroundTextures: {
    default: undefined,
  },
  roofs: {
    default: undefined,
  },
  walls: {
    default: undefined,
  },
}

var visibilityByType = {
  textures: true,
  interactiveElements: true,
}

var textureImageOnLoad = function () {
  if (editor && editor.signals && editor.signals.materialChanged) {
    editor.signals.materialChanged.dispatch()
  }
}

//@TODO: Switch to environment mapping so textures continue along slighly imperfect walls???
var createWallMaterial = function (wallTypeTexture) {
  if (cacheFacetMesh.walls[wallTypeTexture]) {
    return cacheFacetMesh.walls[wallTypeTexture]
  }

  // Use default wall texture if no specific texture available
  if (!Designer.FILE_PATHS.WALL_TEXTURES.hasOwnProperty(wallTypeTexture)) {
    wallTypeTexture = 'default'
  }

  var wallTextureImage = Designer.FILE_PATHS.WALL_TEXTURES[wallTypeTexture]

  // If texture is found but empty, make transparent
  if (!wallTextureImage) {
    return cacheFacetMesh.walls['default']
  }

  var imageUrl = Designer.prepareFilePathForLoad(wallTextureImage)

  var material = new THREE.MeshStandardMaterial({
    color: 0x999999,

    //@TODO: Try to fix order of faces to avoid needing DoubleSide
    side: THREE.DoubleSide,

    //@todo: Cache core image texture. Cannot cache the map because it includes rotation
    map: THREE.ImageUtils.loadTexture(imageUrl, undefined, textureImageOnLoad),
    roughness: 0.9,
    metalness: 0.1,
    visible: visibilityByType.textures,
  })

  material.map.wrapS = THREE.RepeatWrapping
  material.map.wrapT = THREE.RepeatWrapping

  var textureMeterPerPixel = 0.4
  material.map.repeat = new THREE.Vector2(textureMeterPerPixel, textureMeterPerPixel)
  material.map.offset = new THREE.Vector2(0.0, 0.0)

  material.map.needsUpdate = true

  cacheFacetMesh.walls[wallTypeTexture] = material

  return material
}

var createRoofMaterial = function (roofTypeTexture, azimuth) {
  //@TODO: Clear old roof materials which may cause memory leak as new versions created for new azimuths

  var materialCacheKey = roofTypeTexture + '_' + azimuth

  if (cacheFacetMesh.roofs[materialCacheKey]) {
    return cacheFacetMesh.roofs[materialCacheKey]
  }

  // Use default roof texture if no specific texture available
  if (!Designer.FILE_PATHS.ROOF_TEXTURES.hasOwnProperty(roofTypeTexture)) {
    roofTypeTexture = 'default'
  }

  var roofTextureImage = Designer.FILE_PATHS.ROOF_TEXTURES[roofTypeTexture]

  // If texture is found but empty, make transparent
  if (!roofTextureImage) {
    return cacheFacetMesh['material']
  }

  var imageUrl = Designer.prepareFilePathForLoad(roofTextureImage)

  var material = new THREE.MeshStandardMaterial({
    color: 0x808080,
    side: THREE.DoubleSide,
    //@todo: Cache core image texture. Cannot cache the map because it includes rotation
    map: THREE.ImageUtils.loadTexture(imageUrl, undefined, textureImageOnLoad),
    roughness: 1.0,
    visible: visibilityByType.textures,
    renderOrder: window.RENDER_ORDER.FacetMesh,
  })

  material.map.wrapS = THREE.RepeatWrapping
  material.map.wrapT = THREE.RepeatWrapping

  material.map.repeat = new THREE.Vector2(0.2, 0.2)
  material.map.offset = new THREE.Vector2(0.0, 0.0)

  material.map.needsUpdate = true
  material.map.rotation = -1.0 * azimuth * THREE.Math.DEG2RAD

  cacheFacetMesh.roofs[materialCacheKey] = material

  return material
}

function OsFacetMesh(_facet, vertices) {
  THREE.Mesh.call(this)
  this.facet = _facet

  this.userData.excludeFromExport = true

  //Create all materials on first load
  if (!cacheFacetMesh.material) {
    Object.assign(cacheFacetMesh, {
      material: new THREE.MeshStandardMaterial({
        side: THREE.DoubleSide,
        flatShading: THREE.FlatShading,
        transparent: true,
        opacity: 0.1,

        //seemingly needs to be visible to pickup intersections! Beware setting to false
        visible: visibilityByType.interactiveElements,
      }),
      materialShadowOnly: new THREE.ShadowMaterial({
        opacity: 0.1,
      }),
      materialFacetClipped: new THREE.MeshStandardMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.2,
        visible: visibilityByType.interactiveElements,
      }),
      materialSetbacks: new THREE.ShaderMaterial({
        transparent: true,
        visible: visibilityByType.interactiveElements,
        renderOrder: window.RENDER_ORDER.FacetMeshSetbacks,
        uniforms: {
          color: new THREE.Uniform(new THREE.Color(0xedc322)),
          gap: new THREE.Uniform(4),
          opacity: new THREE.Uniform(0.7),
          devicePixelRatio: new THREE.Uniform(Math.floor(window.devicePixelRatio)),
        },
        vertexShader: `
          void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          uniform vec3 color;
          uniform int gap;
          uniform float opacity;
          uniform int devicePixelRatio;

          void main() {
            int mod = int(floor(gl_FragCoord.x)) % (gap * devicePixelRatio);
            float alpha = float(int(mod == 0));
            gl_FragColor = vec4(color, alpha * opacity);
          }
        `,
      }),
      materialSetbacksOutline: new THREE.LineBasicMaterial({
        color: new THREE.Color(0xedc322),
        opacity: 0.85,
        transparent: true,
        visible: visibilityByType.interactiveElements,
        renderOrder: window.RENDER_ORDER.FacetMeshSetbacks,
      }),
      walls: {
        default: undefined,
      },
      roofs: {
        default: undefined,
      },
    })
  }

  var roofTexture = this.getRoofTexture()
  var wallTexture = this.getWallTexture()

  this.useShadowOnly = !roofTexture
  this.castShadow = getStudioDetail() === 'high' //even though the full facet is not visible, it casts a shadow

  // receiveShadow if using a texture. Otherwise use a shadow-only layer
  this.receiveShadow = roofTexture && getStudioDetail() === 'high'

  //this.drawMode = 0;
  //this.updateMorphTargets();

  this.type = 'OsFacetMesh'

  this.helpers = []

  //not selectable unless a facet is assigned
  this.selectable = false

  // avoid calling this unless necessary and only once per call because it could be heavy if terrain is loaded
  var baseBottomZ = null

  if (_facet && vertices.length >= 3) {
    //_facet.mesh = this;//applied by facet, not here. It could go either way but it's more explicit in OsFacet.
    this.selectable = true
    this.selectionDelegate = _facet

    //Draw lines
    if (!_facet.obstruction) {
      this.helpers.push(this.drawAzimuthLine())
      this.helpers.push(this.drawNormalLine())
    }

    //Generate shapes for various layers

    var shapes = _facet.shapesWithSetbacksJSTS(!this.facet.obstruction)

    if (shapes['facetShape']) {
      try {
        var areaFactorFromSlope = _facet.slope ? 1 / Math.cos(_facet.slope * Math.DEG_PER_RAD) : 1.0
        _facet.area = areaFactorFromSlope * shapes['facetShape'].getArea()
      } catch (err) {
        _facet.area = null
      }
    } else {
      _facet.area = null
    }

    //Draw shadow layer (only if not using a texture for this facet)

    if (shapes['facetShape'] && this.useShadowOnly) {
      var shadowLayerParts = Utils.jstsShapeToParts(shapes['facetShape'])
      if (shadowLayerParts.contour && shadowLayerParts.holes) {
        var shadowLayerGeometry = Utils.geometryFromVertices(
          shadowLayerParts['contour'],
          shadowLayerParts['holes'],
          _facet.plane
        )
        if (shadowLayerGeometry) {
          //Make geomerty relative to FacetMesh (convert from world units to local units)
          shadowLayerGeometry.vertices.forEach(function (v) {
            v.sub(_facet.position)
          }, this)

          var shadowLayer = new THREE.Mesh(shadowLayerGeometry, cacheFacetMesh['materialShadowOnly'])
          shadowLayer.position.copy(_facet.plane.normal.clone().multiplyScalar(0.1)) //render shadows on top of other meshes
          shadowLayer.name = 'FacetMeshShadowLayer'

          // @TODO: Do we need to ensure this is applied/unapplied at the right time, e.g. when terrain loaded/unloaded
          shadowLayer.castShadow = false
          // if (editor.getTerrain()) {
          //   shadowLayer.castShadow = false
          // } else {
          //   shadowLayer.castShadow = getStudioDetail() === 'high'
          // }

          shadowLayer.receiveShadow = getStudioDetail() === 'high'
          shadowLayer.selectable = false
          shadowLayer.userData.excludeFromExport = true
          this.add(shadowLayer)
          this.helpers.push(shadowLayer)
        }
      }
    }

    //Draw full facet

    //@todo: Can we remove? Need this for intersections/assignments of modules/grids/floaters.
    if (shapes['facetShape']) {
      var facetMeshParts = Utils.jstsShapeToParts(shapes['facetShape'])
      if (facetMeshParts.contour && facetMeshParts.holes) {
        var facetMeshGeometry = Utils.geometryFromVertices(
          facetMeshParts['contour'],
          facetMeshParts['holes'],
          _facet.plane
        )

        //Make geomerty relative to FacetMesh (convert from world units to local units)
        facetMeshGeometry.vertices.forEach(function (v) {
          v.sub(_facet.position)
        }, this)

        this.geometry = facetMeshGeometry
        this.geometry.elementsNeedUpdate = true
        this.geometry.uvsNeedUpdate = true
        this.geometry.computeFaceNormals()

        var facetDisplayMode = ViewHelper.facetDisplayMode()

        this.refreshMaterial(facetDisplayMode)

        ///////////////////////////////////////////////////////
        /// DRAW WALLS
        //
        //Draw walls as a plane dropping from each edge
        //Only draw walls in GoogleStatic (when ground is set)
        //if(editor.ground && editor.ground.parent){
        if (true) {
          if (!baseBottomZ) {
            baseBottomZ = editor.getBaseElevation()
          }

          this.facet.getEdges().forEach(function (edgeLine) {
            //Only draw walls if one point is significantly above the ground/baseBottomZ
            if (
              (edgeLine.nodes[0].position.z > baseBottomZ + 0.5 || edgeLine.nodes[1].position.z > baseBottomZ + 0.5) !==
              true
            ) {
              return
            }

            // Geometry is a) rectangle plus b) triangle on top

            var higher, lower
            var higherIs0 = null
            if (edgeLine.nodes[0].position.z === edgeLine.nodes[1].position.z) {
              //If roof is flat (i.e. if points have same elevation) then do not add the extra triangle
              higherIs0 = null

              //does not matter
              higher = edgeLine.nodes[0].position
              lower = edgeLine.nodes[1].position
            } else if (edgeLine.nodes[0].position.z > edgeLine.nodes[1].position.z) {
              higherIs0 = true
              higher = edgeLine.nodes[0].position
              lower = edgeLine.nodes[1].position
            } else {
              higherIs0 = false
              higher = edgeLine.nodes[1].position
              lower = edgeLine.nodes[0].position
            }

            var wallGeometry = new THREE.Geometry()
            wallGeometry.vertices = [
              this.relativePosition(
                new THREE.Vector3(edgeLine.nodes[0].position.x, edgeLine.nodes[0].position.y, baseBottomZ)
              ),
              this.relativePosition(
                new THREE.Vector3(edgeLine.nodes[1].position.x, edgeLine.nodes[1].position.y, baseBottomZ)
              ),
              this.relativePosition(
                new THREE.Vector3(edgeLine.nodes[1].position.x, edgeLine.nodes[1].position.y, lower.z)
              ),
              this.relativePosition(
                new THREE.Vector3(edgeLine.nodes[0].position.x, edgeLine.nodes[0].position.y, lower.z)
              ),
            ]

            if (higherIs0 !== null) {
              wallGeometry.vertices.push(this.relativePosition(higher))
            }

            //@TODO: Set direction to always face out towards camera, instead of using double sided texture
            wallGeometry.faces = [new THREE.Face3(1, 2, 0), new THREE.Face3(3, 0, 2)]

            if (higherIs0 !== null) {
              wallGeometry.faces.push(higherIs0 ? new THREE.Face3(3, 2, 4) : new THREE.Face3(2, 4, 3))
            }

            wallGeometry.faceVertexUvs[0] = []

            var diff = new THREE.Vector3().subVectors(edgeLine.nodes[0].position, edgeLine.nodes[1].position)
            var diffApexZ = Math.abs(diff.z)
            diff.z = 0
            var diffXY = diff.length()

            var tx = diffXY
            var ty = lower.z - baseBottomZ
            var tyApex = diffApexZ

            wallGeometry.faceVertexUvs[0].push([
              new THREE.Vector2(tx, 0),
              new THREE.Vector2(tx, ty),
              new THREE.Vector2(0, 0),
            ])

            wallGeometry.faceVertexUvs[0].push([
              new THREE.Vector2(0, 0),
              new THREE.Vector2(0, ty),
              new THREE.Vector2(tx, 0),
            ])

            if (higherIs0 !== null) {
              if (higherIs0) {
                wallGeometry.faceVertexUvs[0].push([
                  new THREE.Vector2(0, 0),
                  new THREE.Vector2(tx, 0),
                  new THREE.Vector2(0, tyApex),
                ])
              } else {
                wallGeometry.faceVertexUvs[0].push([
                  new THREE.Vector2(0, 0),
                  new THREE.Vector2(0, tyApex),
                  new THREE.Vector2(tx, 0),
                ])
              }
            }

            wallGeometry.elementsNeedUpdate = true
            wallGeometry.uvsNeedUpdate = true
            wallGeometry.computeFaceNormals()

            var material = createWallMaterial(wallTexture)

            if (material && material.map) {
              material.map.needsUpdate = true
            }

            var wallMesh = new THREE.Mesh(wallGeometry, material)
            wallMesh.name = 'wallMesh'
            wallMesh.selectable = false
            wallMesh.castShadow = true

            this.add(wallMesh)
            this.helpers.push(wallMesh)
          }, this)
        }
      }
    }

    // Draw Wall Edges as non-selectable OsEdges but which will show annotations on rollover
    // Hide these if we are viewing any imagery views (top-down/obliques)
    var wallCorner
    var groundNode
    var roofNode
    this.facet.vertices.forEach(function (node) {
      if (!(node.position.z > 0.5)) {
        return
      }

      //@TODO: Fix z to intersect with terrain/ground, whatever height that may be at this location
      // groundNode = new OsNode({
      //   position: this.relativePosition(new THREE.Vector3(node.position.x, node.position.y, 0)),
      // })
      // groundNode.userData.excludeFromExport = true
      // this.add(groundNode)
      //
      // roofNode = new OsNode({
      //   position: this.relativePosition(node.position),
      // })
      // roofNode.userData.excludeFromExport = true
      // this.add(roofNode)

      if (!baseBottomZ) {
        baseBottomZ = editor.getBaseElevation()
      }

      groundNode = new OsNode({
        position: new THREE.Vector3(node.position.x, node.position.y, baseBottomZ),
        selectable: false,
        visible: false,
      })
      groundNode.userData.excludeFromExport = true
      groundNode.userData.ephemeral = true
      groundNode.ghostMode(true)
      this.helpers.push(groundNode)
      editor.addObject(groundNode)

      roofNode = new OsNode({
        position: node.position,
        selectable: false,
        visible: false,
      })
      roofNode.userData.excludeFromExport = true
      roofNode.userData.ephemeral = true
      roofNode.ghostMode(true)
      this.helpers.push(roofNode)
      editor.addObject(roofNode)

      wallCorner = new OsEdge({ nodes: [roofNode, groundNode], selectable: false })
      wallCorner.userData.excludeFromExport = true
      wallCorner.ghostMode(true)
      wallCorner.visible = Designer.style !== 'planset' && ViewHelper.facetWallsDisplayMode() !== 'planset'
      wallCorner.isWallCorner = true
      this.helpers.push(wallCorner)
      editor.addObject(wallCorner)
    }, this)

    /// END DRAW WALLS
    ///////////////////////////////////////////////////////

    //Draw setbacks

    if (shapes['facetClippedShape'] && this.useShadowOnly) {
      var meshFacetClippedParts = Utils.jstsShapeToParts(shapes['facetClippedShape'])
      if (meshFacetClippedParts.contour && meshFacetClippedParts.holes) {
        var meshFacetClippedGeometry = Utils.geometryFromVertices(
          meshFacetClippedParts['contour'],
          meshFacetClippedParts['holes'],
          _facet.plane
        )
        if (meshFacetClippedGeometry) {
          //Make geomerty relative to FacetMesh (convert from world units to local units)
          meshFacetClippedGeometry.vertices.forEach(function (v) {
            v.sub(_facet.position)
          }, this)

          this.meshFacetClipped = new THREE.Mesh(meshFacetClippedGeometry, cacheFacetMesh['materialFacetClipped'])
          this.meshFacetClipped.name = 'FacetMeshClipped'
          this.meshFacetClipped.selectionDelegate = _facet
          this.meshFacetClipped.userData.excludeFromExport = true
          this.add(this.meshFacetClipped)
          this.helpers.push(this.meshFacetClipped)
        }
      }
    }

    //Don't display if setbacks are empty
    if (shapes['setbacksShape']) {
      var meshSetbacksParts = Utils.jstsShapeToParts(shapes['setbacksShape'])
      if (meshSetbacksParts.contour && meshSetbacksParts.holes) {
        var meshSetbacksGeometry = Utils.geometryFromVertices(
          meshSetbacksParts['contour'],
          meshSetbacksParts['holes'],
          _facet.plane
        )

        if (meshSetbacksGeometry) {
          //Make geomerty relative to FacetMesh (convert from world units to local units)
          meshSetbacksGeometry.vertices.forEach(function (v) {
            v.sub(_facet.position)
          }, this)

          this.meshSetbacks = new THREE.Mesh(meshSetbacksGeometry, cacheFacetMesh['materialSetbacks'])
          this.meshSetbacks.name = 'FacetMeshSetbacks'
          this.meshSetbacks.selectionDelegate = _facet
          this.meshSetbacks.userData.excludeFromExport = true
          this.meshSetbacks.position.z += 0.01 //prevent z-fighting if whole facet mesh is shown with texture
          this.add(this.meshSetbacks)
          this.helpers.push(this.meshSetbacks)

          if (meshSetbacksParts.holes.length > 0) {
            this.meshSetbacksOutline = new THREE.Group()
            this.meshSetbacksOutline.name = 'FacetMeshSetbacksOutline'
            this.meshSetbacksOutline.selectionDelegate = _facet
            this.meshSetbacksOutline.userData.excludeFromExport = true
            this.meshSetbacksOutline.position.z += 0.01 // to prevent z-fighting with roof facet

            meshSetbacksParts.holes.forEach((holeVertices) => {
              const points = holeVertices.map((vertex) => {
                const line = new THREE.Line3(
                  new THREE.Vector3(vertex.x, vertex.y, -1000),
                  new THREE.Vector3(vertex.x, vertex.y, 1000)
                )
                const intersection = _facet.plane.intersectLine(line, new THREE.Vector3())
                return intersection.sub(_facet.position)
              })

              if (points.length === 0 || isNaN(points[0].x)) {
                console.warn('FacetMeshSetbacksOutline has a hole with no valid points, skip it')
                return
              }

              const line = new THREE.Line(
                new THREE.BufferGeometry().setFromPoints(points),
                cacheFacetMesh['materialSetbacksOutline']
              )
              line.name = 'SetbacksOutline'
              line.selectionDelegate = _facet
              line.userData.excludeFromExport = true
              this.meshSetbacksOutline.add(line)
            })

            this.add(this.meshSetbacksOutline)
            this.helpers.push(this.meshSetbacksOutline)
          }
        }
      }
    }
  }

  this.getFacets = function () {
    return [this.facet]
  }

  return this
}

OsFacetMesh.setVisibilityByType = function (type, value) {
  switch (type) {
    case 'textures':
      Object.keys(cacheFacetMesh.roofs)
        .map(function (e) {
          return cacheFacetMesh.roofs[e]
        })
        .forEach(function (m) {
          if (m && m.visible !== value) {
            m.visible = value
          }
        })
      Object.keys(cacheFacetMesh.walls)
        .map(function (e) {
          return cacheFacetMesh.walls[e]
        })
        .forEach(function (m) {
          if (m && m.visible !== value) {
            m.visible = value
          }
        })
      Object.keys(cacheFacetMesh.roofGroundTextures)
        .map(function (e) {
          return cacheFacetMesh.roofGroundTextures[e]
        })
        .forEach(function (m) {
          if (m && m.visible !== value) {
            m.visible = value
          }
        })
      if (visibilityByType.textures !== value) visibilityByType.textures = value
      break
    case 'interactiveElements':
      //Warning: Making cacheFacetMesh.material invisible prevents clicks/intersections from being detected
      if (cacheFacetMesh.material && cacheFacetMesh.material.visible !== value) cacheFacetMesh.material.visible = value
      if (cacheFacetMesh.materialFacetClipped && cacheFacetMesh.materialFacetClipped.visible !== value)
        cacheFacetMesh.materialFacetClipped.visible = value
      if (cacheFacetMesh.materialSetbacks && cacheFacetMesh.materialSetbacks.visible !== value)
        cacheFacetMesh.materialSetbacks.visible = value
      if (cacheFacetMesh.materialSetbacksOutline && cacheFacetMesh.materialSetbacksOutline.visible !== value)
        cacheFacetMesh.materialSetbacksOutline.visible = value
      if (visibilityByType.interactiveElements !== value) visibilityByType.interactiveElements = value
      break
    default:
      break
  }
}

OsFacetMesh.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsFacetMesh,
  getRoofTexture: function () {
    return this.facet.roofType() ? this.facet.roofType().texture : this.facet.roofTexture
  },
  getWallTexture: function () {
    return this.facet.wallType() ? this.facet.wallType().texture : this.facet.wallTexture
  },
  refreshMaterial: function (facetDisplayMode) {
    var ground = editor.getGround()
    var groundVariationData = editor.scene.groundVariationData()
    var roofTexture = this.getRoofTexture()

    if (facetDisplayMode === 'ground' && !!ground) {
      //Handle if still loading
      if (!ground.material || !ground.material.map || !ground.material.map.image) {
        console.log('Warning: looks like ground imagery is stil loading...')
        this.material = new THREE.MeshStandardMaterial({
          color: 0x808080,
          side: THREE.DoubleSide,
          roughness: 0.0,
          metalness: 0.0,
          visible: false,
        })
      } else {
        //Copy texture from ground
        var facetMeshTexture = new THREE.Texture(ground.material.map.image)
        facetMeshTexture.rotation = ground.rotation.z

        facetMeshTexture.wrapS = THREE.ClampToEdgeWrapping
        facetMeshTexture.wrapT = THREE.ClampToEdgeWrapping

        var textureScalingU = groundVariationData.scale ? groundVariationData.scale[0] : 1
        var textureScalingV = groundVariationData.scale ? groundVariationData.scale[1] : 1
        facetMeshTexture.repeat = new THREE.Vector2(
          1 / (ground.size[0] * textureScalingU),
          1 / (ground.size[1] * textureScalingV)
        )
        var groundBoundRight = ground.localToWorld(new THREE.Vector3(ground.size[0] / 2, 0, 0))
        var groundBoundTop = ground.localToWorld(new THREE.Vector3(0, ground.size[1] / 2, 0))
        var groundVecX = groundBoundRight.clone().sub(ground.position)
        var groundVecY = groundBoundTop.clone().sub(ground.position)
        var offsetX =
          (groundVecX.dot(this.position.clone().sub(ground.position)) / groundVecX.length() + groundVecX.length()) /
          (groundVecX.length() * 2)
        var offsetY =
          (groundVecY.dot(this.position.clone().sub(ground.position)) / groundVecY.length() + groundVecY.length()) /
          (groundVecY.length() * 2)
        facetMeshTexture.offset = new THREE.Vector2(offsetX, offsetY)

        this.material = new THREE.MeshStandardMaterial({
          color: 0x808080,
          map: facetMeshTexture,
          side: THREE.DoubleSide,
          roughness: 1.0,
          visible: visibilityByType.textures,
        })

        //Store so we can use later for show/hide visibility
        cacheFacetMesh.roofGroundTextures[this.facet.uuid] = this.material

        this.geometry.uvsNeedUpdate = true
        if (this.material && this.material.map) {
          this.material.map.needsUpdate = true
        }
      }
    } else if (facetDisplayMode === 'library' && roofTexture) {
      // can we remove the extra check for roofTexture?
      this.material = createRoofMaterial(roofTexture, this.facet.azimuth)
    } else {
      // Not using texture or no texture available
      // 'edges' or 'library' with no texture available
      this.material = new THREE.MeshStandardMaterial({
        color: 0x808080,
        side: THREE.DoubleSide,
        roughness: 0.0,
        metalness: 0.0,
        visible: false,
      })
    }
  },
  onRemove: function (editor) {
    this.helpers.forEach((o) => editor.removeObject(o, false))
  },
  setVisibility: function (shadowVisibility, facetVisibility, helperVisibility) {
    // allow passing null to leave unchanged

    if (facetVisibility !== null) {
      this.material.visible = facetVisibility
    }

    this.helpers.forEach(function (o) {
      if (o.name === 'FacetMeshShadowLayer') {
        if (shadowVisibility !== null) {
          o.visible = shadowVisibility
        }
      } else if (o.name === 'wallMesh') {
        if (facetVisibility !== null) {
          o.visible = facetVisibility
        }
      } else {
        if (helperVisibility !== null) {
          if (!o.ghostMode || !o.ghostMode()) {
            o.visible = helperVisibility
          }
        }
      }
    })
  },

  relativePosition: function (worldPosition) {
    return worldPosition.clone().sub(this.facet.position)
  },

  drawAzimuthLine: function () {
    var azimuthLineLength = 3
    var azimuthVector = this.facet.getAzimuthVector().multiplyScalar(azimuthLineLength)

    var centroid = new THREE.Vector3(0, 0, 0)
    var arrowStart = centroid.clone()
    var arrowEnd = centroid.clone().add(azimuthVector).projectOnPlane(this.facet.plane.normal)
    var direction = arrowEnd.clone().sub(arrowStart)
    var length = direction.length()
    var arrowHelper = new THREE.ArrowHelper(direction.normalize(), arrowStart, length, 0x00ff00)
    arrowHelper.visible = visibilityByType.interactiveElements
    arrowHelper.type = 'ArrowHelper'
    arrowHelper.userData.excludeFromExport = true
    arrowHelper.line.selectable = false
    arrowHelper.cone.selectable = false
    this.add(arrowHelper)
    return arrowHelper
  },

  drawNormalLine: function () {
    if (!this.facet.plane) {
      return
    }

    var normalLineLength = 3

    var centroid = new THREE.Vector3(0, 0, 0)
    var arrowStart = centroid.clone()
    var arrowEnd = centroid.clone().add(this.facet.plane.normal.clone().normalize().multiplyScalar(normalLineLength))
    var direction = arrowEnd.clone().sub(arrowStart)
    var length = direction.length()
    var arrowHelper = new THREE.ArrowHelper(direction.normalize(), arrowStart, length, 0x00ff00)
    arrowHelper.visible = visibilityByType.interactiveElements
    arrowHelper.type = 'ArrowHelper'
    arrowHelper.userData.excludeFromExport = true
    arrowHelper.line.selectable = false
    arrowHelper.cone.selectable = false
    this.add(arrowHelper)

    return arrowHelper
  },

  // handleMouse: function(isOver) {
  // Removed: mouseovers on grids
  // this.facet.moduleGrids().forEach(function(mg) {
  //   mg.handleMouse(isOver)
  // })
  // },

  handleMouseBehavior: ObjectBehaviors.handleMouseBehavior,
})

OsFacetMesh.rebuildFacetMeshesWithGroundTexture = function (loadedTexture) {
  editor.uiPause('render', 'OsFacetMesh.rebuildFacetMeshesWithGroundTexture')
  editor.uiPause('ui', 'OsFacetMesh.rebuildFacetMeshesWithGroundTexture')

  SceneHelper.rebuildFacetTexturesInProgress++
  editor.filter('type', 'OsFacet').forEach((facet) => {
    console.log('Refresh facets with loaded ground texture')
    facet.refreshMesh(editor)
  })
  SceneHelper.rebuildFacetTexturesInProgress--

  editor.uiResume('render', 'OsFacetMesh.rebuildFacetMeshesWithGroundTexture')
  editor.uiResume('ui', 'OsFacetMesh.rebuildFacetMeshesWithGroundTexture')

  editor.render()
}
