// Ensure the order of execution does not matter when loading multiple map type classes
// Subclasses of OsWMTS are injected dynamically so we do not register WMTS directly
// window.MAP_TYPES = Object.assign(window.MAP_TYPES || {}, {
//   Linz: {
//     mapType: 'Linz',
//     className: 'OsWMTS',
//     provider: null,
//     designMode: '2D',
//     designModePriority: 12,
//     library: 'none',
//     isTopDown: true,
//   },
// })

window.MAP_TYPES_CONSTRUCTORS = Object.assign(window.MAP_TYPES_CONSTRUCTORS || {}, {
  WMTS: function (mapType, oblique) {
    window.MAP_TYPES = Object.assign(window.MAP_TYPES || {}, {
      [mapType]: {
        mapType: mapType,
        className: 'OsWMTS',
        provider: null,
        designMode: '2D',
        designModePriority: 12,
        library: 'none',
        isTopDown: true,
        oblique: oblique || null,
        imageryType: function (mapData, scene) {
          return 'top'
        },
      },
    })
  },
})

var OsWMTS = {
  mapType: 'WMTS',
  isTopDown: true,
  minMetersPerPixel: function () {
    return 0.01
  },
  screenPositionToLatLon: function (x, y, screenScaleX, screenScaleY) {
    // var viewportSize = MapHelper.viewportSize()
    // var w = viewportSize[0]
    // var h = viewportSize[1]

    // var screenPositionRelativeToCenterScaled = [(x - w * 0.5) / screenScaleX, (y - h * 0.5) / screenScaleY]
    var pointInMapProjection = this.dom.getCoordinateFromPixel([x, y])
    var location4326 = ol.proj.transform(pointInMapProjection, 'EPSG:3857', 'EPSG:4326')
    return location4326
  },
  getCenterFromMapUnderCameraRay4326: getCenterFromMapUnderCameraRay4326Generic,
  toMapData: function () {
    var center4326
    try {
      center4326 = this.getCenterFromMapUnderCameraRay4326()
    } catch (e) {
      // This may fail for various reasons if other pieces of data are not initialized
      // But this should only happen in MyE when it is safe to just use existing mapData.center
      center4326 = this.mapData.center
    }

    return new MapData({
      mapType: this.mapData.mapType,
      center: center4326,
      zoomTarget: this.dom.getView().getZoom(),
      maxZoom: 20,
      sceneOrigin: this.mapData._sceneOrigin(),
      scale: this.mapData.scale,
      heading: null,
      oblique: this.mapData.oblique,
      pano: null,
    })
  },
  drawMap: function (mapData) {
    /*
    Static method! `this` is not a map instance
    */

    // Note that mapData.mapType will not be WMTS because we subclass this, e.g. Linz
    var domElementId = 'map' + mapData.mapType

    if (!document.getElementById('DesignerContainer').length) {
      var mapDomElement = document.createElement('div')
      mapDomElement.id = domElementId
      document.getElementById('DesignerContainer').appendChild(mapDomElement)

      // Since we are dynamically adding the DOM element it will not have been updated with CSS from the previous call
      // to refreshMapContainers, call immediately after adding dom element.
      MapHelper.refreshMapContainers(mapData.mapType)
    }

    var extras = {} // mapData.mapType === 'QldGlobe' ? { tileSize: [256, 256], wrapX: false, tilePixelRatio: 2 } : {}

    var map = new ol.Map({
      controls: [],
      target: domElementId,
      interactions: ol.interaction.defaults({
        altShiftDragRotate: false,
        onFocusOnly: false,
        doubleClickZoom: false,
        keyboard: false,
        mouseWheelZoom: false,
        shiftDragZoom: false,
        dragPan: true,
        pinchRotate: false,
        pinchZoom: false,
      }),
      layers: [
        new ol.layer.Tile({
          source: new ol.source.XYZ({
            //Disabling in case this breaks Basic Authentication
            //crossOrigin: 'anonymous',

            // Sample URL:
            // url: 'https://basemaps.linz.govt.nz/v1/tiles/aerial/3857/{z}/{x}/{y}.png?api=c01fee57b4zanjdyjcm476gh1q8', //d927d69f260d4a0d8704cb132af0a946

            url: mapData.oblique?.url,
            // tileLoadFunction: OsWMTS.tileLoadFunction, // Not required unless we want to use a proxy or custom headers
            maxZoom: mapData.oblique?.max_zoom || 20,
            ...extras,
          }),
        }),
      ],
      view: new ol.View({
        center: ol.proj.transform([mapData.center[0], mapData.center[1]], 'EPSG:4326', 'EPSG:3857'),
        zoom: mapData.oblique?.max_zoom || 20,
      }),
    })

    map.mapType = mapData.mapType

    MapHelper.manageMapIsAnimatingOpenLayers(map)

    return new MapInstance(mapData, makeMapProxy(new OpenLayers2DMapAdapter(map)))
  },
  // interactive: "not required",
  // setPrimary: "not required",
  updateSize: function () {
    this.dom.updateSize()
  },
  setView: async function (mapData, rebuildLayers, isGoogleObliqueDetection, latLonAtScreenCenterForMap4326) {
    if (!mapData.maxZoom) {
      mapData.maxZoom = mapData.oblique?.max_zoom || 20
    }

    this.dom.setView(
      new ol.View({
        center: ol.proj.transform(latLonAtScreenCenterForMap4326.toArray(), 'EPSG:4326', 'EPSG:3857'),
        zoom: mapData.zoomTarget,
      })
    )
    this.zoom = mapData.zoomTarget
  },
  // scaleDomElement: "not required",
  // matchScene:  "not required",

  ///////////// Non-Standard Instance Methods Below Here ////////////

  getZoomForResolution: function (targetMetersPerPixel, lat) {
    var roundUpToNextZoomLevel = function (tmpZoom) {
      // Why was this not implemented?
      // return tmpZoom

      if (Math.ceil(tmpZoom) - tmpZoom < 0.001) {
        return Math.ceil(tmpZoom)
      } else {
        return tmpZoom
      }
    }

    //https://stackoverflow.com/questions/9356724/google-map-api-zoom-range
    //var lat = 50.8076955
    var scaleForLat = Math.cos((lat * Math.PI) / 180)
    var max = 156543.03392804097 * scaleForLat
    var fudgeFactor = Math.log(2)

    var zoom = 0 + Math.log(max / targetMetersPerPixel) / fudgeFactor
    return roundUpToNextZoomLevel(zoom)
  },

  tileLoadFunction: function (tile, src) {
    //Can be bypassed during dev/testing by setting window.bypassProxy = true

    if (tileCache[src]) {
      tile.getImage().src = tileCache[src]
      window.studioDebug && console.log('tile cache hit: ' + src)
      return
    } else {
      window.studioDebug && console.log('tile cache miss: ' + src)
    }

    var xhr = new XMLHttpRequest()
    xhr.open('GET', src)
    xhr.responseType = 'arraybuffer'

    xhr.onload = function () {
      if (xhr.status == 200 || xhr.status == 304) {
        var arrayBufferView = new Uint8Array(this.response)
        var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
        var urlCreator = window.URL || window.webkitURL
        var imageUrl = urlCreator.createObjectURL(blob)
        tile.getImage().src = imageUrl
        tileCache[src] = imageUrl
      } else if (xhr.status === 404) {
        // Since 404 is not recoverable we load a transparent png image into this pixel
        // otherwise tile loading queue will fill up and no more tiles will be loaded
        tile.getImage().src = transparentPixelPngSrc
        tileCache[src] = transparentPixelPngSrc
      } else {
        console.warn('Error loading tile', xhr.status)
      }
    }
    xhr.send()
  },
  getLabel: function (mapData) {
    /* static method */
    return {
      full: mapData.mapType,
      major: mapData.mapType,
      minor: 'Top',
    }
  },
}
