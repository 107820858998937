function ViewInstance(data) {
  this.uuid = data.uuid ? data.uuid : Utils.generateUUID()
  this.cameraParams = data.cameraParams ? data.cameraParams : null
  this.viewBoxParams = data.viewBoxParams ? data.viewBoxParams : null
  this.mapData = new MapData(data.mapData)
  this.showTextures = Boolean(data.showTextures)
  this.show_customer = Boolean(data.show_customer)
  this.showGround = Boolean(data.showGround)
  this.style = data.style ? data.style : 'default'
  this.facetDisplayModeOverride = data.facetDisplayModeOverride ? data.facetDisplayModeOverride : 'default' // 'default' or 'alternative'
  if (typeof data.isAligned === 'boolean') {
    this.isAligned = data.isAligned
  }
  if (typeof data.allowTilt === 'boolean') {
    this.allowTilt = data.allowTilt
  } else if (
    this.mapData.type === 'None' ||
    this.mapData.type === 'Nearmap3D' ||
    this.mapData.type === 'Google3D' ||
    this.mapData.type === 'GetMapping3D' ||
    this.mapData.type === 'GetMappingPremium3D' ||
    this.mapData.type === 'Vexcel3D'
  ) {
    this.allowTilt = true
  }
}

ViewInstance.prototype = Object.assign({
  toObject: function () {
    return {
      uuid: this.uuid,
      cameraParams: this.cameraParams,
      viewBoxParams: this.viewBoxParams,
      mapData: this.mapData.toObject(),
      showTextures: this.showTextures,
      showGround: this.showGround,
      style: this.style,
      heading: this.heading,
      show_customer: this.show_customer,
      facetDisplayModeOverride: this.facetDisplayModeOverride,
      isAligned: this.isAligned,
      allowTilt: this.allowTilt,
    }
  },
})

/*
Requires listeners to be added in Designer.js
Cannot add in constructor here because editor does not yet exist
Perhaps we should create ViewHelper inside editor? Then we could just put them in the constructor.
*/
function ViewHelperClass() {}

ViewHelperClass.prototype = Object.assign({
  displayModeCurrent: null,
  views: [],
  _selectedViewUuid: null,

  clearViewsCommand: function (commandUUID) {
    if (!commandUUID) {
      commandUUID = Utils.generateCommandUUIDOrUseGlobal()
    }

    while (this.views.length > 0) {
      editor.execute(new RemoveViewCommand(this.views[0].uuid, this, commandUUID, false))
    }
  },

  clear: function () {
    this.storeViews([])
  },

  setSkew: function (bearing, magnitudeInDegrees) {
    //Add Guide model and set to camera center...
    window.SceneHelper.skewGuideShow()

    var cameraParams = window.ViewHelper.getCameraParams(editor.camera, editor)

    var orientationCorrection = {
      bearing: bearing,
      magnitudeInDegrees: magnitudeInDegrees,
    }

    window.editor.loadCamera(
      null,
      window.ViewHelper.createCameraParams(
        'top',
        orientationCorrection,
        new window.THREE.Vector3().fromArray(cameraParams.center)
      )
    )

    var isNew = true
    window.editor.signals.cameraChanged.dispatch(window.editor.camera, isNew)
    //window.editor.signals.escapePressed.dispatch()

    // var cameraParams = this.createCameraParams('top', {bearing:bearing, magnitudeInDegrees:magnitudeInDegrees})
    // var view = this.views[this.selectedView()]
    // view.cameraParams = cameraParams
    // this.saveView(this.selectedViewIndex(), null, null, MapHelper.activeMapInstance.toMapData(), window.editor.scene);
  },

  createCameraParams: function (orientation, orientationCorrectionData, center, metersPerPixel) {
    /*
        orientationCorrectionData format: {bearing:Degrees, magnitudeInDegrees:Degrees}
        Only used when direction is "top" or "T"
        When bearing is 0, positive magnitudeInDegrees will rotate towards the north
        */
    //@todo: I don't quite understand how to set camera up to be compatible with Trackball controls
    //hacked these into working

    var hr2 = Math.pow(2, 0.5) / 2 //root_two/2

    var position
    var up = new THREE.Vector3(0, 1, 0)

    switch (orientation) {
      case 'front':
        position = new THREE.Vector3(0, -100, 0)
        break
      case 'left':
        position = new THREE.Vector3(-100, 0, 0)
        break
      case 'right':
        position = new THREE.Vector3(100, 0, 0)
        break
      case 'N':
      case 'north':
        position = new THREE.Vector3(0, -100, 100)
        up = new THREE.Vector3(0, 1 - hr2, hr2)
        break
      case 'S':
      case 'south':
        position = new THREE.Vector3(0, 100, 100)
        up = new THREE.Vector3(0, hr2 - 1, hr2)
        break
      case 'E':
      case 'east':
        position = new THREE.Vector3(-100, 0, 100)
        up = up.applyAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 4) //down tilt
        up = up.applyAxisAngle(new THREE.Vector3(0, 0, 1), -Math.PI / 2) //azimuth
        break
      case 'W':
      case 'west':
        position = new THREE.Vector3(100, 0, 100)
        up = up.applyAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 4) //down tilt
        up = up.applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2) //azimuth
        break
      case 'top':
      case 'T':
      default:
        // Make y position slightly negative to ensure the up direction is calculated correctly, otherwise
        // it is unstable and the camera may jump erattically when it is first moved.
        position = new THREE.Vector3(0, -0.001, 100)

        if (orientationCorrectionData) {
          //mag negative fixes this axis
          var axis = new THREE.Vector3(0, 1, 0).applyAxisAngle(
            new THREE.Vector3(0, 0, 1),
            -1 * (orientationCorrectionData.bearing - 90) * THREE.Math.DEG2RAD
          )
          position.applyAxisAngle(axis, -1 * orientationCorrectionData.magnitudeInDegrees * THREE.Math.DEG2RAD)
        }
    }

    //If center supplied, make position relative to it
    if (center) {
      position.add(center)
    } else {
      center = new THREE.Vector3()
    }

    //Use basic objects, not THREE objects
    return {
      position: position.toArray(),
      center: center.toArray(),
      up: up.toArray(),
      metersPerPixel: typeof metersPerPixel !== 'undefined' ? metersPerPixel : null,
    }
  },

  matchesPreset: function (cameraParams, orientation, threshold) {
    // Check if camera direction matches
    var presetCameraParams = this.createCameraParams(orientation)
    var presetCameraEye = new THREE.Vector3()
      .fromArray(presetCameraParams.position)
      .sub(new THREE.Vector3().fromArray(presetCameraParams.center))
      .normalize()
    var cameraEye = new THREE.Vector3()
      .fromArray(cameraParams.position)
      .sub(new THREE.Vector3().fromArray(cameraParams.center))
      .normalize()

    return cameraEye.dot(presetCameraEye) > (threshold ? threshold : 0.99)
  },

  buildNearmapVerticalViewInstance: function (until, options) {
    var metersPerPixel = options && options.metersPerPixel ? options.metersPerPixel : editor.defaultMetersPerPixel

    var sceneOrigin

    if (options && options.sceneOrigin) {
      sceneOrigin = options.sceneOrigin
    } else if (options && options.center) {
      sceneOrigin = options.center
    } else {
      sceneOrigin = MapHelper.activeMapInstance.toMapData().sceneOrigin
    }

    var viewInstance = new ViewInstance({
      uuid: Utils.generateUUID(),
      cameraParams: this.createCameraParams('top', undefined, options.cameraCenter, metersPerPixel),
      mapData: new MapData({
        center: options.center ? options.center : sceneOrigin,
        sceneOrigin: sceneOrigin,
        mapType: 'Nearmap',
        maxZoom: options && options.maxZoom ? options.maxZoom : 21,
        zoomTarget: 20,
        oblique: {
          until: until,
        },
      }),
      showTextures: options && options.showTextures ? Boolean(options.showTextures) : false,
      show_customer: options && options.show_customer ? Boolean(options.show_customer) : false,
      showGround: options && options.showGround ? Boolean(options.showGround) : false,
      style: options && options.style ? options.style : 'default',
    })
    return viewInstance
  },

  buildGetMappingViewInstance: function (options) {
    const metersPerPixel = options?.metersPerPixel || window.editor.defaultMetersPerPixel

    return new window.ViewInstance({
      uuid: Utils.generateUUID(),
      cameraParams: this.createCameraParams('top', undefined, options.cameraCenter, metersPerPixel),
      mapData: new window.MapData({
        center: options.center ? options.center : options.sceneOrigin,
        sceneOrigin: options.sceneOrigin,
        mapType: 'GetMapping',
        maxZoom: options?.maxZoom || 21,
        zoomTarget: 20,
      }),
      showTextures: Boolean(options?.showTextures),
      show_customer: Boolean(options?.show_customer),
      showGround: Boolean(options?.showGround),
      style: options?.style || 'default',
    })
  },

  buildGetMappingPremiumViewInstance: function (options) {
    const metersPerPixel = options?.metersPerPixel || window.editor.defaultMetersPerPixel

    return new window.ViewInstance({
      uuid: Utils.generateUUID(),
      cameraParams: this.createCameraParams('top', undefined, options.cameraCenter, metersPerPixel),
      mapData: new window.MapData({
        center: options.center ? options.center : options.sceneOrigin,
        sceneOrigin: options.sceneOrigin,
        mapType: 'GetMappingPremium',
        maxZoom: options?.maxZoom || 21,
        zoomTarget: 20,
      }),
      showTextures: Boolean(options?.showTextures),
      show_customer: Boolean(options?.show_customer),
      showGround: Boolean(options?.showGround),
      style: options?.style || 'default',
    })
  },
  buildVexcelViewInstance: function (options) {
    const metersPerPixel = options?.metersPerPixel || window.editor.defaultMetersPerPixel

    return new window.ViewInstance({
      uuid: Utils.generateUUID(),
      cameraParams: this.createCameraParams('top', undefined, options.cameraCenter, metersPerPixel),
      mapData: new window.MapData({
        center: options.center ? options.center : options.sceneOrigin,
        sceneOrigin: options.sceneOrigin,
        mapType: 'Vexcel',
        maxZoom: options?.maxZoom || 21,
        zoomTarget: 20,
        oblique: options.oblique,
      }),
      showTextures: Boolean(options?.showTextures),
      show_customer: Boolean(options?.show_customer),
      showGround: Boolean(options?.showGround),
      style: options?.style || 'default',
    })
  },
  buildNearmapObliqueViewInstance: function (photoMeta, options) {
    var p = oslib3d.getPhotoProj(photoMeta)
    ol.proj.addProjection(p)

    ol.proj.addCoordinateTransforms(
      ol.proj.get('EPSG:4326'),
      p,
      function (worldLatLon) {
        return oslib3d.lngLatToXY(worldLatLon, photoMeta)
      },
      function (pixelCoord) {
        return oslib3d.xyToLngLat(pixelCoord, photoMeta)
      }
    )

    // var views = ViewHelper.views

    var center = options && options.center ? options.center : MapHelper.activeMapInstance.toMapData().center

    var sceneOrigin

    if (options && options.sceneOrigin) {
      sceneOrigin = options.sceneOrigin
    } else if (options && options.startLocation4326) {
      sceneOrigin = options.startLocation4326
    } else {
      sceneOrigin = MapHelper.activeMapInstance.toMapData().sceneOrigin
    }

    if (!sceneOrigin) {
      // Unable to set sceneOrigin using current viewport, so we must build from the scene data
      sceneOrigin = editor.scene.sceneOrigin4326
    }
    //use center instead of sceneOrigin which should be slightly more preice? (Assuming meta-data is correct)
    var cameraParams = MapHelper.NearmapOblique.cameraParamsAtLatLon(
      photoMeta,
      center,
      options ? options.cameraCenter : null
    )

    var viewInstance = new ViewInstance({
      uuid: Utils.generateUUID(),
      cameraParams: cameraParams,
      mapData: new MapData({
        mapType: 'NearmapSource',
        center: center, //photoMeta.gpsCoordinate.coordinates,
        zoomTarget: 0,
        sceneOrigin: sceneOrigin, //photoMeta.gpsCoordinate.coordinates,
        scale: 1,
        heading: Designer.classifyDirection(photoMeta.inclinationDeg, photoMeta.bearingDeg),
        oblique: photoMeta,
        pano: null,
      }),
      showTextures: options && options.showTextures ? Boolean(options.showTextures) : false,
      show_customer: options && options.show_customer ? Boolean(options.show_customer) : false,
      showGround: options && options.showGround ? Boolean(options.showGround) : false,
      style: options && options.style ? options.style : 'default',
    })
    return viewInstance
  },

  build3DViewInstance: function (mapType, options) {
    if (!options) {
      options = {}
    }
    var metersPerPixel = options && options['metersPerPixel'] ? options['metersPerPixel'] : editor.defaultMetersPerPixel
    var sceneOrigin

    try {
      sceneOrigin = options.sceneOrigin || MapHelper.activeMapInstance.toMapData().sceneOrigin
    } catch (err) {
      // Unable to set sceneOrigin using current viewport, so we must build from the scene data
      sceneOrigin = editor.scene.sceneOrigin4326
    }

    var cameraParams = this.createCameraParams(
      options?.orientation || 'north',
      undefined,
      options.cameraCenter || undefined,
      metersPerPixel
    )

    var viewInstance = new ViewInstance({
      uuid: Utils.generateUUID(),
      cameraParams: cameraParams,
      mapData: new MapData({
        mapType: mapType, //Nearmap3D or Google3D
        center: options.center ? options.center : sceneOrigin,
        zoomTarget: 0,
        sceneOrigin: sceneOrigin,
        scale: 1,
        heading: '3D',
        oblique: options.oblique || null,
        pano: null,
      }),
      showTextures: options && options.showTextures ? Boolean(options.showTextures) : false,
      show_customer: options && options.show_customer ? Boolean(options.show_customer) : false,
      showGround: options && options.showGround ? Boolean(options.showGround) : false,
      style: options && options.style ? options.style : 'default',
      allowTilt: true,
    })
    return viewInstance
  },

  createDefaultView: function (orientation, mapData, options) {
    if (typeof mapData === 'undefined') {
      mapData = new MapData({
        mapType: 'None',
        zoomTarget: 20,
      })
    }

    var metersPerPixel

    if (options?.metersPerPixel) {
      metersPerPixel = options.metersPerPixel
    } else if (mapData?.zoomTarget && mapData?.center) {
      // metersPerPixel = SceneHelper.metersPerPixelForZoom(mapData.zoomTarget)

      // It's probably better to use MapHelper.getZoomForResolution which adapts to different MapTypes
      // but it does not currently match perfectly for Google Maps, so using the other instead
      // metersPerPixel = MapHelper.getZoomForResolution(mapData.mapType, mapData.zoomTarget, mapData.center[1])
      metersPerPixel = MapHelper.getMetersPerPixelForZoomAndLat(mapData.zoomTarget, mapData.center[1])
    } else {
      // This will probably never be used??? How could we ever have mapData without zoomTarget and center?
      // Can we delete?
      metersPerPixel = editor.defaultMetersPerPixel
    }

    var viewInstance = new ViewInstance({
      uuid: options && options.uuid ? options.uuid : Utils.generateUUID(),
      cameraParams: this.createCameraParams(
        orientation,
        undefined,
        options ? options.cameraCenter : undefined,
        metersPerPixel
      ),
      mapData: mapData,
      showTextures: options && options.showTextures ? Boolean(options.showTextures) : false,
      show_customer: options && options.show_customer ? Boolean(options.show_customer) : false,
      showGround: options && options.showGround ? Boolean(options.showGround) : false,
      allowTilt: options && options.allowTilt ? Boolean(options.allowTilt) : false,
      style: options && options.style ? options.style : 'default',
    })

    return viewInstance
  },

  /**
   *
   * @param {ViewInstance} viewInstance
   * @param {boolean} select - whether the view must be selected immediately after successfull append
   */
  appendViewCommand: function (viewInstance, select) {
    window.editor.execute(
      new window.AddViewCommand(viewInstance, this, select, undefined, window.Utils.generateCommandUUIDOrUseGlobal())
    )
  },

  storeViewsWithCommands: function (viewInstances, selectedViewUuid) {
    viewInstances.forEach((viewInstance) => {
      var select = viewInstance.uuid === selectedViewUuid
      var insertAtIndex = undefined
      editor.execute(new AddViewCommand(viewInstance, ViewHelper, select, insertAtIndex))
    })
  },

  storeViews: function (_views, selectedViewUuid) {
    if (!_views) {
      _views = []
    }

    this.views = _views

    if (selectedViewUuid) {
      this.selectedViewUuid(selectedViewUuid)
    } else if (_views.length === 0) {
      //no views present, clear selected view
      this.selectedViewUuid(null)
    } else if (!this.selectedView()) {
      // views present but selected view is invalid
      // beware this may result in loading data for a view that should not actually be loaded
      this.selectedView(_views[0])
    }

    editor.signals.viewsChanged.dispatch(this.views, this.selectedViewUuid())
  },

  selectViewByUUID: function (viewUUID) {
    var viewData = window.ViewHelper.getViewByUuid(viewUUID)
    if (!viewData) return
    var dummyObject = new window.THREE.Object3D()
    dummyObject.type = 'ViewDummy'
    dummyObject.userData = viewData

    dummyObject.toolsActive = function () {
      return {
        translateXY: false,
        translateZ: false,
        scaleXY: false,
        scaleZ: false,
        scale: false, //legacy
        rotate: false,
        delete: false,
      }
    }

    dummyObject.canDelete = function () {
      if (window.Designer.controlMode && window.Designer.controlMode !== 'both') {
        return 'Unable to delete a view while in align-map mode '
      } else if (window.ViewHelper.views.length > 1) {
        return true
      } else {
        return 'Unable to delete the last remaining view'
      }
    }

    dummyObject.confirmBeforeDelete = function () {
      return 'Are you sure you want to delete this view?'
    }

    dummyObject.onDeleteOverride = function () {
      //Call this instead of deleting the dummy object
      //window.ViewHelper.deleteView(window.ViewHelper.getViewByUuid(viewUUID), window.editor)
      window.editor.execute(new window.RemoveViewCommand(viewUUID, window.ViewHelper))
    }

    window.editor.select(dummyObject)
  },

  getCameraParams: function (camera, _editor) {
    //Critical note: This has a dependency on editor.cameraCenter and editor.metersPerPixel()
    //We should try to refactor this to keep them completely inside the camera

    return {
      position: camera.position.toArray(),
      rotation: camera.rotation.toArray(),
      up: camera.up.toArray(),
      center: _editor.cameraCenter.toArray(),
      metersPerPixel: _editor.metersPerPixel(),
    }
  },

  selectedView: function (view) {
    if (typeof view === 'undefined') {
      return ViewHelper.getViewByUuid(this.selectedViewUuid())
    } else {
      return this.selectedViewUuid(view.uuid)
    }
  },

  viewHasGroundTexture: function () {
    // Note: We assume viewData.showGround will be removed so all None
    // views will have ground texture if the scene has ground texture
    var groundVariationData = editor.scene && editor.scene.groundVariationData()
    return groundVariationData && groundVariationData.url
  },

  facetDisplayMode: function () {
    var selectedView = this.selectedView()
    var facetDisplayModeOverride = selectedView ? selectedView.facetDisplayModeOverride : 'default'

    if (selectedView && selectedView.mapData && selectedView.mapData.mapType === 'None') {
      if (this.viewHasGroundTexture()) {
        return facetDisplayModeOverride !== 'alternative' ? 'ground' : 'library'
      } else {
        return facetDisplayModeOverride !== 'alternative' ? 'library' : 'edges'
      }
    } else if (selectedView && selectedView.mapData && MapData.mapTypeIs3D(selectedView.mapData.mapType)) {
      return facetDisplayModeOverride !== 'alternative' ? 'edges' : 'library'
    } else {
      return 'none'
    }
  },

  facetEdgesDisplayMode: function () {
    var displayMode = editor.displayMode
    var facetDisplayMode = this.facetDisplayMode()

    if (displayMode === 'interactive') {
      return 'default'
    } else if (facetDisplayMode === 'edges') {
      return 'default'
    } else if (displayMode === 'presentation' && facetDisplayMode === 'edges') {
      //planset style
      return 'planset'
    } else {
      return 'none'
    }
  },

  facetWallsDisplayMode: function () {
    var selectedView = this.selectedView()
    if (
      selectedView &&
      selectedView.mapData &&
      selectedView.mapData.mapType === 'None' &&
      this.viewHasGroundTexture()
    ) {
      return 'default'
    } else {
      return 'none'
    }
  },

  groundDisplayMode: function () {
    var facetDisplayMode = this.facetDisplayMode()
    var displayMode = editor.displayMode
    var selectedView = this.selectedView()

    if (selectedView && selectedView.mapData && selectedView.mapData.mapType === 'None') {
      if (this.viewHasGroundTexture()) {
        return 'ground'
      } else {
        if (displayMode === 'presentation' && facetDisplayMode === 'alternative') {
          return 'none'
        } else {
          return 'shadow'
        }
      }
    }

    return 'none'
  },

  selectedViewUuid: function (value) {
    if (typeof value === 'undefined') {
      return this._selectedViewUuid
    } else {
      this._selectedViewUuid = value
    }
  },

  selectedViewIndex: function (viewIndex) {
    if (typeof viewIndex === 'undefined') {
      return this.getIndexForView(this.selectedView())
    } else {
      this.selectedView(this.getViewByIndex(viewIndex))
    }
  },

  selectedViewMapType: function () {
    try {
      return this.selectedView().mapData.mapType
    } catch (e) {
      return null
    }
  },

  hasNoneView: function () {
    return ViewHelper.views.some(function (v) {
      return v.mapData && v.mapData.mapType === 'None'
    })
  },

  hasChangedCamera: function (viewIndex) {
    if (ViewHelper.views[viewIndex] && editor.camera) {
      const oldCameraParams = ViewHelper.views[viewIndex]?.cameraParams
      const newCameraParams = ViewHelper.getCameraParams(editor.camera, editor)
      function hasDifferent(attributeName) {
        try {
          if (oldCameraParams[attributeName] && newCameraParams[attributeName]) {
            return oldCameraParams[attributeName].some((val, index) => {
              if (typeof val === 'number') {
                return Math.round(val) !== Math.round(newCameraParams[attributeName][index])
              } else if (typeof val === 'string') {
                return val !== newCameraParams[attributeName][index]
              } else {
                return false
              }
            })
          }
        } catch (e) {
          console.log('error when detecting camera change: ', e)
          return true
        }
      }
      return hasDifferent('center') || hasDifferent('position') || hasDifferent('rotation')
    }
  },

  has3DView: function () {
    return ViewHelper.views.some(function (v) {
      return v.mapData && MapData.mapTypeIs3D(v.mapData.mapType)
    })
  },

  hasManualOr3DView: function () {
    return ViewHelper.views.some(function (v) {
      return v.mapData && (MapData.mapTypeIs3D(v.mapData.mapType) || MapData.mapTypeIsManual(v.mapData.mapType))
    })
  },

  hasDesign: function () {
    var objectsType = ['OsFacet', 'OsObstruction', 'OsClipper', 'OsTree', 'OsNode', 'OsEdge', 'OsModuleGrid']
    if (editor && objectsType.some((type) => editor.filter('type', type).length > 0)) {
      return true
    } else {
      return false
    }
  },

  enableDrawingTools: function () {
    return editor.selectedSystem && !editor.selectedSystem.isBasicMode() && !this.disable3DTools()
  },

  enableDrawOther3D: function () {
    // Can only add Obstructions and Trees if a) 3D/None view type exists or b) Roof facet exists
    var objectsType = ['OsFacet', 'OsObstruction', 'OsClipper', 'OsTree']
    if (editor && objectsType.some((type) => editor.filter('type', type).length > 0)) {
      return !this.disable3DTools()
    } else if (this.has3DView() || this.hasNoneView()) {
      return !this.disable3DTools()
    } else {
      return false
    }
  },

  disable3DTools: function () {
    return (
      (this.has3DView() && !MapData.mapTypeIs3D(this.selectedViewMapType())) || !Designer.showFacetsOnActiveMapType()
    )
  },

  getIndexForView: function (view) {
    if (!view) return -1
    return this.views.findIndex((v) => v?.uuid === view.uuid)
  },

  addView: function (viewInstance, select, index) {
    var indexToInsert = index === -1 || index === undefined ? ViewHelper.views.length : index
    ViewHelper.views.splice(indexToInsert, 0, viewInstance)
    ViewHelper.storeViews(ViewHelper.views)
    if (select) {
      ViewHelper.loadViewByIndex(indexToInsert, editor)
    }
  },

  updateView: function (viewInstance, viewIndex, select) {
    if (typeof viewIndex === 'undefined') {
      viewIndex = this.selectedViewIndex()
    }

    //Apply old properties to the new instance
    var oldViewInstanceData = ViewHelper.views[viewIndex]
    viewInstance.uuid = oldViewInstanceData.uuid
    viewInstance.showTextures = oldViewInstanceData.showTextures
    viewInstance.show_customer = oldViewInstanceData.show_customer
    viewInstance.showGround = oldViewInstanceData.showGround
    viewInstance.style = oldViewInstanceData.style
    viewInstance.facetDisplayModeOverride = oldViewInstanceData.facetDisplayModeOverride

    ViewHelper.views[viewIndex] = viewInstance
    ViewHelper.storeViews(ViewHelper.views)
    if (select || viewIndex === this.selectedViewIndex()) {
      //Refresh view even if select!=true if it is the current view, otherwise changes won't be allied until view change
      ViewHelper.loadViewByIndex(viewIndex, editor)
    }
  },

  // Note that index can be -1, which means to append a new view
  saveView: function (
    index,
    cameraOrCameraParams,
    mapData,
    scene,
    overrideViewUUId,
    duplicateView,
    dispatchViewsChangedSignal = true
  ) {
    // @param camera: Can either be a ThreeJS camera object or cameraParams
    var cameraParams =
      cameraOrCameraParams && cameraOrCameraParams.type === 'OrthographicCamera'
        ? this.getCameraParams(cameraOrCameraParams, editor)
        : cameraOrCameraParams

    if (window.logger) window.logger('ViewHelper.saveView', arguments)

    if (!window.editor.interactive() && window.Designer.controlMode !== 'map') {
      var msg = 'DANGER: calling ViewHelper.saveView() when editor.interactive should be false AND controlMode !== map'
      if (window.TESTING) {
        console.log(msg)
      } else if (window.editor.scene?.autoDesignGeoJson && SceneHelper.drawModulesInProgress) {
        // Do not throw error because we should allow commands to run in read-only mode if they are drawing modules
        // based on a supplied auto-design. We should review which conditions we use and we may change this system in future
        // but for now this must not throw an error.
        console.log(msg)
      } else {
        throw Error(msg)
      }
    }

    // Abort if calling with mapData undefined but supplied index != ViewHelper.selectedViewIndex()
    // This indicates that an animation was triggered by an old view
    // which is now finishing it's animation
    // Requires index to be supplied to allow the check to occur
    if (typeof index !== 'undefined' && typeof mapData === 'undefined' && index !== ViewHelper.selectedViewIndex()) {
      console.warn('Abort saveView(): we are saving over the wrong view!')
      return
    }

    //Set index:-1 to add a new view at the end
    if (typeof index === 'undefined') {
      index = ViewHelper.selectedViewIndex()
    }

    if (typeof mapData === 'undefined') {
      mapData = MapHelper.activeMapInstance.toMapData()
    }

    if (typeof cameraParams === 'undefined') {
      cameraParams = this.getCameraParams(editor.camera, editor)
    }

    if (typeof scene === 'undefined') {
      scene = editor.scene
    }

    // Values can be detected from three sources:
    //  a) If saving an existing view, use current value from the view
    //  b) If duplicating a view, use current value from the view
    //  c) If current view is found, use a default value
    var selectedViewIndex = ViewHelper.selectedViewIndex()

    var data = {
      uuid:
        this.views.length && this.views[index] && this.views[index].uuid
          ? this.views[index].uuid
          : overrideViewUUId || Utils.generateUUID(),
      cameraParams: cameraParams,
      mapData: mapData,
      showTextures:
        this.views.length && this.views[selectedViewIndex] ? this.views[selectedViewIndex].showTextures : false,
      show_customer:
        this.views.length && this.views[selectedViewIndex] ? this.views[selectedViewIndex].show_customer : false,
      showGround: this.views.length && this.views[selectedViewIndex] ? this.views[selectedViewIndex].showGround : false,
      style: this.views.length && this.views[selectedViewIndex] ? this.views[selectedViewIndex].style : 'default',
      facetDisplayModeOverride:
        this.views.length && this.views[selectedViewIndex]
          ? this.views[selectedViewIndex].facetDisplayModeOverride
          : 'default',
      isAligned: this.views.length && Boolean(this.views[index]) ? this.views[index].isAligned : undefined,
      allowTilt:
        this.views.length && Boolean(this.views[index])
          ? this.views[index].allowTilt
          : mapData.mapType === 'None' || mapData.mapType === 'Nearmap3D',
    }

    if (this.views[selectedViewIndex]?.viewBoxParams) {
      data.viewBoxParams = this.views[selectedViewIndex].viewBoxParams
    }

    if (duplicateView) {
      data.isAligned = duplicateView.isAligned
      data.allowTilt = duplicateView.allowTilt
    }

    if (index === -1 || index > this.views.length - 1) {
      this.views.push(new ViewInstance(data))
      index = this.views.length - 1
    } else {
      this.views[index] = new ViewInstance(data)
    }

    //Change to new selectedViewIndex key if different to previous key
    //(i.e. when duplicating a view)
    if (index !== this.selectedViewIndex()) {
      this.selectedViewIndex(index)
    }

    //We only call viewsChanged here so they can be displayed by other views e.g. To update a list of views
    if (dispatchViewsChangedSignal !== false) {
      editor.signals.viewsChanged.dispatch(this.views, this.selectedViewUuid())
    }
    return this.selectedViewUuid()
  },

  deleteView: function (view, editor, force, overrideAutoSelectNewView) {
    if (typeof view === 'undefined' || view === null) {
      view = this.selectedView()
    }

    var viewIndexToDelete = this.getIndexForView(view)

    var _this = this
    if (this.views.length > 1 || force === true) {
      var deletedView = this.views.splice(viewIndexToDelete, 1)[0]
      if (overrideAutoSelectNewView !== false) {
        this.loadViewByIndex(Math.max(viewIndexToDelete - 1, 0), editor).then(function () {
          editor.signals.viewsChanged.dispatch(_this.views, _this.selectedViewUuid())
        })
      }

      if (this.views.length === 1 && !this.views[0].show_customer) {
        // make the last remaining view visible to customer (if not already),
        // otherwise, proposal will be empty
        editor.execute(new window.SetViewValueCommand(this.views[0], 'show_customer', true, this))
      }

      return deletedView
    } else {
      Designer.showNotification('Unable the delete the last remaining view', 'danger')
      return false
    }
  },

  duplicateView: function (view, overrideViewUUId) {
    if (typeof view === 'undefined') {
      view = this.selectedView()
    }
    //var overrideIsAligned = view.isAligned
    return this.saveView(-1, editor.camera, view.mapData, editor.scene, overrideViewUUId, view)
  },

  moveView: function (view, direction) {
    if (typeof view === 'undefined') {
      view = this.selectedView()
    }

    var currentIndex = this.getIndexForView(view)
    var newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1

    // index of current
    Utils.arrayMove(this.views, currentIndex, newIndex)

    this.loadView(view, editor)
  },

  getCustomerViews: function () {
    return this.views.filter((view) => view.show_customer)
  },

  getViewByIndex: function (index, _views) {
    if (!_views) {
      _views = this.views
    }
    return this.views[index]
  },

  selectViewByIndex: function (index) {
    if (index + 1 > this.views.length) return
    const selectedViewUuid = this.getViewByIndex(index).uuid
    window.editor.execute(new window.SetViewCommand(selectedViewUuid, window.ViewHelper))
  },

  getViewByUuid: function (uuid, _views) {
    if (!_views) {
      _views = this.views
    }
    for (var i = 0; i < _views.length; i++) {
      if (_views[i].uuid === uuid) {
        return _views[i]
      }
    }
    return null
  },

  loadViewByUuid: function (uuid, editor) {
    return this.loadView(this.getViewByUuid(uuid), editor)
  },

  loadViewByIndex: function (viewIndex, editor) {
    return this.loadView(this.getViewByIndex(viewIndex), editor)
  },

  loadViewRefreshCamera: function (cameraParams, mapType) {
    /*
    Supply mapType because new mapType will normally not yet be loaded
    */
    //// Load Camera ////

    var refreshCamera = function () {
      try {
        var notTopDown = mapType ? !MapHelper.isTopDown(mapType) : false
        var _cameraControllerTriggerDispatchEvent = false
        editor.loadCamera(null, cameraParams, notTopDown, _cameraControllerTriggerDispatchEvent)
        editor.camera.updateMatrixWorld()
      } catch (error) {
        console.warn('Error in MapHelper.setActiveMap()...refreshCamera(). Ignorning', error)
      }
      return false
    }

    try {
      refreshCamera()

      // var _this = this

      //// Signals ////

      // Do not dispatch viewsChanged signal until view is loaded, call this in loadView after map is ready
      // Otherwise we get nasty behavior like views overwriting each other
      // editor.signals.cameraChanged.dispatch(editor.camera)
      // editor.signals.viewsChanged.dispatch(_this.views, _this.selectedViewUuid())

      var fixTextures = function () {
        if (editor.disableTextureRefreshHack !== true) {
          window.studioDebug &&
            console.warn(
              "Hack to fix textures updating, requires extra call to render(). Don't know why but this needs to be deferred until next frame using setTimeout with minimal delay of 1ms"
            )
          setTimeout(function () {
            if (editor && editor.viewport) {
              editor.render()
            }
          }, 1)
        }
      }

      fixTextures()
    } catch (error) {
      console.warn(error)
    }
  },

  loadView: async function (newView, editor) {
    if (!newView) {
      console.warn('Tried to select a non-existent view')
      return
    }

    // If we are in special control mode (i.e. map or scene)
    // revert to "both" before changing the view
    // nothing will happen if already correct
    // in OS Lite, we allow switching between views in any control mode
    // since OS Lite only ever shows a single view and we just destroy the current then create a new view
    // when switching to another map type
    if (
      window.Designer.controlMode &&
      window.Designer.controlMode !== 'both' &&
      window.editor?.designMode !== 'studioLite'
    ) {
      Designer.showNotification('Warning: Unable to change view when AlignMap mode is active.', 'danger')
      return
    }

    MapHelper.registerMapType(newView.mapData)

    var viewUuidBeforeLoadViewCalled = this.selectedViewUuid()

    //// Update selectedViewUuid  ////

    var _this = this

    //// Load Map ////

    if (!window.MapHelper) {
      window.MapHelper = {
        setActiveMap: async function () {
          return
        },
      }
    }

    //Flag layer to be cleared because map is changing
    //@TODO: Only mark to be cleared if there is more than one map instance which uses this Map control
    if (MapHelper.activeMapInstance && MapHelper.activeMapInstance.dom) {
      MapHelper.activeMapInstance.dom.rebuildLayers = true
    }

    // Update style immediately for new selected view
    // Note this actually updates the 3D scene (but no persistent changes)
    // Visibility of edges is set by editor.setMode() so we only need to refresh the line
    // to update the texture if necessary
    try {
      editor.filter('type', 'OsEdge').forEach(function (o) {
        o.refreshLine()
      })
    } catch (error) {
      console.warn(error)
    }

    // Should we reset ViewHelper.selectedViewUuid() after we finished setActiveMap?
    // Or should it happen before???
    this.selectedViewUuid(newView.uuid)

    // Beware, doing this before map is ready can result in overwriting mapData between views!
    // We now ensure that loadViewRefreshCamera prevents CameraController from dispatching a "changed" signal
    // before the camera & mapData are all loaded.
    if (newView.viewBoxParams) {
      this.loadViewRefreshCamera(newView.viewBoxParams.cameraParams, newView.mapData.mapType)
      // update the camera settings of the new view to match the loaded viewbox camera parameters
      newView.cameraParams = this.getCameraParams(window.editor.camera, window.editor)
      window.ViewBoxHelper.show(newView.viewBoxParams, true)
    } else {
      this.loadViewRefreshCamera(newView.cameraParams, newView.mapData.mapType)
      window.ViewBoxHelper.clear()
    }

    await MapHelper.setActiveMap(newView.mapData).catch((e) => {
      console.warn('Error in loadView(): setActiveMap failed', e)
    })

    // Should we reset ViewHelper.selectedViewIndex() after we finished setActiveMap?
    // Or should it happen before???
    //_this.selectedViewUuid(newView.uuid)

    window.studioDebug && console.log('MapHelper.setActiveMap is ready.. proceed with loadViewByIndex...')

    var refreshDisplayMode = function () {
      try {
        //Only update if different to editor
        if (editor.displayMode && editor.displayMode !== this.displayModeCurrent) {
          //This should not make changes but it currently does :-(
          //We should not make any changes because we're changing from read-only presentation mode
          var discardChanges = true
          editor.setMode(editor.displayMode, discardChanges)
          this.displayModeCurrent = editor.displayMode
        }

        return true
      } catch (error) {
        console.warn('Error in MapHelper.setActiveMap()...refreshDisplayMode(). Ignorning', error)
      }
      return false
    }

    //// Display Mode - why update here??? ////
    refreshDisplayMode()

    //// Control Mode - why update here??? ////

    //Ordering problem: Setting controlMode could cause errors both before/after loadCamera.
    //Instead, call it after and disable responding to camera update so we can manually handle it when ready
    var newControlMode = this.displayModeCurrent === 'presentation' ? 'none' : 'best'

    window.studioDebug &&
      console.log('WARNING!!! Removed editor.displayMode update Designer.changeControl(newControlMode)')
    if (editor.toolbar) editor.toolbar.controlMode(newControlMode)
    // if(Designer.changeControl) Designer.changeControl(newControlMode)

    // Old sequence for refreshing camera after map has loaded
    // Now moved to before map is loaded because camera loads synchronously and should not require any
    // inputs/dependencies on the map, and we need camera to be loaded early so we can determine difference
    // between MapWindow center and ViewFinder center
    // this.loadViewRefreshCamera(newView.cameraParams, newView.mapData.mapType)

    editor.signals.cameraChanged.dispatch(editor.camera)
    editor.signals.viewsChanged.dispatch(_this.views, _this.selectedViewUuid())

    //Special hack for Nearmap Source views
    //Force the view to re-update itself to fix an incorrect offset which sometimes occurs, probably due to race
    if (newView.mapData.mapType === 'NearmapSource') {
      //Prevent recursion: Only trigger hack if this was the original change from one view to another
      if (newView.uuid !== viewUuidBeforeLoadViewCalled) {
        setTimeout(function () {
          //Only trigger if the selected view is still loaded (to prevent this from interfering if user already changed the view)
          if (newView.uuid === ViewHelper.selectedViewUuid()) {
            window.studioDebug && console.warn('Hack: Reload view to fix NearmapSource misalignment')
            ViewHelper.loadView(ViewHelper.selectedView(), window.editor)
          }
        }, 100)
      }
    }

    // Call render with forceClear=true to clear render artifacts etc because otherwise render will not repaint
    // if no visible objects on screen
    setTimeout(function () {
      if (editor && editor.viewport) {
        window.studioDebug && console.log('Hack: Rerender with forceClear=true from loadView > setActiveMap > ready')
        editor.render(true)
      }
    }, 1)
  },

  addGeotiffView: function (d) {
    /*
        Geotiff result is in the form:
        var d = {
        	epsg: image.getGeoKeys()['GeographicTypeGeoKey'],
        	size: [image.getWidth(),image.getHeight()],
        	origin: image.getOrigin(),
        	resolution: image.getResolution(),
        	boundingBox: image.getBoundingBox(),
        	dataURL: canvas.toDataURL('image/png'),
        }
        */

    var views = ViewHelper.views

    var points = [
      ol.proj.transform([d.boundingBox[0], d.boundingBox[1]], 'EPSG:' + d.epsg, 'EPSG:3857'),
      ol.proj.transform([d.boundingBox[0], d.boundingBox[3]], 'EPSG:' + d.epsg, 'EPSG:3857'),
      ol.proj.transform([d.boundingBox[2], d.boundingBox[1]], 'EPSG:' + d.epsg, 'EPSG:3857'),
      ol.proj.transform([d.boundingBox[2], d.boundingBox[3]], 'EPSG:' + d.epsg, 'EPSG:3857'),
    ]

    views.push(
      ViewHelper.createDefaultView(
        'T',
        new MapData({
          mapType: 'Image',
          center: editor.scene.sceneOrigin4326,
          zoomTarget: 1,
          sceneOrigin: null,
          scale: 1,
          heading: 'T',
          oblique: {
            //need to generalize EPSG code passing, this will work for "urn:x-ogc:def:crs:EPSG:28992" => 28992
            epsg: '3857',
            url: d.dataURL,
            imageId: 'abc',
            extent: Utils.extentFromPoints(points),
            direction: 'N',
            size: d.size,
            year: null,
            timestamp: null,
            zoomLevels: 1,
            heading: 0,
            roll: 0,
            pitch: 0,
            tileSize: d.size,
          },
        }),
        {}
      )
    )

    ViewHelper.storeViews(views)
    ViewHelper.loadViewByIndex(views.length - 1, editor)
  },

  addImageView: function (img, dataURL) {
    /*
        Image is not georeferenced! Create a default extent using 3857

        Based on scene origin so it appears on screen not 1000s of pixels off screen

        Image result is in the form (same as GeoTiff but with georeference fields empty:

        var d = {
        	epsg: undefined,
        	size: [image.getWidth(),image.getHeight()],
        	origin: undefined,
        	resolution: image.getResolution(),
        	boundingBox: undefined,
        	dataURL: canvas.toDataURL('image/png'),
        }

        dataURL can be supplied for convenience if already available
        This can avoid re-generating it from the supplied img

        */

    if (typeof dataURL === 'undefined') {
      dataURL = img.toDataURL()
    }

    var metersPerImagePixel = editor.defaultMetersPerPixel

    var centerLatLonAs3857 = ol.proj.transform(editor.scene.sceneOrigin4326, 'EPSG:4326', 'EPSG:3857')

    //Centered around latlon projected to 3857
    var extent = [
      centerLatLonAs3857[0] + (-img.width / 2) * metersPerImagePixel,
      centerLatLonAs3857[1] + (-img.height / 2) * metersPerImagePixel,
      centerLatLonAs3857[0] + (img.width / 2) * metersPerImagePixel,
      centerLatLonAs3857[1] + (img.height / 2) * metersPerImagePixel,
    ]

    var imageSize = [img.width, img.height]

    var views = ViewHelper.views

    views.push(
      ViewHelper.createDefaultView(
        'T',
        new MapData({
          mapType: 'Image',
          center: editor.scene.sceneOrigin4326,
          zoomTarget: 1,
          zoomDelta: 0,
          sceneOrigin: editor.scene.sceneOrigin4326,
          scale: 1,
          heading: 'T',
          oblique: {
            //need to generalize EPSG code passing, this will work for "urn:x-ogc:def:crs:EPSG:28992" => 28992
            epsg: '3857',
            url: dataURL,
            imageId: 'abc',
            extent: extent,
            direction: 'N',
            size: imageSize,
            year: null,
            timestamp: null,
            zoomLevels: 1,
            heading: 0,
            roll: 0,
            pitch: 0,
            tileSize: imageSize,
          },
        }),
        { metersPerPixel: metersPerImagePixel, show_customer: true }
      )
    )

    ViewHelper.storeViews(views)
    ViewHelper.loadViewByIndex(views.length - 1, editor)
  },

  toggleShowTexturesForView: function (viewObject, value) {
    var newValue = typeof value !== 'undefined' ? value : !viewObject.showTextures
    editor.execute(new window.SetViewValueCommand(viewObject, 'showTextures', newValue, ViewHelper))
  },

  toggleShowCustomerForView: function (viewObject, value) {
    var newValue = typeof value !== 'undefined' ? value : !viewObject.show_customer
    editor.execute(new window.SetViewValueCommand(viewObject, 'show_customer', newValue, ViewHelper))
  },

  toggleShowGroundForView: function (viewObject, value) {
    var newValue = typeof value !== 'undefined' ? value : !viewObject.showGround
    editor.execute(new window.SetViewValueCommand(viewObject, 'showGround', newValue, ViewHelper))
  },

  toggleStyleForView: function (viewObject, value) {
    // Deprecated, remove once planset is removed completely
    var newValue = typeof value !== 'undefined' ? value : viewObject.style !== 'planset' ? 'planset' : 'default'
    //editor.signals.viewsChanged.dispatch(this.views, this.selectedViewUuid())
    editor.execute(new window.SetViewValueCommand(viewObject, 'style', newValue, ViewHelper))
  },

  toggleFacetDisplayModeForView: function (viewObject, value) {
    // Deprecated, remove once planset is removed completely
    var newValue =
      typeof value !== 'undefined'
        ? value
        : viewObject.facetDisplayModeOverride !== 'alternative'
        ? 'alternative'
        : 'default'
    //editor.signals.viewsChanged.dispatch(this.views, this.selectedViewUuid())
    editor.execute(new window.SetViewValueCommand(viewObject, 'facetDisplayModeOverride', newValue, ViewHelper))

    //If changed rebuild all facet textures
    SceneHelper.applyFacetDisplayMode()
  },

  matchCameraToOtherView: function (viewIndex) {
    var view = ViewHelper.getViewByIndex(viewIndex)

    if (!view || !view.mapData || !view.mapData.oblique) {
      console.error('Invalid view to copy parameters')
      return
    }

    editor.controllers.Camera.reset(
      new THREE.Vector3().fromArray(view.cameraParams.center),
      new THREE.Vector3().fromArray(view.cameraParams.position),
      new THREE.Vector3().fromArray(view.cameraParams.up)
    )
    editor.metersPerPixel(view.cameraParams.metersPerPixel)
    editor.refreshCamera()
    editor.render()
  },

  animateToDirection: async function (direction, onFinish) {
    var center

    var screenPoint = editor.viewport.viewFinderCenterToScreenFraction()

    // If terrain is loading we will await it, otherwise we can continue and we will just use the ground instead
    // Beware: If terrain takes longer than 20 seconds to load this could throw an error but if it takes that long
    // then we do indeed have an error that we should handle properly.
    await editor.getTerrainWhenReady()

    var center = editor.viewport.getIntersectionWithTerrainOrGround(screenPoint)

    // Flip the look direction from the direction that was clicked
    var lookDirection = {
      west: 'east',
      east: 'west',
      south: 'north',
      north: 'south',
      top: 'top',
    }[direction]

    var newCameraParams = this.createCameraParams(lookDirection, null, center)
    newCameraParams.metersPerPixel = this.selectedView().cameraParams.metersPerPixel

    this.animateCameraToParams(newCameraParams, null, onFinish)
    // This has been broken because animations seem to be ok, but has this broken something
    // we have overlooked?
    // window.editor.signals.viewsChanged.dispatch(window.ViewHelper.views, window.ViewHelper.selectedView().uuid)
  },

  animateCameraToParams: function (cameraParams, duration, onFinish) {
    if (!duration) {
      duration = 500
    }
    var view = ViewHelper.selectedView()

    var spherical = new SphericalZup()

    //Swap Y and Z coordinates, we use Z as up
    var eyeStart = new THREE.Vector3()
      .fromArray(view.cameraParams.position)
      .sub(new THREE.Vector3().fromArray(view.cameraParams.center))
    spherical.setFromVector3(eyeStart)
    var sphericalStart = { phi: spherical.phi, theta: spherical.theta }

    var eyeEnd = new THREE.Vector3()
      .fromArray(cameraParams.position)
      .sub(new THREE.Vector3().fromArray(cameraParams.center))
    spherical.setFromVector3(eyeEnd)
    var sphericalEnd = { phi: spherical.phi, theta: spherical.theta }

    // End orientation corrections

    // 1. If target is top-down then force theta to Math.PI (North-is-up)
    if (sphericalEnd.phi < 0.001) {
      sphericalEnd.theta = Math.PI
    }

    // 2. Find the shortest path to the target
    var thetaDifferences = {
      equal: Math.abs(sphericalEnd.theta - sphericalStart.theta),
      plus: Math.abs(sphericalEnd.theta - sphericalStart.theta + Math.PI * 2),
      minus: Math.abs(sphericalEnd.theta - sphericalStart.theta - Math.PI * 2),
    }

    if (thetaDifferences.plus < thetaDifferences.equal) {
      sphericalEnd.theta = sphericalEnd.theta + Math.PI * 2
    } else if (thetaDifferences.minus < thetaDifferences.equal) {
      sphericalEnd.theta = sphericalEnd.theta - Math.PI * 2
    }

    var startParams = {
      center: view.cameraParams.center.slice(),
      position: view.cameraParams.position.slice(),
      up: view.cameraParams.up.slice(),
      metersPerPixel: view.cameraParams.metersPerPixel,
    }

    createjs.Tween.get({})
      .to({}, duration)
      .call(function handleComplete() {
        // We only use setTimeout because the library incorrectly calls handleComplete BEFORE the final change event
        setTimeout(function () {
          // There is no need to call render() here because this fires after the final change event which calls render

          // Keep old version in case we encounter any issues with the new approach we can easily roll back
          // // Call during animation which will avoid an extra render
          // var dispatchViewsChangedSignal = false
          // ViewHelper.saveView(
          //   undefined,
          //   undefined,
          //   undefined,
          //   undefined,
          //   undefined,
          //   undefined,
          //   dispatchViewsChangedSignal
          // )

          // New version
          // Save updated camera params only once after completion, not during animation
          // This code is copied from CameraController, it could be nice to reuse this instead of duplicating.
          // Old method updated the view but did not use a command which breaks undo/redo.
          editor.signals.cameraAnimationFinished.dispatch()

          /*
          Only finish animation after all renders are complete, which will avoid redundant renders because they know
          the rendering will be handled by the animation.
          */
          editor.signals.animationStop.dispatch('tween', 'animateCameraToParams')

          if (onFinish) {
            onFinish()
          }
        }, 1)
      })
      .on('change', function () {
        var fraction = createjs.Ease.cubicInOut(this.position / this.duration)

        // Tween method: lerp center but tween spherical params thera & phi and use them to update position on each frame

        var phi = sphericalStart.phi * (1 - fraction) + sphericalEnd.phi * fraction
        var theta = sphericalStart.theta * (1 - fraction) + sphericalEnd.theta * fraction

        spherical.phi = phi
        spherical.theta = theta
        spherical.makeSafe()
        var vectorFromSpherical = new THREE.Vector3().setFromSpherical(spherical)

        //Swap Y and Z coordinates, we use Z as up
        var vector = new THREE.Vector3(vectorFromSpherical.x, vectorFromSpherical.z, vectorFromSpherical.y)

        var center = new THREE.Vector3()
          .fromArray(startParams.center)
          .lerp(new THREE.Vector3().fromArray(cameraParams.center), fraction)

        var position = center.clone().add(vector)

        // Utils.lookAtSafe(editor.camera, center)

        editor.controllers.Camera.reset(
          center,
          position,
          new THREE.Vector3().fromArray(startParams.up).lerp(new THREE.Vector3().fromArray(cameraParams.up), fraction),

          // prevent calling render now, do it after other updates have been made
          false
        )
        editor.metersPerPixel((1 - fraction) * startParams.metersPerPixel + fraction * cameraParams.metersPerPixel)

        editor.refreshCamera()

        editor.viewWidget.refreshForCamera(editor.camera.position, editor.metersPerPixel())

        // Do not call render on change, let Viewport handle animation rendering
        // No! >>> editor.render()
      })

    editor.signals.animationStart.dispatch('tween', 'animateCameraToParams')
  },
  animateToLonLat: function (lonlat, duration, onFinish) {
    var worldPositionForLonLat = new THREE.Vector3(
      ...SceneHelper.positionForLonLatUsingSceneOrigin(lonlat, editor.scene.sceneOrigin4326),
      0
    )
    var distance = 100
    var cameraDirection = editor.camera.getWorldDirection(new THREE.Vector3())
    var cameraOffsetFromPosition = cameraDirection.clone().multiplyScalar(distance)
    var cameraPosition = new THREE.Vector3().subVectors(worldPositionForLonLat, cameraOffsetFromPosition)
    var cameraParams = ViewHelper.getCameraParams(editor.camera, editor)

    ViewHelper.animateCameraToParams(
      {
        center: worldPositionForLonLat.toArray(),
        position: cameraPosition.toArray(),
        up: editor.camera.up.toArray(),
        metersPerPixel: editor.metersPerPixel(),
      },
      duration,
      onFinish
    )
  },

  allowPreload3D: function () {
    return this.views.some((view) => view.show_customer && MapData.is3D(view.mapData))
  },

  clean3DUrls: function () {
    if (!this.has3DView()) {
      editor.scene.dsmUrl = undefined
      editor.scene.orthoUrl = undefined
      editor.scene.terrainPosition = undefined
    }
  },

  convertAll3DViewsToProvider: function (provider) {
    var oldMapType = provider === 'Google' ? 'Nearmap3D' : 'Google3D'
    var newMapType = provider === 'Google' ? 'Google3D' : 'Nearmap3D'
    var changed = false
    this.views.forEach((view) => {
      if (view.mapData && view.mapData.mapType && view.mapData.mapType === oldMapType) {
        // Temporary update just so we can create a new ViewInstance
        // The actual update must happen using a command
        view.mapData.mapType = newMapType
        editor.execute(new UpdateViewCommand(new ViewInstance(view), ViewHelper))

        changed = true
      }
    })
    if (changed) {
      editor.signals.viewsChanged.dispatch(this.views, this.selectedViewUuid())
    }
  },

  viewUuidsForCustomer: function (views) {
    if (!views) {
      views = this.views
    }
    var viewUuids = views
      .filter(function (view) {
        return view.show_customer
      })
      .map(function (view) {
        return view.uuid
      })

    var selectedViewUuid = this.selectedViewUuid()
    if (!viewUuids.includes(selectedViewUuid)) {
      selectedViewUuid = viewUuids[0]
    }

    return { viewUuids, selectedViewUuid }
  },

  autoAddViewsOnCustomizeViews: async function (views) {
    if (typeof views === 'undefined') {
      views = ViewHelper.views
    }

    // If only a single 3D view is enabled then automatically add 5 more views (VNSEW)
    if (views.length === 1 && views[0].mapData.mapType === 'Google3D') {
      await loadMapsLibrary('Google')

      var mapTypeData = { map_type: 'GoogleTop' }
      ViewHelper.addView(
        SceneHelper.viewForMapTypeData(mapTypeData, {
          center: views[0].mapData.center,
          sceneOrigin: editor.scene.sceneOrigin4326,
          show_customer: false,
        })
      )

      // Disable automatic notifications because we want to show custom notifications for this special case
      var showNotifications = false

      Designer.showNotification('Detecting available imagery at this location...', 'info')

      var hasObliques = await OsGoogle.addGoogleObliquesAfterDetection(editor.scene.sceneOrigin4326, showNotifications)
      if (hasObliques) {
        Designer.showNotification('Vertical and oblique views added', 'info')
      } else {
        Designer.showNotification('Vertical view added', 'info')
      }

      return hasObliques
    }

    return false
  },

  renderPresetPerspective: function (preset) {
    // Reposition to center of bounding box and rescale to fill bounding box
    var boundingBox = new THREE.Box3().setFromObject(editor.scene, SceneHelper.objectIsPartOfDesign)
    var boundingSphereRadius = boundingBox.getBoundingSphere(new THREE.Sphere()).radius

    var viewportShortestAxisPixels = Math.min(editor.viewport.rect().width, editor.viewport.rect().height)

    var metersPerPixel = (boundingSphereRadius * 2) / viewportShortestAxisPixels

    // Add min/max meters per pixel limits
    var metersPerPixelMin = 0.04 // prevent zooming too close
    var metersPerPixelMax = 2.0 // prevent zooming out too far
    if (metersPerPixel < metersPerPixelMin) {
      metersPerPixel = metersPerPixelMin
    } else if (metersPerPixel > metersPerPixelMax) {
      metersPerPixel = metersPerPixelMax
    }

    var newCameraParams

    if (preset.type === 'direction') {
      var center = preset.targetObjectUuid
        ? editor.objectByUuid(preset.targetObjectUuid).position
        : boundingBox.getCenter()

      newCameraParams = window.ViewHelper.createCameraParams(preset.direction, null, center, metersPerPixel)
    }

    editor.loadCamera(null, newCameraParams)

    editor.signals.cameraChanged.dispatch(editor.camera, false)
    editor.viewport.getRenderer().clear()
    editor.render()
  },

  setViewBoxEditMode: function (editMode) {
    var selectedView = this.selectedView()
    if (editMode) {
      if (!selectedView.viewBoxParams) {
        let currentmpp = window.editor.metersPerPixel()
        // create a default viewbox for the currently selected view if it doesn't exist
        selectedView.viewBoxParams = {
          cameraParams: selectedView.cameraParams,
          size: [
            currentmpp * window.ViewBoxHelper.DEFAULT_VIEWBOX_SIZE_PX[0],
            currentmpp * window.ViewBoxHelper.DEFAULT_VIEWBOX_SIZE_PX[1],
            1,
          ],
        }
        window.ViewBoxHelper.show(selectedView.viewBoxParams)
      }
      this.loadViewRefreshCamera(selectedView.viewBoxParams.cameraParams, selectedView.mapData.mapType)
      // update the camera settings of the current view to match the loaded viewbox camera parameters
      selectedView.cameraParams = this.getCameraParams(window.editor.camera, window.editor)
      this._onViewBoxParamsChange = this._onViewBoxParamsChange.bind(this)
      window.MapHelper.matchScene(true)
      window.ViewBoxHelper.startEdit(this._onViewBoxParamsChange)
    } else {
      window.ViewBoxHelper.endEdit()
    }
  },

  deleteViewBox: function () {
    var selectedView = this.selectedView()
    if (selectedView.viewBoxParams) {
      delete selectedView.viewBoxParams
      window.ViewBoxHelper.clear()
      window.SnapshotHelper.showAndFadeOut()
      window.editor.signals.historyChanged.dispatch()
    }
  },

  _onViewBoxParamsChange: function (viewBoxParams) {
    this.selectedView().viewBoxParams = viewBoxParams
    window.editor.signals.historyChanged.dispatch()
  },

  toggleShade: function (value) {
    // apply value if supplied, otherwise toggle base on the current value
    const _terrain = window.editor.getTerrain()
    if (_terrain) {
      let useHeatmap = typeof value !== 'undefined' ? value : !window.editor.getTerrain().useHeatmap()
      _terrain.useHeatmap(useHeatmap)
      window.editor.signals.viewsChanged.dispatch()
    }
  },
})

var ViewHelper = new ViewHelperClass()
