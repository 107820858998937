/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var StringModuleAssignmentCommand = function (string, newModules, modulesToRemove) {
  Command.call(this)

  this.type = 'StringModuleAssignmentCommand'
  this.name = 'String Module Updatable Assignment Command'
  this.updatable = true
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.OBJECT_REFERENCES = ['string']

  this.modulesAlreadyAdded = []
  this.modulesAlreadyRemoved = []
  this.alterReference = {}

  if (arguments.length) {
    this.string = string
    this.newModules = newModules || []
    this.modulesToRemove = modulesToRemove || []
  }
}

StringModuleAssignmentCommand.prototype = {
  execute: function () {
    var isObjectChanged = false

    for (var j = 0; j < this.modulesToRemove.length; j++) {
      if (this.modulesAlreadyRemoved.includes(this.modulesToRemove[j])) continue
      this.string.removeModule(this.modulesToRemove[j])
      this.modulesAlreadyRemoved.push(this.modulesToRemove[j])
      isObjectChanged = true
    }

    for (var i = 0; i < this.newModules.length; i++) {
      if (this.modulesAlreadyAdded.includes(this.newModules[i])) continue
      var moduleWasAdded = this.string.addModule(this.newModules[i], this.alterReference)
      if (moduleWasAdded) {
        isObjectChanged = true
      } else {
        this.modulesAlreadyAdded.push(this.newModules[i])
      }
    }

    if (isObjectChanged) {
      this.string.refreshUserData()
      this.editor.signals.objectChanged.dispatch(this.string)
    }
  },

  undo: function () {
    this.editor.signals.sceneGraphChanged.active = false
    var isObjectChanged = false

    for (var i = 0; i < this.newModules.length; i++) {
      if (!this.modulesAlreadyAdded.includes(this.newModules[i])) {
        this.string.removeModule(this.newModules[i])
        isObjectChanged = true
      }
    }

    for (var j = 0; j < this.modulesAlreadyRemoved.length; j++) {
      this.string.addModule(this.modulesAlreadyRemoved[j])
      isObjectChanged = true
    }

    for (var moduleUUID in this.alterReference) {
      var module = this.editor.objectByUuid(moduleUUID)
      var string = this.editor.objectByUuid(this.alterReference[moduleUUID])
      string.addModule(module)
      this.editor.signals.objectChanged.dispatch(string)
    }

    this.modulesAlreadyAdded = []
    this.modulesAlreadyRemoved = []
    this.string.refreshUserData()
    if (isObjectChanged) {
      this.editor.signals.objectChanged.dispatch(this.string)
    }
    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.sceneGraphChanged.dispatch()
  },
  update: function (command) {
    if (command.newModules.length > 0) {
      this.newModules = this.newModules.concat(command.newModules.filter((m) => !this.newModules.includes(m)))
    }
    if (command.modulesToRemove.length > 0) {
      this.modulesToRemove = this.modulesToRemove.concat(
        command.modulesToRemove.filter((m) => !this.modulesToRemove.includes(m))
      )
    }
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    output.alterReference = Object.assign({}, this.alterReference)
    output.newModulesUuids = this.newModules.map((m) => m.uuid)
    output.modulesAlreadyAddedUuids = this.modulesAlreadyAdded.map((m) => m.uuid)
    output.modulesToRemoveUuids = this.modulesToRemove.map((m) => m.uuid)
    output.modulesAlreadyRemovedUuids = this.modulesAlreadyRemoved.map((m) => m.uuid)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
    this.newModules = json.newModulesUuids.map((uuid) => this.editor.objectByUuid(uuid))
    this.modulesAlreadyAdded = json.modulesAlreadyAddedUuids.map((uuid) => this.editor.objectByUuid(uuid))
    this.modulesToRemove = json.modulesToRemoveUuids.map((uuid) => this.editor.objectByUuid(uuid))
    this.modulesAlreadyRemoved = json.modulesAlreadyRemovedUuids.map((uuid) => this.editor.objectByUuid(uuid))
  },
}
