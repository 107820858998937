/**
 * @author adampryor
 */

var cacheGroundTextures = {}

var GROUND_IMAGERY_ZOOM_DEFAULT = 18

function OsGround(options) {
  THREE.Mesh.call(this)

  // Loading from saved ThreeJS scene
  if (options && options.userData) {
    this.userData = options.userData
    options = null
  }

  this.keysForUserData = ['size', 'groundType', 'receiveShadow', 'lon', 'lat', 'groundUrl']
  this.type = 'OsGround'
  this.name = 'OsGround'

  // this.lon = typeof lon !== 'undefined' ? lon : null
  // this.lat = typeof lat !== 'undefined' ? lat : null
  this.zoom = GROUND_IMAGERY_ZOOM_DEFAULT

  var optionKeyAndDefaults = {
    groundType: 'none',
    groundUrl: null,
    lon: null,
    lat: null,
    groundTexture: null,

    //Only receive shadows if roof textures are showing, othewise shadows show through facets
    receiveShadow: false,
    size: null,
  }

  for (var key in optionKeyAndDefaults) {
    this[key] = Utils.getFromOptionsOrDefault(options, key, optionKeyAndDefaults[key])
  }

  var metersFor640Pixels = {
    20: 640 * Utils.latAndZoomToMetersPerPixel(this.lat, 20),
    19: 640 * Utils.latAndZoomToMetersPerPixel(this.lat, 19),
    18: 640 * Utils.latAndZoomToMetersPerPixel(this.lat, 18),
    17: 640 * Utils.latAndZoomToMetersPerPixel(this.lat, 17),
    16: 640 * Utils.latAndZoomToMetersPerPixel(this.lat, 16),
  }

  //cannot specify size unless we override imagery pixels and/or zoom
  if (this.size && isNaN(this.size) && Boolean(this.size[0])) {
    // size already set ok
  } else {
    this.size = [metersFor640Pixels[this.zoom], metersFor640Pixels[this.zoom]]
  }

  this.geometry = new THREE.PlaneGeometry(this.size[0], this.size[1], 2, 2)
  this.selectable = true

  this.loadMaterial()

  //Refresh facets so drawing style updates
  editor.filter('type', 'OsFacet').forEach(function (f) {
    f.onChange(editor)
  })

  this.userData.excludeFromExport = true

  this.reset()
}

OsGround.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsGround,
  toolsActive: function () {
    return {
      translateXY: this.groundType === 'upload',
      translateZ: this.hasImagery(),
      translateX: false,
      rotate: this.groundType === 'upload',
      scaleXY: this.groundType === 'upload',
      scaleZ: false,
      scale: false, //legacy
      scaleLocks: this.groundType === 'upload' ? ['x', 'y'] : [], // two or three axis can be scaled proportionally (fixed ratio) using scaleLocks
      scaleInPlace: true, // when true, scaling does not shift the object's world position
    }
  },
  formatUrlForGsaEndpoint: function (proxyUrlRaw) {
    var projectId = window.WorkspaceHelper?.project?.id
    var urlRequestParts = JSON.parse(window.atob(proxyUrlRaw.split('?request=')[1]))
    urlRequestParts['project_id'] = projectId
    return proxyUrlRaw.split('?request=')[0] + '?request=' + window.btoa(JSON.stringify(urlRequestParts))
  },

  reset: function () {},
  groundTextureCacheKey: function () {
    return (
      this.lat +
      '_' +
      this.lon +
      '_' +
      this.size[0] +
      '_' +
      this.size[1] +
      '_' +
      this.groundType +
      '_' +
      (this.groundType !== 'upload' ? this.groundUrl : 'todo_use_hash')
    )
  },

  loadMaterial: function () {
    if (this.groundType && this.geometry && this.geometry.parameters.width !== this.size[0]) {
      this.geometry = new THREE.PlaneGeometry(this.size[0], this.size[1], 2, 2)
    }
    var textureCacheKey = this.groundTextureCacheKey()

    var groundVariationData = editor.scene.groundVariationData()

    if (this.groundType === 'upload') {
      // For uploaded imagery in 3D

      if (!cacheGroundTextures[textureCacheKey]) {
        //@todo: Handle cache clearing etc for ground texture
        //@TODO: Beware memory leak if loading too many textures
        console.log('Loading ground texture for key: ' + textureCacheKey)
        THREE.ImageUtils.crossOrigin = 'anonymous'

        cacheGroundTextures[textureCacheKey] = new THREE.TextureLoader().load(
          groundVariationData.url,
          OsFacetMesh.rebuildFacetMeshesWithGroundTexture
        )
      }

      this.groundTexture = cacheGroundTextures[textureCacheKey]
      this.material = new THREE.MeshStandardMaterial({
        map: this.groundTexture,
        side: THREE.DoubleSide,
        color: 0x808080,
        roughness: 1.0,
      })
      this.scale.x = this.scale.y = groundVariationData.scale ? groundVariationData.scale[0] : 1
      this.rotation.z = groundVariationData.rotation ? groundVariationData.rotation[2] : 0
      this.position.x = groundVariationData.offset ? groundVariationData.offset[0] : 0
      this.position.y = groundVariationData.offset ? groundVariationData.offset[1] : 0
      this.updateMatrixWorld()
    } else if (this.groundType === 'google') {
      // For ground imagery using Google Ground Imagery
      if (!cacheGroundTextures[textureCacheKey]) {
        //@todo: Handle cache clearing etc for ground texture
        //@TODO: Beware memory leak if loading too many textures
        console.log('Loading ground texture for key: ' + textureCacheKey)
        THREE.ImageUtils.crossOrigin = ''

        cacheGroundTextures[textureCacheKey] = new THREE.TextureLoader().load(
          this.groundUrl.replace('API_KEY', AccountHelper.getApiKey('google')),
          OsFacetMesh.rebuildFacetMeshesWithGroundTexture
        )
      }

      this.groundTexture = cacheGroundTextures[textureCacheKey]
      this.material = new THREE.MeshStandardMaterial({
        map: this.groundTexture,
        side: THREE.DoubleSide,
        color: 0x808080,
        roughness: 1.0,
      })
    } else if (this.groundType === 'nearmap') {
      // For ground imagery using Nearmap
      if (!cacheGroundTextures[textureCacheKey]) {
        //@todo: Handle cache clearing etc for ground texture
        //@TODO: Beware memory leak if loading too many textures
        console.log('Loading ground texture for key: ' + textureCacheKey)
        THREE.ImageUtils.crossOrigin = ''

        // @TODO: Beware that AccountHelper.getApiKey('nearmap') may not be set yet if the user loads directly
        // into studio. We should probably delay loading of studio until the account-level data has been loaded
        // into the Redux which makes this available.

        let url = this.groundUrl.replace('API_KEY', AccountHelper.getApiKey('nearmap'))

        cacheGroundTextures[textureCacheKey] = new THREE.TextureLoader().load(
          url,

          // not loaded into ground yet... how can we trigger
          OsFacetMesh.rebuildFacetMeshesWithGroundTexture
        )
      }

      this.groundTexture = cacheGroundTextures[textureCacheKey]
      this.material = new THREE.MeshStandardMaterial({
        map: this.groundTexture,
        side: THREE.DoubleSide,
        color: 0x808080,
        roughness: 1.0,
      })
    } else if (this.groundType === 'google_sunroof') {
      // For ground imagery using Google Sunroof
      if (!cacheGroundTextures[textureCacheKey]) {
        //@todo: Handle cache clearing etc for ground texture
        //@TODO: Beware memory leak if loading too many textures
        console.log('Loading ground texture for key: ' + textureCacheKey)
        THREE.ImageUtils.crossOrigin = ''

        // Note window.editor is only used here for convenience, if there is no window.editor specified then this
        // will still be safe, it is just an optimization
        if (this.groundUrl) {
          var groundUrlProcessedRaw =
            !(window.editor && window.editor.designNewlyCreated) &&
            !THREE.TerrainLoader.checkCache(SceneHelper.cleanUrlForTerrainEndpoint(this.groundUrl))
              ? SceneHelper.refreshUrl(this.groundUrl)
              : SceneHelper.cleanUrlForTerrainEndpoint(this.groundUrl)

          var groundUrlProcessed = SceneHelper.routeThroughImageReformatProxy(groundUrlProcessedRaw)

          cacheGroundTextures[textureCacheKey] = new THREE.TextureLoader().load(
            groundUrlProcessed,

            // not loaded into ground yet... how can we trigger
            OsFacetMesh.rebuildFacetMeshesWithGroundTexture
          )
        } else {
          //ground set but URL not supplied
        }
      }

      this.groundTexture = cacheGroundTextures[textureCacheKey]
      this.material = new THREE.MeshStandardMaterial({
        map: this.groundTexture,
        side: THREE.DoubleSide,
        color: 0x808080,
        roughness: 1.0,
      })
    } else if (this.groundType === 'google_solar_api') {
      // For ground imagery using Google Solar API
      if (!cacheGroundTextures[textureCacheKey]) {
        //@todo: Handle cache clearing etc for ground texture
        //@TODO: Beware memory leak if loading too many textures
        console.log('Loading ground texture for key: ' + textureCacheKey)
        THREE.ImageUtils.crossOrigin = ''

        // @TODO: Find a more reliable way to detect projectId
        let projectId = window.WorkspaceHelper?.project?.id
        if (!projectId) {
          console.warn('projectId could not be detected, ground texture url will be malformatted')
        }

        let url = this.formatUrlForGsaEndpoint(this.groundUrl)

        if (url) {
          cacheGroundTextures[textureCacheKey] = new THREE.TextureLoader().load(
            url,
            OsFacetMesh.rebuildFacetMeshesWithGroundTexture
          )
        } else {
          //ground set but URL not supplied
        }
      }

      this.groundTexture = cacheGroundTextures[textureCacheKey]
      this.material = new THREE.MeshStandardMaterial({
        map: this.groundTexture,
        side: THREE.DoubleSide,
        color: 0x808080,
        roughness: 1.0,
      })
    } else if (this.groundType === 'getmapping') {
      // For ground imagery using Nearmap
      if (!cacheGroundTextures[textureCacheKey]) {
        //@todo: Handle cache clearing etc for ground texture
        //@TODO: Beware memory leak if loading too many textures
        console.log('Loading ground texture for key: ' + textureCacheKey)
        THREE.ImageUtils.crossOrigin = ''

        // @TODO: Beware that AccountHelper.getApiKey('nearmap') may not be set yet if the user loads directly
        // into studio. We should probably delay loading of studio until the account-level data has been loaded
        // into the Redux which makes this available.

        // @TODO: Find a more reliable way to detect projectId
        let projectId = window.WorkspaceHelper?.project?.id
        if (!projectId) {
          console.warn('projectId could not be detected, ground texture url will be malformatted')
        }

        let url = MapHelper.downloadUrlToProxyUrl(this.groundUrl, projectId, 'getmapping')

        cacheGroundTextures[textureCacheKey] = new THREE.TextureLoader().load(
          url,

          // not loaded into ground yet... how can we trigger
          OsFacetMesh.rebuildFacetMeshesWithGroundTexture
        )
      }

      this.groundTexture = cacheGroundTextures[textureCacheKey]
      this.material = new THREE.MeshStandardMaterial({
        map: this.groundTexture,
        side: THREE.DoubleSide,
        color: 0x808080,
        roughness: 1.0,
      })

      // this.scale.x = this.scale.y = groundVariationData.scale ? groundVariationData.scale[0] : 1
      this.rotation.z = groundVariationData.rotation_degrees
        ? -1 * groundVariationData.rotation_degrees * THREE.Math.DEG2RAD
        : 0

      // @TODO: Should we calculate position based on sceneOrigin4326 and the groundVariationData.lon/lat?
      // this.position.x = groundVariationData.offset ? groundVariationData.offset[0] : 0
      // this.position.y = groundVariationData.offset ? groundVariationData.offset[1] : 0
    } else if (this.groundType === 'shadow') {
      //this.material = new THREE.MeshStandardMaterial( { color: 0xCCCCCC, side: THREE.DoubleSide } );
      this.material = new THREE.ShadowMaterial({ opacity: 0.25 })
    }
  },
  applyUserData: function () {
    var keysChanged = []

    this.keysForUserData.forEach(function (key) {
      if (this.userData.hasOwnProperty(key)) {
        if (this[key] !== this.userData[key]) {
          keysChanged.push(key)
        }
        this[key] = this.userData[key]
      }
    }, this)

    if (keysChanged.length > 0) {
      this.loadMaterial()
    }
  },
  refreshUserData: function () {
    this.keysForUserData.forEach(function (key) {
      this.userData[key] = this[key]
    }, this)
  },
  setReceiveShadows: function (value) {
    if (this.receiveShadow !== value) {
      this.receiveShadow = value
      this.material.needsUpdate = true
    }
  },
  setVisible: function (value) {
    if (this.material.visible !== value) {
      this.material.visible = value
      this.material.needsUpdate = true
    }
  },

  setOpacity: function (value) {
    if (value > 1.0 || value < 0) {
      // invalid opacity values
      return
    }
    if (!this.material.transparent) {
      this.material.transparent = true
    }
    this.material.opacity = value
    this.material.needsUpdate = true
    window.editor.render()
  },

  resetOpacity: function () {
    if (this.material.transparent) {
      this.material.transparent = false
    }
    this.material.opacity = 1.0
    this.material.needsUpdate = true
    window.editor.render()
  },

  onSelect: function (editor) {
    if (this.groundType === 'upload') {
      SceneHelper.showReferenceForCustomImagery()
      editor.lockSelection()
    }
  },

  onDeselect: function (editor) {
    if (this.groundType === 'upload') {
      this.resetOpacity()
      SceneHelper.hideReferenceForCustomImagery()
    }
  },

  onChange: function (editor) {
    var groundVariationData = editor.scene.groundVariationData()
    if (this.groundType === 'upload') {
      groundVariationData.scale = [this.scale.x, this.scale.y, 1]
      groundVariationData.offset = [this.position.x, this.position.y, 0]
      groundVariationData.rotation = [0, 0, this.rotation.z]
    }
    var force = true

    //redraw walls with new elevation
    //force update because change in ground elevation is not reflected in facet hash
    editor.filter('type', 'OsFacet').forEach(function (o) {
      o.onChange(editor, force)
    })

    editor.filter('type', 'OsTree').forEach(function (o) {
      o.onChange(editor)
    })

    editor.signals.showGridChanged.dispatch(!this.hasImagery())
  },

  hasImagery: function () {
    return ['upload', 'nearmap', 'google', 'google_sunroof', 'getmapping'].indexOf(this.groundType) !== -1
  },

  getContextMenuItems: function (position) {
    var _this = this
    var isSelected = editor.selected === this
    var items = []

    if (this.groundType === 'upload') {
      items.push({
        label: window.translate(isSelected ? 'Deselect Ground' : 'Select Ground'),
        useHTML: false,
        selected: false,
        targetObjUuid: _this.uuid,
        onClick: function () {
          if (editor) {
            if (isSelected) {
              editor.unlockSelection()
              editor.deselect()
            } else {
              editor.select(_this)
            }
          }
        },
      })
    }

    if (editor.selected && editor.selected.type === 'OsEdge') {
      ;[
        ['A-Frame 1 storey', 'aframe_1'],
        ['Hip 1 storey', 'hip_1'],
        ['Flat Roof 1 storey', 'flat_1'],
      ].forEach((settings) => {
        items.push({
          label: window.translate('Create ' + settings[0]),
          useHTML: false,
          selected: false,
          onClick: function () {
            if (editor) {
              editor.selected.extrudeToFacet(position, settings[1])
            }
          },
        })
      })
    }
    return items
  },
})
