/**
 * @author adampryor

 Notice: Annotations will not align with ThreeJS scene if window.devicePixelRatio changes during a
 while Studio is already loaded. This will not be fixed because this only ever happens during
 testing when device type is simulated and changed in a browser.
 */
var AnnotationController = function (editor, viewport) {
  this.name = 'Annotation'

  var container = viewport.container

  var annotationContainer = null
  var annotationsByUuid = {}

  this.active = false

  /*
  Allow quickly disabling refresh without adding/removing handles or triggering an extra refresh
  */
  this.refreshPaused = false

  var _this = this

  this.activate = function (refreshAnnotationImmediately) {
    this.active = true

    if (!annotationContainer) {
      try {
        annotationContainer = document.createElement('div')
        annotationContainer.setAttribute('id', 'annotationContainer')
        annotationContainer.style.cssText = 'pointer-events:none;position:absolute;width:100%;height:100%;z-index:3;'
        editor.DesignerRootDomElement.appendChild(annotationContainer)
      } catch (error) {
        console.warn('Error in AnnotationController.activate(): Ignore')
      }
    }

    editor.signals.objectSelected.add(this.handleObjectSelected)
    editor.signals.objectChanged.add(this.handleObjectSelected)
    editor.signals.objectAdded.add(this.handleObjectSelected)
    editor.signals.objectRemoved.add(this.handleObjectSelected)
    editor.signals.objectAnnotationChanged.add(this.handleObjectSelected)
    editor.signals.cameraChanged.add(this.handleObjectSelected)
    editor.signals.sceneLoaded.add(this.handleObjectSelected)
    editor.signals.controlModeChanged.add(this.handleObjectSelected)
    editor.signals.displayModeChanged.add(this.handleObjectSelected)
    editor.signals.sceneGraphChanged.add(this.handleObjectSelected)
    editor.signals.transformModeChanged.add(this.handleObjectSelected)
    editor.signals.designGuidesVisibilityChanged.add(this.handleObjectSelected)
    editor.signals.editorCleared.add(this.removeAll)

    if (refreshAnnotationImmediately) {
      this.handleObjectSelected()
    }

    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.deactivate = function () {
    this.active = false

    editor.signals.objectSelected.remove(this.handleObjectSelected)
    editor.signals.objectChanged.remove(this.handleObjectSelected)
    editor.signals.objectAdded.remove(this.handleObjectSelected)
    editor.signals.objectRemoved.remove(this.handleObjectSelected)
    editor.signals.objectAnnotationChanged.remove(this.handleObjectSelected)
    editor.signals.cameraChanged.remove(this.handleObjectSelected)
    editor.signals.sceneLoaded.remove(this.handleObjectSelected)
    editor.signals.controlModeChanged.remove(this.handleObjectSelected)
    editor.signals.displayModeChanged.remove(this.handleObjectSelected)
    editor.signals.sceneGraphChanged.remove(this.handleObjectSelected)
    editor.signals.transformModeChanged.remove(this.handleObjectSelected)
    editor.signals.designGuidesVisibilityChanged.remove(this.handleObjectSelected)
    editor.signals.editorCleared.remove(this.removeAll)

    this.removeAll()

    if (annotationContainer) {
      annotationContainer.innerHTML = ''

      if (editor.DesignerRootDomElement.contains(annotationContainer)) {
        editor.DesignerRootDomElement.removeChild(annotationContainer)
      } else {
        console.log('Not removing annotationContainer, not a child of DesignerRootDomElement')
      }
    }

    annotationContainer = null
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.handleObjectSelected = function (objectFromSignal, extraHoverObject) {
    if (_this.refreshPaused === true) {
      return
    }

    // Only respond to specified object types to avoid firing for helpers, etc.
    // OsSystem is included because it represents de-selection
    var OBJECT_TYPES = ['OsEdge', 'OsFacet', 'OsNode', 'OsModuleGrid', 'OsAnnotation', 'OsSystem']

    if (objectFromSignal) {
      if (OBJECT_TYPES.indexOf(objectFromSignal.type) === -1) {
        return
      } else if (objectFromSignal.visible === false || objectFromSignal._ghostMode === true) {
        return
      }
    }

    var object = editor.selected
    var uuidsRequiringAnnotations = []

    // Angle annotation for all nodes with 2 edges
    // Where either the node is selected
    // or it belongs to a facet which is selected
    // or it's at the other end of an edge connecting to a selected node

    editor.filter('type', 'OsNode').filter((n) => {
      n.osAngles.forEach((a) => {
        a.parent.remove(a)
      })
      n.osAngles = []
    })

    if (editor.metersPerPixel() < 0.08) {
      editor
        .filter('type', 'OsNode')
        .filter((n) => {
          return (
            n.edges.length >= 2 &&
            (editor.selected === n ||
              (editor.selected && editor.selected.type === 'OsEdge' && editor.selected.nodes.indexOf(n) !== -1) ||
              (editor.selected && editor.selected.type === 'OsFacet' && editor.selected.vertices.indexOf(n) !== -1) ||
              (editor.selected &&
                editor.selected.type === 'OsNode' &&
                editor.selected.edges.some((e) => e.nodes.indexOf(n) !== -1)))
          )
        })
        .forEach((n) => {
          // We modify the uuid to combine UUIDS for node + each edge combination (sorted alphabetically)
          // This allows us to uniquely identify each node+pair of edges.
          n.getEdgePairsByBearingExcludingReflexAngles().forEach(([e0, e1]) => {
            // Create angle for this pair of edges and register with the node
            try {
              var osAngle = new OsAngle({ edge0: e0, edge1: e1 })
              n.add(osAngle)
              n.osAngles.push(osAngle)
              uuidsRequiringAnnotations.push(osAngle.uuid)
            } catch (e) {
              console.warn(e)
            }
          })
        })
    }

    if (extraHoverObject && extraHoverObject.uuid) {
      if (extraHoverObject.type === 'OsAnnotation') {
        var annotationLength =
          (editor.selectedSystem && editor.selectedSystem.annotations().filter((a) => !a['label']).length) || 0
        extraHoverObject.number = annotationLength + 1
      }
      uuidsRequiringAnnotations.push(extraHoverObject.uuid)
    }

    if (object && object.type === 'OsEdge') {
      uuidsRequiringAnnotations.push(object.uuid)

      //for both nodes on the edge, add all it's edges
      object.nodes.forEach(function (node) {
        if (!node) {
          console.warn('Warning: edge contains a node which is not set', object)
          return
        }
        node.getEdges().forEach(function (edge) {
          if (uuidsRequiringAnnotations.indexOf(edge.uuid) == -1) {
            uuidsRequiringAnnotations.push(edge.uuid)
          }
        })
      })
    } else if (object && object.type === 'OsFacet') {
      object.getEdges().forEach(function (o) {
        uuidsRequiringAnnotations.push(o.uuid)
      })
    } else if (object && object.type === 'OsNode') {
      object.getEdges().forEach(function (o) {
        uuidsRequiringAnnotations.push(o.uuid)
      })
    } else if (object && object.type === 'OsModuleGrid') {
      if (window.Designer._shadingVisibility && editor.metersPerPixel() < 0.06) {
        //hide annotation when it zoomed out a long way
        var modules = object.getModules()

        var worldToScreenOffsetFromCenter = editor.viewport.worldToScreenOffsetFromCenter
        if (modules.length > 100) {
          // show up to 100 number annotations closest to the center of the viewport
          modules.sort((a, b) => {
            return (
              a.distanceFromScreenCenterInPixels(worldToScreenOffsetFromCenter) -
              b.distanceFromScreenCenterInPixels(worldToScreenOffsetFromCenter)
            )
          })
          modules = modules.slice(0, 100)
        }
        modules.forEach(function (o) {
          uuidsRequiringAnnotations.push(o.uuid)
        })
      }
    }

    // this.filter('type', 'OsAnnotation').forEach(function (o) {
    //   o.visible = vis.interactiveElements
    //   // @TODO: Need to control whether we are in "pro" mode or "customer" mode
    //   window.showAnnotationsWithColors = true
    //   window.restrictAnnotationsToCustomer = true
    //   if (window.restrictAnnotationsToCustomer === true || typeof o.override_show_customer === 'boolean') {
    //     o.visible = o.override_show_customer
    //   }
    // })

    var annotationHashesAlreadyHadNumber = {}

    editor.selectedSystem &&
      editor.selectedSystem
        .annotations()
        .sort((a, b) => {
          if (a.ephemeral && !b.ephemeral) return -1
          if (!a.ephemeral && b.ephemeral) return 1
          return OsAnnotation.getCompareString(a).localeCompare(OsAnnotation.getCompareString(b))
        })
        .filter((o) => {
          return (
            window.annotationDisplayMode !== 'none' &&
            (window.editor.displayMode === 'interactive' ||
              window.annotationDisplayMode === 'pro' ||
              Boolean(o.override_show_customer))
          )
        })
        .forEach(function (o, index) {
          uuidsRequiringAnnotations.push(o.uuid)
          var hash = o.label + '||' + o.description
          if (!o.label) {
            var number = Object.keys(annotationHashesAlreadyHadNumber).length + 1
            o.number = number
            annotationHashesAlreadyHadNumber[number] = number
          } else if (annotationHashesAlreadyHadNumber[hash]) {
            o.number = annotationHashesAlreadyHadNumber[hash]
          } else {
            var number = Object.keys(annotationHashesAlreadyHadNumber).length + 1
            o.number = number
            annotationHashesAlreadyHadNumber[hash] = number
          }
        })

    Object.keys(annotationsByUuid).forEach(function (uuid) {
      if (uuidsRequiringAnnotations.indexOf(uuid) == -1) {
        //annotation does not have a matching object in the scene, delete it
        _this.remove(uuid)
      }
    }, _this)

    try {
      uuidsRequiringAnnotations.forEach(function (uuid) {
        _this.sync(uuid)
      }, _this)
    } catch (e) {
      console.warn(e)
    }
  }

  this.sync = function (uuid) {
    var object = editor.objectByUuid(uuid)

    if (object && object.getAnnotation) {
      var annotationData = object.getAnnotation()

      // Invalid annotations (e.g. edges with no nodes placed) can be skipped
      // When they become valid they will get added automatically.

      if (annotationData) {
        _this.set(object.uuid, annotationData.content, viewport.worldToScreen(annotationData.position), object)
      }
    }
  }

  this.removeAll = function () {
    Object.keys(annotationsByUuid).forEach(function (uuid) {
      // Clear annotation cache too so we do not revive stale annotations when we re-active later
      var o = editor.objectByUuid(uuid)

      // Beware this object may not exist by the time this is called (e.g. when called async)
      if (o?.clearAnnotationCache) {
        o.clearAnnotationCache()
      }
      _this.remove(uuid)
    }, _this)
  }

  this.remove = function (uuid) {
    if (annotationsByUuid[uuid]) {
      if (annotationContainer && annotationsByUuid[uuid].id) {
        if (annotationsByUuid[uuid].id.startsWith('clickable_annotation_')) {
          editor.DesignerRootDomElement.removeChild(annotationsByUuid[uuid])
        } else {
          annotationContainer.removeChild(annotationsByUuid[uuid])
        }
      } else {
        console.log('Warning calling remove() but annotationContainer not present')
      }
      delete annotationsByUuid[uuid]
    }
  }

  this.set = function (uuid, content, screenPosition, object) {
    if (!annotationContainer) {
      //annotationContainer not available, probably inactive?
      return
    }

    var annotation

    if (!annotationsByUuid.hasOwnProperty(uuid)) {
      annotationsByUuid[uuid] = document.createElement('div')
      annotation = annotationsByUuid[uuid]

      if (object.type == 'OsAnnotation') {
        annotation.setAttribute('id', 'clickable_annotation_' + uuid)
        editor.DesignerRootDomElement.appendChild(annotation)
      } else {
        annotation.setAttribute('id', 'annotation_' + uuid)
        annotationContainer.appendChild(annotation)
      }
    } else {
      annotation = annotationsByUuid[uuid]
    }

    annotation.innerHTML = content

    var rect = viewport.rect()
    var left = screenPosition.x
    var top = screenPosition.y

    if (object.type == 'OsAnnotation') {
      var extra = ''

      if (
        window.editor.viewport.transformControls.transformMode &&
        editor.selected &&
        (editor.selected.type === 'OsAnnotation' || Boolean(window.editor.viewport.transformControls.axis))
      ) {
        extra = 'pointer-events:none'
      }

      annotation.style.cssText = annotation.style.cssText =
        'text-align:center;position:absolute;margin-left:-12px;margin-top:-12px;left:' +
        left +
        'px;top:' +
        top +
        'px;' +
        extra
    } else {
      annotation.style.cssText =
        'text-align:center;position:absolute;text-shadow: 1px 1px 0px #CCC;width:60px;height:60px;margin-left:-30px;margin-top:-30px;line-height:60px;left:' +
        left +
        'px;top:' +
        top +
        'px'
    }

    // debugging outlines
    // annotation.style.cssText += 'border:1px solid pink; '
  }
}
