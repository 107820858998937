var gf = new jsts.geom.GeometryFactory()

// var SCENE_HELPER_DEBUG = false

var sunShadowMapSize = 4096
var MAX_RAYS_TO_SHOW = 50

// When this is large, ensure the near and far planes are not too large. e.g. Near=100000-100 and Far=100000+100
// to avoid precision issues
var SUN_DISTANCE = 1000
var SUN_CAMERA_DEPTH = 100

// Panel groups with very steep slopes are unstable and orientation often cannot be recovered after
// slope gets too steep, because it devolves into a line of points where the only significant deviation is in
// the z axis. Cap the steepest slope we will apply through auto-detection to make it easier to recover
var SLOPE_MAX_FOR_AUTO_ORIENTATION = 60

// Thin points with a 30cm grid
var EAGLEVIEW_THIN_POINTS_CELL_SIZE_METERS = 0.3

var EAGLEVIEW_EXISTING_PANEL_GROUP_ALIGNMENT_MESSAGE =
  'Existing panel groups can become hidden or misaligned after loading an EagleView report. Drag panel groups to align them with the EagleView model and automatically update the orientation and elevation. You can locate hidden panel groups in the "Panels" tab.'

var ELEVATE_PANEL_GROUP_ABOVE_FACET = 0.1

function getStudioDetail() {
  if (window.studioDetail) {
    return window.studioDetail
  } else {
    return 'high'
  }
}

function faceHeight(face, vertices) {
  var max = Math.max(vertices[face.a].z, vertices[face.b].z, vertices[face.c].z)
  var min = Math.min(vertices[face.a].z, vertices[face.b].z, vertices[face.c].z)
  return max - min
}

function faceArea(face, vertices) {
  var triangle = new THREE.Triangle(vertices[face.a], vertices[face.b], vertices[face.c])
  return triangle.getArea()
}

function minecraftify(data, w, h) {
  ////////////////////////////
  // MINECRAFT-IFY
  //

  var edgeIndexes = []
  var missingIndexes = []

  // Set all edge vertices to 0 so edges look nice like Minecraft, and we don't see inside meshes
  // @TODO: We can improve speed by starting/ending the loop to exclude first/last rows

  // Quickly and safely get any arbitrary Z value so missing values don't make our boundingBox invalid
  // This is important to avoid complication later with missing values and bounding boxes
  var arbitraryValidZ
  for (var i = 0, l = data.length; i < l; i++) {
    if (data[i] > -9000) {
      arbitraryValidZ = data[i]
      break
    }
  }

  for (var i = 0, l = data.length; i < l; i++) {
    let row = Math.floor(i / w)
    let col = i % w

    if (row === 0 || row === h - 1 || col === 0 || col >= w - 1) {
      data[i] = arbitraryValidZ
      edgeIndexes.push(i)
    } else if (data[i] > -9000) {
      // data[i] = data[i]
    } else {
      // set missing values (often set to -9999) to lowest point on mesh
      data[i] = arbitraryValidZ
      missingIndexes.push(i)
    }
  }

  // place lowest point in the model at z=0
  // calculate minZ before breaking it by modifying edges
  //geometry.computeBoundingBox()

  // geometryMinZ does not need to be exact, just a good, quick estimate
  var geometryMinZ = 1000

  for (var i = 0, l = data.length; i < l; i += 20) {
    if (data[i] < geometryMinZ) {
      geometryMinZ = data[i]
    }
  }

  edgeIndexes.forEach(function (i) {
    //2 meters below lowest point
    data[i] = geometryMinZ - 2
  })

  missingIndexes.forEach(function (i) {
    data[i] = geometryMinZ
  })

  // END MINECRAFT-IFY
  ////////////////////////////////
  return data
}

var SceneHelper = {
  isLoadingEagleViewReport: false,
  _facetDisplayModeRendered: null,
  showObliqueFirstIfAvailable: false,
  requestTimeout: 29500,
  rebuildFacetTexturesInProgress: 0,

  xyToUv: function (x, y) {
    // Should be over-written geometry/texture is loaded
    return null
  },

  skewGuideShow: function () {
    /*
    Add house model at camera center
    */
    var house
    var houseObjects = editor.filter('type', 'OsHouse')

    var center = new THREE.Vector3().fromArray(ViewHelper.selectedView().cameraParams.center)

    if (houseObjects.length > 0) {
      house = houseObjects[0]
      house.position.copy(center)
    } else {
      house = new OsHouse()
      house.position.copy(center)
      editor.addObject(house)
    }

    house.userData.excludeFromExport = true
  },

  skewGuideHide: function () {
    var houseObjects = editor.filter('type', 'OsHouse')
    houseObjects.forEach(function (houseObject) {
      editor.removeObject(houseObject)
    })
  },

  metersPerPixelForZoom: function (zoom) {
    return editor.defaultMetersPerPixel * Math.pow(2, 21 - zoom)
  },

  applyFacetDisplayMode: function () {
    this.facetDisplayModeRendered(ViewHelper.facetDisplayMode())
  },

  facetDisplayModeRendered: function (value) {
    if (typeof value !== 'undefined') {
      if (this._facetDisplayModeRendered !== value) {
        this._facetDisplayModeRendered = value
        SceneHelper.rebuildFacetTextures()
      }
    }
    return this._facetDisplayModeRendered
  },

  blankScene: async function (
    editor,
    startLocation4326,
    country_iso2,
    views,
    bare,
    initData,
    roofTypeId,
    timezoneOffset,
    performanceCalculator,
    autoCreateViews,
    skipExtraViews,
    options
  ) {
    editor.designNewlyCreated = true

    if (MapHelper) MapHelper.clearMaxDepths()

    //For restarting an existing scene
    if (!startLocation4326) {
      startLocation4326 = editor.scene.sceneOrigin4326
    }

    if (!country_iso2 && editor.scene.country && editor.scene.country.iso2) {
      country_iso2 = editor.scene.country.iso2
    }

    if (!roofTypeId) {
      roofTypeId = editor.scene.roofTypeId()
    }

    //Ensure country is known before proceeding... may require an async call
    const country = await SceneHelper.loadCountryCode(startLocation4326, country_iso2)
    return SceneHelper.blankSceneWithCountry(
      editor,
      startLocation4326,
      views,
      country,
      bare,
      initData,
      roofTypeId,
      timezoneOffset,
      performanceCalculator,
      autoCreateViews,
      skipExtraViews,
      options?.overrideDefaultMapType,
      undefined,
      options?.skipAutoCreateSystem,
      options
    )
  },

  blankSceneMockSynchronous: function (
    editor,
    startLocation4326,
    views,
    bare,
    countryIso2,
    initData,
    roofTypeId,
    timezoneOffset,
    performanceCalculator
  ) {
    if (MapHelper) MapHelper.clearMaxDepths()
    if (!countryIso2) {
      countryIso2 = 'US'
    }

    //For restarting an existing scene
    if (!startLocation4326) {
      startLocation4326 = editor.scene.sceneOrigin4326
    }

    if (!roofTypeId) {
      roofTypeId = editor.scene.roofTypeId()
    }

    //Ensure country is known before proceeding... may require an async call
    SceneHelper.blankSceneWithCountry(
      editor,
      startLocation4326,
      views,
      { iso2: countryIso2 },
      bare,
      null,
      roofTypeId,
      timezoneOffset,
      performanceCalculator
    )
  },

  defaultViewParams: function (overrideDefaultMapType, options) {
    var imageryType

    if (overrideDefaultMapType) {
      imageryType = overrideDefaultMapType
    } else {
      imageryType = window.getDefaultMapType()

      if (MapHelper.mapTypesAvailableAsDefault().indexOf(imageryType) === -1) {
        imageryType = 'GoogleTop'
        console.warn('Default imagery type not available, use ' + imageryType + ' instead')
      }
    }

    return {
      cameraPreset: 'top',
      mapData: MapData.mapDataForTopDown(imageryType, options),
      showTextures: imageryType === 'None' ? true : false,
      showGround: imageryType === 'None' ? true : false,
      allowTilt: imageryType === 'None' ? true : false,
    }
  },

  viewForParams: function (viewParams) {
    var mapType = viewParams.mapData.mapType ? viewParams.mapData.mapType : 'GoogleTop'
    return ViewHelper.createDefaultView(viewParams.cameraPreset, viewParams.mapData, {
      label: viewParams.label && viewParams.label.length > 0 ? viewParams.label : viewParams.mapData.getLabel(),
      heading: viewParams.heading ? viewParams.heading : null,
      show_customer: viewParams.show_customer,
      showTextures: viewParams.showTextures,
      showGround: viewParams.showGround,
      allowTilt: viewParams.allowTilt,
      style: 'default',
      cameraCenter: viewParams.cameraCenter,
      metersPerPixel: viewParams.metersPerPixel,
    })
  },

  handleNoNearmapImagery: function () {
    window.Designer.showNotification(
      'Nearmap imagery was not found at this location. "Choose Imagery" to see if other imagery is available.',
      'danger'
    )
  },

  viewForMapTypeData: function (mapTypeData, options) {
    if (!options) {
      options = {}
    }

    if (
      mapTypeData &&
      (mapTypeData.map_type === 'Google3D' ||
        mapTypeData.map_type === 'Nearmap3D' ||
        mapTypeData.map_type === 'GetMapping3D' ||
        mapTypeData.map_type === 'GetMappingPremium3D' ||
        mapTypeData.map_type === 'Vexcel3D')
    ) {
      return window.ViewHelper.build3DViewInstance(mapTypeData.map_type, {
        oblique: mapTypeData.variation_data,
        cameraCenter: options.cameraCenter || editor.viewport.worldPositionAtViewFinderCenter(),
        center: options.center || options.sceneOrigin.slice(),
        sceneOrigin: options.sceneOrigin ? options.sceneOrigin : options.center.slice(),
        show_customer: options.hasOwnProperty('show_customer') ? options.show_customer : true,
        orientation:
          options.orientation || window.getStorage().getItem('studio.defaultCameraOrientationFor3D') || undefined,
      })
    } else if (
      mapTypeData &&
      (mapTypeData.map_type === 'None' ||
        mapTypeData.map_type === 'NoneWithGoogleTexture' ||
        mapTypeData.map_type === 'NoneWithNearmapTexture')
    ) {
      var zoom = options?.zoomTarget || 21
      var metersPerPixel = this.metersPerPixelForZoom(zoom)
      return new ViewInstance({
        uuid: Utils.generateUUID(),
        cameraParams:
          options?.cameraParams || ViewHelper.createCameraParams('top', undefined, undefined, metersPerPixel),
        mapData: new MapData({
          mapType: 'None',
          center: options.center,
          zoomTarget: zoom,
          sceneOrigin: options.sceneOrigin ? options.sceneOrigin : options.center.slice(),
          scale: 1,
          heading: '3D',
          oblique: null,
          pano: null,
        }),
        showTextures: true,
        show_customer: options.hasOwnProperty('show_customer') ? options.show_customer : true,
        showGround: true,
        style: 'default',
        allowTilt: true,
      })
    } else if (mapTypeData && mapTypeData.map_type === 'Nearmap') {
      var sceneOrigin
      if (options.sceneOrigin) {
        sceneOrigin = options.sceneOrigin
      } else if (MapHelper.activeMapInstance) {
        sceneOrigin = MapHelper.activeMapInstance.toMapData().sceneOrigin
      } else {
        sceneOrigin = options.center
      }

      return ViewHelper.buildNearmapVerticalViewInstance(mapTypeData.variation_data.capture_date, {
        maxZoom: 24,
        show_customer: true,
        center: options.center,
        sceneOrigin: sceneOrigin,
        cameraCenter: editor.viewport.worldPositionAtViewFinderCenter(),
        metersPerPixel: editor.metersPerPixel(),
      })
    } else if (mapTypeData?.map_type === 'GetMapping') {
      const sceneOrigin =
        options?.sceneOrigin || options?.center || window.MapHelper.activeMapInstance.toMapData().sceneOrigin

      return ViewHelper.buildGetMappingViewInstance({
        maxZoom: 21,
        show_customer: true,
        center: options.center,
        sceneOrigin: sceneOrigin,
        cameraCenter: editor.viewport.worldPositionAtViewFinderCenter(),
        metersPerPixel: editor.metersPerPixel(),
      })
    } else if (mapTypeData?.map_type === 'GetMappingPremium') {
      const sceneOrigin =
        options?.sceneOrigin || options?.center || window.MapHelper.activeMapInstance.toMapData().sceneOrigin

      return ViewHelper.buildGetMappingPremiumViewInstance({
        maxZoom: 20,
        show_customer: true,
        center: options.center,
        sceneOrigin: sceneOrigin,
        cameraCenter: editor.viewport.worldPositionAtViewFinderCenter(),
        metersPerPixel: editor.metersPerPixel(),
      })
    } else if (mapTypeData?.map_type === 'Vexcel') {
      const sceneOrigin =
        options?.sceneOrigin || options?.center || window.MapHelper.activeMapInstance.toMapData().sceneOrigin

      return ViewHelper.buildVexcelViewInstance({
        maxZoom: 20,
        show_customer: true,
        center: options.center,
        sceneOrigin: sceneOrigin,
        cameraCenter: editor.viewport.worldPositionAtViewFinderCenter(),
        metersPerPixel: editor.metersPerPixel(),
        oblique: mapTypeData.variation_data,
      })
    } else if (mapTypeData && mapTypeData.map_type === 'NearmapSource') {
      return ViewHelper.buildNearmapObliqueViewInstance(mapTypeData.variation_data, {
        show_customer: true,
        center: options.center,
        sceneOrigin: options.sceneOrigin ? options.sceneOrigin : null,
        cameraCenter: editor.viewport.worldPositionAtViewFinderCenter(),
        metersPerPixel: editor.metersPerPixel(),
      })
    } else {
      var viewParams = this.defaultViewParams(mapTypeData.map_type, {
        center: options.center,
        zoomTarget: options.zoomTarget,
        sceneOrigin: options.sceneOrigin ? options.sceneOrigin : null,
        variation_data: mapTypeData.variation_data,
      })
      viewParams.show_customer = options.hasOwnProperty('show_customer') ? options.show_customer : true
      if (mapTypeData.variation_data && mapTypeData.variation_data.hasOwnProperty('heading')) {
        viewParams.mapData.heading = mapTypeData.variation_data.heading
        viewParams.cameraPreset = { 0: 'N', 90: 'E', 180: 'S', 270: 'W' }[mapTypeData.variation_data.heading] || 'top'
      }
      viewParams.cameraCenter = editor.viewport.worldPositionAtViewFinderCenter()
      viewParams.metersPerPixel = editor.metersPerPixel()
      return this.viewForParams(viewParams)
    }
  },

  addOrChangeView: function (mapTypeData, mode) {
    var commandUUID = Utils.generateCommandUUIDOrUseGlobal()
    var options = {}

    try {
      var mapData = MapHelper.activeMapInstance.toMapData()
    } catch (err) {
      // Various problems could cause the above call to fail, so have a safe fallback
      console.error(err)
    }

    var viewInstance = this.viewForMapTypeData(mapTypeData, mapData)

    if (mode === 'add') {
      // add
      editor.execute(new AddViewCommand(viewInstance, ViewHelper, true, undefined, commandUUID))
    } else {
      // change
      editor.execute(new UpdateViewCommand(viewInstance, ViewHelper, commandUUID))
    }

    // If this modifies 3D terrain then also apply to scene groundVariationData too and refresh terrain
    if (
      mapTypeData.map_type === 'Google3D' ||
      mapTypeData.map_type === 'Nearmap3D' ||
      mapTypeData.map_type === 'Vexcel3D'
    ) {
      editor.execute(
        new SetObjectTypeCommand(editor.scene, 'groundVariationData', mapTypeData.variation_data, commandUUID)
      )

      window.SceneHelper.refreshTerrain(mapTypeData, undefined, commandUUID)
      window.editor.signals.terrainLoaded.addOnce(this.refloatModuleGridsToTerrain)
    }
  },

  refloatModuleGridsToTerrain: function () {
    const moduleGrids = editor.filter('type', 'OsModuleGrid')
    let repositionCommands = moduleGrids.map((mg) => {
      let newPosition = mg.position.clone()

      const moduleSamplePoints = window.SceneHelper.samplePointsFromModulesV2(mg, mg.getModules())
      const moduleSamplesToTerrainPoints = moduleSamplePoints.map((sample) => {
        try {
          return {
            sample,
            terrainPoint: window.SceneHelper.pointOnObj(sample),
          }
        } catch (e) {
          return null
        }
      })

      const elevationOffset = moduleSamplesToTerrainPoints.reduce((offset, sampleToTerrainPoint) => {
        if (sampleToTerrainPoint === null) {
          return offset
        }
        const moduleElevation = sampleToTerrainPoint.sample.z
        const terrainElevation = sampleToTerrainPoint.terrainPoint.z
        const elevationDiff = moduleElevation - terrainElevation
        return elevationDiff < offset ? elevationDiff : offset
      }, Number.POSITIVE_INFINITY)

      if (elevationOffset === Number.POSITIVE_INFINITY) {
        // was not able to compute a valid offset
        // can happen when the entire module grid is outside the terrain
        return null
      }

      newPosition.z += -elevationOffset
      newPosition.z += MODULE_GRID_OFFSET_FROM_TERRAIN
      return new window.SetPositionCommand(
        mg,
        newPosition,
        mg.position, // old position
        null, // auto-generate command uuid
        {
          skipAutoOrientation: true,
        }
      )
    })

    repositionCommands = repositionCommands.filter((cmd) => cmd !== null)

    if (repositionCommands.length === 0) return

    const multiCommand = new window.MultiCmdsCommand(repositionCommands)
    const undoMultiCommand = () => {
      // if the user decides to undo using the Ctrl+Z shortcut
      // and the notification is still displayed, we just do nothing
      // because it has been undone
      const undoStack = window.editor.history.undos
      const canUndo = undoStack[undoStack.length - 1].commandUUID === multiCommand.commandUUID
      if (!canUndo) return // do nothing
      window.editor.history.undo()
    }
    window.editor.execute(multiCommand)
    window.Designer.showNotification('Module grid elevations auto-adjusted to match terrain', 'info', {
      autoHideDuration: 5000,
      buttons: [
        {
          label: 'Undo',
          action: undoMultiCommand,
        },
      ],
    })
  },

  getIsImageryPremium: (imageryType) => {
    if (!imageryType) return false
    if (imageryType.is_premium) return true
    else if (window.getStorage().getItem('nearmap_is_premium') === 'true' && imageryType.provider === 'Nearmap') {
      return true
    } else return false
  },

  startDesignMode: async function (startLocation4326, country_iso2, state, mapTypeData, timezoneOffset, options) {
    var commandUUID = Utils.generateCommandUUIDOrUseGlobal()

    var activate = function () {
      editor.setMode('interactive', true)

      editor.waiting.views = false

      // Let viewsUpdated detect that 3D urls exist that haven't yet been loaded then load DSM
      editor.signals.viewsChanged.dispatch()
    }

    // Clear blocking of maxDigitalZoom applied in Explore mode
    Object.entries(MapHelper.mapInstances).forEach(([key, mapInstance]) => {
      if (mapInstance && mapInstance.dom && mapInstance.dom.hasOwnProperty('maxDigitalZoom')) {
        console.log('Clear blocking of maxDigitalZoom applied in Explore mode from: ' + key)
        delete mapInstance.dom.maxDigitalZoom
      }
    })

    // Use default instead of hard-code...
    var performanceCalculator = WorkspaceHelper.getDefaultPerformanceCalculator()

    editor.execute(new SetValueCommand(editor.scene, 'timezoneOffset', timezoneOffset, commandUUID))
    editor.execute(new SetValueCommand(editor.scene, 'country', { iso2: country_iso2 }, commandUUID))

    // Inject startLocation4326 into editor.scene.sceneOrigin4326 to fix a bug #5578 which was found when using
    // "enter address later" which would not update editor.scene.sceneOrigin4326 leaving it at [0,0] which caused
    // output simulation to assume lat=0,lon=0 and give totally wrong output.
    // But this line should probably be applied for all projects anyway and it is probably only luck that it was not
    // needed for regular projects which were created using the full address search.
    if (startLocation4326 && startLocation4326[0]) {
      editor.execute(new SetValueCommand(editor.scene, 'sceneOrigin4326', startLocation4326, commandUUID))
    }

    // If mapType already exists then copy it's zoom level to minimize unnecessary changes when starting design mode
    // Otherwise just maxZoom if it has been detected
    // Otherwise don't provide anything and let it use defaults
    var zoomTarget

    if (MapHelper.activeMapInstance?.mapData?.mapType === mapTypeData.map_type) {
      zoomTarget = MapHelper.activeMapInstance.mapData.zoomTarget
    } else if (MapHelper.mapInstances[mapTypeData.map_type]?.dom?.maxZoom) {
      zoomTarget = MapHelper.mapInstances[mapTypeData.map_type].dom.maxZoom
    } else {
      zoomTarget = null
    }

    if (
      mapTypeData &&
      (mapTypeData.map_type === 'Google3D' ||
        mapTypeData.map_type === 'Nearmap3D' ||
        mapTypeData.map_type === 'GetMapping3D' ||
        mapTypeData.map_type === 'GetMappingPremium3D' ||
        mapTypeData.map_type === 'Vexcel3D')
    ) {
      // If calculator is PVWatts automatically upgrade it to SAM to allow raytraced shading.
      // Warn user so they are not taken by surprise.
      var calculator_has_been_upgraded = false
      if (performanceCalculator === 1) {
        performanceCalculator = 2
        calculator_has_been_upgraded = true
      }

      if (performanceCalculator === 2) {
        // If any systems already exist, update their calculators.
        // Otherwise apply this calculator in the first auto-created system, below.
        editor.getSystems().forEach((s) => {
          if (s.calculator !== performanceCalculator) {
            editor.execute(new SetValueCommand(s, 'calculator', performanceCalculator, commandUUID))
            calculator_has_been_upgraded = true
          }
        })
        if (calculator_has_been_upgraded) {
          window.Designer.showNotification(
            'Energy Production Calculator upgraded to System Advisor Model (SAM) to enable automated shading.',
            'info'
          )
        }
      }

      // Reset the camera to focus on world origin. If terrain needs to be reloaded that is handled separately

      ViewHelper.clearViewsCommand(commandUUID)

      var viewInstance = this.viewForMapTypeData(mapTypeData, {
        center: startLocation4326,
        zoomTarget: zoomTarget || undefined,
        cameraCenter: new THREE.Vector3(),
      })
      editor.execute(new AddViewCommand(viewInstance, ViewHelper, true, 0, commandUUID))

      // Unload existing terrain if already loaded
      OsTerrain.unloadTexturedDSM(editor)

      /*
      We must commit any changes to variation_data to the scene. In most cases this has no effect, but it is critical
      if the terrain has changed. Othewise this would still be applied but it would not persist in the scene and the
      scene/serialzied-data and imagery could get out of sync.
      */
      var sceneHasGroundVariationData =
        editor.scene.groundVariationData() && Object.keys(editor.scene.groundVariationData()).length > 0
      var selectedMapTypeHasGroundVariationData =
        mapTypeData.variation_data && Object.keys(mapTypeData.variation_data).length > 0

      if (sceneHasGroundVariationData || selectedMapTypeHasGroundVariationData) {
        editor.execute(
          new SetObjectTypeCommand(editor.scene, 'groundVariationData', mapTypeData.variation_data, commandUUID)
        )
      }

      // Only use if 3D center is close enough to scene center after any adjustments in explore page
      // Require this to be within 25% of the full terrainSize
      // e.g. Within a 25m radius from the center of a 100m terrain square
      var distanceFromFinalExploreLocationAnd3D = Utils.distanceBetweenLocations4326(
        [mapTypeData.variation_data.lon, mapTypeData.variation_data.lat],
        startLocation4326
      )
      var distanceThreshold = 0.25 * AccountHelper.terrainSize

      if (distanceFromFinalExploreLocationAnd3D < distanceThreshold) {
        editor.execute(new SetValueCommand(editor.scene, 'terrainProvider', mapTypeData.provider, commandUUID))
        editor.execute(new SetValueCommand(editor.scene, 'dsmUrl', mapTypeData.variation_data.dsm, commandUUID))
        editor.execute(new SetValueCommand(editor.scene, 'orthoUrl', mapTypeData.variation_data.ortho, commandUUID))

        // We often requested this terrain at a different location to the final location used for sceneOrigin after
        // leaving the explore page. Position the terrain to keep it aligned.
        var terrainPosition = OsTerrain.positionInScene(
          startLocation4326,
          [mapTypeData.variation_data.lon, mapTypeData.variation_data.lat],
          editor.scene.terrainPosition ? editor.scene.terrainPosition.z : 'not-set'
        )
        editor.execute(
          new SetValueCommand(editor.scene, 'terrainPosition', terrainPosition, commandUUID, false, 'Vector3')
        )

        var terrainRotationZ = mapTypeData.variation_data.rotation_degrees
          ? -1 * mapTypeData.variation_data.rotation_degrees * THREE.Math.DEG2RAD
          : 0
        editor.execute(new SetValueCommand(editor.scene, 'terrainRotationZ', terrainRotationZ, commandUUID))

        // Let viewsUpdated detect that 3D urls exist that haven't yet been loaded then load DSM
        editor.signals.viewsChanged.dispatch()
      } else {
        // 3D was either a) not preloaded or b) not close enough to final location, reload it now
        SceneHelper.refreshTerrain(mapTypeData, undefined, commandUUID)
      }

      // Do not call refreshTerrain directly, it will fire automatically in response to viewsChanged signal above
      // window.SceneHelper.refreshTerrain()

      if (options?.refloatModuleGridsToTerrain)
        window.editor.signals.terrainLoaded.addOnce(this.refloatModuleGridsToTerrain)

      activate()
    } else if (mapTypeData && mapTypeData.map_type === 'None') {
      ViewHelper.clearViewsCommand(commandUUID)

      var viewInstance = this.viewForMapTypeData(mapTypeData, { center: startLocation4326 })
      editor.execute(new AddViewCommand(viewInstance, ViewHelper, true, 0, commandUUID))

      // Set groundVariationData even if it is not set so we can effectively clear textures here
      // as well as setting it
      // editor.scene.groundVariationData(mapTypeData.variation_data)
      editor.execute(
        new SetObjectTypeCommand(editor.scene, 'groundVariationData', mapTypeData.variation_data, commandUUID)
      )

      // @TODO: Can we listen to a signal that ground has changed rather than calling procedurally?
      SceneHelper.refreshGround()

      // @TODO: Delay this until the ground imagery has finished loading OR force a render after ground imagery loaded
      activate()
    } else if (mapTypeData && mapTypeData.map_type === 'EagleViewInform') {
      await window.SceneHelper.loadEagleViewReport(
        mapTypeData.variation_data.Id,
        mapTypeData.variation_data?.ReportProducts?.ProductPrimaryId
      )
    } else {
      ViewHelper.clearViewsCommand(commandUUID)

      // Most other view types simply need to override default map type
      var overrideDefaultMapType = mapTypeData && mapTypeData.map_type ? mapTypeData.map_type : null

      var views
      var autoCreateViews

      // Special case for Nearmap type
      if (mapTypeData.map_type === 'Nearmap') {
        views = [this.viewForMapTypeData(mapTypeData, { center: startLocation4326, zoomTarget: zoomTarget })]
        autoCreateViews = true
      } else {
        views = null
        autoCreateViews = !(Boolean(views) && views.length > 0)
      }

      var keepDesignOnlyChangeViews = true

      await SceneHelper.blankSceneWithCountry(
        editor,
        startLocation4326,
        views,
        { iso2: country_iso2 },
        null,
        undefined,
        null,
        timezoneOffset,
        performanceCalculator,
        autoCreateViews,
        false,
        overrideDefaultMapType,
        keepDesignOnlyChangeViews,
        false,
        { zoomTarget: zoomTarget, variation_data: mapTypeData.variation_data, ...options }
      )

      activate()
    }

    // Now create a system if one does not already exist
    window.SceneHelper.createFirstSystem(performanceCalculator)

    // Add lighting which may not exist yet because the explore page creates a "bare" scene
    SceneHelper.addAnchorAndLights(editor)

    SceneHelper.rebuildFacetTextures()

    // Ensure that rendering is enabled on startDesignMode
    // skip-address workflows previously did not re-enable this
    editor.viewport.renderActive(true)

    editor.signals.sceneLoaded.dispatch()
  },

  createFirstSystem: function (performanceCalculator, commandUUID) {
    //If calculator == 0 then auto-detect, otherwise use it directly

    WorkspaceHelper.applyProjectConfigurationFromProjectOrOrgDefault()

    if (!performanceCalculator) {
      performanceCalculator = WorkspaceHelper.getDefaultPerformanceCalculatorForNewProjects()
    }

    if (editor.getSystems().length == 0) {
      // var system = editor.createObject('OsSystem', null, null, {
      //   calculator: performanceCalculator,
      //   show_customer: true,
      // })

      //abort creating new system if component specs not get loaded
      if (!window.AccountHelper.isLoaded()) {
        console.log('AccountHelper not ready, waiting for readiness')
        AccountHelper.waitUntilLoadedDataIsReady()
          .then(() => {
            SceneHelper.createFirstSystem(performanceCalculator, commandUUID)
          })
          .catch((e) => {
            console.error('Failed to wait for AccountHelper readiness, aborting', e)
          })
        return
      }

      const options = { calculator: performanceCalculator, show_customer: true }
      options.order = editor.orderForNextSystem()
      if (options.basicMode === undefined) {
        options.basicMode = SceneHelper.useBasicModeForNewSystem()
      }
      options.unstrungModulesInverterEfficiency =
        AccountHelper && AccountHelper.getDefaultInverterEfficiency
          ? AccountHelper.getDefaultInverterEfficiency()
          : null

      options.autoSync = {
        pricingScheme: true,
        costing: true,
        adders: true,
      }
      const newSystem = new OsSystem(options)

      editor.execute(new AddObjectCommand(newSystem, undefined, true, commandUUID))

      window.editor.signals.objectChanged.dispatch(newSystem, 'show_customer')
    }
  },

  blankSceneWithCountry: async function (
    editor,
    startLocation4326,
    views,
    country,
    bare,
    initData,
    roofTypeId,
    timezoneOffset,
    performanceCalculator,
    autoCreateViews,
    skipExtraViews,
    overrideDefaultMapType,
    keepDesignOnlyChangeViews,
    skipAutoCreateSystem,
    options
  ) {
    //countryCode will be populated from an async lookup

    // Workaround: if we store existing editor.displayMode and reinstate after we are finished then beware that
    // if we started in presentation mode then at the end it will be disabled
    var originalEditorMode = editor.displayMode ? editor.displayMode : 'hidden'
    var originalDesignMode = editor.designMode ? editor.designMode : 'hidden'

    function deactivateScene() {
      editor.setMode('presentation')
    }

    function activateScene() {
      //original editor mode become invalid after designMode changed/page changed
      //In a case like jump from project page to studio run blankSceneWithCountry again in studio before first blankSceneWithCountry(project oage) finish
      if (originalDesignMode !== editor.designMode) return
      if (originalEditorMode === 'explore' && editor.designMode === 'explore') {
        // hacky workaround to prevent interactive mode being enabled in explore mode.
        // why would we want to set interactive in explore mode?
      } else {
        editor.setMode(originalEditorMode, true)
      }

      // This copies ViewHelper.views into editor.scene.views so it can be saved into
      // editor.scene.userData.views when saving
      // This extra check for interactive mode probably redundant now that we prevent this from running when
      // mode === 'presentation' so we can probably always call ViewHelper.saveView() here
      if (originalEditorMode === 'interactive') {
        ViewHelper.saveView()
      } else {
        console.warn(
          'Calling SceneHelper.activateScene() when originalEditorMode not interactive, ignore to avoid throwing error in ViewHelper.saveView()'
        )
      }

      // window.editor.signals.viewsChanged.dispatch()
    }

    deactivateScene()

    //For restarting an existing scene
    if (!startLocation4326) {
      startLocation4326 = editor.scene.sceneOrigin4326
    }

    //For restarting an existing scene
    if (!country) {
      country = editor.scene.country
    }

    if (!keepDesignOnlyChangeViews) {
      editor.clear()
      editor.scene.roofTypeId(roofTypeId)
      editor.scene.wallTypeId(null)

      if (startLocation4326) {
        editor.scene.sceneOrigin4326 = startLocation4326
        MapHelper.defaultSceneOrigin4326 = startLocation4326
      }

      if (country) {
        editor.scene.country = country
      }

      if (timezoneOffset !== null) {
        editor.scene.timezoneOffset = timezoneOffset
      }
    }

    //If #nomaps then just load one viewport with "None" map type
    if (!loadMapScripts) {
      let viewParams = [
        {
          cameraPreset: 'top',
          mapData: MapData.mapDataForTopDown('None', { center: startLocation4326 }),
        },
      ]
    }

    if (!views) {
      views = [
        this.viewForParams(
          this.defaultViewParams(overrideDefaultMapType, {
            center: startLocation4326,
            zoomTarget: options?.zoomTarget,
            variation_data: options?.variation_data,
          })
        ),
      ]
    }

    ViewHelper.storeViewsWithCommands(views)

    editor.waiting.views = true
    window.editor.signals.viewsChanged.dispatch()

    if (views.length) {
      await ViewHelper.loadViewByIndex(0, editor)
    }

    // Note: in new scene a system will not exist initially so we must detect whether the new system would use basic mode
    var usingBasicMode = options?.basicMode || SceneHelper.useBasicModeForNewSystem()

    var defaultMapTypeForAutoCreate = overrideDefaultMapType || window.getDefaultMapType()

    if (autoCreateViews === false) {
      // skip auto-creation
    } else if (usingBasicMode) {
      //do not auto-create additional views in basic mode

      editor.waiting.views = false
      window.editor.signals.viewsChanged.dispatch()

      activateScene()
    } else if (
      (country.iso2 === 'AU' || country.iso2 === 'US') &&
      AccountHelper.hasNearmap() &&
      defaultMapTypeForAutoCreate === 'Nearmap' &&
      skipExtraViews !== true
    ) {
      var buildNearmapViewsCallback = function () {
        window.Designer.browseNearmapPhotos(
          startLocation4326,
          function (data) {
            if (data.surveys.length === 0) {
              SceneHelper.handleNoNearmapImagery()
            }

            var directions = ['V', 'N', 'E', 'S', 'W']

            var optimalHeading = {
              N: 0,
              E: 90,
              S: 180,
              W: 270,
            }

            var photosByDirection = {}

            directions.forEach(function (d) {
              photosByDirection[d] = []
            })

            data.photos.forEach(function (photoMeta) {
              var forceCardinal = true
              photosByDirection[
                Designer.classifyDirection(photoMeta.inclinationDeg, photoMeta.bearingDeg, forceCardinal)
              ].push(photoMeta)
            })

            var bestPhotoThisOrientation

            //Create viewports for best views in each direction from: data.photos
            directions.slice(1).forEach(function (d) {
              if (photosByDirection[d].length > 0) {
                bestPhotoThisOrientation = oslib3d.sortByBestOblique(
                  photosByDirection[d],
                  { lng: startLocation4326[0], lat: startLocation4326[1] },
                  optimalHeading[d],
                  50
                )[0]
                window.ViewHelper.addView(
                  window.ViewHelper.buildNearmapObliqueViewInstance(bestPhotoThisOrientation, {
                    center: startLocation4326,
                    cameraCenter: editor.viewport.worldPositionAtViewFinderCenter(),
                  })
                )
              }
            })

            editor.waiting.views = false
            editor.signals.viewsChanged.dispatch()
          },
          function (message) {
            editor.waiting.views = false

            window.Designer.showNotification(
              'Creation of views from Nearmap Source Maps Unsuccessful.' + ' ' + message,
              'danger'
            )
            editor.signals.viewsChanged.dispatch()
          }
        )
      }

      // We no longer auto-load terrain unless specifically ising 3D
      // if (performanceCalculator === 2 || WorkspaceHelper.getDefaultPerformanceCalculator() === 2) {
      //   // First check for 3D. If 3D is available, create a 3D view first then proceed with loading the other views
      //   OsTerrain.loadTerrainAtLocation(
      //     editor
      //     'nearmap',
      //     [SceneHelper.getLongitude(), SceneHelper.getLatitude()],
      //     null,
      //     true,
      //     buildNearmapViewsCallback
      //   )
      // } else {
      //   buildNearmapViewsCallback()
      // }
      buildNearmapViewsCallback()

      activateScene()
    } else if (
      country.iso2 === 'NL' &&
      AccountHelper.getApiKey('Cyclomedia') &&
      defaultMapTypeForAutoCreate === 'Cyclomedia'
    ) {
      MapHelper.getCyclomediaObliquesAtLocation(editor.scene.sceneOrigin4326, function () {
        // Mark loading complete only when views are finished being added

        editor.waiting.views = false
        window.editor.signals.viewsChanged.dispatch()
        activateScene()
      })
    } else if (
      defaultMapTypeForAutoCreate === 'GoogleTop' &&
      (country.iso2 === 'US' ||
        country.iso2 === 'AU' ||
        country.iso2 === 'NZ' ||
        country.iso2 === 'IT' ||
        country.iso2 === 'NL' ||
        country.iso2 === 'GB') &&
      skipExtraViews !== true
    ) {
      await OsGoogle.addGoogleObliquesAfterDetection(startLocation4326, true)

      activateScene()
    } else {
      // No viewport creation required, mark loading complete immediately.

      editor.waiting.views = false
      window.editor.signals.viewsChanged.dispatch()
      activateScene()
    }

    if (bare !== true) {
      SceneHelper.addAnchorAndLights(editor)

      if (!keepDesignOnlyChangeViews) {
        var initDataCreateSystemActions =
          initData &&
          initData.filter(function (initAction) {
            return initAction.action === 'createSystem'
          })

        if (initDataCreateSystemActions && initDataCreateSystemActions.length > 0) {
          initDataCreateSystemActions.forEach(function (createSystemAction) {
            const options = createSystemAction.options
            options.order = editor.orderForNextSystem()
            if (options.basicMode === undefined) {
              options.basicMode = SceneHelper.useBasicModeForNewSystem()
            }
            options.unstrungModulesInverterEfficiency =
              AccountHelper && AccountHelper.getDefaultInverterEfficiency
                ? AccountHelper.getDefaultInverterEfficiency()
                : null
            const newSystem = new OsSystem(options)
            editor.addObject(newSystem, null)
            editor.select(newSystem)

            window.editor.signals.objectChanged.dispatch(newSystem, 'show_customer')
          })
        } else if (!skipAutoCreateSystem) {
          SceneHelper.createFirstSystem(performanceCalculator)
        }
      }
    }

    // Mark the first auto-created view as showCustomer=true by default
    var view = ViewHelper.getViewByIndex(0)
    if (view) {
      // ViewHelper.toggleShowCustomerForView(view, true)
      view['show_customer'] = true
    }

    editor.select(null)

    //@TODO: Fix hack to show grid after auto-creating mapType: None
    if (view && view.mapData.mapType === 'None') {
      editor.signals.mapChanged.dispatch()
    }

    editor.signals.sceneLoaded.dispatch()
  },

  refreshTerrain: function (mapTypeData, showNotificationOnError, commandUUID) {
    /*
    Use the lon/lat in the response to position the terrain relative to the sceneOrigin which ensures that we don't
    have issues due to using cached terrain urls from a nearby location, etc.
    */

    var currentMapType = MapHelper.activeMapInstance.toMapData().mapType

    var provider

    if (mapTypeData && mapTypeData.provider) {
      provider = mapTypeData.provider
    } else {
      if (currentMapType === 'Nearmap' || currentMapType === 'NearmapSource' || currentMapType === 'Nearmap3D') {
        provider = 'Nearmap'
      } else if (currentMapType === 'Google3D') {
        provider = 'Google'
      } else if (currentMapType === 'GetMapping3D') {
        provider = 'GetMapping'
      } else if (currentMapType === 'GetMappingPremium3D') {
        provider = 'GetMappingPremium'
      } else if (currentMapType === 'Vexcel3D') {
        provider = 'Vexcel'
      } else {
        console.warn('Unknown map provider for currentMapType:' + currentMapType)
      }
    }

    var filterResultsFunc

    var targetMapType = mapTypeData?.map_type || currentMapType

    const usingLegacyGoogle3D = () => {
      // New endpoints use the proxy endpoint instead of direct link to Google endpoint
      return window.editor?.scene?.dsmUrl?.includes('google')
    }

    if (targetMapType === 'Nearmap3D') {
      filterResultsFunc = (availableMapType) =>
        availableMapType?.variation_data?.capture_date === mapTypeData?.variation_data?.capture_date
    } else if (targetMapType === 'Google3D') {
      // If we have specified mapTypeData then we can simply match the variation_name but if we are just refreshing
      // the current imagery we do not have that data to match against. Instead, match which type is being used currently
      if (mapTypeData && mapTypeData?.variation_name) {
        filterResultsFunc = (availableMapType) => availableMapType?.variation_name === mapTypeData?.variation_name
      } else {
        filterResultsFunc = (availableMapType) => {
          // See equivalent logic in spa/app/src/projectSections/sections/design/tools/view/PopoverViewSelector.tsx
          if (availableMapType?.map_type === targetMapType && targetMapType === 'Google3D') {
            const isLegacyGoogle3D = usingLegacyGoogle3D()
            if (isLegacyGoogle3D && availableMapType.variation_name === 'Google 3D (Legacy)') {
              return true
            } else if (!isLegacyGoogle3D && availableMapType.variation_name === 'Google Solar API') {
              return true
            } else {
              return false
            }
          } else {
            return availableMapType?.map_type === targetMapType
          }
        }
      }
    }

    // Only create new view if there is no existing 3D views
    var createNewView =
      ViewHelper.views.filter((viewObject) => MapData.mapTypeIs3D(viewObject.mapData.mapType)).length === 0

    OsTerrain.loadTerrainAtLocation(
      editor,
      provider,
      MapHelper.activeMapInstance.toMapData().center,
      null,
      createNewView,
      null,
      showNotificationOnError,
      filterResultsFunc,
      commandUUID
    )
  },

  // basic mode will be deprecated
  // new systems will now always be in "full mode"
  useBasicModeForNewSystem: function () {
    return false
  },

  getSunShadowCameraSize: function () {
    return AccountHelper.terrainSize
  },

  addAnchorAndLights: function (editor) {
    //Clear if already exist
    this.clearLights(editor)

    if (editor.viewWidget) {
      editor.removeObject(editor.viewWidget)
      delete editor.viewWidget
    }

    // if (!editor.scene.fog) {
    //   editor.scene.fog = new THREE.Fog(0xffffff, 300, 1000)
    // }

    if (!editor.ambientLight) {
      editor.ambientLight = new THREE.AmbientLight(0xffffff)
      editor.ambientLight.intensity = 1.0
      editor.ambientLight.name = 'Ambient Light'
      editor.ambientLight.userData.excludeFromExport = true
      editor.addObject(editor.ambientLight)
    }

    if (!editor.sun) {
      editor.sun = new THREE.DirectionalLight(0xffffff, 2.0)

      editor.sun.userData.excludeFromExport = true
      editor.sun.name = 'Sun'

      editor.sun.target.userData.excludeFromExport = true
      editor.sun.target.name = 'SunTarget'

      editor.sun.distance = SUN_DISTANCE
      editor.sun.castShadow = getStudioDetail() === 'high' ? true : false
      editor.sun.shadow.mapSize.width = sunShadowMapSize
      editor.sun.shadow.mapSize.height = sunShadowMapSize

      //editor.sun.shadow.bias = -0.001;//better for terrain
      editor.sun.shadow.bias = -0.00001 //better for shading of roof by modules (very close)
      //editor.sun.shadow.radius = 1

      var sunShadowCameraSize = this.getSunShadowCameraSize()

      editor.sun.shadow.camera.top = sunShadowCameraSize / -2
      editor.sun.shadow.camera.bottom = sunShadowCameraSize / 2
      editor.sun.shadow.camera.left = sunShadowCameraSize / -2
      editor.sun.shadow.camera.right = sunShadowCameraSize / 2
      editor.sun.shadow.camera.near = editor.sun.distance - SUN_CAMERA_DEPTH
      editor.sun.shadow.camera.far = editor.sun.distance + SUN_CAMERA_DEPTH

      editor.addObject(editor.sun)
      editor.addObject(editor.sun.target)
    }

    // Add Sun Helper
    if (!this.sunSphere) {
      this.sunSphere = new THREE.Mesh(
        new THREE.SphereBufferGeometry(10, 16, 8),
        new THREE.MeshBasicMaterial({ color: 0xffffff })
      )
      this.sunSphere.position.x = -70
      this.sunSphere.visible = false
      this.sunSphere.userData.excludeFromExport = true
    }
    editor.scene.add(this.sunSphere)

    // this.initSky()

    this.updateSun() //includes a call to arrangeLights()

    // Build ViewWidget
    // Start invisible then let other controllers make it visible if necessary
    editor.viewWidget = new OsViewWidget({ visible: false })
    editor.addObject(editor.viewWidget)
  },

  arrangeLights: function (editor, relativePosition) {
    if (!editor.sun) {
      return
    }

    if (!relativePosition) {
      if (this.lastRelativePosition) {
        relativePosition = this.lastRelativePosition
      }
    }

    // Lights have a specified angle and point at the screen-center-intersection-with-ground
    var worldPositionAtViewportCenter = editor.viewport.worldPositionAtViewportCenter()

    if (!worldPositionAtViewportCenter) {
      // Cannot find intersection with ground, skip updating lights to avoid errors
      // We will automatically resume once we can find an intersection
      return
    }

    if (!relativePosition) {
      relativePosition = new THREE.Vector3().fromArray([0, this.getHemisphere() === 'north' ? -20 : 20, 50])
    }

    editor.sun.position.copy(new THREE.Vector3().addVectors(worldPositionAtViewportCenter, relativePosition))
    editor.sun.target.position.copy(worldPositionAtViewportCenter)
    // editor.sun.target.position.copy(new THREE.Vector3())

    // editor.sun.shadow.camera.lookAt(worldPositionAtViewportCenter)
    // editor.sun.shadow.camera.updateProjectionMatrix()
    this.lastRelativePosition = relativePosition
  },

  clearLights: function (editor) {
    editor.filter('type', 'AmbientLight').forEach(function (o) {
      editor.removeObject(o)
    })

    editor.filter('type', 'DirectionalLight').forEach(function (o) {
      editor.removeObject(o)
    })

    delete editor.sun
    delete editor.ambientLight
  },

  setCustomImageAsGround: function (img, imageDataUri) {
    let aspectRatio = img.width / img.height
    let prevGroundVariationData = window.editor.scene.groundVariationData()
    let newGroundVariationData = {
      ground_imagery_provider: 'upload',
      size: [100 * aspectRatio, 100],
      scale: [1, 1, 1],
      rotation: [0, 0, 0],
      offset: [0, 0, 0],
      url: imageDataUri,
    }
    if (prevGroundVariationData.ref_imagery_data) {
      // recycle the reference imagery data to avoid unnecessary calls to Google Static maps API again
      newGroundVariationData.ref_imagery_data = prevGroundVariationData.ref_imagery_data
      delete prevGroundVariationData.ref_imagery_data
    }
    window.editor.scene.groundVariationData(newGroundVariationData)
    this.refreshGround()
    window.editor.select(window.editor.getGround())
  },

  getGoogleGroundImageryUrlForProject() {
    let location4326 = AccountHelper.sceneOrigin4326FromSceneOrProject()
    let countryCode = window.WorkspaceHelper.project?.country_iso2
    let stateCode = window.WorkspaceHelper.project?.state
    let cachedMapTypes = AccountHelper.terrainUrlsCache.get(
      'cachedGetMapTypesAtLocationRequest',
      location4326,
      countryCode,
      stateCode,
      !!window.AccountHelper.getIsPremiumImageryAvailable(location4326, countryCode, stateCode).isAvailable
    )[0]
    let referenceImagery = cachedMapTypes.find((mapType) => {
      return (
        mapType.provider === 'Google' &&
        mapType.map_type === 'None' &&
        mapType.variation_name === 'Google Ground Imagery'
      )
    })
    if (referenceImagery) {
      return referenceImagery.variation_data.url
    } else {
      return ''
    }
  },

  showReferenceForCustomImagery: async function () {
    let ground = editor.getGround()
    let variationData = editor.scene.groundVariationData()

    function displayReferenceImageryPlane(imageryData) {
      // create and display the plane for the reference imagery
      const targetWidth = 400 * Utils.latAndZoomToMetersPerPixel(SceneHelper.getLatitude(), 18)
      const targetHeight = 400 * Utils.latAndZoomToMetersPerPixel(SceneHelper.getLatitude(), 18)
      const refImageryTexture = new THREE.TextureLoader().load(imageryData, window.editor.render)
      const refImageryPlane = new THREE.PlaneGeometry(targetWidth, targetHeight)
      const refImageryMaterial = new THREE.MeshStandardMaterial({
        map: refImageryTexture,
        side: THREE.DoubleSide,
        color: 0x808080,
        roughness: 1.0,
      })
      const refImageryMesh = new THREE.Mesh(refImageryPlane, refImageryMaterial)
      refImageryMesh.position.z = ground.position.z - 1
      refImageryMesh.name = 'ReferenceImagery'
      refImageryMesh.userData.excludeFromExport = true
      editor.addObject(refImageryMesh)
    }

    if (!variationData.ref_imagery_data) {
      // old projects with custom imagery may not have reference imagery yet
      // add it in so they can benefit from this new feature
      let refImageryUrl = this.getGoogleGroundImageryUrlForProject()
      refImageryUrl = refImageryUrl
        .replace('size=640x640', 'size=400x400')
        .replace('API_KEY', AccountHelper.getApiKey('google'))
      // fetch the reference satellite imagery and encode it as data URL
      Designer.showNotification('Loading reference satellite imagery...', 'info')
      let blob = await fetch(refImageryUrl).then((response) => response.blob())
      let dataUrl = await new Promise((resolve) => {
        let reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
      // save the reference imagery encoded in base64 as part of the design data
      Utils.compressImageToJPEGViaDataUrl(dataUrl, 0.7, (compressedDataURL) => {
        variationData.ref_imagery_data = compressedDataURL
        displayReferenceImageryPlane(variationData.ref_imagery_data)
      })
    }

    if (variationData.ref_imagery_data) {
      displayReferenceImageryPlane(variationData.ref_imagery_data)
    }
  },

  hideReferenceForCustomImagery: function () {
    let referenceImageryObjs = editor.filter('name', 'ReferenceImagery')
    referenceImageryObjs.forEach((refImagery) => {
      refImagery.material.dispose()
      refImagery.geometry.dispose()
      editor.removeObject(refImagery)
    })
  },

  refreshGround: function () {
    editor.uiPause('ui', 'refreshGround')
    editor.uiPause('render', 'refreshGround')

    var groundVariationData = editor.scene.groundVariationData()
    var groundType =
      groundVariationData && groundVariationData.ground_imagery_provider
        ? groundVariationData.ground_imagery_provider
        : 'shadow'
    var groundDisplayMode = ViewHelper.groundDisplayMode()

    if (groundDisplayMode === 'none') {
      this.hideGround(editor)
      return
    }

    // Remove old ground

    var ground = editor.getGround()

    if (ground && ground.parent) {
      editor.removeObject(ground)
    }

    // If size is not set in groundVariationData then default to 100m x 100m
    // Google Sunroof currently supplied size=100, this will be converted to [100,100]
    // Google Ground currently supplied size=undefined, forced it to [null, null] to trigger auto-detection
    // Uploaded image is supplied as [aspectRatio, 100, 100], it can be used directly
    // Default Shadow Ground supplied size=undefined, default to [100, 100]
    var size = groundVariationData.size || [100, 100]

    // Hack to handle some values which are tuples and some which are a single value
    // @TODO: Standardize so that everywhere uses a tuple, including the back-end.
    if (size?.length !== 2) {
      size = [size, size]
    }

    if (groundType === 'google') {
      // Special case for google ground, it should supply [null, null] so it gets auto-detected
      // based on latitude.
      // We should probably set this in the back-end response, but this will temporarily fix it
      // without needing to touch the back-end
      size = [null, null]
    }

    if (groundType === 'shadow') {
      // New ground
      ground = new OsGround({
        groundType: groundType,
        receiveShadow: true,
        lon: editor.scene.sceneOrigin4326[0],
        lat: editor.scene.sceneOrigin4326[1],
        size: size,
        groundUrl: groundVariationData.url,
      })
    } else {
      // New ground
      ground = new OsGround({
        groundType: groundType,
        receiveShadow: false,
        lon: editor.scene.sceneOrigin4326[0],
        lat: editor.scene.sceneOrigin4326[1],
        size: size,
        groundUrl: groundVariationData.url,
      })

      var elevation = groundVariationData.elevation || 0

      //Move ground slightly below z=0 to avoid z-fighting with grid helper
      if (elevation === 0) {
        elevation = -0.01
      }

      ground.position.z = elevation
    }

    editor.addObject(ground)
    ground.onChange(editor)

    editor.uiResume('ui', 'refreshGround')
    editor.uiResume('render', 'refreshGround')
  },
  // Special hack to prevent clearing shading and triggering raytracing just due to
  // changing textures

  rebuildFacetTextures: function () {
    editor.uiPauseUntilComplete(
      function () {
        editor.uiPauseUntilComplete(
          function () {
            SceneHelper.rebuildFacetTexturesInProgress++
            editor.filter('type', 'OsFacet').forEach(function (f) {
              //@todo: Avoid the full onChange and just update the necessary parts
              //either cache internally in onChange or just call what you need for the ground changes
              // f.onChange(editor, true) // much too slow, this would need massive work to cache effectively

              // Avoid crashing if facet does not have a mesh (e.g. because it has no vertices)
              if (f.mesh) {
                f.mesh.refreshMaterial(ViewHelper.facetDisplayMode())
              }
            })
            SceneHelper.rebuildFacetTexturesInProgress--
          },
          this,
          'render',
          'renderPauseLock::rebuildFacetTextures'
        )
      },
      this,
      'ui',
      'uiPauseLock::rebuildFacetTextures',
      false
    )
  },

  hideGround: function (editor) {
    var changed = false

    var ground = editor.getGround()

    if (ground && ground.parent) {
      editor.removeObject(ground)
      changed = true
    }

    //Refresh facets so drawing style updates
    if (changed) {
      this.rebuildFacetTextures()
      ground.onChange(editor)
    }
  },

  setupHorizon: function (fileName, elevations, radius) {
    if (!radius) {
      radius = 800
    }
    if (elevations) {
      editor.scene.horizon = elevations
    }

    var horizonObject = this.getHorizon()
    if (horizonObject) {
      editor.removeObject(horizonObject)
    }

    if (editor.scene.horizon) {
      horizonObject = new OsHorizon(radius, editor.scene.horizon, { fileName })
      editor.scene.add(horizonObject)
      //Lazy to avoid needing to handle objectAdded for OsHorizon too (in order to clear shading calcs)
      editor.signals.objectChanged.dispatch(horizonObject)
    }
  },

  getHorizon: function () {
    return editor.filter('type', 'OsHorizon')[0]
  },

  clearHorizon: function () {
    editor.scene.horizon = null
    this.setupHorizon()
    this.clearShadingAndRecalc()
  },

  clearShadingAndRecalc: function () {
    editor.filter('type', 'OsSystem').forEach((system) => {
      ShadeHelper.clearModuleShading(system.uuid)
      window.Designer.requestSystemCalculations(system)
    }, this)
  },

  activateCellsForTarget: function (system, autoDesignGeoJson, targetkWh) {
    // Sort cells from highest to lowest output, then enable as many as required starting with the best
    var cellsForAllFeatures = []

    var autoDesignGeoJsonForSystem = autoDesignGeoJson.find((d) => d.uuid === system.uuid)

    if (!autoDesignGeoJsonForSystem) {
      console.warn('Abort activateCellsForTarget: Unable to find geojson which matches system uuid')
      return
    }

    autoDesignGeoJsonForSystem.features
      .filter((feature) => feature.properties.type == 'module')
      .forEach(function (feature) {
        cellsForAllFeatures.push({
          grid_uuid: feature.properties.grid_uuid,
          col: feature.properties.col,
          row: feature.properties.row,
          output_kwh: feature.properties.output_kwh,
        })
      })

    var cellsSorted = cellsForAllFeatures.sort((a, b) => (a.output_kwh > b.output_kwh ? -1 : 1))
    var currentkWh = 0
    var cellsToActivate = []
    cellsSorted.forEach((cell) => {
      if (currentkWh < targetkWh) {
        cellsToActivate.push({
          grid_uuid: cell.grid_uuid,
          col: cell.col,
          row: cell.row,
        })
        currentkWh += cell.output_kwh
      }
    })

    system.moduleGrids().forEach((mg) => {
      var feature = autoDesignGeoJsonForSystem.features.find((feature) => feature.properties.uuid === mg.uuid)

      if (feature) {
        mg.populate(
          feature.properties.modules
            .filter((m) =>
              cellsToActivate.find(
                (cellToActivate) =>
                  cellToActivate.col === m[0] &&
                  cellToActivate.row === m[1] &&
                  cellToActivate.grid_uuid === feature.properties.uuid
              )
            )
            .map((m) => m[0] + ',' + m[1])
        )

        mg.onChange(editor)
      } else {
        console.log('moduleGrid not found')
      }
    })
  },
  drawModules: function (editor, system, autoDesignGeoJson, activateAllModules, gridLocks) {
    /*
    if activateAllModules is True then activate all modules, otherwise set them all to buildable but not active.

    */
    if (!system.parent) {
      console.log(
        'SceneHelper.drawModules() called for system not belonging to scene, assumine this is ghost async response from old scene.'
      )
      return
    }

    SceneHelper.drawModulesInProgress = true

    //system.clearModules(editor)
    var moduleGrids = system.moduleGrids()
    editor.execute(new SystemClearElectricalsCommand(system, moduleGrids))

    var sceneOrigin3857 = ol.proj.transform(editor.scene.sceneOrigin4326, 'EPSG:4326', 'EPSG:3857')
    var webMercatorMetersToRealWorldMeters = Utils.webMercatorMetersToRealWorldMeters(editor.scene.sceneOrigin4326[1])

    var systemModule = null
    var moduleGrid = null

    /*
    Determine which panels to enable to reach target kWh output.
    */

    autoDesignGeoJson.features.forEach(function (feature) {
      /*
      Old method which determined which modules to include then sent them to the front-end.
      We are parking that approach for now because we will simply generate the max-fit design on the back-end
      then choose which panels to enable in the frontend.

      if(feature.properties.type == 'module'){

        systemModule = new OsModule(new THREE.Vector3());

        var centroid = turf.centroid(feature);
        var position = new THREE.Vector3(centroid.geometry.coordinates[0]-sceneOrigin3857[0], centroid.geometry.coordinates[1]-sceneOrigin3857[1], 0);

        systemModule.arrange(position, feature.properties.slope, feature.properties.azimuth);

        editor.addObject(systemModule);
        systemModule.onChange(editor);
        console.log("addModule");
      }
      */

      if (feature.properties.type === 'grid') {
        var moduleType = AccountHelper.getModuleData(editor.selectedSystem?.moduleId)

        // Positioning based on Grid Origin or Centroid can often places the origin outside the facet polygon.
        // Instead, we set position based on first module.
        var gridOriginOriginal = new THREE.Vector2().fromArray(feature.geometry.coordinates)
        var position = new THREE.Vector3(
          gridOriginOriginal.x - sceneOrigin3857[0],
          gridOriginOriginal.y - sceneOrigin3857[1],
          0
        ).multiplyScalar(webMercatorMetersToRealWorldMeters)

        // Strangely, sometimes terrain exists but editor.scene.terrainPosition.z is not set
        // so use terrain position directly if available.
        var terrain = editor.getTerrain()
        var terrainPositionZ = terrain?.position?.z || editor.scene.terrainPosition?.z || 0
        position.z = feature.properties.z + terrainPositionZ + ELEVATE_PANEL_GROUP_ABOVE_FACET

        moduleGrid = new OsModuleGrid({
          size: moduleType.size,
          orientation: feature.properties.orientation,
          offsetRows: feature.properties.offset_rows,
          moduleSpacing: [feature.properties.grid_spacing_x, feature.properties.grid_spacing_y],
          panelConfiguration: feature.properties.use_tilt_rack
            ? OsModuleGrid.PanelConfigTypes.SingleTilt
            : OsModuleGrid.PanelConfigTypes.Flush,
          azimuth: feature.properties.tilt_rack_azimuth || feature.properties.azimuth,
          azimuthAuto: !!gridLocks?.includes('orientation') ? false : true,
          slope: feature.properties.tilt_rack_slope || feature.properties.slope,
          slopeAuto: !!gridLocks?.includes('orientation') ? false : true,
          panelTiltOverride: feature.properties.use_tilt_rack ? feature.properties.tilt_rack_slope : null,
          moduleTexture: moduleType.module_texture,
          elevationAuto: !!gridLocks?.includes('elevation') ? false : true,
          position: position,
          uuid: feature.properties.uuid,

          // This should work but unfortunately it can cause panel groups to shift, possibly because this affects the
          // grid orbit point? For now we will manuall ymake an extra call to populate()
          // cellsActive: feature.properties.modules
          //   .filter((m) => activateAllModules || m[2] === 1)
          //   .map((m) => m[0] + ',' + m[1]),

          buildableCells: feature.properties.modules.map((m) => m[0] + ',' + m[1]),
        })

        // editor.addObject(moduleGrid, system);
        editor.execute(new AddObjectCommand(moduleGrid, system, false))

        // Extra call added here because we cannot inject cellsActive above or it can mess with position of
        // auto-populated grids/panels
        // Otherwise only populate active cells

        // Critical bugfix for ensure populateSystems() and drawModules() do not give incorrect
        // panel placements when this happens while rendering is disabled. I wonder if perhaps we should always
        // call this inside the OsModuleGrid constructor - I tried that but could not get it to work.
        moduleGrid.updateMatrixWorld()

        moduleGrid.populate(
          feature.properties.modules.filter((m) => activateAllModules || m[2] === 1).map((m) => m[0] + ',' + m[1])
        )

        // @TODO: do not call onChange here because we will call it below after modules have been populated
        moduleGrid.onChange(editor)
      }
    })

    //Re-add system to ensure all children are added/refreshed
    //editor.execute(new AddObjectCommand(system, undefined, false))

    editor.viewport.cleanObjectsWithNoParent()

    SceneHelper.drawModulesInProgress = false

    //Now perform all UI updates which were skipped due to SceneHelper.drawModulesInProgress == true
    //@TODO: Is this still required?
    // if (Designer && Designer.refreshSystemsUI) {
    //   Designer.refreshSystemsUI()
    // }
  },

  handleCalcTimeout: (data) => {
    let tooManyIntegratedPmts = false
    try {
      tooManyIntegratedPmts =
        window.editor.selectedSystem?.payment_options?.filter((pmt) => !!pmt.integration)?.length > 6
    } catch (ex) {}
    if (tooManyIntegratedPmts) {
      data.message =
        'Calcuations taking longer than expected. Try reducing the number of integrated payment options on this system.'
    } else {
      data.message = 'Calculations are taking longer than expected.'
    }
  },

  calculateSystem: async function (editor, system_uuid, callback, calcCount, full_calcs) {
    if (window.isFeatureEnabled('async_full_calcs_only', 'on')) {
      this.requestTimeout = 150000
    }

    if (typeof system_uuid === 'undefined') {
      if (!editor.selectedSystem) {
        Designer.showNotification(window.translate('Create and/or Select a system to calculate'), 'danger')
        callback(false)
        return
      }
      system_uuid = editor.selectedSystem.uuid
    }

    var system = editor.objectByUuid(system_uuid)

    if (!system) {
      //@TODO: We should clear associated, pending timeouts whenever a system is deleted
      console.log('calculateSystem aborted... system not found. Perhaps it was deleted?')
      if (callback) callback(false)
      return
    }

    calcCount = calcCount || system.systemCalcCount

    system.refreshAutomatedComponents()

    // If state is invalid, prevent calculations and show warning message instead
    // Be careful not to spam user with too many messages?
    var errors = system.validate()
    if (errors.length > 0) {
      Designer.showNotification(window.translate('Unable to run calculations') + ': ' + errors.join(','), 'danger')
      callback(false)
      return
    }

    //@TODO: Only calculate if something has changed
    await ShadeHelper.calculateShadingQueue(system.uuid, true) // useOldResultsIfAvailable: true

    // Beware: sometimes an earlier promise may return while another is processing in which case shading
    // in this async call will be incomplete. Abandon this call if shading incomplete and let the other call
    // trigger the save to backend
    if (system.calculator === 2 && ShadeHelper.panelsMissingShading(system, false, false).length > 0) {
      console.log('Notice: Aborting SceneHelper.calculateSystem() because shading incomplete, wait until the next call')
      return
    }

    // If currently selected a ModuleGrid we should refresh shading annotations after shading recalc
    if (editor.selected && editor.selected.type === 'OsModuleGrid') {
      editor.signals.objectAnnotationChanged.dispatch(editor.selected)
      editor.signals.shadingUpdated.dispatch()
    }

    var system_design = system.refreshUserData()

    function getSystemFromSceneData(sceneData, uuid) {
      for (var i = 0; i < sceneData['object']['children'].length; i++) {
        var o = sceneData['object']['children'][i]
        if (o['uuid'] === uuid) {
          return o['userData']
        }
      }
    }

    function getSystemsFromSceneData(sceneData) {
      return sceneData['object']['children']
        .filter(function (o) {
          return o['type'] === 'OsSystem'
        })
        .map(function (s) {
          return s['userData']
        })
    }

    function getInverersFromSceneData(systemUuid, sceneData) {
      return sceneData['object']['children']
        .filter(function (o) {
          return o['type'] === 'OsSystem' && o['uuid'] === systemUuid
        })
        .map(function (s) {
          return s['children']
            .filter(function (o) {
              return o['type'] === 'OsInverter'
            })
            .map(function (i) {
              return i['userData']
            })
        })
    }

    var error = function (data, textStatus) {
      if (textStatus === 'timeout') SceneHelper.handleCalcTimeout(data)
      Designer.updateCalculationResponseWaitingQueued('remove', system.uuid)
      var detail = Designer.getErrorDetail(data, 'Unspecified Error.')
      Designer.showNotification(window.translate('System calculations could not be applied') + ': ' + detail, 'danger')
      if (callback) callback(false)
    }

    var success = function (data) {
      Designer.updateCalculationResponseWaitingQueued('remove', system.uuid)
      if (
        calcCount !== system.systemCalcCount &&
        editor.getSystems().filter(function (system) {
          return system.awaitingCalcs
        }).length < 2
      ) {
        console.warn('Calculation aborted! systemCalcCount: ' + calcCount)
        return
      }
      if (editor.history.redos.length > 0) {
        console.warn(
          'Abort running UpdateSystemCalculationCommand! Invalid calculation request! redo will trigger recalc for system!'
        )
        if (callback) callback(false)
        return
      }
      data = window.CompressionHelper.decompress(data, true)

      try {
        var cmds = []
        getSystemsFromSceneData(data).forEach(function (systemUserData) {
          var keys = [
            'id',
            'version',
            'output',
            'environmentals',
            'pricing',
            'payment_options',
            'bills',
            'consumption',
            'override_price_locking',
            'integration_json',
          ]
          var newSystemUserData = {}
          keys.forEach(function (key) {
            if (systemUserData.hasOwnProperty(key)) {
              newSystemUserData[key] = systemUserData[key]
            }
          })
          cmds.push(
            new UpdateSystemCalculationCommand(
              editor.scene.getObjectByProperty('uuid', systemUserData.uuid),
              newSystemUserData,
              'output',
              systemUserData.inverters
            )
          )
        })
        editor.execute(new MultiCmdsCommand(cmds))
        window.globalCommandUUID = Utils.generateCommandUUIDOrUseGlobal()
        Designer.showNotification(window.translate('System calculations applied'))

        //refresh price lock error message
        window.WorkspaceHelper?.refreshPriceLockError(system)
        if (callback) callback(true)
      } catch (err) {
        console.log(err)
        error()
      }
    }

    var sceneJSONFiltered = editor.sceneSystemsOnlyAsJSON([system_uuid], true)

    // Inject system_current into each system, if found
    var system_current_matches = editor.filterObjects(function (s) {
      return s.type === 'OsSystem' && s.userData.is_current === true
    })
    if (system_current_matches.length > 0) {
      for (var i = 0; i < sceneJSONFiltered['object']['children'].length; i++) {
        var s = sceneJSONFiltered['object']['children'][i]
        if (s.userData.is_current !== true) {
          s.userData.system_current = system_current_matches[0].userData
        }
      }
    }

    const restPostData = window.projectForm ? window.projectForm.mutators.getUnsavedChangeAffectSystemCalcs() : {}

    Designer.updateCalculationResponseWaitingQueued('add', system.uuid)

    $.ajax({
      type: 'POST',
      url:
        API_BASE_URL +
        'orgs/' +
        window.getStorage().getItem('org_id') +
        '/projects/' +
        WorkspaceHelper.getProjectId() +
        '/system_calcs/?full_calcs=' +
        (full_calcs ? 'true' : 'false') +
        '&is_lite=' +
        (WorkspaceHelper.getIsProjectLite() ? 'true' : 'false') +
        (localStorage.getItem('calc_version') ? '&calc_version=' + localStorage.getItem('calc_version') : ''),
      data: JSON.stringify({
        // only specified system(s). skip auto-refreshUserData because we did it explicitly above
        ...restPostData,
        design: window.CompressionHelper.compress(JSON.stringify(sceneJSONFiltered)),
      }),
      //contentType: 'text/plain',
      contentType: 'application/json',
      headers: Utils.tokenAuthHeaders({
        'X-CSRFToken': getCookie('csrftoken'),
      }), //cors for django
      timeout: SceneHelper.requestTimeout,
      success: success,
      error: error,
    })
  },

  requestSystemCalculation: function (system_uuid, full_calcs) {
    if (window.isFeatureEnabled('async_full_calcs_only', 'on')) {
      this.requestTimeout = 150000
    }

    return new Promise((resolve, reject) => {
      var error = function (data, textStatus) {
        if (textStatus === 'timeout') SceneHelper.handleCalcTimeout(data)
        //@TODO: Move this to a reducer??
        Designer.updateCalculationResponseWaitingQueued('remove', system_uuid)

        reject(data)
      }

      var success = function (data) {
        //@TODO: Move this to a reducer??
        Designer.updateCalculationResponseWaitingQueued('remove', system_uuid)

        resolve(data)
      }

      var sceneJSONFiltered = editor.sceneSystemsOnlyAsJSON([system_uuid], true)

      // Inject system_current into each system, if found
      var system_current_matches = editor.filterObjects(function (s) {
        return s.type === 'OsSystem' && s.userData.is_current === true
      })
      if (system_current_matches.length > 0) {
        for (var i = 0; i < sceneJSONFiltered['object']['children'].length; i++) {
          var s = sceneJSONFiltered['object']['children'][i]
          if (s.userData.is_current !== true) {
            s.userData.system_current = system_current_matches[0].userData
          }
        }
      }

      const restPostData = window.projectForm ? window.projectForm.mutators.getUnsavedChangeAffectSystemCalcs() : {}

      Designer.updateCalculationResponseWaitingQueued('add', system_uuid)

      $.ajax({
        type: 'POST',
        url:
          API_BASE_URL +
          'orgs/' +
          window.getStorage().getItem('org_id') +
          '/projects/' +
          WorkspaceHelper.getProjectId() +
          '/system_calcs/?full_calcs=' +
          (full_calcs ? 'true' : 'false') +
          '&is_lite=' +
          (WorkspaceHelper.getIsProjectLite() ? 'true' : 'false') +
          (localStorage.getItem('calc_version') ? '&calc_version=' + localStorage.getItem('calc_version') : ''),
        data: JSON.stringify({
          // only specified system(s). skip auto-refreshUserData because we did it explicitly above
          ...restPostData,
          design: window.CompressionHelper.compress(JSON.stringify(sceneJSONFiltered)),
        }),
        //contentType: 'text/plain',
        contentType: 'application/json',
        headers: Utils.tokenAuthHeaders({
          'X-CSRFToken': getCookie('csrftoken'),
        }), //cors for django
        timeout: SceneHelper.requestTimeout,
        success,
        error,
      })
    })
  },

  handleCalculationSuccess: function (data, options = {}) {
    data = window.CompressionHelper.decompress(data, true)
    function getSystemsFromSceneData(sceneData) {
      return sceneData['object']['children']
        .filter(function (o) {
          return o['type'] === 'OsSystem'
        })
        .map(function (s) {
          return s['userData']
        })
    }
    try {
      var cmds = []
      var calculationWarnings = []

      getSystemsFromSceneData(data).forEach(function (systemUserData) {
        var keys = [
          'id',
          'version',
          'output',
          'environmentals',
          'pricing',
          'payment_options',
          'bills',
          'consumption',
          'override_price_locking',
          'integration_json',
        ]
        var newSystemUserData = {}
        keys.forEach(function (key) {
          if (systemUserData.hasOwnProperty(key)) {
            newSystemUserData[key] = systemUserData[key]
          }
        })

        // Collect any calculation errors/warnings
        if (newSystemUserData.output?.calculation_errors?.length) {
          calculationWarnings.push(...newSystemUserData.output.calculation_errors)
        }

        const cmd = new UpdateSystemCalculationCommand(
          editor.scene.getObjectByProperty('uuid', systemUserData.uuid),
          newSystemUserData,
          'output',
          systemUserData.inverters
        )

        if (options.silentUpdate) {
          cmd.isEphemeral = true
        }

        cmds.push(cmd)
      })
      const multiComand = new MultiCmdsCommand(cmds)
      if (options.silentUpdate) {
        multiComand.isEphemeral = true
      }
      editor.execute(multiComand)
      window.globalCommandUUID = Utils.generateCommandUUIDOrUseGlobal()
      return {
        success: true,
        warnings: calculationWarnings,
      }
    } catch (err) {
      console.warn(err)
      return {
        success: false,
        error: err,
      }
    }
  },

  autoStringParams: function (system, moduleUuids) {
    var modules = system.getModulesSortedForStinging(moduleUuids)
    var dcOptimizer = system.dcOptimizer() ? system.dcOptimizer().userData : undefined
    var clustersAsObject = OsModuleCluster.clustersToObject(OsModuleCluster.clustersFromModules(modules))

    var [temperature_min, temperature_max] = Utils.getMinMaxTemperature(window.projectForm.getState().values)

    return {
      clusters: clustersAsObject,
      module_id: system.moduleId,
      temperature_min: temperature_min,
      temperature_max: temperature_max,
      dcOptimizer: dcOptimizer,
    }
  },

  systemForClusters: function (editor, system_uuid, callback, return_type, moduleUuids) {
    /*
    e.g.
    clusters = [
          [{uuid:'x1'},{uuid:'x2'}],
          [{uuid:'y1'}]
      ]
    */

    if (!return_type) {
      return_type = 'best'
    }

    var system = editor.objectByUuid(system_uuid)

    //Do we actually need to call refreshUserData once to populate system.site?
    //Calling refreshUserData just in case
    system.refreshUserData()

    var error = function (data) {
      console.log('error', data)
      var message = window.translate('Stringing could not be applied')

      if (data && data.responseJSON && data.responseJSON.error && data.responseJSON.error.length > 0) {
        message += ', ' + data.responseJSON.error
      }
      Designer.showNotification(message, 'danger')
      if (callback) callback(false)
    }

    var success = function (data) {
      if (editor.history.redos.length > 0) {
        console.warn('Abort request system calculation! redo will trigger recalc for system!')
        if (callback) callback(false)
        return
      }
      try {
        console.log('success', data)

        if (return_type === 'all') {
          data.system_uuid = system_uuid
          reduxStore.dispatch({
            type: 'SET_AUTO_STRING_RECOMMENDATIONS',
            recommendations: data,
          })
        } else {
          reduxStore.dispatch({
            type: 'SET_AUTO_STRING_RECOMMENDATIONS',
            recommendations: undefined,
          })
          if (editor) {
            editor.uiPauseUntilComplete(
              function () {
                SceneHelper.buildSystemForModuleClustersAndSystem(editor, system_uuid, data)
              },
              this,
              'ui',
              'uiPauseLock::buildSystemForModuleClustersAndSystem'
            )
          } else {
            SceneHelper.buildSystemForModuleClustersAndSystem(editor, system_uuid, data)
          }

          Designer.showNotification(window.translate('Inverter configuration applied'))
        }

        if (callback) callback(true)
      } catch (err) {
        console.log(err)
        error()
      }
    }

    var data = SceneHelper.autoStringParams(system, moduleId, moduleUuids)

    if (data.clustersAsObject.length === 0) {
      return
    }

    if (
      typeof system.inverterRange === 'string' &&
      (system.inverterRange.endsWith('_micro') || system.inverterRange.endsWith('_string'))
    ) {
      data.inverter_range = system.inverterRange
    } else if (typeof system.inverterRange === 'number') {
      //We are specifying a specific inverter, so supply it as an inverter id, not an inverter range
      data.inverter_ids = [system.inverterRange]
    }

    $.ajax({
      type: 'POST',
      url: API_BASE_URL + 'orgs/' + window.getStorage().getItem('org_id') + '/system_for_clusters/',
      data: JSON.stringify(data),
      contentType: 'application/json',
      headers: Utils.tokenAuthHeaders({
        'X-CSRFToken': getCookie('csrftoken'),
      }), //cors for django
      success: success,
      error: error,
    })
  },

  requestSystemForClusters: function (system_uuid, return_type, moduleUuids, inverterRange, replaceInverters) {
    return new Promise((resolve, reject) => {
      const editor = window.editor
      const system = editor.objectByUuid(system_uuid)

      //Do we actually need to call refreshUserData once to populate system.site?
      //Calling refreshUserData just in case
      system.refreshUserData()

      var modules = system.getModulesSortedForStinging(moduleUuids)
      var clustersAsObject = OsModuleCluster.clustersToObject(OsModuleCluster.clustersFromModules(modules))

      if (clustersAsObject.length === 0) {
        system.clearElectricals(editor)
        reject('clustersAsObject not found')
      }
      var error = function (data) {
        console.log('error', data)
        let message = 'Stringing could not be applied'

        if (data && data.responseJSON && data.responseJSON.error && data.responseJSON.error.length > 0) {
          message += ', ' + data.responseJSON.error
        }
        reject(message)
      }

      var success = function (data) {
        if (editor.history.redos.length > 0) {
          console.warn('Abort request system calculation! redo will trigger recalc for system!')
          reject('Abort request system calculation')
        }
        try {
          if (return_type === 'all') {
            data.system_uuid = system_uuid
            reduxStore.dispatch({
              type: 'SET_AUTO_STRING_RECOMMENDATIONS',
              recommendations: data,
            })

            // hack 2 or 2 for refreshing system panel which will not refresh because we are passing data in using a hack
            window.editor.signals.sceneGraphChanged.dispatch()
          } else {
            if (replaceInverters) {
              const inverters = system.inverters()
              console.debug('Removing inverters before applying auto-string recommendations', inverters)
              inverters.forEach((inverter) => {
                editor.removeObject(inverter)
              })
            }
            if (editor) {
              editor.uiPauseUntilComplete(
                function () {
                  SceneHelper.buildSystemForModuleClustersAndSystem(editor, system_uuid, data)
                },
                this,
                'ui',
                'uiPauseLock::buildSystemForModuleClustersAndSystem'
              )
            } else {
              SceneHelper.buildSystemForModuleClustersAndSystem(editor, system_uuid, data)
            }
          }
          resolve(data)
        } catch (err) {
          console.log(err)
          error()
        }
      }

      var [temperature_min, temperature_max] = Utils.getMinMaxTemperature(window.projectForm.getState().values)

      var dcOptimizer = system.dcOptimizer() ? system.dcOptimizer().userData : undefined
      var data = {
        clusters: clustersAsObject,
        module_id: system.moduleId,
        temperature_min: temperature_min,
        temperature_max: temperature_max,
        return_type: return_type,
        dcOptimizer: dcOptimizer,
      }

      if (!inverterRange) inverterRange = system.inverterRange

      if (
        typeof inverterRange === 'string' &&
        (inverterRange.endsWith('_micro') || inverterRange.endsWith('_string'))
      ) {
        data.inverter_range = inverterRange
      } else if (typeof inverterRange === 'number') {
        //We are specifying a specific inverter, so supply it as an inverter id, not an inverter range
        data.inverter_ids = [inverterRange]
      }

      $.ajax({
        type: 'POST',
        url: API_BASE_URL + 'orgs/' + window.getStorage().getItem('org_id') + '/system_for_clusters/',
        data: JSON.stringify(data),
        contentType: 'application/json',
        headers: Utils.tokenAuthHeaders({
          'X-CSRFToken': getCookie('csrftoken'),
        }), //cors for django
        success: success,
        error: error,
      })
    })
  },

  buildSystemForModuleClustersAndSystem: function (
    editor,
    system_uuid,
    systemDataForClusters,
    inverterExtraProps = {}
  ) {
    var affectedModuleUuids = []

    systemDataForClusters.inverters.forEach((i) => {
      i.mppts.forEach((m) => {
        m.strings.forEach((s) => {
          s.modules.forEach((m) => {
            affectedModuleUuids.push(m.uuid)
          })
        })
      })
    })

    var system = editor.objectByUuid(system_uuid)
    //We should now target an existing system but theoretically this could create it's own fresh system too
    if (!system) {
      console.log(
        'System not found to apply results of clusters. Abort handling response to avoid duplicate systems. uuid:' +
          system_uuid
      )
      return
    } else if (!system.parent) {
      console.log(
        'SceneHelper.buildSystemForModuleClustersAndSystem() called for system not belonging to scene, assumine this is ghost async response from old scene.'
      )
    }

    if (system.requireClusters) {
      system.requireClusters = false
    }
    //Be sure to reinstate!
    // if (Designer) {
    //   Designer.uiUpdatesActive = false
    // }
    if (window.editor && window.editor.viewport) {
      window.editor.uiPause('render', 'buildSystemForModuleClustersAndSystem')
    }

    const invertersAdded = []
    const mpptsAdded = []
    const stringsAdded = []

    // If Previously we would clear electricals from the whole system, but now we only clear them from affected modules
    // editor.execute(new SystemClearElectricalsCommand(system))

    affectedModuleUuids.forEach((moduleUuid) => {
      var osModule = editor.objectByUuid(moduleUuid)
      osModule.removeStringsAndCleanupEmptyElectricals()
    })

    systemDataForClusters.inverters.forEach(function (i) {
      var inverter = new OsInverter({
        // changed due to regression after new inverter wizard updates
        // inverter_id: i.inverter_id,
        inverter_id: i.inverter_activation_id === undefined ? i.id : i.inverter_activation_id,
        code: i.code,
        manufacturer: i.manufacturer_name,
        efficiency: i.efficiency,
        microinverter: i.microinverter,
        ...inverterExtraProps,
      })
      invertersAdded.push(inverter)

      editor.execute(new AddObjectCommand(inverter, system, false))

      i.mppts.forEach(function (m) {
        var mppt = new OsMppt()
        mpptsAdded.push(mppt)
        var cmds = []
        editor.execute(new AddObjectCommand(mppt, inverter, false))
        m.strings.forEach(function (s) {
          var string = new OsString()
          stringsAdded.push(string)
          var modulesArray = []
          s.modules.forEach(function (m) {
            var module = editor.objectByUuid(m.uuid)

            //don't create a module, it already exists, just locate it using it's uuid
            modulesArray.push(module)
          })
          //editor.addObject(string, mppt)
          editor.execute(new AddObjectCommand(string, mppt, false))
          cmds.push(new StringModuleArrayAssignmentCommand(string, modulesArray))
        })
        //editor.addObject(mppt, inverter)

        cmds.length > 0 && editor.execute(new MultiCmdsCommand(cmds))
      })
      //editor.addObject(inverter, system)
    })

    // Ensure everything committed to userData before re-adding
    // otherwise properties could be wiped
    system.refreshUserData()

    //Re-add even if it already exists to ensure all children are added/refreshed too
    //skip history if error occur
    //editor.execute(new AddObjectCommand(system, system.parent, false))

    //Reinstate UI updates and call once now that updates are complete
    // if (Designer) {
    //   Designer.uiUpdatesActive = true
    // }
    if (window.editor && window.editor.viewport) {
      window.editor.uiResume('render', 'buildSystemForModuleClustersAndSystem')
    }

    editor.signals.sceneGraphChanged.dispatch()
    return {
      system,
      invertersAdded,
      mpptsAdded,
      stringsAdded,
    }
  },

  designForFacets: function (editor, skipAfterModulesPlaced) {
    if (!editor.filter('type', 'OsFacet').length) {
      Designer.showNotification(window.translate('Auto-design requires roof facets to be drawn first.'))
      return
    }

    if (!editor.selectedSystem) {
      var system

      //If there are no systems at all then auto-create one. Otherwise warn and abort.
      if (editor.filter('type', 'OsSystem').length === 0) {
        system = new OsSystem()
        editor.execute(new AddObjectCommand(system, undefined, true))
      } else if (editor.filter('type', 'OsSystem').length === 1) {
        system = editor.filter('type', 'OsSystem')[0]
        editor.selectSystem(system)
      } else {
        Designer.showNotification(window.translate('Please create or select a system to work with'))
        return
      }
    }

    //Need to keep looking up using uuid in editor.selected changes... really???
    var system_uuid = editor.selectedSystem?.uuid

    var afterSystemEngineered = function () {
      //console.log('Calculations & Pricing disabled')
      SceneHelper.calculateSystem(editor, system_uuid)
    }

    var afterModulesPlaced = function () {
      //console.log('Stringing disabled')
      skipAfterModulesPlaced && SceneHelper.systemForClusters(editor, system_uuid, afterSystemEngineered)
    }

    var system = editor.objectByUuid(system_uuid)
    if (!system) {
      Designer.showNotification(window.translate('Please create or select a system to work with'))
    }

    Designer.showNotification(window.translate('Designing module layout...'))

    GeoJSONHelper.sendGeoJSON(
      GeoJSONHelper.toGeoJSON(editor),
      system.moduleId,
      WorkspaceHelper.getProjectId(),
      SetbacksHelper.tilt_rack_default_orientation,
      SetbacksHelper.tilt_rack_default_tilt,
      (data) => {
        SceneHelper.drawModules(editor, system, data, true)
        Designer.showNotification(window.translate('Module layout loaded.'))
        afterModulesPlaced()
      },
      (data) => {
        Designer.showNotification(translate('System design was unsuccessful'), 'danger')
      }
    )
  },

  autoGenerateMaxFit: function (setbackDistance, targetkWh) {
    // @TODO: Require Google 3D enabled

    //Need to keep looking up using uuid in editor.selected changes... really???
    var system_uuid = editor.selectedSystem?.uuid

    var afterSystemEngineered = function () {
      //console.log('Calculations & Pricing disabled')
      SceneHelper.calculateSystem(editor, system_uuid)
    }

    var system = editor.objectByUuid(system_uuid)
    if (!system) {
      Designer.showNotification(window.translate('Please create or select a system to work with'))
    }

    Designer.showNotification(window.translate('Designing module layout...'))

    var sceneOrigin4326 = editor.scene.sceneOrigin4326
    var url =
      API_BASE_URL +
      'orgs/' +
      window.getStorage().getItem('org_id') +
      `/projects/` +
      WorkspaceHelper.project.id +
      '/auto_design/'

    return fetch(url, {
      headers: window.Utils.tokenAuthHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        module_id: system.moduleType().id,
        setback_config: [setbackDistance || 0, setbackDistance || 0, setbackDistance || 0, setbackDistance || 0].join(
          ','
        ),
      }),
    })
      .then((response) => response.json())
      .then((autoDesignGeoJsonForSystem) => {
        // Override system_uuid so it matches the current system.
        // Unlike fully auto-designed systems, this will only include one system
        // so it will only match this system, not others. Eventually we should improve thie
        // but it is complicated due to the need for unique ModuleGrid UUIDs across all systems.
        autoDesignGeoJsonForSystem.uuid = system_uuid

        editor.scene.autoDesignGeoJson = [autoDesignGeoJsonForSystem]

        SceneHelper.drawModules(editor, system, autoDesignGeoJsonForSystem, false, ['orientation', 'elevation'])

        SceneHelper.activateCellsForTarget(system, editor.scene.autoDesignGeoJson, targetkWh)

        Designer.showNotification(window.translate('Module layout loaded.'))

        // @TODO: Replace with a more sensible signal
        editor.signals.objectChanged.dispatch(system)
      })
      .catch((error) => {
        console.log('Error autoGenerateMaxFit()', error)
      })
  },
  autoDesignEphemeralInProgress: 0,
  autoDesignRunAndLoad: function (options = {}) {
    /*
    Wrapper for autoDesignRunAndLoadV1 for calling through SDK with some differences:
    - provide arguments as an options object instead of separate arguments
    - control facets with a toggle rather than passing them in directly
    */
    return SceneHelper.autoDesignRunAndLoadV1(
      options.setbackDistance,
      options.systemTemplatesJson,
      options.mappingConfigDistributor,
      options.includeFacets && editor.filter('type', 'OsFacet').length > 0
        ? GeoJSONHelper.toGeoJSON(editor)
        : undefined,
      options.lonlat,
      options.customData,
      options.runShading,
      options.runCalcs,
      options.dataSource
    )
  },
  autoDesignRunAndLoadV1: function (
    setbackDistance,
    systemTemplatesJson,
    mappingConfigDistributor,
    facetsGeoJson,
    lonlat,
    customData,
    runShading,
    runCalcs,
    dataSource
  ) {
    SceneHelper.autoDesignEphemeralInProgress += 1

    return SceneHelper.autoDesignRunAndLoadWorker(
      setbackDistance,
      systemTemplatesJson,
      mappingConfigDistributor,
      facetsGeoJson,
      lonlat,
      customData,
      runShading,
      runCalcs,
      dataSource
    )
      .catch((e) => {
        Designer.showNotification('Auto-Design failed.', 'error', { error: e })
        throw e
      })
      .finally(() => {
        SceneHelper.autoDesignEphemeralInProgress -= 1
      })
  },

  handleAutoDesignDesignData: async function (designData, options) {
    const hasOsModuleChildren = options.runShading || options.runCalcs

    if (hasOsModuleChildren) {
      // When results include OsModule objects as children of OsModuleGrids we need to prevent certain
      // default behaviors which we achieve by simulating loading the scene using sceneIsLoading. We
      // clean this up blow once loading is finished.
      window.editor.sceneIsLoading = true
    }

    const addedSystems = []

    if (true) {
      // calculate the offset between the auto-designed scene and the currently-loaded scene.
      var autoDesignedScenePositionInCurrentScene = new THREE.Vector2().fromArray(
        SceneHelper.positionForLonLatUsingSceneOrigin(
          designData?.object?.userData?.sceneOrigin4326,
          editor.scene.sceneOrigin4326
        )
      )

      const commandUUID = Utils.generateCommandUUIDOrUseGlobal()
      editor.getSystems().forEach((s) => {
        editor.execute(new window.RemoveObjectCommand(s, true, undefined, commandUUID))
      })

      // special hack to fix positioning. This should just work by using "matrix" property but
      // various hacks have now broken this standard ThreeJS functionality.
      SceneHelper.__flag_prevent_resetObjectPositionToCentroidOfCells = true

      var loader = new THREE.ObjectLoader()

      // If new design was based on a higher elevation, we need to reduce the elevation of the returned objects
      // to be consistent with the lower elevation of the current scene
      var newDesignTerrainZ = designData.object.userData.terrainPosition[2] || 0

      // Ensure if we have designed with ground-texture we do not crash, since editor.scene.terrainPosition is not set
      var currentTerrainPositionZ = editor.scene.terrainPosition?.z || 0
      var terrainZDelta = currentTerrainPositionZ - newDesignTerrainZ

      const serializedSystems = designData.object.children.filter((c) => c.type === 'OsSystem')

      for (const newSystemSerialized of serializedSystems) {
        const newSystem = loader.parse({ object: newSystemSerialized })
        addedSystems.push(newSystem)

        if (newSystem.isSpecsReloadRequired()) {
          await new Promise((resolve) => {
            const onReloadSuccess = () => {
              newSystem.refreshDesignComponentSpecs()
              resolve()
            }
            window.AccountHelper.loadComponentSpecs(onReloadSuccess)
          })
        }

        // Before creating the OsSystem inject any custom data
        // For now all systems will receive the same custom data
        if (options.custom_data) {
          // inject keys/values from customData into newSystem.custom_data without overwriting the whole value
          Object.assign(newSystem.custom_data, options.custom_data)
        }

        editor.execute(new AddObjectCommand(newSystem, editor.scene, false, commandUUID))

        // adjust elevation of all objects (currently only OsModuleGrids) to match terrain Z
        // beacuse this may have been designed with a different assumed terrain Z
        // @TODO: We could apply this directly to the designData before loading into the scene which would be faster to render
        // by avoiding multiple steps, but it would require updating matrix directly so leave this as a future optimization.
        //
        // also adjust xy position to align current scene with auto-designed scene which will often be slightly different
        // due to various reasons, such as designing on cached data at a slightly different location
        if (terrainZDelta || autoDesignedScenePositionInCurrentScene.x || autoDesignedScenePositionInCurrentScene.y) {
          newSystem.children.forEach((child) => {
            if (child.type === 'OsModuleGrid') {
              let newPosition = child.position.clone()
              newPosition.z += terrainZDelta

              newPosition.x += autoDesignedScenePositionInCurrentScene.x
              newPosition.y += autoDesignedScenePositionInCurrentScene.y

              editor.execute(new SetPositionCommand(child, newPosition))
            }
          })
        }
      }

      SceneHelper.__flag_prevent_resetObjectPositionToCentroidOfCells = false
    } else {
      // assume editor.loadScene() fires all necessary signals
      // @TODO: If we can somehow confirm that the scene itself is already up-to-date (i.e. DSM & Terrain loaded correclty)
      // then we could avoid loading the whole scene and just delete old systems and load new systems.
      // @TODO: If creating the whole scene from scratch, we need to retain any critical parts such as Facets, etc.
      // editor.loadScene(designData, loadSceneParams)
    }

    Designer.showNotification(window.translate('Auto-Design Loaded'))

    if (hasOsModuleChildren) {
      // @TODO: Remove this workaround which is required to make buildableCells visible immediately after auto-design
      // Call mg.refreshFromChildren() instead of mg.draw() because this also synchronizes the existing OsModule children
      // with activeCells. It will also be updated to ensure the any shadingOverride and/or shadingOverrideRaw values are
      // retained when we replace the loaded OsModule children with the newly generated OsModule children from mg.draw().
      addedSystems.forEach((system) => {
        system.moduleGrids().forEach((mg) => {
          mg.refreshFromChildren(editor, true)
        })

        // Currently if sceneIsLoading === true the OsSystem constructor will not automatically apply userData
        // so we will call this manually here. In future it might be nice to remove this special condition in the
        // OsSystem constructor so it behaves the same way during initial scene loading and when loading from an
        // ephemeral loaded scene, but the current approach avoids the unknowns/risk of changing the constructor
        // by ensuring we only change the way the constructor behaves when loading from an ephemeral scene.
        system.applyUserData()
      })

      window.editor.sceneIsLoading = false
    } else {
      // We are not loading OsModule children so we need to manually trigger drawing of the module grids
      addedSystems.forEach((system) => {
        system.moduleGrids().forEach((mg) => mg.draw())
      })
    }

    // If we request both runShading and runCalcs and we confirm they competed then systems will return with
    // no further calcs required.
    // We check two things:
    //  - All Module Grids have beamAccess
    //  - All Modules have shadingOverride
    const hasShadingValues =
      addedSystems.every((s) => s.moduleGrids().every((mg) => mg.beamAccess !== undefined)) &&
      addedSystems.every((s) => s.getModules().every((m) => !!m.shadingOverride?.length))

    const hasCalcsValues = addedSystems.every((s) => !!s.output?.annual)

    const shadingFailed = options.runShading && !hasShadingValues
    const calcsFailed = options.runCalcs && !hasCalcsValues

    if (shadingFailed || calcsFailed) {
      window.captureException(
        new Error(
          `Auto-design failed to complete shading or calculations (project_id: ${WorkspaceHelper.getProjectId()}, hasShadingValues: ${hasShadingValues}, hasCalcsValues: ${hasCalcsValues})`
        )
      )
    }

    if (!options.runShading || !options.runCalcs || !hasShadingValues || !hasCalcsValues) {
      // Shading or initial calcs not yet completed or not requested
      addedSystems.forEach((s) => {
        Designer.requestSystemCalculations(s)
      })
    }

    editor.select(addedSystems[0])

    // @TODO: Check if the design includes any component activations that have not yet been loaded and refresh components
    // This can happen if auto-design auto-activates a component, then it will be included in the design but it wil not
    // yet be loaded into studio. We can simply reload all components when we detect this.

    // return a value that can be used to track the status and completion of auto-design
    return {
      status: 'success',
      systems: addedSystems.map((s) => ({
        uuid: s.uuid,
        name: s.getName(),
        moduleQuantity: s.moduleQuantity(),
        kwStc: s.kwStc(),
        batteryTotalKwh: s.batteryTotalKwh(),
        output: { annual: s.output?.annual },
      })),
    }
  },

  autoDesignRunAndLoadWorker: function (
    setbackDistance,
    systemTemplatesJson,
    mappingConfigDistributor,
    facetsGeoJson,
    lonlat,
    customData,
    runShading,
    runCalcs,
    dataSource
  ) {
    // @TODO: Require Google 3D enabled

    // setbackDistance should be a dict.
    // Legacy feature also allows setbackDistance should be a list with either 4 values or 1 value
    // or leave as undefined to use the auto-applied values
    var setbackDistancePayload = undefined
    var setbackDistanceArray = undefined

    if (setbackDistance === undefined || setbackDistance === null) {
      // not specified, allow API to dynamically determine
    } else if (setbackDistance.constructor === Object) {
      setbackDistancePayload = JSON.stringify(setbackDistance)
    } else if (setbackDistance.constructor === Array) {
      if (setbackDistance.length === 1) {
        setbackDistanceArray = [setbackDistance[0], 0, 0, 0]
      } else if (setbackDistance.length === 4) {
        setbackDistanceArray = setbackDistance
      }
    } else if (typeof setbackDistance === 'number') {
      setbackDistanceArray = [setbackDistance[0], 0, 0, 0]
    }

    if (setbackDistanceArray && !setbackDistancePayload) {
      setbackDistancePayload = setbackDistanceArray?.join(',')
    }

    if (!lonlat) {
      // If lonlat not supplied a) if any panels, center on them b) if no panels, center on viewport center
      // This ensures the panels don't accidentally drift onto the wrong roof.
      // If the wrong roof was designed, you need to delete the panels to focus on a new roof.
      if (editor.selectedSystem?.moduleGrids().length) {
        var panelsCentroid = editor.selectedSystem.getCentroid()
        lonlat = window.editor.viewport
          .lonLatFor3DPositionUsingSceneOrigin(
            panelsCentroid,
            new window.THREE.Vector2().fromArray(editor.scene.sceneOrigin4326)
          )
          .toArray()
      } else {
        lonlat = window.editor.viewport
          .lonLatAtAtViewportCenterFrom3DCameraAndSceneOrigin(
            new window.THREE.Vector2().fromArray(editor.scene.sceneOrigin4326)
          )
          .toArray()
      }
    }

    var loadSceneParams = {
      id: WorkspaceHelper.project.id,
      // pull from projectForm??
      timezoneOffset: editor.scene.userData.timezoneOffset,
      roofTypeId: editor.scene.userData.roofTypeId,
    }

    Designer.showNotification(window.translate('Auto-Design...'))

    var url =
      API_BASE_URL +
      'orgs/' +
      window.getStorage().getItem('org_id') +
      `/projects/` +
      WorkspaceHelper.project.id +
      '/auto_design_ephemeral/'

    // Use dataSource if supplied, otherwise auto-detect
    if (!dataSource) {
      if (MapHelper.activeMapInstance.mapType === 'Nearmap3D') {
        dataSource = {
          type: 'Nearmap3D',
          variation_data: { capture_date: MapHelper.activeMapInstance?.mapData?.oblique?.capture_date || null },
        }
      } else if (MapHelper.activeMapInstance.mapType === 'Google3D') {
        dataSource = { type: 'Google3D', variation_data: {} }
      } else {
        dataSource = undefined
      }
    }

    return fetch(url, {
      headers: window.Utils.tokenAuthHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        data_source: dataSource,
        module_id: editor.selectedSystem?.moduleType().id || null,
        setback_config: setbackDistancePayload,
        system_templates: systemTemplatesJson || null,
        mapping_config_distributor: mappingConfigDistributor,
        facets_geojson: facetsGeoJson,
        lonlat: lonlat ? lonlat : null,
        run_shading: !!runShading,
        run_calcs: runCalcs || null,
      }),
    })
      .then((response) => {
        return response.json().then((data) => {
          return [response.ok, response.status, data]
        })
      })
      .then(([isOk, status, data]) => {
        if (!isOk) {
          const msg = data?.message || (typeof data === 'string' ? data : 'Unspecified Error')
          throw new Error(msg, { cause: data })
        }
        return data
      })
      .then(async (designData) => {
        /*

        Some known/suspected issues with calling handleAutoDesignDesignData() before populating
        AccountHelper.loadedData (and there may be others too):

        - The scene may not be setup yet, so the sceneIsLoading flag will not be set and the OsSystem constructor
          (or other code that depends on sceneIsLoading) may behave incorrectly.

        - Before waiting on terrain, wait for AccountHelper.isLoaded()===true because otherwise waiting for terrain
          may abort early because the scene may not even be setup.
          
        */
        await AccountHelper.waitUntilLoadedDataIsReady()
        await editor.getTerrainWhenReady()
        return SceneHelper.handleAutoDesignDesignData(designData, { custom_data: customData, runShading, runCalcs })
      })
      .catch((error) => {
        console.error(error)

        // clear sceneIsLoading just in case it was not cleared above due to an exception
        window.editor.sceneIsLoading = false

        throw error
      })
  },

  setGroundElevationBasedOnFacets() {
    /* We assume the lowest facet point is a standard height above the ground. This is very inaccurate
    but at least it generally results in approximately realistic elevations above the ground, plus
    the ground elevation can now be manually adjusted.
    */
    var STANDARD_GUTTER_HEIGHT_ABOVE_GROUND = 3.3

    var lowestZ = null

    var ground = editor.getGround()
    if (!ground) {
      return
    }

    editor.filter('type', 'OsFacet').forEach((facet) => {
      facet.vertices.forEach((vertex) => {
        if (!lowestZ || vertex.position.z < lowestZ) {
          lowestZ = vertex.position.z
        }
      })
    })

    if (lowestZ !== null) {
      ground.position.z = lowestZ - STANDARD_GUTTER_HEIGHT_ABOVE_GROUND
    }
  },

  autoGenerateFacets: function (autoFacetsGeoJson) {
    // @TODO: Require Google 3D enabled

    var loadSiteModel = (siteModelGeoJson) => {
      SceneHelper.deleteNonSystemObjects()

      SceneHelper.loadFacetsFromSiteModelGeoJson(siteModelGeoJson)

      SceneHelper.setGroundElevationBasedOnFacets()

      Designer.showNotification(window.translate('Facets loaded.'))

      // @TODO: Replace with a more sensible signal
      editor.signals.sceneGraphChanged.dispatch()
    }

    if (autoFacetsGeoJson) {
      loadSiteModel(autoFacetsGeoJson)
    } else {
      Designer.showNotification(window.translate('Generating facets...'))

      var sceneOrigin4326 = editor.scene.sceneOrigin4326
      var url =
        API_BASE_URL +
        'orgs/' +
        window.getStorage().getItem('org_id') +
        `/projects/` +
        WorkspaceHelper.project.id +
        '/auto_facets/'

      return fetch(url, {
        headers: window.Utils.tokenAuthHeaders({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      })
        .then((response) => response.json())
        .then((siteModelGeoJson) => {
          loadSiteModel(siteModelGeoJson)
        })
        .catch((error) => {
          console.log('Error autoGenerateMaxFit()', error)
        })
    }
  },

  loadObj: function () {
    throw new Error('OBJ needs a small tweak to be re-enabled. It has not been adapted to the new OsTerrain class')
    /*
    var onProgress = function (xhr) {
      if (xhr.lengthComputable) {
        var percentComplete = (xhr.loaded / xhr.total) * 100
        console.log(Math.round(percentComplete, 2) + '% downloaded')
      }
    }

    var onError = function () {}

    var manager = new THREE.LoadingManager()
    manager.addHandler(/\.dds$/i, new DDSLoader())

    // comment in the following line and import TGALoader if your asset uses TGA textures
    // manager.addHandler( /\.tga$/i, new TGALoader() );

    // new MTLLoader(manager).setPath('Mesh/').load('Mesh.mtl', function(materials) {
    new MTLLoader(manager).setPath('Mesh/').load('Mesh.mtl', function (materials) {
      materials.preload()

      new OBJLoader(manager)
        .setMaterials(materials)
        .setPath('Mesh/')
        .load(
          'Mesh.obj',
          function (object) {
            // How to we set castShadow and receiveShadow to true for obj?

            // object.rotation.x = -1
            // object.position.y = -195
            object.userData.excludeFromExport = true
            object.position.z = -20
            // object.scale.fromArray([5, 5, 5])
            editor.scene.add(object)
            editor.scene.terrain = object
            editor.render()
          },
          onProgress,
          onError
        )
    })
    */
  },

  loadGlbIntoTargetCache: {},
  loadGlbIntoTarget: function (url, target, headers, onLoad, onError) {
    var loadFromArrayBuffer = function (arrayBuffer) {
      var loader = new THREE.GLTFLoader()
      var path = ''
      loader.parse(
        arrayBuffer,
        path,
        function (result) {
          result.scene.name = 'LoadedTreeModel'
          target.add(result.scene)
          if (onLoad) {
            onLoad(result.scene)
          }
          editor.render()
        },
        function (error) {
          console.error(error)
        }
      )
    }

    if (SceneHelper.loadGlbIntoTargetCache[url]) {
      loadFromArrayBuffer(SceneHelper.loadGlbIntoTargetCache[url])
      return
    }

    var request = new XMLHttpRequest()

    request.addEventListener('load', function (event) {
      SceneHelper.loadGlbIntoTargetCache[url] = event.target.response
      loadFromArrayBuffer(event.target.response)
    })

    request.addEventListener(
      'error',
      function (event) {
        console.warn('Glb loader error', event)

        if (onError) {
          onError(event)
        }
      },
      false
    )

    request.open('GET', url, true)

    request.responseType = 'arraybuffer'

    if (headers) {
      Object.keys(headers).forEach((key) => {
        request.setRequestHeader(key, headers[key])
      })
    }

    request.send(null)
  },

  loadObjIntoTarget: function (modelPath, modelBaseName, target, onLoaded) {
    var onProgress = function (xhr) {
      if (xhr.lengthComputable) {
        var percentComplete = (xhr.loaded / xhr.total) * 100
        console.log(Math.round(percentComplete, 2) + '% downloaded')
      }
    }

    var onError = function () {}

    var manager = new THREE.LoadingManager()
    manager.addHandler(/\.dds$/i, new DDSLoader())

    // comment in the following line and import TGALoader if your asset uses TGA textures
    // manager.addHandler( /\.tga$/i, new TGALoader() );

    new MTLLoader(manager).setPath(modelPath).load(modelBaseName + '.mtl', function (materials) {
      materials.preload()

      new OBJLoader(manager)
        .setMaterials(materials)
        .setPath(modelPath)
        .load(
          modelBaseName + '.obj',
          function (object) {
            if (onLoaded) {
              onLoaded(object)
            }

            target.add(object)
            editor.render()
          },
          onProgress,
          onError
        )
    })
  },

  objectAffectsDepth: (object, strict) => {
    //OsClipper affects terrain so it which affects depth, even though it does not affect shading directly

    if (object.ghostMode && object.ghostMode()) {
      return false
    }

    return (
      (object.type === 'OsDesignerScene' && !strict) ||
      // Must show system/grid to show modules which are it's children
      (object.type === 'OsSystem' && !strict) ||
      object.type === 'OsModuleGrid' ||
      object.type === 'OsTerrain' ||
      (object.type === 'OsModule' && object.active === true) ||
      object.type === 'OsFacet' ||
      // Old: Assume that OsGroup will almost always contain objects which affect shading, so include it without more checks
      // New: Ignore OsGroup because it is never actually used as a persistent object in a scene, it is only ever
      // used for temporary grouping during operations.
      // object.type === 'OsGroup' ||
      object.type === 'OsFacetMesh' ||
      object.type === 'OsObstruction' ||
      object.type === 'OsHorizon' ||
      object.type === 'OsTree' ||
      object.type === 'OsClipper' ||
      (object.parent && object.parent.type === 'OsTree') ||
      (object.parent && object.parent.parent && object.parent.parent.type === 'OsTree')
    )
  },

  objectOccludesTheSun: function (object, strict) {
    /*
    Filter objects to use for creating the DSM.
    Currently the only exception from objectAffectsDepth() is OsCliper which affects it indirectly (by modifing the DSM)
    but not directly, so it should not affect the depth map used in raytracing.
    */
    return object.type !== 'OsClipper' && SceneHelper.objectAffectsDepth(object, strict)
  },

  objectAffectsSceneBoundingBox: (object) => {
    return (
      object.type === 'OsModuleGrid' ||
      object.type === 'OsTerrain' ||
      (object.type === 'OsModule' && object.active === true && object.getSystem() === editor.selectedSystem) ||
      object.type === 'OsFacet' ||
      object.type === 'OsFacetMesh' ||
      object.type === 'OsObstruction' ||
      object.type === 'OsClipper' ||
      object.type === 'OsTree'
    )
  },

  objectIsPartOfDesign: (object) => {
    return (
      object.type === 'OsModuleGrid' ||
      (object.type === 'OsModule' && object.active === true && object.getSystem() === editor.selectedSystem) ||
      object.type === 'OsFacet' ||
      object.type === 'OsFacetMesh' ||
      object.type === 'OsObstruction' ||
      object.type === 'OsClipper' ||
      object.type === 'OsTree'
    )
  },

  sceneBoundingBoxCache: {},
  getSceneBoundingBoxCacheInvalidate: (cacheKey) => {
    SceneHelper.sceneBoundingBoxCache = {}
  },
  getSceneBoundingBoxWithCache: (filterFunc, filterFuncCacheKey = 'noFilterFunc') => {
    // It is important that filterFuncCacheKey matches the filterFunc, otherwise the cache will not be used correctly
    // It is critical that all object added/removed/modified in the scene are also invalidated in the cache
    if (!SceneHelper.sceneBoundingBoxCache) {
      SceneHelper.sceneBoundingBoxCache = {}
    }

    if (!SceneHelper.sceneBoundingBoxCache[filterFuncCacheKey]) {
      SceneHelper.sceneBoundingBoxCache[filterFuncCacheKey] = SceneHelper.getSceneBoundingBox(filterFunc)
    }

    return SceneHelper.sceneBoundingBoxCache[filterFuncCacheKey]
  },
  getSceneBoundingBox: (filterFunc) => {
    // Include all objects which affect the depth map
    var box = new THREE.Box3()
    var occlusionObjects = editor.filterObjects(filterFunc || SceneHelper.objectAffectsSceneBoundingBox)

    occlusionObjects.forEach((o) => {
      box.union(new THREE.Box3().setFromObject(o))
    })

    return box

    // Old: Only terrain
    // var terrain = editor.getTerrain()
    // return terrain.geometry.boundingBox.clone().translate(terrain.position)
  },

  gridPositionToCell: function (
    rasterPosition,
    rasterSize,
    rasterResolution,
    positionWorld,
    funcX,
    funcY,
    rasterRotationZ,
    allowOutOfRasterBounds = false
  ) {
    var width = rasterSize.x
    var height = rasterSize.y
    var cols = rasterResolution.x
    var rows = rasterResolution.y
    var dx = width / cols
    var dy = (-1 * height) / rows // flip y direction

    // Account for terrain rotation, if set.
    // Convert the (world) position into the coordinate space of the terrain
    var position = rasterRotationZ
      ? Utils.rotateAroundPoint(positionWorld.clone(), new THREE.Vector3(0, 0, 1), -1 * rasterRotationZ, rasterPosition)
      : positionWorld

    // Account for terrain position not necessarily being 0,0,0
    var gridCell = new THREE.Vector2(
      (position.x - rasterPosition.x) / dx + cols / 2,
      (position.y - rasterPosition.y) / dy + rows / 2
    )

    if (!allowOutOfRasterBounds && (gridCell.x > cols || gridCell.y > rows)) {
      throw new Error('Outside raster grid bounds!')
    }

    if (funcX) {
      gridCell.x = funcX(gridCell.x)
    }
    if (funcY) {
      gridCell.y = funcY(gridCell.y)
    }
    return gridCell
  },

  terrainPositionToCell: function (terrain, position, funcX, funcY) {
    var bb = terrain.geometry.boundingBox
    if (!bb) {
      terrain.geometry.computeBoundingBox()
      bb = terrain.geometry.boundingBox
    }
    var width = bb.max.x - bb.min.x
    var height = bb.max.y - bb.min.y
    var cols = terrain.rasterResolution.x
    var rows = terrain.rasterResolution.y
    return this.gridPositionToCell(
      terrain.position,
      new THREE.Vector3(width, height, 0),
      terrain.rasterResolution,
      position,
      funcX,
      funcY,
      terrain.rotation.z,
      false
    )
  },

  rowColToPosition: function (rasterPosition, rasterSize, rasterResolution, colIndex, rowIndex) {
    return new THREE.Vector3(
      rasterSize.x * (colIndex / rasterResolution.x - 0.5) + rasterPosition.x,

      // need to flip rowIndex, not really sure why
      rasterSize.y * (0.5 - rowIndex / rasterResolution.y) + rasterPosition.y,
      0
    )
  },

  rasterIndexToRowCol: function (rasterIndex) {
    var terrain = editor.getTerrain()
    return new THREE.Vector2(
      rasterIndex % terrain.rasterResolution.x,
      Math.floor(rasterIndex / terrain.rasterResolution.x)
    )
  },

  rowColToRasterIndex: function (colIndex, rowIndex, rasterCols) {
    if (typeof rasterCols === 'undefined') {
      rasterCols = editor.getTerrain().rasterResolution.x
    }
    return rowIndex * rasterCols + colIndex
  },

  pointOnObj: function (position) {
    // Lookup terrain DSM directly to avoid slow geometry intersection calcs
    // only the x and y coordinates of position are used, z coordinate is ignored

    // @TODO: Lookup DSM directly instead of geometry.vertices (which is still pretty quick)
    // 1. find cell in dsm
    // 2. lookup value

    if (!editor.getTerrain()) {
      throw new Error('Called pointOnObj() with no editor.getTerrain() available')
    }

    var terrain = editor.getTerrain()
    var gridCellFractional = this.terrainPositionToCell(terrain, position)

    // Interpolate between adjacent cells
    var colLeft = Math.floor(gridCellFractional.x)
    var rowBottom = Math.floor(gridCellFractional.y)

    var colRight = colLeft + 1
    var rowTop = rowBottom + 1

    var colLeftDistance = gridCellFractional.x - colLeft
    var rowBottomDistance = gridCellFractional.y - rowBottom

    var colRightWeighting = colLeftDistance
    var colLeftWeighting = 1 - colRightWeighting

    var rowTopWeighting = rowBottomDistance
    var rowBottomWeighting = 1 - rowTopWeighting

    var dataIndexes = {
      tl: this.rowColToRasterIndex(colLeft, rowTop, terrain.rasterResolution.x),
      tr: this.rowColToRasterIndex(colRight, rowTop, terrain.rasterResolution.x),
      br: this.rowColToRasterIndex(colRight, rowBottom, terrain.rasterResolution.x),
      bl: this.rowColToRasterIndex(colLeft, rowBottom, terrain.rasterResolution.x),
    }

    var elevations = {}
    for (var key in dataIndexes) {
      elevations[key] = terrain.rasterData[dataIndexes[key]]
    }

    // Calculate level of influence separately for bottom/top and left/right
    // Do not use inverse distance weighting because we want the influence of other points
    // to drop to zero when on the other edge of the cell.

    var weightings = {
      tl: rowTopWeighting * colLeftWeighting,
      tr: rowTopWeighting * colRightWeighting,
      br: rowBottomWeighting * colRightWeighting,
      bl: rowBottomWeighting * colLeftWeighting,
    }

    var weightedElevation = 0
    var sumOfWeightings = 0

    for (var key in weightings) {
      weightedElevation += elevations[key] * weightings[key]
      sumOfWeightings += weightings[key]
    }
    var elevationEstimate = weightedElevation / sumOfWeightings

    return new THREE.Vector3(position.x, position.y, elevationEstimate + terrain.position.z)
  },

  pointOnObjUsingGeometryIntersection: function (position) {
    if (!editor.getTerrain()) {
      throw new Error('Called pointOnObj() with no editor.getTerrain() available')
    }
    var terrain = editor.getTerrain()
    var origin = new THREE.Vector3(position.x, position.y, 100)
    var direction = new THREE.Vector3(0, 0, -1)
    var raycaster = new THREE.Raycaster(origin, direction, 0, 100000)
    raycaster.linePrecision = 1.0
    var intersects = raycaster.intersectObjects(terrain.getMeshes(), false)
    if (!intersects || intersects.length === 0) {
      throw new Error('No terrain intersection found in pointOnObj()')
    }
    var intersectionPoint = intersects[0].point
    return intersectionPoint
  },

  objectCoversPositionXY: function (obj, position) {
    var origin = new THREE.Vector3(position.x, position.y, 100)
    var direction = new THREE.Vector3(0, 0, -1)
    var raycaster = new THREE.Raycaster(origin, direction, 0, 100000)
    raycaster.linePrecision = 1.0
    var intersects = raycaster.intersectObject(obj, false)
    return intersects && intersects.length > 0
  },

  orientationAtPosition: window.Utils.cacheFunction(
    function (position, radiusX, radiusY, calculatePointOffsetFromPlane) {
      var points = [SceneHelper.pointOnObj(position)]

      //@TODO: Flip if landscape
      if (!radiusX) {
        radiusX = 0.4
      }
      if (!radiusY) {
        radiusY = 0.6
      }

      for (var i = -radiusX; i <= radiusX; i += 2 * radiusX) {
        for (var j = -radiusY; j <= radiusY; j += 2 * radiusY) {
          points.push(SceneHelper.pointOnObj(new THREE.Vector3(position.x + i, position.y + j, 0)))
        }
      }
      // console.log('points len', points.length)
      var centroid = SceneHelper.centroidOfPositions(points)

      var positions = points.map(function (point) {
        return point.toArray()
      })
      var plane = OsFacet.planeFromPoints(positions)
      var azimuth = OsFacet.azimuthForNormal(plane.normal)
      var slope = OsFacet.slopeForNormal(plane.normal)

      var results = {
        centroid: centroid,
        plane: plane,
        azimuth: azimuth,
        slope: slope,
      }

      if (calculatePointOffsetFromPlane) {
        results.pointOffsets = positions.map(function (p) {
          return plane.distanceToPoint(new THREE.Vector3().fromArray(p))
        })
      }

      return results
    },
    function (position, radiusX, radiuxY, calculatePointOffsetFromPlane) {
      var cacheResolutionPointsPerMeter = 20

      return (
        Math.round(position.x * cacheResolutionPointsPerMeter) +
        '_' +
        Math.round(position.y * cacheResolutionPointsPerMeter) +
        '_' +
        Boolean(calculatePointOffsetFromPlane)
      )
    }
  ),

  snapModuleGridToGroundLevel: function (moduleGrid, restrictToGroundMountedPanels = true) {
    // by default, this snapping only applies to module grids that are mounted on the ground
    if (restrictToGroundMountedPanels && moduleGrid.panelPlacement !== 'ground') return

    // STEP 1: determine the base (lowest extent)  of the module grid
    // the base must account for the ground clearance
    // if ground clearance > 0, the base of the module grid has an offset from ground level
    // ground clearance = 0, the base of the module has sits flushed on ground level

    // STEP 2: once we determine the base position, we check if it's below ground level
    // if there is no terrain present (no 3D view), ground level is z=0
    // if there is a terrain, ground level will be the terrain elevation at the base of the module grid

    // STEP 3: if the base of the module grid is below ground level
    // raise the module grid's Z position so that the base is at ground level

    let lowestPoint = moduleGrid.getLowestPoint()
    if (!lowestPoint) return

    let lowestZWithGroundClearance = lowestPoint.z - moduleGrid.groundClearance()
    let groundLevel = 0
    let terrain = editor.getTerrain()
    if (terrain) {
      // the terrain model covers an area of n x n, with the middle at the scene origin (0, 0, 0)
      // we make sure the lowest point is within the XY bounds of the terrain, otherwise
      // pointOnObj() will crash
      let lowestPointWithinTerrainBounds =
        Math.abs(lowestPoint.x) <= terrain.size[0] / 2 && Math.abs(lowestPoint.y) <= terrain.size[1] / 2
      // instead of the default z=0 ground level, we query the terrain model to get the DSM elevation
      // below the lowest point of the module grid and use that as the ground level
      groundLevel = lowestPointWithinTerrainBounds ? this.pointOnObj(lowestPoint).z : 0
    }
    if (lowestZWithGroundClearance < groundLevel) {
      moduleGrid.position.z += terrain ? groundLevel - lowestZWithGroundClearance : Math.abs(lowestZWithGroundClearance)
      editor.signals.objectChanged.dispatch(moduleGrid, 'position')
    }
  },

  orientModuleGrid: function (moduleGrid, azimuth, slope) {
    Utils.applyOrientation(moduleGrid, azimuth, slope)
  },

  autoOrient: function (position) {
    var result = SceneHelper.orientationAtPosition(position)
    var mg = new OsModuleGrid({
      position: new THREE.Vector3()
        .fromArray(result.centroid)
        .add(new THREE.Vector3(0, 0, MODULE_GRID_OFFSET_FROM_TERRAIN)),
      cellsActive: ['0,0', '-1,0', '1,0'],
    })
    SceneHelper.orientModuleGrid(mg, result.azimuth, result.slope)
  },

  autoOrientModuleGridDebounced: window.Utils.debounce(function (moduleGrid) {
    SceneHelper.autoOrientModuleGrid(moduleGrid)
  }, 20),

  autoOrientModuleGrid: function (moduleGrid, inflationFactor) {
    if (moduleGrid.facet && !moduleGrid.facet.isNonSpatial()) {
      console.log('using facet for orientation')
      return
    }

    return this.autoOrientModuleGridWithLeastSquares(moduleGrid, {
      iterations: null,
      numPoints: null,
      distanceThresholdForInliers: null,
      drawNodes: false,
      inflationFactor,
      pointSelectionMethod: null,
    })
  },

  concatUnique: function (collection, points) {
    points.forEach((point) => {
      if (collection.every((pointInCollection) => !pointInCollection.equals(point))) {
        collection.push(point)
      }
    })
  },

  AUTO_ORIENT_DEFAULTS: {
    /*
    more changes to get a good answer
    */
    iterations: 100,

    /*
    fewer points is more robust vs outliers but is more volatile, ignores lots of data
    */
    numPointsFraction: 1 / 3,

    /*
    large value cannot fine-tine results, small value may not find enough inliers to be reliable
    */
    distanceThresholdForInliers: 0.05,

    /*
    where to look for extra points other than corners of modules. 0.5 = half way between center and corner. 1.0 = no effect, same as corner points.
    */
    inflationFactor: 1,

    /* only use new estimate if either slopr or azimuth changed more than this magnitude (in degrees) */
    azimuthChangeThreshold: 0.1,

    /* only use new estimate if either slopr or azimuth changed more than this magnitude (in degrees) */
    slopeChangeThreshold: 0.1,

    drawNodes: false,

    /* New method: (value=2) uses 3x3 grid of points for every mondule */
    /* Old method (value=1) used 4 corner points and center */
    pointSelectionMethod: 2,

    /*
    Calculate and track Mean Absolute Error for distance from points on best-fit plane against raw values
    */
    showMAE: false,
  },

  samplePointsFromModulesV1: function (moduleGrid, modules, inflationFactor) {
    var corners = []

    // We use concatUnique to omit any points/corners/centers already added by other modules

    modules.forEach(function (moduleObject) {
      // @TODO: inset from edges in case the panel is positioned near a wall or obstruction which could massively skew it
      this.concatUnique(corners, moduleGrid.pointsForModule(moduleObject, [inflationFactor[0], inflationFactor[1]]))

      this.concatUnique(
        corners,
        // 'edges' argument avoids adding a duplicate point at cell center from 2 calls to moduleGrid.pointsForModule()
        moduleGrid.pointsForModule(moduleObject, [0.5 * inflationFactor[0], 0.5 * inflationFactor[1]], 'edges')
      )
    }, this)

    return corners
  },

  samplePointsFromModulesV2: function (moduleGrid, modules) {
    /*
    Compile a grid of points on the module with a variable number of rows and columns.

    Our strategy is to increase point density when number of modules is small and reduce when there are lots of panels

    Just add all the points including duplicates initially then remove duplicates in a single pass at the end
    */

    var cellBounds = moduleGrid.getBounds(true)
    var activeModuleGridCols = cellBounds ? cellBounds[2] - cellBounds[0] + 1 : 1
    var activeModuleGridRows = cellBounds ? cellBounds[3] - cellBounds[1] + 1 : 1

    // X x Y gives are target or around (X-1)*(Y-1) points (after removing overlapping points)
    // 10 x 6 gives around 54 points
    var targetTotalGridCols = 10
    var targetTotalGridRows = 6

    // ceil to err towards including more points
    var sampleColsPerPanelX = Math.ceil(targetTotalGridCols / activeModuleGridCols)
    var sampleRowsPerPanelY = Math.ceil(targetTotalGridRows / activeModuleGridRows)

    var points = []

    // We use concatUnique to omit any points/corners/centers already added by other modules

    modules.forEach(function (moduleObject) {
      points = points.concat(moduleObject.getPointsGridOnModule(sampleColsPerPanelX, sampleRowsPerPanelY))
    }, this)

    var pointsUnique = []
    this.concatUnique(pointsUnique, points)

    return pointsUnique
  },

  computeModuleGridPositionFromDSM: function (moduleGrid) {
    // unlike the ray-plane intersection method used in computeModuleGridAzimuthSlopeFromDSM(),
    // this method looks up the DSM elevation value at the module grid's position
    // it can be prone to noise, if for example, the MG's position (centroid, technically)
    // happens to be on a chimney or obstruction or just an abnormal dip/spike in the DSM
    moduleGrid.updateMatrix()
    moduleGrid.updateMatrixWorld()
    const raiseOffset = new THREE.Vector3(0, 0, MODULE_GRID_OFFSET_FROM_TERRAIN)
    const moduleGridPosition = moduleGrid.position.clone()
    const terrainUnderCentroid = SceneHelper.pointOnObj(moduleGridPosition)
    return moduleGridPosition.add(terrainUnderCentroid.sub(moduleGridPosition)).add(raiseOffset)
  },

  computeModuleGridAzimuthSlopeFromDSM: function (moduleGrid, opts = {}) {
    /*
      RANSAC 3D Plane Fitting Paramaters
      Iterations is probably the most important factor, more iterations is slower but often more accurate/robust.
      To learn how numPoints and distanceThresholdForInliers you will need to learn about ransac 3D plane fitting.
      The quick idea is that we get a sample of points from all available points (numPoints) we build a plane with
      those points, then we see how many points are very closely fitted to that plane (within
      distanceThresholdForInliers). Run many times (iterations) and keep the result with the most points falling
      inside distanceThresholdForInliers.
    */

    let { iterations, numPoints, distanceThresholdForInliers, drawNodes, inflationFactor, pointSelectionMethod } = opts

    if (!inflationFactor) {
      inflationFactor = [
        SceneHelper.AUTO_ORIENT_DEFAULTS.inflationFactor,
        SceneHelper.AUTO_ORIENT_DEFAULTS.inflationFactor,
      ]
    }

    let modules = Object.values(moduleGrid.moduleObjects).filter(function (m) {
      return m.active || moduleGrid.cellIsBuildable(m.cell)
    })

    if (modules.length === 0 && moduleGrid.moduleObjects['0,0']) {
      modules = [moduleGrid.moduleObjects['0,0']]
    }

    if (modules.length === 0) {
      modules = [Object.values(moduleGrid.moduleObjects)[0]]
    }

    if (!pointSelectionMethod) {
      pointSelectionMethod = SceneHelper.AUTO_ORIENT_DEFAULTS.pointSelectionMethod
    }

    let corners =
      pointSelectionMethod === 2
        ? SceneHelper.samplePointsFromModulesV2(moduleGrid, modules)
        : SceneHelper.samplePointsFromModulesV1(moduleGrid, modules, inflationFactor)

    if (drawNodes || SceneHelper.AUTO_ORIENT_DEFAULTS.drawNodes) {
      editor.uiPause('render', 'debugForRansacCorners')
      editor.filterObjects((o) => o.debugForRansacCorners).forEach((o) => editor.removeObject(o))

      corners.forEach(function (p) {
        const node = new OsNode({ position: p, selectable: false })
        node.userData.excludeFromExport = true
        node.debugForRansacCorners = true
        node.refreshForCamera()
        editor.addObject(node)
      })
      editor.uiResume('render', 'debugForRansacCorners')
    }

    let terrainUnderCellPositions = corners.map(function (position) {
      return SceneHelper.pointOnObj(position)
    })

    if (!iterations) {
      iterations = SceneHelper.AUTO_ORIENT_DEFAULTS.iterations
    }

    // Lower numPoints makes it easier to exclude extreme outliers
    // Highter numPoints makes the solution more stable in the presence of significant noise
    if (!numPoints) {
      numPoints = terrainUnderCellPositions.length * SceneHelper.AUTO_ORIENT_DEFAULTS.numPointsFraction + 3
    }

    if (!distanceThresholdForInliers) {
      distanceThresholdForInliers = SceneHelper.AUTO_ORIENT_DEFAULTS.distanceThresholdForInliers
    }

    let planeFromPoints = OsFacet.planeFromPointsRANSAC(
      terrainUnderCellPositions.map(function (p) {
        return p.toArray()
      }),
      {
        iterations,
        // For large datasets use around half the points in each RANSAC iteration, otherwise use them all.
        numPoints,
        distanceThresholdForInliers,
      }
    ).plane

    const azimuth = OsFacet.azimuthForNormal(planeFromPoints.normal)
    const slope = Math.min(OsFacet.slopeForNormal(planeFromPoints.normal), SLOPE_MAX_FOR_AUTO_ORIENTATION)

    if (opts?.computePosition) {
      // this can be a useful optimization if the caller of this function
      // needs both the orientation (slope, azimuth) and elevation
      // we can compute a ray-plane intersection which is a valid way to compute
      // the ideal module grid's elevation based on the terrain under it
      const ray = new THREE.Ray(
        moduleGrid.position.clone().sub(new THREE.Vector3(0, 0, 1000)),
        new THREE.Vector3(0, 0, 1)
      )
      const position = ray.intersectPlane(planeFromPoints, new THREE.Vector3())
      return { azimuth, slope, position }
    } else {
      return { azimuth, slope }
    }
  },

  autoOrientModuleGridWithLeastSquares: function (moduleGrid, opts = {}) {
    // there's no point running the auto-orientation algorithm
    // if we're never gonna use any of the results anyway
    // so we just do nothing and abort early
    if (!moduleGrid.slopeAuto && !moduleGrid.azimuthAuto && !moduleGrid.elevationAuto) return

    let elevationFromAutoOrientation = null

    try {
      const initialAzimuth = moduleGrid.getAzimuth()
      const initialSlope = moduleGrid.getSlope()

      const orientationAndPositionFromTerrain = this.computeModuleGridAzimuthSlopeFromDSM(moduleGrid, {
        ...opts,
        computePosition: true,
      })

      const azimuthFromTerrain = orientationAndPositionFromTerrain.azimuth
      const slopeFromTerrain = orientationAndPositionFromTerrain.slope

      let azimuthToApply = initialAzimuth
      let azimuthNeedsUpdate = false

      if (moduleGrid.azimuthAuto) {
        azimuthToApply = azimuthFromTerrain
        azimuthNeedsUpdate =
          Utils.differenceBetweenAngles(initialAzimuth, azimuthFromTerrain) >
          this.AUTO_ORIENT_DEFAULTS.azimuthChangeThreshold
      }

      let slopeToApply = initialSlope
      let slopeNeedsUpdate = false

      if (moduleGrid.slopeAuto) {
        slopeToApply = slopeFromTerrain
        slopeNeedsUpdate =
          Utils.differenceBetweenAngles(initialSlope, slopeFromTerrain) > this.AUTO_ORIENT_DEFAULTS.slopeChangeThreshold
      }

      if (azimuthNeedsUpdate || slopeNeedsUpdate) {
        this.orientModuleGrid(moduleGrid, azimuthToApply, slopeToApply)
      }

      elevationFromAutoOrientation = orientationAndPositionFromTerrain.position
    } catch (e) {
      // auto-orientation of module grid failed with error
      // happens when some sample points are outside the terrain bounds
    }

    if (moduleGrid.elevationAuto && editor.getTerrain()) {
      try {
        if (!!elevationFromAutoOrientation) {
          // the auto orientation algorithm successfully returned a plane-ray intersection
          // at the module grid's position, we can use that result instead for the elevation
          // no need to re-compute using DSM lookup
          const raiseOffset = new THREE.Vector3(0, 0, MODULE_GRID_OFFSET_FROM_TERRAIN)
          const computedPosition = elevationFromAutoOrientation.add(raiseOffset)
          moduleGrid.position.copy(computedPosition)
        } else {
          moduleGrid.position.copy(this.computeModuleGridPositionFromDSM(moduleGrid))
        }
      } catch (e) {
        // auto-elevation of module grid failed with error
        // happens when the centroid of the module grid is outside the terrain bounds
      }
    }

    this.snapModuleGridToGroundLevel(moduleGrid)
    moduleGrid.azimuthIndicators.sync()
  },

  detectModuleGridOrientationWithFixedAzimuth: function (moduleGrid, azimuth) {
    // Supplied azimuth can be flipped 180 degrees, we will auto-detect
    // We do not use 3D to get exact azimuth because it can be imperfect, but we can reliably
    // detect whether to flip azimuth by 180 degrees
    if (typeof azimuth === 'undefined') {
      azimuth = moduleGrid.getAzimuth()
    }
    var azimuthRad = azimuth * (Math.PI / 180)

    if (editor.getTerrain()) {
      try {
        // Assume cells are already positioned correctly ??

        // Find orientation for corner of each cell
        // Just use cell centers for now
        var cellPositions = Object.values(moduleGrid.moduleObjects)
          .filter(function (m) {
            return m.active
          })
          .map(function (moduleObject) {
            return moduleObject.getWorldPosition(new THREE.Vector3())
          })

        // Calculate best-fit plane for points at top-middle and bottom-middle of each cell
        // Rathar than extreme top and bottom, use half-way to top and half-way to bottom otherwise points
        // may fall off the edge of a steep slope.
        // @TODO: Refine this to give more optimal results for any slope? e.g. If slope is close to flat
        // then go closer to top or bottom.
        //
        // @TODO: Use points at the extreme top/bottom for each column. If rows 0,1 are filled, use bottom of 0 and top of 1

        // If no cells active, just use moduleGrid position, which is the same as cell 0,0
        if (cellPositions.length === 0) {
          cellPositions = [moduleGrid.position]
        }

        var samplePoints = []
        var sampleDistanceForHalfPanel = 0.5
        cellPositions.forEach(function (p) {
          //add top of panel

          // Subtract distance because azimuth=0 faces north, so top is towards the south (before rotating gy azimuth)
          var offset = new THREE.Vector3(0, sampleDistanceForHalfPanel, 0).applyAxisAngle(
            new THREE.Vector3(0, 0, 1),
            -azimuthRad
          )
          var top = p.clone().add(offset)
          samplePoints.push(top)

          var bottom = p.clone().sub(offset)
          samplePoints.push(bottom)
        })

        var centroidWorld = moduleGrid.getCentroid().add(moduleGrid.position)
        var axleDirection2 = new THREE.Vector2(1, 0).rotateAround(new THREE.Vector3(), -azimuthRad)
        var axleDirection3 = new THREE.Vector3(axleDirection2.x, axleDirection2.y, 0)
        var axlePlane = new THREE.Plane().setFromNormalAndCoplanarPoint(axleDirection3, centroidWorld)

        var terrainUnderCellPositions = samplePoints.map(function (position) {
          return SceneHelper.pointOnObj(position)
        })

        var pointsProjectedOntoAxlePlane = terrainUnderCellPositions.map(function (p) {
          return axlePlane.projectPoint(p, new THREE.Vector3())
        })

        // Debug by adding nodes to the scene
        // pointsProjectedOntoAxlePlane.forEach(function(p) {
        //   var n = new OsNode({ position: p })
        //   editor.addObject(n)
        // })

        var centroidInAxleSpaceForSubtraction = axlePlane.projectPoint(centroidWorld, new THREE.Vector3())

        // Rotate so all points are aligned with the x axis
        var pointsProjectedOntoAxleSpace = pointsProjectedOntoAxlePlane.map(function (p) {
          return p.applyAxisAngle(new THREE.Vector3(0, 0, 1), azimuthRad).sub(centroidInAxleSpaceForSubtraction)
        })

        var bestFitSlopeParams = Utils.findLineByLeastSquares(
          pointsProjectedOntoAxleSpace.map(function (p) {
            return p.y
          }),
          pointsProjectedOntoAxleSpace.map(function (p) {
            return p.z
          })
        )

        var slope = -1 * Math.atan(bestFitSlopeParams[0]) * (180 / Math.PI)

        var intercept = bestFitSlopeParams[1]

        // If slope is negative, then our initial guess at azimuth was wrong by 180 degrees
        // we should flip both slope and azimuth
        // console.log('slope, azimuth', slope, azimuth)

        if (slope < 0) {
          slope *= -1
          azimuth = (azimuth + 180) % 360
          azimuthRad = (azimuthRad + Math.PI) % (Math.PI * 2)
        }

        var terrainPointAtCentroid = SceneHelper.pointOnObj(centroidWorld)

        // Find the point which is furthest below the plane and elevate the grid to ensure it is above the face
        // var interceptIncludingOffsetAbovePlane = pointsProjectedOntoAxlePlane.map(function(p) {
        //   return p.y * bestFitSlopeParams[0] + bestFitSlopeParams[1]
        // })
        // var lowestInterceptIncludingOffsetAbovePlane = Math.min.apply(null, interceptIncludingOffsetAbovePlane)

        // Alternative method until we fix the 2D slope calculations
        // var planeFromPoints = OsFacet.planeFromPoints(
        //   terrainUnderCellPositions.map(function(p) {
        //     return p.toArray()
        //   }),
        //   null,
        //   null
        // )
        // var slopeHack = OsFacet.slopeForNormal(planeFromPoints.normal)
        // console.log('slope comparison', slope, slopeHack)
        // slope = slopeHack

        // intercept = 0
        // results.pointOffsets = SceneHelper.mean(terrainUnderCellPositions.map(function(p) {
        //   return planeFromPoints.distanceToPoint(p)
        // }))

        // if (moduleGrid.useTiltRack) {
        //   azimuth = moduleGrid.tiltRackAzimuth
        //   slope = moduleGrid.tiltRackSlope
        // } else if (slope < 8) {
        //   // moduleGrid.useTiltRack = true
        //   //
        //   // //@TODO: Auto-detect based on hemisphere
        //   // azimuth = 0
        //   // slope = 20
        // } else {
        //   // Disable: We are using the pre-specified azimuth instead
        //   // azimuth = SceneHelper.mean(
        //   //   orientationsForCells.map(function(orientation) {
        //   //     return orientation.azimuth
        //   //   })
        //   // )
        // }

        // Apply to moduleGrid
        // SceneHelper.orientModuleGrid(moduleGrid, azimuth, slope)

        var moduleGridZ = terrainPointAtCentroid.z + MODULE_GRID_OFFSET_FROM_TERRAIN

        // Set grid position to ensure grid centroid is in the correct location
        /*
        if (false) {
          var oldCentroidWorld = moduleGrid.localToWorld(moduleGrid.getCentroid())
          var deltaCentroidPosition = targetCentroid.sub(oldCentroidWorld)
          var raiseOffset = new THREE.Vector3(0, 0, MODULE_GRID_OFFSET_FROM_TERRAIN)

          moduleGrid.position.add(deltaCentroidPosition).add(raiseOffset)
        }
        */

        return [slope, azimuth, moduleGridZ]
      } catch (e) {
        //Error detecting from terrain, just continue as if there's not terrain
      }
    }

    // If no terrain there's no need to auto-detect slope, just use existing slope
    return [moduleGrid.getSlope(), azimuth, moduleGrid.position.z]
  },

  orientWithPositions: function (moduleGrid, anchorPositions, allowUpdateTiltRacks) {
    // Note: This treates moduleGrid.azimuthAuto as true, even if it's not.
    // anchorPositions must already have z values populated from intersection with terrain

    // Calculate axle, which is the flat line between anchor points (non-horizontal axle not yet implemented)
    var axleZ = (anchorPositions[0].z + anchorPositions[1].z) / 2
    var axle = new THREE.Line3(
      new THREE.Vector3(anchorPositions[0].x, anchorPositions[0].y, axleZ),
      new THREE.Vector3(anchorPositions[1].x, anchorPositions[1].y, axleZ)
    )

    // @TODO: Guess azimuth from terrain to allow painting on southern faces
    var azimuth = Utils.azimuthBetweenPoints(anchorPositions, this.getHemisphere())

    // Calculate best-fit slope only if we need to detect either azimuth or slope
    var slope, orientation

    if (moduleGrid.azimuthAuto || moduleGrid.slopeAuto || moduleGrid.elevationAuto) {
      orientation = SceneHelper.detectModuleGridOrientationWithFixedAzimuth(moduleGrid, azimuth)

      slope = moduleGrid.slopeAuto ? orientation[0] : moduleGrid.getPanelTilt()

      var useTiltRack = slope < 10
      var newPanelConfiguration
      if (allowUpdateTiltRacks) {
        if (useTiltRack) {
          newPanelConfiguration = OsModuleGrid.PanelConfigTypes.SingleTilt
          moduleGrid.panelTiltOverride = 20
        } else {
          newPanelConfiguration = OsModuleGrid.PanelConfigTypes.Flush
          moduleGrid.panelTiltOverride = null
        }
        if (moduleGrid.panelConfiguration !== newPanelConfiguration) {
          console.log('change panelConfiguration=', newPanelConfiguration)
          window.editor.execute(
            new window.SetPanelConfigurationCommand(moduleGrid, 'panelConfiguration', newPanelConfiguration)
          )
        }
      }

      if (moduleGrid.azimuthAuto) {
        azimuth = orientation[1] //may be flipped from original azimuth
      }
    } else {
      // console.log('not detecting slope or orientation')
      slope = moduleGrid.getSlope()
    }

    var moduleGridZ = moduleGrid.elevationAuto ? orientation[2] : moduleGrid.position.z

    SceneHelper.orientModuleGrid(moduleGrid, azimuth, slope)

    // var raiseOffset = new THREE.Vector3(0, 0, 0.2)
    // moduleGrid.position.copy(targetCentroid).add(raiseOffset)
    moduleGrid.position.z = moduleGridZ
    // editor.signals.objectChanged.dispatch(moduleGrid)
  },

  snap: function () {
    if (editor && editor.getTerrain()) {
      editor.filter('type', 'OsModuleGrid').forEach(function (mg) {
        SceneHelper.autoOrientModuleGrid(mg)
      })
      editor.render()
    }
  },

  mean: function (values) {
    return (
      values.reduce(function (a, b) {
        return a + b
      }) / values.length
    )
  },

  centroidOfPositions: function (positions) {
    var sum = new THREE.Vector3()
    positions.forEach(function (position) {
      sum.add(position)
    })
    return sum.divideScalar(positions.length)
  },

  isSampleUrl: function (url) {
    return url && url.indexOf('sample_terrain_urls') !== -1
  },

  refreshUrl: function (url) {
    const orgId = window.getStorage().getItem('org_id')
    if (this.isSampleUrl(url)) {
      // Do not modify sample terrain URLs which are used during testing
      return url
    } else if (url.indexOf('nearmap') !== -1) {
      return API_BASE_URL + 'orgs/' + orgId + '/maps/refresh_download_url/nearmap/?url=' + btoa(url)
    } else {
      return API_BASE_URL + 'orgs/' + orgId + '/maps/refresh_download_url/google/?url=' + btoa(url)
    }
  },

  cleanUrlForTerrainEndpoint: function (url) {
    if (this.isSampleUrl(url)) {
      // Do not modify sample terrain URLs which are used during testing
      return url
    } else if (url.indexOf('google') !== -1) {
      // Remove extra parameters from URLs before contacting endpoint directly without routing through refresh endpoint
      // because Google endpoints will fail if unexpected parameters are included
      // This assumes there should be no querystring except for our extra params
      return url.split('?')[0]
    } else {
      return url
    }
  },

  routeThroughImageReformatProxy: function (url) {
    return (
      API_BASE_URL +
      'reformat_image/?request=' +
      btoa(
        JSON.stringify({
          quality: 98,
          url: url,
          width: 2000,
          height: 2000,
        })
      )
    )
  },

  resetFaces: function () {
    var t = editor.getTerrain()
    t.wallFacesIndices.forEach((faceIndex) => {
      t.geometry.faces[faceIndex].materialIndex = 1
    })
    t.geometry.groupsNeedUpdate = true
    editor.render()
  },

  fixTreeFaces: function (offsetMagnitude) {
    var t = editor.getTerrain()

    if (!offsetMagnitude) {
      offsetMagnitude = 0.5
    }

    t.wallFacesIndices.forEach((faceIndex) => {
      var isGreen = false

      // var isTall = false
      // metrics = this.faceMetrics(t.geometry.faces[faceIndex])
      // if (metrics.height > 2) {
      //   isTall = true
      // }

      var offsets = [
        [0, 0],
        [offsetMagnitude, offsetMagnitude],
        [-offsetMagnitude, offsetMagnitude],
        [-offsetMagnitude, -offsetMagnitude],
        [offsetMagnitude, -offsetMagnitude],
      ]
      offsets.forEach((offset) => {
        if (SceneHelper.isGreen(SceneHelper.getColorForFace(faceIndex, offset[0], offset[1]))) {
          isGreen = true
        }
      })

      if (isGreen) {
        t.geometry.faces[faceIndex].materialIndex = 0
      }
    })
    t.geometry.groupsNeedUpdate = true
    editor.render()
  },

  faceMetrics: function (face) {
    // Metrics implemented:
    // - height

    // Metrics to consider adding:
    // - center color
    // - average face color
    // - adjacent face with green-ish color yes/no
    // - steepness (face.normal.z)
    // - horizontal_distance
    // - top vertex co-linear with prominent gutter line or co-planar with a prominent facet
    // - intersects significant detected edge
    var g = editor.getTerrain().geometry

    var vertices = [g.vertices[face.a], g.vertices[face.b], g.vertices[face.c]]

    return {
      height:
        Math.max(vertices[0].z, vertices[1].z, vertices[2].z) - Math.min(vertices[0].z, vertices[1].z, vertices[2].z),
    }
  },

  isGreen: function (rgb) {
    var hsl = new THREE.Color(rgb[0] / 255, rgb[1] / 255, rgb[2] / 255).getHSL({})
    // if (hsl.s > 0.25) {
    // if (hsl.l > 0.3) {
    if (hsl.h > 0.2 && hsl.h < 0.5) {
      return true
    }
    // }
    // }
    return false
  },

  getColorForFace: function (faceIndex, offsetX, offsetY) {
    var p = this.getPositionForFace(faceIndex)
    return this.getColorAtPixel(p.x + offsetX, p.y + offsetY)
  },

  getPositionForFace: function (faceIndex) {
    var geom = editor.getTerrain().geometry
    var face = geom.faces[faceIndex]
    var v0 = geom.vertices[face.a]
    // Get average of face.a, face.b, face.c
    return new THREE.Vector3(
      (geom.vertices[face.a].x + geom.vertices[face.b].x + geom.vertices[face.c].x) / 3,
      (geom.vertices[face.a].y + geom.vertices[face.b].y + geom.vertices[face.c].y) / 3,
      (geom.vertices[face.a].z + geom.vertices[face.b].z + geom.vertices[face.c].z) / 3
    )
  },

  getColorAtPixel: function (worldX, worldY) {
    // convert geometry position to image position
    var imagePixel = this.xyToUv(worldX, worldY)

    var t = editor.getTerrain()
    var image = t.material[0].map.image

    imagePixel.x *= image.width
    imagePixel.y *= image.height

    var downScaleFactor = 4

    if (!window.ctx) {
      window.canvas = document.createElement('canvas')
      window.ctx = window.canvas.getContext('2d')
      window.canvas.style.top = '0'
      window.canvas.style.left = '0'
      window.canvas.style.width = image.width / downScaleFactor + 'px'
      window.canvas.style.height = image.width / downScaleFactor + 'px'
      window.canvas.style.zIndex = -100000000
      window.canvas.style.position = 'absolute'
      window.canvas.style.backgroundColor = '#cccccc'

      window.canvas.width = image.width / downScaleFactor
      window.canvas.height = image.height / downScaleFactor

      window.ctx.imageSmoothingEnabled = true
      window.ctx.drawImage(image, 0, 0, window.canvas.width, window.canvas.height)

      document.body.appendChild(window.canvas)
    }

    return window.ctx
      .getImageData(
        parseInt(Math.round(imagePixel.x / downScaleFactor)),
        parseInt(Math.round(imagePixel.y / downScaleFactor)),
        1,
        1
      )
      .data.slice(0, 3)
  },

  getSceneCentroidOld: function () {
    // Previously we would use terrain position if available, otherwise use centroid of all moduleGrids (across all systems)
    // But this results in a shading simulation area that is not aligned with the actual location of panels and terrain.
    if (editor.scene.terrainPosition) {
      return editor.scene.terrainPosition
    } else {
      return Utils.getCentroid(editor.filter('type', 'OsModuleGrid').map((mg) => mg.position))
    }
  },

  getSceneCentroid: function () {
    // Use the center of the bounding box for all objects that affect shading which includes all panel groups and terrain.
    // This avoids objects which do not actually impact shading which may reduce some issues with stray objects
    // (e.g. It would ignore a node/vertex placed at some absurd location, far from scene center)
    return this.getSceneBoundingBoxWithCache().getCenter(new THREE.Vector3())
  },

  loadCountryCode: function (location4326, country_iso2) {
    //Asynchronous call

    if (country_iso2) {
      editor.scene.country = { iso2: country_iso2 }
    }

    if (editor.scene.country && editor.scene.country.iso2.length === 2) {
      return Promise.resolve(editor.scene.country)
    }

    console.error('Warning: country_iso2 not supplied and country-lookup using unreliable geonames...')

    return new Promise(function (resolve, reject) {
      $.ajax({
        type: 'GET',
        url:
          API_BASE_URL +
          'orgs/' +
          window.getStorage().getItem('org_id') +
          '/countries/by_location/' +
          location4326.join(','),
        dataType: 'json',
        contentType: 'application/json',
        headers: Utils.tokenAuthHeaders({
          'X-CSRFToken': getCookie('csrftoken'),
        }), //cors for django
        data: {
          filetype: 'json',
        },
        success: function (data) {
          Designer.showNotification(window.translate('Country detected') + ': ' + data['iso2'])

          editor.scene.country = data

          resolve(data)
        },
        error: function (data) {
          Designer.showNotification(window.translate('Country detection failed'), 'danger')
          console.log('error', data)
          reject()
        },
      })
    })
  },

  duplicateSystem: function (system) {
    // This also prevents clearing hash in AddObjectCommand which avoids unnecessary onChange handlers/updates
    var isDuplicating = true
    var options = {
      basicMode: false,
      autoSync: { ...system.autoSync },
    }
    var newSystem = editor.createObject('OsSystem', undefined, undefined, options, null, isDuplicating)

    // Copy hash into new system because it will match the original system
    newSystem._hash = system._hash

    var overrideSelect = newSystem

    //copy system settings
    newSystem.userData = window._.cloneDeep(system.refreshUserData())

    //Ensure new order is not overwritten
    newSystem.userData.order = newSystem.order

    newSystem.applyUserData()

    var reverseArray = function (originalArray) {
      // avoid modifying original array
      // reverse to preserve order when added to the child

      var newArray = []
      for (var i = originalArray.length - 1; i > -1; i--) {
        newArray.push(originalArray[i])
      }

      return newArray
    }

    // Update duplicated slots and replace the old associated uuids with the new duplicated object uuids
    function moveSlots(newSystem, oldUuid, newUuid) {
      newSystem.userData.slots.forEach((slot) => {
        if (slot.attachToUuid === oldUuid) {
          const oldSlotKey = `${slot.type}_${slot.attachToUuid}`
          const components = newSystem.getComponentsForSlot(oldSlotKey)
          // update slot
          slot.attachToUuid = newUuid
          // update components associated with slot with correct slotKey
          components.forEach((component) => {
            component.userData.slotKey = `${slot.type}_${slot.attachToUuid}`
            component.applyUserData()
          })
        }
      })
    }
    var slotsToMove = [] // [{oldUuid: string, newUuid: string}]

    //duplicate module grids
    system.moduleGrids().forEach(function (moduleGrid) {
      // We do not need to reverse the order of moduleGrids() to maintain the order in the duplicate system
      // unlike the other components

      // We could refactor this, we just need to deal with `position` which is
      // the only field not in userData (and check dataType THREE.Vector3 vs Array

      //Use duplicate but do not pass editor param, so it will not be added to the scene, and force position to remain
      var newModuleGrid = moduleGrid.duplicate({ keepPosition: true })

      editor.createObject('OsModuleGrid', newModuleGrid, newSystem, undefined, overrideSelect)
      newModuleGrid.userData = Object.assign({}, moduleGrid.refreshUserData())
      //used later for re-matching new modules to corresponding grids
      newModuleGrid.applyUserData()

      //map new module uuids to old modules uuids
      moduleGrid.getModules().forEach(function (oldModule) {
        var newModule = newModuleGrid.getModules().filter(function (_newModule) {
          return _newModule.cell === oldModule.cell
        })[0]
        newModule.oldModuleUuid = oldModule.uuid
      })
    })

    reverseArray(system.inverters()).forEach(function (inverter) {
      var newInverter = editor.createObject('OsInverter', undefined, newSystem, undefined, overrideSelect)
      newInverter.userData = Object.assign({}, inverter.refreshUserData())
      newInverter.applyUserData()
      slotsToMove.push({ oldUuid: inverter.uuid, newUuid: newInverter.uuid })

      reverseArray(inverter.mppts()).forEach(function (mppt) {
        var newMppt = editor.createObject('OsMppt', undefined, newInverter, undefined, overrideSelect)
        newMppt.userData = Object.assign({}, mppt.refreshUserData())
        newMppt.applyUserData()
        slotsToMove.push({ oldUuid: mppt.uuid, newUuid: newMppt.uuid })

        mppt.strings().forEach(function (osString) {
          var newString = editor.createObject('OsString', undefined, newMppt, undefined, overrideSelect)
          // copy module uuids for new string
          // replies on the assumption that order of modules is identical in old/new ModulesGrids
          // find uuid for each old module, find which moduleGrid and position
          // inject new module uuid in the equivalent newModuleGrid
          if (osString.moduleUuids) {
            newString.moduleUuids = osString.moduleUuids.map(function (oldModuleUuid) {
              var newModule = newSystem.getModules().filter(function (o) {
                return o.oldModuleUuid === oldModuleUuid
              })[0]

              if (newModule) {
                return newModule.uuid
              } else {
                console.log('Warning: newModule not found for old module, should not be possible.')
                return null
              }
            })
          } else {
            newString.moduleUuids = []
          }
          newString.userData.moduleUuids = newString.moduleUuids
          newString.applyUserData()
          slotsToMove.push({ oldUuid: osString.uuid, newUuid: newString.uuid })
        })
      })
    })

    reverseArray(system.batteries()).forEach(function (battery) {
      var newBattery = editor.createObject('OsBattery', undefined, newSystem, undefined, overrideSelect)
      newBattery.userData = Object.assign({}, battery.refreshUserData())

      // Ensure uuid is not overwritten when copying over userData from original battery
      newBattery.userData.uuid = newBattery.uuid

      newBattery.applyUserData()
    })

    reverseArray(system.others()).forEach(function (other) {
      var newOther = editor.createObject('OsOther', undefined, newSystem, undefined, overrideSelect)
      newOther.userData = Object.assign({}, other.refreshUserData())

      // Ensure uuid is not overwritten when copying over userData from original battery
      newOther.userData.uuid = newOther.uuid

      newOther.applyUserData()
    })

    // This must be called after objects have been duplicated, so their slotKey can be updated
    if (newSystem.userData.slots) {
      slotsToMove.forEach((slotToMove) => moveSlots(newSystem, slotToMove.oldUuid, slotToMove.newUuid))
      newSystem.applyUserData()
    }

    if (system.annotations().length > 0) {
      editor.uiPause('ui', 'signals.objectAdded')
      system.annotations().forEach(function (annotation) {
        var newAnnotation = editor.createObject('OsAnnotation', undefined, newSystem, undefined, overrideSelect)
        newAnnotation.position.copy(annotation.position)
        newAnnotation.userData = Object.assign({}, annotation.refreshUserData())
        newAnnotation.applyUserData()
      })
      editor.uiResume('ui', 'signals.objectAdded')
    }

    // Now that duplication is complete, clear isDuplicating flag
    newSystem.isDuplicating = false

    // BEFORE: do not duplicate integration_json (x)
    // CURRENT: deep clone integration_json but remove "ironridge" prop if it exists (/)
    if ('integration_json' in system) {
      newSystem.integration_json = JSON.parse(JSON.stringify(system.integration_json))
      delete newSystem.integration_json?.ironridge
    }

    // Triggering recalcs on the newly duplicated systems if the original system is awaiting calcs
    // otherwise the new system will never get calculated until user interacts with it
    if (system.awaitingCalcs) {
      Designer.requestSystemCalculations(newSystem)
    }

    window.editor.signals.objectChanged.dispatch(newSystem, 'isDuplicating')
    return newSystem
  },

  moveSystem: function (system, direction) {
    /*
    Method:
    1) Get systems sorted in current order
    2) Re-sort the array to new correct order
    3) Iterate over the new array and set system.order to be same as the position in sorted array
    */
    var systems = editor.getSystems() //already sorted

    var currentIndex = systems.indexOf(system)
    var newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1

    systems = Utils.arrayMove(systems, currentIndex, newIndex)

    var uuid = window.Utils.generateCommandUUIDOrUseGlobal()
    for (var i = 0; i < systems.length; i++) {
      window.editor.execute(new window.SetValueCommand(systems[i], 'order', i, uuid))
      //systems[i].order = i
    }
  },

  getHemisphere: function () {
    return this.getLatitude() && this.getLatitude() < 0 ? 'south' : 'north'
  },

  dayOfYear: function (value) {
    if (typeof value !== 'undefined') {
      this._dayOfYear = value
      return
    }

    // if set explicitly then use that, otherwise use a default for summer high noon based on the hemisphere
    if (this._dayOfYear !== null) {
      return this._dayOfYear
    } else {
      if (this.getHemisphere() === 'south') {
        return 0 //Jan
      } else {
        return 183 //June
      }
    }
  },

  _dayOfYear: null,

  currentMonth: function () {
    return Utils.monthForDay(this.dayOfYear())
  },

  hourOfDayUTC: function (value) {
    if (typeof value !== 'undefined') {
      this._hourOfDayUTC = value
      return
    }

    // if set explicitly then use that, otherwise use a default for summer high noon based on the hemisphere
    if (this._hourOfDayUTC !== null) {
      return this._hourOfDayUTC
    } else {
      return 12 - SceneHelper.estimateTimezoneOffset()
    }
  },

  _hourOfDayUTC: null,
  getLongitude: function () {
    return editor.scene.sceneOrigin4326[0]
  },

  getLatitude: function () {
    return editor.scene.sceneOrigin4326[1]
  },

  estimateTimezoneOffset: function (lon, lat) {
    // Beware editor.scene.timezoneOffset can be === 0 (in UK)
    if (editor && editor.scene && (editor.scene.timezoneOffset || editor.scene.timezoneOffset === 0)) {
      return editor.scene.timezoneOffset
    }

    if (typeof lon === 'undefined') {
      lon = SceneHelper.getLongitude()
    }
    if (typeof lat === 'undefined') {
      lat = SceneHelper.getLatitude()
    }
    return Utils.estimateTimezoneOffset(lon, lat)
  },

  getTime() {
    var timezoneOffsetHours = window.SceneHelper.estimateTimezoneOffset()
    var hourOfDayLocal = this.hourOfDayUTC() + timezoneOffsetHours
    if (hourOfDayLocal > 24) {
      hourOfDayLocal = hourOfDayLocal % 24
    }

    return {
      day: this.dayOfYear(),
      month: this.currentMonth(),
      hourUTC: this.hourOfDayUTC(),
      hourLocal: hourOfDayLocal,
      hour288: this.currentMonth() * 24 + hourOfDayLocal,
      hourUTC288: this.currentMonth() * 24 + this.hourOfDayUTC(),
    }
  },

  shadingPointsEnabled: false,
  shadingPoints: function (enabled) {
    if (typeof enabled === 'undefined') {
      enabled = this.shadingPointsEnabled
    }

    this.shadingPointsEnabled = Boolean(enabled)

    editor.uiPause('ui', 'SceneHelper.shadingPoints')
    editor.uiPause('render', 'SceneHelper.shadingPoints')

    //Clear existing first
    editor.sceneHelpers.children
      .filter((m) => m.type === 'ModuleShadingPoint')
      .forEach((m) => {
        editor.removeObject(m, false)
      })

    if (enabled) {
      editor.selectedSystem?.getModules().forEach((m) => {
        if (!m.getGrid().hasShadingOverride()) {
          m.shadingPoints(enabled)
        }
      })
    }
    editor.uiResume('ui', 'SceneHelper.shadingPoints', false)
    editor.uiResume('render', 'SceneHelper.shadingPoints', false)
    editor.render()
  },

  animateSun: function (targetDay, targetHourUTC) {
    var fromDay = this.dayOfYear()
    var fromHourOfDayUTC = this.hourOfDayUTC()

    //Find shortest path to target to avoid wrapping. e.g. 22 > 2 should go via 22,23,0,1,2 not 22,21...3,2
    var dayRawDistance = Math.abs(targetDay - fromDay)

    var dayWrappedDistanceAbove = targetDay + 364 - fromDay //search above 365
    if (dayWrappedDistanceAbove < dayRawDistance) {
      //Set target day > 364
      targetDay = targetDay + 364
    }
    var dayWrappedDistanceBelow = fromDay + 364 - targetDay //search below 364
    if (dayWrappedDistanceBelow < dayRawDistance) {
      //Set target day > 364
      targetDay = targetDay - 364
    }

    var hourRawDistance = Math.abs(targetHourUTC - fromHourOfDayUTC)

    var hourWrappedDistanceAbove = targetHourUTC + 24 - fromHourOfDayUTC //search above 24
    if (hourWrappedDistanceAbove < hourRawDistance) {
      //Set target day > 24
      targetHourUTC = targetHourUTC + 24
    }
    var hourWrappedDistanceBelow = fromHourOfDayUTC + 24 - targetHourUTC //search below 0
    if (hourWrappedDistanceBelow < hourRawDistance) {
      //Set target day > 24
      targetHourUTC = targetHourUTC - 24
    }

    var clampDayToRange = function (dayRaw) {
      return (dayRaw + 364) % 364
    }
    var clampHourToRange = function (hourRaw) {
      return (hourRaw + 24) % 24
    }

    /*
    Annotations are very heavy, so disable them when updating rays which can be very slow for large panel groups
    A panel group with 50 panels takes around 12ms to process Annotation.handleObjectSelected which kills animation
    performance
    */
    editor.uiPause('annotation', 'animateSun')
    editor.uiPause('handle', 'animateSun')

    var duration = 1000
    createjs.Tween.get({})
      .to({}, duration)
      .call(function handleComplete() {
        editor.signals.animationStop.dispatch('tween', 'animateSun')
        if (SceneHelper.shadingPointsEnabled) {
          SceneHelper.shadingPoints(true)
        }

        editor.uiResume('annotation', 'animateSun')
        editor.uiResume('handle', 'animateSun')
      })
      .on('change', function () {
        var fraction = createjs.Ease.cubicInOut(this.position / duration)
        var newDay = clampDayToRange(Math.round(fromDay * (1 - fraction) + targetDay * fraction))
        var newHour = clampHourToRange(fromHourOfDayUTC * (1 - fraction) + targetHourUTC * fraction)

        SceneHelper.updateSun(newDay, newHour, false)
      })

    editor.signals.animationStart.dispatch('tween', 'animateSun')
  },

  updateSun: function (day, hourUTC, allowRender) {
    if (typeof day !== 'undefined') {
      this.dayOfYear(day)
    }
    if (typeof hourUTC !== 'undefined') {
      this.hourOfDayUTC(hourUTC)
    }

    var worldPositionAtViewportCenter = editor.viewport.worldPositionAtViewportCenter()
    var target = worldPositionAtViewportCenter

    var sunPosition = Utils.sunPositionFromTargetAtDateTimeUTC(
      this.dayOfYear(),
      this.hourOfDayUTC(),
      this.getLongitude(),
      this.getLatitude(),
      target,
      editor.sun && editor.sun.distance
    )

    if (this.sunSphere) {
      this.sunSphere.position.copy(sunPosition)
      this.sunSphere.dotWithUp = sunPosition.dotWithUp
      this.sunSphere.visible = this.effectController && this.effectController.sun ? true : false
    }

    if (editor.sun) {
      // editor.sun.position.copy(this.sunSphere.position)
      SceneHelper.arrangeLights(window.editor, this.sunSphere.position)
    }

    editor.signals.sunUpdated.dispatch(this.dayOfYear(), this.hourOfDayUTC())

    if (!Designer.shadingVisibility()) {
      // normal ambient and sun intensity
      editor.ambientLight.intensity = 2.5
      editor.sun.intensity = 0.5
    }

    // Disable handle rendering because this smashes performance when many rays may be
    // added/removed every frame

    editor.uiPause('handle', 'updateSun')

    editor.uiResume('handle', 'updateSun')

    if (this.effectController && this.gui) {
      this.gui.updateDisplay()
    }
  },

  sunDirection: function () {
    var sp = Utils.sunPositionAtDateTimeUTC(
      this.dayOfYear(),
      this.hourOfDayUTC(),
      this.getLongitude(),
      this.getLatitude()
    )

    // Calculate direction of ray to determine whether it is behind the plane of the panel
    // This is not considered "shaded" because there is no way for the beam to directly hit the panel anyway
    var inclination = Math.PI / 2 - sp.altitude

    var sunRayDirection = new THREE.Vector3(0, 0, 1)
    sunRayDirection.applyAxisAngle(new THREE.Vector3(1, 0, 0), inclination)
    sunRayDirection.applyAxisAngle(new THREE.Vector3(0, 0, 1), -(sp.azimuth + Math.PI))
    sunRayDirection.negate()

    return sunRayDirection
  },

  getRays: function () {
    return editor
      .filter('type', 'RayToPanelHelper', false, editor.sceneHelpers)
      .concat(editor.filter('type', 'HorizontalRayToPanelHelper', false, editor.sceneHelpers))
  },

  sunraysToModules: function (visibility, opts = { modules: [], render: false }) {
    editor.uiPause('render', 'SceneHelper.sunraysToModules')

    const removeRaysFromScene = () => {
      const rays = this.getRays()
      if (rays.length) {
        rays.forEach(function (rayToPanel) {
          // Do not dispatch signals for every ray which kills performance
          editor.removeObject(rayToPanel, false)
          rayToPanel.cone.geometry.dispose()
          rayToPanel.cone.material.dispose()
          rayToPanel.line.geometry.dispose()
          rayToPanel.line.material.dispose()
        })
      }
    }

    if (visibility === false) {
      editor.ambientLight.intensity = 2.5
      editor.sun.intensity = 0.5

      removeRaysFromScene()

      editor.uiResume('render', 'SceneHelper.sunraysToModules', false)
      if (!!opts.render) {
        editor.render()
      }

      return
    }

    try {
      // range ambient intensity between 3 (night) and 1 (day)
      const ambientIntensityFraction = 1 - this.sunSphere.dotWithUp
      editor.ambientLight.intensity = -0.0 + ambientIntensityFraction * 1.5 + 1.0
      // range of sun intensity between 3 (day) and 6 (night)
      const sunIntensityFraction = (8 - this.sunSphere.dotWithUp * 5) * 0.5
      editor.sun.intensity = sunIntensityFraction

      removeRaysFromScene()

      let sunVector = this.sunDirection().multiplyScalar(40)

      let modules = opts.modules
      if (modules === undefined) {
        if (editor.selected && editor.selected.type === 'OsModule') {
          // alternatively show all panels on the same panel grid
          // modules = editor.selected.getGrid().getModules()
          modules = [editor.selected]
        } else if (editor.selected && editor.selected.type === 'OsModuleGrid') {
          modules = editor.selected.getModules()
        }
      }

      if (modules && modules.length > 0 && modules.length <= MAX_RAYS_TO_SHOW) {
        // One ray for each module in the panel group
        modules.forEach(function (osModule) {
          if (
            osModule.getGrid().hasShadingOverride() ||
            !osModule.shadingOverride ||
            osModule.shadingOverride.length !== 288
          ) {
            // skip ray, not yet raytraced or using override which should not show rays
            return
          }

          // DRAW RAY

          var month = Utils.monthForDay(this.dayOfYear())
          var shadingValue = osModule.shadingOverride[24 * month + Math.round(this.hourOfDayUTC())]

          //////////////////////

          var rayColor = new THREE.Color(0, 0, 0).fromArray(
            Designer.colorForPanelsRGB(shadingValue !== null ? 1 - shadingValue : null)
          )

          var arrowEnd = osModule.getWorldPosition(new THREE.Vector3())
          var arrowStart = new THREE.Vector3().subVectors(arrowEnd, sunVector)

          var direction = arrowEnd.clone().sub(arrowStart)
          var length = direction.length()
          var arrowHelper = new THREE.ArrowHelper(direction.normalize(), arrowStart, length, rayColor, 0.5, 0.1)
          arrowHelper.type = 'RayToPanelHelper'
          arrowHelper.userData.excludeFromExport = true
          arrowHelper.line.selectable = false
          arrowHelper.cone.selectable = false
          editor.sceneHelpers.add(arrowHelper)

          // DRAW HORIZONTAL PROJECTION OF RAY
          // @TODO: Draw this onto the terrain texture instead so it looks like a shadow cast by the ray
          var rayColor2 = 0x666666
          var arrowEnd = osModule.getWorldPosition(new THREE.Vector3())
          // var arrowStart = this.sunSphere.position.clone()
          var arrowStart = new THREE.Vector3().subVectors(arrowEnd, sunVector)
          arrowStart.z = arrowEnd.z
          var direction = arrowEnd.clone().sub(arrowStart)
          var length = direction.length()
          var arrowHelperHorizontal = new THREE.ArrowHelper(
            direction.normalize(),
            arrowStart,
            length,
            rayColor2,
            0.01,
            0.01
          )
          arrowHelperHorizontal.type = 'HorizontalRayToPanelHelper'
          arrowHelperHorizontal.userData.excludeFromExport = true
          arrowHelperHorizontal.line.selectable = false
          arrowHelperHorizontal.cone.selectable = false
          editor.sceneHelpers.add(arrowHelperHorizontal)
        }, this)
      }
    } catch (e) {
      console.error('Error SceneHelper.sunraysToModules()', e)
      editor.uiResume('render', 'SceneHelper.sunraysToModules', false)
    }

    editor.uiResume('render', 'SceneHelper.sunraysToModules', false)

    if (opts.render !== false) {
      editor.render()
    }
  },

  getSky: function () {
    return editor.filter('type', 'Sky')[0]
  },

  guiChanged: function () {
    // Cannot use "this" because it is equal to gui elements
    var _this = SceneHelper
    if (_this.sky && _this.effectController) {
      var uniforms = _this.sky.material.uniforms
      uniforms['turbidity'].value = _this.effectController.turbidity
      uniforms['rayleigh'].value = _this.effectController.rayleigh
      uniforms['mieCoefficient'].value = _this.effectController.mieCoefficient
      uniforms['mieDirectionalG'].value = _this.effectController.mieDirectionalG
      uniforms['luminance'].value = _this.effectController.luminance
      _this.updateSun(_this.effectController.dayOfYear, _this.effectController.hourOfDayUTC, true)
      editor.scene.sceneOrigin4326[1] = _this.effectController.latitude
    }
  },

  initGui: function () {
    if (!this.sky) {
      console.warn('Must call SceneHelper.initSky() first')
      return
    }

    if (this.gui) {
      this.gui.destroy()
    }

    this.gui = new GUI()
    this.gui.add(this.effectController, 'turbidity', 1.0, 20.0, 0.1).onChange(this.guiChanged)
    this.gui.add(this.effectController, 'rayleigh', 0.0, 4, 0.001).onChange(this.guiChanged)
    this.gui.add(this.effectController, 'mieCoefficient', 0.0, 0.1, 0.001).onChange(this.guiChanged)
    this.gui.add(this.effectController, 'mieDirectionalG', 0.0, 1, 0.001).onChange(this.guiChanged)
    this.gui.add(this.effectController, 'luminance', 0.0, 2).onChange(this.guiChanged)
    // this.gui.add( this.effectController, "inclination", 0, 1, 0.0001 ).onChange( this.guiChanged );
    // this.gui.add( this.effectController, "azimuth", 0, 1, 0.0001 ).onChange( this.guiChanged );
    this.gui.add(this.effectController, 'dayOfYear', 0, 364, 1.0).onChange(this.guiChanged)
    this.gui.add(this.effectController, 'hourOfDayUTC', 0, 24, 0.5).onChange(this.guiChanged)
    this.gui.add(this.effectController, 'latitude', -60, 60, 1.0).onChange(this.guiChanged)
    this.gui.add(this.effectController, 'sun').onChange(this.guiChanged)
    this.guiChanged()
  },

  initSky: function (showGui) {
    // Add Sky
    if (!this.sky) {
      this.sky = new Sky()
    }
    this.sky.type = 'Sky'
    this.sky.userData.excludeFromExport = true
    this.sky.scale.setScalar(100)
    this.sky.material.uniforms['up'].value = new THREE.Vector3(0, 0, 1)
    editor.scene.add(this.sky)

    /// GUI
    if (!this.effectController) {
      this.effectController = {
        turbidity: 10,
        rayleigh: 2,
        mieCoefficient: 0.005,
        mieDirectionalG: 0.8,
        luminance: 1,
        inclination: 0.49, // elevation / inclination
        azimuth: 0.25, // Facing front,
        dayOfYear: 0,
        hourOfDayUTC: 12,
        latitude: -25,
        sun: !true,
      }
    }

    if (showGui) {
      this.initGui()
    }
  },
  terrainPointsWithinShape: function (shape, samples = 10) {
    const { minx, maxx, miny, maxy } = shape.getEnvelopeInternal()
    const points = []

    dx = (maxx - minx) / samples
    dy = (maxy - miny) / samples
    for (var x = minx; x <= maxx; x += dx) {
      for (var y = miny; y <= maxy; y += dy) {
        var p = gf.createPoint(new jsts.geom.Coordinate(x, y))
        if (shape.contains(p)) {
          points.push(SceneHelper.pointOnObj(new THREE.Vector3(x, y, 0)))
        }
      }
    }

    return points
  },

  terrainPointsUnderFacet: function (facet, options = {}) {
    var excludeOtherFacets = typeof options.excludeOtherFacets !== 'undefined' ? options.excludeOtherFacets : false

    if (window.STUDIO_FEATURE_FLAGS?.STUDIO_NEXT) {
      return SceneHelper.terrainPointsUnderFacet2(facet)
    }

    if (facet.vertices.length === 0) {
      return []
    }

    editor.getTerrain().geometry.computeBoundingBox()
    var bb = facet.getBoundingBox()

    var points = []

    // Insetting edges of sample area will greatly improve accuracy becase edges/vertices may be on/near walls
    // Inset by 30cm
    //@TODO: Avoid buffering small shapes too much
    var facetShapeWithInset = facet.shapesWithSetbacksJSTS(true).facetShape.buffer(-0.3)

    var shape = facetShapeWithInset

    if (excludeOtherFacets) {
      var otherFacetsCombined = new jsts.geom.GeometryFactory().createGeometryCollection(
        editor
          .filter('type', 'OsFacet')
          .filter((f) => f !== facet)
          .map((f) => f.shapesWithSetbacksJSTS(true).facetShape)
      )
      shape = shape.difference(otherFacetsCombined)
    }

    return SceneHelper.terrainPointsWithinShape(shape)
  },

  terrainPointsUnderFacet2: function (facet) {
    // For a thin facet, we must be careful not to inset too much or the resulting point cloud may devolve into more like a
    // line rather than a plane. We apply a simple method by checking the number of points after inset and if too few
    // then return the whole facet instead of insetting it.

    var NUM_SAMPLES_PER_AXIS_DEFAULT = 10
    var NUM_SAMPLES_PER_AXIS_MAX = 20
    var SAMPLE_SPACING_FOR_HIGHER_RESOLUTION = 0.5
    var NUM_POINTS_TO_ALLOW_INSET = 20
    var FACET_INSET_DISTANCE_FOR_SAMPLING_POINTS = 0.3

    editor.getTerrain().geometry.computeBoundingBox()
    var bb = facet.getBoundingBox()

    // Insetting edges of sample area will greatly improve accuracy becase edges/vertices may be on/near walls
    // Inset by 30cm
    //@TODO: Avoid buffering small shapes too much
    var facetShape = facet.shapesWithSetbacksJSTS(true).facetShape
    var facetShapeWithInset = facetShape.buffer(-1 * FACET_INSET_DISTANCE_FOR_SAMPLING_POINTS)

    // These two sets of points combined equals the full set of points
    var pointsAwayFromEdge = []
    var pointsNearEdge = []

    // @TODO: Set a minimum sampling distance because otherwise points for a thin facet will be unnecessarily
    // closely packed
    dx = (bb.max.x - bb.min.x) / (NUM_SAMPLES_PER_AXIS_DEFAULT - 1)
    dy = (bb.max.y - bb.min.y) / (NUM_SAMPLES_PER_AXIS_DEFAULT - 1)

    // if more than 0.5 between samples, then double the number of samples
    // this is a very simple way if increasing resolution to fix long+thin facets that may not be side enough
    // to get a good plane sample which could result in very erratic plane orientations.
    if (dx > SAMPLE_SPACING_FOR_HIGHER_RESOLUTION) {
      dx = (bb.max.x - bb.min.x) / (NUM_SAMPLES_PER_AXIS_MAX - 1)
    }
    if (dy > SAMPLE_SPACING_FOR_HIGHER_RESOLUTION) {
      dy = (bb.max.y - bb.min.y) / (NUM_SAMPLES_PER_AXIS_MAX - 1)
    }

    var getPointOnObject = (x, y) => {
      // This is slow so only call for points that will be included
      return SceneHelper.pointOnObj(new THREE.Vector3(x, y, 0))
    }

    for (var x = bb.min.x; x <= bb.max.x; x += dx) {
      for (var y = bb.min.y; y <= bb.max.y; y += dy) {
        var jstsPoint = gf.createPoint(new jsts.geom.Coordinate(x, y))
        // Sequence is optimized so any points that fall outside the full facet will only be tested once
        // This avoids testing all points outside the facet twice
        if (facetShape.contains(jstsPoint)) {
          if (facetShapeWithInset.contains(jstsPoint)) {
            pointsAwayFromEdge.push(getPointOnObject(x, y))
          } else {
            pointsNearEdge.push(getPointOnObject(x, y))
          }
        }
      }
    }

    if (pointsAwayFromEdge.length >= NUM_POINTS_TO_ALLOW_INSET) {
      return pointsAwayFromEdge
    } else {
      return pointsAwayFromEdge.concat(pointsNearEdge)
    }
  },

  getClipperPoints: function (rasterPosition, rasterSize, rasterResolution) {
    // Ensure they are unique and not duplicated when the same point is clipped by multiple clippers
    // when multiple clippers clip the same cell, use the lowest/min of all values

    var points = []

    var clippers = editor.filter('type', 'OsClipper')
    if (!clippers.length) {
      // no clippers to process
      return points
    }

    clippers.forEach((clipper) => {
      var cellsForClipper = SceneHelper.clipGridCellsToShape(
        clipper,
        clipper.position.z,
        rasterPosition,
        rasterSize,
        rasterResolution
      )

      points = points.concat(cellsForClipper)
    })

    var indexesProcessed = []
    var pointsUniqueLowest = []

    points.forEach((p) => {
      if (indexesProcessed.indexOf(p.index) === -1) {
        // new index to process, get all matching points with same coordinates and use lowest
        // instead of creating a new object just overwrite the z value which is safe because we will not process it again here
        p.z = Math.min.apply(
          null,
          points.filter((p2) => p2.x === p.x && p2.y === p.y).map((p) => p.z)
        )
        pointsUniqueLowest.push(p)
        indexesProcessed.push(p.index)
      }
    })

    var indexesProcessed = []
    var pointsUniqueLowest = []

    points.forEach((p) => {
      var index = SceneHelper.rowColToRasterIndex(p.x, p.y, rasterResolution.x)
      if (indexesProcessed.indexOf(index) === -1) {
        // new index to process, get all matching points with same coordinates and use lowest
        // instead of creating a new object just overwrite the z value which is safe because we will not process it again here
        p.z = Math.min.apply(
          null,
          points.filter((p2) => p2.x === p.x && p2.y === p.y).map((p) => p.z)
        )
        pointsUniqueLowest.push(p)
        indexesProcessed.push(index)
      }
    })

    return pointsUniqueLowest
  },

  clipAndRefreshTerrain: async function () {
    var terrain = editor.getTerrain()

    if (!terrain) {
      // if terrain is not yet loaded, just skip it now and it will run again when terrain is finshed loading
      return
    }

    var terrainElevationZ = terrain.position.z
    let wallBlurringIsActive = terrain.getWallBlurringActive()
    if (wallBlurringIsActive) {
      // temporarily turn off wall blurring while we switch to a new geometry
      terrain.setWallBlurringActive(false)
    }
    var { geometry, originalElevationPointIndexes, wallFacesIndices } = await OsTerrain.buildTerrainGeometry(
      terrain.rasterData,
      terrain.rasterDataOriginal,
      terrain.position,
      terrain.size,
      terrain.rasterResolution,
      terrainElevationZ,
      terrain.originalElevationPointIndexes
    )
    terrain.geometry = geometry
    terrain.wallFacesIndices = wallFacesIndices
    terrain.originalElevationPointIndexes = originalElevationPointIndexes
    if (wallBlurringIsActive) {
      // if wall blurring was active, turn it back on
      // AFTER the geometry and wallFacesIndices has been set
      terrain.setWallBlurringActive(true)
    }
    editor.render()
  },

  clipAndRefreshTerrainDebounced: window.Utils.debounce(function () {
    return SceneHelper.clipAndRefreshTerrain()
  }, 3000),

  clipGridCellsToShape: function (obj, value, rasterPosition, rasterSize, rasterResolution, restrictPointIndexes) {
    var bb3 = new THREE.Box3().setFromObject(obj)

    var points = []
    var position
    var indexes = []

    const terrain = editor.getTerrain()

    var min = this.gridPositionToCell(
      rasterPosition,
      rasterSize,
      rasterResolution,
      bb3.min,
      Math.floor,
      Math.floor,
      terrain ? terrain.rotation.z : 0,
      true
    )
    var max = this.gridPositionToCell(
      rasterPosition,
      rasterSize,
      rasterResolution,
      bb3.max,
      Math.ceil,
      Math.ceil,
      terrain ? terrain.rotation.z : 0,
      true
    )

    // for some reason min.y and max.y are swapped, fix them manually here
    var tmp = min.y
    min.y = max.y
    max.y = tmp

    // limit the shape points to within the raster bounds
    min.clamp(new THREE.Vector2(0, 0), new THREE.Vector2(rasterResolution.x, rasterResolution.y))
    max.clamp(new THREE.Vector2(0, 0), new THREE.Vector2(rasterResolution.x, rasterResolution.y))

    for (var x = min.x; x <= max.x; x++) {
      for (var y = min.y; y <= max.y; y++) {
        var index = SceneHelper.rowColToRasterIndex(x, y, rasterResolution.x)

        // if restricting to specified indexes, skip if we know this index will be excluded anyway
        if (!restrictPointIndexes || restrictPointIndexes.indexOf(index) !== -1) {
          // check intersection
          var position = SceneHelper.rowColToPosition(rasterPosition, rasterSize, rasterResolution, x, y)
          if (SceneHelper.objectCoversPositionXY(obj, position)) {
            var p = new THREE.Vector3(x, y, value)
            p.index = index
            points.push(p)
          }
        }
      }
    }

    return points
  },

  snapFacetsToTerrainDebounced: window.Utils.debounce(function () {
    editor.uiPauseUntilComplete(
      function () {
        editor.uiPauseUntilComplete(
          function () {
            SceneHelper.snapFacetsToTerrain(SceneHelper.snapFacetsToTerrainDebouncedFacetsInQueue)

            // Clear the queue now that it has been processed
            SceneHelper.snapFacetsToTerrainDebouncedFacetsInQueue = []
          },
          this,
          'render',
          'renderPauseLock::snapFacetsToTerrain'
        )
      },
      this,
      'ui',
      'uiPauseLock::snapFacetsToTerrain',
      false
    )
  }, 100),

  snapFacetsToTerrainDebouncedFacetsInQueue: [],

  snapFacetsToTerrainAddFacetToQueue: function (facet) {
    if (!this.snapFacetsToTerrainDebouncedFacetsInQueue.includes(facet)) {
      this.snapFacetsToTerrainDebouncedFacetsInQueue.push(facet)
    }
    this.snapFacetsToTerrainDebounced()
  },

  snapFacetsToTerrain: function (selectedFacets, planeFunc, iterations, numPoints, distanceThresholdForInliers) {
    if (!editor.getTerrain()) {
      return
    }

    var elevationsForEachNode = {}

    /*
    Note: Until the new queuing system (behind feature flag) is used, selectedFacets will be an empty array, so we
    must ensure that empty array is treated as "all facets" and not "no facets"
     */
    var facets = selectedFacets && selectedFacets?.length > 0 ? selectedFacets : editor.filter('type', 'OsFacet')
    facets.forEach(function (facet) {
      try {
        var terrainPointsUnderFacet = SceneHelper.terrainPointsUnderFacet(facet)

        if (!planeFunc) {
          planeFunc = OsFacet.planeFromPointsRANSAC
          // var planeFunc = OsFacet.planeFromPoints
        }

        var planeFromTerrain = planeFunc(
          terrainPointsUnderFacet.map(function (p) {
            return p.toArray()
          }),
          {
            iterations,
            numPoints,
            distanceThresholdForInliers,
          }
        ).plane

        facet.vertices.forEach(function (node) {
          //detect plane by sampling terrain

          // Assume the x,y coordinates are perfect for each node,
          // Calculate the elevation which would bring the node to intersect the plane
          var raycaster = new THREE.Raycaster(
            new THREE.Vector3(node.position.x, node.position.y, 1000),
            new THREE.Vector3(0, 0, -1)
          )

          var intersection = raycaster.ray.intersectPlane(planeFromTerrain, new THREE.Vector3())

          if (intersection) {
            if (!elevationsForEachNode[node.uuid]) {
              elevationsForEachNode[node.uuid] = []
            }
            elevationsForEachNode[node.uuid].push(intersection.z)
          } else {
            console.warn('intersection not found')
          }
        })
      } catch (e) {
        console.warn(e)
      }
    })

    // Merge elevations with nodes which share a flat edge by combining all the elevation estimates
    // Since we go through uuids in a fixed order and only add extra elevations to the current node
    // we can do this in a single loop and simply update the original list
    // We need to store extra nodes separately then combine as the final pass otherwise we can double-add same points
    var elevationsForEachNodeFromFlatEdges = {}

    for (var uuid in elevationsForEachNode) {
      var node = editor.objectByUuid(uuid)
      if (!node) {
        console.log('Expected node missing from elevationsForEachNode')
      } else {
        node.getEdges().forEach(function (edge) {
          if (edge.isFlat()) {
            // add elevations from distal node
            var otherNode = edge.getOtherNode(node)
            if (elevationsForEachNode[otherNode.uuid]) {
              if (!elevationsForEachNodeFromFlatEdges[uuid]) {
                elevationsForEachNodeFromFlatEdges[uuid] = []
              }
              elevationsForEachNodeFromFlatEdges[uuid] = elevationsForEachNodeFromFlatEdges[uuid].concat(
                elevationsForEachNode[otherNode.uuid]
              )
            }
          }
        })
      }
    }
    //combine original and merged points from flat edges
    for (var uuid in elevationsForEachNode) {
      if (elevationsForEachNode[uuid] && elevationsForEachNodeFromFlatEdges[uuid]) {
        elevationsForEachNode[uuid] = elevationsForEachNode[uuid].concat(elevationsForEachNodeFromFlatEdges[uuid])
      }
    }

    // Detect which facets have been modified so we can refresh them.
    // Note that this may be different to selectedFacets:
    // - It may include additional facets (e.g. where a node belongs to multiple facets)
    // - It may omit some facets from selectedFacets if the node elevations did not actually change

    var facetsChanged = []

    for (var uuid in elevationsForEachNode) {
      var node = editor.objectByUuid(uuid)
      if (node) {
        let newZ = SceneHelper.mean(elevationsForEachNode[uuid]) + 0.2

        // Only add to facetsChanged if node elevation has actually changed
        if (node.position.z !== newZ) {
          node.position.z = newZ
          node.facets.forEach((facet) => {
            if (!facetsChanged.includes(facet)) {
              facetsChanged.push(facet)
            }
          })
        }
      } else {
        console.warn('Node not found found for uuid in elevationsForEachNode: ' + uuid)
      }
    }

    facetsChanged.forEach(function (facet) {
      var allowSnapFacets = false
      facet.onChange(editor, true, allowSnapFacets)
    })

    editor.render()
  },

  defaultTiltRackAzimuth: function () {
    return this.getHemisphere() === 'north' ? 180 : 0
  },

  preloadOrthoUrl: function (orthoUrlRaw) {
    var orthoUrlCleaned = SceneHelper.cleanUrlForTerrainEndpoint(orthoUrlRaw)

    var orthoUrlReformatted = this.routeThroughImageReformatProxy(orthoUrlCleaned)

    fetch(orthoUrlReformatted, {
      mode: 'cors',
      headers: orthoUrlCleaned.indexOf(API_BASE_URL) !== -1 ? window.Utils.tokenAuthHeaders() : {},
    })
      .then((response) => response.blob())
      .then((blob) => {
        var blobUrl = URL.createObjectURL(blob)
        LoadTextureWithHeadersResponseCache[orthoUrlCleaned] = blobUrl

        // embed inside an image to prevent garbage collection?
        if (!window.blobImages) {
          window.blobImages = []
        }
        var blobImage = new Image()
        blobImage.src = blobUrl
        window.blobImages.push(blobImage)
      })
  },
}

SceneHelper.updateCameraUsingSpherical = function (camera, radius, theta, phi, target, resetCameraController) {
  var _spherical = new SphericalZup()
  _spherical.radius = radius
  _spherical.theta = theta
  _spherical.phi = phi
  _spherical.makeSafe()
  var vectorFromSpherical = new THREE.Vector3().setFromSpherical(_spherical)
  //console.log('vectorFromSpherical for theta', vectorFromSpherical, theta)

  //Swap Y and Z coordinates, we use Z as up
  vector = new THREE.Vector3(vectorFromSpherical.x, vectorFromSpherical.z, vectorFromSpherical.y)

  camera.position.copy(target).add(vector)
  camera.up.fromArray([0, 0, 1])
  Utils.lookAtSafe(camera, target)
  camera.updateMatrixWorld()

  if (resetCameraController) {
    editor.controllers.Camera.reset(target, camera.position, camera.up)
  }
}

SceneHelper.getSelectionBox = function () {
  return editor.viewport.selectionBox
}

SceneHelper.prepareShadeTestCase = function (referenceViewid) {
  //Usage in console: JSON.stringify(SceneHelper.prepareShadeTestCase(5), null, indent=2)
  var shadeAnnotations = []
  editor.filter('type', 'OsNode').forEach((node) => {
    shadeAnnotations = shadeAnnotations.concat(node.getShadeAnnotations())
  })
  return {
    dsmUrl: editor.scene.userData.dsmUrl,
    orthoUrl: editor.scene.userData.orthoUrl,
    shadeAnnotations: shadeAnnotations,
    surveyResourceId: ViewHelper.views[referenceViewid].mapData.oblique?.surveyResourceId,
  }
}

SceneHelper.buildDepthMap = function () {
  // Old debugging function for rendering a depth map from a scene
  function download(dataURL, filename) {
    var a = document.createElement('a')
    a.href = dataURL
    a.setAttribute('download', filename)
    a.click()
  }

  var sceneRenderTarget = new THREE.WebGLRenderTarget(1000, 1000)
  sceneRenderTarget.texture.format = THREE.RGBFormat
  sceneRenderTarget.texture.minFilter = THREE.NearestFilter
  sceneRenderTarget.texture.magFilter = THREE.NearestFilter
  sceneRenderTarget.texture.generateMipmaps = false
  sceneRenderTarget.stencilBuffer = false
  sceneRenderTarget.depthBuffer = true
  sceneRenderTarget.depthTexture = new THREE.DepthTexture()
  sceneRenderTarget.depthTexture.type = THREE.UnsignedShortType

  var depthRenderTarget = new THREE.WebGLRenderTarget(1000, 1000)

  // Setup post processing stage

  var depthCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 100)
  var postMaterial = new THREE.ShaderMaterial({
    vertexShader: document.querySelector('#post-vert').textContent.trim(),
    fragmentShader: document.querySelector('#post-frag').textContent.trim(),
    uniforms: {
      cameraNear: { value: depthCamera.near },
      cameraFar: { value: depthCamera.far },
      // cameraNear: { value: 0 },
      // cameraFar: { value: 200 },
      tDiffuse: { value: sceneRenderTarget.texture },
      tDepth: { value: sceneRenderTarget.depthTexture },
    },
  })
  // postMaterial = new THREE.MeshStandardMaterial({
  //   color: 0xff0000,
  // })

  var depthPlane = new THREE.PlaneBufferGeometry(2, 2)
  var depthQuad = new THREE.Mesh(depthPlane, postMaterial)
  depthScene = new THREE.Scene()
  depthScene.add(depthQuad)

  var screenPlane = new THREE.PlaneBufferGeometry(20, 20)
  var screenMaterial = new THREE.MeshPhongMaterial({
    map: depthRenderTarget.texture,
  })
  var screenQuad = new THREE.Mesh(screenPlane, screenMaterial)
  screenQuad.userData.excludeFromExport = true
  screenQuad.z = 0
  editor.addObject(screenQuad)

  var depthRenderer = new THREE.WebGLRenderer({
    canvas: document.getElementById('depthCanvas'),
    antialias: false,
    alpha: false,
    preserveDrawingBuffer: true,
  })
  depthRenderer.setPixelRatio(devicePixelRatio)
  depthRenderer.setSize(1000, 1000, false)
  depthRenderer.setClearColor(0xffffff, 1)
  depthRenderer.clear()

  var depthMapWidthMeters = 20
  var depthMapHeightMeters = 20
  var sceneCamera = new THREE.OrthographicCamera(
    -depthMapWidthMeters / 2,
    depthMapWidthMeters / 2,
    depthMapHeightMeters / 2,
    -depthMapHeightMeters / 2,
    0,
    1000
  )
  sceneCamera.position.fromArray([0, 0, 100])
  sceneCamera.up.copy(new THREE.Vector3(0, 1, 0))
  sceneCamera.lookAt(new THREE.Vector3())
  // var sceneCamera = editor.camera

  depthRenderer.setRenderTarget(sceneRenderTarget)
  depthRenderer.render(editor.scene, sceneCamera)

  // render post FX
  depthRenderer.setRenderTarget(null)
  depthRenderer.render(depthScene, depthCamera)

  var dataURL = depthRenderer.domElement.toDataURL()
  download(dataURL, 'depth.png')
  // window.open(dataURL, '_blank')
}

SceneHelper.loadHorizonDataFromFileContents = function (contents) {
  // Warning: Some sources suggest that horizon are flipped in northern and southern hemispheres.
  // However, SunEye and PVGIS does not flip them so we will not either.
  // We assume azimuth=0 is North for both hemispheres.

  var loadingIssues

  var lines = contents.split(/[/\r/\n]+/)
  if (lines.length < 360) {
    loadingIssues = 'missingValues'
  }

  var horizon = new Array(360).fill(0)

  var parts, bearing, azimuthCorrected
  lines.forEach((line) => {
    let partsAsFloats = line.split(' ').map(parseFloat)
    //Only contains tow parts that can be parsed into numbers
    //Only two columns
    if (partsAsFloats.length > 2 || partsAsFloats.some(isNaN)) {
      loadingIssues = 'error'
    }
    azimuthCorrected = Math.round(partsAsFloats[0])
    bearing = (azimuthCorrected + 360) % 360
    horizon[bearing] = Math.round(partsAsFloats[1])
  })

  return { horizon, loadingIssues }
}

SceneHelper.loadHorizonFromPVGIS = function () {
  var sceneOrigin4326 = editor.scene.sceneOrigin4326
  var url =
    API_BASE_URL +
    'orgs/' +
    window.getStorage().getItem('org_id') +
    `/horizon/?lat=${sceneOrigin4326[1]}&lon=${sceneOrigin4326[0]}`

  fetch(url, {
    headers: window.Utils.tokenAuthHeaders(),
  })
    .then((response) => response.text())
    .then((data) => {
      var { horizon, loadingIssues } = SceneHelper.loadHorizonDataFromFileContents(data)

      if (loadingIssues === 'error') {
        throw new Error()
      }

      editor.scene.horizon = horizon
      SceneHelper.setupHorizon('PGVIS')
    })
    .catch((error) => {
      console.log('Error loading horizon file')
    })
}

SceneHelper.preparePointsFromEagleViewModelData = function (modelData, imageCenterLonLat, modelBoundingBoxLonlat) {
  var LOWEST_GUTTER_HEIGHT_ABOVE_GROUND = 3.3

  // E+ seems to require northorientationDegrees but IA does not
  // Quick method to detect report type from the raw data
  var isInformAdvanced = !!modelData.EAGLEVIEW_EXPORT.STRUCTURES[0].ROOF[0].POINTS.POINT[0]['@coords']

  var pointsRaw = modelData.EAGLEVIEW_EXPORT.STRUCTURES[0].ROOF[0].POINTS.POINT.map((p) =>
    new THREE.Vector3().fromArray(p['@data'].split(',').map((coordinateRaw) => parseFloat(coordinateRaw) / 3.28084))
  )

  var degreesPerMeter = new THREE.Vector2(
    editor.viewport.lonLatFor3DPositionUsingSceneOrigin(new THREE.Vector3(1, 0, 0), imageCenterLonLat).x -
      editor.viewport.lonLatFor3DPositionUsingSceneOrigin(new THREE.Vector3(0, 0, 0), imageCenterLonLat).x,
    editor.viewport.lonLatFor3DPositionUsingSceneOrigin(new THREE.Vector3(0, 1, 0), imageCenterLonLat).y -
      editor.viewport.lonLatFor3DPositionUsingSceneOrigin(new THREE.Vector3(0, 0, 0), imageCenterLonLat).y
  )
  // console.log('degreesPerMeter', degreesPerMeter)

  if (isInformAdvanced) {
    // IA includes lat/lon along with each point which makes it much easier to align points to image
    // because the we know the image center lonlat so we just need to calculate the offset from any point

    var modelCenterLonLatFromSceneOrigin = editor.viewport.lonLatFor3DPositionUsingSceneOrigin(
      pointsRaw[0],
      imageCenterLonLat
    )

    var firstPointLonLat = new THREE.Vector2().fromArray(
      modelData.EAGLEVIEW_EXPORT.STRUCTURES[0].ROOF[0].POINTS.POINT[0]['@coords'].split(',').map((v) => parseFloat(v))
    )

    var deltaLonLat = modelCenterLonLatFromSceneOrigin.clone().sub(firstPointLonLat)
    // console.log('deltaLonLat', deltaLonLat)

    var lowestGutterElevation = Math.min(...pointsRaw.map((point) => point.z))
    var raiseElevation = -lowestGutterElevation + LOWEST_GUTTER_HEIGHT_ABOVE_GROUND

    var modelCorrectionMeters = new THREE.Vector3(
      -deltaLonLat.x / degreesPerMeter.x,
      -deltaLonLat.y / degreesPerMeter.y,
      raiseElevation
    )
    // console.log('modelCorrectionMeters', modelCorrectionMeters)

    return pointsRaw.map((point) => point.clone().add(modelCorrectionMeters))
  } else {
    var northorientationDegrees = parseFloat(modelData.EAGLEVIEW_EXPORT.STRUCTURES[0]['@northorientation'])

    // We do not know the latlon for the world origin (0,0,0) so we just rotate all the points around the origin
    // we will fix the bounding box later to ensure the final latlons are correct.
    // This would be a bad idea if we knew the latlon for world origin, but we don't :-(
    var fixNorthOrientation = function (point) {
      return Utils.rotateAroundPoint(
        point,
        new THREE.Vector3(0, 0, 1),
        northorientationDegrees * THREE.Math.DEG2RAD,
        new THREE.Vector3(0, 0, 0)
      )
    }

    var pointsBeforeOffset = pointsRaw.map((point) => fixNorthOrientation(point))

    // Calculate bounding box of feature coordinates in raw non-north-aligned space
    var modelBoundingBox = new THREE.Box3(
      new THREE.Vector3(
        Math.min(...pointsBeforeOffset.map((p) => p.x)),
        Math.min(...pointsBeforeOffset.map((p) => p.y)),
        Math.min(...pointsBeforeOffset.map((p) => p.z))
      ),
      new THREE.Vector3(
        Math.max(...pointsBeforeOffset.map((p) => p.x)),
        Math.max(...pointsBeforeOffset.map((p) => p.y)),
        Math.max(...pointsBeforeOffset.map((p) => p.z))
      )
    )

    // Lowest point on gutter is set to z=0. But we want ground height to be z=0.
    // Find lowest point in the model and set that to be the ground height.
    //
    // Raise the lowest point up to one storey above ground.
    // @TODO: Ideally we could detect the gutter elevation and set this precisely but currently we do not have that data

    var raiseElevation = -modelBoundingBox.min.z + LOWEST_GUTTER_HEIGHT_ABOVE_GROUND

    // First calculate bounding box of feature coordinates in raw non-north-aligned space
    var modelBoundingBoxCenterLonLat = new THREE.Vector3(
      modelBoundingBoxLonlat.center.x,
      modelBoundingBoxLonlat.center.y,
      modelBoundingBoxLonlat.center.z
    )

    // calculate world position of the model center latlon, we will adjust all the points to match this
    var modelCenterWorld = modelBoundingBox.getCenter(new THREE.Vector3())

    var modelCenterLonLatFromSceneOrigin = editor.viewport.lonLatFor3DPositionUsingSceneOrigin(
      modelCenterWorld,
      imageCenterLonLat
    )

    var deltaLonLat = modelCenterLonLatFromSceneOrigin.clone().sub(modelBoundingBoxCenterLonLat)
    // console.log('deltaLonLat', deltaLonLat)

    var modelCorrectionMeters = new THREE.Vector3(
      -deltaLonLat.x / degreesPerMeter.x,
      -deltaLonLat.y / degreesPerMeter.y,
      raiseElevation
    )
    // console.log('modelCorrectionMeters', modelCorrectionMeters)

    return pointsBeforeOffset.map((point) => point.clone().add(modelCorrectionMeters))
  }
}

SceneHelper.shading288StringTo288Array = function (rawShadingString288, timezoneOffset) {
  // return value is based on "IsSunny" e.g. true=Sunny, false=Shade, null=Sun below horizon.

  var rearrangeHoursForDay = function (rawShadingStringForDay) {
    if (timezoneOffset < 0) {
      return rawShadingStringForDay.slice(timezoneOffset) + rawShadingStringForDay.slice(0, 24 + timezoneOffset)
    } else if (timezoneOffset > 0) {
      // This case would never actually occur in USA
      throw new Error('Not implemented for timezoneOffset > 0')
    } else {
      return rawShadingStringForDay
    }
  }

  var values288 = []

  for (var i = 0; i < 12; i++) {
    // we use the 2nd of each month to ensure that a timezone offset would never wrap 1st jan back into december
    // which would push array indices negative and would require special handling.
    var rawShadingStringForDay = rawShadingString288.slice(i * 24, (i + 1) * 24)
    var shadingStringForDay = rearrangeHoursForDay(rawShadingStringForDay, timezoneOffset)
    values288 = values288.concat(shadingStringForDay.split(''))
  }

  return values288.map((valueString) => {
    if (valueString === '-') {
      return null
    } else if (valueString === '0') {
      // shade
      return false
    } else {
      // sun
      return true
    }
  })
}

SceneHelper.buildSceneFromEagleViewXml = function (
  modelData,
  topImage,
  topImageWorldFileLines,
  topImageSizePixels,
  modelBoundingBoxLonlat,
  shadingPoints,
  timezoneOffset
) {
  /*
  build facets from edges, build edges from nodes.
  */

  editor.scene.sceneOrigin4326 = [
    parseFloat(modelData.EAGLEVIEW_EXPORT.LOCATION['@long']),
    parseFloat(modelData.EAGLEVIEW_EXPORT.LOCATION['@lat']),
  ]

  //@TODO: Detect size of image in pixels from metadata?
  editor.uiPause('ui', 'buildSceneFromEagleViewXml')
  editor.uiPause('render', 'buildSceneFromEagleViewXml')

  var { imageCenterLonLat, sizeMeters } = SceneHelper.getImageCenterAndSize(
    topImageWorldFileLines,
    new THREE.Vector2().fromArray(topImageSizePixels)
  )

  // Set sceneOrigin equal to imageCenterLonLat
  editor.scene.sceneOrigin4326 = [imageCenterLonLat.x, imageCenterLonLat.y]

  // Use image as uploaded ground texture

  editor.scene.groundVariationData({
    ground_imagery_provider: 'upload',
    size: sizeMeters.toArray(),
    url: 'data:image/jpeg;base64,' + topImage,
  })

  // just in case this is required (e.g. in tests when ground may not already be initialized
  // but this is not normally required
  SceneHelper.refreshGround()

  editor.getGround().groundType = 'upload'
  editor.getGround().size = sizeMeters.toArray()
  editor.getGround().loadMaterial()

  var points = SceneHelper.preparePointsFromEagleViewModelData(modelData, imageCenterLonLat, modelBoundingBoxLonlat)

  var objects = {
    facets: {},
    edges: {},
    nodes: {},
  }

  var buildNode = (nodeIdentifier) => {
    if (!objects.nodes[nodeIdentifier]) {
      var pointIndex = modelData.EAGLEVIEW_EXPORT.STRUCTURES[0].ROOF[0].POINTS.POINT.findIndex(
        (item) => item['@id'] === nodeIdentifier
      )
      let node = new OsNode({ position: points[pointIndex] })
      editor.addObject(node)
      objects.nodes[nodeIdentifier] = node
    }
    return objects.nodes[nodeIdentifier]
  }

  var buildEdge = (edgeIdentifier) => {
    if (!objects.edges[edgeIdentifier]) {
      var edgeData = modelData.EAGLEVIEW_EXPORT.STRUCTURES[0].ROOF[0].LINES.LINE.find(
        (item) => item['@id'] === edgeIdentifier
      )
      var pointIdentifiers = edgeData['@path'].split(',')

      var node1 = buildNode(pointIdentifiers[0])
      var node2 = buildNode(pointIdentifiers[1])

      var EV_EDGE_TYPE_MAPPINGS = {
        RIDGE: 'ridge',
        RAKE: 'rake',
        EAVE: 'gutter',
        VALLEY: 'valley',
        HIP: 'hip',
        STEPFLASH: 'default',
        FLASHING: 'default',
      }
      var evEdgeType = edgeData['@type']
      var edgeType = EV_EDGE_TYPE_MAPPINGS[evEdgeType] || 'default'

      var edge = new OsEdge({ nodes: [node1, node2], edgeType: edgeType })

      /*
      if (SCENE_HELPER_DEBUG) {
        edge.edgeIdentifier = edgeIdentifier
        if (!node1.edgeIdentifiers) {
          node1.edgeIdentifiers = [edgeIdentifier]
        } else {
          node1.edgeIdentifiers.push(edgeIdentifier)
        }
        if (!node2.edgeIdentifiers) {
          node2.edgeIdentifiers = [edgeIdentifier]
        } else {
          node2.edgeIdentifiers.push(edgeIdentifier)
        }
      }
      */

      editor.addObject(edge)
      objects.edges[edgeIdentifier] = edge
    }

    return objects.edges[edgeIdentifier]
  }

  var offsetSelfIntersections = (edges) => {
    /*
    Identify any self-intersections
    Fix self intersections by retracting self-intersecting edges so they no longer meet,
    then replace the original point with one of the retracted points.
    1. Identify nodes with self-intersections
    2. Find all edges that connect to these self-intersections
    3. Retract these edges... how?
    
    Beware: This has the side-effects of:
      - adding new OsNodes to the scene and modifying notes for edges
      - injecting nodes into objects.nodes (for debugging purposes only)
    */
    var nodesReferenced = edges.map((e) => e.nodes[0]).concat(edges.map((e) => e.nodes[1]))
    var nodesUnique = nodesReferenced.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    var selfIntersectingNodes = []
    nodesUnique.forEach((nodeToCheck) => {
      let nodeCount = nodesReferenced.filter((_node) => nodeToCheck === _node).length
      if (nodeCount === 4) {
        selfIntersectingNodes.push(nodeToCheck)
      }
    })
    var selfIntersectingEdgePairs = []

    selfIntersectingNodes.forEach((selfIntersectingNode) => {
      // Find the "corners" that reference this node in the facet which identifies the "pairs" of edges.
      // indexes of the edge in this facet's edges list will be adjacent (or last and first)
      for (let i = 0; i < edges.length; i++) {
        var edgeA = edges[i]
        var edgeB = edges[(i + 1) % edges.length]
        if (edgeA.nodes.includes(selfIntersectingNode) && edgeB.nodes.includes(selfIntersectingNode)) {
          selfIntersectingEdgePairs.push({
            corner: selfIntersectingNode,
            edges: [edgeA, edgeB],
          })
        }
      }
    })

    selfIntersectingEdgePairs.forEach((selfIntersectingEdgePair) => {
      // Find bisector of the corner then move 10cm along it
      var otherNodeA = selfIntersectingEdgePair.edges[0].getOtherNode(selfIntersectingEdgePair.corner)
      var otherNodeB = selfIntersectingEdgePair.edges[1].getOtherNode(selfIntersectingEdgePair.corner)
      var directionNormalized = new THREE.Vector3(
        otherNodeA.position.x + otherNodeB.position.x - selfIntersectingEdgePair.corner.position.x * 2,
        otherNodeA.position.y + otherNodeB.position.y - selfIntersectingEdgePair.corner.position.y * 2,
        otherNodeA.position.z + otherNodeB.position.z - selfIntersectingEdgePair.corner.position.z * 2
      ).normalize()

      var offset = directionNormalized.multiplyScalar(0.1)
      var adjustedPosition = selfIntersectingEdgePair.corner.position.clone().add(offset)

      // Now we know the new position, create a node at this position and replace the corner node in these edges
      var adjustedNode = new OsNode({ position: adjustedPosition })
      editor.addObject(adjustedNode)

      // We probably don't need to do this but we are adding a reference to objects.nodes to prevent
      // any mysteries around nodes being used in the model that are not included in objects.nodes
      objects.nodes['adjusted_' + adjustedNode.uuid] = adjustedNode

      if (selfIntersectingEdgePair.edges[0].nodes[0] === selfIntersectingEdgePair.corner) {
        selfIntersectingEdgePair.edges[0].nodes[0] = adjustedNode
      } else {
        selfIntersectingEdgePair.edges[0].nodes[1] = adjustedNode
      }

      if (selfIntersectingEdgePair.edges[1].nodes[0] === selfIntersectingEdgePair.corner) {
        selfIntersectingEdgePair.edges[1].nodes[0] = adjustedNode
      } else {
        selfIntersectingEdgePair.edges[1].nodes[1] = adjustedNode
      }
    })
  }

  var onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
  }

  var removeCyclesFromEdgeIdentifiers = (edgeIdentifiers) => {
    /*
    Handle the scenario where we traverse the same edge twice. This seems like a "hack" that is used to cut a hole is cut out of the polygon
    without supporting real "holes". Sequence:

    Find any edges that are included more than once.
    Strip the start and end edges and all edges in between to remove the "hole"
    */
    var edgesIncludedTwice = edgeIdentifiers
      .filter(
        (edgeIdentifier) => edgeIdentifiers.filter((_edgeIdentifier) => _edgeIdentifier === edgeIdentifier).length === 2
      )
      .filter(onlyUnique)

    if (edgesIncludedTwice.length > 0) {
      var edgeIdentifierToStrip = edgesIncludedTwice[0]
      var edgeIdentifiersFixed = []
      var strip = false
      edgeIdentifiers.forEach((edgeIdentifier) => {
        if (!strip && edgeIdentifier === edgeIdentifierToStrip) {
          // start stripping edgges and ignore this edge too
          strip = true
        } else if (strip && edgeIdentifier === edgeIdentifierToStrip) {
          // stop stripping edgges but we still ignore this edge too
          strip = false
        } else if (!strip) {
          edgeIdentifiersFixed.push(edgeIdentifier)
        }
      })

      if (edgesIncludedTwice.length > 1) {
        return removeCyclesFromEdgeIdentifiers(edgeIdentifiersFixed)
      } else {
        return edgeIdentifiersFixed
      }
    } else {
      return edgeIdentifiers
    }
  }

  var buildFacet = (faceData, isObstruction) => {
    var evEdgeIdentifiers = removeCyclesFromEdgeIdentifiers(faceData.POLYGON['@path'].split(','))

    var edges = []
    evEdgeIdentifiers.forEach((evEdgeIdentifier) => {
      edges.push(buildEdge(evEdgeIdentifier))
    })

    try {
      offsetSelfIntersections(edges)
    } catch (e) {
      console.warn('Error fixing self intersections', faceData, e)
      // Return empty result so it can be ignored and avoid causing problems with other faces
      return
    }

    var facetNodes = []

    // This method fails intermittently when one of the edges is out of sequence and neither of its notes
    // have been processed yet
    // Use another method so we only process nodes in a ring, extending from a node that is already included
    // edges.forEach((edge) => {
    //   // not sure why but sometimes the nodes could be flipped. To avoid this, if we see that the next nodes[0]
    //   // has already been added, use the other node for the edge instead.
    //   if (facetNodes.map((n) => n.uuid).includes(edge.nodes[0].uuid)) {
    //     facetNodes.push(edge.nodes[1])
    //   } else {
    //     facetNodes.push(edge.nodes[0])
    //   }
    // })

    let unprocessedEdges = [...edges]

    var nextEdge

    for (var i = 0; i < edges.length; i++) {
      var lastNodeAdded = facetNodes[facetNodes.length - 1]

      if (facetNodes.length === 0) {
        nextEdge = unprocessedEdges[0]
        facetNodes.push(nextEdge.nodes[0])
      } else {
        // find the edge which is not yet processed and includes the last node added to continue the ring
        nextEdge = unprocessedEdges.find((_edge) => _edge.nodes.includes(lastNodeAdded))
        if (nextEdge) {
          if (!facetNodes.includes(nextEdge.nodes[0])) {
            facetNodes.push(nextEdge.nodes[0])
          } else if (!facetNodes.includes(nextEdge.nodes[1])) {
            facetNodes.push(nextEdge.nodes[1])
          } else {
            console.warn('Both nodes for this edge are already included in the facet nodes')
          }
        } else {
          console.warn('Unexpected format for facet/edges/nodes. Skip facet. faceData:', faceData)

          /*
          if (SCENE_HELPER_DEBUG) {
            // Export to CSV for loading into QGIS
            // Each line is a point, and it's corresponding edge identifier
            var debugPointPairs = edges.map((e) => [e.nodes[0].position, e.nodes[1].position])
            debugPointPairs.forEach((debugPointPair) => {
              editor.addObject(new OsNode({ position: new THREE.Vector3(debugPointPair[0].x, debugPointPair[0].y, 0) }))
              editor.addObject(new OsNode({ position: new THREE.Vector3(debugPointPair[1].x, debugPointPair[1].y, 0) }))
            })

            console.log('debugPointPairs', debugPointPairs)
            var outputCsvLines = ['X,Y,Z,edgeIdentifier']
            edges.forEach((_edge) => {
              outputCsvLines.push(_edge.nodes[0].position.toArray().join(',') + ',' + '')
              outputCsvLines.push(_edge.nodes[1].position.toArray().join(',') + ',' + '')

              let midPoint = new THREE.Vector3(
                (_edge.nodes[0].position.x + _edge.nodes[1].position.x) / 2,
                (_edge.nodes[0].position.y + _edge.nodes[1].position.y) / 2,
                (_edge.nodes[0].position.z + _edge.nodes[1].position.z) / 2
              )
              outputCsvLines.push(midPoint.toArray().join(',') + ',' + _edge.edgeIdentifier)
            })
            console.log(outputCsvLines.join('\n'))
          }
          */

          // Return empty result so it can be ignored and avoid causing problems with other faces
          return
        }
      }
      unprocessedEdges.splice(unprocessedEdges.indexOf(nextEdge), 1)
    }

    var facet = new OsFacet({ nodes: facetNodes, isManaged: true, obstruction: isObstruction })

    /// WTF is this required
    facet.userData.obstruction = isObstruction

    // editor.addObject(facet)
    editor.execute(new AddObjectCommand(facet, undefined, false, window.globalCommandUUID))
    return facet
  }

  // Inform advanced has both obstructions and penetrations. obstructions are much more useful so we load
  // them for Inform Advanced.
  // Essentials+ only has penetrations, so we only load them for Essentials+ which is better than nothing.

  var isInformAdvanced = !!modelData.EAGLEVIEW_EXPORT.STRUCTURES[0].ROOF[0].POINTS.POINT[0]['@coords']

  var extraObstructionType = isInformAdvanced ? 'ROOFOBSTRUCTION' : 'ROOFPENETRATION'

  // Penetrations are flat so we raise them slightly for visibility.
  // Obstructions are already correctly elevated so do not change their elevation
  var ROOF_PENETRATION_EXTRA_ELEVATION = isInformAdvanced ? 0.0 : 0.2

  var nodesWhichAreRoofPenetrations = new Set()

  modelData.EAGLEVIEW_EXPORT.STRUCTURES[0].ROOF[0].FACES.FACE.filter((faceData) =>
    ['ROOF', extraObstructionType].includes(faceData['@type'])
  ).forEach((faceData) => {
    var isObstruction = ['ROOFPENETRATION', 'ROOFOBSTRUCTION'].includes(faceData['@type'])

    var facet = buildFacet(faceData, isObstruction)
    if (facet && isObstruction) {
      facet.vertices.forEach((n) => nodesWhichAreRoofPenetrations.add(n))
    }
  })

  // Slightly raise roof penetrations to make them visible and selectable
  nodesWhichAreRoofPenetrations.forEach((n) => {
    n.position.z += ROOF_PENETRATION_EXTRA_ELEVATION
  })

  editor.filter('type', 'OsFacet').forEach((facet) => {
    // facet.refreshMesh(editor)
    facet.refreshUserData()
    facet.onChange(editor)
  })

  if (shadingPoints) {
    let shadingPointsAll = shadingPoints
      ? shadingPoints.map((shadingPoint) => {
          let position = SceneHelper.positionForLonLatUsingSceneOrigin(
            [shadingPoint[0], shadingPoint[1]],
            editor.scene.sceneOrigin4326
          )
          return {
            x: position[0],
            y: position[1],
            shading288: SceneHelper.shading288StringTo288Array(shadingPoint[2], timezoneOffset),
            diffuse: shadingPoint[3],
          }
        })
      : null

    // Only keep one point per 20cm x 20cm cell, keep the point closest to the center of the cell
    editor.scene.preGeneratedRaytraceResults = SceneHelper.thinPointsWorld(
      EAGLEVIEW_THIN_POINTS_CELL_SIZE_METERS,
      shadingPointsAll
    )
  } else {
    // IMPORTANT NOTE: This is a really nasty hack which allows us to determine if the current design was created
    // from E+ because the value will be null instead of false.
    // We should absolutely improve this, but we will probably revisit adding other EV reports in future, so
    // I am hoping to make the most minimal change at this time.
    editor.scene.preGeneratedRaytraceResults = null
  }

  editor.uiResume('ui', 'buildSceneFromEagleViewXml')
  editor.uiResume('render', 'buildSceneFromEagleViewXml')
}

SceneHelper.getImageCenterAndSize = function (topImageWorldFileLines, imageSizePixels) {
  var sizeDegrees = [imageSizePixels.x * topImageWorldFileLines[0], imageSizePixels.y * topImageWorldFileLines[3]]

  var imageBoundsLonLat = {
    BL: [topImageWorldFileLines[4], topImageWorldFileLines[5]],
    BR: [topImageWorldFileLines[4] + sizeDegrees[0], topImageWorldFileLines[5]],
    TL: [topImageWorldFileLines[4], topImageWorldFileLines[5] + sizeDegrees[1]],
  }

  return {
    imageCenterLonLat: new THREE.Vector3(
      imageBoundsLonLat.BL[0] + sizeDegrees[0] / 2,
      imageBoundsLonLat.BL[1] + sizeDegrees[1] / 2,
      0
    ),
    sizeMeters: new THREE.Vector2(
      Utils.distanceBetweenLocations4326(imageBoundsLonLat.BL, imageBoundsLonLat.BR),
      Utils.distanceBetweenLocations4326(imageBoundsLonLat.TL, imageBoundsLonLat.BL)
    ),
  }
}

SceneHelper.deleteNonSystemObjects = function () {
  // delete non-system objects
  var typesToDelete = ['OsFacet', 'OsTree', 'OsObstruction', 'OsNode', 'OsEdge']
  var removeObjectCmds = []
  typesToDelete.forEach((typeToDelete) => {
    editor.filter('type', typeToDelete).forEach((o) => {
      removeObjectCmds.push(new RemoveObjectCommand(o, false))
    })
  })
  // Use editor.changingHistory to avoid chaos with facet trying to rebuild themselves during deletion
  editor.changingHistory = true
  editor.execute(new MultiCmdsCommand(removeObjectCmds))
  editor.changingHistory = false
}

SceneHelper.restartDesignWithNoneView = function (keepSystems) {
  editor.uiPause('ui', 'restartDesignWithNoneView')
  editor.uiPause('render', 'restartDesignWithNoneView')

  if (!keepSystems) {
    window.editor.clear()
  } else {
    SceneHelper.deleteNonSystemObjects()
  }
  window.SceneHelper.addAnchorAndLights(window.editor)
  window.SceneHelper.createFirstSystem()

  const sceneOrigin4326 = window.AccountHelper.sceneOrigin4326FromSceneOrProject()

  SceneHelper.refreshGround()

  window.SceneHelper.startDesignMode(
    sceneOrigin4326,
    window.WorkspaceHelper.project?.country_iso2,
    window.WorkspaceHelper.project?.state,
    { map_type: 'None' },
    editor.scene.timezoneOffset
  )

  editor.uiResume('ui', 'restartDesignWithNoneView')
  editor.uiResume('render', 'restartDesignWithNoneView')
}

SceneHelper.loadEagleViewReport = async function (reportId, reportType) {
  var success = function (dataGzipped) {
    SceneHelper.isLoadingEagleViewReport = false
    editor.signals.viewsChanged.dispatch()

    var data = CompressionHelper.decompress(dataGzipped, true)

    SceneHelper.restartDesignWithNoneView(true)

    SceneHelper.buildSceneFromEagleViewXml(
      data.model_json,
      data.top_image,
      data.top_image_world_file_lines,
      data.top_image_size_pixels,
      data.bounding_box_lonlat,
      data.shading_points,
      projectForm.getState().values.timezone_offset
    )

    if (editor.getSystems().some((s) => s.moduleQuantity() > 0)) {
      window.Designer.showNotification(EAGLEVIEW_EXISTING_PANEL_GROUP_ALIGNMENT_MESSAGE, 'info', {
        autoHideDuration: 10000,
      })
    }
  }

  var error = function (data, textStatus) {
    SceneHelper.isLoadingEagleViewReport = false
    editor.signals.viewsChanged.dispatch()

    console.error(textStatus, data)
  }

  var projectId = window.projectForm?.getState()?.values?.id

  SceneHelper.isLoadingEagleViewReport = true

  return $.ajax({
    type: 'GET',
    url:
      API_BASE_URL +
      'orgs/' +
      window.getStorage().getItem('org_id') +
      '/eagleview/get_report_files/' +
      reportId +
      '/?use_gzip=true&report_type=' +
      reportType +
      '&project_id=' +
      projectId,
    contentType: 'text/plain',
    headers: Utils.tokenAuthHeaders({
      'X-CSRFToken': getCookie('csrftoken'),
    }), //cors for django
    success: success,
    error: error,
  })
}

SceneHelper.calculateDetectedValues = function (panelGroup, facet) {
  var bbFacet = window.Utils.getBoundingBoxWithAzimuthAndSlope(facet, 0, 0)

  var facetAzimuthNorthUp = (facet.azimuth + 180) % 360

  var bbAlignedToFacet = window.Utils.getBoundingBoxWithAzimuthAndSlope(facet, facetAzimuthNorthUp, facet.slope - 90)
  var bbSize = bbAlignedToFacet.getSize(new THREE.Vector3())

  // This variation allows us to calculate the real elevation of the facet which is not possible
  // when the slope is applied to the bounding box preparation.
  var bbAlignedToFacetAzimuthOnly = window.Utils.getBoundingBoxWithAzimuthAndSlope(facet, facetAzimuthNorthUp, 0)
  var bbAlignedToFacetAzimuthOnlySize = bbAlignedToFacetAzimuthOnly.getSize(new THREE.Vector3())

  var vectorFromCentroidToFacetLeftbbEdge = new THREE.Vector3(-bbSize.x / 2, 0, 0).applyAxisAngle(
    new THREE.Vector3(0, 0, 1),
    facetAzimuthNorthUp * THREE.Math.DEG2RAD
  )

  var facetLeftEdge = bbAlignedToFacetAzimuthOnly.getCenter().add(vectorFromCentroidToFacetLeftbbEdge)

  var bounds = panelGroup.getBounds()

  // Get top-left corner for all modules in the array
  // Beware: directions of row/col are very confusing compared to world units.
  var panelGroupTopLeft = panelGroup.pointOnCell(0.5, -0.5, [bounds[2], bounds[1]], true, false, 0)

  var facetTopLeft = bbFacet
    .getCenter(new THREE.Vector3())
    .add(
      new THREE.Vector3(-bbAlignedToFacetAzimuthOnlySize.x / 2, 0, 0).applyAxisAngle(
        new THREE.Vector3(0, 0, -1),
        facetAzimuthNorthUp * THREE.Math.DEG2RAD
      )
    )
    .add(
      new THREE.Vector3(0, bbAlignedToFacetAzimuthOnlySize.y / 2, bbAlignedToFacetAzimuthOnlySize.z / 2).applyAxisAngle(
        new THREE.Vector3(0, 0, -1),
        facetAzimuthNorthUp * THREE.Math.DEG2RAD
      )
    )

  var vectorHorizontalAlongTopOfPanelGrop = new THREE.Vector3(1, 0, 0).applyAxisAngle(
    new THREE.Vector3(0, 0, -1),
    facetAzimuthNorthUp * THREE.Math.DEG2RAD
  )
  var leftPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(vectorHorizontalAlongTopOfPanelGrop, facetTopLeft)

  var vectorSlopedUpLeftSideOfPanelGrop = new THREE.Vector3(0, 1, 0)
    .applyAxisAngle(new THREE.Vector3(1, 0, 0), facet.slope * THREE.Math.DEG2RAD)
    .applyAxisAngle(new THREE.Vector3(0, 0, -1), facetAzimuthNorthUp * THREE.Math.DEG2RAD)

  var topPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(vectorSlopedUpLeftSideOfPanelGrop, facetTopLeft)

  // Project panelGroupTopLeft onto each of the planes. Use the distance between the point and each projection onto each plane.
  var pointProjectedToLeftPlane = leftPlane.projectPoint(panelGroupTopLeft, new THREE.Vector3())
  var pointProjectedToTopPlane = topPlane.projectPoint(panelGroupTopLeft, new THREE.Vector3())

  return {
    width: bbSize.x,
    length: bbSize.y,
    height: Math.min(...facet.vertices.map((v) => v.position.z)),
    topLeftPosition: {
      x: new THREE.Vector3().subVectors(pointProjectedToLeftPlane, panelGroupTopLeft).length(),
      y: new THREE.Vector3().subVectors(pointProjectedToTopPlane, panelGroupTopLeft).length(),
    },
  }
}

SceneHelper.thinPointsWorld = (cellSizeMeters, points) => {
  // cells are stored as a dict with key = stringified coordinates
  // value is a dict with keys: point (reference to original point), distance from center of cell
  // we will keep the closest point to the center of each cell to evenly spread the samples
  var cells = {}

  points.forEach((point) => {
    var cellFractional = new THREE.Vector2(point.x / cellSizeMeters, point.y / cellSizeMeters)
    var cellFloor = new THREE.Vector2(Math.floor(cellFractional.x), Math.floor(cellFractional.y))
    var key = cellFloor.x + '_' + cellFloor.y

    // overwrite cellFractional for speed since we don't need it anymore
    // Manhattan distance is sufficient and much faster
    var distanceFromCellCenterMeters =
      Math.abs(cellFractional.x - cellFloor.x) + Math.abs(cellFractional.y - cellFloor.y)

    if (!cells[key]) {
      cells[key] = {
        point: point,
        distance: distanceFromCellCenterMeters,
      }
    } else if (distanceFromCellCenterMeters < cells[key].distance) {
      cells[key].point = point
      cells[key].distance = distanceFromCellCenterMeters
    }
  })

  return Object.values(cells).map((cell) => cell.point)
}

SceneHelper.thinPointsLonLat = (cellSizeMeters, points) => {
  //cells are the same size in both axes (in meters)

  // calculate cell size in units of lon/lat based on the latitude for the first point.
  var lonlat = new THREE.Vector2(points[0].lon, points[0].lat)
  var lonlatRight = new THREE.Vector2(lonlat.x + 1.0, lonlat.y)
  var lonlatUp = new THREE.Vector2(lonlat.x, lonlat.y + 1.0)

  // meters per degree of lon/lat, respectively
  var metersPerDegree = new THREE.Vector2(
    Utils.distanceBetweenLocations4326(lonlat.toArray(), lonlatRight.toArray()),
    Utils.distanceBetweenLocations4326(lonlat.toArray(), lonlatUp.toArray())
  )

  // degrees per meter multiplied by cellSizeMeters
  var cellSizeDegrees = new THREE.Vector2(1 / metersPerDegree.x, 1 / metersPerDegree.y).multiplyScalar(cellSizeMeters)

  // cells are stored as a dict with key = stringified coordinates
  // value is a dict with keys: point (reference to original point), distance from center of cell
  // we will keep the closest point to the center of each cell to evenly spread the samples
  var cells = {}

  points.forEach((point) => {
    var cellFractional = new THREE.Vector2(point.lon / cellSizeDegrees.x, point.lat / cellSizeDegrees.y)
    var cellFloor = new THREE.Vector2(Math.floor(cellFractional.x), Math.floor(cellFractional.y))
    var key = cellFloor.x + '_' + cellFloor.y

    // overwrite cellFractional for speed since we don't need it anymore
    var distanceFromCellCenterMeters = new THREE.Vector2(
      metersPerDegree.x * (cellFractional.x - cellFloor.x) * cellSizeDegrees.x,
      metersPerDegree.y * (cellFractional.y - cellFloor.y) * cellSizeDegrees.y
    ).length()

    if (!cells[key]) {
      cells[key] = {
        point: point,
        distance: distanceFromCellCenterMeters,
      }
    } else if (distanceFromCellCenterMeters < cells[key].distance) {
      cells[key].point = point
      cells[key].distance = distanceFromCellCenterMeters
    }
  })

  return Object.values(cells).map((cell) => cell.point)
}

SceneHelper.positionForLonLatUsingSceneOrigin = function (lonlat, sceneOrigin4326) {
  // use sceneOrigin to calculate 3D point for this lonlat
  var sceneOrigin3857 = ol.proj.transform(sceneOrigin4326, 'EPSG:4326', 'EPSG:3857')
  var position3857 = ol.proj.transform(lonlat, 'EPSG:4326', 'EPSG:3857')
  var _webMercatorMetersToRealWorldMeters = Utils.webMercatorMetersToRealWorldMeters(sceneOrigin4326[1])
  return [
    (position3857[0] - sceneOrigin3857[0]) * _webMercatorMetersToRealWorldMeters,
    (position3857[1] - sceneOrigin3857[1]) * _webMercatorMetersToRealWorldMeters,
  ]
}

SceneHelper.positionFrom3857UsingSceneOrigin = function (position3857, sceneOrigin4326) {
  // use sceneOrigin to calculate 3D point for this lonlat
  var sceneOrigin3857 = ol.proj.transform(sceneOrigin4326, 'EPSG:4326', 'EPSG:3857')
  var _webMercatorMetersToRealWorldMeters = Utils.webMercatorMetersToRealWorldMeters(sceneOrigin4326[1])
  return [
    (position3857[0] - sceneOrigin3857[0]) * _webMercatorMetersToRealWorldMeters,
    (position3857[1] - sceneOrigin3857[1]) * _webMercatorMetersToRealWorldMeters,
    position3857[2] || position3857[2] === 0 ? position3857[2] : undefined,
  ]
}

SceneHelper.scenePositionsTo3857UsingSceneOrigin = function (positions, sceneOrigin4326) {
  var sceneOrigin3857 = ol.proj.transform(sceneOrigin4326, 'EPSG:4326', 'EPSG:3857')
  var _webMercatorMetersToRealWorldMeters = Utils.webMercatorMetersToRealWorldMeters(sceneOrigin4326[1])
  return positions.map((p) => [
    p.x * _webMercatorMetersToRealWorldMeters + sceneOrigin3857[0],
    p.y * _webMercatorMetersToRealWorldMeters + sceneOrigin3857[1],
  ])
}

SceneHelper.loadFacetsFromSiteModelGeoJson = function (siteModelGeoJson) {
  editor.uiPause('ui', 'loadFacetsFromSiteModelGeoJson')
  editor.uiPause('render', 'loadFacetsFromSiteModelGeoJson')

  var terrainPositionZ = editor.getTerrain()?.position?.z || 0

  var from3857 = function (position3857) {
    var position = SceneHelper.positionFrom3857UsingSceneOrigin(position3857, editor.scene.sceneOrigin4326)
    position[2] += terrainPositionZ
    return position
  }

  // We will create dummy facet/edge/node identifiers using indexes
  var objects = {
    // key = facetIndex
    facets: {},

    // key = facetIndex + firstNodeIndex
    edges: {},

    // key = facetIndex + firstNodeIndex
    nodes: {},
  }

  var buildNode = (c, nodeIdentifier) => {
    if (!objects.nodes[nodeIdentifier]) {
      node = new OsNode({ position: new THREE.Vector3().fromArray(from3857(c)) })
      editor.addObject(node)
      objects.nodes[nodeIdentifier] = node
    }
    return objects.nodes[nodeIdentifier]
  }

  var buildEdge = (c1, c2, facetIndex, edgeIndex, edgeCount) => {
    let edgeIdentifier = facetIndex + '_' + edgeIndex
    if (!objects.edges[edgeIdentifier]) {
      var node1 = buildNode(c1, facetIndex + '_' + edgeIndex)
      var node2 = buildNode(c2, facetIndex + '_' + ((edgeIndex + 1) % edgeCount))

      var edgeType = 'default'

      var edge = new OsEdge({ nodes: [node1, node2], edgeType: edgeType })

      editor.addObject(edge)
      objects.edges[edgeIdentifier] = edge
    }
    return objects.edges[edgeIdentifier]
  }

  var buildFacet = (feature, facetIndex) => {
    // coordinates[0] assumes there is a polygon without any holes and not a multiPloygon
    // we strip off the last coordinate because GeoJson repeats the first coordinate at the end
    var coordinates = feature.geometry.coordinates[0].slice(0, -1)

    var edges = []
    for (let i = 0; i < coordinates.length; i++) {
      // ensure the nextFacetIndex for the final node wraps back to 0
      edges.push(buildEdge(coordinates[i], coordinates[i + 1], facetIndex, i, coordinates.length))
    }

    var facetNodes = []

    let unprocessedEdges = [...edges]

    var nextEdge

    for (var i = 0; i < edges.length; i++) {
      var lastNodeAdded = facetNodes[facetNodes.length - 1]

      if (facetNodes.length === 0) {
        nextEdge = unprocessedEdges[0]
        facetNodes.push(nextEdge.nodes[0])
      } else {
        // find the edge which is not yet processed and includes the last node added to continue the ring
        nextEdge = unprocessedEdges.find((_edge) => _edge.nodes.includes(lastNodeAdded))
        if (nextEdge) {
          if (!facetNodes.includes(nextEdge.nodes[0])) {
            facetNodes.push(nextEdge.nodes[0])
          } else if (!facetNodes.includes(nextEdge.nodes[1])) {
            facetNodes.push(nextEdge.nodes[1])
          } else {
            console.warn('Both nodes for this edge are already included in the facet nodes')
          }
        } else {
          console.warn('Unexpected format for facet/edges/nodes. Skip facet. feature:', feature)

          // Return empty result so it can be ignored and avoid causing problems with other faces
          return
        }
      }
      unprocessedEdges.splice(unprocessedEdges.indexOf(nextEdge), 1)
    }

    var facet = new OsFacet({ nodes: facetNodes, isManaged: false, snapToTerrain: false })

    editor.execute(new AddObjectCommand(facet, undefined, false, window.globalCommandUUID))

    let facetIdentifier = String(facetIndex)
    objects.facets[facetIdentifier] = facetIdentifier

    return facet
  }

  siteModelGeoJson.features
    .filter((feature) => feature.geometry.type === 'Polygon')
    .forEach((feature, featureIndex) => {
      var facet = buildFacet(feature, featureIndex)
    })

  editor.filter('type', 'OsFacet').forEach((facet) => {
    facet.refreshUserData()
    facet.onChange(editor)
  })

  editor.uiResume('ui', 'loadFacetsFromSiteModelGeoJson')
  editor.uiResume('render', 'loadFacetsFromSiteModelGeoJson')
}

SceneHelper.getDefaultSetbacksArray = function () {
  // See setback_config.py for format of setbacks

  return [
    window.projectForm.getState()?.values?.configuration?.setbacks_arrays ||
      window.projectForm.getState()?.values?.configuration?.setbacks_default ||
      0,
    (window.projectForm.getState()?.values?.configuration?.setbacks_ridge || 0) -
      (window.projectForm.getState()?.values?.configuration?.setbacks_arrays || 0),
    (window.projectForm.getState()?.values?.configuration?.setbacks_gutter || 0) -
      (window.projectForm.getState()?.values?.configuration?.setbacks_arrays || 0),
    (window.projectForm.getState()?.values?.configuration?.setbacks_rake || 0) -
      (window.projectForm.getState()?.values?.configuration?.setbacks_arrays || 0),
  ]
}

SceneHelper.convertActiveEquipmentToSystemTemplate = function (calculator_id, equipment, constraints) {
  /* If equipment not supplied, create a dummy system template using only the selected module type */

  var componentsAndQuantities = []

  if (!equipment) {
    componentsAndQuantities.push({
      component_type: 'module',
      // specify "code" not "code_external" to use the internal code
      code: editor.selectedSystem.moduleType().code,
      quantity: 0,
    })
  } else {
    if (equipment.panel) {
      componentsAndQuantities.push({
        component_type: 'module',
        code_external: equipment.panel.modelId, // Q.PEAK DUO BLK ML-G10.a+ 400
        quantity: 0,
      })
    }

    if (equipment.microInverter) {
      componentsAndQuantities.push({
        component_type: 'inverter',
        code_external: equipment.microInverter.modelId, // IQ7+
        quantity: 0,
      })
    }

    if (equipment.stringInverter) {
      componentsAndQuantities.push({
        component_type: 'inverter',
        code_external: equipment.stringInverter.modelId, // SE6000H-USxxxBNC4 (Inverter)
        quantity: 0,
      })
    }

    if (equipment.optimizer) {
      componentsAndQuantities.push({
        component_type: 'other',
        code_external: equipment.optimizer.modelId, // P401-5NM4MRMS-NM29 (Optimizer)
        quantity: 0,
      })
    }

    if (equipment.monitor) {
      componentsAndQuantities.push({
        component_type: 'other',
        code_external: equipment.monitor.modelId, // ENV-IQ-AM1-240M
        quantity: 0,
      })
    }
  }

  var systemTemplate = {
    uuid: 'uuidA',
    name: 'Auto-Design System',
    calculator_id: calculator_id,
    components_and_quantities: componentsAndQuantities,
    pricing_scheme_id: null,
    payment_options_override: null,
    azimuth_from_to: [
      Utils.getValueOrDefault(constraints?.azimuth_from_to?.min) || 0,
      Utils.getValueOrDefault(constraints?.azimuth_from_to?.max) || 0,
    ],
    constraints: [
      {
        type: 'annual_kwh',
        min: Utils.getValueOrDefault(constraints?.annual_kwh?.min, null),
        max: Utils.getValueOrDefault(constraints?.annual_kwh?.max, 1000000),
      },
      {
        type: 'module_quantity',
        min: Utils.getValueOrDefault(constraints?.module_quantity?.min, 1),
        max: Utils.getValueOrDefault(constraints?.module_quantity?.max, 10000),
      },
      {
        type: 'module_quantity_per_array',
        min: Utils.getValueOrDefault(constraints?.module_quantity_per_array?.min, 1),
        max: Utils.getValueOrDefault(constraints?.module_quantity_per_array?.max, null),
      },
    ],
  }

  return systemTemplate
}

SceneHelper.autoDesignRunAndLoadFromEquipment = function (
  calculator_id,
  setbackDistance,
  equipment,
  mappingConfigDistributor,
  facetsMode,
  lonlat,
  constraints,
  customData,
  runShading,
  runCalcs,
  dataSource
) {
  var systemTemplatesJson = [SceneHelper.convertActiveEquipmentToSystemTemplate(calculator_id, equipment, constraints)]

  var facetsGeoJson =
    facetsMode === 'auto' && editor.filter('type', 'OsFacet').length ? GeoJSONHelper.toGeoJSON(editor) : null

  return SceneHelper.autoDesignRunAndLoadV1(
    setbackDistance,
    systemTemplatesJson,
    mappingConfigDistributor,
    facetsGeoJson,
    lonlat,
    customData,
    runShading,
    runCalcs,
    dataSource
  )
}

SceneHelper.setComponents = function (componentCodesAndQuantities, keepExistingComponents, systemUuid) {
  return new Promise((resolve, reject) => {
    /*

    Auto-activates any components that are not already activated

    componentCodesAndQuantities sample data format: [{
      code: str
      quantity: int
    }]
    */
    // activate components first if necessary
    // AccountHelper.debouncedActivateComponents()
    const componentCodesMissing = componentCodesAndQuantities
      .filter(
        (componentCodeAndQuantity) => !AccountHelper.getComponentActivationFromCode(componentCodeAndQuantity.code)
      )
      .map((componentCodeAndQuantity) => componentCodeAndQuantity.code)

    const setComponentsWhenReady = () => {
      const system = systemUuid && systemUuid !== 'selected' ? editor.objectByUuid(systemUuid) : editor.selectedSystem
      // @todo merge commands

      var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()

      if (!keepExistingComponents) {
        system.clearComponents(commandUUID)
      }

      componentCodesAndQuantities.forEach((componentCodeAndQuantity) => {
        let componentType = AccountHelper.getComponentActivationFromCode(componentCodeAndQuantity.code)

        if (componentType instanceof ModuleType) {
          window.editor.execute(
            new window.UpdateElectricalsCommand(system, 'setModuleTypeByModuleId', componentType.id, system.moduleId)
          )
        } else if (componentType instanceof InverterType) {
          editor.execute(
            new AddObjectCommand(new OsInverter({ inverter_id: componentType.id }), system, false, commandUUID)
          )
        } else if (componentType instanceof BatteryType) {
          editor.execute(
            new AddObjectCommand(new OsBattery({ battery_id: componentType.id }), system, false, commandUUID)
          )
        } else if (componentType instanceof OtherType) {
          editor.execute(
            new AddObjectCommand(
              new OsOther({ other_id: componentType.id, quantity: componentCodeAndQuantity?.quantity || 1 }),
              system,
              false,
              commandUUID
            )
          )
        }
      })
      resolve()
    }

    if (componentCodesMissing.length > 0) {
      AccountHelper.debouncedActivateComponents(componentCodesMissing, setComponentsWhenReady)
    } else {
      setComponentsWhenReady()
    }
  })
}

SceneHelper.addOpenLayersUtmCoordinateTransforms = function () {
  /*
  See https://stackoverflow.com/questions/68801445/how-can-i-display-the-global-utm-grid-on-a-map-in-openlayers
  This function adds the ability to convert between any UTM and lat/lon coordinates in OpenLayers.
  */
  const utmProjs = []
  for (let zone = 1; zone <= 60; zone++) {
    const code = 'EPSG:' + (32600 + zone)
    proj4.defs(code, '+proj=utm +zone=' + zone + ' +ellps=WGS84 +datum=WGS84 +units=m +no_defs')
    ol.proj.proj4.register(proj4)
    utmProjs[zone] = ol.proj.get(code)
  }

  const llProj = ol.proj.get('EPSG:4326')

  const midpointX = 500000
  const width = midpointX * 2
  const xOffset = 100 * 60 * width

  function ll2utm(ll) {
    //const world = Math.floor((ll[0] + 180) / 360);
    const lon = (((ll[0] % 360) + 540) % 360) - 180 // normalise any wrapx
    const lat = ll[1]
    const zone = Math.floor((180 + lon) / 6) + 1
    const zoneCoord = ol.proj.transform([lon, lat], llProj, utmProjs[zone])
    const coord = [xOffset + zoneCoord[0] + (zone - 1) * width, zoneCoord[1]]
    //coord[0] += world * 60 * width;
    return coord
  }

  function utm2ll(coord) {
    //const world = Math.floor((coord[0] - xOffset) / (60 * width));
    const zone = (Math.floor(coord[0] / width) % 60) + 1
    const c0 = coord[0] % width
    const c1 = coord[1]
    const ll = ol.proj.transform([c0, c1], utmProjs[zone], llProj)
    if (Math.floor((180 + ll[0]) / 6) + 1 != zone) {
      ll[0] = (zone - (c0 < midpointX ? 1 : 0)) * 6 - 180
    }
    //ll[0] += world * 360;
    return ll
  }

  const UTM = new ol.proj.Projection({
    code: 'GlobalUTM',
    units: 'm',
    extent: [xOffset, -10000000, xOffset + 60 * width, 10000000],
    //global: true
  })

  ol.proj.addProjection(UTM)
  ol.proj.addCoordinateTransforms(llProj, UTM, ll2utm, utm2ll)
}

window.OSAD = {
  scriptLoaded: false,

  /**
   * Quick way to store the world file from the last tif that was loaded. This avoids needing to actually persist this
   * data because we only use it for the download training package feature.
   */
  lastWorldFileLines: null,
  lastEpsgCode: null,

  loadScript: (scriptUrl, callback) => {
    var script = document.createElement('script')
    script.src = scriptUrl
    script.async = true
    document.head.appendChild(script)
    if (callback) {
      script.onload = callback
    }
  },
  // similar to loadScript but return a promise that resolves after script has loaded
  loadScriptPromise: (scriptUrl) => {
    if (OSAD.scriptLoaded) {
      return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
      OSAD.loadScript(scriptUrl, () => {
        OSAD.scriptLoaded = true
        resolve()
      })
    })
  },

  /**
   * Adapted from editor.loadTexturedDsm. That should really be refactored to be more re-usable but we are
   * not changing it now to avoid regression error risk.
   */
  injectProjectIdToProxyUrl: (url, projectId) => {
    if (!url.includes('?request=')) {
      return url
    }
    const urlRequestParts = JSON.parse(window.atob(url.split('?request=')[1]))
    urlRequestParts['project_id'] = projectId
    return url.split('?request=')[0] + '?request=' + window.btoa(JSON.stringify(urlRequestParts))
  },

  downloadBinaryFile: async (url) => {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const contentType = response.headers.get('Content-Type')
    const blob = await response.blob()
    return { blob, contentType }
  },

  addBinaryUrlToZip: async (url, fileName, zip) => {
    const fileData = await OSAD.downloadBinaryFile(url)
    return OSAD.addBinaryFileToZip(fileData, fileName, zip)
  },

  addBinaryFileToZip: async (fileData, fileName, zip) => {
    return zip.file(fileName, fileData.blob, { binary: true })
  },

  addMetaToZip: async (zip) => {
    let projectData = projectForm.getState().values
    return zip.file(
      'meta.json',
      JSON.stringify(
        {
          environment: window.ENV,
          project: {
            id: projectData.id,
            org_id: projectData.org_id,
            lon: projectData.lon,
            lat: projectData.lat,
            address: projectData.address,
            locality: projectData.locality,
            zip: projectData.zip,
            state: projectData.state,
            country_iso2: projectData.country_iso2,
          },
        },
        null,
        2
      )
    )
  },

  addJpwToZip: async (worldFileLines, filename, zip) => {
    return zip.file(filename, worldFileLines.join('\n'))
  },

  addAuxXmlToZip: async (epsgCode, filename, zip) => {
    return zip.file(filename, `<PAMDataset><SRS>EPSG:${epsgCode}</SRS></PAMDataset>`)
  },

  addTextFileToZip: async (zip, filename, value) => {
    return zip.file(filename, value)
  },

  addGeoJsonToZip: async (zip) => {
    return zip.file(
      'objects.geojson',
      JSON.stringify(
        GeoJSONHelper.toGeoJSON(editor, editor.scene, {
          applySetbacks: false,
          removeObstructions: false,
          includeEdges: true,
          includeObstructions: true,
          includeParcels: false,
          notifyErrors: true,
        })
      )
    )
  },

  addParcelGeoJsonToZip: async (zip) => {
    var parcelsGeoJSON = GeoJSONHelper.toGeoJSON(editor, editor.scene, {
      applySetbacks: false,
      removeObstructions: false,
      includeEdges: false,
      includeObstructions: false,
      includeParcels: true,
      epsgCode: 3857,
      notifyErrors: true,
    })
    parcelsGeoJSON.features = parcelsGeoJSON.features.filter((feature) => feature.properties.type === 'parcel')
    if (parcelsGeoJSON.features.length > 0) {
      return zip.file('parcel.geojson', JSON.stringify(parcelsGeoJSON))
    }
  },

  getWorldFileLinesAdjusted: (lastWorldFileLines) => {
    // Nearmap currently loads 4x resolution images for ortho texture. It is tricky to detect this from the texture
    // so we just check the map type instead.
    var resolutionMultiplier = MapHelper.activeMapInstance.mapType === 'Nearmap3D' ? 0.25 : 1
    var lastWorldFileLinesAdjusted = [
      lastWorldFileLines[0] * resolutionMultiplier,
      lastWorldFileLines[1],
      lastWorldFileLines[2],
      lastWorldFileLines[3] * resolutionMultiplier,
      lastWorldFileLines[4],
      lastWorldFileLines[5],
    ]
    return lastWorldFileLinesAdjusted
  },

  downloadTrainingPackage: async (options = {}) => {
    await OSAD.loadScriptPromise('/js/jszip.min.js')

    const projectData = projectForm.getState().values
    const rgbUrl = OSAD.injectProjectIdToProxyUrl(editor.scene.orthoUrl, projectData.id)
    const dsmUrl = OSAD.injectProjectIdToProxyUrl(editor.scene.dsmUrl, projectData.id)

    const lonlatString = `${projectData.lon}_${projectData.lat}`
    const zip = new JSZip()

    await OSAD.addBinaryUrlToZip(rgbUrl, 'rgb.jpg', zip)
    await OSAD.addBinaryUrlToZip(dsmUrl, 'dsm.tif', zip)

    if (window.OSAD.lastWorldFileLines && window.OSAD.lastEpsgCode) {
      await OSAD.addJpwToZip(window.OSAD.getWorldFileLinesAdjusted(window.OSAD.lastWorldFileLines), 'rgb.jpw', zip)
      await OSAD.addAuxXmlToZip(window.OSAD.lastEpsgCode, 'rgb.jpg.aux.xml', zip)
    }

    await OSAD.addGeoJsonToZip(zip)
    await OSAD.addMetaToZip(zip)
    await OSAD.addParcelGeoJsonToZip(zip)

    if (options?.includeBuildingInsights !== false) {
      const buildingInsightsUrl = `${API_BASE_URL}orgs/${window.getStorage().getItem('org_id')}/projects/${
        projectData.id
      }/get_building_data/?lon=${projectData.lon}&lat=${projectData.lat}`
      const buildingInsights = await fetch(buildingInsightsUrl, {
        mode: 'cors',
        headers: window.Utils.tokenAuthHeaders(),
      }).then((response) => response.json())
      await OSAD.addTextFileToZip(zip, 'buildingInsights.json', JSON.stringify(buildingInsights))
    }

    if (options?.includeParcelData === true) {
      const parcelDataUrl = `${API_BASE_URL}orgs/${window.getStorage().getItem('org_id')}/projects/${
        projectData.id
      }/parcel/?lon=${projectData.lon}&lat=${projectData.lat}`
      const parcelData = await fetch(parcelDataUrl, {
        mode: 'cors',
        headers: window.Utils.tokenAuthHeaders(),
      }).then((response) => response.json())
      await OSAD.addTextFileToZip(zip, 'regrid.geojson', JSON.stringify(parcelData))
    }

    const content = await zip.generateAsync({ type: 'blob' })

    const zipBlob = new Blob([content], { type: 'application/zip' })
    const zipUrl = URL.createObjectURL(zipBlob)

    const link = document.createElement('a')
    link.href = zipUrl
    link.download = `bundle_${lonlatString}.zip`
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(zipUrl)
  },
}
