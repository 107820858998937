/*
@TODO: MapData automatically has getters/setter functions for each property name
@TODO: Actual values are stored in this._{propertyName}
@TODO: Call getter/setter with empty value to get, supply a single argument to set
*/
function MapData(data) {
  var _this = this
  MapData.prototype.properties.forEach(function (property) {
    _this[property] = data[property]
  })

  // Cleanup and apply defaults

  // Only need to set either center or sceneOrigin
  if (!this.center && this.sceneOrigin) {
    this.center = this.sceneOrigin.slice()
  } else if (!this.sceneOrigin && this.center) {
    this.sceneOrigin = this.center.slice()
  }

  return this
}

MapData.prototype.toObject = function () {
  var _this = this
  var output = {}
  MapData.prototype.properties.forEach(function (property) {
    output[property] = _this[property]
  })
  return output
}

MapData.createMapData = function createMapData(options) {
  var mapData = {
    mapType: options.mapType,
    center: options.center,
    zoomTarget: options.zoomTarget || 19,
    maxZoom: null,
    sceneOrigin: options.sceneOrigin || options.center.slice(),
    scale: options.scale || 1,
    heading: 0,

    // if oblique is not supplied and the MapType has oblique data as part of it's definition, the ninject here
    // this allows a simple createMapData() which leverages the initial config/registration for the Map type
    // so we don't need to supply it when creating default map data
    oblique: options.oblique || window.MAP_TYPES[options.mapType]?.oblique || null,
    pano: null,
  }

  return new MapData(mapData)
}

MapData.getLabel = function (mapData) {
  let className = window.MAP_TYPES[mapData.mapType]?.className
  if (className && window[className] && window[className].getLabel) {
    return window[className].getLabel(mapData)
  }

  switch (mapData.mapType) {
    case 'Turntable':
      return {
        full: 'Turntable',
        major: 'Turntable',
        minor: 'No Imagery',
      }
    case 'Nearmap3D':
      return {
        full: 'Nearmap 3D',
        major: 'NM3D',
        minor: '3D DSM',
      }
    case 'Google3D':
      return {
        full: 'Google 3D',
        major: 'G3D',
        minor: '3D',
      }
    case 'GetMapping3D':
      return {
        full: 'GetMapping 3D',
        major: 'GM3D',
        minor: '3D',
      }
    case 'GetMappingPremium3D':
      return {
        full: 'GetMapping Premium 3D',
        major: 'GMP3D',
        minor: '3D',
      }
    case 'Vexcel3D':
      return {
        full: 'Vexcel 3D',
        major: 'VEX3D',
        minor: '3D',
      }
    case 'None':
      return {
        full: 'No Imagery',
        major: 'None',
        minor: 'None',
      }
    case 'Image':
      return {
        full: 'Uploaded Image',
        major: 'Image',
        minor: '',
      }
    case 'GoogleTop':
      return {
        full: 'Google Vertical',
        major: 'G',
        minor: 'Top',
      }
    case 'GoogleRoadMap':
      return {
        full: 'Google RoadMap',
        major: 'G',
        minor: 'Road',
      }
    case 'Bing':
      return {
        full: 'Bing Vertical',
        major: 'B',
        minor: 'Top',
      }
    case 'Google': {
      let directionLetter = Utils.headingToDirection(mapData.heading)
      let directionFull =
        mapData.heading || mapData.heading === 0 ? Utils.directionLetterToFull[directionLetter] : 'Vertical'
      return {
        full: 'Google ' + directionFull,
        major: 'G ' + directionLetter,
        minor: directionFull,
      }
    }
    case 'Cyclomedia':
      return {
        full: 'Cyclomedia Vertical',
        major: 'Cycl',
        minor: 'Top',
      }
    case 'CyclomediaOblique':
      return {
        full: 'Cyclomedia Oblique',
        major: 'Cycl',
        minor: 'Oblique',
      }
    case 'Nearmap':
      return {
        full: 'Nearmap Vertical',
        major: 'NM',
        minor: 'Vert',
      }

    case 'GetMapping':
      return {
        full: 'GetMapping Vertical',
        major: 'GM',
        minor: 'Top',
      }

    case 'GetMappingPremium':
      return {
        full: 'GetMapping Premium Vertical',
        major: 'GMP',
        minor: 'Top',
      }
    case 'Vexcel':
      return {
        full: 'Vexcel Vertical',
        major: 'VEX',
        minor: 'Top',
      }
    case 'VexcelOblique':
      let directionLetter = mapData.oblique.heading
      return {
        full: 'Vexcel Oblique',
        major: 'VEX',
        minor: directionLetter,
      }
    case 'NearmapSource': {
      let directionShort =
        mapData.oblique && mapData.oblique.inclinationDeg && mapData.oblique.bearingDeg
          ? Designer.classifyDirection(mapData.oblique.inclinationDeg, mapData.oblique.bearingDeg)
          : ''
      let directionFull = window.Utils.directionLetterToFull[directionShort]
      return {
        full: 'Nearmap Oblique ' + directionFull,
        major: 'NMS',
        minor: directionShort,
      }
    }
    case 'ArcGisStreetMap': {
      return {
        full: 'ArcGisStreetMap',
        major: 'AGOSM',
        minor: 'Top',
      }
    }
    case 'MetroMap': {
      return {
        full: 'MetroMap',
        major: 'MM',
        minor: 'Top',
      }
    }
    case 'SixMaps': {
      return {
        full: 'SixMaps',
        major: 'SM',
        minor: 'Top',
      }
    }
    case 'Kadastraal': {
      return {
        full: 'Kadastraal',
        major: 'PDOK',
        minor: 'Top',
      }
    }
    case 'IgnPotential': {
      return {
        full: 'Potential',
        major: 'Potential',
        minor: 'Top',
      }
    }
    case 'IgnMedian': {
      return {
        full: 'Median',
        major: 'Median',
        minor: 'Top',
      }
    }
    case 'IgnCatastral': {
      return {
        full: 'Catastral',
        major: 'Catastral',
        minor: 'Top',
      }
    }
    case 'IgnOrtofotos': {
      return {
        full: 'Ortofotos',
        major: 'Ortofotos',
        minor: 'Top',
      }
    }
    default:
      return {
        major: '---',
        minor: '---',
      }
  }
}

MapData.prototype.getLabel = function () {
  return MapData.getLabel(this)
}

MapData.getVariationName = function (mapData) {
  switch (mapData.mapType) {
    case 'Google': {
      let directionLetter = Utils.headingToDirection(mapData.heading)
      let directionFull =
        mapData.heading || mapData.heading === 0 ? Utils.directionLetterToFull[directionLetter] : 'Vertical'
      return directionFull
    }
    case 'Nearmap':
      // Must match back-end exactly
      return 'Vertical ' + (mapData.oblique?.until || 'Latest')
    case 'NearmapSource':
      // Must match back-end exactly
      return (
        Designer.classifyDirection(mapData.oblique?.inclinationDeg, mapData.oblique?.bearingDeg) +
        ' ' +
        mapData.oblique?.createdTime
      )
    case 'Vexcel':
      return mapData.oblique?.until
    case 'VexcelOblique':
      return mapData.oblique?.until
    case 'MetroMap':
      return mapData.oblique?.until
    case 'ArcGisStreetMap':
      return mapData.oblique?.until
    default:
      return null
  }
}

MapData.prototype.properties = [
  'mapType',
  'center',
  'zoomTarget',
  'zoomDelta',
  'maxZoom',
  'sceneOrigin',
  'scale',
  'heading',
  'oblique',
  'pitch',
  'pano',
]

//Add getters and setters with underscore prefix. e.g. mapData._sceneOrigin(valueToSet)
MapData.prototype.properties.forEach(function (property) {
  var propertiesUsingArrayDataType = ['center', 'sceneOrigin']

  MapData.prototype['_' + property] = function (value) {
    if (typeof value === 'undefined') {
      return this[property]
    } else {
      if (
        propertiesUsingArrayDataType.indexOf(property) !== -1
          ? this[property] && this[property][0] === value[0] && this[property][1] === value[1]
          : this[property] === value
      ) {
        // if value has not changed then there is no need to check whether updates are permitted
      } else if (window.editor && !window.editor.interactive()) {
        console.warn('Updating MapData.' + property + ' but !editor.interactive()')
      } else if (!window.MapHelper.interactive()) {
        console.warn('Updating MapData.' + property + ' but !MapHelper.interactive()')
      } else if (window.editor && window.editor.sceneIsLoading === true) {
        console.warn('Updating MapData.' + property + ' but editor.sceneIsLoading===true')
      } else if (window.WorkspaceHelper.designIsLoading === true) {
        console.warn('Updating MapData.' + property + ' but WorkspaceHelper.designIsLoading===true')
      }

      //Allow watching specific parameters
      // if (['center', 'sceneOrigin'].indexOf(property) != -1) {
      //   console.log('update ' + property + ' for ' + this.mapType, value)
      // }

      this[property] = value
    }
  }
})

//@todo: heading parameter is probably wrong, not used anymore
MapData.mapDataForGoogleOblique = function (heading, sceneOrigin4326) {
  return new MapData({
    mapType: 'Google',
    center: sceneOrigin4326,
    zoomTarget: 21,
    sceneOrigin: sceneOrigin4326,
    scale: 1,
    heading: heading,
    oblique: null,
    pano: null,
  })
}

MapData.mapDataForTopDown = function (mapType, options) {
  if (!options.zoomTarget) {
    options.zoomTarget = 21
  }

  // Unfortunately we can only guarantee to get accurate scale when digital zoom is required if we know the
  // maxZoom in advance. Incorrect guess of scale should not be too bad, it will resolve itself once maxZoom is detected
  // but it is not ideal.
  // If we already have maxZoom we can improve this by detecting scale upfront.
  var maxZoom =
    MapHelper.mapInstances[mapType]?.mapData?.maxZoom || MapHelper.mapInstances[mapType]?.dom?.maxZoom || null

  var scale = maxZoom ? Math.pow(2, options.zoomTarget - maxZoom) : 1

  return new MapData({
    mapType: mapType,
    center: options.center || options.sceneOrigin.slice(),
    zoomTarget: options.zoomTarget || undefined,
    sceneOrigin: options.sceneOrigin || options.center,
    scale: scale,
    heading: null,
    oblique: options.variation_data || null,
    pano: null,
  })
}

MapData.is3D = function (mapData) {
  if (!mapData) {
    return false
  }
  return MapData.mapTypeIs3D(mapData.mapType)
}

MapData.isManual = function (mapData) {
  if (!mapData) {
    return false
  }
  return mapData.mapType === 'None'
}

MapData.is2D = function (mapData) {
  if (!mapData) {
    return false
  }
  return !MapData.is3D(mapData) && !MapData.isManual(mapData)
}

MapData.isOblique = function (mapData) {
  if (!mapData) return false
  // heading can be a number (angle in deg) or a string (N,S,E,W)
  return MapData.is2D(mapData) && (typeof mapData.heading === 'string' || typeof mapData.heading === 'number')
}

MapData.isTopDown = function (mapData) {
  return !(MapData.is3D(mapData) || MapData.isManual(mapData) || MapData.isOblique(mapData))
}

MapData.mapTypeIs3D = function (mapType) {
  return (
    mapType === 'Nearmap3D' ||
    mapType === 'Google3D' ||
    mapType === 'GetMapping3D' ||
    mapType === 'GetMappingPremium3D' ||
    mapType === 'Vexcel3D'
  )
}

MapData.mapTypeIsManual = function (mapType) {
  return mapType === 'None'
}
