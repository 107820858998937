/**
 * @author adampryor
 */

var MODULE_GRID_OFFSET_FROM_TERRAIN = 0.2

var SUNPATH_PRECISION = 'medium'

var SUNPATH_PRECISION_SETTINGS = {
  low: { horizontal: 12, vertical: 11 },
  medium: { horizontal: 72, vertical: 180 },
  high: { horizontal: 360, vertical: 180 },
}

/* 
Stores vertices to sample for the current precision level to avoid rebuilding multiple times
on the first run which requires building SphereGeometry
*/
var SUNPATH_VERTICES_CACHE = {
  low: null,
  medium: null,
  high: null,
}

var gf = new jsts.geom.GeometryFactory()

var OsModuleGridCache = {}

let trackingModes = [
  { id: 0, name: 'None (Fixed)' },
  { id: 1, name: '1 Axis Horizontal (No Backtracking)' },
  { id: 2, name: '1 Axis Horizontal (With Backtracking)' },
  { id: 4, name: '1 Axis Tilted (No Backtracking)' },
  { id: 5, name: '1 Axis Tilted (With Backtracking)' },
  { id: 3, name: '2 Axis' },
]

window.trackingModes = trackingModes

OsModuleGrid.PanelConfigTypes = {
  Flush: 'STANDARD',
  SingleTilt: 'TILT_RACK',
  DualTilt: 'DUAL_TILT_RACK',
}

OsModuleGrid.Layouts = {
  [OsModuleGrid.PanelConfigTypes.Flush]: window.ModuleGridLayoutFlush,
  [OsModuleGrid.PanelConfigTypes.SingleTilt]: window.ModuleGridLayoutSingleTilt,
  [OsModuleGrid.PanelConfigTypes.DualTilt]: window.ModuleGridLayoutDualTilt,

  getInitialVersion: function (panelConfigType) {
    return this[panelConfigType][0]
  },

  getVersion: function (panelConfigType, version) {
    return this[panelConfigType].find((v) => v.version === version)
  },

  getLatestVersion: function (panelConfigType) {
    const versionList = this[panelConfigType]
    return versionList[versionList.length - 1]
  },
}

function OsModuleGrid(options) {
  THREE.Object3D.call(this)

  if (!options) {
    options = {}
  }

  if (options.uuid) {
    this.uuid = options.uuid
  }

  const fromJSON = options.fromJSON

  if (fromJSON && options.userData) {
    var userData = options.userData
    // Construct options from options.userData
    options = {
      facet: editor.objectByUuid(userData.facetUuid),
      // Unfortunate legacy bug: ideally we would avoid using "orientation" going forward and instead use moduleLayout
      // but there are still some references that use options.orientation, so beware.
      orientation: userData.moduleLayout,
      // Beware inconsistency between offsetRows and moduleLayoutOffset, support both here
      offsetRows: userData.offsetRows ? userData.offsetRows : userData.moduleLayoutOffset,
      gridOriginOffset: userData.gridOriginOffset,
      useNewSpacingFormula: userData.useNewSpacingFormula,
      colSpacingOffset: userData.colSpacingOffset,
      rowSpacingOffset: userData.rowSpacingOffset,

      // Ensure we start with a value applied so it does not get applied again
      gridOriginOffsetApplied: userData.gridOriginOffset,
      panelConfiguration: userData.panelConfiguration,
      panelTiltOverride: userData.panelTiltOverride,
      panelPlacement: userData.panelPlacement,
      moduleSpacing: userData.moduleSpacing,
      groupSpacing: userData.groupSpacing,
      azimuthAuto: userData.azimuthAuto,
      slopeAuto: userData.slopeAuto,
      elevationAuto: userData.elevationAuto,
      trackingMode: userData.trackingMode,
      rotationLimit: userData.rotationLimit,
      beamAccess: userData.beamAccess,
      beamAccessBack: userData.beamAccessBack,
      diffuseShading: userData.diffuseShading,
      diffuseShadingBack: userData.diffuseShadingBack,
      horizonElevations: userData.horizonElevations,

      // position/rotation applied automatically by THREE Loader parseObject()
      size: userData.size,
      moduleTexture: userData.moduleTexture,
      modulesPerCol: userData.modulesPerCol ? userData.modulesPerCol : 1,
      modulesPerRow: userData.modulesPerRow,
      groundClearance: userData.groundClearance,
      cellsActive: userData.cellsActive,
      buildableCells: userData.buildableCells,
      layoutCalcsVersion: userData.layoutCalcsVersion,

      // Do not set slope/azimuth because this is set automatically by parseObject() using object.matrix
      // Otherwise it will trigger auto-orientation and will mess up rotation.
      // slope: userData.slope,
      // azimuth: userData.azimuth,
    }

    if (Array.isArray(this.userData.buildableCells)) {
      if (userData.buildableCells.length === 0) {
        // the buildable cells data is in compressed form
        // this means the buildable cells are the active cells
        // technical: symmetric diff of this.buildableCells and this.cellsActive is empty
        options.buildableCells = userData.cellsActive.slice()
      } else {
        options.buildableCells = userData.buildableCells
      }
    }
  }

  this.panelConfiguration = options.panelConfiguration
    ? options.panelConfiguration
    : OsModuleGrid.PanelConfigTypes.Flush

  if (fromJSON && options.layoutCalcsVersion) {
    // serialized instance with a layout calcs version stored
    // use the exact layout calc version as specified
    this.layoutCalcs = OsModuleGrid.Layouts.getVersion(this.panelConfiguration, options.layoutCalcsVersion)
  } else if (fromJSON) {
    // serialized instance without a layout calcs version stored
    // we assume this was created before the versioning system was introduced
    // use the initial layout calcs version
    this.layoutCalcs = OsModuleGrid.Layouts.getInitialVersion(this.panelConfiguration)
  } else {
    // created at this very instant
    // use the latest layout calcs version
    this.layoutCalcs = OsModuleGrid.Layouts.getLatestVersion(this.panelConfiguration)
  }

  this.cellToUuid = options && options.cellToUuid ? options.cellToUuid : {}

  if (!OsModuleGridCache.geometry) {
    OsModuleGridCache.geometry = new THREE.SphereBufferGeometry(0.5)
  }
  this.geometry = OsModuleGridCache.geometry

  if (!OsModuleGridCache.material) {
    OsModuleGridCache.material = new THREE.MeshStandardMaterial({
      color: 0xf7ca18,
    })
  }
  this.material = OsModuleGridCache.material

  this.helpers = []

  this.type = 'OsModuleGrid'
  this.name = 'OsModuleGrid'
  this.facet = options.facet ? options.facet : null

  this._moduleLayout = options && options.orientation ? options.orientation : 'portrait'
  this._moduleLayoutOffset = options && options.offsetRows ? Boolean(options.offsetRows) : false

  this.moduleLayoutOffsetApplied = null //stores the value from moduleLayoutOffset() only when drawn
  this.gridOriginOffset =
    options && options.hasOwnProperty('gridOriginOffset') && options.gridOriginOffset
      ? new THREE.Vector3().fromArray(options.gridOriginOffset)
      : new THREE.Vector3()
  this.gridOriginOffsetApplied =
    options && options.hasOwnProperty('gridOriginOffsetApplied') && options.gridOriginOffsetApplied
      ? new THREE.Vector3().fromArray(options.gridOriginOffsetApplied)
      : new THREE.Vector3()
  this.panelTiltOverride = _.isNumber(options.panelTiltOverride) ? options.panelTiltOverride : null
  this.panelPlacement = options.panelPlacement ? options.panelPlacement : 'roof'

  this.azimuthAuto = options && options.hasOwnProperty('azimuthAuto') ? Boolean(options.azimuthAuto) : true
  this.slopeAuto = options && options.hasOwnProperty('slopeAuto') ? Boolean(options.slopeAuto) : true
  this.elevationAuto = options && options.hasOwnProperty('elevationAuto') ? Boolean(options.elevationAuto) : true

  //includes backtracking option
  this.trackingMode(options && options.hasOwnProperty('trackingMode') ? options.trackingMode : 0)
  this.rotationLimit = options && options.hasOwnProperty('rotationLimit') ? options.rotationLimit : 0

  this.diffuseShading = options && options.hasOwnProperty('diffuseShading') ? options.diffuseShading : null
  this.diffuseShadingBack = options && options.hasOwnProperty('diffuseShadingBack') ? options.diffuseShadingBack : null
  this.beamAccess = options && options.hasOwnProperty('beamAccess') ? options.beamAccess : null
  this.beamAccessBack = options && options.hasOwnProperty('beamAccessBack') ? options.beamAccessBack : null
  this.horizonElevations = options && options.hasOwnProperty('horizonElevations') ? options.horizonElevations : null

  if (options.position) {
    this.position.copy(options.position)
  }

  if (options.rotation) {
    this.rotation.copy(options.rotation)
  }

  if (options.position || options.rotation) {
    // manual call required to updateMatrixWorld in order to update based on supplied position and/or rotation
    // otherwise other methods which rely on the matrix will fail if they are called before the object is updated.
    // e.g. Specifically this affects later call to populate() in the constructor, which calls localToWorld
    this.updateMatrixWorld()
  }

  // if this is a newly-created module grid
  // OR if this has been created before and used the new spacing formula
  // use the new spacing formula for this
  if (!fromJSON || (fromJSON && options.useNewSpacingFormula)) {
    this.useNewSpacingFormula = true
    if (options.colSpacingOffset !== undefined) {
      this.colSpacingOffset = options.colSpacingOffset
    }
    if (options.rowSpacingOffset !== undefined) {
      this.rowSpacingOffset = options.rowSpacingOffset
    }
  }

  //Only apply extra slope if different to value already applied
  this.extraSlopeApplied = 0

  this.size = options.size ? options.size : [0.6, 1.2]

  this.cellsActive = options.cellsActive || []
  this.oldModuleObjects = {}
  this.moduleObjects = {} //reference to the SystemModule at each position

  this._modulesPerCol = options && options.hasOwnProperty('modulesPerCol') ? options.modulesPerCol : 1
  this._modulesPerRow = options && options.hasOwnProperty('modulesPerRow') ? options.modulesPerRow : 1
  this._groundClearance = options && options.hasOwnProperty('groundClearance') ? options.groundClearance : 0

  this.moduleSpacing = options?.moduleSpacing || [0, 0]
  this.moduleSpacingApplied = [null, null]
  this.groupSpacing = options?.groupSpacing || [0, 0]
  this.groupSpacingApplied = [null, null]

  //Apply the settigns which determine final module size
  this.setSize()
  this.moduleTexture(options && options.moduleTexture ? options.moduleTexture : 'default')

  // Only call populate() if not loading fromJSON or scene is not loading.
  // Note that we must call populate() if we are loading a ModuleGrid that does not contain
  // OsModules as children, because populate is responsible for creating the OsModules.
  // But this should not be called if OsModules ARE being loaded as children of the OsModuleGrid.
  // There is no easy way to know this at the time the object is parsed from JSON but this method
  // will suffice for now.
  if (!fromJSON || !editor.sceneIsLoading) {
    if (options && options.cellsActive) {
      this.populate(options.cellsActive)
    } else {
      this.populate([])
    }
  }

  if (options?.buildableCells) {
    this.buildableCells = options.buildableCells
    this.clearInactiveBuildableCellsCache()
  }

  this._mouseOver = options._mouseOver ? true : false
  this._selected = options._selected ? true : false

  //No rollovers on iOS!!! Always use rolled-over state
  if (Utils.iOS()) {
    this._mouseOver = true
  }

  this.refreshModules()

  this.shadingOverride = []

  if (options && options.hasOwnProperty('slope')) {
    this.setSlope(options.slope)
  }

  if (options && options.hasOwnProperty('azimuth')) {
    this.setAzimuth(options.azimuth)
  }

  this.setbacksMarkers = new THREE.Group()
  this.setbacksMarkers.visible = false
  this.setbacksMarkers.name = 'Setbacks'
  this.setbacksMarkers.userData.excludeFromExport = true
  this.add(this.setbacksMarkers)
  this.setbackMeshTemplate = this.createSetbackMeshTemplate()

  this.saveHash()

  this.signals = {
    snappedToFacet: new window.Signal(),
    unsnappedFromFacet: new window.Signal(),
  }

  this.azimuthIndicators = new window.ModuleGridAzimuthIndicators(this)
}

OsModuleGrid.displayMode = null

OsModuleGrid.setbacksBorderFlagsUniforms = {
  // Top, Right, Bottom, Left  - in that order
  T: new THREE.Uniform(new THREE.Vector4(1, 0, 0, 0)),
  R: new THREE.Uniform(new THREE.Vector4(0, 1, 0, 0)),
  B: new THREE.Uniform(new THREE.Vector4(0, 0, 1, 0)),
  L: new THREE.Uniform(new THREE.Vector4(0, 0, 0, 1)),
  TR: new THREE.Uniform(new THREE.Vector4(1, 1, 0, 0)),
  TL: new THREE.Uniform(new THREE.Vector4(1, 0, 0, 1)),
  BR: new THREE.Uniform(new THREE.Vector4(0, 1, 1, 0)),
  BL: new THREE.Uniform(new THREE.Vector4(0, 0, 1, 1)),
}

OsModuleGrid.AzimuthalSubsets = {
  Front: 'FRONT',
  Back: 'BACK',
  FrontAndBack: 'FRONT_AND_BACK',
}

OsModuleGrid.userDataValidators = {
  facetUuid: (value) => value === null || typeof value === 'string',
  size: (value) => Array.isArray(value) && value.length === 2 && value.every((el) => typeof el === 'number'),
  gridOriginOffset: (value) =>
    Array.isArray(value) && value.length === 3 && value.every((el) => typeof el === 'number'),
  azimuthAuto: (value) => typeof value === 'boolean',
  slopeAuto: (value) => typeof value === 'boolean',
  azimuth: (value) => typeof value === 'number',
  slope: (value) => typeof value === 'number',
  panelTiltOverride: (value) => typeof value === 'number' || value === null,
  cellsActive: (value) => Array.isArray(value) && value.every((el) => typeof el === 'string'),
  modulesPerCol: (value) => typeof value === 'number',
  modulesPerRow: (value) => typeof value === 'number',
  moduleSpacing: (value) => Array.isArray(value) && value.length === 2 && value.every((el) => typeof el === 'number'),
  groupSpacing: (value) => Array.isArray(value) && value.length === 2 && value.every((el) => typeof el === 'number'),
  panelConfiguration: (value) => Object.values(OsModuleGrid.PanelConfigTypes).includes(value),
  panelPlacement: (value) => typeof value === 'string',
  elevationAuto: (value) => typeof value === 'boolean',
  groundClearance: (value) => typeof value === 'number',
  moduleLayout: (value) => typeof value === 'string',
  trackingMode: (value) => typeof value === 'number',
  gcr: (value) => typeof value === 'number',
}

OsModuleGrid.prototype = Object.assign(Object.create(THREE.Object3D.prototype), {
  getName: function (translate) {
    if (translate) {
      const panels = this.moduleQuantity()
      return panels === 1 ? translate('1 Panel') : translate('%{panels} Panels', { panels })
    } else {
      return 'Panel Group'
    }
  },
  constructor: OsModuleGrid,
  _hash: null,
  getHash: ObjectBehaviors.changeDetection.getHash,
  saveHash: ObjectBehaviors.changeDetection.saveHash,
  clearHash: ObjectBehaviors.changeDetection.clearHash,
  hasChanged: ObjectBehaviors.changeDetection.hasChanged,

  createSetbackMeshTemplate: function () {
    const setbackGeometry = new THREE.PlaneGeometry(1, 1)
    const setbackMaterial = new THREE.ShaderMaterial({
      transparent: true,
      renderOrder: window.RENDER_ORDER.ArraySetbacks,
      uniforms: {
        color: new THREE.Uniform(new THREE.Color(0xedc322)),
        gap: new THREE.Uniform(4),
        hatchOpacity: new THREE.Uniform(0.7),
        outlineOpacity: new THREE.Uniform(0.85),
        borderFlags: new THREE.Uniform(new THREE.Vector4(1, 1, 1, 1)), // <- Top, Right, Bottom, Left  - in that order
      },
      vertexShader: `
          out vec3 vPos;

          void main() {
            vPos = position;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
      fragmentShader: `
          uniform vec3 color;
          uniform int gap;
          uniform float hatchOpacity;
          uniform float outlineOpacity;
          uniform vec4 borderFlags;

          in vec3 vPos;
          
          // a setback geometry is always a 1 x 1 square, with (0, 0, 0) at the center
          // we detect if this fragment is a border by computing it's local distance from (0, 0, 0)
          // if the distance is ALMOST OR EXACTLY 0.5, then it's near or at the border of the geometry
          // the threshold is arbitrary

          float BORDER_THRESHOLD = 0.5 - 0.04;

          precision highp float;

          void main() {
            vec3 origin = vec3(0, 0, 0);

            // 0 - when the fragment is neither part of the hatching pattern or classified as a border
            // 1 - when the fragment is part of the hatching pattern but not a border
            // 2 - when the fragment is classified as a border, but not part of the hatching pattern
            // 3 - when the fragment is both part of the hatching pattern and a border
            float opacityOptions[4] = float[4] (0.0f, hatchOpacity, outlineOpacity, outlineOpacity);

            // detect whether this fragment should be colored as a border
            bool colorAsBorderTop = borderFlags[0] == 1.0 && vPos.y - origin.y >= BORDER_THRESHOLD;
            bool colorAsBorderRight = borderFlags[1] == 1.0 && vPos.x - origin.x >= BORDER_THRESHOLD;
            bool colorAsBorderBottom = borderFlags[2] == 1.0 && origin.y - vPos.y >= BORDER_THRESHOLD;
            bool colorAsBorderLeft = borderFlags[3] == 1.0 && origin.x - vPos.x >= BORDER_THRESHOLD;

            // detect whether this fragment should be colored for the hatching pattern
            int mod = int(floor(gl_FragCoord.x)) % gap;

            bool colorAsHatch = mod == 0;
            bool colorAsBorder = colorAsBorderTop || colorAsBorderRight || colorAsBorderBottom || colorAsBorderLeft;
            
            // we're intentionally avoiding conditional statements here
            // because we're not using a uniform as basis for the condition
            // so the compiler will not be able to optimize it
            int opacityIndex = int(colorAsHatch) + (int(colorAsBorder) * 2); // either 0, 1, 2, 3

            float alpha = float(int(colorAsHatch || colorAsBorder)) * opacityOptions[opacityIndex];
            
            gl_FragColor = vec4(color.rgb, alpha);
          }
        `,
    })
    return new THREE.Mesh(setbackGeometry, setbackMaterial)
  },

  refreshFromChildren: function (editor, forceClear) {
    this.children
      .filter(function (o) {
        return o.type === 'OsModule'
      })
      .forEach(function (m) {
        //for some reason this can already contain inactive modules
        //rerunning on an existing active modules would be idempotent

        //automatically populates from userData in constructor??
        if (m.userData.cell) {
          m.cell = m.userData.cell
        } else if (m.cell) {
          console.warn(
            'refreshFromChildren could not find m.userData.cell, populate m.userData.cell from m.cell instead'
          )
          m.userData.cell = m.cell
        }

        // Unsure if this is necessary, but this would required in the past
        if (m.userData.active !== m.active) {
          m.active = m.userData.active
        }

        if (this.moduleObjects[m.userData.cell] && this.moduleObjects[m.userData.cell] !== m) {
          // @TODO: We should also explore if we can actually retain the OsModule objects that
          // were already loaded when parsing the scene because they may require less processing,
          // avoid needing to copy properties over, and we may be able to avoid re-creating them
          // in the first place.

          // If we are clearing a module with a shadingOverride and/or shadingOverrideRaw transfer
          // it into the new module
          var shadingOverride = this.moduleObjects[m.userData.cell].userData.shadingOverride
          if (shadingOverride?.length) {
            m.shadingOverride = shadingOverride
            m.userData.shadingOverride = shadingOverride
          }

          var shadingOverrideRaw = this.moduleObjects[m.userData.cell].userData.shadingOverrideRaw
          if (shadingOverrideRaw?.length) {
            m.shadingOverrideRaw = shadingOverrideRaw
            m.userData.shadingOverrideRaw = shadingOverrideRaw
          }

          editor.removeObject(this.moduleObjects[m.userData.cell])
        }
        this.moduleObjects[m.userData.cell] = m

        if (m.active) {
          this.activateCell(m.userData.cell, true, false)
        }
      }, this)

    this.draw(forceClear)
    // Do we really need to call onChange while loading the scene??? Surely not
    // If absolutely necessary, be sure to saveHash before so we do not clear shading
    this.saveHash()
    this.onChange(editor)
  },

  setVisibility: function (helperVisibility) {
    this.helpers.forEach(function (o) {
      o.visible = helperVisibility
    })
  },

  clearAnnotationCache: function () {
    this.getModules().forEach((m) => {
      m.clearAnnotationCache()
    })
  },

  onSelect: function () {
    this.selected(true)
    if (!this.ghostMode()) {
      this.showSetbacks()
      this.azimuthIndicators.show()
    }
  },

  onDeselect: function () {
    this.selected(false)
    this.hideSetbacks()
    this.azimuthIndicators.hide()
    if (this.getModules().length === 0 && !this.hasBuildablePanels()) {
      // no active modules or inactive buildable modules in this module grid
      // flag it for removal
      let system = this.getSystem()
      system && system.removeModuleGrid(this)
    }
    //SceneHelper.refreshRayToPanel()
  },

  selected: function (value) {
    if (typeof value === 'undefined') {
      return this._selected
    }

    if (this._selected !== value) {
      this._selected = value
      this.refreshModules()
    }
  },

  toolsActive: function () {
    return {
      translateXY: true,
      // translateZ: this.facet ? false : true,
      translateZ: this.elevationAuto ? false : true,
      translateX: false,
      rotate: !this.azimuthAuto ? true : false,
      scaleXY: false,
      scaleZ: false,
      scale: false, //legacy
    }
  },

  transformWithLocalCoordinates: function () {
    // When facet is assigned, we translateXY along the facet and we don't elevate at all
    // When no facet is assigned, translate all directions using global axes
    return Boolean(this.facet)
  },

  calculateGroundCoverageRatio: function () {
    // Use tilt=0 for horizontal 1-axis tracking and 2-axis tracking because we ignore the tilt
    var panelTilt = this.trackingMode() > 0 ? 0 : this.getPanelTilt()
    var moduleHeight = this.sizeForLayout(this.size, this.moduleLayout())[1]
    var moduleHeightForeshortened = Math.cos(panelTilt * THREE.Math.DEG2RAD) * moduleHeight
    var rowGroundHeight = moduleHeight + this.moduleSpacing[1] + this.groupSpacing[1]
    return moduleHeightForeshortened / rowGroundHeight
  },

  calculateBifacialTransmissionFactor: function () {
    // Assumes shape is rectangular and that all panels are affected equally (i.e. no special treatment for first row)
    // which will not have any self-shading. We assume that SAM accounts for this sensibly.
    var bounds = this.getBounds()
    // var rows = bounds[3] - bounds[1] + 1
    var cols = bounds[2] - bounds[0] + 1
    // Just calculate the first row which is assumed to be the same as all other rows.
    var moduleArea = this.size[0] * this.size[1] * cols
    var gapArea = this.size[1] * (this.moduleSpacing[0] + this.groupSpacing[0]) * (cols - 1)
    var transmissionFactor = this.getSystem().moduleType().transmission || 0
    return (moduleArea * transmissionFactor + gapArea) / (moduleArea + gapArea)
  },

  getRows: function () {
    var bounds = this.getBounds()
    return bounds[3] - bounds[1] + 1
  },

  getCols: function () {
    var bounds = this.getBounds()
    return bounds[2] - bounds[0] + 1
  },

  calculateNormal: function (back = false) {
    let azimuth = back ? this.getAzimuth() + 180 : this.getAzimuth()
    return Utils.normalFromSlopeAzimuth(this.getPanelTilt(), azimuth)
  },

  calculateDiffuseShading: function (options) {
    // If shadingOverride is used, then use it to calculate diffuse shading
    var system = this.getSystem()

    var shadingOverrideToApply = null
    if (system.hasShadingOverride()) {
      shadingOverrideToApply = system.shadingOverride
    } else if (this.hasShadingOverride()) {
      shadingOverrideToApply = this.shadingOverride
    }

    if (shadingOverrideToApply) {
      return [
        (100 - window.ShadeHelper.percentageSun(OsSystem.shadingOverrideTo288(shadingOverrideToApply))) / 100,
        null,
      ]
    }

    // If pregenerated points are available, fetch diffise shading from the closest point to the modulegrid centroid
    if (editor.scene.preGeneratedRaytraceResults) {
      var pointsWithDiffuseShading = editor.scene.preGeneratedRaytraceResults.filter((p) => p.diffuse)
      if (pointsWithDiffuseShading.length > 0) {
        var centroidPositionWorld = this.localToWorld(this.getCentroid())
        // Convert "diffuse" irradiance fraction to "diffuse shading"
        var diffuseShading =
          1.0 -
          OsModule.getClosestShadeSample(pointsWithDiffuseShading, centroidPositionWorld.x, centroidPositionWorld.y)
            .diffuse
        return [diffuseShading, null]
      }
    }

    // If raytraced shading not available for some reason, set to average of all panels
    // @TODO: Does this overlap with the method above? Can we unify this to avoid redundancy?
    if (!editor.scene.raytracedShadingAvailable()) {
      var sum = 0
      var count = 0
      this.getModules(options).forEach((m) => {
        m.shadingOverride.forEach((s) => {
          sum += s
          count++
        })
      })

      return [sum / count, null]
    }

    // See SAM 3D calculator: 3dtool.cpp:
    // bool ShadeAnalysis::SimulateDiffuse(std::vector<surfshade> &shade, bool save)
    // We simplify the analysis, assume Isotropic diffuse irradiance
    // Based on % of sky dome which is shaded and we assume proportional to the dot product of the sun direction
    // and module direction.
    //
    // Sky dome is estimated by creating spherical geometry (see THREE.SphereGeometry)
    // with X width segments and Y height segments but only using vertices above the equator.
    // Old settings: width:8, height:6 => 8 * floor(6/2 - 1) + 1 = 8 * 2 + 1 = 17 samples for sky dome
    // Later settings: width:12, height:11 => 8 * floor(11/2 - 1) + 1 = 8 * 4 + 1 = 33 samples for sky dome
    // The previous settings can be retained by setting SUNPATH_PRECISION = 'low'

    // Sunpath
    // To visualize the sunpath in studio you can enter this in the JS console after running shade calcs:
    // SceneHelper.setupHorizon('null', editor.filter('type','OsModuleGrid')[0].horizonElevations, 50)

    var samples = SUNPATH_PRECISION_SETTINGS[SUNPATH_PRECISION]

    if (!SUNPATH_VERTICES_CACHE[SUNPATH_PRECISION]) {
      // @TODO: Optimization could determine sample points using a different method than THREE.SphereGeometry
      // which is inefficient because the points converge near the zenith, which results in lots of unnecessary
      // precision at high elevations. Different methods could be more uniform and avoid unnecessary raytraces.
      SUNPATH_VERTICES_CACHE[SUNPATH_PRECISION] = new THREE.SphereGeometry(
        1,
        samples.horizontal,
        samples.vertical
      ).vertices
    }

    var pointsToSample = SUNPATH_VERTICES_CACHE[SUNPATH_PRECISION]

    var sunRayDirections = pointsToSample
      // rays pointing down to ground (z negative)
      .filter((v) => v.y < -0.01)
      // switch y for z
      .map((v) => new THREE.Vector3(v.x, v.z, v.y))

    var relativeIrradianceTotal = 0
    var relativeIrradianceShaded = 0

    var raytracePosition = this.localToWorld(this.getCentroid())
    var elevationGrid = viewport.render(false, false, true, 0, 0, null, this.getSystem().uuid) // @TODO: Optimize this or force rebuild?
    var elevationGridMax = Math.max(...elevationGrid.map((row) => Math.max(...row)))
    // var ignoreBackfaceShading = false
    var gridParams = viewport.buildGridParams()
    var gridBounds = gf.createLineString(
      [
        [0, 0],
        [0, gridParams.resolution - 1],
        [gridParams.resolution - 1, gridParams.resolution - 1],
        [gridParams.resolution - 1, 0],
        [0, 0],
      ].map(function (c) {
        return new jsts.geom.Coordinate(c[0], c[1])
      })
    )
    var gridCellPosition = OsModule.worldPositionToElevationGridCell(raytracePosition, gridParams)
    var horizon = SceneHelper.getHorizon()
    var skipFirstRaypoints = 2
    var ignoreBackfaceShading = true

    // If Back of DualTilt, use the opposite azimuth
    var panelPlaneNormal = this.calculateNormal(options?.subset === OsModuleGrid.AzimuthalSubsets.Back)
    var debugRayTrace = false

    var horizonBucketSizeDegrees = 360 / samples.horizontal
    var azimuthToHorizonElevationBucketIndex = (azimuth, horizonBucketSizeDegrees) => {
      return Math.floor(azimuth / horizonBucketSizeDegrees)
    }

    // start all horizon elevations at 0, then increase each time we find a higher sunny sample
    // values in radians, converted to degrees in final output
    var horizonElevationBuckets = new Array(samples.horizontal).fill(0)

    sunRayDirections.forEach((sunRayDirection) => {
      var directionToSun = sunRayDirection.clone().negate()
      var sunAzimuth = Utils.bearing(new THREE.Vector3(), directionToSun) * THREE.Math.DEG2RAD
      var sunAltitude = Utils.elevation(new THREE.Vector3(), directionToSun) * THREE.Math.DEG2RAD

      var isSunny = OsModule.isSunny(
        raytracePosition,
        gridParams,
        gridCellPosition,
        elevationGrid,
        elevationGridMax,
        gridBounds,
        horizon,
        ignoreBackfaceShading,
        skipFirstRaypoints,
        sunRayDirection,
        panelPlaneNormal,
        sunAzimuth,
        sunAltitude,
        debugRayTrace
      )
      if (isSunny === null) {
        //sun is behind the module, ignore it
      } else {
        var relativeIrradiance = Math.max(0, -1 * sunRayDirection.dot(panelPlaneNormal))
        relativeIrradianceTotal += relativeIrradiance

        if (isSunny === false) {
          relativeIrradianceShaded += relativeIrradiance

          // point is shaded, update the elevation for the highest shading obstruction for this azimuth bucket
          var elevationBucketIndex = azimuthToHorizonElevationBucketIndex(
            sunAzimuth * THREE.Math.RAD2DEG,
            horizonBucketSizeDegrees
          )
          if (sunAltitude > horizonElevationBuckets[elevationBucketIndex]) {
            horizonElevationBuckets[elevationBucketIndex] = sunAltitude
          }
        }
      }
    })

    return [
      relativeIrradianceShaded / relativeIrradianceTotal,
      // convert to degrees
      horizonElevationBuckets.map((v) => (v === 0 ? 0 : v * THREE.Math.RAD2DEG)),
    ]
  },

  calculateMeanBeamAccess: function (options = { subset: OsModuleGrid.AzimuthalSubsets.FrontAndBack }) {
    /*
    For 288 month-by-hour calculate the fraction of all panels which are shaded.
    Use a simple mean of all raytrace samples for all panels in the panel group.

    Since azimuth & slope are identical for all panels in the panel group, the sun will shine from the back
    at the same hour for all panels. Return a `null` value to indicate sun is behind the panel, so shading is
    not-applicable

    */

    // If shadingOverride is used, then use it to calculate beam access
    var system = this.getSystem()

    var shadingOverrideToApply = null
    if (system.hasShadingOverride()) {
      shadingOverrideToApply = system.shadingOverride
    } else if (this.hasShadingOverride()) {
      shadingOverrideToApply = this.shadingOverride
    }

    if (shadingOverrideToApply) {
      return OsSystem.shadingOverrideTo288(shadingOverrideToApply).map((v) => 1 - v)
    }

    // If raytraced shading not available for some reason, set to average of all panels
    // @TODO: Does this overlap with the method above? Can we unify this to avoid redundancy?
    if (!editor.scene.raytracedShadingAvailable()) {
      return Array(288).fill(1)
    }

    const calculateForModules = (modules) => {
      const summedValues = Array(288).fill(0)
      modules
        .map((m) => m.shadingOverrideRawMean())
        .forEach((sunProfileForModule) => {
          for (var h = 0; h < 288; h++) {
            if (summedValues[h] === null) {
              // already identified as sun-behind-module, leave unchanged
            } else if (sunProfileForModule[h] === null) {
              summedValues[h] = null
            } else {
              summedValues[h] += sunProfileForModule[h]
            }
          }
        })
      return summedValues.map((v) => (v !== null ? v / modules.length : null))
    }

    const modules = this.getModules()

    if (options.subset === OsModuleGrid.AzimuthalSubsets.FrontAndBack) {
      return calculateForModules(modules)
    }

    if (options.subset === OsModuleGrid.AzimuthalSubsets.Front) {
      return calculateForModules(this.getModules().filter((m) => m.getAzimuth() === this.getAzimuth()))
    }

    if (options.subset === OsModuleGrid.AzimuthalSubsets.Back) {
      return calculateForModules(this.getModules().filter((m) => m.getAzimuth() !== this.getAzimuth()))
    }
  },

  userDataValidators: OsModuleGrid.userDataValidators,

  refreshUserData: function (recursive) {
    this.userData.facetUuid = this.facet ? this.facet.uuid : null
    this.userData.size = this.size
    this.userData.gridOriginOffset = this.gridOriginOffset.toArray()

    this.userData.modulesPerCol = this.modulesPerCol()
    this.userData.modulesPerRow = this.modulesPerRow()
    this.userData.groundClearance = this.groundClearance()
    this.userData.moduleLayout = this.moduleLayout()
    this.userData.moduleLayoutOffset = this.moduleLayoutOffset()
    this.userData.moduleTexture = this.moduleTexture()

    this.userData.azimuth = this.getAzimuth()
    this.userData.slope = this.getSlope()
    this.userData.panelTiltOverride = this.panelTiltOverride

    this.userData.panelConfiguration = this.panelConfiguration
    this.userData.panelPlacement = this.panelPlacement
    this.userData.moduleSpacing = this.moduleSpacing
    this.userData.groupSpacing = this.groupSpacing
    this.userData.shadingOverride = this.shadingOverride
    this.userData.cellsActive = this.cellsActive.slice()

    if (this.hasBuildablePanels()) {
      if (_.xor(this.cellsActive, this.buildableCells).length === 0) {
        this.userData.buildableCells = []
      } else {
        this.userData.buildableCells = this.buildableCells.slice()
      }
    } else {
      delete this.userData.buildableCells
    }

    this.userData.azimuthAuto = this.azimuthAuto
    this.userData.slopeAuto = this.slopeAuto
    this.userData.elevationAuto = this.elevationAuto

    this.userData.trackingMode = this.trackingMode()
    this.userData.rotationLimit = this.rotationLimit
    this.userData.gcr = this.calculateGroundCoverageRatio()
    this.userData.diffuseShading = this.diffuseShading
    this.userData.diffuseShadingBack = this.diffuseShadingBack
    this.userData.beamAccess = this.beamAccess
    this.userData.beamAccessBack = this.beamAccessBack
    this.userData.horizonElevations = this.horizonElevations

    if (this.useNewSpacingFormula) {
      this.userData.useNewSpacingFormula = this.useNewSpacingFormula
      if (this.colSpacingOffset !== undefined) {
        this.userData.colSpacingOffset = this.colSpacingOffset
      }
      if (this.rowSpacingOffset !== undefined) {
        this.userData.rowSpacingOffset = this.rowSpacingOffset
      }
    }

    this.userData.layoutCalcsVersion = this.layoutCalcs.version

    if (recursive === true) {
      Object.values(this.moduleObjects).forEach((m) => m.refreshUserData())
    }

    return this.userData
  },

  applyUserData: function () {
    if (Object.keys(this.userData).length === 0) {
      console.log('Warning: skipping OsFacet.applyUserData() because this.userData === {}')
      return
    }

    // IMPORTANT: set the formula flag BEFORE any re-draw
    // the function calls below like this.setSize() will trigger a re-draw!
    if (this.userData.useNewSpacingFormula) {
      this.useNewSpacingFormula = this.userData.useNewSpacingFormula
    }
    if (this.userData.colSpacingOffset) {
      this.colSpacingOffset = this.userData.colSpacingOffset
    }
    if (this.userData.rowSpacingOffset) {
      this.rowSpacingOffset = this.userData.rowSpacingOffset
    }

    if (this.userData.modulesPerRow) this.modulesPerRow(this.userData.modulesPerRow)
    if (this.userData.groundClearance) this.groundClearance(this.userData.groundClearance)
    if (this.userData.moduleLayout) this.moduleLayout(this.userData.moduleLayout)
    if (this.userData.moduleLayoutOffset) this.moduleLayoutOffset(this.userData.moduleLayoutOffset)
    if (this.userData.moduleTexture) this.moduleTexture(this.userData.moduleTexture)
    if (this.userData.gridOriginOffset) this.gridOriginOffset.fromArray(this.userData.gridOriginOffset)
    if (this.userData.size) this.setSize(this.userData.size)

    // Assume this.userData.azimuth/slope does not need to be applied beacuse it is already baked into object rotations
    this.panelTiltOverride =
      typeof this.userData.panelTiltOverride !== 'undefined' ? this.userData.panelTiltOverride : null
    this.panelConfiguration =
      typeof this.userData.panelConfiguration !== 'undefined'
        ? this.userData.panelConfiguration
        : OsModuleGrid.PanelConfigTypes.Flush
    this.panelPlacement = typeof this.userData.panelPlacement !== 'undefined' ? this.userData.panelPlacement : 'roof'

    this.moduleSpacing = this.userData.moduleSpacing || [0, 0]
    this.groupSpacing = this.userData.groupSpacing || [0, 0]
    this.shadingOverride = this.userData.shadingOverride ? this.userData.shadingOverride : []

    this.azimuthAuto = Boolean(this.userData.azimuthAuto)
    this.slopeAuto = Boolean(this.userData.slopeAuto)
    this.elevationAuto = Boolean(this.userData.elevationAuto)

    this.trackingMode(this.userData.trackingMode)
    this.rotationLimit = this.userData.rotationLimit

    this.diffuseShading = this.userData.diffuseShading
    this.diffuseShadingBack = this.userData.diffuseShadingBack
    this.beamAccess = this.userData.beamAccess
    this.beamAccessBack = this.userData.beamAccessBack
    this.horizonElevations = this.userData.horizonElevations

    if (Array.isArray(this.userData.buildableCells)) {
      if (this.userData.buildableCells.length === 0) {
        // the buildable cells data is in compressed form
        // this means the buildable cells are the active cells
        // technical: symmetric diff of this.buildableCells and this.cellsActive is empty
        this.buildableCells = this.userData.cellsActive.slice()
      } else {
        this.buildableCells = this.userData.buildableCells
      }
    }

    this.layoutCalcs = this.userData.layoutCalcsVersion
      ? OsModuleGrid.Layouts.getVersion(this.panelConfiguration, this.userData.layoutCalcsVersion)
      : OsModuleGrid.Layouts.getInitialVersion(this.panelConfiguration)

    //Only apply a facet if facetUuid is set, otherwise it simply doesn't have an assigned facet.
    if (editor && this.userData.facetUuid) {
      var facet = editor.objectByUuid(this.userData.facetUuid)
      if (!facet) {
        //throw "Facet not found for assignment to OsModuleGrid"
        console.log(
          '!!!!! Warning: Facet not found for assignment to OsModuleGrid. We better hope this will be re-run elsewhere???'
        )
      } else {
        this.facet = facet
        facet.addFloatingObject(this)

        // Tricky/nasty workaround: The hash value was originally stored after the constructor was fired
        // but most of the userData had not yet been applied at that point.
        // Therefore, we manually reset the stored hash now that we have applied this data
        // so that when we float the object we don't accidentally treat it like a chance has occurred
        // which would clear out a lot of data (like diffuseShading) which is already correct
        // This was added to fix the symptom of diffuseShading being cleared as a result of
        // calling facet.floatObject(this). We should be careful to ensure this does not introduce
        // any other dangerous side-effects, such as the object-floating being incomplete.
        this.saveHash()

        facet.floatObject(this)
      }
    }
    //if (this.cellsActive.length === 0) {
    //@TODO: We assume this is a redundant and results in duplicate modules being created when loading saved design
    // this.populate(
    //   this.userData.modules.map(function(m) {
    //     return m.cell
    //   })
    // )
    //}
  },

  colForWorldPoint: function (worldPoint) {
    // var delta = new THREE.Vector3().subVectors(worldPoint, this.position)
    // translate into the moduleGrid local space
    //this.updateMatrix()
    var localPoint = this.worldToLocal(worldPoint.clone())
    return Math.round(localPoint.x / this.size[0])
  },

  //////////////////////////////////////////////////
  //#region   Layout Computation
  //////////////////////////////////////////////////

  getColRowSpacingFormula: function () {
    if (this.cellsActive.length > 0 && this.useNewSpacingFormula) {
      const maxX = this.colSpacingOffset === undefined ? this._getActiveCellsMaxCoordX() : this.colSpacingOffset
      const maxY = this.rowSpacingOffset === undefined ? this._getActiveCellsMaxCoordY() : this.rowSpacingOffset
      // the baseline is so that the panel at 0,0 will have spacing = 0, which is the established behavior
      const baselineX = Math.floor(maxX / this.modulesPerCol())
      const baselineY = Math.floor(maxY / this.modulesPerRow())
      return {
        // @TODO: tweak the formula for col spacing as it currently has an awkward behavior
        // wherein the grouping gravitate towards the right instead of towards the left
        // when you place panels left to right
        // example: if you have [][][][][], you'll get [] [][] [][]
        // instead of [][] [][] []
        // this is because the grid coordinates of panels have positive x values going to the right, negative to the left
        // the formula maxX - cellX will force the grouping to gravitate towards the panel with the biggest x value
        computeColSpacing: (cellX) => -(Math.floor((maxX - cellX) / this.modulesPerCol()) - baselineX),
        computeRowSpacing: (cellY) => -(Math.floor((maxY - cellY) / this.modulesPerRow()) - baselineY),
      }
    } else {
      return {
        // provide the old formula for when the module grid was already using it (compatibility with existing designs)
        // or when there are no active cells in the module grid (our new formula will break in that case)
        computeColSpacing: (cellX) =>
          cellX !== 0 ? Math.floor(cellX / this.modulesPerCol()) : Math.ceil(cellX / this.modulesPerCol()),
        computeRowSpacing: (cellY) =>
          cellY !== 0 ? Math.floor(cellY / this.modulesPerRow()) : Math.ceil(cellY / this.modulesPerRow()),
      }
    }
  },

  /**
   * Pre-computes all SHARED layout parameters to be used for any per-panel position+rotation computation
   * See: positionAndRotationForCell()
   *
   * Result is cached for speed, and cleared by this.onChange() which is simple but very effective.
   *
   * @param {{ flipOrientation: boolean, additionalZOffset: number, ignoreGroundClearance: boolean }} params
   * @returns {{
   * isOnFacet: boolean,
   * isOnTiltRacks: boolean,
   * panelConfig: string, // 'STANDARD' | 'TILT_RACK' | 'DUAL_TILT_RACK'
   * isOffset: boolean,
   * isOrientationFlipped: boolean,
   * moduleWidth: number,
   * moduleHeight: number,
   * modulesPerCol: number,
   * modulesPerRow: number,
   * moduleSpacingX: number,
   * moduleSpacingY: number,
   * groupSpacingX: number,
   * groupSpacingY: number,
   * colSpacingOffset: number,
   * rowSpacingOffset: number,
   * computeColSpacingFunc: Function,
   * computeRowSpacingFunc: Function,
   * totalInterModuleSpaceX: number,
   * totalInterModuleSpaceY: number,
   * gridOriginOffset: Vector3,
   * groundClearance: number,
   * xOffset: number,
   * extraSlope: number,
   * relativeTilt: number,
   * rotationX: number,
   * forshortenedDistancePerPanelY: number,
   *
   * }}
   */
  getParamsForLayoutCalcs: function (params) {
    const cacheKey = JSON.stringify(params)
    if (this.getParamsForLayoutCalcsCache[cacheKey]) {
      return this.getParamsForLayoutCalcsCache[cacheKey]
    }

    const { flipOrientation, additionalZOffset, ignoreGroundClearance } = params

    const isOnFacet = this.facet
    const isOnTiltRacks = this.isUsingTiltRacks()
    const panelConfig = this.getPanelConfiguration()
    const isOffset = this.moduleLayoutOffset()

    const moduleSize = this.size || [1, 1]
    const moduleWidth = flipOrientation ? moduleSize[1] : moduleSize[0]
    const moduleHeight = flipOrientation ? moduleSize[0] : moduleSize[1]

    const modulesPerCol = this.modulesPerCol()
    const modulesPerRow = this.modulesPerRow()
    const moduleSpacingX = modulesPerCol > 1 ? this.moduleSpacing[0] : 0
    const moduleSpacingY = modulesPerRow > 1 ? this.moduleSpacing[1] : 0
    const groupSpacingX = modulesPerCol > 1 ? this.groupSpacing[0] : this.moduleSpacing[0]
    const groupSpacingY = modulesPerRow > 1 ? this.groupSpacing[1] : this.moduleSpacing[1]
    let colSpacingOffset = this.colSpacingOffset === undefined ? this._getActiveCellsMaxCoordX() : this.colSpacingOffset
    let rowSpacingOffset = this.rowSpacingOffset === undefined ? this._getActiveCellsMaxCoordY() : this.rowSpacingOffset

    if (this.cellsActive.length === 0) {
      colSpacingOffset = 0
      rowSpacingOffset = 0
    }

    const spacingFormula = this.getColRowSpacingFormula()

    const totalInterModuleSpaceX = (modulesPerCol - 1) * moduleSpacingX
    const totalInterModuleSpaceY = (modulesPerRow - 1) * moduleSpacingY

    const gridOriginOffset = this.gridOriginOffset || new THREE.Vector3(0, 0, 0)
    const groundClearance = (ignoreGroundClearance === true ? 0 : this.groundClearance()) + additionalZOffset
    const xOffset = isOffset ? (moduleWidth + moduleSpacingX + groupSpacingX) / 2 : 0

    let extraSlope = 0
    let rotationX = 0

    // @TODO refactor this block of code so it's more straightforward and readable...
    if (isOnFacet || isOnTiltRacks) {
      let underlyingSlope = 0

      if (isOnTiltRacks) {
        underlyingSlope = this.getSlope()
      } else if (isOnFacet) {
        underlyingSlope = this.facet.slope
      }

      extraSlope = this.panelTiltOverride !== null ? this.panelTiltOverride - underlyingSlope : 0

      if (Math.abs(extraSlope) < 1) extraSlope = 0

      if (this.extraSlopeApplied !== extraSlope) {
        rotationX = extraSlope * THREE.Math.DEG2RAD
      }
    }

    const relativeTilt = extraSlope

    const forshortenedDistancePerPanelY = moduleHeight - Math.cos(extraSlope * THREE.Math.DEG2RAD) * moduleHeight

    let result = {
      isOnFacet,
      isOnTiltRacks,
      panelConfig,
      isOffset,
      isOrientationFlipped: !!flipOrientation,

      moduleWidth,
      moduleHeight,

      modulesPerCol,
      modulesPerRow,
      moduleSpacingX,
      moduleSpacingY,
      groupSpacingX,
      groupSpacingY,
      colSpacingOffset,
      rowSpacingOffset,
      computeColSpacingFunc: spacingFormula.computeColSpacing,
      computeRowSpacingFunc: spacingFormula.computeRowSpacing,
      totalInterModuleSpaceX,
      totalInterModuleSpaceY,

      gridOriginOffset,
      groundClearance,
      xOffset,
      extraSlope,
      relativeTilt,
      rotationX,
      forshortenedDistancePerPanelY,
    }

    this.getParamsForLayoutCalcsCache[cacheKey] = result
    return result
  },
  /*
  Stores the last result of getParamsForLayoutCalcs() to avoid recalculating it
  This value is cleared in onChange()
  */
  getParamsForLayoutCalcsCache: {},
  getParamsForLayoutCalcsCacheClear: function () {
    this.getParamsForLayoutCalcsCache = {}
  },

  /**
   * Computes the position and rotation of a cell in the module grid
   * given the layout properties of the module grid and the cell's coordinates
   *
   * @param {{
   * isOnFacet: boolean,
   * isOnTiltRacks: boolean,
   * isOffset: boolean,
   * isOrientationFlipped: boolean,
   * moduleWidth: number,
   * moduleHeight: number,
   * moduleCoordX: number,
   * moduleCoordY: number
   * modulesPerCol: number,
   * modulesPerRow: number,
   * moduleSpacingX: number,
   * moduleSpacingY: number,
   * groupSpacingX: number,
   * groupSpacingY: number,
   * colSpacingOffset: number,
   * rowSpacingOffset: number,
   * computeColSpacingFunc: Function,
   * computeRowSpacingFunc: Function,
   * totalInterModuleSpaceX: number,
   * totalInterModuleSpaceY: number,
   * gridOriginOffset: Vector3,
   * groundClearance: number,
   * xOffset: number,
   * extraSlope: number,
   * rotationX: number,
   * forshortenedDistancePerPanelX: number,
   * forshortenedDistancePerPanelY: number,
   * applyToObject: OsModule
   * }} params
   * @returns {{ position: Vector3, rotation: Vector3 }}
   */
  positionAndRotationForCell: function (params) {
    return this.layoutCalcs.formula(params)
  },

  //////////////////////////////////////////////////
  //#endregion   Layout Computation
  //////////////////////////////////////////////////

  determineInactiveModuleVisibility: function () {
    //Never show inactive modules in `presentation` displayMode
    if (OsModuleGrid.displayMode === 'presentation') {
      return false
    }

    return this._mouseOver || !this.facet
  },

  anyClickSelectsModuleGrid: function (value) {
    for (var cell in this.moduleObjects) {
      this.moduleObjects[cell].selectable = value
    }
  },

  getBoxHelperVisibility: function () {
    return this.cellsActive.length > 100
  },

  refreshSelectionBox: function () {
    if (this.getBoxHelperVisibility() && SceneHelper.getSelectionBox().visible === false) {
      editor.viewport.refreshSelectionBox()
    } else if (SceneHelper.getSelectionBox().visible && this.getBoxHelperVisibility() === false) {
      SceneHelper.getSelectionBox().visible = false
    }
  },

  refreshModules: function () {
    // selected==true:
    //     show inactive modules
    //     clicking active/inactive module starts module painting
    //     show azimuth indicator arrow
    //
    // selected==false:
    //     hide inactive modules
    //     clicking active module selects the parent grid
    //     hide azimuth indicator arrow
    //
    // always: only show handle if no modules active
    //

    editor.uiPause('ui', 'OsModuleGrid.refreshModules')
    editor.uiPause('render', 'OsModuleGrid.refreshModules')

    this.setVisibility(this.selected() === true)
    if (this.selected()) {
      for (var cell in this.moduleObjects) {
        this.moduleObjects[cell].visible = true

        //modules not selectable when grid is selected (only paintable)
        this.moduleObjects[cell].selectable = true
        this.moduleObjects[cell].moduleTexture(this.moduleTexture())
        //update selection outline
        if (this.moduleObjects[cell].active) {
          this.moduleObjects[cell].refreshModuleOutline()
        }

        delete this.moduleObjects[cell].selectionDelegate
      }

      this.refreshSelectionBox()
    } else {
      for (let cell in this.moduleObjects) {
        this.moduleObjects[cell].visible =
          this.moduleObjects[cell].active || (this.cellIsBuildable(cell) && editor?.displayMode === 'interactive')

        //modules selectable when grid is inactive (inactive modules are invisible)
        this.moduleObjects[cell].selectable =
          this.moduleObjects[cell].active || (this.cellIsBuildable(cell) && editor?.displayMode === 'interactive')

        //clicking module on inactive grid selects the parent grid
        this.moduleObjects[cell].selectionDelegate = this

        this.moduleObjects[cell].moduleTexture(this.moduleTexture())
        //update selection outline
        if (this.moduleObjects[cell].active) {
          this.moduleObjects[cell].refreshModuleOutline()
        }
      }
    }

    window.studioDebug &&
      console.log('Hack to force module outlines buttons to disappear immediately after module grid is deselected')

    // @TODO: Check if this is safe to remove???
    // setTimeout(function() {
    //   editor.viewport.render()
    // }, 50)

    editor.uiResume('render', 'OsModuleGrid.refreshModules')
    editor.uiResume('ui', 'OsModuleGrid.refreshModules', false) // do not trigger a react refresh on completion
  },

  getCellsActiveModified: function (cell, active) {
    var tmpCellsActive = this.cellsActive.slice(0)

    if (active && tmpCellsActive.indexOf(cell) === -1) {
      tmpCellsActive.push(cell)
    } else if (!active && tmpCellsActive.indexOf(cell) !== -1) {
      tmpCellsActive.splice(tmpCellsActive.indexOf(cell), 1)
    }

    tmpCellsActive.forEach(function (c) {
      if (!c) {
        console.log('cell empty!', cell)
      }
    })

    return tmpCellsActive
  },

  activateCell: function (cell, active, draw) {
    if (!cell) {
      // var msg = 'Error: attempting to activateCell with cell==null'
      return
    }

    this.cellsActive = this.getCellsActiveModified(cell, active)
    this.cellsActiveUpdatedClearCaches()

    if (draw !== false) {
      this.draw()
    }

    this.onModuleActivationChange(false)
  },

  setActivationForModule: function (systemModule, active) {
    var cell = Utils.getKeyByValue(this.moduleObjects, systemModule)
    this.activateCell(cell, active)
  },

  getBounds: function (includeBuildableCells = false) {
    //format: [minx, miny, maxx, maxy]
    var cells =
      includeBuildableCells && this.hasBuildablePanels()
        ? this.cellsActive.concat(this.buildableCells)
        : this.cellsActive
    if (cells.length === 0) {
      return null
    } else {
      var firstCellParts = cells[0].split(',').map(function (v) {
        return parseInt(v, 10)
      })
      var minX = firstCellParts[0],
        minY = firstCellParts[1],
        maxX = firstCellParts[0],
        maxY = firstCellParts[1]

      cells.forEach(function (cell) {
        var cellParts = cell.split(',').map(function (v) {
          return parseInt(v, 10)
        })

        if (cellParts[0] < minX || typeof minX === 'undefined') {
          minX = cellParts[0]
        } else if (cellParts[0] > maxX) {
          maxX = cellParts[0]
        }
        if (cellParts[1] < minY) {
          minY = cellParts[1]
        } else if (cellParts[1] > maxY) {
          maxY = cellParts[1]
        }
      })

      return [minX, minY, maxX, maxY]
    }
  },

  fillRectangle: function () {
    var bounds = this.getBounds()
    if (bounds) {
      var newCellsActive = []
      for (var x = bounds[0]; x <= bounds[2]; x++) {
        for (var y = bounds[1]; y <= bounds[3]; y++) {
          newCellsActive.push(x + ',' + y)
        }
      }
      return newCellsActive
      //this.populate(newCellsActive, true)
    }
  },

  clearLanes: function (spanSize, gapSize, direction) {
    var shouldClearCell = function (position) {
      var index_in_chunk = position % (spanSize + gapSize)
      if (index_in_chunk + 1 > spanSize) {
        return true
      }
    }

    var bounds = this.getBounds()
    var cellsToRemove = []
    if (bounds) {
      for (var x = bounds[0]; x <= bounds[2]; x++) {
        for (var y = bounds[1]; y <= bounds[3]; y++) {
          // Start at first row which is 0-bounds[1]
          if (direction === 'rows') {
            if (shouldClearCell(y - bounds[1])) {
              cellsToRemove.push(x + ',' + y)
            }
          } else {
            //cols
            if (shouldClearCell(x - bounds[0])) {
              cellsToRemove.push(x + ',' + y)
            }
          }
        }
      }
    }

    var newCellsActive = this.cellsActive.filter(function (cell) {
      return cellsToRemove.indexOf(cell) === -1
    })
    this.populate(newCellsActive, false)
  },

  duplicate: function (options) {
    var positionOffset = Utils.positionOffsetFromDuplicateOptions(options)
    this.refreshUserData()
    var dupOptions = {
      facet: this.facet,
      orientation: this._moduleLayout,
      offsetRows: this._moduleLayoutOffset,
      panelTiltOverride: this.panelTiltOverride,
      panelConfiguration: this.panelConfiguration,
      panelPlacement: this.panelPlacement,
      gridOriginOffset: this.gridOriginOffset.toArray(),
      moduleSpacing: this.moduleSpacing,
      groupSpacing: this.groupSpacing,
      position:
        options?.keepPosition === true
          ? this.position.clone()
          : new THREE.Vector3().copy(this.position).add(positionOffset),
      rotation: new THREE.Euler().copy(this.rotation),
      size: this.size,
      moduleTexture: this.moduleTexture(),
      cellsActive: this.cellsActive,
      buildableCells: this.buildableCells,
    }
    var newModuleGrid = new OsModuleGrid(dupOptions)
    newModuleGrid.userData = JSON.parse(JSON.stringify(this.userData))
    newModuleGrid.applyUserData()
    editor.execute(new AddObjectCommand(newModuleGrid, this.getSystem(), true))
    if (newModuleGrid.hasBuildablePanels()) {
      newModuleGrid.draw(true)
    }

    return newModuleGrid
  },

  updateCellToUuid: function (cellToUuid) {
    /*
    cellToUuid: Optionally supply a dict which specifies the module uuid for each cell so we can create modules with
    deterministic uuids
    */
    this.cellToUuid = Object.assign(this.cellToUuid, cellToUuid)
  },

  populate: function (cellsActive, forceClear) {
    //@todo: Retain existing modules, only add/remove those that need an update

    this.cellsActive = cellsActive
    this.cellsActiveUpdatedClearCaches()

    if (SceneHelper.__flag_prevent_resetObjectPositionToCentroidOfCells) {
      // Workaround for autoDesign and loader.parse(). We just attempt to detect this scenario by checking
      // this.parent not set. This could be dangerous.
      // This also fires when FingerPaint is initiated but it does not seem to cause any problems in that case.
      this.draw(forceClear)
      this.updateMatrixWorld()
      return
    }

    // Removed due to bugs with panels sometimes not showing and inactive cells
    // not being redrawn/repositioned
    //
    // // Beware: if we remove the call to resetObjectPositionToCentroidOfCells(true)
    // // we need to reinstate a separate call to this.draw(forceClear)
    if (cellsActive.length > 1 && !this._ghostMode) {
      // In some cases this will be much more inefficient because it always uses forceClear
      // @TODO: Try to only call forceClear when necessary?
      // var forceDrawAndClear = true
      this.resetObjectPositionToCentroidOfCells(false)
    } else {
      this.draw(forceClear)
      this.updateMatrixWorld()
    }
  },

  /**
   * Computes which active cells (panels) in this module grid
   * are not completely surrounded on all four sides (TOP, RIGHT, BOTTOM, LEFT)
   * The data returned will also indicate diagonal borders (TOP-RIGHT, BOTTOM-LEFT, etc)
   * which can be useful in some scenarios (e.g. showing setbacks)
   *
   * @param {Boolean} returnComputationData - data such as the cellMap, spanX, etc. will also be returned along
   *                                          with the list of border cells if set to true
   * @returns [
   *  Array<{ coordString: Array<string>, dirs: Array<string> }> - list of border cell coords and their border directions
   *  Uint8Array - the cell map (an n x m matrix where n = spanX, m = spanY) each cell marked 1 is an active cell, 0 otherwise
   *  Number - the lowest x coord of the active cells (minX)
   *  Number - the lowest y coord of the active cells (minY)
   *  Number - the span of the panels along the x axis (spanX)
   *  Number - the span of the panels along the y axis (spanY)
   * ]
   *  OR
   *  Array<[border]> - the list of border cells
   */
  getBorderCells: function (returnComputationData = false) {
    if (this.cellsActive.length === 0) {
      // the are no active cells in this module grid; abort
      return returnComputationData ? [[], new Uint8Array(0), 0, 0, 0, 0] : []
    }

    let maxX = Number.NEGATIVE_INFINITY
    let maxY = Number.NEGATIVE_INFINITY
    let minX = Number.POSITIVE_INFINITY
    let minY = Number.POSITIVE_INFINITY

    // cellsActive array contains the coordinates of the panels as strings
    // we must first convert them to number pairs for further processing
    // record the max and min Xs and Ys for later
    // IMPORTANT TO REMEMBER:
    // The direction of the x and y axis of the cell coords are flipped
    // Take the cell at 0,0 as reference,
    // the cell at its RIGHT is -1,0, the cell at its LEFT is 1,0
    // the cell at its TOP is 0,-1, the cell at its BOTTOM is 0,1
    // Keep this in mind!
    const activeCellsXY = this.cellsActive.map((cell) => {
      const cellCoords = cell.split(',').map((c) => parseInt(c))
      if (cellCoords[0] > maxX) maxX = cellCoords[0]
      if (cellCoords[1] > maxY) maxY = cellCoords[1]
      if (cellCoords[0] < minX) minX = cellCoords[0]
      if (cellCoords[1] < minY) minY = cellCoords[1]
      return cellCoords
    })

    // compute the x and y span
    // which will let us know how high and wide the module grid is
    const spanX = maxX - minX + 1
    const spanY = maxY - minY + 1

    // this function is for debugging purposes only
    const printCellMap = (cellMap, spanX) => {
      let rowString = ''
      let finalString = ''
      for (let i = 0; i < cellMap.length; i++) {
        rowString += cellMap[i]
        rowString += ' '
        if ((i + 1) % spanX === 0) {
          finalString += '\n'
          finalString += rowString.trim().split('').reverse().join('')
          rowString = ''
        }
      }
      console.log(finalString.trim())
    }

    // cellMap is a matrix of 1s (active cells) and 0s (inactive cells)
    // that's exactly spanX wide and spanY high
    // we'll use it as a lookup table to quickly determine
    // if an active cell is adjacent to another active cell
    const cellMap = new Uint8Array(spanX * spanY)
    activeCellsXY.forEach((activeCell) => {
      // active cell coords can start at arbitrary integer values (not necessarily 0)
      // we must first map these raw coords so they start at index 0
      // in this case, minX and minY will both be mapped to 0
      const xCoordMapped = activeCell[0] + -minX
      const yCoordMapped = activeCell[1] + -minY
      cellMap[spanX * yCoordMapped + xCoordMapped] = 1
    })

    window.studioDebug && printCellMap(cellMap, spanX)

    // find the border cells of this module grid
    // border cells are cells that are active but have at least one side
    // that's either at the very edge of the module grid
    // or adjacent to a non-active cell
    const borderCells = []
    const [LEFT, RIGHT, TOP, BOTTOM] = ['L', 'R', 'T', 'B']
    const [TOP_LEFT, BOTTOM_LEFT, TOP_RIGHT, BOTTOM_RIGHT] = ['TL', 'BL', 'TR', 'BR']

    // function for determining whether a cell at [xCoordMapped, yCoordMapped] of the cell map
    // is not bordered by an active cell at a specified corner
    const cornerCellIsInactive = (cellMap, spanX, spanY, xCoordMapped, yCoordMapped, corner) => {
      const targetX = corner === TOP_LEFT || corner === BOTTOM_LEFT ? xCoordMapped + 1 : xCoordMapped - 1
      const targetY = corner === BOTTOM_LEFT || corner === BOTTOM_RIGHT ? yCoordMapped + 1 : yCoordMapped - 1
      if (targetX < 0 || targetX === spanX) return true
      if (targetY < 0 || targetY === spanY) return true
      return cellMap[spanX * targetY + targetX] === 0
    }

    let xCoordMapped = 0
    let yCoordMapped = 0
    let dirs = []
    let bordersLeft = false
    let bordersRight = false

    for (let i = 0; i < activeCellsXY.length; i++) {
      xCoordMapped = activeCellsXY[i][0] + -minX
      yCoordMapped = activeCellsXY[i][1] + -minY
      dirs = []
      bordersLeft = false
      bordersRight = false

      if (xCoordMapped - 1 < 0 || cellMap[spanX * yCoordMapped + (xCoordMapped - 1)] === 0) {
        // we stepped to the left of the cell, this visually corresponds to going right
        // Remember the flipped coord system for cells
        dirs.push(RIGHT)
        bordersRight = true
      }

      if (xCoordMapped + 1 === spanX || cellMap[spanX * yCoordMapped + (xCoordMapped + 1)] === 0) {
        // we stepped to the right of the cell, this visually corresponds to going left
        // Remember the flipped coord system for cells
        dirs.push(LEFT)
        bordersLeft = true
      }

      if (yCoordMapped - 1 < 0 || cellMap[spanX * (yCoordMapped - 1) + xCoordMapped] === 0) {
        // we stepped below of the cell, this visually corresponds to going up
        // Remember the flipped coord system for cells
        dirs.push(TOP)

        bordersLeft &&
          cornerCellIsInactive(cellMap, spanX, spanY, xCoordMapped, yCoordMapped, TOP_LEFT) &&
          dirs.push(TOP_LEFT)
        bordersRight &&
          cornerCellIsInactive(cellMap, spanX, spanY, xCoordMapped, yCoordMapped, TOP_RIGHT) &&
          dirs.push(TOP_RIGHT)
      }

      if (yCoordMapped + 1 === spanY || cellMap[spanX * (yCoordMapped + 1) + xCoordMapped] === 0) {
        // we stepped above of the cell, this visually corresponds to going down
        // Remember the flipped coord system for cells
        dirs.push(BOTTOM)

        bordersLeft &&
          cornerCellIsInactive(cellMap, spanX, spanY, xCoordMapped, yCoordMapped, BOTTOM_LEFT) &&
          dirs.push(BOTTOM_LEFT)
        bordersRight &&
          cornerCellIsInactive(cellMap, spanX, spanY, xCoordMapped, yCoordMapped, BOTTOM_RIGHT) &&
          dirs.push(BOTTOM_RIGHT)
      }

      // do not record cells that are surrounded on all sides
      if (dirs.length > 0) {
        borderCells.push({ coordString: activeCellsXY[i].join(','), dirs })
      }
    }

    return returnComputationData ? [borderCells, cellMap, minX, minY, spanX, spanY] : borderCells
  },

  showSetbacks: function () {
    const setbackDistances = SetbacksHelper.getSetbackDistances()
    const SETBACK_IN_METERS = 'arrays' in setbackDistances ? setbackDistances['arrays'] : 0

    if (SETBACK_IN_METERS === 0 || (this.facet && !this.facet.isNonSpatial())) return

    const [LEFT, RIGHT, TOP, BOTTOM] = ['L', 'R', 'T', 'B']
    const [TOP_LEFT, BOTTOM_LEFT, TOP_RIGHT, BOTTOM_RIGHT] = ['TL', 'BL', 'TR', 'BR']
    const SETBACK_OFFSET_Z = 0.03 // raise setbacks a tiny bit to prevent z-fighting
    const [GO_LEFT, GO_RIGHT, GO_UP, GO_DOWN] = [-1, 1, 1, -1]

    const isLateralAxis = (dir) => {
      return (
        dir === LEFT ||
        dir === RIGHT ||
        dir === TOP_LEFT ||
        dir === TOP_RIGHT ||
        dir === BOTTOM_LEFT ||
        dir === BOTTOM_RIGHT
      )
    }

    const isMedialAxis = (dir) => {
      return (
        dir === TOP ||
        dir === BOTTOM ||
        dir === TOP_LEFT ||
        dir === TOP_RIGHT ||
        dir === BOTTOM_LEFT ||
        dir === BOTTOM_RIGHT
      )
    }

    // creates a new setback mesh according to specs
    // or recycles an existing mesh and configures it according to specs
    const prepareSetbackMesh = (
      mesh,
      modulePosition,
      moduleSize,
      dir,
      setbackInMeters,
      clipLeft = false,
      clipRight = false
    ) => {
      const HALF_OF_MODULE_WIDTH = moduleSize[0] / 2
      const HALF_OF_MODULE_HEIGHT = moduleSize[1] / 2
      const HALF_OF_SETBACK = setbackInMeters / 2
      let offsetZ = SETBACK_OFFSET_Z
      const PANEL_TILT = this.panelTiltOverride || 0
      const IS_USING_TILT_RACKS = this.isUsingTiltRacks() && PANEL_TILT > 0

      const setbackMesh = mesh || this.setbackMeshTemplate.clone()

      setbackMesh.material = setbackMesh.material.clone()
      setbackMesh.material.uniforms.borderFlags = OsModuleGrid.setbacksBorderFlagsUniforms[dir]

      const setbackMeshWidth = isLateralAxis(dir)
        ? setbackInMeters
        : moduleSize[0] - setbackInMeters * (clipLeft + clipRight) // implicit cast to boolean to int
      const setbackMeshHeight = isMedialAxis(dir)
        ? setbackInMeters
        : Math.cos(THREE.Math.DEG2RAD * PANEL_TILT) * HALF_OF_MODULE_HEIGHT * 2

      if (setbackMeshWidth <= 0) return null

      setbackMesh.scale.set(setbackMeshWidth, setbackMeshHeight, 1)

      const setbackOffsetX = isLateralAxis(dir)
        ? (HALF_OF_MODULE_WIDTH + HALF_OF_SETBACK) *
          (dir === LEFT || dir === TOP_LEFT || dir === BOTTOM_LEFT ? GO_LEFT : GO_RIGHT)
        : 0 - (clipLeft ? HALF_OF_SETBACK : 0) + (clipRight ? HALF_OF_SETBACK : 0)
      const setbackOffsetY = isMedialAxis(dir)
        ? (Math.cos(THREE.Math.DEG2RAD * PANEL_TILT) * HALF_OF_MODULE_HEIGHT + HALF_OF_SETBACK) *
          (dir === TOP || dir === TOP_LEFT || dir === TOP_RIGHT ? GO_UP : GO_DOWN)
        : 0

      if (IS_USING_TILT_RACKS) {
        offsetZ -= modulePosition.z
      }

      setbackMesh.position.copy(modulePosition).add(new THREE.Vector3(setbackOffsetX, setbackOffsetY, offsetZ))
      return setbackMesh
    }

    // determines whether a cell at coord in the cellMap is flanked at its right or/an left
    const getLeftRightFlank = (cellMap, coord, spanX, spanY) => {
      let leftFlank = false
      let rightFlank = false
      if (coord[1] < 0 || coord[1] === spanY) {
        // y coord is out of bounds
        return { leftFlank, rightFlank }
      }

      leftFlank = !(coord[0] + 1 === spanX || cellMap[spanX * coord[1] + (coord[0] + 1)] === 0)
      rightFlank = !(coord[0] - 1 < 0 || cellMap[spanX * coord[1] + (coord[0] - 1)] === 0)

      return { leftFlank, rightFlank }
    }

    const GET_COMPUTATION_DATA = true
    const GAP_X_EXCEEDS_SETBACK = this.moduleSpacing[0] > SETBACK_IN_METERS
    const GAP_Y_EXCEEDS_SETBACK =
      this.moduleSpacing[1] > SETBACK_IN_METERS || (this.isUsingTiltRacks() && this.panelTiltOverride > 0)
    const [borderCells, cellMap, minX, minY, spanX, spanY] = this.getBorderCells(GET_COMPUTATION_DATA)

    // generate the setback meshes for this module grid
    // NOTE that if this module grid's setbacks have been shown before
    // (such as when this function is called just to refresh the setbacks)
    // there will be existing setback meshes already in the scene tree
    // rather than wastefully throwing them away and generating everything again from scratch,
    // we recycle these meshes and just change their scale and position as necessary
    // if the quantity of existing meshes is not enough, we create new ones
    // if there's more than enough, we delete the extras

    let existingMeshCount = this.setbacksMarkers.children.length
    let recyclingMeshCounter = 0

    if (this.isUsingTiltRacks()) {
      const bcells = borderCells.map((bcell) => bcell.coordString)
      _.xor(this.cellsActive, bcells).forEach((cell) => {
        borderCells.push({
          coordString: cell,
          dirs: [TOP, BOTTOM],
        })
      })
    }

    borderCells.forEach((bcell) => {
      // get the module corresponding to the border cell
      const module = this.moduleObjects[bcell.coordString]
      const coordXY = bcell.coordString.split(',').map((coord) => parseInt(coord))
      const coordXYMapped = [coordXY[0] + -minX, coordXY[1] + -minY]

      if (GAP_Y_EXCEEDS_SETBACK) {
        bcell.dirs.push(TOP, BOTTOM)
        const flanks = getLeftRightFlank(cellMap, coordXYMapped, spanX, spanY)
        !flanks.leftFlank && bcell.dirs.push(TOP_LEFT, BOTTOM_LEFT)
        !flanks.rightFlank && bcell.dirs.push(TOP_RIGHT, BOTTOM_RIGHT)
      }

      if (GAP_X_EXCEEDS_SETBACK) {
        bcell.dirs.push(LEFT, RIGHT, TOP_LEFT, TOP_RIGHT, BOTTOM_LEFT, BOTTOM_RIGHT)
      }

      if (this.isUsingTiltRacks()) {
        bcell.dirs.push(TOP, BOTTOM)
      }

      bcell.dirs = _.uniq(bcell.dirs)

      bcell.dirs.forEach((setbackDir) => {
        let flanks = { leftFlank: false, rightFlank: false }

        // if (!GAP_Y_EXCEEDS_SETBACK && APPLY_CLIPPING && (setbackDir === TOP || setbackDir === BOTTOM)) {
        //   flanks = getLeftRightFlank(
        //     cellMap,
        //     [coordXYMapped[0], setbackDir === TOP ? coordXYMapped[1] - 1 : coordXYMapped[1] + 1],
        //     spanX,
        //     spanY
        //   )
        // }

        if (recyclingMeshCounter < existingMeshCount) {
          // recycle existing mesh
          prepareSetbackMesh(
            this.setbacksMarkers.children[recyclingMeshCounter],
            module.position,
            module.size,
            setbackDir,
            SETBACK_IN_METERS,
            flanks.leftFlank,
            flanks.rightFlank
          )
          recyclingMeshCounter++
        } else {
          // create a new mesh
          const mesh = prepareSetbackMesh(
            null,
            module.position,
            module.size,
            setbackDir,
            SETBACK_IN_METERS,
            flanks.leftFlank,
            flanks.rightFlank
          )
          mesh.selectable = false
          mesh && this.setbacksMarkers.add(mesh)
        }
      })
    })

    // delete any extra meshes that's left over from the previous call to this function
    const materialsToDispose = []
    while (existingMeshCount - (recyclingMeshCounter + 1) >= 0) {
      const deleted = this.setbacksMarkers.children.pop()
      materialsToDispose.push(deleted.material)
      existingMeshCount--
    }
    materialsToDispose.forEach((material) => {
      material.dispose()
    })
    // Version of three.js before R114 may prevent JS' GC from disposing unrefenced meshes
    // such as what we're doing above
    // If done frequently enough, this will cause a memory leak
    // one remedy to this is by making this call
    if (!!window.editor.viewport.getRenderer().renderLists) {
      window.editor.viewport.getRenderer().renderLists.dispose()
    }
    this.setbacksMarkers.visible = true
  },

  refreshSetbacks: function () {
    this.selected() && this.showSetbacks()
  },

  hideSetbacks: function () {
    this.setbacksMarkers.visible = false
  },

  getCellsActiveForQuickLookupCache: null,
  getCellsActiveForQuickLookup: function () {
    /*
    Sample output for cellsActive = [ 0,0  2,0  2,3  ]
    
    {
      "0": [0],
      "2": [0, 3],
    }

    */
    if (this.getCellsActiveForQuickLookupCache) {
      return this.getCellsActiveForQuickLookupCache
    }

    var cellsActiveVector2Dict = this.getCellsActiveVector2Dict()
    var result = {}
    Object.entries(cellsActiveVector2Dict).forEach(([cell, position]) => {
      if (!(position.x in result)) {
        result[position.x] = []
      }
      result[position.x].push(position.y)
    })

    this.getCellsActiveForQuickLookupCache = result
    return result
  },

  quickLookupForCellsCache: null,
  detectAdjacentCells: function () {
    var cellsActiveVector2Dict = this.getCellsActiveVector2Dict()
    var inactiveBuildableCellsVector2Dict = this.getInactiveBuildableCellsVector2Dict()
    const coreCells = { ...cellsActiveVector2Dict, ...inactiveBuildableCellsVector2Dict }

    const buildQuickLookupForCells = (cellsVector2Dict) => {
      if (this.quickLookupForCellsCache) {
        return this.quickLookupForCellsCache
      }
      const result = {}

      Object.entries(cellsVector2Dict).forEach(([_cell, position]) => {
        if (!(position.x in result)) {
          result[position.x] = []
        }
        result[position.x].push(position.y)
      })
      this.quickLookupForCellsCache = result
      return result
    }

    var coreCellsQuickLookup = buildQuickLookupForCells(coreCells)

    var adjacentCells = new Set()

    //Populate adjacent virtual modules for painting

    // Selection outlines
    // Draw a selection outline on the edge of an active cell if it touches an adjacent cell
    // Disable until buffer functionality is finalized
    var selectionOutlines = {}

    // Disable until buffer functionality is finalized
    // var selectionOutlineIndices = {
    //   '0,-1': 0, // top
    //   '1,0': 1, // right
    //   '0,1': 2, // down
    //   '-1,0': 3, // left
    // }

    // If there are no actvive panels then there really should not be any inactive panels but when placing grids down
    // we currently rely on at least one inactive panel being present in FingerPaingController which fails if no
    // inactive module is found under the click which places the moduleGrid on a facet.
    // UPDATE: We now generate inactive cells adjacent to inactive+buildable cells
    // in addition to ones we already generate adjacent to active+buildable cells
    if (this.cellsActive.length === 0 && !this.hasBuildablePanels()) {
      adjacentCells.add('0,0')
    } else {
      Object.entries(coreCells).forEach(([cell, position]) => {
        for (var x = -1; x < 2; x++) {
          for (var y = -1; y < 2; y++) {
            if (x !== 0 && y !== 0) {
              // ignore diagnally adjacent
              continue
            }

            var xCandidate = x + position.x
            var yCandidate = y + position.y

            if (!(xCandidate in coreCellsQuickLookup) || coreCellsQuickLookup[xCandidate].indexOf(yCandidate) === -1) {
              var adjacentCellCandidate = xCandidate + ',' + yCandidate
              if (!(adjacentCellCandidate in adjacentCells)) {
                adjacentCells.add(adjacentCellCandidate)
              }

              // Disable until buffer functionality is finalized
              // var selectionOutlineIndex
              //
              // if (x === 0 && y === 1) {
              //   selectionOutlineIndex = 2
              // } else if (x === 1 && y === 0) {
              //   selectionOutlineIndex = 3
              // } else if (x === 0 && y === -1) {
              //   selectionOutlineIndex = 0
              // } else if (x === -1 && y === 0) {
              //   selectionOutlineIndex = 1
              // }
              //
              // if (typeof selectionOutlineIndex !== 'undefined') {
              //   if (!(cell in selectionOutlines)) {
              //     selectionOutlines[cell] = [selectionOutlineIndex]
              //   } else if (selectionOutlines[cell].indexOf(selectionOutlineIndex) === -1) {
              //     selectionOutlines[cell].push(selectionOutlineIndex)
              //   }
              // }
            }
          }
        }
      }, this)
    }

    // convert Set to list
    return [[...adjacentCells], selectionOutlines]
  },

  getModuleForCell: function (col, row, cell) {
    cell = cell || col + ',' + row
    return this.moduleObjects[cell]
  },

  confirmBeforeDelete: function () {
    return 'Are you sure you want to delete this panel groups?'
  },

  cellsActiveUpdatedClearCaches: function () {
    this.centroidCache = null
    this.getCellsActiveVector2DictCache = null
    this.quickLookupForCellsCache = null
  },

  getModuleForCellFromAnySource: function (cell) {
    return (
      this.moduleObjects[cell] ||
      this.oldModuleObjects[cell] ||
      this.children.find((child) => child.cell === cell) ||
      null
    )
  },

  draw: function (forceClear, onlyResetObjectPositionToCentroidOfCellsArg) {
    /*
    onlyResetObjectPositionToCentroidOfCells means we are only adjusting module positions so we can skip out most of the other
    kinds of updates like material changes, etc.
    */

    if (editor.sceneIsLoading && !forceClear) {
      // It is critical that after loading the scene we call draw() on each ModuleGrid.
      // Currently this does happen inside editor.loadScene() which calls  moduleGrid.refreshFromChildren(this);
      // on each ModuleGrid.
      console.log(`Skip OsModuleGrid.draw(..., forceClear=${forceClear}) called while scene is loading.`)
      return
    }

    const onlyResetObjectPositionToCentroidOfCells = onlyResetObjectPositionToCentroidOfCellsArg

    if (editor.controllers?.Handle) {
      editor.controllers.Handle.refreshPaused = true
    }

    // Prepare for speed
    var moduleTexture = this.moduleTexture()
    var inactiveModuleVisibilityOverrideForSpeed = this.determineInactiveModuleVisibility()
    var buildablePanelsEditable =
      Designer.permissions.canEdit('systems.panels') || Designer.permissions.canEdit('systems.buildablePanels')

    editor.uiPause('ui', 'OsModuleGrid.draw')
    editor.uiPause('render', 'OsModuleGrid.draw')
    editor.uiPause('clearShading', 'OsModuleGrid.draw')

    //If we clear everything we need to rebuild the module-cell-to-string assignments
    var cellToStringMapping = []
    var correctly_ordered_stringsUUIDS = {}

    //Ensure we track what the original string order was
    if (forceClear && this.parent?.userData?.inverters) {
      this.parent.userData.inverters.forEach((inverter) => {
        inverter.mppts?.forEach((mppt) => {
          mppt.strings?.forEach((string) => {
            if (string.moduleUuids) {
              correctly_ordered_stringsUUIDS[string.uuid] = string.moduleUuids
            }
          })
        })
      })
    }
    // Delete all existing moduleObjects if forceClear==true which makes this MUCH slower
    if (forceClear) {
      for (var cell in this.moduleObjects) {
        const moduleObject = this.moduleObjects[cell]
        if (moduleObject.assignedOsString && this.cellsActive.includes(cell)) {
          cellToStringMapping.push({
            cell: cell,
            assignedOsStringUuid: moduleObject.assignedOsString.uuid,
            moduleUUID: moduleObject.uuid,
          })
        }
        delete this.moduleObjects[cell]
        this.oldModuleObjects[cell] = moduleObject
        // remove from scene after deleting reference in this.moduleObjects
        // because `Editor.removeObject` can trigger a lot of operations
        // which might query this.moduleObjects
        editor.removeObject(moduleObject)
      }
      // Reorder cellToStringMapping based on correctly_ordered_stringsUUIDS
      cellToStringMapping = Object.values(correctly_ordered_stringsUUIDS).flatMap((moduleUuids) =>
        moduleUuids
          .map((moduleUuid) => cellToStringMapping.find((item) => item.moduleUUID === moduleUuid))
          .filter(Boolean)
      )
    }

    var [adjacentCellsToPopulate, selectionOutlines] = this.detectAdjacentCells()

    //Clear any existing child SystemModules that belong to neither modules or adjacentModules
    if (onlyResetObjectPositionToCentroidOfCells !== true) {
      for (let cell in this.moduleObjects) {
        const moduleObject = this.moduleObjects[cell]
        if (this.cellsActive.indexOf(cell) !== -1) {
          //cell already exists and is a module
          moduleObject.setActivation(true, false, this, inactiveModuleVisibilityOverrideForSpeed)
          moduleObject.moduleTexture(moduleTexture)
          moduleObject.selectionOutlineEdges = selectionOutlines[cell]
        } else if (adjacentCellsToPopulate.indexOf(cell) !== -1) {
          //cell already exists and is adjacent
          moduleObject.setActivation(false, false, this, inactiveModuleVisibilityOverrideForSpeed)
          moduleObject.moduleTexture(moduleTexture)
          moduleObject.selectionOutlineEdges = []
        } else {
          delete this.moduleObjects[cell]
          this.oldModuleObjects[cell] = moduleObject
          // remove from scene after deleting reference in this.moduleObjects
          // because `Editor.removeObject` can trigger a lot of operations
          // which might query this.moduleObjects
          editor.removeObject(moduleObject)
        }
      }
    }

    const commonLayoutParams = this.getParamsForLayoutCalcs({
      ignoreGroundClearance: false,
      flipOrientation: false,
      additionalZOffset: 0,
    })

    var cellsActiveVector2Dict = this.getCellsActiveVector2Dict()

    this.cellsActive.forEach(function (cell) {
      if (this.moduleObjects[cell]) {
        // check for cells that have switched between two states -> buildable to non-buildable and vice-versa
        // switch the material accordingly
        if (
          (onlyResetObjectPositionToCentroidOfCells !== true && window.getStudioDetail()) === 'high' &&
          !window.RUNNING_AS_HTML_FOR_PDF_GENERATION
        ) {
          let newMaterial = OsModuleCache[moduleTexture].materialActive

          if (newMaterial !== this.moduleObjects[cell].material) {
            this.moduleObjects[cell].material = newMaterial
            this.moduleObjects[cell].material.needsUpdate = true
          }
        }
        if (forceClear) {
          // when we call forceUpdate we can skip updating the module position separately here because we will set this
          // already updated it above when we created it
          //
          // skip early to next cell
          return
        }
      }
      var m =
        this.getModuleForCellFromAnySource(cell) ||
        new OsModule({
          active: true,
          size: this.size,
          cell: cell,
          grid: this,
          moduleTexture: moduleTexture,
        })

      if (onlyResetObjectPositionToCentroidOfCells !== true) {
        if (this.cellToUuid[cell]) {
          m.uuid = this.cellToUuid[cell]
        }

        m.size = this.size
        m.setGeometryForSize()
      }

      //   this.positionAndRotationForCell(cell.split(',').map(parseFloat), m, false, false, 0, colRowSpacingFormula)
      const cellCoordsParsed = cellsActiveVector2Dict[cell]
      this.positionAndRotationForCell({
        ...commonLayoutParams,
        moduleCoordX: cellCoordsParsed.x,
        moduleCoordY: cellCoordsParsed.y,
        applyToObject: m,
      })
      if (onlyResetObjectPositionToCentroidOfCells !== true) {
        m.setActivation(true, false, this, inactiveModuleVisibilityOverrideForSpeed)
        if (window.getStudioDetail() === 'high' && !window.RUNNING_AS_HTML_FOR_PDF_GENERATION) {
          let newMaterial = OsModuleCache[moduleTexture].materialActive

          if (newMaterial !== m.material) {
            m.material = newMaterial
            m.material.needsUpdate = true
          }
        }

        if (m.parent !== this) {
          editor.addObject(m, this)
        }

        this.moduleObjects[cell] = m
      }
    }, this)

    var inactiveCellsToRender = []
    if (Designer.permissions.canEdit('systems.panels')) {
      if (this.hasBuildablePanels()) {
        inactiveCellsToRender = adjacentCellsToPopulate.concat(
          this.buildableCells.filter(
            (cell) => adjacentCellsToPopulate.indexOf(cell) === -1 && this.cellsActive.indexOf(cell) === -1
          )
        )
      } else {
        inactiveCellsToRender = adjacentCellsToPopulate
      }
    } else {
      if (this.hasBuildablePanels() && Designer.permissions.canEdit('systems.buildablePanels')) {
        inactiveCellsToRender = this.buildableCells.filter((cell) => this.cellsActive.indexOf(cell) === -1)
      }
    }

    var inactiveCellsToRenderVector2Dict = {}
    inactiveCellsToRender.forEach((cell) => {
      inactiveCellsToRenderVector2Dict[cell] = this.cellToVector2(cell)
    })

    inactiveCellsToRender.forEach(function (cell) {
      if (this.moduleObjects[cell]) {
        // check for cells that have switched between two states -> buildable to non-buildable and vice-versa
        // switch the material accordingly

        if (onlyResetObjectPositionToCentroidOfCells !== true) {
          let m = this.moduleObjects[cell]
          let newMaterial =
            buildablePanelsEditable && this.cellIsBuildable(cell)
              ? OsModuleCache[moduleTexture].materialInactiveBuildable
              : OsModuleCache[moduleTexture].materialInactive

          if (m.material !== newMaterial) {
            m.material = newMaterial
            m.material.needsUpdate = true
          }
        }

        if (forceClear) {
          // when we call forceUpdate we can skip updating the module position separately here because we will set this
          // already updated it above when we created it
          //
          // skip early to next cell
          return
        }
      }
      var m =
        this.getModuleForCellFromAnySource(cell) ||
        new OsModule({
          active: false,
          size: this.size,
          cell: cell,
          grid: this,
          moduleTexture: moduleTexture,
        })

      if (onlyResetObjectPositionToCentroidOfCells !== true) {
        if (this.cellToUuid[cell]) {
          m.uuid = this.cellToUuid[cell]
        }

        m.size = this.size
        m.setGeometryForSize()
        //   this.positionAndRotationForCell(cell.split(',').map(parseFloat), m, false, false, 0, colRowSpacingFormula)
      }

      const cellCoordsParsed = inactiveCellsToRenderVector2Dict[cell]
      this.positionAndRotationForCell({
        ...commonLayoutParams,
        moduleCoordX: cellCoordsParsed.x,
        moduleCoordY: cellCoordsParsed.y,
        applyToObject: m,
      })

      if (onlyResetObjectPositionToCentroidOfCells !== true) {
        m.setActivation(false, false, this, inactiveModuleVisibilityOverrideForSpeed)

        // override the default 'inactive' cell material
        // if the current cell is buildable
        m.material = this.cellIsBuildable(cell)
          ? OsModuleCache[moduleTexture].materialInactiveBuildable
          : OsModuleCache[moduleTexture].materialInactive

        if (m.parent !== this) {
          editor.addObject(m, this)
        }

        this.moduleObjects[cell] = m
      }
    }, this)

    if (onlyResetObjectPositionToCentroidOfCells !== true) {
      if (cellToStringMapping.length) {
        cellToStringMapping.forEach(function (item) {
          //includes swapping assignedOsString to new module

          var oldAssignedOsString = item.assignedOsStringUuid ? editor.objectByUuid(item.assignedOsStringUuid) : null

          if (oldAssignedOsString) {
            //Old method replaced the module, but the module would have already been removed from the string when it
            //was removed from scene.
            //oldAssignedOsString.replaceModule(item.systemModuleOld, this.moduleObjects[item.cell])
            //New method: Now we simply add the modules into place based on their cell position
            var moduleObject = this.moduleObjects[item.cell]
            if (moduleObject) {
              oldAssignedOsString.addModule(moduleObject)
            } else {
              console.warn(
                'Unable to call oldAssignedOsString.addModule(moduleObject), cell: ' + cell + ' no loner exist'
              )
            }

            //editor.execute(new StringModuleArrayAssignmentCommand(oldAssignedOsString, [this.moduleObjects[item.cell]]))
          } else {
            console.warn('Unable to call replaceModule on systemModuleOld.assignedOsString, assignedOsString not set')
          }
          //console.log('Re-assigned module-cell-to-string:' + this.moduleObjects[item.cell].uuid);
        }, this)
        cellToStringMapping = []
      }
    }

    // Redraw selection outline
    // this.refreshSelectionOutline(selectionOutlines)

    // @TODO: We could optimize this.refreshModules() when onlyResetObjectPositionToCentroidOfCells === true
    this.refreshModules()

    if (editor.controllers?.Handle) {
      editor.controllers.Handle.refreshPaused = false
    }

    if (editor.controllers?.Handle) {
      editor.controllers.Handle.refresh()
    }

    editor.uiResume('clearShading', 'OsModuleGrid.draw', false)

    if (!editor.sceneIsLoading) {
      // If scene is loading we are not actually making a change to the scene so do not clear shading
      Designer.clearShadingFromObjectAddedChangedRemovedDebouncedSkipRedundant(this)
    }

    editor.uiResume('render', 'OsModuleGrid.draw')
    editor.uiResume('ui', 'OsModuleGrid.draw')
  },

  // Disable until buffer functionality is finalized
  // useBufferToAdjacentCell: function (cellCoordinates, _selectionEdgeIndex, selectionOutlines) {
  //   // var cellCoordinates = this.getCellCoordinates()
  //   var dx = [-1, 0, 1, 0][_selectionEdgeIndex]
  //   var dy = [0, 1, 0, -1][_selectionEdgeIndex]
  //
  //   // if next panel also has same selection edge selected then offset by
  //   // this.selectionOutlineEdges
  //   var adjacentCell = cellCoordinates[0] + dx + ',' + (cellCoordinates[1] + dy)
  //
  //   var concaveChecks = [
  //     { relativeCellToCheckForConcave: [-1, -1], edgeType: 3 },
  //     { relativeCellToCheckForConcave: [-1, 1], edgeType: 0 },
  //     { relativeCellToCheckForConcave: [1, 1], edgeType: 1 },
  //     { relativeCellToCheckForConcave: [1, -1], edgeType: 2 },
  //   ]
  //
  //   if (selectionOutlines[adjacentCell] && selectionOutlines[adjacentCell].indexOf(_selectionEdgeIndex) !== -1) {
  //     // Draw buffer because the edge continues on the next cell
  //     return true
  //   }
  //
  //   var concaveCheckCell =
  //     cellCoordinates[0] +
  //     concaveChecks[_selectionEdgeIndex].relativeCellToCheckForConcave[0] +
  //     ',' +
  //     (cellCoordinates[1] + concaveChecks[_selectionEdgeIndex].relativeCellToCheckForConcave[1])
  //
  //   // Draw buffer because the adjacent edge continues on the next cell to make a continuous concave edge
  //   // I don't know how to do this efficiently... switching to do all edge drawing at the OsModuleGrid level... :-(
  //   // EdgeType 2 is concave if the
  //
  //   if (
  //     concaveChecks[_selectionEdgeIndex] &&
  //     selectionOutlines[concaveCheckCell] &&
  //     selectionOutlines[concaveCheckCell].indexOf(concaveChecks[_selectionEdgeIndex].edgeType) !== -1
  //   ) {
  //     return true
  //   }
  //
  //   // otherwise always return 0,0
  //   return false
  // },

  alignViewOnChange: function (newValue) {
    if (!editor.selectedSystem || !ViewHelper.selectedView()) return false
    var isPlacingFirstModuleGrid = editor.selectedSystem?.moduleGrids().length === 1 && newValue === true
    var isUndoingPlaceFirstModuleGrid = editor.selectedSystem?.moduleGrids().length === 0 && newValue === false
    if (isPlacingFirstModuleGrid || isUndoingPlaceFirstModuleGrid) {
      editor.setViewAligned(newValue)
      return true
    } else {
      return false
    }
  },

  asObject: function () {
    var round1dp = function (num) {
      return Math.round(10 * num) / 10
    }

    // var round2dp = function (num) {
    //   return Math.round(100 * num) / 100
    // }

    var object = {
      // If this was for other caching purposes it may be too rough but we currently only
      // use caching/hashing to determine whether to recalculate shading so coarse is ok
      slope: Math.round(this.getSlope()),
      shadingOverride: this.shadingOverride,
      panelTiltOverride: Math.round(this.panelTiltOverride),
      _moduleLayout: this._moduleLayout,
      _moduleLayoutOffset: this._moduleLayoutOffset,
      moduleSpacing: this.moduleSpacing ? this.moduleSpacing.map(round1dp) : null,
      groupSpacing: this.groupSpacing ? this.groupSpacing.map(round1dp) : null,
      azimuth: Math.round(this.getAzimuth()),
      position: this.position.toArray().map(round1dp),
      rotation: this.rotation.toArray().map(round1dp),
      cellsActive: this.cellsActive.join('_'),
      elevationAuto: this.elevationAuto,
      slopeAuto: this.slopeAuto,
      azimuthAuto: this.azimuthAuto,
    }

    if (this.hasBuildablePanels()) {
      object.buildableCells = this.buildableCells.join('_')
    }

    return object
  },

  unsnapFromCurrentFacet: function (params) {
    if (!this.facet) return

    if (!params) params = {}
    const { newFacet } = params

    const facet = this.facet
    facet.removeFloatingObject(this)

    this.signals.unsnappedFromFacet.dispatch(facet, newFacet)
  },

  snapToFacet: function (params) {
    if (!params) params = {}
    const { facet, editor } = params

    if (!facet) return

    const previousFacet = this.facet
    facet.addFloatingObject(this)
    if (!facet.isNonSpatial()) {
      facet.refloatObjects(editor || window.editor)
    }

    this.signals.snappedToFacet.dispatch(facet, previousFacet)
  },

  floatingOnFacetOnChange: function (editor, skipRemoveFloatingObject) {
    let isOnSpatialFacet = this.facet ? !this.facet.isNonSpatial() : false

    // vertical ray shooting down onto objects xy position
    let raycaster = new window.THREE.Raycaster(
      new THREE.Vector3(this.position.x, this.position.y, 1000),
      new THREE.Vector3(0, 0, -1)
    )

    // check first if the module grid is still on top of it's currently-reference facet
    // if so, no need to check further. we just leave it
    if (isOnSpatialFacet && raycaster.intersectObject(this.facet.mesh).length > 0) {
      this.facet.refloatObjects(editor) // first refloat
      return true
    }

    let spatialFacetUnderneath = ObjectBehaviors.findFacetUnderneath.call(this, raycaster)

    if (spatialFacetUnderneath) {
      const previousFacet = this.facet
      if (!skipRemoveFloatingObject && previousFacet) {
        this.unsnapFromCurrentFacet({ newFacet: spatialFacetUnderneath })
      }
      this.snapToFacet({ facet: spatialFacetUnderneath, editor })
      return true
    }

    // no facet found underneath, but we can stay on an existing facet if already floating
    // simply return the current floating status
    if (skipRemoveFloatingObject && isOnSpatialFacet) return true

    if (isOnSpatialFacet) {
      this.unsnapFromCurrentFacet({ newFacet: undefined })
    }

    return false
  },

  /**
   * onChange callback
   * @param {Editor} editor
   * @param {boolean} skipRemoveFloatingObject
   * @param {boolean} skipFloatingOnFacetOnChange
   * @param {{ skipAutoOrientation: boolean }} opts
   */
  // @TODO:  move the skipRemoveFloatingObject and skipFloatingOnFacetOnChange flags to inside opts
  onChange: function (editor, skipRemoveFloatingObject, skipFloatingOnFacetOnChange, opts = {}) {
    const DEFAULT_OPTS = {
      skipAutoOrientation: false,
    }
    opts = typeof opts === 'object' ? { ...DEFAULT_OPTS, ...opts } : DEFAULT_OPTS

    this.getParamsForLayoutCalcsCacheClear()

    // If we use 3D shading then moving a module grid should clear shading (only if raytraced) and trigger a recalc

    // We can skip refloating if we are triggering this from refloating, to automatically handle
    // refresh & calcs, but avoid inifinite recursion on floating the object
    if (skipFloatingOnFacetOnChange !== true) {
      var isFloating = this.floatingOnFacetOnChange(editor, skipRemoveFloatingObject)
      if (!isFloating) this.setSize() //not floating so call setSize directly
    }

    // @TODO: Should this only happen during creation/fingerpainting??
    if (
      !opts.skipAutoOrientation &&
      Designer.permissions.canEdit('systems.panels') &&
      editor.getTerrain() &&
      window.terrainMode === 'instant' &&
      (!this.facet || this.facet?.isNonSpatial())
    ) {
      //@TODO: We should still set the elevation to match roof surface when tilt racks apply
      //@TODO: Beware this is debounced so the hasChanged method below may not trigger even after a valid change
      // if the re-orientation does not happen synchronously
      //
      // Ensure this is not triggered on system duplication by checking hasChanged()
      if (this.hasChanged()) {
        SceneHelper.autoOrientModuleGridDebounced(this)
      }
    }

    this.azimuthIndicators.sync()

    if (editor.scene.raytracedShadingAvailable()) {
      if (!editor.changingHistory && this.hasChanged()) {
        if (editor.sceneIsLoading) {
          // While scene is loading we should never make any changes that require a recalc
          // because calculations should already have been up to date when design was saved.
          // If hasChanged() is firing during sceneIsLoading it is a mistake.
          // We should still refreshUserData() and saveHash() but do not do anything that would
          // require a recalc to be triggered, beceause it will not be triggered during scene loading
          // and that may leave data in an invalid state
          console.log('Notice: OsModuleGrid.hasChanged() was true inside OsModuleGrid.onChange() during scene loading.')
          this.refreshUserData()
          this.saveHash()
        } else {
          this.clearRaytracedShadingForModules()
          this.refreshUserData() //@TODO: Is calling refreshUserData crazy slow to do every frame of a drag?
          window.Designer.requestSystemCalculations(editor.selectedSystem)
          this.saveHash()
        }
      }
    }
  },

  hasShadingOverride: function () {
    return (
      (this.shadingOverride && this.shadingOverride.length > 0) ||
      (this.getSystem() && this.getSystem().hasShadingOverride())
    )
  },

  detectInheritedShadingOverride: function () {
    if (this.shadingOverride && this.shadingOverride.length > 0) {
      return this.shadingOverride
    } else if (this.getSystem() && this.getSystem().hasShadingOverride()) {
      return this.getSystem().shadingOverride
    } else {
      return []
    }
  },

  hasRaytracedShading: function () {
    return (
      this.diffuseShadingIsReady() ||
      this.getModules().some((m) => m.shadingOverride && m.shadingOverride.length === 288)
    )
  },

  diffuseShadingIsReady: function () {
    if (this.isDualTilt()) {
      return (
        (this.diffuseShading || this.diffuseShading === 0) && (this.diffuseShadingBack || this.diffuseShadingBack === 0)
      )
    } else {
      return this.diffuseShading || this.diffuseShading === 0
    }
  },

  clearRaytracedShadingForModules: function () {
    // Only send signals if something has actually changed
    var changed = this.hasRaytracedShading()

    // clear raytraced results after change so they will recalculate next time
    this.getModules().forEach(function (osModule) {
      osModule.shadingOverride = []
      osModule.shadingOverrideRaw = []
    }, this)

    //clear diffuse shading ready for next recalc
    this.diffuseShading = null
    this.diffuseShadingBack = null
    this.beamAccess = null
    this.beamAccessBack = null
    this.horizonElevations = null

    if (changed) {
      editor.signals.objectAnnotationChanged.dispatch(this) //@TODO: Debounce to prevent flooding?
      editor.signals.shadingUpdated.dispatch()
    }
  },

  findFacetUnderneath: ObjectBehaviors.findFacetUnderneath,

  getSystem: function () {
    // fallback to parentBeforeRemoval in case this function is called
    // when this module grid is already bound for deletion
    // in which case, the parent property will be set to null
    // see RemoveObjectCommand.execute()
    const parent = this.parent || this.parentBeforeRemoval
    if (parent && parent.getSystem) {
      return parent.getSystem()
    } else {
      return null
    }
  },

  _getActiveCellsMaxCoordX: function () {
    return Math.max(...Object.values(this.getCellsActiveVector2Dict()).map((value) => value.x))
  },

  _getActiveCellsMaxCoordY: function () {
    return Math.max(...Object.values(this.getCellsActiveVector2Dict()).map((value) => value.y))
  },

  modulesPerCol: function (value) {
    if (typeof value === 'undefined') {
      return this._modulesPerCol || 1
    }
    if (this._modulesPerCol !== value) {
      this._modulesPerCol = value
      this.colSpacingOffset = this._getActiveCellsMaxCoordX()
      if (value === 1) {
        this.groupSpacing[0] = 0
      } else {
        if (this.groupSpacing[0] === 0) {
          // make sure that the initial group spacing value is always
          // more than the module spacing value to avoid visual ambiguities
          this.groupSpacing[0] = this.moduleSpacing[0] + 0.5
        }
      }
    }
  },

  modulesPerRow: function (value) {
    if (typeof value === 'undefined') {
      return this._modulesPerRow || 1
    }
    if (this._modulesPerRow !== value) {
      this._modulesPerRow = value
      this.rowSpacingOffset = this._getActiveCellsMaxCoordY()
      if (value === 1) {
        this.groupSpacing[1] = 0
      } else {
        if (this.groupSpacing[1] === 0) {
          // make sure that the initial group spacing value is always
          // more than the module spacing value to avoid visual ambiguities
          this.groupSpacing[1] = this.moduleSpacing[1] + 0.5
        }
      }
    }
  },

  groundClearance: function (value) {
    if (typeof value === 'undefined') {
      return this._groundClearance
    }
    if (this._groundClearance !== value) {
      this._groundClearance = value
      this.setSize() //if groundClearance has changed this will force re-sort and redraw
    }
  },

  trackingMode: function (value) {
    if (typeof value === 'undefined') {
      return this._trackingMode
    }
    this._trackingMode = value
  },

  moduleLayout: function (value) {
    if (typeof value === 'undefined') {
      return this._moduleLayout
    }

    this._moduleLayout = value
    this.setSize() //if layout has changed this will force re-sort and redraw
  },

  cleanupHangingPanels: function (flipOrientation) {
    /*
    Note this must be run AFTER size has been changed to apply new orientation
    */
    var newCellsActive, cellsToRemove, newPosition

    // When UX2...
    // AND
    // ModuleGrid is attached to a facet OR there is 3D terrain loaded
    // Use the new smart method for toggling orientation including optimial positioning and deletion of hanging panels
    var hasTerrain = editor.getTerrain()
    var hasFacet = Boolean(this.floatingOnFacet)

    if (hasTerrain || hasFacet) {
      // Detect new cell positions after toggling orientation INCLUDING a buffer of N cells in X and Y directions
      // Detect the elevation difference for each new cell position and the underlying highest underlying terrain/facet.
      // For each XY offset position in the range, calculate how many cells remain active.
      // Choose the offset position with the most cells remaining (and if there are multiple, choose the position with
      // the smallest offset
      //
      // Update the dimensions based on the new portrait/orientation landscape, update the position to match the
      // best offset position, and remove cells which are now floating too far from original plane after repositioning.

      // Each configuration is a dict with: cellsActive, offsetX, offsetY
      var configurations = []

      var maxCellsOffset = new THREE.Vector2(2, 2)

      var [minX, minY, maxX, maxY] = this.getBounds()
      var minWithBuffer = new THREE.Vector2(minX, minY).sub(maxCellsOffset)
      var maxWithBuffer = new THREE.Vector2(maxX, maxY).add(maxCellsOffset)

      var cellsActiveAfterLayoutChange = []

      var objectTypesToIntersect = ['OsFacetMesh', 'OsObstruction']
      var objectsToIntersect = editor.filterObjects((o) => objectTypesToIntersect.includes(o.type))

      for (var x = minWithBuffer.x; x <= maxWithBuffer.x; x++) {
        for (var y = minWithBuffer.y; y <= maxWithBuffer.y; y++) {
          var cell = x + ',' + y //so inefficient to use strings :-(
          // var cellPosition = this.positionAndRotationForCell([x, y], null, true).position
          var cellPosition = this.pointOnCell(0.0, 0.0, [x, y], true, flipOrientation)

          var highestSurfaceZAtPanelXY = null

          if (hasFacet) {
            // Find highest object intersection with top-down ray
            // If this hits a facet AND the facet is close to the panel then keep the panel
            // Otherwise remove the panel (including when no facet is intersected)
            var origin = new THREE.Vector3(cellPosition.x, cellPosition.y, 1000)
            var direction = new THREE.Vector3(0, 0, -1)
            var raycaster = new THREE.Raycaster(origin, direction, 0, 100000)
            raycaster.linePrecision = 1.0
            var intersects = raycaster.intersectObjects(objectsToIntersect, false)
            if (intersects[0] && intersects[0].object.type === 'OsFacetMesh') {
              highestSurfaceZAtPanelXY = highestSurfaceZAtPanelXY
                ? Math.max(highestSurfaceZAtPanelXY, intersects[0].point.z)
                : intersects[0].point.z
            } else {
              continue
            }
          }

          if (hasTerrain) {
            // If there is no terrain below the facet then we always keep the panel
            var terrainZ = SceneHelper.pointOnObj(cellPosition)?.z
            if (!terrainZ) {
              // bypass this by using the panel position for the z at this location. This preserves the possibility
              // that we may still want to remove the panel due to an obstruction/facet at same elevation even though
              // it's a rare case
              terrainZ = cellPosition.z
            }
            highestSurfaceZAtPanelXY = highestSurfaceZAtPanelXY
              ? Math.max(highestSurfaceZAtPanelXY, terrainZ)
              : terrainZ
          }

          var surfaceElevationThresholds = {
            min: -0.25, // Exclude panel if more than 0.5 meters below the surface
            max: 1, // Exclude panel if more than 1.0 meters above the surface
          }

          // if no elevation found to make a decision, keep this panel by setting relative elevation to 0
          // which will always pass
          var panelRelativeElevationToSurface = highestSurfaceZAtPanelXY ? cellPosition.z - highestSurfaceZAtPanelXY : 0

          if (
            panelRelativeElevationToSurface > surfaceElevationThresholds.min &&
            panelRelativeElevationToSurface < surfaceElevationThresholds.max
          ) {
            // keep cell
            cellsActiveAfterLayoutChange.push(cell)
          }
        }
      }

      // Calculate best offset
      let cellsActiveForThisOffset = []
      for (let x = minWithBuffer.x; x <= maxWithBuffer.x; x++) {
        for (let y = minWithBuffer.y; y <= maxWithBuffer.y; y++) {
          cellsActiveForThisOffset = []

          for (let c = 0; c < this.cellsActive.length; c++) {
            let cellTmp = this.cellsActive[c]
            let cellTmpArray = cellTmp.split(',').map((s) => parseInt(s))
            let cellAdjusted = [cellTmpArray[0] + x, cellTmpArray[1] + y]
            let cellAdjustedString = cellAdjusted.join(',')
            if (cellsActiveAfterLayoutChange.includes(cellAdjustedString)) {
              // store original cell coordinate, not the adjusted coordinate
              cellsActiveForThisOffset.push(cellTmp)
            }
          }
          // this.cellsActive.forEach((cellTmp) => {
          //   var cellTmpArray = cellTmp.split(',').map((s) => parseInt(s))
          //   var cellAdjusted = [cellTmpArray[0] + x, cellTmpArray[1] + y]
          //   var cellAdjustedString = cellAdjusted.join(',')
          //   if (cellsActiveAfterLayoutChange.includes(cellAdjustedString)) {
          //     // store original cell coordinate, not the adjusted coordinate
          //     cellsActiveForThisOffset.push(cellTmp)
          //   }
          // })

          configurations.push({
            cellsActive: cellsActiveForThisOffset,
            offsetX: x,
            offsetY: y,
          })
        }
      }

      //Mostly sort by cellsActive but break ties by preferring smaller offsets
      var bestConfigurationsSorted = configurations.sort((a, b) =>
        a.cellsActive.length - Math.abs(a.offsetX + a.offsetY) * 0.001 >
        b.cellsActive.length - Math.abs(b.offsetX + b.offsetY) * 0.001
          ? -1
          : 1
      )
      var bestConfiguration = bestConfigurationsSorted[0]

      newCellsActive = bestConfiguration.cellsActive
      cellsToRemove = this.cellsActive.filter((cell) => !bestConfiguration.cellsActive.includes(cell))
      newPosition = this.position.clone()
    } else {
      cellsToRemove = []
      newCellsActive = this.cellsActive
      newPosition = this.position
    }

    return {
      cellsToRemove: cellsToRemove,
      newCellsActive: newCellsActive,
      newPosition: newPosition,
    }
  },

  moduleLayoutOffset: function (value) {
    if (typeof value === 'undefined') {
      return this._moduleLayoutOffset
    }
    this._moduleLayoutOffset = value
    this.setSize() //if layout has changed this will force re-sort and redraw
  },

  renderActive: function (value) {
    if (typeof value === 'undefined') {
      return editor && editor.viewport ? editor.viewport.renderActive() : false
    } else {
      if (editor && editor.viewport) {
        window.studioDebug && console.warn('Beware setting renderActive() from OsModuleGrid')
        editor.viewport.renderActive(value)
      }
    }
  },

  clearAssociatedObject: function () {
    if (this.floatingOnFacet) {
      const facet = this.floatingOnFacet
      facet.removeFloatingObject(this)
      this.signals.unsnappedFromFacet.dispatch(facet, null)
    }
    if (!!this.azimuthIndicators) {
      this.azimuthIndicators.hide(true) // hide then dispose
    }
  },

  setSize: function (size) {
    var renderActiveInitial = this.renderActive()

    try {
      this.renderActive(false)

      if (typeof size === 'undefined') {
        size = this.size
      }
      var newSizeSorted = this.sizeForLayout(size, this.moduleLayout())

      // "changed" identifies any changes that require the whole module object to be recreated
      // "changedOrigin" identifies changes that require the module object to be repositioned (only)
      // so if we only have "changedOrigin" then we do not need to call draw(true) since draw(false) updates position

      var changed = false
      var changedOriginOnly = false

      if (newSizeSorted.join(',') !== this.size.join(',')) {
        changed = true
      }

      if (this.moduleLayoutOffset() !== this.moduleLayoutOffsetApplied) {
        changed = true
      }

      if (this.moduleSpacing.join(',') !== this.moduleSpacingApplied.join(',')) {
        changed = true
      }

      if (this.groupSpacing.join(',') !== this.groupSpacingApplied.join(',')) {
        changed = true
      }

      if (this.gridOriginOffset.toArray().join(',') !== this.gridOriginOffsetApplied.toArray().join(',')) {
        changedOriginOnly = true
      }

      if (this.panelConfiguration !== this.panelConfigurationAppied) {
        changed = true
      }

      if (this.panelTiltOverride !== this.panelTiltOverrideAppied) {
        changed = true
      }

      if (this.modulesPerCol() !== this.modulesPerColApplied) {
        changed = true
      }

      if (this.modulesPerRow() !== this.modulesPerRowApplied) {
        changed = true
      }

      if (this.groundClearance() !== this.groundClearanceApplied) {
        changed = true
      }

      if (changed) {
        //@todo: overwriting the size array might be best
        //if we update it's elements it may try to resize all the modules immediately
        this.size = newSizeSorted
        this.panelTiltOverrideAppied = this.panelTiltOverride
        this.panelConfigurationAppied = this.panelConfiguration
        this.moduleLayoutOffsetApplied = this.moduleLayoutOffset()
        this.moduleSpacingApplied = this.moduleSpacing
        this.groupSpacingApplied = this.groupSpacing
        this.gridOriginOffsetApplied = this.gridOriginOffset.clone()
        this.modulesPerColApplied = this.modulesPerCol()
        this.modulesPerRowApplied = this.modulesPerRow()
        this.groundClearanceApplied = this.groundClearance()

        // If we don't do this, there will be active modules positioned with stale values for gridOriginOffset
        this.getParamsForLayoutCalcsCacheClear()

        this.draw(true)
        if (!!this.azimuthIndicators) {
          this.azimuthIndicators.sync()
        }
      } else if (changedOriginOnly) {
        this.gridOriginOffsetApplied = this.gridOriginOffset.clone()

        // clear getParamsForLayoutCalcs cache before calling draw() because it needs to use the latest
        // value for gridOriginOffset which we have just applied but we will NOT call onChange because it is
        // too slow and it's unnecessary so long as we ensure that getParamsForLayoutCalcs gets recalcualted.
        //
        // If we don't do this, there will be active modules positioned with stale values for gridOriginOffset
        this.getParamsForLayoutCalcsCacheClear()

        this.draw(false, true)
        if (!!this.azimuthIndicators) {
          this.azimuthIndicators.sync()
        }
      }

      //////////////////////////////
      // Start applyTiltRacks
      //Only apply slope here, azimuth is applied during OsModuleGrid orientation

      var underlyingSlope = this.facet ? this.facet.slope : this.getSlope()

      var extraSlope = this.panelTiltOverride !== null ? this.panelTiltOverride - underlyingSlope : 0

      if (Math.abs(extraSlope) < 1) {
        extraSlope = 0
      }

      if (this.extraSlopeApplied !== extraSlope) {
        for (var cell in this.moduleObjects) {
          //   var d = this.positionAndRotationForCell(
          //     cell.split(',').map(parseFloat),
          //     null,
          //     false,
          //     false,
          //     0,
          //     colRowSpacingFormula
          //   )
          const cellCoordsParsed = cell.split(',').map(parseFloat)
          const d = this.positionAndRotationForCell({
            ...this.getParamsForLayoutCalcs({
              ignoreGroundClearance: false,
              flipOrientation: false,
              additionalZOffset: 0,
            }),
            moduleCoordX: cellCoordsParsed[0],
            moduleCoordY: cellCoordsParsed[1],
            applyToObject: null,
          })
          this.moduleObjects[cell].position.z = d.position.z
          this.moduleObjects[cell].rotation.x = d.rotation.x
        }
      }
      //Need to check azimuthApplied too, even though it's not applied here
      //this.extraSlopeApplied = extraSlope;
      // End applyTiltRacks
      //////////////////////////////

      this.refreshModules()
    } catch (e) {
      console.log(e)
    }

    if (this.renderActive() !== renderActiveInitial) {
      this.renderActive(renderActiveInitial)
    }

    // Only force a render if renderActive was initially set to true
    // otherwise we were not expecting any rendering here anyway
    if (renderActiveInitial === true) {
      if (editor && editor.viewport) {
        editor.viewport.render(true)
      }
    }
  },

  moduleTexture: function (value) {
    if (typeof value === 'undefined') {
      return this._moduleTexture ? this._moduleTexture : 'default'
    } else if (this._moduleTexture !== value) {
      this._moduleTexture = value

      if ((this._moduleTexture === null || this._moduleTexture === undefined) && (value === 'default' || !value)) {
        // We were firing signals just when this is first applied, so this is an attempt to avoid that
        // We may find a neater way to do this, but we should be careful about making changes to initial values to
        // avoid unexpected/unintented consequences.
        // This is not a real change, do not update other objects or fire signals
      } else {
        this.refreshModules()
        if (this.hasBuildablePanels()) {
          this.draw()
        }
      }
    }
  },

  sizeForLayout: function (size, layout) {
    if (layout === 'portrait') {
      return size.slice(0).sort()
    } else if (layout === 'landscape') {
      return size.slice(0).sort().reverse()
    } else {
      throw new Error('Error: invalid layout for moduleGrid')
    }
  },

  getPermissionCheck: function () {
    return Designer?.permissions.canEdit('systems.panels')
  },

  getContextMenuItems: function (position) {
    var _this = this

    var options = [
      {
        label: window.translate('Panel Orientation: Portrait') + (_this.moduleLayout() === 'portrait' ? '*' : ''),
        selected: _this.moduleLayout() === 'portrait',
        onClick: function () {
          _this.moduleLayout('portrait')
        },
      },
      {
        label: window.translate('Panel Orientation: Landscape') + (_this.moduleLayout() === 'landscape' ? '*' : ''),
        selected: _this.moduleLayout() === 'landscape',
        onClick: function () {
          _this.moduleLayout('landscape')
        },
      },
      {
        label: window.translate('Rows: Regular') + (!_this.moduleLayoutOffset() ? '*' : ''),
        selected: !_this.moduleLayoutOffset(),
        onClick: function () {
          _this.moduleLayoutOffset(false)
        },
      },
      {
        label: window.translate('Rows: Offset') + (_this.moduleLayoutOffset() ? '*' : ''),
        selected: _this.moduleLayoutOffset(),
        onClick: function () {
          _this.moduleLayoutOffset(true)
        },
      },
    ]

    if (OsString.visible()) {
      editor.filter('type', 'OsString').forEach(function (es) {
        options.push({
          label: window.translate('Assign all to string (%{stringSummary})', { stringSummary: es.getSummary() }),
          useHTML: true,
          selected: false,
          onClick: function () {
            _this.getModules().forEach(function (m) {
              //es.addModule(m)
              editor.execute(new StringModuleArrayAssignmentCommand(es, [m]))
            })
            editor.signals.objectChanged.dispatch(es)
          },
        })
      }, this)
    }

    options.push({
      label: window.translate('Select Panel Group'),
      useHTML: false,
      selected: false,
      targetObjUuid: _this.uuid,
      onClick: function () {
        if (editor) {
          editor.select(_this)
        }
      },
    })

    if (this.facet?.isNonSpatial()) {
      options.push({
        label: window.translate('Select Facet'),
        selected: false,
        targetObjUuid: _this.facet.uuid,
        onClick: () => {
          editor.select(this.facet, true)
        },
      })
    }

    return options
  },

  moduleQuantity: function () {
    return this.cellsActive.length
  },

  // remove associated helpers from scene
  onRemoveHelper: function () {
    if (this.helpers.length > 0) {
      this.helpers.forEach(function (helper) {
        if (helper.parent) {
          editor.removeObject(helper)
          editor.signals.helperRemoved.dispatch(helper)
        }
      })
    }
    this.azimuthIndicators.hide(true)
  },

  // add missing helpers to the scene
  onAddHelper: function () {
    if (this.helpers) {
      this.helpers.forEach(function (helper) {
        if (!helper.parent) {
          editor.addObject(helper, editor.sceneHelpers)
        }
      })
    }
  },

  getModules: function (options = { subset: OsModuleGrid.AzimuthalSubsets.FrontAndBack }) {
    const activeModules = _.values(this.moduleObjects).filter(function (m) {
      return m.active
    })

    if (options.subset === OsModuleGrid.AzimuthalSubsets.FrontAndBack) {
      return activeModules
    }

    if (options.subset === OsModuleGrid.AzimuthalSubsets.Front) {
      return activeModules.filter((m) => m.getAzimuth() === this.getAzimuth())
    }

    if (options.subset === OsModuleGrid.AzimuthalSubsets.Back) {
      return activeModules.filter((m) => m.getAzimuth() !== this.getAzimuth())
    }
  },

  getInactiveModules: function () {
    return _.values(this.moduleObjects).filter(function (m) {
      return !m.active
    })
  },

  getSummary: function () {
    return 'x ' + this.moduleQuantity() + ' ' + this.moduleLayout() + ' ' + (this.moduleLayoutOffset() ? ' offset' : '')
  },

  getDimensions: function () {
    const dimensions = { width: 0, height: 0, moduleCountX: 0, moduleCountY: 0, moduleSpanX: 0, moduleSpanY: 0 }

    if (this.cellsActive.length === 0) return dimensions

    const moduleWidth = this.size[0]
    const moduleHeight = this.size[1]
    const relativeTilt = this.getRacksTilt()
    // the cell coordinates are stored as strings
    // example: '1,2'
    // so we need to parse them as int pairs
    const cellCoords = this.cellsActive.map((c) => c.split(',').map((coord) => parseInt(coord)))

    const setXs = new Set([])
    const setYs = new Set([])

    let minXCell = cellCoords[0]
    let maxXCell = cellCoords[0]
    let minYCell = cellCoords[0]
    let maxYCell = cellCoords[0]

    cellCoords.forEach((coord) => {
      setXs.add(coord[0])
      setYs.add(coord[1])
      if (coord[0] < minXCell[0]) {
        minXCell = coord
      }
      if (coord[0] > maxXCell[0]) {
        maxXCell = coord
      }
      if (coord[1] < minYCell[1]) {
        minYCell = coord
      }
      if (coord[1] > maxYCell[1]) {
        maxYCell = coord
      }
    })

    // the actual number of panels along the width of the module grid
    // = the number of unique x-components in the list of module coordinates
    dimensions.moduleCountX = setXs.size
    // the actual number of panels along the width of the module grid
    // = the number of unique y-components in the list of module coordinates
    dimensions.moduleCountY = setYs.size

    // The position of a module object is the local position (that is, in the module grid space)
    // and it at the very middle of the module

    // the width of the module grid is the distance from the module with the smallest x-component
    // to the module with the biggest x-component
    // PLUS the module width because the distance is middle-to-middle
    // so it's still short by exactly 1 module's width
    const minXCellPosition = this.moduleObjects[minXCell.join(',')].position
    const maxXCellPosition = this.moduleObjects[maxXCell.join(',')].position
    const xPositionDiff = Math.abs(maxXCellPosition.x - minXCellPosition.x)
    dimensions.width = xPositionDiff + moduleWidth

    // the computation for the height is almost the same as computing the width
    const minYCellPosition = this.moduleObjects[minYCell.join(',')].position
    const maxYCellPosition = this.moduleObjects[maxYCell.join(',')].position
    const yPositionDiff = Math.abs(maxYCellPosition.y - minYCellPosition.y)
    // the difference is here:
    // instead of adding 1 module height to the distance,
    // we account for the relative tilt
    // because a module will span less vertical distance when it's tilted
    // the bigger the tilt angle, the smaller the span
    // a.k.a foreshortened distance
    dimensions.height = yPositionDiff + Math.cos(THREE.Math.DEG2RAD * relativeTilt) * moduleHeight

    // the moduleSpanX is the number of modules that *can fit* within the entire width of the module grid
    // the moduleSpanY is the number of modules that *can fit* within the entire height of the module grid
    dimensions.moduleSpanX = dimensions.width / moduleWidth
    dimensions.moduleSpanY = dimensions.height / moduleHeight

    return dimensions
  },

  getPanelTilt: function () {
    if (this.panelTiltOverride !== null) {
      return this.panelTiltOverride
    } else {
      return this.getSlope()
    }
  },

  getRacksTilt: function () {
    if (this.panelTiltOverride !== null) {
      return this.panelTiltOverride - this.getSlope()
    } else {
      return 0
    }
  },

  setSlope: function (value) {
    SceneHelper.orientModuleGrid(this, this.getAzimuth(), value)
  },

  getSlope: function () {
    var orientation = Utils.getOrientation(this)
    return orientation['slope']
  },

  setAzimuth: function (value) {
    SceneHelper.orientModuleGrid(this, value, this.getSlope())
  },

  getAzimuth: function () {
    var orientation = Utils.getOrientation(this)
    return orientation['azimuth']
  },

  getPanelConfiguration: function () {
    return this.panelConfiguration
  },

  setPanelConfiguration: function (panelConfig) {
    if (panelConfig !== this.panelConfiguration) {
      // when the panel configuration has changed, switch to the latest layout calcs version
      this.layoutCalcs = OsModuleGrid.Layouts.getLatestVersion(panelConfig)
    }
    this.panelConfiguration = panelConfig
  },

  isUsingTiltRacks: function () {
    const panelConfig = this.getPanelConfiguration()
    return (
      panelConfig === OsModuleGrid.PanelConfigTypes.SingleTilt || panelConfig === OsModuleGrid.PanelConfigTypes.DualTilt
    )
  },

  isDualTilt: function () {
    return this.panelConfiguration === 'DUAL_TILT_RACK'
  },

  getPanelPlacement: function () {
    return this.panelPlacement
  },

  centroidCache: null,
  meanCellCache: null,
  getMeanCellOptimized: function () {
    var meanCell

    var cellsActiveVector2Dict = this.getCellsActiveVector2Dict()

    function getValuesMatchingKeys(obj, keys) {
      return keys.reduce((values, key) => {
        if (key in obj) {
          values.push(obj[key])
        }
        return values
      }, [])
    }

    // If we have no centroid cache then just calculate it from scratch now
    if (!this.meanCellCache) {
      meanCell = this.getMeanCell(cellsActiveVector2Dict)
      this.meanCellCache = {
        cells: this.cellsActive.slice(),
        position: meanCell,
      }
      return meanCell
    }

    /*
    Optimized centroid calcs leveraging cache as shortcut calculation for new centroid.

    New centroid = (centroid old points) - (centroid lost points) + (centroid new points)  /  (num points old - lost + new)
    */

    // var initialCells = this.cellsToVector2(this.meanCellCache.cells)
    var initialCount = this.meanCellCache.cells.length
    var initialCentroid = this.meanCellCache.position

    // Cannot use getValuesMatchingKeys(cellsActiveVector2Dict...) because there are points that are, by definition
    // cells to remove which are in cellsActive
    var pointsToRemove = this.cellsToVector2(
      this.meanCellCache.cells.filter((cell) => !this.cellsActive.includes(cell))
    )

    var pointsToAdd = getValuesMatchingKeys(
      cellsActiveVector2Dict,
      this.cellsActive.filter((cell) => !this.meanCellCache.cells.includes(cell))
    )

    // Calculate the sum of coordinates for the points to be removed
    let sumRemove = new THREE.Vector2(0, 0)
    pointsToRemove.forEach((point) => {
      sumRemove.add(point)
    })

    // Calculate the sum of coordinates for the points to be added
    let sumAdd = new THREE.Vector2(0, 0)
    pointsToAdd.forEach((point) => {
      sumAdd.add(point)
    })

    // Calculate the new total number of points
    let newCount = initialCount - pointsToRemove.length + pointsToAdd.length

    // Calculate the new sum of all coordinates
    let initialSum = initialCentroid.clone().multiplyScalar(initialCount)
    let newSum = initialSum.sub(sumRemove).add(sumAdd)

    // Calculate the new centroid
    let newMeanCell = newSum.divideScalar(newCount)

    this.meanCellCache = {
      cells: this.cellsActive.slice(),
      position: newMeanCell,
    }

    return newMeanCell
  },
  cellToVector2: function (cell) {
    return new THREE.Vector2().fromArray(cell.split(',').map((value) => parseInt(value)))
  },
  cellsToVector2: function (cells) {
    return cells.map(this.cellToVector2)
  },
  cellsToVector2Dict: function (cells) {
    var result = {}
    cells.forEach((cell) => {
      result[cell] = this.cellToVector2(cell)
    })
    return result
  },

  getCellsActiveVector2DictCache: null,
  getCellsActiveVector2Dict: function () {
    // includes caching to avoid spliting/parsing multiple times
    // ensure this is cleared any time cellsActive is updated
    if (this.getCellsActiveVector2DictCache) {
      return this.getCellsActiveVector2DictCache
    } else {
      var cellsActiveAsVector2Dict = this.cellsToVector2Dict(this.cellsActive)
      this.getCellsActiveVector2DictCache = cellsActiveAsVector2Dict
      return cellsActiveAsVector2Dict
    }
  },

  inactiveBuildableCellsVector2DictCache: null,
  getInactiveBuildableCellsVector2Dict: function () {
    if (this.inactiveBuildableCellsVector2DictCache) {
      return this.inactiveBuildableCellsVector2DictCache
    } else {
      const inactiveBuildableCells = _.difference(this.getBuildableCells(), this.cellsActive)
      const dict = {}
      inactiveBuildableCells.forEach((c) => {
        dict[c] = this.cellToVector2(c)
      })
      this.inactiveBuildableCellsVector2DictCache = dict
      return dict
    }
  },

  clearInactiveBuildableCellsCache: function () {
    this.inactiveBuildableCellsVector2DictCache = null
    this.quickLookupForCellsCache = null
  },

  getMeanCell: function (cellsAsVector2Dict) {
    var cellsAsVector2List = Object.values(cellsAsVector2Dict)
    var sumCells = new THREE.Vector2()
    cellsAsVector2List.forEach((cellVector2) => {
      sumCells.add(cellVector2)
    })
    return sumCells.multiplyScalar(1 / cellsAsVector2List.length)
  },

  getCentroid: function () {
    // Sadly centroidCache is broken, at least when panel is floating on terrain, and possibly other cases too.
    // Disable for now because it does not seem to hit cache very often anway, even if it was working reliably.
    var allowCentroidCache = false

    if (allowCentroidCache === true && this.centroidCache) {
      // We clone the cached position to protect it against accidental modification by reference elsewhere
      // e.g. if some other code calls localToWorld(getCentroid()) this would break the cache!
      // @TODO: This would run faster if we could be sure that cellsActive will not be modified by reference
      return this.centroidCache.position.clone()
    }

    var meanCell = this.getMeanCellOptimized()

    //@TODO: We could make this faster by only calculating position, not rotation
    // var p = this.positionAndRotationForCell(meanCell.toArray(), null, ignoreGroundClearance)
    var p = this.positionAndRotationForCell({
      ...this.getParamsForLayoutCalcs({
        ignoreGroundClearance: true,
        flipOrientation: false,
        additionalZOffset: 0,
      }),
      moduleCoordX: meanCell.x,
      moduleCoordY: meanCell.y,
      applyToObject: null,
    })

    this.centroidCache = {
      position: p.position,

      // We include slice() because I worry that cellsActive may get updated by reference.
      // If we could be sure thatcellsActive will not be modified by reference we could avoid this.
      cells: this.cellsActive.slice(),
    }

    return p.position
  },

  getLowestPoint: function () {
    // determine the base (lowest extent)  of the module grid
    // we can't just get the bounding box of the module grid and call it a day
    // because the bounding box will include INACTIVE modules inside the module grid
    // what we want is the base of the ACTIVE modules only
    // we do this by checking the bounding box of each active module
    this.updateMatrixWorld()
    let module = undefined
    let moduleBBoxMin = undefined
    let lowestModuleBBoxMin = undefined
    this.cellsActive.forEach((cell) => {
      module = this.moduleObjects[cell]
      moduleBBoxMin = module.localToWorld(Utils.getBoundingBoxLocal(module).min)
      if (lowestModuleBBoxMin === undefined) {
        lowestModuleBBoxMin = moduleBBoxMin
        return
      }
      if (moduleBBoxMin.z < lowestModuleBBoxMin.z) {
        lowestModuleBBoxMin = moduleBBoxMin
      }
    })
    return lowestModuleBBoxMin
  },

  setGroundClearance: function (value, { skipRedraw = false }) {
    this._groundClearance = value
    if (!skipRedraw) {
      this.setSize(this.size)
    }
  },

  getGroundClearance: function () {
    return this._groundClearance
  },

  resetObjectPositionToCentroidOfCells: function (forceClear) {
    // Do not recalculate gridOriginOffset when placing panels with SolarTouch
    // which we detect through this.ghostMode()
    if (this.ghostMode()) {
      this.draw(forceClear)
      return
    }

    var centroidLocal = this.getCentroid()
    if (centroidLocal.length() < 0.1) {
      // Early return disabled to ensure we don't miss out on later draw(true)
      // call which may be critical
      //
      // return
    }

    if (this.isUsingTiltRacks()) {
      // prevents tilted panels from shifting in position
      // when placed on sloped surfaces
      centroidLocal.z = 0
    }

    var centroidWorld = this.localToWorld(centroidLocal.clone())
    var worldDeltaFromObjectOriginToCentroid = centroidWorld.clone().sub(this.position)

    // Do not apply any elevation when the panels are on tilt racks
    if (this.isUsingTiltRacks()) {
      worldDeltaFromObjectOriginToCentroid.z = 0
    }

    var centroidLocalWithoutElevation = new THREE.Vector3(centroidLocal.x, centroidLocal.y, 0)

    this.gridOriginOffset.sub(centroidLocalWithoutElevation)
    this.position.add(worldDeltaFromObjectOriginToCentroid)

    this.draw(forceClear, false)

    // Avoid calling onChange because it is heavy and resetting position of centroid should
    // never have any impact on the rest of the scene, it will only affect on a future update
    // e.g. if module later has azimuth/slope changed etc.
    // this.onChange(editor)

    // critical to updateMatrixWorld because the moduleGrid position has changed
    this.updateMatrixWorld()
  },

  getPlane: function () {
    //Create from slope & azimuth including any overrides from tilt racks and facet orientation
    var slope = this.getSlope()
    var azimuth = this.getAzimuth()

    var plane = OsFacet.planeFromAzimuthSlopeCentriod(azimuth, slope, this.position)

    return plane
  },

  applyGhostMode: function (value) {
    // if(value){
    //     this.material = ...;
    // }else{
    //     this.material = ...;
    // }
  },

  ghostMode: ObjectBehaviors.handleGhostModeBehavior,

  onRemove: function (e) {
    this.clearAssociatedObject()
  },

  pointOnCell: function (xFraction, yFraction, cell, ignoreGroundClearance, flipOrientation, offsetZ) {
    // Top Right = 0.5, 0.5
    // Bottom Left = -0.5, -0.5

    // return this.localToWorld(
    //   this.positionAndRotationForCell(
    //     [cell[0] + xFraction, cell[1] + yFraction],
    //     null,
    //     ignoreGroundClearance,
    //     flipOrientation,
    //     offsetZ
    //   ).position
    // )

    return this.localToWorld(
      this.positionAndRotationForCell({
        ...this.getParamsForLayoutCalcs({
          ignoreGroundClearance,
          flipOrientation,
          additionalZOffset: offsetZ,
        }),
        moduleCoordX: cell[0] + xFraction,
        moduleCoordY: cell[1] + yFraction,
        applyToObject: null,
      }).position
      // this.positionAndRotationForCell(
      //   [cell[0] + xFraction, cell[1] + yFraction],
      //   null,
      //   ignoreGroundClearance,
      //   flipOrientation,
      //   offsetZ
      // ).position
    )
  },

  pointsForModule: function (osModule, inflationFactor, format) {
    if (!inflationFactor) {
      inflationFactor = [1, 1]
    }

    //'all_with_center' or 'edges'
    if (!format) {
      format = 'all_with_center'
    }

    var cell = osModule.getCell()
    var points = []

    if (format === 'all_with_center') {
      points.push(this.pointOnCell(0.0, 0.0, cell))
    }

    //do clockwise from top-right so we can conveniently use this for building linestrings/polygons etc
    points = points.concat([
      // @TODO: Optimize by calculating dx/2 and dy/2 per cell

      //top-right
      this.pointOnCell(0.5 * inflationFactor[0], 0.5 * inflationFactor[1], cell),

      //bottom-right
      this.pointOnCell(0.5 * inflationFactor[0], -0.5 * inflationFactor[1], cell),

      //bottom-left
      this.pointOnCell(-0.5 * inflationFactor[0], -0.5 * inflationFactor[1], cell),

      //top-left
      this.pointOnCell(-0.5 * inflationFactor[0], 0.5 * inflationFactor[1], cell),
    ])
    return points
  },

  residualsFromTerrain: function (osModule, inflationFactor, drawNodes) {
    //PanelGrid is raisdd AND modules are raised inside the panel grid too
    //Subtract groundClearance because we use the base of the cell to match terrain
    //@TODO: Remove this hack where we multiply MODULE_GRID_OFFSET_FROM_TERRAIN by 2x
    var raiseOffsetZ = this.groundClearance() + 2 * MODULE_GRID_OFFSET_FROM_TERRAIN

    // Find if all 4 corners and center of the inactive module cell are close enough to terrain
    var points = this.pointsForModule(osModule, inflationFactor)

    var residuals = points.map(function (p) {
      return p.z - raiseOffsetZ - SceneHelper.pointOnObj(p).z
    })
    var absoluteResiduals = residuals.map(function (r) {
      return Math.abs(r)
    })

    if (drawNodes) {
      points.forEach(function (p) {
        editor.addObject(new OsNode({ position: p }))
      })
    }

    return {
      residuals: residuals,
      mean: SceneHelper.mean(residuals),
      absoluteResiduals: absoluteResiduals,
      meanAbsolute: SceneHelper.mean(absoluteResiduals),
    }
  },

  moduleAsJstsPolygon: function (osModule) {
    var points = this.pointsForModule(osModule, null, 'edges')

    //Add the first coordinate to the end if no already equal
    points.push(points[0])

    var jstsCoordinates = points.map(function (pt) {
      return new jsts.geom.Coordinate(pt.x, pt.y)
    })
    var linearRing = gf.createLinearRing(jstsCoordinates)
    return gf.createPolygon(linearRing, [])
  },

  growOnTerrain: function (recursive) {
    /*
    If PanelGroup is on a facet then grow inside the facet instead but avoid obstructions and overlapping facets
    */

    var modulesToActivate = []

    if (this.facet) {
      //@TODO: Cache facetClippedShape, or pass the clipped shape into other recursive iterations
      // to avoid re-building it each time
      var facetClippedShape = this.facet.shapesWithSetbacksJSTS(true).facetClippedShape

      // @TODO: Subtract any obstructions

      // Subtract any overlapping facets
      // @TODO This is very slow, we should cull other facets that do not intersect this facet's bounding box
      var _this = this
      // var facetsToSubtract = []
      editor
        .filter('type', 'OsFacet')
        .filter((f) => f !== _this.facet)
        .forEach((f) => {
          facetClippedShape = facetClippedShape.difference(f.shapesWithSetbacksJSTS(true).facetShape)
        })

      this.getInactiveModules().forEach(function (inactiveModule) {
        // Activate panel if it is fully contained within the facet, after clipping setbacks.
        if (facetClippedShape.contains(this.moduleAsJstsPolygon(inactiveModule))) {
          modulesToActivate.push(inactiveModule)
        }
      }, this)

      modulesToActivate.forEach(function (moduleToActivate) {
        moduleToActivate.setActivation(true)
      })
    } else {
      // Not debounced because this must run before the next recursive iteration
      var inflationFactor = [1.2, 1.1]
      SceneHelper.autoOrientModuleGrid(this, inflationFactor)

      // var moduleGridAzimuth = this.getAzimuth()
      // var moduleGridSlope = this.getSlope()

      this.getInactiveModules().forEach(function (inactiveModule) {
        // Require planarity slightly outside the bounds of the panel we are looking to place
        var residuals = this.residualsFromTerrain(inactiveModule, inflationFactor)
        if (residuals.meanAbsolute < 0.05 && Math.max.apply(null, residuals.absoluteResiduals) < 0.2) {
          modulesToActivate.push(inactiveModule)
        }
      }, this)

      modulesToActivate.forEach(function (moduleToActivate) {
        moduleToActivate.setActivation(true)
      })

      // Not debounced because this must run before the next recursive iteration
      SceneHelper.autoOrientModuleGrid(this, inflationFactor)
    }

    if (recursive && modulesToActivate.length > 0) {
      return this.growOnTerrain(recursive)
    }
  },

  refreshGeometryForChildren: function () {
    this.children
      .filter(function (a) {
        return a.children.length === 0 && a.type === 'OsModule'
      })
      .forEach(function (a) {
        a.setGeometryForSize()
      })
  },

  //////////////////////////////////////////////////
  //#region      Buildable Panels
  //////////////////////////////////////////////////
  /// this feature is known as "Buildable Panels" in the UI
  /// the feature is built on a per-module-grid basis
  /// the system-wide implementation is just a wrapper on top of that
  ///
  /// per module grid, the buildable cells are denoted by the buildableCells property
  ///
  /// 1. this.buildableCells prop does not exist
  ///     this means the module grid does not have any buildable cells
  ///     all active and inactive cells in the grid are not buildable
  ///
  /// 3. this.buildableCells === [not empty array]
  ///   the arrays lists the cells in the module grid that are buildable
  ///   it's a list of grid coordinates in string format
  //////////////////////////////////////////////////

  hasBuildablePanels: function () {
    return !!this.buildableCells
  },

  allActivePanelsAreBuildable: function () {
    // the module grid does not have any buildable cells
    if (this.cellsActive.length > 0 && !this.hasBuildablePanels()) return false

    // check if any active cells are not buildable
    for (let i = 0; i < this.cellsActive.length; i++) {
      if (!this.cellIsBuildable(this.cellsActive[i])) return false
    }

    // if the conditions above were passed, this means all active cells are buildable
    return true
  },

  setAllPanelsAsBuildable: function (dispatchSignal = true) {
    this.buildableCells = this.cellsActive.map((cell) => cell)
    this.draw(true)
    dispatchSignal && editor.signals.objectChanged.dispatch(this, 'buildableCells')
  },

  clearBuildablePanels: function (dispatchSignal = true) {
    // absence of the buildablePanels prop indicates that the module grid does not have any buildable panels
    delete this.buildableCells
    this.draw(true)
    dispatchSignal && editor.signals.objectChanged.dispatch(this, 'buildableCells')
  },

  onModuleActivationChange: function (dispatchSignal = false) {
    // adding more active modules on a ground-mounted module grid
    // can cause it to partially sink below ground
    if (this.selected()) this.showSetbacks()
    if (dispatchSignal) {
      window.editor.signals.objectChanged.dispatch(this, 'cellsActive')
    }
  },

  cellIsBuildable: function (cell) {
    if (!this.hasBuildablePanels()) {
      return false
    }
    return this.buildableCells.indexOf(cell) !== -1
  },

  getBuildableCells: function () {
    if (!this.buildableCells) return []
    return this.buildableCells
  },

  updateBuildableCells: function (cells, opts = {}) {
    if (!Array.isArray(cells)) return

    if (cells.length === 0) {
      delete this.buildableCells
    } else {
      this.buildableCells = cells
    }
    if (!!opts.redrawModuleGrid) {
      this.draw(true)
    }
  },

  //////////////////////////////////////////////////
  //#endregion   Buildable Panels
  //////////////////////////////////////////////////
})
