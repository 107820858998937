let SetEdgeSetbackDistanceCommand = function (edge, newSetbackDistance) {
  Command.call(this)

  this.type = 'SetEdgeSetbackDistanceCommand'
  this.name = 'Set Edge Setback Distance'
  this.updatable = false
  this.timeStamp = Date.now()

  this.PROPERTIES = ['newSetbackDistance', 'hadSetbackDistanceOverride', 'oldSetbackDistance']
  this.OBJECT_REFERENCES = ['edge']

  this.edge = edge
  this.newSetbackDistance = newSetbackDistance
  this.hadSetbackDistanceOverride = edge.hasSetbackDistanceOverride()
  this.oldSetbackDistance = edge.getSetbackDistance()
}

SetEdgeSetbackDistanceCommand.prototype = {
  execute: function () {
    if (this.newSetbackDistance === null) {
      this.edge.resetSetbackDistance()
    } else {
      this.edge.setSetbackDistance(this.newSetbackDistance)
    }
    window.editor.signals.objectChanged.dispatch(this.edge, 'setbackDistance')
  },

  undo: function () {
    if (!this.hadSetbackDistanceOverride) {
      this.edge.resetSetbackDistance()
    } else {
      this.edge.setSetbackDistance(this.oldSetbackDistance)
    }
    window.editor.signals.objectChanged.dispatch(this.edge, 'setbackDistance')
  },

  toJSON: function () {
    return Command.prototype.toJSON.call(this)
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}

window.SetEdgeSetbackDistanceCommand = SetEdgeSetbackDistanceCommand
